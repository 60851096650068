import React from 'react';

import { LoadingButton } from '@mui/lab';

import DownloadModal from './DownloadModal';

interface OverrideReportingDownloadProps {
  organizationId: string;
  companyId: string;
  setError: (error: boolean) => void;
}

export default function OverrideReportingDownload({
  organizationId,
  companyId,
  setError,
}: OverrideReportingDownloadProps) {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <LoadingButton
        onClick={handleClickOpen}
        loading={loading}
        variant="contained"
      >
        {'Sobrescrever Reconciliação'}
      </LoadingButton>

      <DownloadModal
        organizationId={organizationId}
        companyId={companyId}
        type={'overrideReporting'}
        label={'Sobrescrever Reconciliação'}
        requirements={{ period: true }}
        states={{
          loading: { state: loading, setState: setLoading },
          error: { state: false, setState: setError },
          open: { state: open, setState: setOpen },
        }}
      />
    </div>
  );
}
