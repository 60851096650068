import React, { ReactElement, ReactNode, cloneElement } from 'react';

import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';

function Tag({
  left = undefined,
  right = undefined,

  children = null,

  color = 'default',
  emphasis = 'default',
  size = 'medium',
  borderRadius = 'large',
  slotProps = {},
}: {
  left?: ReactElement;
  right?: ReactElement;

  children?: ReactNode;

  color?: 'default' | 'info' | 'success' | 'warning' | 'error' | 'secondary';
  emphasis?: 'default' | 'high' | 'low';
  size?: 'small' | 'medium';
  borderRadius?: 'small' | 'large';
  slotProps?: {
    typography?: TypographyProps;
    tooltip?: Omit<TooltipProps, 'children'>;
  };
}) {
  const { textColor, bgcolor } = (
    {
      default: {
        default: {
          textColor: 'text.secondary',
          bgcolor: 'background.default',
        },
        info: {
          textColor: 'info.main',
          bgcolor: 'background.info',
        },
        success: {
          textColor: 'success.main',
          bgcolor: 'background.success',
        },
        warning: {
          textColor: 'warning.main',
          bgcolor: 'background.warning',
        },
        error: {
          textColor: 'error.main',
          bgcolor: 'background.error',
        },
        secondary: {
          textColor: 'text.secondary',
          bgcolor: 'background.secondary',
        },
      },
      high: {
        default: {
          textColor: 'text.secondary',
          bgcolor: 'strokes.light',
        },
        info: {
          textColor: 'info.dark',
          bgcolor: 'strokes.info',
        },
        success: {
          textColor: 'success.dark',
          bgcolor: 'strokes.success',
        },
        warning: {
          textColor: 'warning.dark',
          bgcolor: 'strokes.warning',
        },
        error: {
          textColor: 'error.dark',
          bgcolor: 'strokes.error',
        },
        secondary: {
          textColor: 'text.secondary',
          bgcolor: undefined,
        },
      },
      low: {
        default: {
          textColor: 'text.secondary',
          bgcolor: undefined,
        },
        info: {
          textColor: 'info.main',
          bgcolor: undefined,
        },
        success: {
          textColor: 'success.main',
          bgcolor: undefined,
        },
        warning: {
          textColor: 'warning.main',
          bgcolor: undefined,
        },
        error: {
          textColor: 'error.main',
          bgcolor: undefined,
        },
        secondary: {
          textColor: 'text.secondary',
          bgcolor: undefined,
        },
      },
    } as const
  )[emphasis][color];

  return (
    <TagOptionalTooltip {...slotProps.tooltip}>
      <Box
        sx={{
          display: 'inline-flex',
          padding: '4px 8px',
          alignItems: 'center',
          gap: '3px',

          borderRadius: borderRadius === 'large' ? 1 : 0.5,
          px: 1,
          py: 0.5,
          color: textColor,
          bgcolor,
        }}
      >
        {left && cloneElement(left, { fontSize: 'small' }, null)}
        <Typography
          variant={size === 'medium' ? 'body2' : 'caption'}
          color="inherit"
          {...(slotProps?.typography ?? {})}
        >
          {children}
        </Typography>
        {right && cloneElement(right, { fontSize: 'small' }, null)}
      </Box>
    </TagOptionalTooltip>
  );
}

const TagOptionalTooltip = (props: TooltipProps | React.PropsWithChildren) => {
  if ('title' in props) {
    return <Tooltip {...props}>{props.children}</Tooltip>;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{props.children}</>
  );
};

export { Tag };
