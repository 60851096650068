import { LogoutOutlined } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';

import { BackButton } from '../../../modules/components/BackButton';
import {
  ChangeMembership,
  MembershipContext,
  MembershipOption,
} from '../../../modules/types';

import { MembershipPicker } from './MembershipPicker';

export type MembershipSelectorPageProps = {
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
  setMembershipContext: ChangeMembership;
  logout: () => void;
};

export function MembershipSelectorPage({
  membership,
  membershipOptions,
  setMembershipContext,
  logout,
}: MembershipSelectorPageProps) {
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: {
          mx: 8,
        },
        [theme.breakpoints.down('md')]: {
          paddingBottom: 12,
        },
      })}
    >
      <BackButton />
      <Box px={2.5} pb={4} pt={5}>
        <Typography variant="h1" data-testid="membership-selector-title">
          Empresas
        </Typography>
      </Box>
      <MembershipPicker
        membership={membership}
        membershipOptions={membershipOptions}
        setMembershipContext={setMembershipContext}
      />
      <Box p={3}>
        <Divider />
      </Box>
      <Box
        display="flex"
        px={3}
        gap={2}
        onClick={logout}
        sx={{
          cursor: 'pointer',
        }}
      >
        <LogoutOutlined
          sx={(theme) => ({
            px: 1,
            color: theme.palette.text.secondary,
            height: '24px',
            width: '24px',
          })}
        />
        <Typography variant="body1" fontWeight="500" color="text.secondary">
          Sair
        </Typography>
      </Box>
    </Box>
  );
}
