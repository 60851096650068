import { useEffect, useMemo, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import {
  LegalEntityEntry,
  PayrollElement,
  PayrollEntry,
  fetchGetPayroll,
  fetchSearchAllPayrolls,
  useGetLegalEntity,
  useGetPayroll,
} from '@octopus/api';
import { capitalize, formatMoney, formatPeriodDate } from '@octopus/formatters';
import { summaryElements } from '@octopus/payroll-engine/public-types/core';

import {
  ArrowNavigation,
  Navigator,
} from '../../../../modules/components/Navigator';
import { CalculationExplanation } from '../../../../modules/components/payrolls/CalculationExplanation';
import { ElementsTable } from '../../../../modules/components/payrolls/ElementsTable';
import { getFlagsParsedPayrollEntry } from '../../../../modules/components/payrolls/useFlagsParsedPayroll';
import { DataFetching } from '../../../../modules/dataFetching';
import { FLAGS } from '../../../../modules/flags';
import { PeriodFormat } from '../../../../modules/types';
import { getNextPeriod, getPreviousPeriod } from '../../../../utils';

export function PayrollDetails({
  organizationId,
  companyId,
  period,
  type,
  contractId,
  payrollId,
  compareToPeriod,
  workerNavigation,
  onExit,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
  period: PeriodFormat;
  compareToPeriod?: PeriodFormat;
  type: string;
  payrollId: string;
  workerNavigation: ArrowNavigation;
  onExit: () => void;
}) {
  const [currentPeriodPayrollId, setCurrentPeriodPayrollId] =
    useState<string>(payrollId);
  const [nextPeriodsPayrollIds, setNextPeriodsPayrollIds] = useState<string[]>(
    [],
  );
  const [previousPeriodsPayrollIds, setPreviousPeriodsPayrollIds] = useState<
    string[]
  >([]);

  const [comparisonPayrollId, setComparisonPayrollId] = useState<string | null>(
    undefined,
  );

  const [comparisonPayroll, setComparisonPayroll] = useState<
    PayrollEntry | undefined
  >(undefined);

  const isLoadingComparisonPayroll =
    comparisonPayrollId !== null && !comparisonPayroll;

  useEffect(() => {
    setCurrentPeriodPayrollId(payrollId);
  }, [payrollId]);

  const useGetPayrollResult = useGetPayroll({
    pathParams: {
      organizationId,
      companyId,
      payrollId: currentPeriodPayrollId,
    },
  });

  const useGetLegalEntityResult = useGetLegalEntity(
    {
      pathParams: {
        organizationId: useGetPayrollResult.data?.organizationId,
        companyId: useGetPayrollResult.data?.companyId,
        legalEntityId: useGetPayrollResult.data?.legalEntityId,
      },
    },
    {
      enabled:
        !useGetPayrollResult.isLoading &&
        !!useGetPayrollResult.data?.organizationId &&
        !!useGetPayrollResult.data?.companyId &&
        !!useGetPayrollResult.data?.legalEntityId,
    },
  );

  const { data: currentPayroll } = useGetPayrollResult;

  const isComparingDifferentPeriods = currentPayroll?.period
    ? compareToPeriod && currentPayroll.period !== compareToPeriod
    : period && compareToPeriod && period !== compareToPeriod;

  useEffect(
    function fetchComparisonPayroll() {
      if (!isComparingDifferentPeriods || !comparisonPayrollId) {
        setComparisonPayroll(undefined);
        return;
      }
      fetchGetPayroll({
        pathParams: {
          organizationId,
          companyId,
          payrollId: comparisonPayrollId,
        },
      })
        .then(setComparisonPayroll)
        .catch((err) => {
          console.log('Failed to retrieve comparison payroll', err);
        });
    },
    [
      isComparingDifferentPeriods,
      comparisonPayrollId,
      organizationId,
      companyId,
    ],
  );

  useEffect(() => {
    if (type !== 'monthly') {
      return;
    }

    const fetchSearchPreviousPayrolls = fetchSearchAllPayrolls({
      pathParams: {
        organizationId,
        companyId,
      },
      body: {
        filtering: {
          elements: {
            contractId: [contractId],
            type: [type],
          },
          ranges: {
            period: {
              max: getPreviousPeriod(period),
            },
          },
        },
        sorting: {
          field: 'period',
          order: 'desc',
        },
      },
    });

    const fetchSearchNextPayrolls = fetchSearchAllPayrolls({
      pathParams: {
        organizationId: organizationId,
        companyId: companyId,
      },
      body: {
        filtering: {
          elements: {
            contractId: [contractId],
            type: [type],
          },
          ranges: {
            period: {
              min: getNextPeriod(period),
            },
          },
        },
        sorting: {
          field: 'period',
          order: 'asc',
        },
      },
    });

    Promise.allSettled([
      fetchSearchPreviousPayrolls,
      fetchSearchNextPayrolls,
    ] as const).then((results) => {
      const [previousPayrollsResult, nextPayrollsResult] = results;

      let comparisonPayrollId;
      if (previousPayrollsResult.status === 'fulfilled') {
        setPreviousPeriodsPayrollIds(
          previousPayrollsResult.value.data.map((payroll) => payroll.payrollId),
        );
        for (const payroll of previousPayrollsResult.value.data) {
          if (payroll.period === compareToPeriod) {
            comparisonPayrollId = payroll.payrollId;
            break;
          }
        }
      } else {
        console.error(
          'Failed to retrieve previous periods',
          previousPayrollsResult.reason,
        );
      }

      if (nextPayrollsResult.status === 'fulfilled') {
        setNextPeriodsPayrollIds(
          nextPayrollsResult.value.data.map((payroll) => payroll.payrollId),
        );
        for (const payroll of nextPayrollsResult.value.data) {
          if (payroll.period === compareToPeriod) {
            comparisonPayrollId = payroll.payrollId;
            break;
          }
        }
      } else {
        console.error(
          'Failed to retrieve next periods',
          nextPayrollsResult.reason,
        );
      }

      setComparisonPayrollId(comparisonPayrollId || null);
    });
  }, [
    organizationId,
    companyId,
    payrollId,
    contractId,
    period,
    type,
    compareToPeriod,
  ]);

  const periodNavigation = useMemo(
    () => ({
      canGoBackwards: previousPeriodsPayrollIds.length > 0,
      canGoForward: nextPeriodsPayrollIds.length > 0,
      goBackwards: () => {
        if (previousPeriodsPayrollIds.length === 0) {
          return;
        }
        const [newPeriod, ...previous] = previousPeriodsPayrollIds;
        const next = [currentPeriodPayrollId, ...nextPeriodsPayrollIds];
        setPreviousPeriodsPayrollIds(previous);
        setNextPeriodsPayrollIds(next);
        setCurrentPeriodPayrollId(newPeriod as string);
      },
      goForward: () => {
        if (nextPeriodsPayrollIds.length === 0) {
          return;
        }
        const [newPeriod, ...next] = nextPeriodsPayrollIds;
        const previous = [currentPeriodPayrollId, ...previousPeriodsPayrollIds];
        setPreviousPeriodsPayrollIds(previous);
        setNextPeriodsPayrollIds(next);
        setCurrentPeriodPayrollId(newPeriod as string);
      },
    }),
    [previousPeriodsPayrollIds, nextPeriodsPayrollIds, currentPeriodPayrollId],
  );

  return (
    <DataFetching<{
      payrollData: PayrollEntry;
      legalEntityData: LegalEntityEntry;
    }>
      containerSx={{ height: '100%', overflow: 'hidden' }}
      fetchResult={{
        results: {
          payrollData: useGetPayrollResult,
          legalEntityData: useGetLegalEntityResult,
        },
      }}
      Loading={() => (
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          p={4}
          width="560px"
        >
          <Skeleton variant="rounded" width="100%" height={60} />
          <Skeleton variant="rounded" width="100%" height={50} sx={{ mt: 1 }} />
          <Box display="flex" pt={2} pb={3} gap="4px">
            <Skeleton variant="rounded" width={70} height={30} />
            <Skeleton variant="rounded" width={70} height={30} />
          </Box>
          <Skeleton variant="rounded" width={100} height={40} />
          {new Array(5).fill(null).map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={40} />
          ))}
        </Box>
      )}
      Data={({ data }) => {
        if (!data) {
          return null;
        }
        const { payrollData, legalEntityData } = data;
        const payroll = getFlagsParsedPayrollEntry(payrollData);

        return (
          payroll && (
            <PayrollDetailsWindow
              payroll={payroll}
              legalEntity={legalEntityData}
              comparisonPeriod={isComparingDifferentPeriods && compareToPeriod}
              isLoadingComparisonPayroll={isLoadingComparisonPayroll}
              comparisonPayroll={comparisonPayroll}
              periodNavigation={periodNavigation}
              workerNavigation={workerNavigation}
              onExit={onExit}
            />
          )
        );
      }}
    />
  );
}

function PayrollDetailsWindow({
  payroll,
  legalEntity,
  comparisonPeriod,
  comparisonPayroll,
  isLoadingComparisonPayroll,
  periodNavigation,
  workerNavigation,
  onExit,
}: {
  payroll: PayrollEntry;
  legalEntity: LegalEntityEntry;
  comparisonPeriod: string | null | undefined;
  comparisonPayroll: PayrollEntry | undefined;
  isLoadingComparisonPayroll?: boolean;
  periodNavigation: ArrowNavigation;
  workerNavigation: ArrowNavigation;
  onExit: () => void;
}) {
  const [elementStack, setElementStack] = useState<PayrollElement[]>([]);
  const onClickElement = (element: PayrollElement, replace: boolean) => {
    if (element.id === elementStack[elementStack.length - 1]?.id) {
      setElementStack([]);
      return;
    }
    const payrollElement = payroll.outputs?.elements[element.id];
    if (replace) {
      setElementStack([payrollElement]);
    } else {
      setElementStack((state) => [...state, payrollElement]);
    }
  };

  const header = (
    <Box
      px={5}
      pt={4}
      pb={2}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
      data-testid="payroll-details"
    >
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="h3">
          {capitalize(payroll.workerData.name)}
        </Typography>
        <Box display="flex" gap={1}>
          {payroll.workerData.employeeId && (
            <>
              <Typography
                variant="caption"
                component="p"
                fontWeight="500"
                color="text.secondary"
              >
                Matrícula: {payroll.workerData.employeeId}
              </Typography>

              <Typography
                variant="caption"
                component="p"
                fontWeight="300"
                color="text.secondary"
              >
                -
              </Typography>
            </>
          )}

          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            {capitalize(payroll.workerData.jobTitle)}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="300"
            color="text.secondary"
          >
            -
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            {formatMoney(payroll.workerData.salary)}
          </Typography>
        </Box>

        <Box display="flex" gap={1}>
          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            Admissão:{' '}
            {payroll.workerData.admissionDate.split('-').reverse().join('/')}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="300"
            color="text.secondary"
          >
            -
          </Typography>
          <Typography
            variant="caption"
            component="p"
            fontWeight="500"
            color="text.secondary"
          >
            Local de serviço: {legalEntity.br?.nomeFantasia}
          </Typography>
        </Box>
      </Box>

      <Box display={'flex'}>
        <Navigator
          arrowNavigation={workerNavigation}
          arrowsDirection="vertical"
          iconSize="medium"
        />
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            onExit();
          }}
          sx={[
            () => ({
              borderColor: 'transparent',
              borderRadius: '50%',
              minWidth: 36,
              minHeight: 36,
              height: 36,
              width: 36,
            }),
          ]}
        >
          <Close sx={{ fontSize: '32px' }} />
        </Button>
      </Box>
    </Box>
  );

  const netPayContent = (
    <Box px={2}>
      <Typography variant="h3" sx={{ pb: 2, px: 2.3 }}>
        Proventos
      </Typography>
      <ElementsTable
        payroll={payroll}
        comparisonPeriod={comparisonPeriod}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        summary="workerEarningsTotal"
        onClickElement={(element) => onClickElement(element, true)}
        selectedElementId={elementStack[elementStack.length - 1]?.id}
      />

      <Typography variant="h3" sx={{ px: 2.3, pb: 2, pt: 6 }}>
        Deduções
      </Typography>
      <ElementsTable
        payroll={payroll}
        comparisonPeriod={comparisonPeriod}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        summary="workerDeductionsTotal"
        onClickElement={(element) => onClickElement(element, true)}
        selectedElementId={elementStack[elementStack.length - 1]?.id}
      />
    </Box>
  );

  const totalCostContent = (
    <Box px={2}>
      <ElementsTable
        payroll={payroll}
        comparisonPeriod={comparisonPeriod}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        summary="totalCost"
        onClickElement={(element) => onClickElement(element, true)}
        selectedElementId={elementStack[elementStack.length - 1]?.id}
      />
    </Box>
  );

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = {
    0: summaryElements.netPay,
    ...(FLAGS.ENABLE_PAYROLL_TOTAL_COST && {
      1: summaryElements.totalCost,
    }),
  }[activeTabIndex];

  const mainContent = (
    <>
      <Box
        pt={3}
        pl={4}
        sx={(theme) => ({
          boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
        })}
      >
        <Tabs
          value={activeTabIndex}
          onChange={(_, newTab) => setActiveTabIndex(newTab)}
        >
          <Tab label="Holerite" sx={{ mr: 3 }} />
          {FLAGS.ENABLE_PAYROLL_TOTAL_COST && <Tab label="Prévia de custo" />}
        </Tabs>
      </Box>

      <Box pb={2} width="100%">
        <Box pb={3} />

        <Box
          sx={{
            position: 'relative',
          }}
        >
          {[summaryElements.netPay, summaryElements.totalCost].map(
            (tab) =>
              tab === activeTab && (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: tab === activeTab ? 'relative' : 'absolute',
                    top: 0,
                  }}
                >
                  {{ netPay: netPayContent, totalCost: totalCostContent }[tab]}
                </Box>
              ),
          )}
        </Box>
      </Box>
    </>
  );

  const footer = (
    <Box
      px={3}
      py={2}
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        background: 'rgba(247, 247, 248, 0.85)',
        backdropFilter: 'blur(4px)',
      }}
    >
      <Typography variant="body1" fontWeight="bold">
        {activeTab === summaryElements.netPay &&
          `Valor líquido: ${formatMoney(payroll.outputs?.netPay.total)}`}
        {activeTab === summaryElements.totalCost &&
          `Prévia de custo: ${formatMoney(payroll.outputs?.totalCost.total)}`}
      </Typography>
    </Box>
  );

  return (
    <Box display="flex" alignContent="stretch" height="100%">
      <Box
        width={elementStack.length > 0 ? '520px' : '0px'}
        height="100%"
        zIndex={1}
        sx={{
          opacity: elementStack.length > 0 ? 1 : 0,
          transition: 'all 0.2s',
          backgroundColor: 'background.default',
          overflowY: 'overlay',
        }}
      >
        {elementStack.length > 0 && (
          <CalculationExplanation
            element={elementStack[elementStack.length - 1]}
            canGoBack={elementStack.length > 1}
            goBack={() => setElementStack((state) => state.slice(0, -1))}
            onClickElement={(element) => onClickElement(element, false)}
            onExit={() => setElementStack([])}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        zIndex={2}
        sx={(theme) => ({
          boxShadow: `-4px 4px 16px ${theme.palette.strokes.light}, -2px 2px 4px ${theme.palette.strokes.light}`,
        })}
      >
        <Box flex={1} overflow="overlay" width="624px">
          {header}
          {payroll.type === 'monthly' && (
            <Navigator
              label={formatPeriodDate(payroll.period)}
              arrowNavigation={periodNavigation}
              arrowsDirection="horizontal"
              iconSize="small"
            />
          )}
          {mainContent}
          <Box height="56px" /> {/* offset footer size*/}
          {footer}
        </Box>
      </Box>
    </Box>
  );
}
