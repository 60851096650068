import { z } from 'zod';

export const nonEmptyString = z
  .string()
  .min(1)
  .refine(
    (val) => {
      try {
        const trimmed = val.trim();
        return trimmed.length > 0;
      } catch (_) {
        return false;
      }
    },
    {
      params: {
        i18n: { key: 'form_invalid_empty_string' },
      },
    },
  );
