// this is required to correctly stylize the page using the "usetako" styles
const STYLES_URL =
  'https://cdn.prod.website-files.com/65a91bfa198eb315068b54c8/css/tako-60b456.webflow.262b9b848.min.css';
const LOGO_URL =
  'https://cdn.prod.website-files.com/65a91bfa198eb315068b54c8/65a94b5f7b522c7b3d29e2b9_Icon.png';

export function PrivacyPolicy() {
  return (
    <>
      <link href={STYLES_URL} rel="stylesheet" type="text/css" />
      <section className="header">
        <img
          src={LOGO_URL}
          loading="lazy"
          width="120"
          height="120"
          alt="Tako Simbolo"
          className="simbolo"
        />
        <div className="w-layout-hflex">
          <h1 className="heading-12">Política de privicidade</h1>
        </div>
      </section>
      <div></div>
      <div className="termos-de-uso">
        <div className="text-block-7">
          Em vigor a partir de 22 de Março de 2024
        </div>
        <div className="rich-text-block-2 w-richtext">
          <p>
            A <strong>WGMI TECNOLOGIAS LTDA.</strong> (“<strong>WGMI</strong>”,
            “<strong>nós</strong>” ou “<strong>nossos</strong>”) preza pela
            excelência no atendimento de seus clientes e se preocupa com a sua
            privacidade e com a segurança de seus dados pessoais. Por esta razão
            foi criada esta Política de Privacidade (“Política”) para explicar
            quais Dados Pessoais são coletados e como estes são tratados por
            nós.
          </p>
          <p>
            Durante a sua navegação em nosso website www.8arm.io (“Plataforma”),
            e para que possamos oferecer nossos serviços e produtos disponíveis
            na Plataforma, direcionados, principalmente, ao processamento de
            folhas de pagamento para empresas e gerenciamento de capital humano,
            (conjuntamente, “Serviços”), é necessário que tenhamos acesso a
            determinadas informações sobre você (em conjunto, “Dados Pessoais”).
            Não é possível oferecer os Serviços sem ter acesso a esses Dados
            Pessoais. Ou seja, o tratamento dos Dados Pessoais é condição para
            utilizar a Plataforma.
          </p>
          <p>
            O acesso e cadastro de pessoas jurídicas deverá ser realizado
            necessariamente por um representante legal devidamente autorizado
            para tanto. Para fins desta Política, quando o Usuário for uma
            pessoa jurídica, seu representante legal também será considerado
            “Usuário”, na medida em que seus Dados Pessoais sejam fornecidos e
            tratados pela WGMI. Para fins desta Política, “Usuários” são todos
            os indivíduos que de alguma forma utilizam a Plataforma e usufruem
            de seus Serviços.
          </p>
          <p>
            Ao utilizar os Serviços, o Usuário declara que fornecerá apenas
            Dados Pessoais verdadeiros, atuais e precisos e não deturpará a sua
            identidade de qualquer forma no acesso e utilização dos Serviços.
          </p>
          <p>
            Esta Política explica, de maneira simples, objetiva e transparente,
            quais Dados Pessoais são coletados, usados e tratados por nós e para
            quais finalidades, além de indicar com quem eles podem ser
            compartilhados e quais os recursos disponíveis aos Usuários para
            fazer a gestão dos Dados Pessoais.
          </p>
          <p>
            Esta Política contém informações importantes sobre o tratamento de
            seus Dados Pessoais organizadas nos seguintes itens:
          </p>
          <ol start={0}>
            <li>
              <strong>Informações Gerais</strong>
            </li>
            <li>
              <strong>Dados Pessoais de menores de 18 anos</strong>
            </li>
            <li>
              <strong>Quais Dados Pessoais coletamos e tratamos</strong>
            </li>
            <li>
              <strong>Porque coletamos e tratamos esses Dados Pessoais</strong>
            </li>
            <li>
              <strong>Cookies</strong>
            </li>
            <li>
              <strong>Quando excluímos os Dados Pessoais</strong>
            </li>
            <li>
              <strong>
                Quem mais terá acesso aos Dados Pessoais e por qual(is)
                motivo(s)
              </strong>
            </li>
            <li>
              <strong>
                Quais os seus direitos com relação a esses Dados Pessoais
              </strong>
            </li>
            <li>
              <strong>
                Como armazenamos e protegemos os seus Dados Pessoais
              </strong>
            </li>
            <li>
              <strong>Transferência Internacional</strong>
            </li>
            <li>
              <strong>
                Você precisa fornecer o seu consentimento para a WGMI usar os
                seus Dados Pessoais conforme descrito nesta Política?
              </strong>
            </li>
            <li>
              <strong>Alterações nesta Política</strong>
            </li>
            <li>
              <strong>Quer falar conosco? </strong>
            </li>
          </ol>
          <p>‍</p>
          <h5>
            <strong>1. Informações Gerais</strong>
          </h5>
          <p>
            A WGMI protege os direitos de privacidade dos Usuários que se
            cadastram ou que, de qualquer forma, navegam, acessam ou utilizam os
            Serviços, nos termos da legislação aplicável e de acordo com o
            disposto nesta Política. De acordo com a Lei n. 13.709/2019, a Lei
            Geral de Proteção de Dados Pessoais (“LGPD”), a WGMI atuará como
            controladora dos seus Dados Pessoais na maior parte de suas
            atividades de tratamento.
          </p>
          <p>
            Por meio desta Política, queremos dar a você transparência de quais
            Dados Pessoais a seu respeito coletamos e utilizamos na prestação
            dos Serviços. Lembramos que a Plataforma disponibiliza às empresas,
            nossos clientes (“Clientes”) serviço de processamento de folha de
            pagamento, além de criar um diretório de informações de
            colaboradores para que seja possível a emissão de relatórios, de
            controle de ponto e a gestão de entrada de novos colaboradores.
            Ainda, quando um de nossos Clientes contrata a Plataforma, o Cliente
            garante aos seus colaboradores a possibilidade de criação de login e
            senha específicos para acesso individualizado na Plataforma, que
            permitirá ao colaborador o controle sobre suas informações
            relacionadas à relação de emprego. Por isso, precisamos coletar e
            utilizar determinados Dados Pessoais para verificar que os Dados
            Pessoais fornecidos por você são efetivamente seus; para prestarmos
            os Serviços; e para que possamos nos comunicar com você.
          </p>
          <p>
            Portanto, os Dados Pessoais poderão ser utilizados, entre outras
            finalidades descritas nessa Política, para que possamos:
          </p>
          <ul>
            <li>Realizar a autenticação de sua identidade;</li>
            <li>
              Identificar você quando entrar e acessar os Serviços, bem como
              para personalizarmos os Serviços para você e para suas
              necessidades;
            </li>
            <li>
              Nos comunicar com você sempre que necessário dentro do escopo dos
              Serviços e para pesquisas de satisfação;
            </li>
            <li>
              Compartilhar os seus Dados Pessoais com nossos parceiros de
              negócio e com a prestadores de serviço;
            </li>
            <li>
              Permitir que você utilize os Serviços a partir do seu cadastro,
              conforme descrito em nossos Termos de Uso; e
            </li>
            <li>
              Oferecer a você outros produtos ou serviços que possam ser do seu
              interesse.
            </li>
          </ul>
          <p>
            Os Dados Pessoais são coletados, utilizados e tratados com
            fundamento em diferentes bases legais, como: execução dos Serviços e
            dos termos de uso dos quais você seja parte (incluindo procedimentos
            preliminares à execução de tais documentos); cumprimento de
            obrigações legais; legítimo interesse da WGMI ou de terceiros (desde
            que respeitando os seus direitos fundamentais) e, quando necessário,
            com base no seu consentimento. A revogação do seu consentimento
            apenas nos obrigará a cessar o uso dos Dados Pessoais concedidos
            através de consentimento, sendo que poderemos utilizar os Dados
            Pessoais, quando necessário, para, por exemplo, evitar fraude e
            falsidade em autenticações e identificações, para defesa em
            processos judiciais e administrativos e para cumprimento de
            obrigações legais, tudo na medida do permitido em lei.
          </p>
          <p>
            Se após a leitura desta Política você ainda tiver qualquer dúvida,
            ou por qualquer razão, precisar se comunicar conosco para assuntos
            relacionados a como tratamos seus Dados Pessoais, você pode entrar
            em contato, com o Encarregado de Proteção de Dados da WGMI, pelos
            canais abaixo:
          </p>
          <p>E-mail: privacidade@8arm.io</p>
          <p>‍</p>
          <h5>
            <strong>2. Dados de menores de 18 anos</strong>
          </h5>
          <p>
            Eventualmente, poderemos coletar e tratar Dados Pessoais de
            dependentes, jovens aprendizes ou estagiários menores de idade.
            Nessas situações, o tratamento desses Dados Pessoais será feito na
            forma da lei e obedecendo a todas as exigências específicas.
          </p>
          <p>‍</p>
          <h5>
            <strong>3. Quais Dados Pessoais coletamos e tratamos?</strong>
          </h5>
          <p>
            O Usuário poderá utilizar os Serviços mediante cadastro em nossa
            Plataforma. A WGMI recebe ou de outra forma coleta os seguintes
            tipos de informações relacionadas aos Usuários:
          </p>
          <p>
            <strong>A. Informações de Navegação e Dispositivos. </strong>São
            informações coletadas por meio de tecnologias como cookies, pixels,
            identificadores, entre outras, quando os Usuários interagem com a
            Plataforma, sejam eles cadastrados ou não. Entre essas informações
            estão:
          </p>
          <ul>
            <li>Informações sobre o dispositivo móvel ou computador</li>
            <li>Tipo de navegador</li>
            <li>Páginas visitadas no website ou no app</li>
            <li>Duração da visita</li>
            <li>Caminhos de visualização do site</li>
            <li>Visualizações na página</li>
            <li>Geolocalização</li>
            <li>Endereço IP</li>
            <li>Dados sobre comportamento de navegação</li>
          </ul>
          <p>‍</p>
          <p>
            <strong>B. Dados de Cadastro.</strong> São as informações
            solicitadas ao Usuário ao abrir a conta na Plataforma. Além disso,
            também poderemos tratar os seus Dados Pessoais quando você se
            comunicar conosco, modificar o seu cadastro ou contatar nosso
            suporte, por exemplo.
          </p>
          <ul>
            <li>Nome completo</li>
            <li>CPF</li>
            <li>Endereço de e-mail</li>
            <li>Senha de acesso</li>
            <li>Comprovante de residência</li>
            <li>Vídeo ou imagem pessoal</li>
          </ul>
          <p>‍</p>
          <p>
            <strong>C. Dados recebidos de Clientes.</strong> Podemos ter acesso
            a determinados Dados Pessoais por intermédio de terceiros, em
            especial os nossos clientes, os quais são responsáveis pela
            disponibilização de informações relacionadas a seus colaboradores em
            nossa Plataforma.
          </p>
          <ul>
            <li>Nome completo</li>
            <li>Data de nascimento</li>
            <li>Nacionalidade</li>
            <li>Endereço</li>
            <li>Gênero</li>
            <li>Estado civil</li>
            <li>E-mail</li>
            <li>Número de Telefone</li>
            <li>
              Documentos de identificação (como RG, CPF, CNH, CTPS, PIS/PASEP)
            </li>
            <li>Filiação</li>
            <li>Dados de dependentes</li>
            <li>Empresa empregadora (Cliente)</li>
            <li>Dados contratuais</li>
            <li>Cargo na empresa</li>
            <li>Área e departamento na empresa</li>
            <li>
              Identificador único do colaborador no Cliente (por exemplo, número
              de matrícula, ID, sigla, etc.)
            </li>
            <li>Informações de escolaridade</li>
            <li>Histórico profissional</li>
            <li>Exames admissional, periódico e demissional</li>
            <li>Atestados de saúde</li>
            <li>Salário</li>
            <li>Benefícios conferidos pelo Cliente</li>
            <li>Raça ou origem étnica</li>
            <li>Filiação a sindicato</li>
            <li>
              Dados relacionados à saúde e segurança no ambiente de trabalho
            </li>
            <li>Dados de saúde</li>
            <li>Deficiências Física, Visual, Auditiva, Intelectual</li>
          </ul>
          <p>‍</p>
          <h5>
            <strong>
              4. Por que coletamos e tratamos esses Dados Pessoais?
            </strong>
          </h5>
          <p>
            A WGMI utiliza os Dados dos Usuários para as seguintes finalidades:
          </p>
          <ul>
            <li>
              Fornecimento dos nossos Serviços da melhor maneira possível;
            </li>
            <li>Autenticação e identificação do Usuário;</li>
            <li>Comunicação com o Usuário;</li>
            <li>Verificação de autenticidade de informações e documentos;</li>
            <li>Realização e atualização de cadastro;</li>
            <li>
              Compartilhamento de Dados Pessoais, nas situações necessárias;
            </li>
            <li>
              Prevenção à fraude, como forma de garantir a segurança da WGMI;
            </li>
            <li>Aperfeiçoamento e melhoria da experiência do Usuário;</li>
            <li>
              Personalização dos Serviços de acordo com as preferências do
              Usuário;
            </li>
            <li>Processamento de folha de pagamento dos Clientes;</li>
            <li>
              Processamento e reporte de dados necessários para o eSocial;
            </li>
            <li>Emissão de holerites;</li>
            <li>Geração de relatórios;</li>
            <li>
              Criação de lista de salário líquido a pagar e guias ao governo
              (DARF, obrigações previdenciárias, etc);
            </li>
            <li>Formação de diretório para os colaboradores;</li>
            <li>Controle de ponto;</li>
            <li>Análise de experiência do Usuário;</li>
            <li>
              Resguardo de direitos e obrigações relacionados ao uso dos
              Serviços, inclusive em medidas judiciais e administrativas;
            </li>
            <li>Cumprimento de obrigações legais e regulatórias;</li>
            <li>
              Colaboração e/ou cumprimento de ordem judicial ou requisição por
              autoridade administrativa competente, na medida do exigido por
              lei;
            </li>
            <li>Cumprimento de obrigações contratuais;</li>
            <li>
              Para atender aos nossos legítimos interesses, respeitando seus
              direitos previstos em lei;
            </li>
            <li>
              Recomendação ao Usuário de Serviços da WGMI e também de serviços
              de terceiros;
            </li>
            <li>
              Envio de conteúdos de marketing e mensagens com informações sobre
              produtos ou Serviços que possam ser do seu interesse, inclusive de
              terceiros;
            </li>
            <li>
              Verificação sobre sua visualização de publicidade da WGMI,
              inclusive em websites de terceiros;
            </li>
            <li>Análise de perfil do Usuário;</li>
            <li>Realização estatística e estudos;</li>
            <li>Realização de auditorias internas e externas.</li>
          </ul>
          <p>‍</p>
          <h5>
            <strong>5. Cookies</strong>
          </h5>
          <p>
            Os cookies são arquivos de dados pequenos e com a capacidade de
            armazenar e reconhecer informações relacionadas à sua navegação.
            Durante a utilização da plataforma, podem ser empregados três tipos
            distintos de cookies:
          </p>
          <ul>
            <li>
              Identificação de usuários e autenticação: destinados ao
              reconhecimento individualizado de Usuários, permitindo o acesso e
              utilização de conteúdos e/ou serviços restritos da Plataforma, bem
              como proporcionando experiências de navegação personalizadas.
            </li>
            <li>
              Segurança e detecção de atividade maliciosa: utilizados para
              habilitar recursos de segurança da Plataforma, com o propósito de
              auxiliar na monitoração e/ou detecção de atividades maliciosas ou
              em desacordo com os Termos.
            </li>
            <li>
              Análise de desempenho e navegação: destinados a facilitar a
              compreensão do desempenho da Plataforma, avaliar a audiência,
              analisar os padrões de navegação dos Usuários na Plataforma.
            </li>
          </ul>
          <p>‍</p>
          <h5 className="heading-13">
            <strong>6. Quando excluímos os Dados Pessoais?</strong>
          </h5>
          <p>
            Nós estruturamos os Serviços e as nossas operações para que os seus
            Dados Pessoais não sejam mantidos de maneira identificada por mais
            tempo do que o necessário. Ou seja, nós mantemos os Dados Pessoais
            somente durante o período em que eles são necessários para as
            finalidades descritas acima, se houver alguma obrigação legal que
            nos obrigue a manter os seus Dados Pessoais, ou se for de nosso
            legítimo interesse manter os seus Dados Pessoais.
          </p>
          <p>
            Por isso, poderemos manter os seus Dados Pessoais, entre outras
            razões, para cumprimento de obrigações legais, defesa em ações
            judiciais e/ou administrativas e combate à fraude. Na medida em que
            o consentimento seja a base legal aplicável e caso você tenha
            interesse em solicitar a revogação do consentimento, lembramos que
            poderemos manter seus Dados Pessoais na medida do expressamente
            previsto em lei.
          </p>
          <p>
            ‍<strong>‍</strong>
          </p>
          <h5>
            <strong>
              7. Quem mais terá acesso aos Dados Pessoais e por qual(is)
              motivo(s)?
            </strong>
          </h5>
          <p>
            Podemos eventualmente compartilhar os seus Dados Pessoais com os
            terceiros identificados abaixo. Qualquer compartilhamento de Dados
            Pessoais realizado pela WGMI será feito de acordo com os
            dispositivos legais e, principalmente, de forma a proteger a sua
            privacidade.
          </p>
          <ol className="list-abc">
            <li>
              <em>Outras empresas do mesmo grupo econômico da WGMI</em>
            </li>
          </ol>
          <p>
            Podemos compartilhar seus Dados Pessoais com outras empresas
            pertencentes ao mesmo grupo econômico da WGMI para fins de:
          </p>
          <ul>
            <li>
              Marketing, prospecção, pesquisas de mercado, de opinião e promoção
              dos nossos produtos e serviços;
            </li>
            <li>
              Prevenção e resolução de problemas técnicos ou de segurança; e
            </li>
            <li>Cumprimento de obrigação legal ou regulatória.</li>
          </ul>
          <p>‍</p>
          <ol start={2} className="list-abc">
            <li>
              <em>Prestadores de Serviços</em>
            </li>
          </ol>
          <p>
            Podemos compartilhar os seus Dados Pessoais com nossos prestadores
            de serviços para as seguintes finalidades:
          </p>
          <ul>
            <li>Autenticação e identificação de Usuários;</li>
            <li>Verificação de autenticidade de informações e documentos;</li>
            <li>
              Prevenir riscos, fraudes e garantir a segurança em processos de
              identificação e/ou autenticação;
            </li>
            <li>Prestação dos Serviços;</li>
            <li>Envio de campanhas publicitárias e oferta de Serviços;</li>
            <li>
              Aprimoramento de Serviços e operacionalização de novos produtos ou
              serviços;
            </li>
            <li>
              Prevenção e resolução de problemas técnicos ou de segurança; e
            </li>
            <li>Serviços de suporte, manutenção e atendimento ao cliente.</li>
          </ul>
          <p>‍</p>
          <ol start={3} className="list-13">
            <li>
              <em>Terceiros em caso de reorganização societária</em>
            </li>
          </ol>
          <p>
            Podemos compartilhar seus Dados Pessoais com terceiros como empresas
            de auditoria, escritórios de advocacia e possíveis investidores em
            caso de reorganização societária da WGMI.
          </p>
          <ol start={4} className="list-abc">
            <li>
              <em>Autoridades e órgãos reguladores</em>
            </li>
          </ol>
          <p>
            Podemos compartilhar seus Dados Pessoais com autoridades judiciais,
            policiais ou governamentais, órgãos reguladores ou outros terceiros
            com quem nós sejamos obrigados por lei, norma regulatória ou ordem
            judicial a compartilhar os Dados Pessoais, na medida do permitido em
            lei.
          </p>
          <p>‍</p>
          <h5>
            <strong>
              8. Quais os seus direitos com relação aos seus Dados Pessoais
            </strong>
          </h5>
          <p>
            O Usuário possui direitos e garantias em relação aos seus Dados
            Pessoais. Nós disponibilizamos mecanismos, detalhados abaixo, para
            que os Usuários tenham clareza e transparência no exercício de seus
            direitos. Sempre que necessário, nossa equipe estará pronta para
            atender eventuais solicitações.
          </p>
          <p>‍</p>
          <p>Nos termos da LGPD, os Usuários têm direito a:</p>
          <ul>
            <li>
              <strong>
                Obter confirmação de que realizamos o tratamento dos seus Dados
              </strong>
              <strong>Pessoais</strong>. Em atendimento a essa solicitação,
              iremos informá-lo se realizamos tratamento de seus Dados Pessoais
              ou não.
            </li>
            <li>
              <strong>Acessar seus Dados</strong> <strong>Pessoais</strong>.
              Caso tenha interesse, você pode receber um relatório no qual
              apresentamos os Dados Pessoais de sua titularidade tratados por
              nós.
            </li>
            <li>
              <strong>
                Corrigir Dados Pessoais que estejam incompletos, inexatos ou
                desatualizados
              </strong>
              . Caso você entenda que seus Dados Pessoais estão incorretos, pode
              solicitar a correção, indicando o que precisa ser alterado e por
              quê. É possível que solicitemos algum comprovante para realizar a
              alteração.
            </li>
            <li>
              <strong>
                Solicitar anonimização, bloqueio ou eliminação de Dados Pessoais
                desnecessários, excessivos ou tratados em desconformidade com o
                disposto na LGPD
              </strong>
              . Caso você entenda que estamos tratando seus Dados Pessoais de
              forma desnecessária, excessiva ou em desconformidade com a LGPD,
              pode solicitar que os Dados Pessoais sejam anonimizados,
              bloqueados ou eliminados.
            </li>
            <li>
              <strong>
                Solicitar a portabilidade dos seus Dados Pessoais a outro
                fornecedor de serviço ou produto, observados os nossos segredos
                comerciais e industriais, após a regulamentação pela Autoridade
                Nacional de Proteção de Dados (“ANPD”)
              </strong>
              . Você é o titular de seus próprios Dados Pessoais. Portanto, você
              pode requerer que esses Dados Pessoais sejam transferidos a outro
              fornecedor de serviço ou produto, de acordo com regulamentação da
              ANPD, desde que sejam respeitados segredos comerciais e
              industriais.
            </li>
            <li>
              <strong>
                Solicitar eliminação dos Dados Pessoais tratados com base no seu
                consentimento, exceto nas hipóteses de conservação de Dados
                Pessoais previstas em lei
              </strong>
              . Caso haja tratamento de seus Dados com base em consentimento,
              você pode solicitar a eliminação desses dados. No entanto, a LGPD
              autoriza a conservação de dados para fins de cumprimento de
              obrigação legal ou regulatória; estudo por órgão de pesquisa,
              garantida, se possível, a anonimização; transferência a terceiro e
              uso exclusivo do controlador, desde que anonimizados.
            </li>
            <li>
              <strong>
                Obter informação sobre com quem compartilhamos seus Dados
              </strong>
              <strong>Pessoais</strong>. Você pode solicitar que informemos com
              quais entidades nós compartilhamos os seus Dados Pessoais.
            </li>
            <li>
              <strong>
                Obter informação sobre a possibilidade de não fornecer o seu
                consentimento e as respectivas consequências
              </strong>
              . Quando o consentimento for utilizado como base legal para o
              tratamento de Dados Pessoais, você tem o direito de ser informado
              sobre a possibilidade de não fornecer consentimento e as
              consequências da negativa.
            </li>
            <li>
              <strong>
                Revogar seu consentimento para o tratamento dos seus Dados
              </strong>
              <strong>Pessoais</strong>. Caso haja tratamento de seus Dados
              Pessoais realizado com base em consentimento, você pode revogar
              esse consentimento. Com isso, qualquer tratamento de seus Dados
              Pessoais que esteja sendo feito com base no consentimento será
              interrompido.
            </li>
            <li>
              <strong>Opor-se a tratamento que viole a LGPD</strong>. Caso você
              entenda que estamos tratando seus Dados Pessoais de maneira
              contrária à LGPD, você pode se opor a esse tratamento. A
              solicitação será analisada com bastante cuidado e, caso
              concordemos, o tratamento de seus Dados Pessoais que estiver em
              desconformidade com a LGPD será interrompido.
            </li>
            <li>
              <strong>
                Solicitar a revisão de decisões tomadas unicamente com base em
                tratamento automatizado de seus Dados
              </strong>
              <strong>Pessoais</strong>. Caso decisões sejam tomadas baseadas no
              tratamento automatizado de seus Dados Pessoais, você pode
              solicitar a revisão dessas decisões.
            </li>
            <li>
              <strong>
                Peticionar em relação aos seus Dados Pessoais perante a ANPD.
              </strong>
              Caso entenda necessário, você pode peticionar em relação a seus
              Dados Pessoais perante a ANPD.
            </li>
          </ul>
          <p>‍</p>
          <p>
            Você pode exercer quaisquer dos direitos acima enviando um e-mail
            para privacidade@8arm.io.
          </p>
          <p>‍</p>
          <h5>
            <strong>
              9. Como armazenamos e protegemos os seus Dados Pessoais
            </strong>
          </h5>
          <p>‍</p>
          <p>
            Nós armazenamos seus Dados Pessoais de forma segura em locais de
            acesso restrito, pelo prazo previsto na legislação aplicável. Nós
            armazenamos seus Dados Pessoais em data centers de terceiros
            localizados em Virginia, nos Estados Unidos. Atualmente, contratamos
            os serviços de data centers fornecidos por operadores de serviços de
            computação em nuvem, como a AWS.
          </p>
          <p>
            Antes de enviar os seus Dados Pessoais para armazenamento em outros
            países, nós adotamos todas as medidas adequadas, bem como aquelas
            exigidas por lei, para assegurar que os seus Dados Pessoais
            continuem sempre protegidos. Também adotamos as melhores práticas do
            mercado para evitar a perda e destruição dos seus Dados Pessoais. De
            toda forma, nenhuma plataforma é totalmente segura. Se você tiver
            qualquer preocupação ou suspeita de que seus Dados Pessoais estão em
            risco, por favor, entre em contato enviando um e-mail para
            privacidade@8arm.io.
          </p>
          <h5 className="heading-14">
            <strong>10. Transferência Internacional</strong>
          </h5>
          <p>
            Seus Dados Pessoais podem ser transferidos para outros países, como,
            por exemplo, para os prestadores de serviço de armazenamento no
            exterior.
          </p>
          <p>
            Nos casos em que os seus Dados Pessoais forem transferidos para fora
            do Brasil, nós adotaremos todas as medidas adequadas e aquelas
            exigidas por lei para assegurar que seus Dados Pessoais continuem
            devidamente protegidos, e que essa transferência seja realizada de
            acordo com um dos mecanismos previstos na LGPD e em quaisquer outras
            regulamentações aplicáveis.<strong>‍</strong>
          </p>
          <p>‍</p>
          <h5>
            <strong>
              11. Você precisa fornecer o seu consentimento para a WGMI usar os
              seus Dados Pessoais conforme descrito nesta Política?
            </strong>
          </h5>
          <p>
            A LGPD estabelece diversas situações em que é permitido o tratamento
            de Dados Pessoais independentemente do consentimento do titular
            daqueles dados. São as chamadas “bases legais para o tratamento de
            dados”.
          </p>
          <p>
            Isso significa que, se você optar por utilizar os Serviços, em
            alguns casos poderemos coletar e tratar os seus Dados Pessoais
            independentemente do seu consentimento (se houver uma “base legal”
            prevista na LGPD que nos permita fazer isso), como para a execução
            de medidas preliminares à contratação ou para a execução de contrato
            com você, para o cumprimento de obrigações legais e regulatórias,
            para o exercício regular de direito em contrato ou em processos
            judiciais, administrativos e arbitrais, para a proteção ao crédito,
            para a garantia da prevenção à fraude e à segurança do titular, nos
            processos de identificação e autenticação de cadastro em sistemas
            eletrônicos, para legítimos interesses da WGMI ou de terceiros,
            entre outras.
          </p>
          <p>
            Quando aplicável, solicitaremos o seu consentimento para tratar os
            seus Dados Pessoais. É importante notar que a revogação do seu
            consentimento apenas nos obrigará a cessar o tratamento de Dados
            Pessoais feito exclusivamente com base em consentimento.
          </p>
          <p>‍</p>
          <h5>
            <strong>12. Alterações nesta Política</strong>
          </h5>
          <p>
            Nós podemos alterar as disposições desta Política a nosso critério e
            a qualquer tempo. Toda vez que alterarmos substancialmente esta
            Política, essas alterações serão válidas após serem: (i) divulgadas
            na Plataforma; ou (ii) enviadas por e-mail ao Usuário.
          </p>
          <p>
            O Usuário deverá verificar a versão atualizada desta Política toda
            vez que visitar a Plataforma.
          </p>
          <p>‍</p>
          <h5>
            <strong>13. Quer falar conosco?</strong>
          </h5>
          <p>
            Você pode fazer uma reclamação ou esclarecer dúvidas por meio dos
            Canais de Atendimento informados abaixo. Você também pode enviar uma
            mensagem à ANPD, mas pedimos que você tente solucionar eventuais
            questões diretamente conosco, pois nossa principal preocupação é
            manter nossos Usuários satisfeitos com nossos Serviços. Nos
            esforçaremos para cumprir essa missão através de uma comunicação
            fácil e atenciosa com os Usuários.
          </p>
          <p>‍</p>
          <p>Canais de Atendimento:</p>
          <p>Por e-mail através do endereço privacidade@8arm.io</p>
          <p>‍</p>
        </div>
      </div>
    </>
  );
}
