import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Box, Button, Typography } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SegmentedControls } from '../components';
import { DateField } from '../components/DateField';
import { FilterOption, isStringRangeFilter } from '../filtering';

export function makeDateRangeFilter({
  label,
  icon,
  propertyToFilter,
}: {
  label: string;
  icon?: React.ReactNode;
  propertyToFilter: string;
}): FilterOption {
  return {
    id: propertyToFilter,
    label,
    icon,
    filterFn: () => true,
    canInvertSelection: false,
    component: (props) => {
      const currentState =
        props.filterState.rangeFilters[propertyToFilter] ?? {};
      if (!isStringRangeFilter(currentState)) {
        throw new Error(`Invalid date range filter state for ${label}`);
      }
      return (
        <DateRangeFilter
          setFilter={(min, max) => {
            props.changeFilterState({
              rangeFilters: {
                [propertyToFilter]: { min, max },
              },
            });
          }}
          currentMin={currentState.min}
          currentMax={currentState.max}
          closePopover={() => props.closePopover()}
          data-testid="date-range-filter"
        />
      );
    },
    preview: (filterState) => {
      const filter = filterState.rangeFilters[propertyToFilter];
      if (!filter) {
        return '-';
      }
      if (typeof filter.min === 'number' || typeof filter.max === 'number') {
        throw new Error(`Invalid date range filter state for ${label}`);
      }
      const min = formatDate(filter.min);
      const max = formatDate(filter.max);
      if (!min && !max) {
        return '-';
      }
      if (!min) {
        return `Antes de ${max}`;
      }
      if (!max) {
        return `Depois de ${min}`;
      }
      return `Entre ${min} e ${max}`;
    },
  };
}

type DateRangeFilterMode = 'before' | 'after' | 'between';

function DateRangeFilter({
  setFilter,
  closePopover,
  currentMin,
  currentMax,
}: {
  setFilter: (min?: string, max?: string) => void;
  closePopover: () => void;
  currentMin?: string;
  currentMax?: string;
}) {
  const currentMode = React.useMemo(() => {
    if (currentMin && currentMax) {
      return 'between';
    }
    if (currentMin) {
      return 'after';
    }
    return 'before';
  }, [currentMin, currentMax]);
  const [mode, setMode] = React.useState<DateRangeFilterMode>(currentMode);
  const [max, setMax] = React.useState<dayjs.Dayjs | null>(
    currentMax ? dayjs(currentMax) : null,
  );
  const [showMaxCalendar, setShowMaxCalendar] = React.useState(false);
  const [maxError, setMaxError] = React.useState(false);
  const [min, setMin] = React.useState<dayjs.Dayjs | null>(
    currentMin ? dayjs(currentMin) : null,
  );
  const [showMinCalendar, setShowMinCalendar] = React.useState(false);
  const [minError, setMinError] = React.useState(false);

  const [error, setError] = React.useState('');

  useEffect(() => {
    setShowMinCalendar(false);
    setShowMaxCalendar(false);
    setMinError(false);
    setMaxError(false);
  }, [mode]);

  useEffect(() => {
    setMinError(false);
  }, [min]);

  useEffect(() => {
    setMaxError(false);
  }, [max]);

  function submit() {
    switch (mode) {
      case 'before':
        if (!max) {
          setMaxError(true);
          setError('Selecione uma data');
          return;
        }
        setFilter(undefined, max.format('YYYY-MM-DD'));
        break;
      case 'after':
        if (!min) {
          setMinError(true);
          setError('Selecione uma data');
          return;
        }
        setFilter(min.format('YYYY-MM-DD'), undefined);
        break;
      case 'between':
        if (!min) {
          setMinError(true);
          setError('Selecione uma data');
          return;
        }
        if (!max) {
          setMaxError(true);
          setError('Selecione uma data');
          return;
        }
        if (min.valueOf() >= max.valueOf()) {
          setMinError(true);
          setMaxError(true);
          setError('Data de início deve ser antes que a data final');
          return;
        }
        setFilter(min.format('YYYY-MM-DD'), max.format('YYYY-MM-DD'));
        break;
    }
    closePopover();
  }

  return (
    <Box width="320px">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Box display="flex" flexDirection="column" gap={1}>
          <SegmentedControls
            options={[
              { label: 'Antes de', target: 'before' },
              { label: 'Depois de', target: 'after' },
              { label: 'Entre', target: 'between' },
            ]}
            initialValue={mode}
            onChange={setMode}
          />
          <Box display="flex" flexDirection="row" gap={1}>
            {mode !== 'before' && (
              <DateField
                dateFormat="DD / MM / YYYY"
                value={min}
                onChange={setMin}
                sx={{ width: '100%' }}
                InputProps={{
                  error: minError,
                  endAdornment: (
                    <CalendarTodayOutlinedIcon
                      sx={{ fontSize: '16px', cursor: 'pointer' }}
                      color="primary"
                      onClick={() => {
                        setShowMaxCalendar(false);
                        setShowMinCalendar(!showMinCalendar);
                      }}
                    />
                  ),
                }}
              />
            )}
            {mode !== 'after' && (
              <DateField
                dateFormat="DD / MM / YYYY"
                value={max}
                onChange={setMax}
                sx={{ width: '100%' }}
                InputProps={{
                  error: maxError,
                  endAdornment: (
                    <CalendarTodayOutlinedIcon
                      sx={{ fontSize: '16px', cursor: 'pointer' }}
                      color="primary"
                      onClick={() => {
                        setShowMaxCalendar(!showMaxCalendar);
                        setShowMinCalendar(false);
                      }}
                    />
                  ),
                }}
              />
            )}
          </Box>
          {(minError || maxError) && error && (
            <Typography color="error.main" variant="caption">
              {error}
            </Typography>
          )}
          {showMaxCalendar && (
            <DateCalendar
              value={max}
              onChange={setMax}
              minDate={mode === 'between' && min ? min : undefined}
              dayOfWeekFormatter={(day) => day}
            />
          )}
          {showMinCalendar && (
            <DateCalendar
              value={min}
              onChange={setMin}
              maxDate={mode === 'between' && max ? max : undefined}
              dayOfWeekFormatter={(day) => day}
            />
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap={1}
          >
            <Button
              color="secondary"
              sx={{ height: '40px', width: '104px' }}
              onClick={closePopover}
            >
              <Typography variant="body2">Cancelar</Typography>
            </Button>
            <Button
              color="primary"
              sx={{ height: '40px', width: '104px' }}
              onClick={submit}
            >
              <Typography variant="body2" color="secondary.main">
                Aplicar
              </Typography>
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    </Box>
  );
}

const dateTimeFormat = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' });

function formatDate(date: string | undefined) {
  if (!date) {
    return '';
  }
  return dateTimeFormat.format(new Date(date));
}
