import { useQuery } from '@tanstack/react-query';
import { chunk } from 'lodash';

import { fetchSearchAllContracts } from '@octopus/api';

import { AddReportEntry, AddReportInfo } from './types';

export function usePrepareAddReportInfo({
  organizationId,
  reports,
}: {
  organizationId: string;
  reports: AddReportEntry[];
}) {
  return useQuery<unknown, unknown, AddReportInfo[], unknown[]>({
    queryKey: ['add-report-info', organizationId, reports],
    queryFn: async () => {
      const oldManagerContractIds = [
        ...new Set(
          reports
            .map(({ oldManagerContractId }) => oldManagerContractId)
            .filter((val) => !!val),
        ),
      ];
      const oldManagerNamesByContractId: Record<string, string> = {};
      for (const contractIds of chunk(oldManagerContractIds, 100)) {
        const result = await fetchSearchAllContracts({
          pathParams: {
            organizationId,
          },
          body: {
            filtering: {
              elements: {
                contractId: contractIds,
              },
            },
            pagination: {
              size: 100,
            },
          },
        });
        result.data.forEach(
          ({ contractId, name }) =>
            (oldManagerNamesByContractId[contractId] = name),
        );
      }
      return reports
        .map((entry) => {
          if (!entry.oldManagerContractId) {
            return entry;
          }
          return {
            ...entry,
            oldManagerName:
              oldManagerNamesByContractId[entry.oldManagerContractId],
          } as AddReportInfo;
        })
        .sort((a, b) => a.reportName.localeCompare(b.reportName));
    },
  });
}
