import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { ChevronRight, HourglassEmpty } from '@mui/icons-material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Popover, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import {
  VacationsScheduleEntry,
  VacationsScheduleSummary,
  fetchPostVacationsScheduleCreatePayroll,
} from '@octopus/api';
import { Button } from '@octopus/ui/design-system';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { ActionMenu } from '../../../modules/components/ActionMenu';
import { ExpandableTypography } from '../../../modules/components/ExpandableTypography';
import UserAvatar from '../../../modules/components/UserAvatar';
import { getTag } from '../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import { pollingPayroll } from './polling';
import { VacationsTabs } from './types';

const nameColumn: GridColDef = {
  field: 'workerData.name',
  sortable: true,
  headerName: 'Nome',
  renderHeader: (params) => {
    return <Box ml={2}>{params.field}</Box>;
  },
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.workerData.name;
  },
  renderCell: ({ value }) => {
    return !value ? (
      '-'
    ) : (
      <UserAvatar
        name={value}
        expandNameOnHover={true}
        sx={{
          '--UserAvatar-name-max-width': '12.5em',
        }}
      />
    );
  },
};

const departmentColumn: GridColDef = {
  sortable: false,
  field: 'department',
  headerName: 'Departamento',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.workerData.department;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value || '-'}</ExpandableTypography>;
  },
};

const vacationsBalanceColumn: GridColDef = {
  sortable: true,
  field: 'totalDaysAvailable',
  headerName: 'Dias disponíveis de férias',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value: { totalDaysAvailable, hasOverdueVacations } }) => {
    return (
      <VacationsBalanceComponent
        totalDaysAvailable={totalDaysAvailable}
        hasOverdueVacations={hasOverdueVacations}
      />
    );
  },
};

const VacationsBalanceComponent = ({
  totalDaysAvailable,
  hasOverdueVacations,
}: {
  totalDaysAvailable: number;
  hasOverdueVacations: string;
}) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="start">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: hasOverdueVacations && theme.palette.error.lowEmphasis,
          })}
        >
          {`${totalDaysAvailable} dias`}
        </Typography>
      </Box>
      {hasOverdueVacations && (
        <Box ml={2}>
          <ExpiredVacationsIcon totalDaysAvailable={totalDaysAvailable} />
        </Box>
      )}
    </Box>
  );
};

export const ExpiredVacationsIcon = ({
  totalDaysAvailable,
}: {
  totalDaysAvailable?: number;
}) => {
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  const overdueVacationCount = Math.ceil(totalDaysAvailable / 30);
  return (
    <>
      <Box
        display="flex"
        px={1}
        py={0.5}
        borderRadius={0.5}
        sx={(theme) => ({
          color: theme.palette.error.lowEmphasis,
          bgcolor: theme.palette.background.error,
        })}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <LocalAtmIcon />

        {totalDaysAvailable && (
          <Typography
            ml={0.5}
            variant="body2"
            sx={(theme) => ({ color: theme.palette.error.lowEmphasis })}
          >
            {overdueVacationCount}
          </Typography>
        )}
      </Box>
      <WithPopover>
        {'Férias vencidas, configura pagamento de férias em dobro.'}
      </WithPopover>
    </>
  );
};

const concessionPeriodColumn: GridColDef = {
  sortable: false,
  field: 'currentVacationsAccrualPeriodStartAndEndDate',
  headerName: 'Período concessivo vigente',
  valueGetter: (params: GridValueGetterParams) => {
    const startDate = dayjs(params.row.currentVacationsAccrualPeriodStartDate)
      .add(1, 'year')
      .format('DD/MM/YY');
    const endDate = dayjs(params.row.currentVacationsAccrualPeriodEndDate)
      .add(1, 'year')
      .format('DD/MM/YY');
    return `${startDate} a ${endDate}`;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value ?? '-'}</ExpandableTypography>;
  },
};

const limitDateToStartVacationsColumn: GridColDef = {
  sortable: true,
  field: 'limitDateToStartVacations',
  headerName: 'Limite para início do gozo',
  valueGetter: ({ row }: GridValueGetterParams) => {
    const limitDateToStartVacations = row.limitDateToStartVacations
      ? dayjs(row.limitDateToStartVacations).format('DD/MM/YY')
      : '-';
    return {
      ...row,
      limitDateToStartVacations,
    };
  },
  renderCell: ({
    value: { limitDateToStartVacations, hasVacationsCloseToDueDate },
  }) => {
    const popoverContent =
      'Faltam menos de 60 dias para a data limite do início do gozo.';
    return (
      <AlertWithPopoverComponent
        value={limitDateToStartVacations}
        hasWarning={hasVacationsCloseToDueDate}
        popoverContent={popoverContent}
      />
    );
  },
};

const AlertWithPopoverComponent = ({
  value,
  hasWarning,
  popoverContent,
}: {
  value: string;
  hasWarning: boolean;
  popoverContent: string;
}) => {
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  const withStyles = hasWarning
    ? (theme: any) => ({
        color: theme.palette.warning.main,
      })
    : undefined;

  return (
    <Box
      display="flex"
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography sx={withStyles} variant="body2">
        {value || '-'}
      </Typography>
      {hasWarning && (
        <>
          <Box ml={1}>
            <WarningIcon sx={withStyles} />
          </Box>
          <WithPopover>{popoverContent}</WithPopover>
        </>
      )}
    </Box>
  );
};

const usePopover = (): [
  React.FC<{ children: React.ReactNode }>,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void,
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const Component: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography
        align="center"
        variant="body2"
        sx={{
          width: '240px',
          padding: '12px',
          backgroundColor: '#25252D',
          color: '#fff',
        }}
      >
        {children}
      </Typography>
    </Popover>
  );
  return [Component, handlePopoverOpen, handlePopoverClose];
};

// Dias de gozo
const daysTakenColumn: GridColDef = {
  sortable: false,
  field: 'daysTaken',
  headerName: 'Dias de gozo',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.daysTaken;
  },
  renderCell: ({ value }) => {
    return (
      <ExpandableTypography>
        {value ? `${value} dias` : '-'}
      </ExpandableTypography>
    );
  },
};

// Período de gozo
const vacationPeriodColumn: GridColDef = {
  sortable: false,
  field: 'vacationPeriod',
  headerName: 'Período de gozo',
  valueGetter: (params: GridValueGetterParams) => {
    const startDate = dayjs(params.row.startDate).format('DD/MM/YY');
    const endDate = dayjs(params.row.endDate).format('DD/MM/YY');
    return `${startDate} a ${endDate}`;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value || '-'}</ExpandableTypography>;
  },
};

// Status
const statusColumn: GridColDef = {
  sortable: false,
  field: 'status',
  headerName: 'Status',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.status;
  },
  renderCell: ({ value }) => {
    return getTag(value);
  },
};

const actionsColumn: GridColDef = {
  sortable: false,
  field: 'action',
  headerName: 'Ações',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value }) => {
    return <ActionsComponent value={value} />;
  },
};

const ActionsComponent = ({ value }: { value: VacationsScheduleSummary }) => {
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { sequence, contractId, organizationId } = value;
  const status = value.status as
    | `${typeof vacationsScheduleStatuses.payrollApproved}`
    | `${typeof vacationsScheduleStatuses.payrollCreated}`
    | `${typeof vacationsScheduleStatuses.approved}`
    | `${typeof vacationsScheduleStatuses.waitingApproval}`;

  const { label } =
    {
      [vacationsScheduleStatuses.payrollApproved]: {
        label: 'Aprovado',
      },
      [vacationsScheduleStatuses.payrollCreated]: {
        label: 'Aprovar',
      },
      [vacationsScheduleStatuses.approved]: {
        label: 'Aprovado',
      },
      [vacationsScheduleStatuses.waitingApproval]: {
        label: 'Aprovar',
      },
    }[status] || {};
  const isApproved = vacationsScheduleStatuses.approved === status;
  const isPayrollApproved =
    vacationsScheduleStatuses.payrollApproved === status;

  const goToPayrollRecipt = (skip = false) => {
    setIsLoading(false);
    if (status === vacationsScheduleStatuses.payrollCreated || skip) {
      navigate(`/vacations/${contractId}/receipt?sequence=${sequence}`);
    }
  };

  const awaitingChangeStatus = ({
    companyId,
    organizationId,
    payrollId,
  }: VacationsScheduleEntry) =>
    pollingPayroll({
      companyId,
      organizationId,
      payrollId,
    }).then(() => goToPayrollRecipt());

  const onClick = () => {
    const pathParams = {
      contractId,
      organizationId,
      sequence: `${sequence}`,
    };
    setIsLoading(true);
    showSnackbar({
      isOpen: true,
      Message: 'Calculando, isso pode levar alguns instantes...',
      StartAdornment: <HourglassEmpty />,
    });
    fetchPostVacationsScheduleCreatePayroll({ pathParams })
      .then(awaitingChangeStatus)
      .then(() => goToPayrollRecipt(true))
      .catch(() => {
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao calcular folha de pagamento',
          hasCloseAction: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      {isLoading && getTag('Calculando...')}
      {!isLoading &&
        (isApproved || isPayrollApproved ? (
          <>
            {getTag(status)}
            {isApproved && (
              <ActionMenu
                menuItems={[
                  {
                    label: 'Calcular',
                    onClick: () => onClick(),
                  },
                ]}
              />
            )}
          </>
        ) : (
          <Button
            sx={{ paddingLeft: '0px' }}
            variantLayout="small"
            variantSemantic="tertiary"
            isLoading={isLoading}
            onClick={() => goToPayrollRecipt()}
          >
            {`${label}`}
            <ChevronRight />
          </Button>
        ))}
    </Box>
  );
};

// Parametros
const parametersColumn: GridColDef = {
  sortable: false,
  field: 'parameters',
  headerName: 'Parametros',
  valueGetter: (params: GridValueGetterParams) => {
    const { thirteenthAdvance, daysSold } = params.row;
    return {
      thirteenthAdvance,
      daysSold,
    };
  },
  renderCell: ({ value: { thirteenthAdvance, daysSold } }) => {
    const abono = daysSold ? 'Abono' : '';
    const adiantamento = thirteenthAdvance ? 'Adiantamento 13º' : '';
    const parameters = [abono, adiantamento].filter(Boolean).join(', ') || '-';
    return <ExpandableTypography>{parameters}</ExpandableTypography>;
  },
};

// Aprovar até
const approvalDeadlineColumn: GridColDef = {
  sortable: true,
  field: 'approvalDeadline',
  headerName: 'Aprovar até',
  valueGetter: (params: GridValueGetterParams) => {
    const { approvalDeadline, status } = params.row;
    return {
      approvalDeadline: approvalDeadline
        ? dayjs(approvalDeadline).format('DD/MM/YYYY')
        : '-',
      hasWarning: status === vacationsScheduleStatuses.waitingApproval,
    };
  },
  renderCell: ({ value: { approvalDeadline, hasWarning } }) => {
    const popoverContent = [
      'Caso a aprovação não seja feita antes da data limite a solicitação será',
      'recusada automaticamente',
    ].join(' ');
    return (
      <AlertWithPopoverComponent
        value={approvalDeadline}
        hasWarning={hasWarning}
        popoverContent={popoverContent}
      />
    );
  },
};

export const columnsByTab: Record<VacationsTabs, GridColDef[]> = {
  [VacationsTabs.people]: [
    nameColumn,
    departmentColumn,
    vacationsBalanceColumn,
    concessionPeriodColumn,
    limitDateToStartVacationsColumn,
  ],
  [VacationsTabs.vacationsHistory]: [
    nameColumn,
    departmentColumn,
    daysTakenColumn,
    vacationPeriodColumn,
    statusColumn,
  ],
  [VacationsTabs.requests]: [
    nameColumn,
    vacationPeriodColumn,
    daysTakenColumn,
    parametersColumn,
    approvalDeadlineColumn,
    // statusColumn,
    actionsColumn,
  ],
  [VacationsTabs.calculations]: [
    nameColumn,
    vacationPeriodColumn,
    daysTakenColumn,
    parametersColumn,
    approvalDeadlineColumn,
    actionsColumn,
  ],
};
