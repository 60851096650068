import React from 'react';

import { Box, Slider, TextField } from '@mui/material';

import { FilterOption, isNumberRangeFilter } from '../filtering';

export function makeMoneyRangeFilter({
  label,
  icon,
  propertyToFilter,
  getRangeMin,
  getRangeMax,
}: {
  label: string;
  icon?: React.ReactNode;
  propertyToFilter: string;
  getRangeMin: () => number;
  getRangeMax: () => number;
}): FilterOption {
  return {
    id: propertyToFilter,
    label,
    icon,
    filterFn: () => true,
    component: (props) => {
      const rangeMin = getRangeMin();
      const rangeMax = getRangeMax();
      const currentState = props.filterState.rangeFilters[propertyToFilter] ?? {
        min: rangeMin,
        max: rangeMax,
      };
      if (!isNumberRangeFilter(currentState)) {
        throw new Error(`Invalid money range filter state for ${label}`);
      }
      return (
        <Box data-testid="money-range-filter">
          <Box display="flex" gap={1}>
            <TextField
              placeholder="Mínimo"
              type="number"
              value={currentState?.min ?? ''}
              onChange={(e) => {
                const value = +e.target.value;
                props.changeFilterState({
                  rangeFilters: {
                    [propertyToFilter]: {
                      min: value,
                      max: currentState?.max,
                    },
                  },
                });
              }}
              sx={{ width: '100%' }}
            />
            <TextField
              placeholder="Máximo"
              type="number"
              value={currentState?.max ?? ''}
              onChange={(e) => {
                const value = +e.target.value;
                props.changeFilterState({
                  rangeFilters: {
                    [propertyToFilter]: {
                      ...currentState,
                      max: value,
                    },
                  },
                });
              }}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box p={1}>
            <Slider
              value={[
                currentState?.min ?? rangeMin,
                currentState?.max ?? rangeMax,
              ]}
              onChange={(_, value) => {
                const values = value as number[];
                props.changeFilterState({
                  rangeFilters: {
                    [propertyToFilter]: {
                      min: values[0] as number,
                      max: values[1] as number,
                    },
                  },
                });
              }}
              valueLabelDisplay="auto"
              min={rangeMin}
              max={rangeMax}
            />
          </Box>
        </Box>
      );
    },
    preview: (filterState) => {
      const state = filterState.rangeFilters[propertyToFilter];
      if (!state) {
        return '';
      }
      const min = state?.min ? formatMoney(state.min.toString()) : '';
      const max = state?.max ? formatMoney(state.max.toString()) : '';
      if (!min && !max) {
        return ``;
      }
      return `${min || formatMoney('0')} até ${max}`;
    },

    canInvertSelection: false,
  };
}

function formatMoney(num: string, { withSign = false } = {}) {
  if (!num) {
    num = '0';
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatted = formatter.format(+num);

  if (withSign) {
    if (+num >= 0) {
      return `+ ${formatted}`;
    }
  }

  return formatted.replace('-', '- ');
}
