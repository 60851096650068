import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';

export function ShowablePasswordField({
  show,
  setShow,
  hideButton,
  ...props
}: {
  show: boolean;
  setShow?: () => void;
  hideButton?: boolean;
} & TextFieldProps) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      type={show ? 'text' : 'password'}
      {...props}
      InputProps={{
        endAdornment: hideButton ? null : (
          <InputAdornment position="end">
            <Typography
              variant="caption"
              color="text.secondary"
              onClick={() => setShow && setShow()}
              style={{ cursor: 'pointer', fontWeight: 500 }}
            >
              {show ? 'Esconder' : 'Mostrar'}
            </Typography>
          </InputAdornment>
        ),
      }}
    />
  );
}
