import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AwsRum } from 'aws-rum-web';

import { initZod } from '@octopus/i18n';

import { envVars } from '../env.js';

import App from './app/app';

initZod('pt');

try {
  new AwsRum(envVars.rumApplicationId, '1.0.0', 'us-east-1', {
    sessionSampleRate: 1,
    identityPoolId: envVars.identityPoolId,
    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
    telemetries: ['errors', 'performance', 'http'],
    allowCookies: true,
    enableXRay: false,
  });
} catch (error) {
  console.error('Failed to load rum', error);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
);
