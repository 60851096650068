import { useEffect, useState } from 'react';

import { Cancel } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { useArchivePayroll } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

export function useArchivePayrollModal(
  organizationId: string,
  companyId: string,
) {
  const [openedModalPayrollData, setOpenedModalPayrollData] = useState<{
    employeeName: string;
    payrollId: string;
  } | null>(null);
  const { mutate, isLoading, isSuccess, isError, error } = useArchivePayroll();

  const archivePayroll = (payrollId: string, employeeName: string) => {
    setOpenedModalPayrollData({ payrollId, employeeName });
  };

  const confirmArchivePayroll = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    mutate({
      pathParams: {
        organizationId,
        companyId,
        payrollId: openedModalPayrollData.payrollId,
      },
    });
  };

  const closeModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenedModalPayrollData(null);
  };

  return {
    archivePayrollProps: {
      openedModalPayrollData,
      closeModal,
      confirmArchivePayroll,
      isSuccess,
      isLoading,
      isError,
      error,
    },
    isLoadingArchivePayroll: isLoading,
    archivePayroll,
    ArchivePayrollModal: ArchivePayrollModal,
  };
}

function ArchivePayrollModal({
  openedModalPayrollData,
  closeModal,
  confirmArchivePayroll,
  isSuccess,
  isLoading,
  isError,
  error,
}: ReturnType<typeof useArchivePayrollModal>['archivePayrollProps']) {
  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  const initialScene = (
    <>
      <DialogContent>
        <Typography variant="h1">Remover colaborador(a)</Typography>
        <Box py={1} />
        <Typography variant="body1">
          Tem certeza que deseja remover{' '}
          {openedModalPayrollData && openedModalPayrollData.employeeName} desta
          folha?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="large" color="secondary" onClick={closeModal}>
          Cancelar
        </Button>
        <Button color="error" size="large" onClick={confirmArchivePayroll}>
          Remover
        </Button>
      </DialogActions>
    </>
  );

  const loadingScene = (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Removendo colaborador(a) da folha, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );

  const errorScene = (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro no envio</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível remover o(a) colaboradora da folha, tente novamente.
        </Typography>
        {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={closeModal}>
          Ok
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={!!openedModalPayrollData}
      onClose={closeModal}
      onClick={(event) => event.stopPropagation()}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      {(() => {
        if (isLoading) {
          return loadingScene;
        }

        if (isError) {
          return errorScene;
        }

        if (isSuccess) {
          return null;
        }

        return initialScene;
      })()}
    </Dialog>
  );
}
