import { useState } from 'react';

import { Box } from '@mui/material';

import { Challenge } from '@octopus/api';

import { answerNewPasswordChallenge } from '../../../modules/login';

import { PasswordChange } from './PasswordChange';

export type NewPasswordChallengeProps = {
  username: string;
  challenge: Challenge;
  onSuccess: (token: string) => void;
};

export function NewPasswordChallenge({
  username,
  challenge,
  onSuccess,
}: NewPasswordChallengeProps) {
  const [state, setState] = useState({
    token: '',
  });

  const submit = async (password: string) => {
    const { token } = await answerNewPasswordChallenge(
      username,
      challenge,
      password,
    );
    setState({ token });
  };

  const success = () => {
    onSuccess(state.token);
  };

  return (
    <Box width="100%">
      <PasswordChange submit={submit} onSuccess={success} />
    </Box>
  );
}
