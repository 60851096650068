import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQueries } from '@tanstack/react-query';
import { isObject } from 'lodash';

import {
  ArrowBack,
  BeachAccessOutlined,
  CheckCircle,
  Warning,
} from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';

import {
  fetchGetContract,
  fetchGetContractAccrualPeriods,
  fetchGetVacationsConfiguration,
  fetchPostVacationsSchedule,
} from '@octopus/api';
import { z } from '@octopus/i18n';
import { UI_TYPE } from '@octopus/libs/forms';
import {
  CreateScheduleRule,
  scheduleErrorMessages,
  vacationsScheduleStatuses,
} from '@octopus/vacations-types';

import { Form } from '../../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../../modules/form/useFormFromDefinition';
import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { pollingSearch } from '../../utils/polling';

import { parseFormPayloadToPayrolInput } from './adapters/parseFormPayloadToPayrolInput';
import { IVacationFormInputs } from './form/types';
import { useQueryVacationFormOutputs } from './form/useQueryVacationFormOutputs';
import { VacationFormOverview } from './form/VacationFormOverview';
import { ConfirmModal } from './modal/scheduleModal';
import { ActionBar } from './PageActionsBar';
import { getCurrentAccrualPeriods, getVacationsConfiguration } from './utils';

type Props = {
  organizationId: string;
  companyId: string;
};

export function VacationInputPage({ organizationId }: Props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { contractId } = useParams<{ contractId: string }>();
  const { showSnackbar } = useSnackbar();

  const pathParams = { organizationId, contractId };
  const configPathParams = { organizationId };
  const queryParams = { enabled: !!contractId, refetchOnWindowFocus: false };

  const fetchContract = () => fetchGetContract({ pathParams });
  const fetchContractAccrualPeriods = () =>
    fetchGetContractAccrualPeriods({ pathParams });
  const fetchVacationsConfiguration = () =>
    fetchGetVacationsConfiguration({ pathParams: configPathParams });
  const queryKey = (q: string) => [q, organizationId, contractId];
  const results = useQueries({
    queries: [
      {
        queryKey: queryKey('contractAccrualPeriods'),
        queryFn: fetchContractAccrualPeriods,
        ...queryParams,
      },
      {
        queryKey: queryKey('contract'),
        queryFn: fetchContract,
        ...queryParams,
      },
      {
        queryKey: queryKey('configurations'),
        queryFn: fetchVacationsConfiguration,
        ...queryParams,
      },
    ],
  });
  const [contractAccrualPeriods, contract, configurations] = results;
  const isLoading = results.some((q) => q.isLoading);

  const goToVacationsList = (tab?: string) => navigate(`/vacations?tab=${tab}`);
  const onSubmit = () => isValid && setIsOpen(true);
  const onCancel = () => setIsOpen(false);

  const [formInputs, setFormInputs] = useState<IVacationFormInputs>();

  const currentAccrualPeriods = getCurrentAccrualPeriods(
    contractAccrualPeriods?.data?.accrualPeriods,
  );
  const vacationsConfig = getVacationsConfiguration(
    currentAccrualPeriods,
    configurations.data,
    !!formInputs?.daysSold,
  );
  const startDate = vacationsConfig?.minDate?.format('YYYY-MM-DD');

  const vacationsForm = useFormFromDefinition(
    [
      {
        label: 'Data de início',
        type: z.string(),
        name: 'startDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
        value: startDate,
        extraProps: {
          minDate: vacationsConfig.minDate,
          maxDate: vacationsConfig.maxDate,
          shouldDisableDate: vacationsConfig.disableWeekends,
        },
      },
      {
        label: 'Dias de férias',
        type: z
          .number()
          .min(vacationsConfig.atLeast5DaysEnabled ? 5 : 1)
          .max(
            vacationsConfig.daysAvailableEnabled
              ? vacationsConfig.remainingDaysAvailable
              : Infinity,
          ),
        name: 'vacationDays',
        uiType: UI_TYPE.TEXT_NUMBER,
      },
      {
        label: 'Abono pecuniário',
        type: z.boolean().default(false),
        name: 'daysSold',
        uiType: UI_TYPE.SELECT_RADIO_BOOLEAN,
        options: [
          {
            value: true,
            label: 'Sim',
            disabled: !vacationsConfig?.canSellVacation,
          },
          {
            value: false,
            label: 'Não',
            selected: true,
            disabled: !vacationsConfig?.canSellVacation,
          },
        ],
      },
      {
        label: 'Adiantamento de 50% do 13º',
        type: z.boolean().default(false),
        name: 'thirteenthAdvance',
        uiType: UI_TYPE.SELECT_RADIO_BOOLEAN,
        options: [
          {
            value: true,
            label: 'Sim',
            disabled: !vacationsConfig?.isThirteenthSalaryAdvanceEligible,
          },
          {
            value: false,
            label: 'Não',
            selected: true,
            disabled: !vacationsConfig?.isThirteenthSalaryAdvanceEligible,
          },
        ],
      },
    ],
    {
      id: 'newvacation',
      persistLocal: false,
      useNewParser: true,
      onSubmit,
    },
  );

  const inputs = useMemo(
    () => ({
      organizationId,
      contractId,
      sequence: contractAccrualPeriods?.data?.nextSequence,
      accrualPeriodStart: currentAccrualPeriods?.startDate,
      startDate: vacationsForm?.payloadValue?.startDate as string,
      vacationDays: vacationsForm.payloadValue?.vacationDays as number,
      daysSold: !!vacationsForm.payloadValue?.daysSold,
      thirteenthAdvance: !!vacationsForm.payloadValue?.thirteenthAdvance,
    }),
    [
      organizationId,
      contractId,
      contractAccrualPeriods?.data?.nextSequence,
      currentAccrualPeriods,
      vacationsForm.payloadValue?.startDate,
      vacationsForm.payloadValue?.vacationDays,
      vacationsForm.payloadValue?.daysSold,
      vacationsForm.payloadValue?.thirteenthAdvance,
    ],
  );

  useEffect(() => {
    setFormInputs(inputs);
  }, [inputs]);

  const outputsQueryResult = useQueryVacationFormOutputs({
    inputs,
  });

  const isValid = outputsQueryResult.isSuccess;
  const isValidating = outputsQueryResult.isFetching;

  const onConfirm = () => {
    setIsSubmitting(true);
    return fetchPostVacationsSchedule(parseFormPayloadToPayrolInput(inputs))
      .then(({ sequence }) =>
        pollingSearch({
          organizationId,
          contractId,
          id: `${organizationId}|${contractId}|${sequence}`,
          vacationsScheduleStatusesList: [
            vacationsScheduleStatuses.waitingApproval,
          ],
        }),
      )
      .then(() => {
        setIsSubmitting(false);
        showSnackbar({
          isOpen: true,
          Message: 'Férias agendadas com sucesso',
          StartAdornment: <CheckCircle />,
          hasCloseAction: true,
        });
        goToVacationsList('requests');
      })
      .catch(() => {
        setIsSubmitting(false);
        showSnackbar({
          isOpen: true,
          Message: 'Ocorreu um erro ao agendar as férias',
          variant: 'error',
          hasCloseAction: true,
        });
      });
  };

  if (isLoading) {
    return <VacationFormSkeleton />;
  }
  return (
    <Box data-testid="container" height="100%">
      <Box height="100%">
        <Box display="flex" minHeight="700px" height="100%">
          <Box
            data-testid="vacations-left-side"
            width="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            px={7}
          >
            <Box>
              <Box data-testid="vacations-title" display="flex" mb={3}>
                <Box
                  component={BeachAccessOutlined}
                  height={30}
                  width="auto"
                  mr={1}
                />

                <Typography variant="h2">Agendar férias</Typography>
              </Box>
              <Box px={2} data-testid="vacations-form">
                <Form
                  metaForm={vacationsForm.metaForm}
                  payloadForm={vacationsForm.payloadForm}
                />
                <Form.Layout>
                  <Form.Field for={vacationsForm.fields.startDate} />
                  <Form.Field for={vacationsForm.fields.vacationDays} />
                  <Form.Field for={vacationsForm.fields.daysSold} />
                  <Form.Field for={vacationsForm.fields.thirteenthAdvance} />
                </Form.Layout>
              </Box>
            </Box>
          </Box>

          <Box
            bgcolor="#F7F7F8"
            data-testid="vacations-right-side"
            width="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            px={7}
          >
            <Box data-testid="vacations-output">
              {isValidating ? (
                <Skeleton variant="rounded" height={380} width="100%" />
              ) : (
                <>
                  <FormGlobalMessages error={outputsQueryResult.error} />
                  <VacationFormOverview
                    contract={contract.data}
                    contractAccrualPeriods={contractAccrualPeriods.data}
                    inputs={inputs}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>

        <ActionBar>
          <ActionBar.Action
            variantSemantic="secondary"
            onClick={() => goToVacationsList('people')}
          >
            <ActionBar.Action.Adornment>
              <ArrowBack />
            </ActionBar.Action.Adornment>
            Voltar
          </ActionBar.Action>
          <ActionBar.Action
            sx={{ minWidth: '157px' }}
            isLoading={isValidating}
            type="submit"
            form={vacationsForm.id}
          >
            {'Agendar férias'}
          </ActionBar.Action>
        </ActionBar>
      </Box>

      <ConfirmModal
        open={isOpen}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Box>
  );
}

function FormGlobalMessages({ error }: { error: any }) {
  const errors = isObject(error?.stack?.details)
    ? Object.keys(error?.stack?.details)
    : [];

  if (errors.length === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        background: '#FEF7F1',
        borderRadius: '8px',
        padding: '12px',
        marginBottom: '22px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          alignItems: 'center',
        }}
      >
        <Warning
          sx={{
            paddingLeft: '12px',
            width: '16px',
            fill: '#93500B',
            flex: '0 0 auto',
          }}
        />
        <Box
          component="ul"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            padding: 0,
            paddingInlineStart: '1em',
            margin: 0,
          }}
        >
          {errors.map((key) => {
            const errorKey = key.split('cvs/')[1] as CreateScheduleRule;
            return (
              <Typography key={errorKey} component="li" variant="caption">
                {scheduleErrorMessages[errorKey].description}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

const VacationFormSkeleton = () => {
  return (
    <Box>
      <Box data-testid="container" height="100%">
        <Box height="100%">
          <Box display="flex" minHeight="700px" height="100%">
            <Box
              data-testid="vacations-left-side"
              width="50%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              px={7}
            >
              <Box>
                <Box data-testid="vacations-title" display="flex" mb={3}>
                  <Skeleton width="200px" height="50px" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  px={2}
                  data-testid="vacations-form"
                >
                  {Array.from({ length: 5 }).map((_, i) => (
                    <Box mb={3} key={i}>
                      <Skeleton variant="text" width="150px" height={24} />
                      <Skeleton width="100%" height={50} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              data-testid="vacations-left-side"
              width="50%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              px={7}
              bgcolor="#F7F7F8"
              height="100vh"
            >
              <Skeleton variant="rounded" height={380} width="100%" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
