import { Mapper } from './mapper';

export const Paises = Mapper.from({
  13: 'Afeganistão',
  17: 'Albânia, República da',
  23: 'Alemanha',
  31: 'Burkina Faso',
  37: 'Andorra',
  40: 'Angola',
  43: 'Antígua e Barbuda',
  53: 'Arábia Saudita',
  59: 'Argélia',
  63: 'Argentina',
  64: 'Armênia, República da',
  69: 'Austrália',
  72: 'Áustria',
  73: 'Azerbaijão, República do',
  77: 'Bahamas, Ilhas',
  80: 'Bahrein, Ilhas',
  81: 'Bangladesh',
  83: 'Barbados',
  85: 'Belarus, República da',
  87: 'Bélgica',
  88: 'Belize',
  93: 'Mianmar (Birmânia)',
  97: 'Bolívia, Estado Plurinacional da',
  98: 'Bósnia-Herzegovina, República da',
  101: 'Botsuana',
  105: 'Brasil',
  108: 'Brunei',
  111: 'Bulgária, República da',
  115: 'Burundi',
  119: 'Butão',
  127: 'Cabo Verde, República de',
  137: 'Cayman, Ilhas',
  141: 'Camboja',
  145: 'Camarões',
  149: 'Canadá',
  153: 'Cazaquistão, República do',
  154: 'Catar',
  158: 'Chile',
  160: 'China, República Popular da',
  163: 'Chipre',
  169: 'Colômbia',
  173: 'Comores, Ilhas',
  177: 'Congo',
  183: 'Cook, Ilhas',
  187: 'Coreia (do Norte), Rep. Pop. Democrática da',
  190: 'Coreia (do Sul), República da',
  193: 'Costa do Marfim',
  195: 'Croácia, República da',
  196: 'Costa Rica',
  198: 'Kuwait',
  199: 'Cuba',
  229: 'Benim',
  232: 'Dinamarca',
  235: 'Dominica, Ilha',
  239: 'Equador',
  240: 'Egito',
  243: 'Eritreia',
  244: 'Emirados Árabes Unidos',
  245: 'Espanha',
  246: 'Eslovênia, República da',
  247: 'Eslovaca, República',
  249: 'Estados Unidos',
  251: 'Estônia, República da',
  253: 'Etiópia',
  267: 'Filipinas',
  271: 'Finlândia',
  275: 'França',
  281: 'Gabão',
  285: 'Gâmbia',
  289: 'Gana',
  291: 'Geórgia, República da',
  293: 'Gibraltar',
  297: 'Granada',
  301: 'Grécia',
  317: 'Guatemala',
  329: 'Guiné',
  331: 'Guiné Equatorial',
  334: 'Guiné-Bissau',
  337: 'Guiana',
  341: 'Haiti',
  345: 'Honduras',
  355: 'Hungria, República da',
  357: 'Iêmen',
  361: 'Índia',
  365: 'Indonésia',
  369: 'Iraque',
  372: 'Irã, República Islâmica do',
  375: 'Irlanda',
  379: 'Islândia',
  383: 'Israel',
  386: 'Itália',
  391: 'Jamaica',
  399: 'Japão',
  403: 'Jordânia',
  411: 'Kiribati',
  420: 'Laos, Rep. Pop. Democrática do',
  426: 'Lesoto',
  427: 'Letônia, República da',
  431: 'Líbano',
  434: 'Libéria',
  438: 'Líbia',
  440: 'Liechtenstein',
  442: 'Lituânia, República da',
  445: 'Luxemburgo',
  449: 'Macedônia do Norte',
  450: 'Madagascar',
  455: 'Malásia',
  458: 'Malavi',
  461: 'Maldivas',
  464: 'Mali',
  467: 'Malta',
  474: 'Marrocos',
  476: 'Marshall, Ilhas',
  485: 'Maurício',
  488: 'Mauritânia',
  493: 'México',
  494: 'Moldávia, República da',
  495: 'Mônaco',
  497: 'Mongólia',
  498: 'Montenegro',
  499: 'Micronésia',
  505: 'Moçambique',
  507: 'Namíbia',
  508: 'Nauru',
  517: 'Nepal',
  521: 'Nicarágua',
  525: 'Níger',
  528: 'Nigéria',
  538: 'Noruega',
  545: 'Papua Nova Guiné',
  548: 'Nova Zelândia',
  551: 'Vanuatu',
  556: 'Omã',
  573: 'Países Baixos (Holanda)',
  575: 'Palau',
  576: 'Paquistão',
  578: 'Palestina',
  580: 'Panamá',
  586: 'Paraguai',
  589: 'Peru',
  603: 'Polônia, República da',
  607: 'Portugal',
  623: 'Quênia',
  625: 'Quirguiz, República',
  628: 'Reino Unido',
  640: 'República Centro-Africana',
  647: 'República Dominicana',
  665: 'Zimbábue',
  670: 'Romênia',
  675: 'Ruanda',
  676: 'Rússia, Federação da',
  677: 'Salomão, Ilhas',
  687: 'El Salvador',
  690: 'Samoa',
  695: 'São Cristóvão e Neves, Ilhas',
  697: 'San Marino',
  705: 'São Vicente e Granadinas',
  715: 'Santa Lúcia',
  720: 'São Tomé e Príncipe, Ilhas',
  728: 'Senegal',
  731: 'Seychelles',
  735: 'Serra Leoa',
  737: 'Sérvia',
  741: 'Singapura',
  744: 'Síria, República Árabe da',
  748: 'Somália',
  750: 'Sri Lanka',
  754: 'eSwatini (Essuatíni, Suazilândia)',
  756: 'África do Sul',
  759: 'Sudão',
  760: 'Sudão do Sul',
  764: 'Suécia',
  767: 'Suíça',
  770: 'Suriname',
  772: 'Tadjiquistão, República do',
  776: 'Tailândia',
  780: 'Tanzânia, República Unida da',
  783: 'Djibuti',
  788: 'Chade',
  791: 'Tcheca, República',
  795: 'Timor Leste',
  800: 'Togo',
  810: 'Tonga',
  815: 'Trinidad e Tobago',
  820: 'Tunísia',
  824: 'Turcomenistão, República do',
  827: 'Turquia',
  828: 'Tuvalu',
  831: 'Ucrânia',
  833: 'Uganda',
  845: 'Uruguai',
  847: 'Uzbequistão, República do',
  848: 'Vaticano, Estado da Cidade do',
  850: 'Venezuela',
  858: 'Vietnã',
  870: 'Fiji',
  888: 'Congo, República Democrática do',
  890: 'Zâmbia',
});
