import { BRTipoPix } from '@octopus/api';

type event = {
  type: string;
  payload: object;
};

export function findEventByType(
  list: event[],
  type: string,
): object | undefined {
  const record = list.find((item) => item.type === type);
  return record?.payload;
}

export const pixKeyTypes: { [key in BRTipoPix]: key } = {
  cpf: 'cpf',
  cnpj: 'cnpj',
  email: 'email',
  celular: 'celular',
  aleatoria: 'aleatoria',
} as const;
export type PixKeyType = keyof typeof pixKeyTypes;

export const pixKeyLabels: { [key in PixKeyType]: string } = {
  [pixKeyTypes.cpf]: 'CPF',
  [pixKeyTypes.cnpj]: 'CNPJ',
  [pixKeyTypes.email]: 'E-mail',
  [pixKeyTypes.celular]: 'Celular',
  [pixKeyTypes.aleatoria]: 'Aleatória',
};

export function parsePixKey(key: string | undefined): PixKeyType | undefined {
  if (!key) {
    return undefined;
  }

  const checks: Array<[PixKeyType, RegExp]> = [
    [pixKeyTypes.cpf, /^\d{3}\.\d{3}\.\d{3}-\d{2}$/],
    [pixKeyTypes.cnpj, /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/],
    [pixKeyTypes.email, /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/],
    [
      pixKeyTypes.aleatoria,
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
    ],
    [pixKeyTypes.celular, /^\(?[0-9]{2}\)?(\s|-)?[0-9]{5}-?[0-9]{4}$/],
  ];

  for (const [val, regex] of checks) {
    if (regex.test(key)) {
      return val;
    }
  }

  return undefined;
}
