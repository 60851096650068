import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { IFormDefinition, UI_TYPE } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { AdmissionFormState, FormStepDefinition } from '../types';

import { deficienciaSchema } from './deficienciaSchema';

const getDeficienciaFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const { pessoaComDeficiencia } = formState;

  const temDeficiencia =
    pessoaComDeficiencia?.deficiencias?.length > 0 ||
    pessoaComDeficiencia?.descDeficiencia != null;

  return [
    {
      label: 'Você é portador(a) de alguma deficiência?',
      type: deficienciaSchema,
      uiType: UI_TYPE.SELECT,
      name: 'pessoaComDeficiencia',
      fieldsOptions: [
        {
          label: 'Não',
          selected: !temDeficiencia,
          fields: [
            {
              label: '-',
              hidden: true,
              type: z.string().optional(),
              name: 'deficiencia_hidden',
              value: 'true',
              uiType: UI_TYPE.TEXT,
            },
          ],
        },
        {
          label: 'Sim',
          selected: temDeficiencia,
          fields: [
            {
              label: 'Quais tipos?',
              type: z.array(z.string()).optional(),
              name: 'deficiencias',
              uiType: UI_TYPE.SELECT_CHECKBOX_LIST,
              options: [
                {
                  value: 'defAuditiva',
                  label: 'Auditiva',
                  selected:
                    pessoaComDeficiencia?.deficiencias?.indexOf(
                      'defAuditiva',
                    ) >= 0,
                },
                {
                  value: 'defFisica',
                  label: 'Fisica',
                  selected:
                    pessoaComDeficiencia?.deficiencias?.indexOf('defFisica') >=
                    0,
                },
                {
                  value: 'defIntelectual',
                  label: 'Intelectual',
                  selected:
                    pessoaComDeficiencia?.deficiencias?.indexOf(
                      'defIntelectual',
                    ) >= 0,
                },
                {
                  value: 'defMental',
                  label: 'Mental',
                  selected:
                    pessoaComDeficiencia?.deficiencias?.indexOf('defMental') >=
                    0,
                },
                {
                  value: 'defVisual',
                  label: 'Visual',
                  selected:
                    pessoaComDeficiencia?.deficiencias?.indexOf('defVisual') >=
                    0,
                },
              ],
            },
            {
              label: 'Descrição',
              type: z.string().optional(),
              name: 'descDeficiencia',
              uiType: UI_TYPE.TEXT,
              value: pessoaComDeficiencia?.descDeficiencia,
              placeholder: 'Ex: Daltonismo tricromático',
              info: 'Informe uma descrição resumida, com virgula para multiplas, exemplo: Daltonismo, Dificultade motora',
            },
          ],
        },
      ],
    },
  ];
};

export const getDeficienciaFields = ({
  formState,
  deficienciaFormSteps,
}: {
  formState: AdmissionFormState;
  deficienciaFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getDeficienciaFieldDefinition({
    formState,
  }),
  options: {
    id: admissionDraftFormSteps.deficiencias,
    title: 'Pessoa com deficiência',
    completed: deficienciaFormSteps?.completed,
    reviewed: deficienciaFormSteps?.reviewed,
  },
});
