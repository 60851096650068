import { Box, Typography } from '@mui/material';

import { CompanyEntry, OrganizationEntry } from '@octopus/api';
import { capitalize, formatCNPJ } from '@octopus/formatters';

import { OrganizationAvatar } from '../OrganizationAvatar';

export type EmployerInfoProps = {
  organization: OrganizationEntry;
  company: CompanyEntry;
};

export function EmployerInfo({ organization, company }: EmployerInfoProps) {
  return (
    <Box
      display="flex"
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
          px: 2,
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          px: 2.5,
          py: 1,
          gap: 1,
        },
      })}
      data-testid="payslip-employer-info"
    >
      <OrganizationInfo organization={organization} />
      <CompanyInfo company={company} />
    </Box>
  );
}

export function OrganizationInfo({
  organization,
}: {
  organization: OrganizationEntry;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1.5}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          width: '50%',
        },
      })}
    >
      <OrganizationAvatar name={organization.name} logo={organization.logo} />
      <Typography variant="h2">{organization.name}</Typography>
    </Box>
  );
}

export function CompanyInfo({ company }: { company: CompanyEntry }) {
  if (!company.br) {
    return null;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          width: '50%',
        },
      })}
      gap={0.25}
    >
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {formatCNPJ(company.br.cnpj)}
      </Typography>
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {capitalize(company.br.endereco.logradouro)}{' '}
        {company.br.endereco.numero}
        {company.br.endereco.complemento
          ? `, ${company.br.endereco.complemento}`
          : ''}
      </Typography>
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {capitalize(company.br.endereco.bairro)},{' '}
        {capitalize(company.br.endereco.municipio)} - {company.br.endereco.uf}
      </Typography>
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {company.br.endereco.cep}
      </Typography>
    </Box>
  );
}
