import { z } from 'zod';

import { getDerivationsType } from './types/getDerivationsType';

type TFieldPrimitiveSimpleTypes = z.infer<typeof primitiveTypes>;

const stringSimpleType = z.instanceof(z.ZodString);
const numberSimpleType = z.instanceof(z.ZodNumber);
const booleanSimpleType = z.instanceof(z.ZodBoolean);
const literalSimpleType = z.instanceof(z.ZodLiteral);
const enumSimpleType = z.instanceof(z.ZodEnum);
const tupleSimpleType = z.instanceof(z.ZodTuple);

const stringDerivationType = getDerivationsType(stringSimpleType);
const numberDerivationType = getDerivationsType(numberSimpleType);
const booleanDerivationType = getDerivationsType(booleanSimpleType);
const literalDerivationType = getDerivationsType(literalSimpleType);
const enumDerivationType = getDerivationsType(enumSimpleType);
const tupleDerivationType = getDerivationsType(tupleSimpleType);

export const stringType = z.union([stringSimpleType, stringDerivationType]);
export const numberType = z.union([numberSimpleType, numberDerivationType]);
export const booleanType = z.union([booleanSimpleType, booleanDerivationType]);
export const literalType = z.union([literalSimpleType, literalDerivationType]);
export const enumType = z.union([enumSimpleType, enumDerivationType]);
export const tupleType = z.union([tupleSimpleType, tupleDerivationType]);

export const primitiveTypes = z.union([
  stringType,
  numberType,
  booleanType,
  literalType,
  enumType,
  tupleType,
]);

const collectionSimpleTypes = z.union([
  z
    .instanceof(z.ZodArray)
    .refine(
      (
        arraySchema,
      ): arraySchema is InstanceType<
        typeof z.ZodArray<TFieldPrimitiveSimpleTypes>
      > => {
        return primitiveTypes.safeParse(arraySchema._def.type).success;
      },
    ),
  z
    .instanceof(z.ZodSet)
    .refine(
      (
        setType,
      ): setType is InstanceType<
        typeof z.ZodSet<TFieldPrimitiveSimpleTypes>
      > => {
        return primitiveTypes.safeParse(setType._def.valueType).success;
      },
    ),
]);

const collectionDerivationTypes = getDerivationsType(collectionSimpleTypes);

export const collectionTypes = collectionSimpleTypes.or(
  collectionDerivationTypes,
);

export type TFieldPrimitiveTypes = z.infer<typeof primitiveTypes>;
export type TFieldCollectionTypes = z.infer<typeof collectionTypes>;
