import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type ObservacoesRecordData = {
  observacao: string;
};

export type ObservacoesRecordProps = BaseRecordProps<ObservacoesRecordData>;

export function ObservacoesRecord(props: ObservacoesRecordProps) {
  const {
    data: { observacao },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  return (
    <Record title="Observações" edit={editRecordProps}>
      <RecordEntry
        label="Observações"
        edit={{
          type: 'text',
          editing,
          onChange: (value) =>
            updateData(() => ({
              observacao: value,
            })),
          value: formData.observacao,
          hasError: hasError('br/observacao'),
        }}
      >
        {observacao}
      </RecordEntry>
    </Record>
  );
}
