import { Box, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import {
  TFieldInputRenderProps,
  TFieldRenderProps,
  TFieldsetRenderProps,
} from '../parseField/types';

import { Label } from './commons/Label';

type FieldSelectRadioBoolean = {
  field: TFieldRenderProps &
    TFieldsetRenderProps & {
      fields: [
        TFieldRenderProps & TFieldInputRenderProps,
        TFieldRenderProps & TFieldInputRenderProps,
      ];
    };
  sx?: SxProps<Theme>;
};

export function FieldSelectRadioBoolean(props: FieldSelectRadioBoolean) {
  const { field } = props;

  const [radioFieldA, radioFieldB] = field.fields;

  const commonOptionWrapperStyles = {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    flex: '1 0 0',
  };

  const commonLabelStyles = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '8px 16px',
    borderRadius: '8px',
    gap: '10px',
    cursor: 'pointer',

    border: '1px solid var(--option-border-color)',
    background: 'var(--option-background)',

    '--option-background': 'transparent',
    '--option-border-color': 'transparent',

    '&:focus-visible': {
      outline: 0,
      '--option-border-color':
        'var(--FieldSelectRadioBoolean-focused-border-color)',
    },
  };

  const selectedRadioStyles = {
    '&:checked + label': {
      '--option-background': '#fff',
      '&:not(:focus-visible)': {
        '--option-border-color': '#ededed',
      },
    },
  };

  const onKeyDownSelect = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key !== ' ' && event.key !== 'Enter') return;
    const fieldElement = event.currentTarget;
    fieldElement.click();
    event.preventDefault();
  };

  return (
    <Box
      component="fieldset"
      {...field.props}
      tabIndex={0}
      sx={{
        border: 'none',
        padding: 0,
        margin: 0,
        outline: 0,
        '--FieldSelectRadioBoolean-default-border-color': '#ededed',
        '--FieldSelectRadioBoolean-focused-border-color': (theme) =>
          theme.palette.strokes.info,

        '--wrapper-border-color':
          'var(--FieldSelectRadioBoolean-default-border-color)',
        '&:focus-visible': {
          '--wrapper-border-color':
            'var(--FieldSelectRadioBoolean-focused-border-color)',
        },
      }}
    >
      <Label field={field} />
      <Box
        sx={{
          display: 'flex',
          height: '48px',
          padding: '2px 6px',
          boxSizing: 'border-box',
          alignItems: 'center',
          gap: '12px',
          alignSelf: 'stretch',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'var(--wrapper-border-color)',
          background: '#F7F7F8',
          fontSize: '12px',
          '&:hover': {
            boxShadow:
              '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Box
          {...radioFieldA.props}
          sx={{
            ...commonOptionWrapperStyles,
          }}
        >
          <Box
            component="input"
            {...radioFieldA.input.props}
            sx={{
              ...selectedRadioStyles,
              display: 'none',
            }}
          />
          <Typography
            component="label"
            variant="caption"
            htmlFor={radioFieldA.label.props.htmlFor}
            tabIndex={0}
            onKeyDown={onKeyDownSelect}
            sx={{
              ...commonLabelStyles,
            }}
          >
            {radioFieldA.label.textContent}
          </Typography>
        </Box>

        <Box
          {...radioFieldB.props}
          sx={{
            ...commonOptionWrapperStyles,
          }}
        >
          <Box
            component="input"
            {...radioFieldB.input.props}
            sx={{
              ...selectedRadioStyles,
              display: 'none',
            }}
          />
          <Typography
            component="label"
            variant="caption"
            htmlFor={radioFieldB.label.props.htmlFor}
            tabIndex={0}
            onKeyDown={onKeyDownSelect}
            sx={{
              ...commonLabelStyles,
            }}
          >
            {radioFieldB.label.textContent}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

FieldSelectRadioBoolean.uiType = UI_TYPE.SELECT_RADIO_BOOLEAN;
FieldSelectRadioBoolean.canRender = (
  field: TFieldRenderProps,
): field is FieldSelectRadioBoolean['field'] => {
  const isFieldGroup = 'fields' in field;
  if (!isFieldGroup || field.fields.length < 2) return false;

  const fieldA = field.fields[0];
  const fieldB = field.fields[1];

  if (!fieldA || !fieldB) return false;

  const isRadioInputFieldsTuple =
    'input' in fieldA &&
    'input' in fieldB &&
    fieldA.input.props.type === 'radio' &&
    fieldB.input.props.type === 'radio';

  if (!isRadioInputFieldsTuple) return false;

  const isBooleanField =
    (fieldA.input.props.value === 'true' &&
      fieldB.input.props.value === 'false') ||
    (fieldA.input.props.value === 'false' &&
      fieldB.input.props.value === 'true');

  return isBooleanField;
};
