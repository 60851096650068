import { useEffect } from 'react';

import { Cancel } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { ApproveAllPayrollsError, useApprovePayroll } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

export function ApprovalConfirmationModal({
  organizationId,
  companyId,
  payrollId,
  close,
}: {
  organizationId: string;
  companyId: string;
  payrollId: string;
  close: () => void;
}) {
  const { mutate, isLoading, isError, isSuccess, error } = useApprovePayroll();
  const approvePayrollCalback = () => {
    mutate({
      pathParams: {
        organizationId: organizationId,
        companyId: companyId,
        payrollId: payrollId,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LoadingScene />;
  }

  if (isError) {
    return <ErrorScene error={error} close={close} />;
  }

  if (isSuccess) {
    return null;
  }

  return (
    <InitialScene
      approvePayroll={approvePayrollCalback}
      showInfo={true}
      close={close}
    />
  );
}

function ErrorScene({
  error,
  close,
}: {
  error: ApproveAllPayrollsError;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro na aprovação</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível aprovar a folha, certifique-se de que não existem
          pendências e tente novamente.
        </Typography>
        {error && <pre>{JSON.stringify(error.payload, null, 2)}</pre>}
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Aprovando folha, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}

function InitialScene({
  approvePayroll,
  showInfo: showWarning,
  close,
}: {
  approvePayroll: () => void;
  showInfo: boolean;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          alignItems="flex-start"
          mb={0}
        >
          <Box gap={3}>
            <Typography variant="h1" fontWeight={700}>
              Aprovação de Rescisão
            </Typography>

            <Box gap={3} mt={3} pl=".42rem">
              <Typography fontSize="16px" fontWeight={700}>
                Ao confirmar, o que acontece em seguida:
              </Typography>

              <Box display="flex" flexDirection="column">
                <Box
                  component="ul"
                  pl={2}
                  gap={2}
                  display="flex"
                  flexDirection="column"
                  sx={{
                    listStylePosition: 'outside',
                    paddingInlineStart: '1.5rem',
                  }}
                >
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Geração de kit demissional
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      Iremos preparar o kit demissional, logo você terá acesso e
                      poderá fazer o envio do recibo, no próprio app de
                      rescisão.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Criação de Arquivo de Pagamentos
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      Acesse o app de Pagamentos para fazer o download do
                      arquivo.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      fontWeight={500}
                      variant="body1"
                      fontSize="16px"
                    >
                      Será desligado na plataforma
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="caption"
                      fontSize="12px"
                    >
                      O desligamento será automático e ocorrerá no dia informado
                      como ultimo ás 06:59
                    </Typography>
                  </li>
                </Box>
              </Box>
            </Box>
            {showWarning && (
              <Box
                display="flex"
                sx={(theme) => ({
                  backgroundColor: theme.palette.strokes.secondary,
                })}
                px={3}
                py={2}
                gap={2}
                height="48px"
                width="490px"
                borderRadius={1}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <InfoIcon
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                    })}
                    height="16px"
                    width="16px"
                  />
                </Box>

                <Typography
                  fontWeight={500}
                  variant="caption"
                  fontSize="12px"
                  sx={(theme) => ({
                    color: theme.palette.text.primary,
                  })}
                >
                  O desligamento da plataforma bloqueia o acesso ao colaborador
                  de informações privilegiadas, porém não restringe o acesso as
                  seus holerites e recibos, fornecidos através do acesso no
                  email privado do colaborador.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payrolls-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={approvePayroll}
          data-testid="confirm-approve-payrolls-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </>
  );
}
