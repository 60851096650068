import { FieldMetadata, getInputProps } from '@conform-to/react';

import {
  IFieldSingleValueWithoutOptionsDefinition,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from './types';

export function parseFieldCheckboxCard(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  fieldDefinition: IFieldSingleValueWithoutOptionsDefinition,
  fieldsetName = '',
): TFieldRenderProps & TFieldInputRenderProps {
  const inputProps = getInputProps(fieldMetadata, {
    type: 'checkbox',
  });

  const currentValue =
    (fieldMetadata.dirty && fieldMetadata.value?.toString()) ||
    fieldDefinition.value?.toString() ||
    '';

  const defaultChecked = currentValue && currentValue === 'on';

  const fieldRenderProps: TFieldRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName,
    props: {
      key: inputProps.id,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: inputProps.id,
      },
    },
    input: {
      props: {
        ...inputProps,
        ...(defaultChecked && { defaultChecked }),
        placeholder: fieldDefinition.placeholder,
        info: fieldDefinition.info,
      },
    },
  };

  return fieldRenderProps;
}
