import { FieldMetadata } from '@conform-to/react';

import {
  IFieldCollectionValueWithOptionsDefinition,
  IFieldSingleValueWithOptionsDefinition,
  IFieldSingleValueWithoutOptionsDefinition,
} from '@octopus/libs/forms';

export function parseBranchingErrors({
  fieldMetadata,
  fieldDefinition,
  isInvalid,
  fieldsetName = '',
  payloadFormErrors = {},
}: {
  fieldMetadata: FieldMetadata<any>;
  fieldDefinition:
    | IFieldSingleValueWithoutOptionsDefinition
    | IFieldCollectionValueWithOptionsDefinition
    | IFieldSingleValueWithOptionsDefinition;
  isInvalid: boolean;
  fieldsetName: string;
  payloadFormErrors: Record<string, string[]>;
}): string[] {
  let errors = fieldDefinition.errors;
  const fieldName = fieldMetadata.name;

  if (fieldsetName && !errors && isInvalid) {
    errors = payloadFormErrors[fieldName];
  }

  return errors;
}
