import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { Box, Typography } from '@mui/material';

export type ContractDetailsTitleProps = {
  title: string;
  right?: React.ReactElement;
};

export function ContractDetailsTitle({
  title,
  right = undefined,
}: ContractDetailsTitleProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pb={2}
      borderBottom="1px solid #EDEDED"
      data-testid="contract-title"
    >
      <Typography
        variant="h2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <BadgeOutlinedIcon
          sx={{
            width: '24px',
            height: '24px',
          }}
        />{' '}
        {title}
      </Typography>
      {right}
    </Box>
  );
}
