import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';

import { useField } from '@conform-to/react';

import { Search } from '@mui/icons-material';
import { Autocomplete, Box, SxProps, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import * as CustomOptions from '../Option';
import {
  TFieldInputRenderProps,
  TFieldRenderProps,
  TFieldSelectRenderProps,
  TFieldsetRenderProps,
} from '../parseField/types';

import { ErrorLabel } from './commons/ErrorLabel';
import { Label } from './commons/Label';

type FieldSelectAutoComplete = {
  field: TFieldRenderProps &
    TFieldsetRenderProps & {
      fields: [
        TFieldRenderProps & TFieldInputRenderProps,
        TFieldRenderProps & TFieldInputRenderProps,
        TFieldRenderProps & TFieldSelectRenderProps,
        TFieldRenderProps & TFieldInputRenderProps,
      ];
    };
  sx?: SxProps<Theme>;
};

const LOADING_OPTION_PLACEHOLDER: TFieldSelectRenderProps['select']['options'][number] =
  {
    textContent: 'Carregando...',
    props: {
      key: 'loading',
      value: '',
    },
  };

export function FieldSelectAutoComplete(props: FieldSelectAutoComplete) {
  const { field } = props;
  const payloadField = props.field.fields[0];
  const autoCompleteField = props.field.fields[1];
  const autoCompleteOptions = props.field.fields[2];
  const autoCompleteTimestamp = props.field.fields[3];

  const [payloadFieldMeta, payloadFormMeta] = useField(
    payloadField.input.props.name,
    {
      formId: payloadField.input.props.form,
    },
  );

  const [autoCompleteOptionsMeta] = useField(
    autoCompleteOptions.select.props.name,
    {
      formId: autoCompleteOptions.select.props.form,
    },
  );

  const [autoCompleteTimestampMeta] = useField(
    autoCompleteTimestamp.input.props.name,
    {
      formId: autoCompleteTimestamp.input.props.form,
    },
  );

  const [autoCompleteFieldMeta] = useField(autoCompleteField.input.props.name, {
    formId: autoCompleteField.input.props.form,
  });

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const [_isLoadingOptionsTransitionPending, startLoadingOptionsTransition] =
    useTransition();

  const onChangeAutoCompleteInput = () => {
    startLoadingOptionsTransition(() => {
      setIsLoadingOptions(true);
    });
  };

  const currentAutoCompleteOption: TFieldSelectRenderProps['select']['options'][number] =
    useMemo(() => {
      const currentValue =
        autoCompleteFieldMeta.value ||
        autoCompleteField.input.props.defaultValue;

      const currentKey = payloadFieldMeta.value || currentValue;

      return (
        Boolean(currentKey && currentValue) && {
          textContent: currentValue?.toString(),
          props: {
            key: currentKey?.toString(),
            value: currentKey?.toString(),
          },
        }
      );
    }, [
      payloadFieldMeta.value,
      autoCompleteFieldMeta.value,
      autoCompleteField.input.props.defaultValue,
    ]);

  useEffect(() => {
    const value = currentAutoCompleteOption.props?.value;
    if (
      value &&
      (payloadFormMeta.value == null ||
        payloadFormMeta.value[payloadFieldMeta.name] !== value)
    ) {
      payloadFormMeta.update({
        name: payloadFieldMeta.name,
        value: value || '',
      });
    }
  }, [payloadFormMeta, payloadFieldMeta.name, currentAutoCompleteOption]);

  const autoCompleteFieldOptions: TFieldSelectRenderProps['select']['options'] =
    useMemo(() => {
      const isDefaultValueSelected =
        autoCompleteFieldMeta.value === currentAutoCompleteOption.props?.value;

      const currentOptions = (autoCompleteOptionsMeta.value ||
        []) as TFieldSelectRenderProps['select']['options'];
      const hasAutoCompleteOptions = Boolean(currentOptions.length);
      const currentOption =
        !hasAutoCompleteOptions &&
        isDefaultValueSelected &&
        currentAutoCompleteOption;
      const loadedOptions = [
        ...currentOptions,
        !isLoadingOptions && currentOption,
        isLoadingOptions && LOADING_OPTION_PLACEHOLDER,
      ];
      return loadedOptions.filter(Boolean);
    }, [
      autoCompleteOptionsMeta.value,
      autoCompleteFieldMeta.value,
      currentAutoCompleteOption,
      isLoadingOptions,
    ]);

  const autoCompleteFieldOptionsTimestampRef = useRef<string>();
  const currentSearchTimestamp = useMemo(() => {
    return autoCompleteTimestampMeta.value?.toString() || '';
  }, [autoCompleteTimestampMeta.value]);

  useEffect(() => {
    const previousAutocompleteTimestamp =
      autoCompleteFieldOptionsTimestampRef.current;
    autoCompleteFieldOptionsTimestampRef.current = currentSearchTimestamp;

    const didLoadNewOptions =
      (!currentSearchTimestamp && !previousAutocompleteTimestamp) ||
      currentSearchTimestamp !== previousAutocompleteTimestamp;

    if (isLoadingOptions && didLoadNewOptions) {
      setIsLoadingOptions(false);
    }
  }, [autoCompleteFieldOptions, currentSearchTimestamp, isLoadingOptions]);

  const hasError = field.errors?.length > 0;
  const errorMessage = hasError ? field.errors[0] : '';

  return (
    <Box {...field.props}>
      <input {...payloadField.input.props} type="hidden" />
      <Label field={autoCompleteField} />
      <Autocomplete
        disableClearable
        fullWidth
        {...(Boolean(currentAutoCompleteOption) && {
          defaultValue: currentAutoCompleteOption,
        })}
        options={autoCompleteFieldOptions}
        isOptionEqualToValue={(option, value) => {
          return option.props?.value === value.props?.value;
        }}
        autoHighlight
        clearOnBlur={false}
        filterOptions={(x) => x}
        getOptionLabel={(field) => field.textContent}
        getOptionDisabled={(option) => {
          return option.props?.key === 'loading';
        }}
        renderOption={renderOption}
        onChange={(event, selectedOption) => {
          payloadFormMeta.update({
            name: payloadFieldMeta.name,
            value: selectedOption?.props.value || '',
          });
        }}
        renderInput={(params) => {
          return (
            <TextField
              error={hasError}
              fullWidth
              {...params}
              onChange={onChangeAutoCompleteInput}
              InputProps={{
                ...params.InputProps,
                endAdornment: '',
                startAdornment: (
                  <Search
                    sx={{
                      height: '1em',
                      fill: (theme) => theme.palette.text.primary,
                    }}
                  />
                ),
                inputProps: {
                  ...params.inputProps,
                  ...autoCompleteField.input.props,
                  autoComplete: 'off',
                },
              }}
            />
          );
        }}
      />
      <ErrorLabel errorMessage={errorMessage} />
    </Box>
  );
}

FieldSelectAutoComplete.uiType = UI_TYPE.SELECT;
FieldSelectAutoComplete.canRender = (
  field: TFieldRenderProps,
): field is FieldSelectAutoComplete['field'] => {
  const isFieldset = 'fields' in field;

  if (!isFieldset || field.fields.length < 4) return false;

  // TODO properly check autcomplete on name.endWith or somethig like that
  const isAutoCompleteFieldset =
    'input' in field.fields[0] &&
    'input' in field.fields[1] &&
    'select' in field.fields[2] &&
    'input' in field.fields[3];

  return isAutoCompleteFieldset;
};

function renderOption(
  props: React.HTMLAttributes<HTMLLIElement>,
  option: TFieldSelectRenderProps['select']['options'][number],
) {
  const CustomOption = Object.values(CustomOptions).find((Option) =>
    Option.canRender(option),
  );

  if (CustomOption) {
    return (
      <CustomOption
        props={props}
        option={option as React.ComponentProps<typeof CustomOption>['option']}
      />
    );
  }

  return (
    <Box
      component="li"
      sx={{
        display: 'flex',
        justifyContent: 'stretch',
        padding: 0,
      }}
      {...props}
    >
      <Box sx={{ width: '100%' }}>{option.textContent}</Box>
    </Box>
  );
}
