import { buildAuthorizeUrl } from './client';
import { getConfig, isEnabled } from './config';
import { getMessage } from './errors';
export type { ConfigSwitch, SSOConfig, EnabledSSOConfig } from './config';
export type { SSOAuthorizeUrlParams } from './client';

const Errors = {
  getMessage,
};

const Config = {
  getConfig,
  isEnabled,
};

const Client = {
  buildAuthorizeUrl,
};

export const SSOUtils = {
  Errors,
  Config,
  Client,
};
