import { useMemo } from 'react';

import { Box, Skeleton, Typography } from '@mui/material';

import { ContractEntry, useGetJobTitleEntry } from '@octopus/api';
import {
  contractStatuses,
  getContractJobTitleId,
  getCorporateEmail,
} from '@octopus/contract-types';
import { getWorkerCategory } from '@octopus/esocial/contracts';
import { Municipios, Paises } from '@octopus/esocial/mapper';
import { Tag } from '@octopus/ui/design-system';

import WorkerCategoryTag from '../../modules/components/contracts/WorkerCategoryTag';
import {
  PersonInfoCard,
  PersonInfoCardSkeleton,
} from '../../modules/components/people/PersonInfoCard';
import { QueryResult } from '../../modules/types';

export type PersonalDataInfoProps = {
  contractQuery: QueryResult<ContractEntry>;
};

export function PersonalDataInfo({ contractQuery }: PersonalDataInfoProps) {
  const name = useMemo(() => {
    return (
      contractQuery.data?.br?.pessoa.nmSoc ??
      contractQuery.data?.br?.pessoa.nmTrab ??
      ''
    );
  }, [contractQuery.data]);
  if (contractQuery.isLoading) {
    return <PersonInfoCardSkeleton />;
  }
  return (
    <PersonInfoCard name={name}>
      <CardContent contractQuery={contractQuery} />
    </PersonInfoCard>
  );
}

function CardContent({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box
        display="flex"
        flexDirection="column"
        data-testid="person-info-card-content"
      >
        <JobTitle contractQuery={contractQuery} />
        <CityAndState contractQuery={contractQuery} />
      </Box>

      <CorporateEmail contractQuery={contractQuery}></CorporateEmail>

      <Box pt={1} gap={0.5} display={'flex'}>
        <ContractStatusTag contractQuery={contractQuery} />
        <ContractTypeTag contractQuery={contractQuery} />
      </Box>
    </Box>
  );
}

function CorporateEmail({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { data: contractInfo } = contractQuery;
  if (!contractInfo) {
    return null;
  }
  return (
    <Typography variant="caption" fontWeight="500" color={'text.secondary'}>
      {getCorporateEmail(contractInfo)}
    </Typography>
  );
}

function ContractTypeTag({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { data: contractInfo, isLoading } = contractQuery;
  if (isLoading || !contractInfo) {
    return null;
  }

  const trabalho =
    'trabalho' in contractInfo.br ? contractInfo.br.trabalho : null;

  const workerCategory = getWorkerCategory(contractInfo.contractType, trabalho);

  return <WorkerCategoryTag workerCategory={workerCategory} />;
}

function ContractStatusTag({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { data: contractInfo, isLoading } = contractQuery;
  if (isLoading || !contractInfo) {
    return null;
  }

  const configByStatus: {
    [key: string]: {
      color: 'success' | 'warning' | 'default';
      label: string;
    };
  } = {
    [contractStatuses.active]: {
      color: 'success',
      label: 'Ativo(a)',
    },
    [contractStatuses.onAdmission]: {
      color: 'warning',
      label: 'Em admissão',
    },
    [contractStatuses.onLeave]: {
      color: 'warning',
      label: 'Afastado(a)',
    },
    [contractStatuses.terminated]: {
      color: 'default',
      label: 'Inativo(a)',
    },
  };

  return (
    <Tag color={configByStatus[contractInfo.status]?.color ?? 'default'}>
      {configByStatus[contractInfo.status]?.label ?? '---'}
    </Tag>
  );
}

function JobTitle({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { data: contractInfo, isLoading } = contractQuery;
  const jobTitle = getContractJobTitleId(contractInfo);
  const { data } = useGetJobTitleEntry(
    {
      pathParams: {
        organizationId: contractInfo?.organizationId ?? '',
        jobTitleId: jobTitle ?? '',
      },
    },
    {
      enabled: !!contractInfo?.organizationId && !!jobTitle,
    },
  );
  if (isLoading || !contractInfo) {
    return null;
  }
  if (!data) {
    return <Skeleton variant="rounded" width="100%" height="16px" />;
  }
  return (
    <Typography variant="caption" fontWeight="500" lineHeight={'16px'}>
      {data.name}
      <br />
    </Typography>
  );
}

function CityAndState({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { data: personalInfo } = contractQuery;
  if (!personalInfo || !personalInfo.br.endereco) {
    return null;
  }
  let city = '';
  let state = '';
  if (personalInfo.br?.endereco) {
    if (personalInfo.br.endereco.tipo === 'brasil') {
      city = Municipios.getByCode(personalInfo.br?.endereco.codMunic) ?? '';
      state = personalInfo.br?.endereco.uf;
    } else {
      city = personalInfo.br?.endereco.nmCid;
      state = Paises.getByCode(personalInfo.br?.endereco.paisResid) ?? '';
    }
  }
  return (
    <Typography variant="caption" fontWeight="500">
      {city} - {state}
    </Typography>
  );
}
