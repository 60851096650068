export function formatMoney(
  num: string | number | undefined,
  {
    withSign,
    withCurrency = true,
  }: {
    withSign?: boolean;
    withCurrency?: boolean;
  } = {},
): string {
  if (!num) {
    num = '0';
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: withCurrency ? 'currency' : 'decimal',
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const formatted = formatter.format(+num);

  if (typeof withSign !== 'undefined') {
    if (withSign && +num >= 0) {
      return `+ ${formatted}`;
    }
    if (!withSign && +num < 0) {
      return formatted.replace('-', '');
    }
  }

  return formatted.replace('-', '- ');
}
