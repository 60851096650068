import { useMemo, useRef, useState, useTransition } from 'react';

import { Check, Search } from '@mui/icons-material';
import { Box, SxProps, TextField, Theme, Typography } from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';

import {
  TFieldInputRenderProps,
  TFieldRenderProps,
  TInputFieldsetRenderProps,
} from '../parseField/types';

import { Label } from './commons/Label';

type FieldProps = {
  field: TFieldRenderProps & TInputFieldsetRenderProps;
  sx?: SxProps<Theme>;
};
const checkboxStyles = {
  width: '18px',
  height: '18px',
  flex: '0 0 auto',
  margin: 0.375,
  marginRight: 1.375,
  boxSizing: 'border-box',
  border: '1.5px solid',
  borderColor: (theme: Theme) => theme.palette.strokes.heavy,
  borderRadius: '4px',

  ':focus': {
    // outline: 'none',
    outline: '1px solid',
    outlineColor: (theme: Theme) => theme.palette.strokes.info,
    borderColor: (theme: Theme) => theme.palette.strokes.info,
  },
};

const checkboxSelectedStyles = {
  backgroundColor: (theme: Theme) => theme.palette.strokes.heavy,
};

const checkboxNotSelectedStyles = {};

export function FieldSelectCheckboxListSearch(props: FieldProps) {
  const searchInputRef = useRef(null);

  const onKeyDownSelect = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key !== ' ' && event.key !== 'Enter') return;
    const fieldElement = document.activeElement;
    if (fieldElement instanceof HTMLElement) {
      fieldElement.click();
    }

    if (fieldElement instanceof HTMLElement) {
      const isSelectedCheckbox = Boolean(fieldElement.dataset.checkboxSelected);
      const nextFocusElement = fieldElement.parentElement.querySelector(
        isSelectedCheckbox
          ? '[data-checkbox-not-selected]'
          : '[data-checkbox-selected]',
      ) as HTMLElement | null;
      nextFocusElement?.focus();
    }

    event.preventDefault();
  };

  const fieldsByGroup = props.field.fields.reduce(
    (fieldsByGroup, field, index) => {
      const groupName = field.fieldsetName || `Group ${index % 5}`;
      const fieldsList = fieldsByGroup[groupName] || [];
      fieldsList.push(field);
      fieldsByGroup[groupName] = fieldsList;
      return fieldsByGroup;
    },
    {} as Record<string, (TFieldRenderProps & TFieldInputRenderProps)[]>,
  );

  const allFieldsByGroupEntries = Object.entries(fieldsByGroup);

  const [_isSearchTransitionPending, startSearchTransition] = useTransition();
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryRegex = useMemo(() => {
    return new RegExp(searchQuery, 'i');
  }, [searchQuery]);

  const doSearchFiltering = (query: string) => {
    startSearchTransition(() => {
      setSearchQuery(query);
    });
  };

  const filteredFieldsByGroup = allFieldsByGroupEntries.reduce(
    (filteredFieldsByGroupEntries, groupEntry) => {
      const [groupLabel, fields] = groupEntry;
      const filteredInFields = fields.filter((field) =>
        field.label.textContent.match(searchQueryRegex),
      );
      const filteredOutFields = fields.filter(
        (field) => !field.label.textContent.match(searchQueryRegex),
      );

      filteredFieldsByGroupEntries[groupLabel] = {
        in: filteredInFields,
        out: filteredOutFields,
      };

      return filteredFieldsByGroupEntries;
    },
    {} as Record<
      string,
      {
        in: (TFieldRenderProps & TFieldInputRenderProps)[];
        out: (TFieldRenderProps & TFieldInputRenderProps)[];
      }
    >,
  );

  const filteredFieldsByGroupEntries = Object.entries(filteredFieldsByGroup);

  const groupsFieldsetElementRef = useRef<HTMLElement>();
  const groupsFieldsetMinHeight = useMemo(() => {
    const scrollPosition =
      groupsFieldsetElementRef.current?.getBoundingClientRect().top;
    const bottom = visualViewport.height;
    return bottom - scrollPosition - 100;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const onSelectField = () => {
    const scrollPosition =
      groupsFieldsetElementRef.current?.getBoundingClientRect().top;
    if (scrollPosition < 0 || !searchQuery) return;
    requestAnimationFrame(() => {
      searchInputRef.current?.focus();
    });
  };

  return (
    <Box
      component="fieldset"
      ref={groupsFieldsetElementRef}
      {...props.field.props}
      sx={{
        borderWidth: 0,
        padding: 0,
        margin: 0,
        minHeight: `${groupsFieldsetMinHeight}px`,
      }}
    >
      <Label field={props.field} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          flexDirection: 'column',
        }}
      >
        <Box>
          <TextField
            id={`${props.field.props.id}-search`}
            fullWidth
            variant="standard"
            label=""
            placeholder="Procurar dado"
            onChange={(event) => {
              doSearchFiltering(event.target.value);
            }}
            sx={{
              fontSize: '14px',
              border: '1px solid',
              borderColor: (theme) => theme.palette.strokes.light,
              borderRadius: '8px',
              paddingBlock: 1,
              paddingInline: 1.5,
            }}
            InputProps={{
              inputRef: searchInputRef,
              endAdornment: '',
              startAdornment: (
                <Search
                  sx={{
                    height: '1em',
                    fill: (theme) => theme.palette.text.secondary,
                  }}
                />
              ),
              disableUnderline: true,
              sx: {
                fontSize: '1em',
              },
              slotProps: {
                input: {
                  sx: {
                    marginInlineStart: 1,
                    '::placeholder': {
                      fontFamily: 'Satoshi-Regular',
                      fontSize: '1em',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '20px',
                    },
                  },
                },
              },
              inputProps: {
                autoComplete: 'off',
              },
            }}
          />
        </Box>
        <Box
          component="ul"
          sx={{
            margin: 0,
            marginTop: 4,
            padding: 0,
            listStyle: 'none',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 4,
          }}
        >
          {filteredFieldsByGroupEntries.map(
            ([
              groupLabel,
              { in: filteredInFields, out: filteredOutFields },
            ]) => (
              <Box
                component="ul"
                sx={{
                  margin: 0,
                  padding: 0,
                  listStyle: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  ...(filteredInFields.length === 0 && { display: 'none' }),
                }}
              >
                <Box component="li">
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 'bold',
                      padding: 0.5,
                      display: 'flex',
                      marginBottom: 0.625,
                      color: '#1E1E1E',
                    }}
                  >
                    {groupLabel}
                  </Typography>
                  {filteredOutFields.map((childField) => {
                    return (
                      <Box
                        component="li"
                        {...childField.props}
                        sx={{
                          display: 'none',
                        }}
                      >
                        <Box
                          component="input"
                          onChange={onSelectField}
                          {...childField.input.props}
                          sx={{
                            display: 'none',
                          }}
                        />
                      </Box>
                    );
                  })}
                  {filteredInFields.map((childField) => {
                    return (
                      <Box
                        component="li"
                        {...childField.props}
                        sx={{
                          flex: '0 0 auto',
                          paddingBlock: 0.5,
                          maxWidth: '288px',
                          paddingInline: 1,
                        }}
                      >
                        <Box
                          component="input"
                          onChange={onSelectField}
                          {...childField.input.props}
                          sx={{
                            display: 'none',
                            [`
                            &:not(:checked) + label [data-checkbox-selected],
                            &:checked + label [data-checkbox-not-selected]
                          `]: {
                              display: 'none',
                            },
                          }}
                        />
                        <Box
                          component="label"
                          onKeyDown={onKeyDownSelect}
                          sx={{
                            display: 'flex',
                            height: '32px',
                            alignItems: 'flex-start',
                            borderRadius: '32px',
                            userSelect: 'none',
                          }}
                          {...childField.label.props}
                        >
                          <Box
                            data-checkbox-selected
                            tabIndex={0}
                            sx={{
                              ...checkboxStyles,
                              ...checkboxSelectedStyles,
                            }}
                          >
                            <Check
                              sx={{
                                fill: (theme) =>
                                  theme.palette.primaryAlt.contrastText,
                                maxWidth: '100%',
                                maxHeight: '100%',
                              }}
                            />
                          </Box>
                          <Box
                            tabIndex={0}
                            data-checkbox-not-selected
                            sx={{
                              ...checkboxStyles,
                              ...checkboxNotSelectedStyles,
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              paddingTop: '.18em',
                            }}
                          >
                            {childField.label.textContent}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ),
          )}
        </Box>
      </Box>
    </Box>
  );
}

FieldSelectCheckboxListSearch.uiType = UI_TYPE.SELECT_CHECKBOX_LIST_SEARCH;
FieldSelectCheckboxListSearch.canRender = (
  arg: TFieldRenderProps,
): arg is FieldProps['field'] => {
  if (!('fields' in arg)) return false;

  const isCheckboxSelect = arg.fields.reduce(
    (isCheckboxSelect, fieldOption) => {
      return (
        isCheckboxSelect &&
        'input' in fieldOption &&
        fieldOption.input.props.type === 'checkbox'
      );
    },
    true,
  );

  return isCheckboxSelect;
};
