import { z } from 'zod';

import { nonEmptyString } from '@octopus/libs/forms';

export const deficienciaSchema = z
  .object({
    deficiencia_hidden: z.literal('true').optional(),
    deficiencias: z.array(z.string()).optional(),
    descDeficiencia: nonEmptyString.optional(),
  })
  .superRefine((fieldState, ctx) => {
    const { deficiencia_hidden, descDeficiencia } = fieldState || {};

    if (deficiencia_hidden === 'true') {
      return;
    }

    if (!descDeficiencia) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obrigatório',
        path: ['descDeficiencia'],
      });
    }
  });
