import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmClick: () => void;
  isLoading: boolean;
};

export function MissingReviewsDialog({
  open,
  setOpen,
  confirmClick,
  isLoading,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0,0,0, 0.8)',
          },
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 1,
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
        >
          <Typography variant="h1">Revisão de dados pendente</Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            Algumas sessões ainda precisam ser revisadas. Você pode prosseguir
            com a admissão, mas depois disso não será possível revisá-las.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <>
          <LoadingButton
            variant="outlined"
            color="secondary"
            sx={{ py: 1, px: 4 }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primaryAlt"
            sx={{ py: 1, px: 4 }}
            onClick={confirmClick}
          >
            Concluir admissão
          </LoadingButton>
        </>
      </DialogActions>
    </Dialog>
  );
}
