import React from 'react';

import { Box, Skeleton, Typography } from '@mui/material';

import { Button } from '@octopus/ui/design-system';

import UserAvatar from '../../../../modules/components/UserAvatar';
import { ExplodedDate } from '../../../terminations/local-components/ExplodedDate';

import { useVacationFormOverview } from './useVacationFormOverview';

export function VacationFormOverview(
  outputResults: ReturnType<typeof useVacationFormOverview>,
) {
  if (outputResults.isLoading === true) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Skeleton variant="rounded" width="100%" height={100} />
      </Box>
    );
  }

  if ('errors' in outputResults) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="caption">
          Não foi possível carregar dados de férias do colaborador. Recarregue a
          página para tentar novamente ou siga com o preenchimento do
          formulário.
        </Typography>
        <Button
          variantLayout="small"
          onClick={outputResults.retry}
          sx={{
            marginTop: '22px',
            flex: '0 0 auto',
          }}
        >
          Recarregar
        </Button>
      </Box>
    );
  }

  const outputs = outputResults.data;
  return (
    <Box
      component={'section'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
      }}
    >
      <Box
        component="header"
        sx={{
          display: 'flex',
          padding: '8px',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: '12px',
          alignSelf: 'stretch',
          borderRadius: '8px',
          border: '1px solid #EDEDED',
          background: '#FFF',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '8px',
            gap: '8px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignSelf: 'stretch',
            borderRadius: '8px',
            background: '#F7F7F8',
          }}
        >
          {outputs.employeeName && (
            <UserAvatar
              avatarProps={{
                ml: 0,
                mr: 0,
                my: 0,
              }}
              name={outputs.employeeName}
              showFullName={false}
            />
          )}
          <Box>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
              }}
            >
              {outputs.employeeName}
            </Typography>
            {outputs.employeeUnderNameDetails.map((detail, i, { length }) => (
              <Typography
                key={detail}
                variant="caption"
                sx={{
                  display: 'inline-flex',
                }}
              >
                {detail}
                {i < length - 1 && (
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{
                      marginX: '4px',
                    }}
                  >
                    |
                  </Typography>
                )}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            padding: '8px',
          }}
        >
          <DefinitionList>
            <DefinitionList.Item>
              Data de Início
              <ExplodedDate ddmmyyyy={outputs.startDate} />
            </DefinitionList.Item>
            <DefinitionList.Item>
              Data de fim
              <ExplodedDate ddmmyyyy={outputs.endDate} />
            </DefinitionList.Item>
          </DefinitionList>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '22px',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              display: 'inline-flex',
              marginBottom: '8px',
            }}
          >
            Período disponível
          </Typography>

          {outputs.feriasPorPeriodo.map((ferias, i) => (
            <DefinitionList key={i}>
              <DefinitionList.Item>
                Período aquisitivo
                <ExplodedDate
                  ddmmyyyy={ferias.periodoAquisitivo.dataDeInicio}
                />
                <Typography
                  variant="body2"
                  sx={{ display: 'inline-flex', px: '4px' }}
                >
                  -
                </Typography>
                <ExplodedDate ddmmyyyy={ferias.periodoAquisitivo.dataDeFim} />
              </DefinitionList.Item>
              <DefinitionList.Item>
                Período concessivo
                <ExplodedDate
                  ddmmyyyy={ferias.periodoConcessivo.dataDeInicio}
                />
                <Typography
                  variant="body2"
                  sx={{ display: 'inline-flex', px: '4px' }}
                >
                  -
                </Typography>
                <ExplodedDate ddmmyyyy={ferias.periodoConcessivo.dataDeFim} />
              </DefinitionList.Item>
              <DefinitionList.Item>
                Data limite para concessão
                <ExplodedDate ddmmyyyy={ferias.periodoConcessivo.dataDeFim} />
              </DefinitionList.Item>
              <DefinitionList.Item>
                Dias de gozo disponíveis
                {ferias.qtDiasDisponiveis}
              </DefinitionList.Item>
              {ferias.qtDiasDeAbono !== null && (
                <DefinitionList.Item>
                  Abono pecuniário
                  {ferias.qtDiasDeAbono || 0}
                </DefinitionList.Item>
              )}
              <DefinitionList.Item>
                Faltas no período aquisitivo
                {ferias.qtDiasDeFaltasInjustificadas}
              </DefinitionList.Item>
              <DefinitionList.Item highlight>
                Dias disponíveis após o gozo
                {ferias.qtDiasDisponiveisAposGozo}
              </DefinitionList.Item>
            </DefinitionList>
          ))}
        </Box>
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              display: 'inline-flex',
              marginBottom: '8px',
            }}
          >
            Outras informações
          </Typography>
          <DefinitionList>
            <DefinitionList.Item>
              <>
                Já adiantou o 13º em{' '}
                {outputs.feriasPorPeriodo[0].anoDeAdiantamentoDoDecimoTerceiro}?
              </>
              {outputs.feriasPorPeriodo[0].adiantouDecimoTerceiro
                ? 'Sim'
                : 'Não'}
            </DefinitionList.Item>
          </DefinitionList>
        </Box>
      </Box>
    </Box>
  );
}

type IDefinitionListItemElement = React.ReactElement<
  IDefinitionListItemProps,
  typeof DefinitionList.Item
>;
type IDefinitionListProps = {
  children: IDefinitionListItemElement | IDefinitionListItemElement[];
};

function DefinitionList(props: IDefinitionListProps) {
  return (
    <Box
      component="dl"
      sx={{
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {props.children}
    </Box>
  );
}

type IDefinitionListItemProps = {
  children: [React.ReactNode, ...React.ReactNode[]];
  highlight?: boolean;
};

DefinitionList.Item = function DefinitionListItem(
  props: IDefinitionListItemProps,
) {
  const [dtContent, ...ddContent] = props.children;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}
    >
      <Typography
        component={'dt'}
        variant="caption"
        sx={{
          ...(Boolean(props.highlight) && {
            color: '#0058DB',
            fontWeight: 700,
          }),
        }}
      >
        {dtContent}
      </Typography>
      <Typography
        component={'dd'}
        variant="caption"
        sx={{
          ...(Boolean(props.highlight) && {
            color: '#0058DB',
            fontWeight: 700,
          }),
        }}
      >
        {ddContent}
      </Typography>
    </Box>
  );
};
