import React, { useState } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { OctopusLoading } from '@octopus/ui/design-system';

import { UploadComponent } from '../../../app/payment-requests/[payment-request]/fileComponents';
import trashIcon from '../../../assets/trash.svg';
import { downloadBlobFile } from '../../downloads/download';

export function ConfirmationDialog({
  open,
  setOpen,
  action,
  setError,
}: {
  open: boolean;
  setError: (error: boolean) => void;
  setOpen: (open: boolean) => void;
  action: () => Promise<void>;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Solicitação sem nota fiscal
        </Typography>
        <Typography variant={'body1'}>
          Você não enviou um arquivo de nota fiscal junto com seu pedido, deseja
          continuar assim mesmo?
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-approve-payrolls-button"
      >
        Cancelar
      </Button>
      <Button
        color="primaryAlt"
        variant="contained"
        size="large"
        onClick={() => {
          action()
            .catch(() => {
              setOpen(false);
              setError(true);
            })
            .finally(() => {
              setOpen(false);
            });
        }}
      >
        Continuar sem nota fiscal
      </Button>
    </>
  );

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} hidden={isMobile}>
        <>
          <DialogContent>{content}</DialogContent>
          <DialogActions>{actions}</DialogActions>
        </>
      </Dialog>
      <MobileDrawer
        open={open}
        isMobile={isMobile}
        setOpen={setOpen}
        actions={actions}
        content={content}
      />
    </>
  );
}

export function AttachmentDeleteConfirmationDialog({
  open,
  setOpen,
  action,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (error) {
    return (
      <ErrorDialog
        open={error}
        setOpen={setOpen}
        message={
          'Tivemos um problema técnico interno e não conseguimos enviar sua\n' +
          '              solicitação. Por favor, tente novamente em alguns instantes. Se o\n' +
          '              problema persistir, entre em contato com nosso suporte.'
        }
      />
    );
  }

  const content = (
    <>
      {loading && (
        <LoadingScene
          message={'Excluindo arquivo, aguarde alguns segundos...'}
        />
      )}
      {!loading && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap={4}
          borderRadius={2}
          p={1.5}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            gap={3}
          >
            <Typography fontWeight={700} variant={'h1'}>
              Excluir arquivo
            </Typography>
            <Typography variant={'body1'}>
              <Typography component={'span'} color={'text.error'}>
                Atenção, essa ação não pode ser revertida.{' '}
              </Typography>
              Caso necessário, será possível fazer um novo upload de arquivo
              para esta solicitação.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );

  const actions = (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
      >
        Cancelar
      </Button>
      <Button
        color="error"
        variant="contained"
        size="large"
        onClick={() => {
          setLoading(true);
          action()
            .catch(() => {
              setOpen(false);
              setError(true);
            })
            .finally(() => {
              setLoading(false);
              setOpen(false);
            });
        }}
      >
        Excluir arquivo
      </Button>
    </>
  );

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} hidden={isMobile}>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        isMobile={isMobile}
        actions={actions}
        content={content}
        open={open}
        setOpen={setOpen}
      ></MobileDrawer>
    </>
  );
}

function LoadingScene({ message }: { message: string }) {
  return (
    <Box
      sx={(theme) => {
        return {
          [theme.breakpoints.down('md')]: {
            py: 5,
            height: '300px',
          },
          [theme.breakpoints.up('sm')]: {
            py: 10,
            px: 8,
          },
        };
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box width="100px">
        <OctopusLoading />
      </Box>
      <Box pt={2} width={'100%'}>
        <Typography variant="h4" textAlign="center">
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export function ErrorDialog({
  open,
  setOpen,
  message = 'Tivemos um problema técnico interno e não conseguimos enviar sua\n' +
    '              solicitação. Por favor, tente novamente em alguns instantes. Se o\n' +
    '              problema persistir, entre em contato com nosso suporte.',
}: {
  message?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Desculpe, algo deu errado
        </Typography>
        <Typography variant={'body1'}>{message}</Typography>
      </Box>
    </Box>
  );

  const actions = (
    <Button
      variant="contained"
      size="large"
      color={'primaryAlt'}
      onClick={() => {
        setOpen(false);
      }}
      data-testid="back-error-button"
    >
      Voltar
    </Button>
  );

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} hidden={!isMobile}>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        isMobile={isMobile}
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
      ></MobileDrawer>
    </>
  );
}

export function LoadingDialog({
  open,
  message,
}: {
  open: boolean;
  message: string;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const content = <LoadingScene message={message} />;

  return (
    <>
      <Dialog open={open} hidden={isMobile} fullWidth>
        {content}
      </Dialog>
      <MobileDrawer
        open={open}
        content={content}
        actions={<></>}
        isMobile={isMobile}
      />
    </>
  );
}

export function RejectionDialog({
  open,
  setOpen,
  setRejectionReason,
  action,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setRejectionReason: (reason: string) => void;
  action: () => Promise<void>;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      borderRadius={2}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Recusar solicitação
        </Typography>
        <Typography variant={'body1'}>
          Ao recusar esta solicitação ela será arquivada e um aviso será enviado
          para o prestador. Escreva abaixo o motivo da recusa, e possíveis
          orientações caso seja necessário um novo envio.
        </Typography>
        <Typography variant={'body1'} color={'error'} fontWeight={'700'}>
          Atenção, esta ação não poderá ser revertida.
        </Typography>
        <Box display="flex" flexDirection={'column'} width={'100%'} gap={1}>
          <Typography variant="caption" color="text.secondary">
            Motivo da recusa
          </Typography>
          <TextField
            multiline={true}
            minRows={3}
            fullWidth={true}
            onChange={(event) => {
              setRejectionReason(event.target.value);
            }}
          />
        </Box>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="contained"
        size="large"
        color={'secondary'}
        onClick={() => {
          setOpen(false);
        }}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        size="large"
        color={'error'}
        onClick={() => {
          setOpen(false);
          action();
        }}
        data-testid="reject-button"
      >
        Recusar nota
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        hidden={isMobile}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        isMobile={isMobile}
        open={open}
        content={content}
        actions={actions}
        setOpen={setOpen}
      />
    </>
  );
}

export function CancelPaymentRequestDialog({
  open,
  setOpen,
  action,
  hasInvoice,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: (file: File) => Promise<void>;
  hasInvoice: boolean;
}) {
  const [file, setFile] = useState<File | null>(null);

  const [showFileRequiredWarning, setShowFileRequiredWarning] = useState(false);

  const handleDownload = async () => {
    downloadBlobFile(file, file.name);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      width={'100%'}
      height={'100%'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
        width={'100%'}
        height={'100%'}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Cancelar solicitação
        </Typography>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant={'body1'}>
            Caso esta solicitação tenha uma Nota Fiscal que foi cancelada, você
            deve
            <br /> enviar o arquivo da nota cancelada no campo abaixo.
          </Typography>
          <br />
          <Typography variant={'body1'} color={'error'} fontWeight={700}>
            Atenção, esta ação não pode ser revertida.
          </Typography>
        </Box>
        {hasInvoice && showFileRequiredWarning && (
          <Box
            pt={3}
            pb={2}
            px={3}
            display={'flex'}
            gap={2}
            width={'100%'}
            boxSizing={'border-box'}
            bgcolor={'background.warning'}
            borderRadius={'8px'}
          >
            <WarningIcon color="warning" height="16px" width="16px" />
            <Typography variant={'caption'} color={'text.primary'}>
              Você precisa enviar um arquivo da NF cancelada para continuar
            </Typography>
          </Box>
        )}
        {!file ? (
          <Box
            border={'1px dashed'}
            borderColor={'strokes.light'}
            borderRadius={'8px'}
            width={'100%'}
            height={'100%'}
          >
            <UploadComponent setFile={setFile} />
          </Box>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            py={2}
            boxSizing={'border-box'}
            width={'100%'}
            alignItems={'center'}
          >
            <Typography variant={'caption'} fontWeight={700}>
              Arquivo da NF cancelada
            </Typography>

            <Box
              display={'flex'}
              gap={1}
              p={1.5}
              bgcolor={'strokes.secondary'}
              borderRadius={'8px'}
              width={'100%'}
              boxSizing={'border-box'}
            >
              <Box
                sx={{
                  bgcolor: '#1E78FF',
                  borderRadius: 0.5,
                  color: '#FFF',
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="32px"
                height="32px"
                p={0.5}
              >
                <InsertDriveFileOutlinedIcon
                  sx={{
                    height: '24px',
                    width: '24px',
                  }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                flexGrow={1}
              >
                <Typography variant={'caption'} fontWeight={500} noWrap={true}>
                  {file.name}
                </Typography>
                <Typography
                  variant={'caption'}
                  color={'text.secondary'}
                  fontWeight={500}
                >
                  {parseInt(`${file.size / 1000}`)} kb
                </Typography>
              </Box>
              <Box display={'flex'} gap={2} px={0.5} alignItems={'center'}>
                <IconButton
                  onClick={handleDownload}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid #EDEDED',
                    background: '#FFF',
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <FileDownloadOutlinedIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                    }}
                  />
                </IconButton>
                <Box height={'100%'} alignItems={'center'} display={'flex'}>
                  <Typography
                    color={'strokes.light'}
                    fontSize={'24px'}
                    lineHeight={'24px'}
                  >
                    |
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => {
                    setFile(undefined);
                  }}
                >
                  <img src={trashIcon} width={'16px'} height={'16px'} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="contained"
        size="large"
        color={'secondary'}
        onClick={() => {
          setOpen(false);
        }}
        fullWidth={isMobile}
      >
        Voltar
      </Button>
      <Button
        variant="contained"
        size="large"
        color={'error'}
        fullWidth={isMobile}
        onClick={() => {
          if (hasInvoice && !file) {
            setShowFileRequiredWarning(true);
            return;
          }

          action(file);
          setOpen(false);
        }}
        data-testid="reject-button"
      >
        Cancelar solicitação
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        hidden={isMobile}
        PaperProps={{
          sx: {
            width: '600px',
            height: '560px',
          },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        isMobile={isMobile}
        setOpen={setOpen}
        actions={actions}
        content={content}
      />
    </>
  );
}

function MobileDrawer({
  content,
  actions,
  isMobile,
  setOpen,
  open,
}: {
  open: boolean;
  content: React.ReactNode;
  actions: React.ReactNode;
  isMobile: boolean;
  setOpen?: (open: boolean) => void;
}) {
  return (
    <Drawer
      hidden={!isMobile}
      anchor={'bottom'}
      open={open}
      onClose={() => {
        if (setOpen) {
          setOpen(false);
        }
      }}
      sx={{
        zIndex: 99999,
      }}
      transitionDuration={500}
      elevation={2}
      PaperProps={{ sx: { borderRadius: '16px 16px 0 0 ' } }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        p={2}
      >
        <Box height={'70%'}>{content}</Box>
        <Box
          height={'30%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-end'}
          mt={5}
          mb={1}
          gap={2}
        >
          {actions}
        </Box>
      </Box>
    </Drawer>
  );
}

export function RemoveApprovalDialog({
  open,
  setOpen,
  action,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<void>;
}) {
  const content = (
    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h1'}>
          Remover aprovação
        </Typography>
        <Box>
          <Typography variant={'body1'} fontWeight={700}>
            Atenção, ao remover aprovação de uma solicitação, o que acontece em
            seguida:
          </Typography>
          <Box
            component="ul"
            pl={2}
            gap={2}
            display="flex"
            flexDirection="column"
            sx={{
              listStylePosition: 'outside',
              paddingInlineStart: '1.5rem',
            }}
          >
            <li>
              <Typography variant={'body1'}>
                Alteração do arquivo de pagamento
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                O arquivo de pagamento referente à esta solicitação será
                excluido da seção de Pagamentos. Certifique-se de que o
                pagamento ainda não foi realizado antes de fazer essa mudança.
              </Typography>
            </li>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="contained"
        size="large"
        color={'secondary'}
        onClick={() => {
          setOpen(false);
        }}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        size="large"
        color={'error'}
        onClick={() => {
          setOpen(false);
          action();
        }}
        data-testid="reject-button"
      >
        Continuar
      </Button>
    </>
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        hidden={isMobile}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        content={content}
        actions={actions}
        isMobile={isMobile}
      />
    </>
  );
}
