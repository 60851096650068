import {
  ContractBRCltCreate,
  ContractBRCltEntry,
  ContractBRCltLeavesEntry,
  ContractBRCltLeavesInputDoencaOuAcidente,
  ContractBRCltLeavesInputMaternidade,
  ContractBRCltLeavesInputOutrosMotivos,
  ContractBRCltSummary,
  ContractBRCltUpdate,
  ContractBRPjCreate,
  ContractBRPjEntry,
  ContractBRPjLeavesEntry,
  ContractBRPjUpdate,
  ContractBrCltTermination,
  ContractBrCltVacations,
  ContractBrPjVacations,
  ContractEntry,
  ContractInput,
  ContractLeavesInput,
  ContractSummary,
  ContractTermination,
  ContractUpdate,
  ContractVacations,
} from '@octopus/api';
import {
  BRCltContractLeavesDiseaseOrAccidentCodMotAfast,
  BRCltContractLeavesMaternityCodMotAfast,
  BRCltContractLeavesOtherReasonsCodMotAfast,
  isTrabalhadorAutonomo,
} from '@octopus/esocial/contracts';

export const contractTypes = {
  brClt: 'br:clt',
  brPj: 'br:pj',
} as const;

export type ContractTypes = (typeof contractTypes)[keyof typeof contractTypes];

export const probationPeriodResourceType = 'probation-period';

export const workerCategory = {
  cltGeral: 'clt:geral',
  cltAutonomo: 'clt:autonomo',
  cltTsv: 'clt:tsv',
  cltEstagiario: 'clt:estagiario',
  cltJovemAprendiz: 'clt:jovemAprendiz',
  pj: 'pj',
} as const;

export const workerCategoryLabels = {
  'clt:geral': 'Colaborador',
  'clt:autonomo': 'Autonomo',
  'clt:tsv': 'Trabalhador sem vínculo',
  'clt:estagiario': 'Estagiário',
  'clt:jovemAprendiz': 'Jovem aprendiz',
  pj: 'Prestador',
} as Record<WorkerCategory, string>;

export type WorkerCategory =
  (typeof workerCategory)[keyof typeof workerCategory];

export type CltContractEntry = Extract<
  ContractEntry,
  { contractType: 'br:clt' }
>;

export type PjContractEntry = Extract<ContractEntry, { contractType: 'br:pj' }>;

export type ContractProbationPeriodNotificationContext = {
  resourceId: string;
  resourceType: string;
  organizationId: string;
  contractId: string;

  probationPeriodEndDate?: string;
  probationPeriodExtensionStartDate?: string;
  daysUntilProbationEndDate: number;
  daysUntilProbationExtensionStartDate?: number;
  effectiveStartDate: string;

  workerName: string;
  workerId: string;
};

function isBRCltContract(
  contract?: ContractEntry,
): contract is ContractEntry & {
  contractType: 'br:clt';
  br: ContractBRCltEntry;
} {
  return !!contract && contract.contractType === contractTypes.brClt;
}

function isBRCltContractSummary(
  contract?: ContractSummary,
): contract is ContractSummary & {
  contractType: 'br:clt';
  br: ContractBRCltSummary;
} {
  return !!contract && contract.contractType === contractTypes.brClt;
}

function isBRCltContractInput(
  contract: ContractInput,
): contract is ContractInput & {
  contractType: 'br:clt';
  br: ContractBRCltCreate;
} {
  return contract.contractType === contractTypes.brClt;
}

function isBRCltContractUpdate(
  contract: ContractUpdate,
): contract is ContractUpdate & {
  contractType: 'br:clt';
  br: ContractBRCltUpdate;
} {
  return contract.contractType === contractTypes.brClt;
}

function isBRCltContractTermination(
  contract: ContractTermination,
): contract is ContractTermination & {
  contractType: 'br:clt';
  br: ContractBrCltTermination;
} {
  return contract.contractType === contractTypes.brClt;
}

function isBRCltContractLeaves(
  contract: ContractLeavesInput,
): contract is ContractLeavesInput & {
  contractType: 'br:clt';
  br: ContractBRCltLeavesEntry;
} {
  return contract.contractType === contractTypes.brClt;
}

function isBRCltContractVacation(
  contract: ContractVacations,
): contract is ContractVacations & {
  contractType: 'br:clt';
  br: ContractBrCltVacations;
} {
  return contract.contractType === contractTypes.brClt;
}

function isBRPjContract(contract?: ContractEntry): contract is ContractEntry & {
  contractType: 'br:pj';
  br: ContractBRPjEntry;
} {
  return !!contract && contract.contractType === contractTypes.brPj;
}

function isBRPjContractInput(
  contract: ContractInput,
): contract is ContractInput & {
  contractType: 'br:pj';
  br: ContractBRPjCreate;
} {
  return contract.contractType === contractTypes.brPj;
}

function isBRPjContractUpdate(
  contract: ContractUpdate,
): contract is ContractUpdate & {
  contractType: 'br:pj';
  br: ContractBRPjUpdate;
} {
  return contract.contractType === contractTypes.brPj;
}

function isBRPjContractLeaves(
  contract: ContractLeavesInput,
): contract is ContractLeavesInput & {
  contractType: 'br:pj';
  br: ContractBRPjLeavesEntry;
} {
  return contract.contractType === contractTypes.brPj;
}

function isBRPjContractTermination(
  contract: ContractTermination,
): contract is ContractTermination & {
  contractType: 'br:pj';
} {
  return contract.contractType === contractTypes.brPj;
}

function isBRPjContractVacations(
  contract: ContractVacations,
): contract is ContractVacations & {
  contractType: 'br:pj';
  br: ContractBrPjVacations;
} {
  return contract.contractType === contractTypes.brPj;
}

function isBRCltContractLeavesDiseaseOrAccident(
  br: ContractLeavesInput['br'],
): br is ContractBRCltLeavesInputDoencaOuAcidente {
  return BRCltContractLeavesDiseaseOrAccidentCodMotAfast.includes(
    br.codMotAfast,
  );
}

function isBRCltContractLeavesMaternity(
  br: ContractLeavesInput['br'],
): br is ContractBRCltLeavesInputMaternidade {
  return BRCltContractLeavesMaternityCodMotAfast.includes(br.codMotAfast);
}

function isBRCltContractLeavesOtherReasons(
  br: ContractLeavesInput['br'],
): br is ContractBRCltLeavesInputOutrosMotivos {
  return BRCltContractLeavesOtherReasonsCodMotAfast.includes(br.codMotAfast);
}

function getContractJobTitleId(contract: ContractEntry): string {
  if (isBRCltContract(contract)) {
    return contract.br.trabalho.jobTitleId;
  }

  if (isBRPjContract(contract)) {
    return contract.br.prestador.posicao.jobTitleId;
  }

  return '';
}

function getAdmissionDate(
  contract: ContractEntry | ContractInput,
): string | undefined {
  if (
    contract.contractType === contractTypes.brClt &&
    !isTrabalhadorAutonomo(contract.br.trabalho)
  ) {
    return contract.br.regime?.dtAdm;
  }

  if (contract.contractType === contractTypes.brPj) {
    return contract.br.contrato.inicio;
  }

  return '';
}

function getTerminationDate(contract: ContractEntry): string | undefined {
  if (isBRCltContract(contract) && contract.br.situacao.tipo === 'desligado') {
    return contract.br.situacao.dtDeslig;
  }

  if (isBRPjContract(contract) && contract.status === 'terminated') {
    return contract.br.contrato.termino;
  }

  return undefined;
}

function getCorporateEmail(contract: ContractEntry): string | undefined {
  if (isBRCltContract(contract)) {
    return contract.br.vinculo?.emailCorp;
  }

  if (isBRPjContract(contract)) {
    return contract.br.emailCorp;
  }

  return '';
}

function getWorkerId(contract: ContractEntry): string {
  if (contract.workerId != null) {
    return contract.workerId;
  }

  if (isBRCltContract(contract) && contract.br.vinculo?.matricula) {
    return contract.br.vinculo.matricula;
  }

  return '';
}

function getDepartment(contract: ContractEntry): string | undefined {
  if (isBRCltContract(contract)) {
    return contract.br.trabalho.departamento;
  }
  if (isBRPjContract(contract)) {
    return contract.br.prestador.posicao.departamento;
  }
  return undefined;
}

function getCostCenterId(contract: ContractEntry): string | undefined {
  if (isBRCltContract(contract)) {
    return contract.br.gestao?.costCenterId;
  }
  if (isBRPjContract(contract)) {
    return contract.br.prestador.gestao?.costCenterId;
  }
  return undefined;
}

function getCostCenterName(contract: ContractEntry): string | undefined {
  if (isBRCltContract(contract)) {
    return contract.br.gestao?.nomeCentroCusto;
  }
  if (isBRPjContract(contract)) {
    return contract.br.prestador.gestao?.nomeCentroCusto;
  }
  return undefined;
}

function getCostCenterCode(contract: ContractEntry): string | undefined {
  if (isBRCltContract(contract)) {
    return contract.br.gestao?.codigoCentroCusto;
  }
  if (isBRPjContract(contract)) {
    return contract.br.prestador.gestao?.codigoCentroCusto;
  }
  return undefined;
}

function getRoleName(contract: ContractEntry): string | undefined {
  if (isBRCltContract(contract)) {
    return contract.br.trabalho.nmFuncao;
  }
  if (isBRPjContract(contract)) {
    return contract.br.prestador.posicao.nmFuncao;
  }
  return '';
}

function getName(contract: ContractEntry): string {
  return contract.br.pessoa.nmTrab;
}

function getCpf(contract: ContractEntry): string {
  if (isBRCltContract(contract)) {
    return contract.br.pessoa.cpfTrab;
  }
  if (isBRPjContract(contract)) {
    return contract.br.pessoa.cpfTrab;
  }
  return '';
}

function getSalary(contract: ContractEntry): number {
  if (isBRCltContract(contract)) {
    return contract?.br?.remuneracao?.vrSalFx || 0;
  }
  if (isBRPjContract(contract)) {
    return contract?.br?.pagamento?.honorarios || 0;
  }
  return 0;
}

export {
  getAdmissionDate,
  getContractJobTitleId,
  getCorporateEmail,
  getName,
  getRoleName,
  getCostCenterId,
  getCostCenterName,
  getCostCenterCode,
  getDepartment,
  getSalary,
  getWorkerId,
  getTerminationDate,
  getCpf,
  isBRCltContract,
  isBRCltContractInput,
  isBRCltContractLeaves,
  isBRCltContractLeavesDiseaseOrAccident,
  isBRCltContractLeavesMaternity,
  isBRCltContractLeavesOtherReasons,
  isBRCltContractSummary,
  isBRCltContractTermination,
  isBRCltContractUpdate,
  isBRCltContractVacation,
  isBRPjContract,
  isBRPjContractInput,
  isBRPjContractLeaves,
  isBRPjContractTermination,
  isBRPjContractUpdate,
  isBRPjContractVacations,
};
