import { useSubroute } from './useSubRoute';

export function useMultiStepView<
  const TStepNames extends string = string,
  const TStepData = unknown,
>(preDefinedSteps: Record<TStepNames, TStepData>) {
  const [currentRoute, navigate, routes] = useSubroute('msv', preDefinedSteps);

  const goBack = () => {
    navigate(-1);
  };

  const goForward = (stepState?: Partial<TStepData>) => {
    navigate(1, stepState);
  };

  const goTo = (stepName: TStepNames, stepState?: Partial<TStepData>) => {
    navigate(stepName, stepState);
  };

  const replaceCurrent = (
    stepName: TStepNames,
    stepState?: Partial<TStepData>,
  ) => {
    navigate(stepName, stepState, { replace: true });
  };

  const steps = routes;
  const currentStep = currentRoute;

  return {
    steps,
    currentStep,
    replaceCurrent,
    goTo,
    goBack,
    goForward,
  };
}
