import { useEffect, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';

import {
  ContractBRCltTipoDependente,
  PayrollInputsConfig,
  PayrollInputsList,
  PayrollInputsSubmissionInput,
} from '@octopus/api';

import { PayrollDependentData, PayrollEmployeeData } from './types';
import { cellValueEquals } from './utils';

type InputsEditState = {
  [payrollId: string]: {
    dependents: {
      [dependentId: string]: {
        [inputId: string]: string | null;
      };
    };
    inputs: {
      [inputId: string]: string | null;
    };
  };
};

type EmployeeKey = {
  payrollId: string;
  inputId: string;
};

type DependentKey = EmployeeKey & {
  dependentId: string;
};

export type Edit<Keys extends EmployeeKey> = {
  keys: Keys;
  value: string | null;
};

export type InputsEdit = {
  set: (payrollId: string, inputId: string, value: string | null) => void;
  setAll: (inputId: string, value: string | null) => void;
  hasBeenEdited: (payrollId: string, inputId: string, value: string) => boolean;
  undo: () => void;
  redo: () => void;
};

export type DependentsEdit = {
  set: (
    payrollId: string,
    dependentId: string,
    inputId: string,
    value: string | null,
  ) => void;
  setAll: (inputId: string, value: string | null) => void;
  hasBeenEdited: (
    payrollId: string,
    dependentId: string,
    inputId: string,
    value: string,
  ) => boolean;
  undo: () => void;
  redo: () => void;
};

export type HasPayrollBeenEdited = (payrollId: string) => boolean;

export type GetEditCount = () => number;

export type EditsToSubmissionInput = () => PayrollInputsSubmissionInput;

export type EmployeesState = {
  data: PayrollEmployeeData[];
  edit: InputsEdit;
};

export type DependentsState = {
  data: PayrollDependentData[];
  edit: DependentsEdit;
};

type SubmissionsState = {
  employees: EmployeesState;
  dependents: DependentsState;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
  getEditCount: GetEditCount;
  getSubmissionInput: EditsToSubmissionInput;
};

export function useSubmissionState(
  config: PayrollInputsConfig,
  data: PayrollInputsList,
): SubmissionsState {
  const originalData: InputsEditState = useMemo(
    () => toInputsEditState(data),
    [data],
  );

  const employeesState = useEmployeesState(config, data, originalData);
  const dependentsState = useDependentsState(config, data, originalData);

  const [submissionInput, setSubmissionInput] =
    useState<PayrollInputsSubmissionInput>({});

  useEffect(() => {
    setSubmissionInput(toSubmissionInput(employeesState, dependentsState));
  }, [employeesState.data, dependentsState.data]);

  const hasPayrollBeenEdited: HasPayrollBeenEdited = (payrollId) => {
    const inputChanges = Object.keys(
      submissionInput[payrollId]?.inputs ?? {},
    ).length;
    const dependentChanges = Object.values(
      submissionInput[payrollId]?.dependents ?? {},
    ).reduce((sum, current) => sum + Object.keys(current ?? {}).length, 0);
    return inputChanges + dependentChanges > 0;
  };

  const getEditCount: GetEditCount = () => {
    return Object.values(submissionInput).reduce(
      (sum, inputs) =>
        sum +
        Object.keys(inputs?.inputs ?? {}).length +
        Object.values(inputs?.dependents ?? {}).reduce(
          (sum, current) => sum + Object.keys(current ?? {}).length,
          0,
        ),
      0,
    );
  };

  const getSubmissionInput: EditsToSubmissionInput = () => {
    return submissionInput;
  };

  return {
    employees: employeesState,
    dependents: dependentsState,
    hasPayrollBeenEdited,
    getEditCount,
    getSubmissionInput,
  };
}

function toInputsEditState(data: PayrollInputsList): InputsEditState {
  return Object.entries(data.payrolls ?? {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        inputs: value.inputs,
        dependents: Object.entries(value.dependents ?? {})?.reduce(
          (acc, [depId, dependent]) => ({
            ...acc,
            [depId]: dependent.inputs,
          }),
          {},
        ),
      },
    }),
    {},
  );
}

function useEmployeesState(
  config: PayrollInputsConfig,
  data: PayrollInputsList,
  originalData: InputsEditState,
): EmployeesState {
  const [employeesState, setEmployeesState] = useState<PayrollEmployeeData[]>(
    Object.entries(cloneDeep(data.payrolls))
      .map(([key, payroll]) => ({
        payrollId: key,
        contractId: payroll.contractId,
        personId: payroll.personId,
        name: payroll.name,
        employeeId: payroll.employeeId,
        inputs: payroll.inputs,
      }))
      .sort((a, b) => parseInt(a.employeeId) - parseInt(b.employeeId)),
  );

  const [undoStack, setUndoStack] = useState<Edit<EmployeeKey>[]>([]);
  const [redoStack, setRedoStack] = useState<Edit<EmployeeKey>[]>([]);

  function addToStack(
    payrollId: string,
    inputId: string,
    value: string,
    stack: Edit<EmployeeKey>[],
  ): Edit<EmployeeKey>[] {
    const updatedStack = [
      {
        keys: {
          payrollId,
          inputId,
        },
        value,
      },
      ...stack,
    ];
    if (updatedStack.length > 50) {
      return updatedStack.slice(0, 50);
    }
    return updatedStack;
  }

  function getDataForPayrollId(
    payrollId: string,
    data: PayrollEmployeeData[],
  ): PayrollEmployeeData | undefined {
    return data.filter((row) => row.payrollId === payrollId)[0];
  }

  function updateTableData(
    payrollId: string,
    inputId: string,
    value: string | null,
    data: PayrollEmployeeData[],
  ): PayrollEmployeeData[] {
    const row = getDataForPayrollId(payrollId, data);
    row.inputs = {
      ...(row?.inputs ?? {}),
      [inputId]: value,
    };
    return [...data];
  }

  function getValueForInputId(
    payrollId: string,
    inputId: string,
    data: PayrollEmployeeData[],
  ): string | null {
    return getDataForPayrollId(payrollId, data)?.inputs?.[inputId] ?? null;
  }

  const set = (payrollId: string, inputId: string, value: string | null) => {
    const currentValue = getValueForInputId(payrollId, inputId, employeesState);
    if (
      !cellValueEquals(
        value,
        currentValue,
        config.payload[inputId]?.payloadType,
      )
    ) {
      setUndoStack((current) =>
        addToStack(payrollId, inputId, currentValue, current),
      );
      setEmployeesState((current) =>
        updateTableData(payrollId, inputId, value, current),
      );
    }
  };

  const setAll = (inputId: string, value: string) => {
    employeesState.forEach((row) => {
      set(row.payrollId, inputId, value);
    });
  };

  const hasBeenEdited = (payrollId: string, inputId: string, value: string) => {
    return !cellValueEquals(
      value,
      originalData?.[payrollId]?.inputs?.[inputId],
      config.payload[inputId]?.payloadType,
    );
  };

  const undo = () => {
    const edit = undoStack[0];
    if (edit) {
      const currentValue = getValueForInputId(
        edit.keys.payrollId,
        edit.keys.inputId,
        employeesState,
      );
      setRedoStack((current) =>
        addToStack(
          edit.keys.payrollId,
          edit.keys.inputId,
          currentValue,
          current,
        ),
      );
      setEmployeesState((current) =>
        updateTableData(
          edit.keys.payrollId,
          edit.keys.inputId,
          edit.value,
          current,
        ),
      );
      setUndoStack((current) => current.slice(1));
    }
  };

  const redo = () => {
    const edit = redoStack[0];
    if (edit) {
      const currentValue = getValueForInputId(
        edit.keys.payrollId,
        edit.keys.inputId,
        employeesState,
      );
      setUndoStack((current) =>
        addToStack(
          edit.keys.payrollId,
          edit.keys.inputId,
          currentValue,
          current,
        ),
      );
      setEmployeesState((current) =>
        updateTableData(
          edit.keys.payrollId,
          edit.keys.inputId,
          edit.value,
          current,
        ),
      );
      setRedoStack((current) => current.slice(1));
    }
  };

  return {
    data: employeesState,
    edit: {
      set,
      setAll,
      hasBeenEdited,
      undo,
      redo,
    },
  };
}

function useDependentsState(
  config: PayrollInputsConfig,
  data: PayrollInputsList,
  originalData: InputsEditState,
): DependentsState {
  const [dependentsState, setDependentsState] = useState<
    PayrollDependentData[]
  >(
    Object.entries(cloneDeep(data.payrolls))
      .flatMap(([payrollId, payroll]) => {
        return Object.entries(payroll.dependents ?? {}).map(
          ([depId, dependent]) => ({
            payrollId,
            employeeId: payroll.employeeId,
            dependentId: depId,
            nmDependent: dependent.nmDep,
            tpDependent: dependent.tpDep as ContractBRCltTipoDependente,
            contractId: payroll.contractId,
            personId: payroll.personId,
            name: payroll.name,
            inputs: dependent.inputs,
          }),
        );
      })
      .sort((a, b) => parseInt(a.employeeId) - parseInt(b.employeeId)),
  );

  const [undoStack, setUndoStack] = useState<Edit<DependentKey>[]>([]);
  const [redoStack, setRedoStack] = useState<Edit<DependentKey>[]>([]);

  function addToStack(
    payrollId: string,
    dependentId: string,
    inputId: string,
    value: string,
    stack: Edit<DependentKey>[],
  ): Edit<DependentKey>[] {
    const updatedStack = [
      {
        keys: {
          payrollId,
          dependentId,
          inputId,
        },
        value,
      },
      ...stack,
    ];
    if (updatedStack.length > 50) {
      return updatedStack.slice(0, 50);
    }
    return updatedStack;
  }

  function getDataForPayrollIdAndDependentId(
    payrollId: string,
    dependentId: string,
    state: PayrollDependentData[],
  ): PayrollDependentData | undefined {
    return state.filter(
      (row) => row.payrollId === payrollId && row.dependentId === dependentId,
    )[0];
  }

  function updateTableData(
    payrollId: string,
    dependentId: string,
    inputId: string,
    value: string | null,
    state: PayrollDependentData[],
  ): PayrollDependentData[] {
    const row = getDataForPayrollIdAndDependentId(
      payrollId,
      dependentId,
      state,
    );
    row.inputs = {
      ...(row?.inputs ?? {}),
      [inputId]: value,
    };
    return [...state];
  }

  function getValueForInputId(
    payrollId: string,
    dependentId: string,
    inputId: string,
    state: PayrollDependentData[],
  ): string | null {
    return (
      getDataForPayrollIdAndDependentId(payrollId, dependentId, state)
        ?.inputs?.[inputId] ?? null
    );
  }

  const set = (
    payrollId: string,
    dependentId: string,
    inputId: string,
    value: string | null,
  ) => {
    const currentValue = getValueForInputId(
      payrollId,
      dependentId,
      inputId,
      dependentsState,
    );
    if (
      !cellValueEquals(
        value,
        currentValue,
        config.payload[inputId]?.payloadType,
      )
    ) {
      setUndoStack((current) =>
        addToStack(payrollId, dependentId, inputId, currentValue, current),
      );
      setDependentsState((current) =>
        updateTableData(payrollId, dependentId, inputId, value, current),
      );
    }
  };

  const setAll = (inputId: string, value: string) => {
    dependentsState.forEach((row) => {
      set(row.payrollId, row.dependentId, inputId, value);
    });
  };

  const hasBeenEdited = (
    payrollId: string,
    dependentId: string,
    inputId: string,
    value: string,
  ) => {
    return !cellValueEquals(
      value,
      originalData?.[payrollId]?.dependents?.[dependentId]?.[inputId],
      config.payload[inputId]?.payloadType,
    );
  };

  const undo = () => {
    const edit = undoStack[0];
    if (edit) {
      const currentValue = getValueForInputId(
        edit.keys.payrollId,
        edit.keys.dependentId,
        edit.keys.inputId,
        dependentsState,
      );
      setRedoStack((current) =>
        addToStack(
          edit.keys.payrollId,
          edit.keys.dependentId,
          edit.keys.inputId,
          currentValue,
          current,
        ),
      );
      setDependentsState((current) =>
        updateTableData(
          edit.keys.payrollId,
          edit.keys.dependentId,
          edit.keys.inputId,
          edit.value,
          current,
        ),
      );
      setUndoStack((current) => current.slice(1));
    }
  };

  const redo = () => {
    const edit = redoStack[0];
    if (edit) {
      const currentValue = getValueForInputId(
        edit.keys.payrollId,
        edit.keys.dependentId,
        edit.keys.inputId,
        dependentsState,
      );
      setUndoStack((current) =>
        addToStack(
          edit.keys.payrollId,
          edit.keys.dependentId,
          edit.keys.inputId,
          currentValue,
          current,
        ),
      );
      setDependentsState((current) =>
        updateTableData(
          edit.keys.payrollId,
          edit.keys.dependentId,
          edit.keys.inputId,
          edit.value,
          current,
        ),
      );
      setRedoStack((current) => current.slice(1));
    }
  };

  return {
    data: dependentsState,
    edit: {
      set,
      setAll,
      hasBeenEdited,
      undo,
      redo,
    },
  };
}

function toSubmissionInput(
  inputsState: EmployeesState,
  dependentsState: DependentsState,
): PayrollInputsSubmissionInput {
  const submissionInput = inputsState.data.reduce((acc, row) => {
    if (!row.inputs) {
      return acc;
    }
    const updatedInputs = Object.fromEntries(
      Object.entries(row.inputs).filter(([key, value]) =>
        inputsState.edit.hasBeenEdited(row.payrollId, key, value),
      ),
    );
    if (Object.keys(updatedInputs).length === 0) {
      return acc;
    }
    return {
      ...acc,
      [row.payrollId]: {
        inputs: updatedInputs,
      },
    };
  }, {} as PayrollInputsSubmissionInput);
  return dependentsState.data.reduce((acc, row) => {
    if (!row.inputs) {
      return acc;
    }
    const updatedInputs = Object.fromEntries(
      Object.entries(row.inputs).filter(([key, value]) =>
        dependentsState.edit.hasBeenEdited(
          row.payrollId,
          row.dependentId,
          key,
          value,
        ),
      ),
    );
    if (Object.keys(updatedInputs).length === 0) {
      return acc;
    }
    return {
      ...acc,
      [row.payrollId]: {
        ...acc[row.payrollId],
        dependents: {
          ...acc[row.payrollId]?.dependents,
          [row.dependentId]: updatedInputs,
        },
      },
    } as PayrollInputsSubmissionInput;
  }, submissionInput);
}
