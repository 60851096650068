import { useMemo, useState } from 'react';

import { isNil } from 'lodash';

import { ContractBRCltEntryDeficiencia } from '@octopus/api';
import { formatBooleanBR } from '@octopus/formatters';

import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps, booleanOptions } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type DeficienciaRecordData = {
  deficiencia: ContractBRCltEntryDeficiencia;
};

export type DeficienciaRecordProps = BaseRecordProps<DeficienciaRecordData>;

export function DeficienciaRecord(props: DeficienciaRecordProps) {
  const { deficiencia } = props.data;
  const { observacao, reabReadap, infoCota } = deficiencia;

  const defs = useMemo(
    () =>
      Object.entries(deficiencia)
        .filter(([key]) => key.startsWith('def'))
        .filter(([_, value]) => value === true)
        .map(([key]) => key.slice(3)),
    [deficiencia],
  );
  const [hasDefs, setHasDefs] = useState(defs.length > 0);

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<DeficienciaRecordData>(props);

  const strToBool = (str: string) => str === 'true';

  const setDataProp = <K extends keyof ContractBRCltEntryDeficiencia>(
    propName: K,
    propValue: ContractBRCltEntryDeficiencia[K],
  ) =>
    updateData((data) => ({
      ...data,
      deficiencia: { ...data.deficiencia, [propName]: propValue },
    }));

  const clearDefs = () => {
    setHasDefs(false);
    updateData(() => ({
      deficiencia: {
        defAuditiva: false,
        defFisica: false,
        defIntelectual: false,
        defMental: false,
        defVisual: false,
        infoCota: false,
        reabReadap: false,
        observacao: '',
      },
    }));
  };

  return (
    <Record title="Deficiências" edit={editRecordProps}>
      <RecordEntry
        label="Possui alguma deficiência?"
        edit={{
          type: 'options',
          editing,
          onChange: (v) => {
            const value = strToBool(v);
            return value ? setHasDefs(value) : clearDefs();
          },
          value: hasDefs.toString(),
          options: booleanOptions,
        }}
      >
        {formatBooleanBR(hasDefs)}
      </RecordEntry>
      <RecordEntry
        // TODO: flex-start to "Quais tipos" typography
        label="Quais tipos"
        hide={!hasDefs}
        edit={{
          type: 'multi-check',
          editing,
          onChange: (prop, value) => {
            setDataProp(prop as keyof ContractBRCltEntryDeficiencia, value);
          },
          options: Object.entries(formData.deficiencia)
            .filter(([key]) => key.startsWith('def'))
            .map(([key, val]) => ({
              key,
              value: val as boolean,
              label: key.slice(3),
            })),
          disabled: !hasDefs,
        }}
      >
        {defs.join(', ')}
      </RecordEntry>
      <RecordEntry
        label="Descrição"
        hide={!hasDefs}
        edit={{
          type: 'text',
          editing,
          value: formData.deficiencia.observacao,
          disabled: !hasDefs,
          onChange: (v) => setDataProp('observacao', v),
          hasError: hasError('br/deficiencia/observacao'),
        }}
      >
        {observacao}
      </RecordEntry>
      <RecordEntry
        label="Pessoa reabilitada ou readaptada?"
        hide={!hasDefs}
        edit={{
          type: 'options',
          editing,
          value: formData.deficiencia.reabReadap.toString(),
          disabled: !hasDefs,
          onChange: (v) => setDataProp('reabReadap', strToBool(v)),
          options: booleanOptions,
          hasError: hasError('br/deficiencia/reabReadap'),
        }}
      >
        {formatBooleanBR(reabReadap)}
      </RecordEntry>
      <RecordEntry
        label="Contabilizado para preenchimento de cota?"
        hide={!hasDefs}
        edit={{
          type: 'options',
          editing,
          value: isNil(formData.deficiencia.infoCota)
            ? 'false'
            : formData.deficiencia.infoCota.toString(),
          disabled: !hasDefs,
          onChange: (v) => setDataProp('infoCota', strToBool(v)),
          options: booleanOptions,
          hasError: hasError('br/deficiencia/infoCota'),
        }}
      >
        {formatBooleanBR(infoCota)}
      </RecordEntry>
    </Record>
  );
}
