import { z } from 'zod';

import { nonEmptyString } from '@octopus/libs/forms';

export const estadoMunicipioSchema = z
  .object({
    codMunic: nonEmptyString,
  })
  .superRefine((formstate, ctx) => {
    const { codMunic } = formstate || {};

    if (codMunic == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obrigatório',
        path: ['codMunic'],
      });
    }

    return;
  });
