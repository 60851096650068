import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { Challenge } from '@octopus/api';

import { useLoginProps } from '../page';

import { ForgotPassword } from './ForgotPassword';
import { NewPasswordChallenge } from './NewPasswordChallenge';

export type newPasswordType = 'challenge' | 'forgot-password';

export function NewPassword() {
  const { onSuccess } = useLoginProps();
  const [searchParams] = useSearchParams();
  const { state: locationState } = useLocation();
  const [state, setState] = useState({
    type: undefined as newPasswordType | undefined,
  });

  const getNewPasswordTypeBasedOnParams = (
    state: { username: string; challenge: Challenge } | undefined,
    searchParams: { get: (arg0: string) => string | null },
  ): newPasswordType | undefined => {
    if (state && state.username && state.challenge) {
      return 'challenge';
    } else if (searchParams.get('sub') && searchParams.get('code')) {
      return 'forgot-password';
    }
    return undefined;
  };

  useEffect(() => {
    const type = getNewPasswordTypeBasedOnParams(locationState, searchParams);
    setState({
      type,
    });
  }, [searchParams]);

  if (!state.type) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width="100%">
      {state.type === 'challenge' && (
        <NewPasswordChallenge
          username={locationState.username}
          challenge={locationState.challenge}
          onSuccess={onSuccess}
        />
      )}
      {state.type === 'forgot-password' && (
        <ForgotPassword
          sub={searchParams.get('sub') || ''}
          code={searchParams.get('code') || ''}
        />
      )}
    </Box>
  );
}
