import React, { useRef } from 'react';

import { useForm } from '@conform-to/react';
import dayjs from 'dayjs';

import { CalendarTodayOutlined } from '@mui/icons-material';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { UI_TYPE } from '@octopus/libs/forms';

import {
  TFieldDatePickerRenderProps,
  TFieldInputRenderProps,
  TFieldRenderProps,
} from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldProps = {
  field: TFieldRenderProps &
    TFieldInputRenderProps &
    TFieldDatePickerRenderProps;
  sx?: SxProps<Theme>;
};

export function FieldTextDate(props: FieldProps) {
  const { field } = props;
  const { input, extraProps } = field;

  const datepickerInputRef = useRef(null);
  const defaultValue = input.props.defaultValue
    ? dayjs(field.input.props.defaultValue)
    : null;

  const [payloadFormMeta] = useForm({
    id: field.input.props.form,
  });
  const hasError = field.errors?.length > 0;

  const minDate = extraProps?.minDate ? dayjs(extraProps?.minDate) : null;
  const maxDate = extraProps?.maxDate ? dayjs(extraProps?.maxDate) : null;
  const shouldDisableDate = extraProps?.shouldDisableDate;

  return (
    <FieldWrapper field={field} sx={field.props.sx}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="pt-br"
        localeText={{
          fieldMonthPlaceholder: () => 'MM',
          fieldDayPlaceholder: () => 'DD',
          fieldYearPlaceholder: () => 'AAAA',
        }}
      >
        <input
          {...field.input.props}
          type="hidden"
          tabIndex={-1}
          onFocus={() => {
            datepickerInputRef.current?.focus();
          }}
        />
        <DatePicker
          label=""
          views={['year', 'month', 'day']}
          openTo="month"
          format="DD/MM/YYYY"
          value={defaultValue as any}
          minDate={minDate as any}
          maxDate={maxDate as any}
          shouldDisableDate={shouldDisableDate}
          inputRef={datepickerInputRef}
          sx={{
            width: '100%',
          }}
          slots={{
            openPickerIcon: CalendarTodayOutlined,
          }}
          slotProps={{
            openPickerIcon: {
              sx: {
                fill: (theme: Theme) => theme.palette.text.primary,
                width: '16px',
              },
            },
            textField: {
              error: hasError ?? undefined,
            },
            inputAdornment: {
              position: 'start',
              sx: {
                '& .MuiIconButton-root': {
                  margin: '0px',
                  padding: '0',
                },
              },
            },
          }}
          onChange={(value) => {
            if (!value) {
              payloadFormMeta.update({
                name: field.input.props.name,
                value: '',
              });
            } else if (
              typeof value === 'object' &&
              'format' in value &&
              typeof value.format === 'function'
            ) {
              const formattedValue = value.format('YYYY-MM-DD');
              payloadFormMeta.update({
                name: field.input.props.name,
                value: formattedValue,
              });
            }
          }}
        />
      </LocalizationProvider>
    </FieldWrapper>
  );
}

FieldTextDate.uiType = UI_TYPE.TEXT_DATE_PICKER;
FieldTextDate.canRender = (arg: TFieldRenderProps) => {
  return 'input' in arg && arg.uiType === UI_TYPE.TEXT_DATE_PICKER;
};
