import React, { MouseEvent, useEffect, useState } from 'react';

import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Button, Popover, Skeleton, Typography } from '@mui/material';

import { OrganizationAvatar } from '../../../modules/components/OrganizationAvatar';
import {
  ChangeMembership,
  MembershipContext,
  MembershipOption,
} from '../../../modules/types';
import { membershipEquals } from '../../../utils';

export function MembershipSelector({
  open,
  membership,
  membershipOptions,
  changeMembership,
}: {
  open: boolean;
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
  changeMembership: ChangeMembership;
}) {
  return (
    <MembershipSelectorComponent
      open={open}
      options={membershipOptions}
      membership={membership}
      changeMembership={changeMembership}
    />
  );
}

function MembershipSelectorComponent({
  open,
  options,
  membership,
  changeMembership,
}: {
  open: boolean;
  options: MembershipOption[];
  membership: MembershipContext;
  changeMembership: ChangeMembership;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPopover(!showPopover);
  };

  const selectedMembership = options.find((option) =>
    membershipEquals(membership, option),
  );
  useEffect(() => {
    if (!membership || !selectedMembership) {
      changeMembership(options[0]);
    }
  }, [membership]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!selectedMembership) {
    return null;
  }

  const openPopover = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box
      data-testid="membership-selector"
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          width: '100%',
        },
        [theme.breakpoints.down('md')]: {
          width: 'auto',
        },
      })}
    >
      <MembershipSelectedButton
        option={selectedMembership}
        handleClick={handleClick}
        showText={open}
      />
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        data-testid="membership-selector-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          p={1.5}
          gap={1}
          minWidth="244px"
          maxWidth="244px"
        >
          <Typography
            variant="caption"
            color="text.secondary"
            pb={1}
            sx={{
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderBottomColor: 'divider',
            }}
          >
            Visualizar empresa
          </Typography>
          {options.map((option, i) => (
            <MembershipOptionButton
              key={i}
              option={option}
              selected={membershipEquals(option, selectedMembership)}
              handleClick={() => changeMembership(option)}
              handleClose={handleClose}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
}

function MembershipSelectedButton({
  option,
  showText,
  handleClick,
}: {
  option: MembershipOption | undefined;
  showText: boolean;
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
}) {
  if (!option) {
    return (
      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        data-testid="membership-selector-button-progress"
      >
        <Skeleton
          variant="rounded"
          width="100%"
          height="40px"
          sx={{ m: '8px' }}
          data-testid="membership-selector-button-progress"
        />
      </Box>
    );
  }
  return (
    <Button
      data-testid="membership-selector-button"
      onClick={handleClick}
      data-id={option.organizationId}
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        minWidth: '40px',
        m: 1,
        borderRadius: 1,
        padding: '0',
        border: '0',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.strokes.light,
        },
        '&:active': {
          backgroundColor: theme.palette.strokes.heavy,
        },
        [theme.breakpoints.up('md')]: {
          width: 'calc(100% - 16px)',
        },
        [theme.breakpoints.down('md')]: {
          width: 'auto',
        },
      })}
    >
      <Box m={0.5}>
        <OrganizationAvatar name={option.label} logo={option.logo} />
      </Box>
      {showText && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="calc(100% - 42px)"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            maxWidth="calc(100% - 20px)"
          >
            <Typography
              variant="h4"
              color="text.primary"
              textAlign="left"
              noWrap
            >
              {option?.label}
            </Typography>
            {option?.subLabel && (
              <Typography
                variant="caption"
                color="text.secondary"
                textAlign="left"
                noWrap
              >
                {option?.subLabel}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '20px',
              height: '40px',
            }}
          >
            <ExpandMoreOutlinedIcon
              sx={{
                width: '16px',
                height: '16px',
                color: 'text.primary',
              }}
            />
          </Box>
        </Box>
      )}
    </Button>
  );
}

function MembershipOptionButton({
  option,
  selected,
  handleClick,
  handleClose,
}: {
  option: MembershipOption;
  selected: boolean;
  handleClick: () => void;
  handleClose: () => void;
}) {
  return (
    <Button
      onClick={() => {
        handleClick();
        handleClose();
      }}
      key={option.organizationId}
      data-testid={`membership-selector-menu-item-${option.organizationId}-${option.companyId}`}
      sx={(theme) => ({
        height: '40px',
        padding: '0',
        border: '0',
        width: '100%',
        overflow: 'hidden',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.strokes.light,
        },
      })}
    >
      <Box m={0.5}>
        <OrganizationAvatar name={option.label} logo={option.logo} />
      </Box>
      <Box display="flex" flexDirection="row" width="calc(100% - 40px)">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{
            width: '100%',
            maxWidth: 'calc(100% - 40px)',
          }}
        >
          <Typography variant="body2" fontWeight="bold" textAlign="left" noWrap>
            {option?.label}
          </Typography>
          {option?.subLabel && (
            <Typography variant="caption" textAlign="left" noWrap>
              {option?.subLabel}
            </Typography>
          )}
        </Box>
        {selected && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '40px',
              height: '34.5px',
            }}
          >
            <DoneOutlinedIcon
              color="primary"
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </Box>
        )}
      </Box>
    </Button>
  );
}
