import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import { formatDateBR } from '@octopus/formatters';
import {
  FilterOption,
  makeDateRangeFilter,
  makeElementListFilter,
} from '@octopus/ui/data-grid';

import CancelledIcon from '../../assets/cancelled.svg';
import SendIcon from '../../assets/send.svg';
import { CustomSwitch } from '../../modules/components/CustomSwitch';
import { PayrollTypePage } from '../../modules/components/payrolls/PayrollTypePage';
import { usePayrollTypePage } from '../../modules/components/payrolls/usePayrollTypePage';
import { SendStatus } from '../../modules/components/SendStatus';
import UserAvatar from '../../modules/components/UserAvatar';
import { useFFlags } from '../fflags';
import { useSendPayslip } from '../payrolls/SendPayslips';
import { ExplodedDate } from '../terminations/local-components/ExplodedDate';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

const tabs = {
  open: {
    color: 'warning',
    label: 'Prévias',
    statuses: ['open'],
  },
  approved: {
    color: 'primary',
    label: 'Aprovadas',
    statuses: ['approved'],
  },
  closed: {
    color: 'success',
    label: 'Fechadas',
    statuses: ['closed', 'reconciled'],
  },
  archived: {
    color: 'error',
    label: 'Excluídas',
    statuses: ['archived'],
  },
};

function VacationsPage({ organizationId, companyId }: VacationsProps) {
  const navigate = useNavigate();

  const { sendPayslipsProps, sendPayslips, SendPayslipsComponent } =
    useSendPayslip({
      organizationId,
      companyId,
    });

  const [visibleTabs, setVisibleTabs] = useState([
    tabs.open,
    tabs.approved,
    tabs.closed,
  ]);

  const { fflags } = useFFlags();

  const links = {
    create: () =>
      fflags.canAccessVacationInputs.enabled && {
        href: `/vacations/new`,
        label: `Iniciar cálculo de férias`,
      },
  };

  const isArchivedTabVisible = visibleTabs.includes(tabs.archived);

  const vacationPayrollPageProps: ReturnType<typeof usePayrollTypePage> =
    usePayrollTypePage({
      organizationId,
      companyId,
      payrollType: 'vacations',
      tabs: tabs,
      links,
      defaultTab: tabs.open,
      visibleTabs: visibleTabs,
      getColumnsForTab: (tabName) => {
        return [
          {
            field: 'workerData.name',
            headerName: 'Colaborador(a)',
            valueGetter: (params) => params.row.workerData.name,
            renderCell: (params) => {
              return (
                <UserAvatar
                  name={params.formattedValue}
                  avatarProps={{
                    ml: 0,
                    mr: 2.25,
                  }}
                  expandNameOnHover
                  sx={{
                    '--UserAvatar-name-max-width': '12.5em',
                  }}
                />
              );
            },
          },
          tabName === 'closed' && {
            field: 'vacations.accrualPeriod.startDate',
            headerName: 'Início do aquisitivo',
            valueGetter: (params) =>
              formatDateBR(params.row.vacations?.accrualPeriod?.startDate),
            renderCell: (params) => {
              return <ExplodedDate ddmmyyyy={params.formattedValue} />;
            },
          },
          tabName === 'closed' && {
            field: 'vacations.accrualPeriod.endDate',
            headerName: 'Fim do aquisitivo',
            valueGetter: (params) =>
              formatDateBR(params.row.vacations?.accrualPeriod?.endDate),
            renderCell: (params) => {
              return <ExplodedDate ddmmyyyy={params.formattedValue} />;
            },
          },
          {
            field: 'vacations.startDate',
            headerName: 'Início do gozo',
            valueGetter: (params) =>
              formatDateBR(params.row.vacations?.startDate) || '--',
            renderCell: (params) => {
              return <ExplodedDate ddmmyyyy={params.formattedValue} />;
            },
          },
          {
            field: 'vacations.endDate',
            headerName: 'Fim do gozo',
            valueGetter: (params) =>
              formatDateBR(params.row.vacations?.endDate) || '--',
            renderCell: (params) => {
              return <ExplodedDate ddmmyyyy={params.formattedValue} />;
            },
          },
          tabName !== 'closed' && {
            field: 'vacations.durationInDays',
            headerName: 'Duração',
            valueGetter: (params) =>
              `${params.row.vacations?.durationInDays} dias`,
          },
          tabName === 'open' && {
            field: 'vacations.sellingVacations',
            headerName: 'Abono',
            sortable: false,
            valueGetter: (params) =>
              params.row.vacations?.sellingVacations === true ? 'Sim' : 'Não',
          },
          {
            field: 'todo.vacations.remainingDays',
            headerName: 'Dias restantes',
            sortable: false,
            valueGetter: () => `-- dias`, //TODO add vacations.remainingDays to payroll outputs
          },
          tabName === 'open' && {
            field: 'todo.approvalDeadline',
            headerName: 'Aprovar até',
            sortable: false,
            valueGetter: () => '--/-- às 23:59', //TODO add approvalDeadline
          },
          (tabName === 'approved' || tabName === 'closed') && {
            field: 'todo.receipts',
            headerName: 'Recibos',
            sortable: false,
            valueGetter: (_params) => 'Enviar até 88/88/8888',
            renderCell: (params) => {
              const didSendPayslips = tabName === 'approved' ? false : true; //TODO properly create payslip search endpoint for this one
              return (
                <Box display="flex" alignItems="center">
                  {didSendPayslips && (
                    <SendStatus
                      isSent={true}
                      SentMessage={() => <> Enviados </>}
                      sx={{
                        pr: 3,
                      }}
                    />
                  )}
                  {!didSendPayslips && (
                    <>
                      <SendStatus
                        isSent={false}
                        NotSentMessage={() => <> Enviar até --/-- </>}
                        sx={{
                          pr: 3,
                        }}
                      />
                      <Button
                        color="secondary"
                        startIcon={<Box component="img" src={SendIcon} />}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          sendPayslips({
                            payrollId: params.row.payrollId,
                          });
                        }}
                      >
                        Enviar
                      </Button>
                      <SendPayslipsComponent
                        {...sendPayslipsProps}
                        Title={() => <> Envio de recibo </>}
                      />
                    </>
                  )}
                </Box>
              );
            },
          },
          tabName === 'open' && {
            field: 'reviewCallToAction',
            sortable: false,
            renderCell: () => (
              <Typography color="primary.main" variant="body2" fontWeight={700}>
                Revisar
              </Typography>
            ),
          },
        ];
      },
      getRowActionMenu: (params) => {
        return [
          params.row.status === 'open' && {
            label: 'Excluir',
            isDisabled: vacationPayrollPageProps.data.payrollsBeingArchived.has(
              params.row.payrollId,
            ),
            isPreventingClose:
              vacationPayrollPageProps.data.payrollsBeingArchived.has(
                params.row.payrollId,
              ),
            onClick: () => {
              vacationPayrollPageProps.actions.archivePayroll(
                params.row.payrollId,
              );
            },
          },
          params.row.status !== 'open' && {
            label: 'Baixar recibo',
            isDisabled: true,
            onClick: () => {
              // TODO download receipt
            },
          },
        ];
      },
      getPageActionMenu: () => {
        return [
          {
            label: 'Mostrar excluídas',
            render: ({ key, sx }) => (
              <Box
                sx={{
                  ...sx,
                  display: 'flex',
                  alignItems: 'center',
                }}
                key={key}
              >
                <Typography
                  variant="body2"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    mr: 4,
                  }}
                  component={'label'}
                  htmlFor="toggleArchived"
                >
                  <Box
                    component={'img'}
                    src={CancelledIcon}
                    sx={{
                      mr: 1.5,
                      mt: '-.0125em', //artificially sets icon baseline
                      alignSelf: 'baseline',
                      height: '1.25em',
                    }}
                  />
                  Mostrar excluídas
                </Typography>
                <CustomSwitch
                  inputProps={{
                    id: 'toggleArchived',
                    'aria-label': 'Mostrar excluídos',
                  }}
                  checked={isArchivedTabVisible}
                  onChange={() => {
                    setVisibleTabs((visibleTabs) =>
                      visibleTabs.includes(tabs.archived)
                        ? [tabs.open, tabs.approved, tabs.closed]
                        : [
                            tabs.open,
                            tabs.approved,
                            tabs.closed,
                            tabs.archived,
                          ],
                    );

                    if (!isArchivedTabVisible) {
                      vacationPayrollPageProps.actions.openTab(tabs.archived);
                    } else if (
                      vacationPayrollPageProps.data.tabs.currentTabName ===
                      'archived'
                    ) {
                      vacationPayrollPageProps.actions.openTab(tabs.open);
                    }
                  }}
                />
              </Box>
            ),
          },
        ];
      },
      filters: getFilters(),
      onRowClick: (params) => {
        const payrollSummary = params.row;
        navigate(`/vacations/${payrollSummary.payrollId}`);
      },
    });

  return (
    <PayrollTypePage
      {...vacationPayrollPageProps}
      Title={() => <> Férias </>}
      TitleIcon={BeachAccessOutlined}
    />
  );
}

function getFilters(): FilterOption[] {
  return [
    makeElementListFilter({
      label: 'Abono Pecuniário',
      propertyToFilter: 'vacations.sellingVacations',
      elements: ['true', 'false'],
      labels: {
        true: 'Sim',
        false: 'Não',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeElementListFilter({
      label: 'Adiantamento do 13º',
      propertyToFilter: 'vacations.anticipate13th',
      elements: ['true', 'false'],
      labels: {
        true: 'Sim',
        false: 'Não',
      },
      sortElements: false,
      disableSearch: true,
    }),
    makeDateRangeFilter({
      label: 'Data de início do gozo',
      propertyToFilter: 'vacations.startDate',
    }),
    makeDateRangeFilter({
      label: 'Data de fim do gozo',
      propertyToFilter: 'vacations.endDate',
    }),
    makeDateRangeFilter({
      label: 'Início do período aquisitivo',
      propertyToFilter: 'vacations.accrualPeriod.startDate',
    }),
    makeDateRangeFilter({
      label: 'Fim do período aquisitivo',
      propertyToFilter: 'vacations.accrualPeriod.endDate',
    }),
  ];
}

export default VacationsPage;
