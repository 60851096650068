import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { confirmPasswordRecovery } from '../../../modules/login';

import { PasswordChange } from './PasswordChange';

export type ForgotPasswordProps = {
  sub: string;
  code: string;
};

export function ForgotPassword({ sub, code }: ForgotPasswordProps) {
  const navigate = useNavigate();
  const submit = async (password: string) => {
    await confirmPasswordRecovery(sub, code, password);
  };

  const onSuccess = () => {
    navigate('success');
  };

  return (
    <Box width="100%">
      <PasswordChange submit={submit} onSuccess={onSuccess} />
    </Box>
  );
}
