import { Button, Typography } from '@mui/material';

import GoogleIconImage from '../../../../assets/google.svg';
import { SSOAuthorizeUrlParams, SSOUtils } from '../../../../utils/sso';

export type GoogleLoginButtonProps = {
  origin: string;
  title: string;
};

export function GoogleLoginButton({ title, origin }: GoogleLoginButtonProps) {
  const config = SSOUtils.Config.getConfig();

  if (!config.enabled) {
    return null;
  }

  const googleConfig = {
    ...config,
    origin,
    identityProvider: 'Google',
  };

  return (
    <Button
      color="secondary"
      startIcon={<GoogleIcon />}
      sx={{
        paddingBottom: 1.5,
        paddingTop: 1.5,
      }}
      onClick={() => {
        redirectToOAuthPage(googleConfig);
      }}
    >
      <Typography variant="body1" fontWeight="700" sx={{ paddingLeft: 1 }}>
        {title}
      </Typography>
    </Button>
  );
}

function redirectToOAuthPage(params: SSOAuthorizeUrlParams) {
  const oAuthPageUrl = SSOUtils.Client.buildAuthorizeUrl(params);
  window.location.href = oAuthPageUrl;
}

function GoogleIcon() {
  return <img width="24px" height="24px" src={GoogleIconImage} alt="Google" />;
}
