import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';

import { TermsOfServiceBox } from './TermsOfServiceBox';

export function TermsOfService() {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    accepted: false,
  });

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (!locationState || !locationState.username || !locationState.challenge) {
      navigate('/login');
    }
  }, [locationState]);

  const goToNewPassword = () => {
    navigate('/login/new-password', { state: locationState });
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={3}>
      <TermsOfServiceBox />
      <Box mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              color="primaryAlt"
              onChange={(e) => setState({ accepted: e.target.checked })}
              data-testid="accept"
            />
          }
          label="Declaro que li e aceito o inteiro teor destes Termos de Uso, ficando desde já obrigado(a) e sujeito(a) aos direitos e obrigações aqui previstos"
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        {showMessage && (
          <Box py={2}>
            <Typography textAlign={'center'} color={'error'}>
              Para continuar é necessário ler e declarar que aceita os Termos de
              Uso acima ☝️
            </Typography>
          </Box>
        )}
        <Button
          fullWidth
          color="primary"
          onClick={() => {
            if (!state.accepted) {
              setShowMessage(true);
              return;
            }

            goToNewPassword();
          }}
          sx={{ p: 1.5 }}
          data-testid="submit"
        >
          <Typography variant="body1" color={'secondary'}>
            Continuar
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
