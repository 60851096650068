import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { UI_TYPE, nonEmptyString } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { AdmissionFormState, FormStepDefinition } from '../types';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}) => {
  const { user_name, user_email } = formState;

  return [
    {
      label: 'Nome do candidato',
      type: nonEmptyString,
      name: 'user_name',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: Maria de Lourdes',
      value: user_name || '',
    },
    {
      label: 'Email pessoal',
      type: z.string().email(),
      name: 'user_email',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: exemplo@exemplo.com.br',
      value: user_email || '',
    },
  ];
};

export const getCriacaoUsuarioFields = ({
  formState,
  criacaoUsuarioFormSteps,
}: {
  formState: AdmissionFormState;
  criacaoUsuarioFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({ formState }),
  options: {
    id: admissionDraftFormSteps.criacao_usuario,
    title: 'Dados do candidato',
    completed: criacaoUsuarioFormSteps?.completed,
    reviewed: criacaoUsuarioFormSteps?.reviewed,
    disclaimer:
      formState.admissionType === 'send_invite'
        ? 'O novo colaborador vai receber o e-mail de convite, com a senha temporária.'
        : 'O novo colaborador vai receber o e-mail com a senha temporária no dia da admissão.',
  },
});
