import { Mapper } from './mapper';

// Without commas because of spreadsheets usage
export const map = {
  A: 'Acordo Coletivo de Trabalho',
  B: 'Legislação federal/estadual/municipal/distrital',
  C: 'Convenção Coletiva de Trabalho',
  D: 'Sentença normativa - Dissídio',
  E: 'Conversão de licença saúde em acidente de trabalho',
  F: 'Outras verbas de natureza salarial ou não salarial devidas após o desligamento',
  G: 'Antecipação de diferenças de acordo/convenção/dissídio coletivo',
  H: 'Declaração de base de cálculo de FGTS anterior ao início do FGTS Digital',
  I: 'Sentença judicial (exceto reclamatória trabalhista)',
  J: 'Parcelas complementares conhecidas após o fechamento da folha',
} as const;

export type TipoEventoRemunRetroativa = keyof typeof map;

export const TipoEventoRemunRetroativa = Mapper.from(map);
