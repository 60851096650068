import {
  ChangeEvent,
  ReactElement,
  type RefCallback,
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputProps,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Mapper } from '@octopus/esocial/mapper';

export type RecordGroupProps = {
  title: string;
  showExpandIcon?: boolean;
  children: ReactElement<RecordProps> | ReactElement<RecordProps>[];
};

export function RecordGroup({
  title,
  showExpandIcon,
  children,
}: RecordGroupProps) {
  const [expanded, setExpanded] = useState(true);
  return (
    <Box
      display="flex"
      flexDirection="column"
      data-testid="person-personal-details"
      gap={1.5}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        {...(showExpandIcon
          ? {
              onClick: () => setExpanded((state) => !state),
              sx: { cursor: 'pointer' },
            }
          : {})}
      >
        <Typography variant="h3" fontWeight="700" px={1}>
          {title}
        </Typography>
        {showExpandIcon && (
          <ExpandLessOutlinedIcon
            sx={{
              height: '24px',
              width: '24px',
              transition: 'all 0.5s',
              ...(!expanded && { transform: 'scaleY(-1)' }),
            }}
          />
        )}
      </Box>
      {expanded && children}
    </Box>
  );
}

export type RecordProps = {
  title: string;
  children:
    | ReactElement<RecordEntryProps | RecordEntryGroupProps>
    | ReactElement<RecordEntryProps>[]
    | ReactElement<RecordEntryGroupProps>[]
    | ReactElement[]
    | undefined;
  hide?: boolean;
  edit?: RecordEditProps;
  extraActions?: ReactElement;
};

export type RecordEditProps = {
  isEditable: boolean | undefined;
  onEdit: () => void;
  onCancel: (callback?: () => void) => void;
  onSave: (callback?: () => void) => void;
  isEditing?: boolean;
};

export function Record(props: RecordProps) {
  const { title, children, edit, hide = false, extraActions } = props;
  const { isEditing = false } = edit || {};

  if (!children || hide) {
    return null;
  }

  const isRecordEntryGroupArray = (
    children:
      | ReactElement<RecordEntryProps | RecordEntryGroupProps>
      | ReactElement<RecordEntryProps>[]
      | ReactElement<RecordEntryGroupProps>[],
  ) => {
    if (Array.isArray(children)) {
      return children
        .map((child) => {
          if (typeof child.type !== 'string') {
            return child.type?.name === 'RecordEntryGroup';
          }
          return false;
        })
        .every((value) => value);
    }
    return false;
  };
  const renderEntries = (
    children:
      | ReactElement<RecordEntryProps | RecordEntryGroupProps>
      | ReactElement<RecordEntryProps>[],
  ) => (
    <Box display="flex" flexDirection="column" gap={isEditing ? 0.5 : 1.5}>
      {children}
    </Box>
  );
  const renderEntryGroups = (
    children: ReactElement<RecordEntryGroupProps>[],
  ) => (
    <Box display="flex" flexDirection="column">
      {children.reduce<Array<ReactElement>>((acc, child, index) => {
        if (index < children.length - 1) {
          return [...acc, child, <Divider key={index} sx={{ my: 2 }} />];
        }
        return [...acc, child];
      }, [] as Array<ReactElement>)}
    </Box>
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      boxSizing="border-box"
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.07)',
        borderRadius: '12px',
        width: '100%',
        px: 4,
        pt: 3,
        pb: 4,
        backgroundColor: 'background.paper',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" color="text.primary">
          {title}
        </Typography>
        <Box display="flex" gap={4}>
          {edit?.isEditable && !isEditing && (
            <Typography
              variant="caption"
              fontWeight="700"
              color="primary.main"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                edit.onEdit();
              }}
            >
              Editar
            </Typography>
          )}
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      {isRecordEntryGroupArray(children)
        ? renderEntryGroups(children as ReactElement<RecordEntryGroupProps>[])
        : renderEntries(
            children as
              | ReactElement<RecordEntryProps | RecordEntryGroupProps>
              | ReactElement<RecordEntryProps>[],
          )}
      {isEditing && (
        <Box display="flex" justifyContent="space-between" flexGrow={1} pt={2}>
          <Box display="flex" alignItems="center">
            {extraActions}
          </Box>
          <Box display="flex" gap={1} width="252px">
            <Button
              size="large"
              color="secondary"
              onClick={() => edit.onCancel()}
              fullWidth
            >
              Cancelar
            </Button>
            <Button
              size="large"
              color="primaryAlt"
              onClick={() => edit.onSave()}
              fullWidth
            >
              Salvar
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export type RecordEntryGroupProps = {
  title?: string;
  children:
    | ReactElement<RecordEntryProps | Element>
    | ReactElement<RecordEntryProps | Element>[]
    | undefined;
  isEditing?: boolean;
  withDivider?: boolean;
};

export function RecordEntryGroup({
  title,
  children,
  isEditing = false,
  withDivider = false,
}: RecordEntryGroupProps) {
  if (!children) {
    return null;
  }
  return (
    <Box display="flex" flexDirection="column" gap={isEditing ? 0.5 : 1.5}>
      {title && (
        <Typography
          variant="body2"
          fontWeight="700"
          color="text.primary"
          pb={0.75}
          pt={1}
        >
          {title}
        </Typography>
      )}
      {children}
      {withDivider && (
        <Box pb={4} pt={isEditing ? 3.5 : 2.5}>
          <Divider />
        </Box>
      )}
    </Box>
  );
}

export type RecordEntryProps = {
  label: string;
  children?: string | ReactElement | undefined | string[];
  isLoading?: boolean;
  hide?: boolean;
  hideValue?: boolean;
  edit?: EditingProps;
  description?: string;
};

type BaseRecordEntryEditProps = {
  editing: boolean;
  disabled?: boolean;
  inputProps?: Partial<InputProps>;
  hasError?: boolean;
};

type TextRecordEntryEditProps = {
  type: 'text';
  value: string | number;
  mask?: RefCallback<HTMLElement>;
  onChange: (value: string) => void;
};

export type OptionsRecordEntrySelectOption = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

type OptionsRecordEntryEditProps = {
  type: 'options';
  value: string | number;
  options: OptionsRecordEntrySelectOption[];
  onChange: (value: string) => void;
};

type DateRecordEntryEditProps = {
  type: 'date';
  value: string | number;
  valueFormat: string;
  onChange: (value: string) => void;
};

type MultiCheckRecordEntryOption = {
  label: string;
  key: string;
  value: boolean;
  disabled?: boolean;
};

type MultiCheckRecordEntryEditProps = {
  type: 'multi-check';
  options: MultiCheckRecordEntryOption[];
  onChange: (key: string, value: boolean) => void;
};

type OptionsAndTextRecordEntryEditProps = {
  type: 'options-and-text';
  options: Omit<OptionsRecordEntryEditProps, 'type'>;
  text: Omit<TextRecordEntryEditProps, 'type'> & { disabled?: boolean };
};

export type EditingProps = BaseRecordEntryEditProps &
  (
    | TextRecordEntryEditProps
    | OptionsRecordEntryEditProps
    | DateRecordEntryEditProps
    | MultiCheckRecordEntryEditProps
    | OptionsAndTextRecordEntryEditProps
  );

export function RecordEntry(props: RecordEntryProps) {
  const {
    label,
    children,
    isLoading = false,
    hide = false,
    edit,
    description,
  } = props;
  if (hide) {
    return null;
  }
  let content;
  if (isLoading) {
    content = <Skeleton variant="rectangular" width="100%" />;
  } else if (edit && edit.editing) {
    content = <RecordEntryEditField {...edit} />;
  } else {
    if (props.hideValue) {
      content = null;
    } else if (typeof children === 'string') {
      content = (
        <Typography variant="body2" color="text.primary">
          {children}
        </Typography>
      );
    } else if (typeof children === 'object' && Array.isArray(children)) {
      content = (
        <Box display="flex" flexDirection="column" gap={1}>
          {children.map((child, index) => (
            <Typography key={index} variant="body2" color="text.primary">
              {child}
            </Typography>
          ))}
        </Box>
      );
    } else if (!children) {
      content = (
        <Typography variant="body2" color="strokes.heavy">
          Não informado
        </Typography>
      );
    } else {
      content = children;
    }
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2.5}
    >
      <Typography
        variant="body2"
        color="text.primary"
        display="inline-flex"
        width="100%"
        maxWidth="36%"
      >
        {label}
      </Typography>
      <Box
        display="inline-flex"
        width="100%"
        maxWidth="57%"
        flexGrow={1}
        gap={0.5}
      >
        {content}
        {description && (
          <Typography
            variant="body2"
            color="text.primary"
            display="flex"
            alignItems="center"
            justifyContent={edit && edit.editing ? 'center' : 'left'}
            minWidth="40%"
            maxWidth="60%"
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function RecordEntryEditField(props: EditingProps) {
  const { type } = props;
  if (type === 'text') {
    return <TextRecordEntryEditField {...props} />;
  }
  if (type === 'options') {
    return <OptionsRecordEntryEditField {...props} />;
  }
  if (type === 'date') {
    return <DateRecordEntryEditField {...props} />;
  }
  if (type === 'multi-check') {
    return <MultiCheckRecordEntryEditField {...props} />;
  }
  if (type === 'options-and-text') {
    return <OptionsAndTextRecordEntryEditField {...props} />;
  }

  throw new Error('Invalid record entry edit field type');
}

function TextRecordEntryEditField({
  mask,
  value,
  onChange,
  disabled,
  inputProps,
  hasError,
}: BaseRecordEntryEditProps & Omit<TextRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      value={value ?? ''}
      InputProps={{
        ...inputProps,
        ...(error
          ? { endAdornment: <ErrorOutlineOutlinedIcon color="error" /> }
          : {}),
      }}
      fullWidth
      onInput={(event: ChangeEvent<HTMLInputElement>) => {
        setError(false);
        onChange(event.target.value);
      }}
      disabled={disabled}
      ref={mask}
      error={error}
      helperText={error ? 'Valor inválido' : ''}
    />
  );
}

function OptionsRecordEntryEditField({
  value,
  options,
  onChange,
  disabled,
  hasError,
}: BaseRecordEntryEditProps & Omit<OptionsRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      select
      value={value ?? ''}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setError(false);
        onChange(event.target.value);
      }}
      fullWidth
      disabled={disabled}
      error={error}
      helperText={error ? 'Valor inválido' : ''}
      inputProps={{
        ...(error
          ? { endAdornment: <ErrorOutlineOutlinedIcon color="error" /> }
          : {}),
      }}
    >
      {options?.map(({ label, value, disabled = false }, i) => (
        <MenuItem key={i} value={value} disabled={disabled}>
          {label}
        </MenuItem>
      )) ?? (
        <MenuItem key={1} value={undefined} disabled={true}>
          {''}
        </MenuItem>
      )}
    </TextField>
  );
}

function DateRecordEntryEditField({
  value,
  onChange,
  valueFormat,
  disabled,
  hasError,
}: BaseRecordEntryEditProps & Omit<DateRecordEntryEditProps, 'type'>) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        defaultValue={value ? dayjs(value) : undefined}
        sx={{ width: '100%' }}
        onChange={(v) => {
          onChange(v ? v.format(valueFormat) : null);
        }}
        disabled={disabled}
        slotProps={{
          textField: {
            error,
            helperText: error ? 'Data inválida' : '',
          },
        }}
      />
    </LocalizationProvider>
  );
}

function MultiCheckRecordEntryEditField({
  options,
  onChange,
  disabled: allDisabled,
}: BaseRecordEntryEditProps & Omit<MultiCheckRecordEntryEditProps, 'type'>) {
  return (
    <FormGroup
      sx={{
        paddingTop: 1,
        alignSelf: 'flex-start',
      }}
    >
      {options.map(({ key, label, value, disabled }) => (
        <FormControlLabel
          disabled={allDisabled || disabled}
          control={
            <Checkbox
              disabled={allDisabled || disabled}
              checked={value}
              onChange={(_, value) => onChange(key, value)}
            />
          }
          label={
            <Typography variant="body2" fontWeight="500">
              {label}
            </Typography>
          }
        />
      ))}
    </FormGroup>
  );
}

function OptionsAndTextRecordEntryEditField({
  hasError,
  editing,
  options,
  text,
}: BaseRecordEntryEditProps &
  Omit<OptionsAndTextRecordEntryEditProps, 'type'>) {
  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <Box minWidth="128px" maxWidth="128px">
        <OptionsRecordEntryEditField
          editing={editing}
          {...options}
          hasError={hasError}
        />
      </Box>
      <TextRecordEntryEditField
        editing={editing}
        {...text}
        hasError={hasError}
      />
    </Box>
  );
}

type MapperToOptionsArgs = {
  mapper: Mapper;
  sortBy?: 'label' | 'value';
  getLabel?: (code: string | number) => string;
  filter?: (code: string | number) => boolean;
};

export function mapperToOptions({
  mapper,
  getLabel,
  sortBy = 'value',
  filter = () => true,
}: MapperToOptionsArgs): OptionsRecordEntrySelectOption[] {
  const labelMapper = getLabel ?? mapper.getByCode.bind(mapper);

  type Sorter = (
    a: OptionsRecordEntrySelectOption,
    b: OptionsRecordEntrySelectOption,
  ) => number;

  const sorter: Sorter =
    sortBy === 'value'
      ? (a, b) => sortValue(a.value, b.value)
      : (a, b) => sortValue(a.label, b.label);

  return mapper
    .codes()
    .filter(filter)
    .map((code) => ({
      label: labelMapper(code),
      value: code,
    }))
    .sort(sorter);
}

function sortValue<T extends string | number>(a: T, b: T): number {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return 0;
}
