import { z } from 'zod';

import { IndicativoAdmissao } from '@octopus/esocial/mapper';

const indAdmissaoTypeSchema = IndicativoAdmissao.codes().reduce(
  (acc, type) => {
    return { ...acc, [`indAdmissao_${type}`]: z.string().optional() };
  },
  {} as Record<string, z.ZodType>,
);

export const indicativoAdmissaoSchema = z
  .object({
    ...indAdmissaoTypeSchema,
    nrProcTrab: z.string().length(20).optional(),
  })
  .superRefine((formState, ctx) => {
    const [indAdmissao, nrProcTrab] = Object.values(
      formState || {},
    ) as string[];

    if (indAdmissao === '3' && !nrProcTrab) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obrigatório',
        path: ['nrProcTrab'],
      });
    }

    return;
  });
