import { DayOfWeek, LocalDate } from '@js-joda/core';
import Holidays from 'date-holidays';

const HOLIDAYS = new Holidays('BR', undefined, undefined, {
  types: ['public', 'bank', 'optional'],
});
HOLIDAYS.setTimezone(undefined);

export function findNearestBankingDate(limitPaymentDate: LocalDate): string {
  let paymentDate = limitPaymentDate;
  while (!isBankingDay(paymentDate)) {
    paymentDate = paymentDate.minusDays(1);
  }

  return paymentDate.toString();
}

export function isBankingDay(date: LocalDate): boolean {
  const dayOfWeek = date.dayOfWeek();
  if (dayOfWeek === DayOfWeek.SATURDAY || dayOfWeek === DayOfWeek.SUNDAY) {
    return false;
  }
  const dateTime = date.atTime(18, 0).toString();
  return !HOLIDAYS.isHoliday(dateTime);
}
