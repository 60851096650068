export function capitalize(
  str: string | undefined,
  lower = true,
  firstWordOnly = false,
): string | undefined {
  if (!str) {
    return str;
  }
  const allWords = /(?:^|\s|["'([{])+\S/g;
  const firstWord = /(?:^|["'([{])+\S/g;
  return (lower ? str.toLowerCase() : str).replace(
    firstWordOnly ? firstWord : allWords,
    (match) => match.toUpperCase(),
  );
}

export function getOnlyDigits(value: string | undefined): string | undefined {
  return value?.replace(/\D/g, '');
}

export function formatUUID(value: string | undefined): string | undefined {
  if (!value) {
    return undefined;
  }
  const valueWithoutDashes = value.replace(/-/g, '');
  if (valueWithoutDashes.length < 20) {
    return value;
  }
  return (
    valueWithoutDashes.slice(0, 8) +
    '-' +
    valueWithoutDashes.slice(8, 12) +
    '-' +
    valueWithoutDashes.slice(12, 16) +
    '-' +
    valueWithoutDashes.slice(16, 20) +
    '-' +
    valueWithoutDashes.slice(20)
  );
}
