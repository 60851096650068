import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQueries, useQuery } from '@tanstack/react-query';

import { BeachAccessOutlined, Edit } from '@mui/icons-material';
import {
  Box,
  Container,
  Divider,
  Drawer,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  PayrollElement,
  PayrollEntry,
  PayrollPayslipEntry,
  VacationsScheduleEntry,
  fetchGetPayroll,
  fetchGetPayrollPayslip,
  fetchGetVacationsSchedule,
  useApprovePayroll,
  useArchivePayroll,
  useChangePayrollPaymentDate,
} from '@octopus/api';
import { capitalize, formatDateBR, formatMoney } from '@octopus/formatters';
import { Tag } from '@octopus/ui/design-system';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { ActionMenu } from '../../../../modules/components/ActionMenu';
import { BackButton } from '../../../../modules/components/BackButton';
import DoubleColumnListRow from '../../../../modules/components/DoubleColumnListRow';
import { CalculationExplanation } from '../../../../modules/components/payrolls/CalculationExplanation';
import { ElementsTable } from '../../../../modules/components/payrolls/ElementsTable';
import { SendStatus } from '../../../../modules/components/SendStatus';
import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { downloadPayslipPdf } from '../../../../modules/pdf/payslips';
import { useSendPayslip } from '../../../payrolls/SendPayslips';
import { ActionBar } from '../../new/PageActionsBar';
import { pollingPayroll, pollingSearch } from '../../utils/polling';
import { ConfirmModal } from '../new/modal/PayrollApprovalModal';
import { RejectModal } from '../new/modal/PayrollRejectModal';

import { ChangePaymentDateModal } from './modal/SchedulePayrollModal';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function VacationReceiptPage({ organizationId, companyId }: VacationsProps) {
  const [isOpen, setIsOpen] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const { contractId } = useParams<{
    contractId: string;
  }>();
  const { showSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const sequence = searchParams.get('sequence');
  const getVacationsSchedule = () =>
    fetchGetVacationsSchedule({
      pathParams: {
        organizationId,
        contractId,
        sequence,
      },
    });

  const result = useQuery({
    queryKey: ['vacationsSchedule', organizationId, contractId, sequence],
    queryFn: getVacationsSchedule,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { payrollId } = result?.data || {};
  const enabled = !!organizationId && !!companyId && !!payrollId;
  const pathParams = {
    companyId,
    organizationId,
    payrollId,
  };
  const defaultParams = {
    enabled,
    refetchOnWindowFocus: false,
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ['payroll', pathParams],
        queryFn: () => fetchGetPayroll({ pathParams }),
        ...defaultParams,
      },
      {
        queryKey: ['payrollPayslip', pathParams],
        queryFn: () => fetchGetPayrollPayslip({ pathParams }),
        retry: false,
        ...defaultParams,
      },
    ],
  });
  const [
    { data: payroll, refetch: payrollRefetch },
    { data: payslip, refetch: payslipRefetch },
  ] = results;
  const { data: vacation } = result;
  const isLoading =
    results.some((result) => result.isLoading) || result.isLoading;

  const onClickApprove = () => setIsOpen('approve');
  const onClickReject = () => setIsOpen('reject');

  const navigate = useNavigate();
  const archivePayrollMutation = useArchivePayroll();
  const approvePayrollMutation = useApprovePayroll();

  const waitingStatusChange = ({
    status,
    contractId,
  }: {
    status: string;
    contractId: string;
  }) => {
    return () =>
      pollingSearch({
        contractId,
        organizationId,
        id: `${organizationId}|${contractId}|${sequence}`,
        vacationsScheduleStatusesList: [status],
      }).then(() => Promise.all([payrollRefetch(), payslipRefetch()]));
  };

  const waitingPayrollStatusChange = () => {
    setIsPolling(true);
    return pollingPayroll({
      companyId,
      organizationId,
      payrollId,
    })
      .then(() => Promise.all([payrollRefetch()]))
      .then(() => setIsPolling(false));
  };

  const archivePayroll = async ({ contractId }: PayrollEntry) => {
    setIsSubmitting(true);
    await archivePayrollMutation
      .mutateAsync({
        pathParams,
      })
      .then(
        waitingStatusChange({
          contractId,
          status: vacationsScheduleStatuses.payrollArchived,
        }),
      )
      .then(() => {
        setIsSubmitting(false);
        navigate('/vacations');
      })
      .catch(() => {
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao reprovar a folha de férias',
          hasCloseAction: true,
        });
        setIsSubmitting(false);
      });
  };

  const approvePayroll = async ({ contractId }: PayrollEntry) => {
    setIsSubmitting(true);
    await approvePayrollMutation
      .mutateAsync({
        pathParams,
      })
      .then(
        waitingStatusChange({
          contractId,
          status: vacationsScheduleStatuses.payrollApproved,
        }),
      )
      .then(() => {
        setIsSubmitting(false);
        navigate('/vacations');
      })
      .catch(() => {
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao aprovar a folha de férias',
          hasCloseAction: true,
        });
        setIsSubmitting(false);
      });
  };

  if (isLoading || isPolling) {
    return <VacationSkeleton />;
  }

  return (
    <>
      <Box>
        <BackButton destination="/vacations" />
      </Box>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: '800px',
        }}
      >
        {payroll && (
          <VacationsReceipt
            organizationId={organizationId}
            companyId={companyId}
            payroll={payroll}
            vacation={vacation}
            payslip={payslip}
            onChangePaymentDate={waitingPayrollStatusChange}
          />
        )}
      </Container>
      {payroll?.status === 'open' && (
        <>
          <ActionBar>
            <ActionBar.Action
              variantSemantic="secondary"
              onClick={onClickReject}
              sx={{
                marginRight: 1,
              }}
            >
              Recusar
            </ActionBar.Action>
            <ActionBar.Action
              sx={{ minWidth: '110px' }}
              onClick={onClickApprove}
            >
              Aprovar
            </ActionBar.Action>
          </ActionBar>
          <ConfirmModal
            open={isOpen === 'approve'}
            isSubmitting={isSubmitting}
            onCancel={() => setIsOpen(null)}
            onConfirm={() => approvePayroll(payroll)}
          />
          <RejectModal
            open={isOpen === 'reject'}
            isSubmitting={isSubmitting}
            onCancel={() => setIsOpen(null)}
            onConfirm={() => archivePayroll(payroll)}
          />
        </>
      )}
    </>
  );
}

export function VacationsDetails({
  payroll,
  vacation,
}: {
  payroll: PayrollEntry;
  vacation: VacationsScheduleEntry;
}) {
  const { accrualPeriod, startDate, endDate, daysTaken, daysSold } = vacation;
  const diasAbonados = `${daysSold} dias`;

  return (
    <Box key="vacation-details">
      <Typography variant="h3" color="text.primary">
        Detalhes das férias
      </Typography>
      {[
        [
          'Período de aquisição',
          `${formatDateBR(accrualPeriod.startDate)} -
          ${formatDateBR(accrualPeriod.endDate)}`,
        ],
        ['Dias a exercer', `${daysTaken} dias`],
        ['Dias abonados', diasAbonados],
        [
          'Período de gozo',
          `${formatDateBR(startDate, { includeDayName: true })} -
          ${formatDateBR(endDate, { includeDayName: true })}`,
        ],
      ].map(([label, value]) => (
        <>{DoubleColumnListRow(label, value)}</>
      ))}
      <Divider sx={{ my: 1.5 }} />
      <>
        {DoubleColumnListRow(
          'Valor do pagamento',
          formatMoney(payroll.outputs?.netPay.total),
          {
            value: {
              sx: {
                fontWeight: 700,
              },
            },
          },
        )}
      </>
      <>
        {DoubleColumnListRow(
          'Data de pagamento',
          formatDateBR(vacation.paymentDate),
        )}
      </>
    </Box>
  );
}

export function ElementsTables({ payroll }: { payroll: PayrollEntry }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [elementStack, setElementStack] = useState<PayrollElement[]>([]);

  const onClickElement = (element: PayrollElement) => {
    setElementStack((state) => [
      ...state,
      payroll.outputs?.elements[element.id],
    ]);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Box
        borderRadius={1.5}
        borderColor="strokes.light"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <Box px={2}>
          <Typography
            variant="h4"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Proventos
          </Typography>
          <ElementsTable
            payroll={payroll}
            summary="workerEarningsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />

          <Typography
            variant="h4"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Descontos
          </Typography>
          <ElementsTable
            payroll={payroll}
            summary="workerDeductionsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />
        </Box>

        <Box px={4} pt={3}>
          <Divider />
        </Box>

        <Box px={2} py={3} display="flex" justifyContent="space-between">
          <table
            width="100%"
            style={{
              tableLayout: 'fixed',
              borderCollapse: 'collapse',
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: '16px' }} role="presentation" />
                <td style={{ width: '70%' }}>
                  <Typography variant="h4" color="text.primary">
                    Valor líquido total
                  </Typography>
                </td>
                <td style={{ width: '30%' }}>
                  <Typography variant="h4" color="text.primary">
                    {formatMoney(payroll.outputs?.netPay?.total)}
                  </Typography>
                </td>
                <td style={{ width: '16px' }} role="presentation" />
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>

      <Drawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        elevation={2}
      >
        <Box
          width="520px"
          height="100%"
          sx={{
            opacity: elementStack.length > 0 ? 1 : 0,
            transition: 'all 0.2s',
            backgroundColor: 'background.default',
            overflowY: 'overlay',
          }}
        >
          <CalculationExplanation
            element={elementStack[elementStack.length - 1]}
            canGoBack={elementStack.length > 1}
            goBack={() => setElementStack((state) => state.slice(0, -1))}
            onClickElement={onClickElement}
            onExit={() => {
              setElementStack([]);
              setIsDrawerOpen(false);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

export function VacationsReceipt({
  organizationId,
  companyId,
  payroll,
  payslip,
  vacation,
  onChangePaymentDate,
}: {
  organizationId: string;
  companyId: string;
  payroll: PayrollEntry;
  payslip: PayrollPayslipEntry;
  vacation: VacationsScheduleEntry;
  onChangePaymentDate: () => void;
}) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const pathParams = {
    companyId,
    organizationId,
    payrollId: payroll.payrollId,
  };
  const archivePayrollMutation = useArchivePayroll();
  const changePaymentDateMutation = useChangePayrollPaymentDate();
  const { sendPayslipsProps, sendPayslips, SendPayslipsComponent } =
    useSendPayslip({
      organizationId,
      companyId,
    });
  const onClickArchivePayroll = () => {
    archivePayrollMutation.mutate({
      pathParams,
    });
  };

  useEffect(() => {
    if (archivePayrollMutation.isSuccess) {
      navigate(-1);
    }
  }, [navigate, archivePayrollMutation.isSuccess]);

  return (
    <Box pt={7} pb={14} data-testid="vacations-receipt">
      <ChangePaymentDateModal
        isSubmitting={changePaymentDateMutation.isLoading}
        open={isOpen}
        summary={payroll}
        onCancel={() => setIsOpen(false)}
        onConfirm={(_, formData) => {
          changePaymentDateMutation.mutate(
            {
              pathParams,
              body: {
                paymentDate: formData.payload.paymentDate as string,
                version: payroll.version,
              },
            },
            {
              onSuccess: () => {
                onChangePaymentDate();
                setIsOpen(false);
              },
              onError: () => {
                showSnackbar({
                  isOpen: true,
                  variant: 'error',
                  Message: 'Erro ao alterar a data de pagamento',
                  hasCloseAction: true,
                });
              },
            },
          );
        }}
      />
      <SendPayslipsComponent {...sendPayslipsProps} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BeachAccessOutlined
            fontSize="inherit"
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
          &nbsp; Recibo de Férias
        </Typography>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {payroll.status === 'open' && (
            <Tag
              left={<Edit />}
              emphasis="high"
              color="default"
              slotProps={{
                tooltip: {
                  title:
                    'A prévia é um estado temporário da folha de férias para a sua conferência, ela não tem impacto na folha mensal e na folha de férias, para que seja efetivada você precisa aprovar a prévia.',
                  placement: 'bottom-end',
                  PopperProps: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  },
                },
              }}
            >
              Prévia
            </Tag>
          )}
          {payslip?.status === 'approved' && payroll?.status !== 'open' && (
            <SendStatus
              isSent={false}
              NotSentMessage={() => <> Não enviado </>}
            />
          )}
          {payslip?.status === 'sent' && (
            <SendStatus isSent={true} SentMessage={() => <> Enviado </>} />
          )}
          <ActionMenu
            sx={{
              '--ActionMenu-button-margin': '0px 0px 0px 8px',
            }}
            variant="button"
            menuItems={[
              payroll?.status === 'open' && {
                label: 'Excluir',
                isDisabled: archivePayrollMutation.isLoading,
                isPreventingClose: archivePayrollMutation.isLoading,
                onClick: onClickArchivePayroll,
              },
              payroll?.status !== 'open' &&
                payslip?.status === 'approved' && {
                  label: 'Baixar recibo',
                  onClick: () => downloadPayslipPdf(payslip),
                },
              payroll?.status !== 'open' &&
                payslip?.status === 'approved' && {
                  label: 'Enviar recibo',
                  onClick: () => {
                    sendPayslips({ payrollId: payroll.payrollId });
                  },
                },
              {
                label: 'Alterar data de pagamento',
                onClick: () => setIsOpen(true),
              },
            ]}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="h1" color="text.primary">
          {payroll.workerData.name}
        </Typography>
        <Typography variant="caption" component="p" color="text.secondary">
          {capitalize(payroll.workerData.jobTitle, true, true)} |{' '}
          {formatMoney(payroll.workerData.salary)}/mês
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Admissão em {formatDateBR(payroll.workerData.admissionDate)}
        </Typography>
      </Box>
      <Divider light sx={{ my: 3 }} />
      <VacationsDetails payroll={payroll} vacation={vacation} />
      <Box pt={6} />
      <ElementsTables payroll={payroll} />
    </Box>
  );
}

const VacationSkeleton = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: '800px',
      }}
    >
      <Box pt={12}>
        <Typography variant="h3" color="text.primary">
          <Skeleton width={200} />
        </Typography>
        <Typography variant="h1" color="text.primary">
          <Skeleton width="100%" />
        </Typography>
        <Skeleton width={200} />
        <Skeleton width={100} />
        <Divider sx={{ my: 2 }} />
        <Skeleton variant="rounded" height={300} />
        <Divider sx={{ my: 2 }} />
        <Skeleton variant="rounded" height={300} />
      </Box>
    </Container>
  );
};

export default VacationReceiptPage;
