import { Box, SxProps, Typography } from '@mui/material';

function DoubleColumnListRow(
  label: string,
  value: string,
  slotProps?: { label?: { sx?: SxProps }; value?: { sx?: SxProps } },
) {
  return (
    <Box key={label} sx={{ display: 'flex', alignItems: 'start', my: 1 }}>
      <Typography
        variant="body2"
        color="text.primary"
        sx={{ flex: 1, ...slotProps?.label?.sx }}
      >
        {label}
      </Typography>
      <Typography
        variant="body2"
        sx={{ flex: 1, textAlign: 'right', ...slotProps?.value?.sx }}
      >
        {value}
      </Typography>
    </Box>
  );
}

export default DoubleColumnListRow;
