import { Box } from '@mui/material';

import {
  ChangeMembership,
  MembershipContext,
  MembershipOption,
} from '../../../modules/types';

import { MembershipSelector } from './membershipSelector';

export function SidebarHeader({
  open,
  membership,
  membershipOptions,
  changeMembership,
}: {
  open: boolean;
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
  changeMembership: ChangeMembership;
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      sx={(theme) => ({
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'divider',
        transition: theme.transitions.create('height', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      })}
    >
      <MembershipSelector
        open={open}
        membership={membership}
        membershipOptions={membershipOptions}
        changeMembership={changeMembership}
      />
    </Box>
  );
}
