import React, { ReactElement } from 'react';

import { useMultiStepView } from './useMultiStepView';

type IMultiStepViewRootProps<
  TStepNames extends string = string,
  TStepData = unknown,
  TMSView extends ReturnType<
    typeof useMultiStepView<TStepNames, TStepData>
  > = ReturnType<typeof useMultiStepView<TStepNames, TStepData>>,
> = {
  multiStepView: TMSView;
  children: ReactElement<
    IMultiStepViewChildProps<TMSView['steps'][number]['name'], TStepData>,
    typeof MultiStepView.Step<TStepNames, TStepData>
  >[];
};

type IMultiStepViewChildProps<
  TStepNames extends string = string,
  TStepData = unknown,
> = React.PropsWithChildren &
  TStepData & {
    name: TStepNames;
  };

export function MultiStepView<
  const TStepNames extends string = string,
  const TStepData = unknown,
>(props: IMultiStepViewRootProps<TStepNames, TStepData>) {
  const multiStepView = props.multiStepView;

  const StepsElements = React.Children.map(props.children, (childStep) => {
    const isCurrentStep =
      childStep.props.name === multiStepView.currentStep?.name;
    return !isCurrentStep ? (
      <div style={{ display: 'none' }}>{childStep}</div>
    ) : (
      childStep
    );
  });

  return StepsElements;
}

MultiStepView.Step = function MultiStepViewStep<
  const TStepNames extends string = string,
  const TStepData = unknown,
>(props: IMultiStepViewChildProps<TStepNames, TStepData>) {
  return props.children;
};
