import { ChangeEvent, useEffect, useState } from 'react';

import { useMaskito } from '@maskito/react';
import { CustomCellEditorProps } from 'ag-grid-react';

import { Box, TextField } from '@mui/material';

import { PayrollInputsPayloadTypes } from '@octopus/api';
import { formatDecimal, formatMoney } from '@octopus/formatters';

import {
  getInputPropsForPayloadType,
  getRegexForPayloadType,
  parseValueForPayloadType,
} from './utils';

export type InputCellEditorProps = CustomCellEditorProps<unknown, string> & {
  payloadType: PayrollInputsPayloadTypes;
};

export function InputCellEditor(props: InputCellEditorProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
      height="100%"
      boxSizing="border-box"
      overflow="hidden"
    >
      {renderCellEditor(props.payloadType, props)}
    </Box>
  );
}

function renderCellEditor(
  payloadType: PayrollInputsPayloadTypes,
  props: InputCellEditorProps,
) {
  switch (payloadType) {
    case 'currency':
      return <CurrencyEditor {...props} />;
    case 'number':
      return <NumberEditor {...props} />;
    case 'hours':
      return <HoursEditor {...props} />;
    case 'percentage':
      return <PercentageEditor {...props} />;
    default:
      return (
        <TextField
          fullWidth
          value={props.value}
          autoFocus
          onChange={({ target: { value } }) => props.onValueChange(value)}
        />
      );
  }
}

function CurrencyEditor(props: InputCellEditorProps) {
  const regex = getRegexForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: {
      mask: regex,
    },
  });
  const [value, setValue] = useState(() => {
    if (regex.test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    return formatMoney(props.initialValue, { withCurrency: false });
  });
  useEffect(
    () =>
      props.onValueChange(parseValueForPayloadType(value, props.payloadType)),
    [value],
  );
  return (
    <TextField
      fullWidth
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
    />
  );
}

function NumberEditor(props: InputCellEditorProps) {
  const regex = getRegexForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: {
      mask: regex,
    },
  });
  const [value, setValue] = useState(() => {
    if (regex.test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    return formatMoney(props.initialValue, { withCurrency: false });
  });
  useEffect(
    () =>
      props.onValueChange(parseValueForPayloadType(value, props.payloadType)),
    [value],
  );
  return (
    <TextField
      fullWidth
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
    />
  );
}

function HoursEditor(props: InputCellEditorProps) {
  const regex = getRegexForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: {
      mask: regex,
    },
  });
  const [value, setValue] = useState(() => {
    if (regex.test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    const [hours, minutes] = props.initialValue.split(':');
    return `${hours.padStart(2, '0')}:${minutes?.padEnd(2, '0') ?? '00'}`;
  });
  useEffect(() => {
    props.onValueChange(parseValueForPayloadType(value, props.payloadType));
  }, [value]);
  return (
    <TextField
      fullWidth
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
    />
  );
}

function PercentageEditor(props: InputCellEditorProps) {
  const regex = getRegexForPayloadType(props.payloadType);
  const maskito = useMaskito({
    options: {
      mask: regex,
    },
  });
  const [value, setValue] = useState(() => {
    if (regex.test(props.eventKey)) {
      return props.eventKey;
    }
    if (!props.initialValue) {
      return '';
    }
    return formatDecimal(props.initialValue);
  });
  useEffect(
    () =>
      props.onValueChange(parseValueForPayloadType(value, props.payloadType)),
    [value],
  );
  return (
    <TextField
      fullWidth
      value={value}
      autoFocus
      onInput={(event: ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value)
      }
      InputProps={getInputPropsForPayloadType(props.payloadType)}
      ref={maskito}
    />
  );
}
