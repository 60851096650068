import React from 'react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export function SecurePasswordValidator({
  hasMinLength,
  hasUpperCase,
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
}: {
  hasMinLength: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
}) {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap={1.5}>
      <Typography variant="body2" fontWeight="700">
        Sua senha precisa ter:
      </Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        <CheckedItem success={hasMinLength} label="Mínimo de 12 caracteres" />
        <CheckedItem
          success={hasLowerCase}
          label="Pelo menos uma letra minuscula"
        />
        <CheckedItem
          success={hasUpperCase}
          label="Pelo menos uma letra maiuscula"
        />
        <CheckedItem success={hasNumber} label="Pelo menos um número" />
        <CheckedItem
          success={hasSpecialCharacter}
          label="Pelo menos um simbolo"
        />
      </Box>
    </Box>
  );
}

function CheckedItem({ success, label }: { success: boolean; label: string }) {
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      {success ? <CheckCircle color="success" /> : <Cancel color="error" />}
      <Box>
        <Typography variant="body2">{label}</Typography>
      </Box>
    </Box>
  );
}
