import { useRef, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Badge,
  Box,
  IconButton,
  Link,
  Popover,
  Typography,
} from '@mui/material';

import { Utils } from './utils';

type ContractHistoryButtonProps = {
  onHistorySelected: () => void;
  scheduledEvents?: number;
};

export function ContractEventsHistoryButton({
  onHistorySelected,
  scheduledEvents,
}: ContractHistoryButtonProps) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { hoveredStyle, hoverEventHandlers } = Utils.Hooks.useHoverBackground();
  const close = () => setOpen(false);
  const open = () => setOpen(true);
  const hasScheduledEvents = scheduledEvents > 0;

  const menuRef = useRef(null);

  return (
    <>
      {scheduledEvents && scheduledEvents > 0 ? (
        <Badge badgeContent={scheduledEvents} color="primary">
          <IconButton
            size="small"
            onClick={open}
            sx={{ borderRadius: 1 }}
            ref={menuRef}
          >
            <MoreVertIcon />
          </IconButton>
        </Badge>
      ) : (
        <IconButton
          size="small"
          onClick={open}
          sx={{ borderRadius: 1 }}
          ref={menuRef}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Popover
        open={isOpen}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box
          display="flex"
          {...hoverEventHandlers}
          sx={{
            height: '32px',
            width: !hasScheduledEvents ? '220px' : undefined,
            py: 1,
            px: 0,
          }}
        >
          <Link
            component="button"
            underline="none"
            textAlign="left"
            sx={(theme) => ({
              ...hoveredStyle(theme),
              padding: 0,
              width: !hasScheduledEvents ? '100%' : undefined,
            })}
            onClick={(event) => {
              event.stopPropagation();
              setOpen(false);
              onHistorySelected();
            }}
          >
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{ px: 2, py: 0.5 }}
            >
              Histórico
            </Typography>
          </Link>
          {hasScheduledEvents ? (
            <Link
              component="button"
              underline="none"
              textAlign="left"
              sx={(theme) => ({
                ...hoveredStyle(theme),
                width: '100%',
                padding: 0,
              })}
              onClick={(event) => {
                event.stopPropagation();
                setOpen(false);
                onHistorySelected();
              }}
            >
              <Typography
                variant="body2"
                color="primary"
                sx={{ px: 2, py: 0.5 }}
              >
                {scheduledEvents} agendamentos
              </Typography>
            </Link>
          ) : undefined}
        </Box>
      </Popover>
    </>
  );
}
