import { createFFlagsContext } from './createFFlagsContext';
import { createFFlagsHook } from './createFFlagsHook';
import { createFFlagsResolver } from './createFFlagsResolver';
import { TFlagNameSetup } from './TFlagNameSetup';

type TFFlags<TFlagName extends string> = {
  FFlagsProvider: ReturnType<
    typeof createFFlagsContext<TFlagName>
  >['FFlagsProvider'];
  useFFlags: ReturnType<typeof createFFlagsHook<TFlagName>>;
  resolveFFlags: ReturnType<typeof createFFlagsResolver<TFlagName>>;
};

export function createFFlags<const TFlagName extends string>(
  flagNames: TFlagNameSetup<TFlagName>,
): TFFlags<TFlagName> {
  const { FFlagsProvider, flagsContext } = createFFlagsContext(flagNames);
  const useFFlags = createFFlagsHook(flagNames, flagsContext);
  const resolveFFlags = createFFlagsResolver(flagNames, flagsContext);
  return {
    FFlagsProvider,
    useFFlags,
    resolveFFlags,
  };
}
