import { useState } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { CheckCircle, ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  VacationsAccrualPeriodEntry,
  VacationsScheduleEntry,
} from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { SnackbarType } from '../../hooks/snackbarContext';
import { useSnackbar } from '../../hooks/useSnackbar';

const errorSnackbar: SnackbarType = {
  isOpen: true,
  variant: 'error',
  Message: 'Ocorreu um erro, por favor tente novamente.',
  hasCloseAction: true,
};

const getSuccessSnackbar = (message: string): SnackbarType => ({
  isOpen: true,
  variant: 'default',
  Message: message,
  StartAdornment: <CheckCircle />,
  autoHideDuration: 5000,
  hasCloseAction: false,
});

export function DatePickerDialog({
  open,
  setOpen,
  value,
  onChange,
  minDate,
  maxDate,
  disableWeekends,
  accrualPeriod,
  minAntecipation,
  daysBeforeDSR,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: dayjs.Dayjs | null;
  onChange: (date: dayjs.Dayjs | null) => void;
  minDate: dayjs.Dayjs;
  minAntecipation: number;
  daysBeforeDSR: number;
  maxDate: dayjs.Dayjs;
  disableWeekends: (date: dayjs.Dayjs) => boolean;
  accrualPeriod: VacationsAccrualPeriodEntry;
}) {
  const [dateSelected, setDateSelected] = useState(value);
  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', pb: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={value}
          onChange={setDateSelected}
          minDate={minDate}
          maxDate={maxDate}
          shouldDisableDate={disableWeekends}
          disableHighlightToday={true}
          sx={{
            width: '100%',
            '& .MuiPickersLayout-actionBar': {
              display: 'none',
            },
            '& .MuiDayCalendar-weekContainer': {
              justifyContent: 'space-around',
              margin: '8px 0 0 0',
              width: '100%',
            },
            '& .MuiDayCalendar-header': {
              height: '35px',
              justifyContent: 'space-around',
            },
            '& .MuiDayCalendar-root': {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            },
            '& .MuiDateCalendar-root': {
              width: '100%',
            },
            '& .MuiPickersDay-root': {
              fontSize: '1.0rem',
              display: 'flex',
            },
            '& .MuiPickersLayout-root': {
              width: '100%',
              display: 'flex',
            },
          }}
          slots={{
            calendarHeader: ({ onMonthChange, currentMonth }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '16px 0',
                }}
              >
                <IconButton
                  onClick={() => {
                    if (dayjs(currentMonth).isAfter(minDate)) {
                      onMonthChange(
                        dayjs(currentMonth).subtract(1, 'month'),
                        'left',
                      );
                    }
                  }}
                >
                  <ChevronLeft />
                </IconButton>
                <Typography
                  variant="h6"
                  sx={{ fontSize: '18px', fontWeight: 650 }}
                >
                  {dayjs(currentMonth)
                    .locale('pt-br')
                    .format('MMMM YYYY')
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </Typography>
                <IconButton
                  onClick={() => {
                    if (dayjs(currentMonth).add(1, 'month').isBefore(maxDate)) {
                      onMonthChange(
                        dayjs(currentMonth).add(1, 'month'),
                        'right',
                      );
                    }
                  }}
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            ),
          }}
        />
      </LocalizationProvider>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          bgcolor: theme.palette.background.secondary,
          p: 3,
          borderRadius: '16px',
        })}
      >
        <Box>
          <Typography
            variant="body2"
            sx={{ fontSize: '16px', fontWeight: 650 }}
          >
            Requisitos:
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              fontWeight: 450,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {`• Solicitações devem ser feitas com pelo menos ${minAntecipation} dias de
            antecedência`}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              fontWeight: 450,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {`• Não iniciar com ${daysBeforeDSR} ${daysBeforeDSR > 1 ? 'dias' : 'dia'} ou menos antes de descansos remunerados e
            feriados`}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              fontWeight: 450,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {`• As férias devem estar dentro do seu período de concessão: ${dayjs(accrualPeriod.concessionPeriod.startDate).format('DD/MM/YY')}
            a ${dayjs(accrualPeriod.concessionPeriod.endDate).format('DD/MM/YY')}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          py: '8px',
          px: '20px',
          ':hover': {
            backgroundColor: 'strokes.light',
          },
          height: '49px',
          width: '100%',
        }}
        onClick={() => {
          setOpen(false);
        }}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primaryAlt"
        sx={{
          py: '8px',
          px: '20px',
          height: '49px',
          width: '100%',
        }}
        onClick={() => {
          setOpen(false);
          onChange(dateSelected);
        }}
      >
        Aplicar
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '400px', maxWidth: '800px', minHeight: '256px' },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            gap: 1,
            justifyContent: 'flex-start',
          }}
        >
          {actions}
        </DialogActions>
      </Dialog>
      <DatePickerMobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
      />
    </>
  );
}

function DatePickerMobileDrawer({
  content,
  actions,
  setOpen,
  open,
}: {
  open: boolean;
  content: React.ReactNode;
  actions?: React.ReactNode;
  setOpen?: (open: boolean) => void;
}) {
  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={() => {
        if (setOpen) {
          setOpen(false);
        }
      }}
      sx={(theme) => ({
        display: 'flex',
        [theme.breakpoints.up('sm')]: { display: 'none' },
      })}
      transitionDuration={300}
      elevation={2}
      PaperProps={{
        sx: { borderRadius: '16px 16px 0 0 ', width: '100%', height: 'auto' },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        p={3}
        pt={3}
      >
        <Box>{content}</Box>
        {actions && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 2,
              mt: 2,
            }}
          >
            {actions}
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

export function CancelConfirmationDialog({
  open,
  setOpen,
  action,
  onConfirmation,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<VacationsScheduleEntry>;
  onConfirmation: () => void;
}) {
  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setOpen(false);
    setLoading(false);
    console.error(error);
  };
  const [loading, setLoading] = useState(false);

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      borderRadius={2}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={1}
      >
        <Typography fontWeight={700} variant={'h2'}>
          Cancelar solicitação
        </Typography>
        <Typography variant={'body1'} fontWeight={440}>
          Tem certeza de que deseja cancelar sua solicitação de férias? Essa
          ação não pode ser desfeita.
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        color={'error'}
        variant="contained"
        sx={{
          height: '48px',
        }}
        onClick={() => {
          setLoading(true);
          action()
            .then(() => {
              onConfirmation();
              showSnackbar(getSuccessSnackbar('Solicitação cancelada'));
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
              setOpen(false);
            });
        }}
      >
        Cancelar solicitação
      </Button>
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
          height: '48px',
        }}
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-archive-admission-button"
      >
        Manter solicitação
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '400px', maxWidth: '800px', minHeight: '256px' },
        }}
      >
        {loading ? (
          <LoadingScene message={'Cancelando...'} />
        ) : (
          <>
            <DialogContent>{content}</DialogContent>
            <DialogActions
              sx={{
                px: 3,
                flexDirection: 'row-reverse',
                gap: 1,
                justifyContent: 'flex-start',
              }}
            >
              {actions}
            </DialogActions>
          </>
        )}
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export function MobileDrawer({
  content,
  actions,
  setOpen,
  setLoading,
  loading,
  open,
}: {
  open: boolean;
  content: React.ReactNode;
  actions?: React.ReactNode;
  setOpen?: (open: boolean) => void;
  setLoading?: (open: boolean) => void;
  loading?: boolean;
}) {
  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={() => {
        if (setOpen) {
          setOpen(false);
          setLoading && setLoading(false);
        }
      }}
      sx={(theme) => ({
        display: 'flex',
        [theme.breakpoints.up('sm')]: { display: 'none' },
      })}
      transitionDuration={500}
      elevation={2}
      PaperProps={{ sx: { borderRadius: '16px 16px 0 0 ', height: 'auto' } }}
    >
      {loading && <LoadingScene message={'Cancelando'} />}
      {!loading && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          p={3}
          pt={4}
          gap={4}
        >
          <Box height={'70%'}>{content}</Box>
          {actions && (
            <Box
              height={'30%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-end'}
              mt={2}
              mb={1}
              gap={1.5}
            >
              {actions}
            </Box>
          )}
        </Box>
      )}
    </Drawer>
  );
}

function LoadingScene({ message }: { message: string }) {
  return (
    <Box
      sx={(theme) => {
        return {
          [theme.breakpoints.down('md')]: {
            height: '30%',
            mt: '15%',
          },
          [theme.breakpoints.up('sm')]: {
            minWidth: '500px',
            minHeight: '280px',
          },
        };
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="100px">
        <OctopusLoading />
      </Box>
      <Box pt={2} width={'100%'}>
        <Typography variant="h4" textAlign="center">
          {message}
        </Typography>
      </Box>
    </Box>
  );
}
