import { Mapper } from './mapper';

const map = {
  '1': 'acidenteDoencaRelacionadaAoTrabalho',
  '3': 'acidenteDoencaNaoRelacionadaAoTrabalho',
} as const;

export type MotivoAfastamentoEngine = (typeof map)[keyof typeof map];

export const MotivoAfastamentoEngine = Mapper.from(map);
