import { Mapper } from './mapper';

export const NivelEstagio = Mapper.from({
  1: 'Fundamental',
  2: 'Médio',
  3: 'Formação profissional',
  4: 'Superior',
  8: 'Especial',
  9: 'Mãe social (Lei 7.644/1987)',
});
