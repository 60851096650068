import { Typography } from '@mui/material';

type Props = {
  required: boolean;
};

export const RequiredTag = ({ required }: Props) => {
  return required ? (
    <Typography
      variant={'caption'}
      sx={{
        fontSize: '12px',
        fontWeight: 700,
        color: (theme) => theme.palette.error.main,
        marginLeft: 0.5,
      }}
    >
      *
    </Typography>
  ) : null;
};
