import { useDvdScreensaver } from 'react-dvd-screensaver';

import { Box, BoxProps } from '@mui/material';

import Polygon from './Polygon';

export type BouncingPolygonProps = {
  speed?: number;
  size?: number;
  color?: string;
  containerProps?: BoxProps;
  imageProps?: { cursor?: string; onHover?: Record<string, string> } & BoxProps;
  blurProps?: { show?: boolean; rotate?: number } & BoxProps;
};

export default function BouncingPolygon({
  speed = 0.5,
  size = 200,
  color,
  containerProps = {},
  imageProps = {},
  blurProps = {},
}: BouncingPolygonProps) {
  const screensaver = useDvdScreensaver({
    speed,
  });
  const { cursor = 'pointer', onHover = {}, ...imagePropsRest } = imageProps;
  const { show = true, rotate = 0, ...blurPropsRest } = blurProps;
  return (
    <Box {...containerProps} ref={screensaver.containerRef}>
      <Box
        {...imagePropsRest}
        width={`${size}px`}
        height={`${size}px`}
        ref={screensaver.elementRef}
        sx={{
          cursor,
        }}
      >
        <Box
          sx={{
            animation: 'spin 50s infinite linear',
            '@-webkit-keyframes spin': {
              '0%': { '-webkitTransform': 'rotate(0deg)' },
              '100%': { '-webkitTransform': 'rotate(360deg)' },
            },
            transition: 'filter 0.5s',
            ':hover': onHover,
          }}
        >
          <Polygon fill={color} width={size} height={size} />
        </Box>
      </Box>
      {show && (
        <Box
          {...blurPropsRest}
          position="absolute"
          sx={{
            zIndex: 1,
            transform: `rotate(${rotate}deg)`,
            background: 'rgba(247, 247, 248, 0.23)',
            boxShadow: 'inset 0px 52px 72px rgba(30, 120, 255, 0.04)',
            backdropFilter: 'blur(40px)',
          }}
        />
      )}
    </Box>
  );
}
