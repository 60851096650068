import { envVars } from '../../../env';

export type ConfigSwitch<T> = ({ enabled: true } & T) | { enabled: false };
export type EnabledSSOConfig = {
  readonly enabled: true;
  readonly clientId: string;
  readonly authorizationUri: string;
  readonly redirectPath: string;
  readonly scope: string;
};
export type SSOConfig = ConfigSwitch<EnabledSSOConfig>;

const API_REDIRECT_PATH = 'api/user-auth/sso-authorize';
const OAUTH_SCOPES = 'email profile openid';

const config: SSOConfig = {
  enabled: envVars.ssoEnabled === 'true',
  clientId: envVars.ssoClientId,
  authorizationUri: envVars.ssoAuthorizeUri,
  redirectPath: API_REDIRECT_PATH,
  scope: OAUTH_SCOPES,
};

export const getConfig = (): SSOConfig => config;
export const isEnabled = (): boolean => config.enabled;
