import { MutableRefObject, useEffect, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

export type UseAutoSizeTableProps = {
  gridRef: MutableRefObject<AgGridReact<unknown>>;
  show: boolean;
};

export function useAutoSizeTable({ gridRef, show }: UseAutoSizeTableProps) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (show && !initialized && !!gridRef?.current?.api) {
      setInitialized(true);
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [show, initialized, gridRef.current]);
}
