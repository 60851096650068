import { RefObject } from 'react';

import { Box, Typography } from '@mui/material';

import {
  ContractBRCltAddress,
  ContractBRCltDependent,
  ContractBRCltEntryDeficiencia,
  ContractBRCltEntryEndereco,
  ContractBRCltEntryNascimento,
  ContractBRCltEntryPessoa,
  ContractBRCltForeignAddress,
  ContractEntry,
} from '@octopus/api';
import { isBRCltContract } from '@octopus/contract-types';
import {
  CondicaoIngresso,
  EstadoCivil,
  GrauInstrucao,
  Municipios,
  Nacionalidades,
  Paises,
  RacaCor,
  Sexo,
  TempoResidencia,
  TipoDependente,
  TipoLogradouro,
} from '@octopus/esocial/mapper';
import {
  formatBooleanBR,
  formatCEP,
  formatCPF,
  formatDateBR,
  formatPhoneBR,
} from '@octopus/formatters';

import {
  Record,
  RecordEntry,
  RecordEntryGroup,
  RecordGroup,
} from '../../modules/components/Record';
import { QueryResult } from '../../modules/types';

type BrContract = Required<ContractEntry>['br'];

export type PersonalDetailsProps = {
  contractQuery: QueryResult<ContractEntry>;
  showExpandIcon: boolean;
  personalDetailsRef?: RefObject<Element>;
};

export function PersonalDataDetails({
  showExpandIcon,
  personalDetailsRef,
  contractQuery,
}: PersonalDetailsProps) {
  const { isLoading, data: contract } = contractQuery;
  if (!isLoading && (!contract || !contract.br)) {
    return null;
  }
  if (isBRCltContract(contract)) {
    const { pessoa, nascimento, endereco, deficiencia, contato, dependentes } =
      contract?.br || {};
    return (
      <Box ref={personalDetailsRef} data-testid="person-details">
        <RecordGroup showExpandIcon={showExpandIcon} title="Dados Pessoais">
          <InformacoesPessoais pessoa={pessoa} isLoading={isLoading} />
          <Nascimento nascimento={nascimento} isLoading={isLoading} />
          <Endereco endereco={endereco} isLoading={isLoading} />
          <Deficiencia deficiencia={deficiencia} isLoading={isLoading} />
          <Contato contato={contato} isLoading={isLoading} />
          <Dependentes dependentes={dependentes} isLoading={isLoading} />
        </RecordGroup>
      </Box>
    );
  } else {
    const { pessoa, nascimento, endereco, contato, dependentes } =
      contract?.br || {};
    return (
      <Box ref={personalDetailsRef} data-testid="person-details">
        <RecordGroup showExpandIcon={showExpandIcon} title="Dados Pessoais">
          <InformacoesPessoais pessoa={pessoa} isLoading={isLoading} />
          <Nascimento nascimento={nascimento} isLoading={isLoading} />
          <Endereco endereco={endereco} isLoading={isLoading} />
          <Contato contato={contato} isLoading={isLoading} />
          <Dependentes dependentes={dependentes} isLoading={isLoading} />
        </RecordGroup>
      </Box>
    );
  }
}

function InformacoesPessoais({
  pessoa,
  isLoading,
}: {
  pessoa: Partial<ContractBRCltEntryPessoa> | undefined;
  isLoading: boolean;
}) {
  const { nmTrab, nmSoc, nmMae, cpfTrab, sexo, racaCor, estCiv, grauInstr } =
    pessoa ?? {};
  return (
    <Record title="Informações pessoais">
      <RecordEntry label="Nome completo" isLoading={isLoading}>
        {nmTrab}
      </RecordEntry>
      <RecordEntry label="Nome social" isLoading={isLoading}>
        {nmSoc}
      </RecordEntry>
      <RecordEntry label="CPF" isLoading={isLoading}>
        {formatCPF(cpfTrab)}
      </RecordEntry>
      <RecordEntry label="Nome da mãe" isLoading={isLoading}>
        {nmMae}
      </RecordEntry>
      <RecordEntry label="Sexo" isLoading={isLoading}>
        {Sexo.getByCode(sexo)}
      </RecordEntry>
      <RecordEntry label="Raça e cor" isLoading={isLoading}>
        {RacaCor.getByCode(racaCor)}
      </RecordEntry>
      <RecordEntry label="Estado civil" isLoading={isLoading}>
        {EstadoCivil.getByCode(estCiv)}
      </RecordEntry>
      <RecordEntry label="Grau de instrução" isLoading={isLoading}>
        {GrauInstrucao.getByCode(grauInstr)}
      </RecordEntry>
    </Record>
  );
}

function Nascimento({
  nascimento,
  isLoading,
}: {
  nascimento: Partial<ContractBRCltEntryNascimento> | undefined;
  isLoading: boolean;
}) {
  const { dtNascto, paisNascto, paisNac, tmpResid, condIng } = nascimento || {};
  return (
    <Record title="Nascimento">
      <RecordEntry label="Data de nascimento" isLoading={isLoading}>
        {formatDateBR(dtNascto)}
      </RecordEntry>
      <RecordEntry label="País de nascimento" isLoading={isLoading}>
        {Paises.getByCode(paisNascto)}
      </RecordEntry>
      <RecordEntry label="Nacionalidade" isLoading={isLoading}>
        {Nacionalidades.getByCode(paisNac)}
      </RecordEntry>
      <RecordEntry label="Tempo de residência" isLoading={isLoading}>
        {TempoResidencia.getByCode(tmpResid)}
      </RecordEntry>
      <RecordEntry label="Condição de ingresso no país" isLoading={isLoading}>
        {CondicaoIngresso.getByCode(condIng)}
      </RecordEntry>
    </Record>
  );
}

function Endereco({
  endereco,
  isLoading,
}: {
  endereco: ContractBRCltEntryEndereco | undefined;
  isLoading: boolean;
}) {
  if (!endereco || endereco.tipo === 'brasil') {
    return (
      <EnderecoBrasil
        endereco={endereco as ContractBRCltAddress}
        isLoading={isLoading}
      />
    );
  } else {
    return <EnderecoExterior endereco={endereco} />;
  }
}

function Deficiencia({
  deficiencia,
  isLoading,
}: {
  deficiencia: ContractBRCltEntryDeficiencia | undefined;
  isLoading: boolean;
}) {
  const { observacao, reabReadap, infoCota } = deficiencia ?? {};

  if (!deficiencia) {
    return (
      <Record title="Deficiência">
        <RecordEntry label="Possui alguma deficiência?" isLoading={isLoading}>
          <Typography variant="body2" color="strokes.heavy" textAlign="right">
            Não informado
          </Typography>
        </RecordEntry>
      </Record>
    );
  }

  const defs = Object.entries(deficiencia ?? {})
    .filter(([key, _]) => key.startsWith('def'))
    .filter(([_, value]) => value === true)
    .map(([key, _]) => key.slice(3));
  return (
    <Record title="Deficiência">
      <RecordEntry label="Possui alguma deficiência?" isLoading={isLoading}>
        {formatBooleanBR(defs.length > 0)}
      </RecordEntry>
      <RecordEntry label="Quais tipos" isLoading={isLoading}>
        {defs.length > 0 && defs.join(', ')}
      </RecordEntry>
      <RecordEntry label="Descrição" isLoading={isLoading}>
        {observacao}
      </RecordEntry>
      <RecordEntry
        label="Pessoa reabilitada ou readaptada?"
        isLoading={isLoading}
      >
        {formatBooleanBR(reabReadap)}
      </RecordEntry>
      <RecordEntry
        label="Contabilizado para preenchimento de cota?"
        isLoading={isLoading}
      >
        {formatBooleanBR(infoCota)}
      </RecordEntry>
    </Record>
  );
}

function Contato({
  contato,
  isLoading,
}: {
  contato?: BrContract['contato'];
  isLoading: boolean;
}) {
  if (!isLoading && !contato) {
    return null;
  }
  const { fonePrinc, emailPrinc } = contato ?? {};
  if (!isLoading && !fonePrinc && !emailPrinc) {
    return null;
  }
  return (
    <Record title="Contato">
      <RecordEntry label="Telefone" isLoading={isLoading}>
        {formatPhoneBR(fonePrinc)}
      </RecordEntry>
      <RecordEntry label="E-mail" isLoading={isLoading}>
        {emailPrinc}
      </RecordEntry>
    </Record>
  );
}

function Dependentes({
  dependentes,
}: {
  dependentes: Partial<ContractBRCltDependent>[] | undefined;
  isLoading: boolean;
}) {
  if (!dependentes || dependentes.length === 0) {
    return null;
  }
  const getConsiderados = (depIRRF: boolean, depSF: boolean) => {
    if (depIRRF && depSF) {
      return (
        <Typography variant="body2" color="text.primary" textAlign="right">
          Imposto de renda
          <br />
          Salário família
        </Typography>
      );
    } else if (depIRRF) {
      return 'Imposto de renda';
    } else if (depSF) {
      return 'Salário família';
    } else {
      return 'Não é considerado nos cálculos';
    }
  };
  return (
    <Record title="Dependentes">
      {dependentes.map(
        ({
          tpDep,
          nmDep,
          dtNascto,
          cpfDep,
          sexoDep,
          incTrab,
          depIRRF,
          depSF,
        }) => (
          <RecordEntryGroup key={cpfDep} title={nmDep}>
            <RecordEntry label="Parentesco">
              {TipoDependente.getByCode(tpDep)}
            </RecordEntry>
            <RecordEntry label="Data de nascimento">
              {formatDateBR(dtNascto)}
            </RecordEntry>
            <RecordEntry label="CPF">{formatCPF(cpfDep)}</RecordEntry>
            <RecordEntry label="Sexo">{Sexo.getByCode(sexoDep)}</RecordEntry>
            <RecordEntry label="Possui incapacidade física ou mental para trabalho?">
              {formatBooleanBR(incTrab)}
            </RecordEntry>
            <RecordEntry label="Considerado(a) nos cálculos de">
              {getConsiderados(depIRRF, depSF)}
            </RecordEntry>
          </RecordEntryGroup>
        ),
      )}
    </Record>
  );
}

function EnderecoBrasil({
  endereco,
  isLoading,
}: {
  endereco: ContractBRCltAddress | undefined;
  isLoading: boolean;
}) {
  const {
    cep,
    tpLograd,
    dscLograd,
    nrLograd,
    complemento,
    bairro,
    uf,
    codMunic,
  } = endereco ?? {};
  const tipoLogradouro = TipoLogradouro.getByCode(tpLograd);
  const logradouro = tipoLogradouro
    ? `${tipoLogradouro} ${dscLograd}`
    : dscLograd;
  return (
    <Record title="Endereço">
      <RecordEntry label="País" isLoading={isLoading}>
        Brasil
      </RecordEntry>
      <RecordEntry label="CEP" isLoading={isLoading}>
        {formatCEP(cep)}
      </RecordEntry>
      <RecordEntry label="Logradouro" isLoading={isLoading}>
        {logradouro}
      </RecordEntry>
      <RecordEntry label="Número" isLoading={isLoading}>
        {nrLograd}
      </RecordEntry>
      <RecordEntry label="Complemento" isLoading={isLoading}>
        {complemento}
      </RecordEntry>
      <RecordEntry label="Bairro" isLoading={isLoading}>
        {bairro}
      </RecordEntry>
      <RecordEntry label="Unidade Federal" isLoading={isLoading}>
        {uf}
      </RecordEntry>
      <RecordEntry label="Cidade / Município" isLoading={isLoading}>
        {Municipios.getByCode(codMunic)}
      </RecordEntry>
    </Record>
  );
}

function EnderecoExterior({
  endereco,
}: {
  endereco: ContractBRCltForeignAddress | undefined;
}) {
  const {
    paisResid,
    dscLograd,
    nrLograd,
    complemento,
    bairro,
    codPostal,
    nmCid,
  } = endereco;
  return (
    <Record title="Endereço">
      <RecordEntry label="País">{Paises.getByCode(paisResid)}</RecordEntry>
      <RecordEntry label="Código Postal">{codPostal}</RecordEntry>
      <RecordEntry label="Logradouro">{dscLograd}</RecordEntry>
      <RecordEntry label="Número">{nrLograd}</RecordEntry>
      <RecordEntry label="Complemento">{complemento}</RecordEntry>
      <RecordEntry label="Bairro">{bairro}</RecordEntry>
      <RecordEntry label="Cidade / Município">{nmCid}</RecordEntry>
    </Record>
  );
}
