import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Box, Typography } from '@mui/material';

import {
  ContractBRCltEntry,
  PayrollTypes,
  PayrollWorkerData,
} from '@octopus/api';
import {
  formatDateBR,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';

import { formatPayslipType } from '../../formatters';

export type EmployeeInfoProps = {
  payment: {
    period: string;
    type: PayrollTypes;
    workerData: PayrollWorkerData;
  };
  contract: ContractBRCltEntry;
  workerId: string;
};

export function EmployeeInfo({
  payment,
  contract,
  workerId,
}: EmployeeInfoProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="450px"
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          gap: 3,
          px: 2.5,
          pt: 2,
          pb: 3,
        },
        [theme.breakpoints.up('md')]: {
          gap: 1,
        },
      })}
      data-testid="payslip-employee-info"
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            gap: 2,
          },
          [theme.breakpoints.up('md')]: {
            gap: 1,
          },
        })}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <ReceiptLongOutlinedIcon
            sx={{ height: '16px', width: '16px', color: 'text.secondary' }}
          />
          <Typography variant="body2" color="text.secondary" fontWeight="500">
            {formatPayslipType(payment.type)}
          </Typography>
          <Typography variant="body2" color="#BABABF" fontWeight="300">
            •
          </Typography>
          <Typography variant="body2" color="text.secondary" fontWeight="700">
            {formatPeriodDate(payment.period)}
          </Typography>
        </Box>
        <Typography fontSize="1.75rem" fontWeight="700">
          {payment.workerData.name}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box display="flex" gap={1}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            {payment.workerData.jobTitle}
          </Typography>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            |
          </Typography>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            CBO {contract.trabalho.CBOFuncao}
          </Typography>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            |
          </Typography>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            {formatMoney(payment.workerData.salary)} /mês
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            Admissão em {formatDateBR(contract.regime.dtAdm)}
          </Typography>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            |
          </Typography>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            Matrícula {workerId}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
