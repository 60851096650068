import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { logoutUser } from '../../../modules/login';

export function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    logoutUser().then(() => {
      navigate('/login');
    });
  }, []);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      data-testid="logout-title"
    >
      <CircularProgress />
    </Box>
  );
}
