import {
  AdmissionDraftAttachmentType,
  AdmissionDraftInputFormStepEnum,
} from '@octopus/api';
import {
  addressAttachmentTypes,
  dependentAttachmentTypes,
  grauInstrpersonalAttachmentTypes,
  personalAttachmentTypes,
} from '@octopus/onboarding-types';

export type DocumentSectionsType = Partial<{
  [key in AdmissionDraftInputFormStepEnum]: {
    required: boolean;
    title: string;
    description: string;
    allowedTypes: AdmissionDraftAttachmentType[];
  };
}>;

export const documentSections: DocumentSectionsType = {
  dados_pessoais: {
    required: true,
    title: 'Documento de identificação',
    description: 'Preferencialmente RG ou CNH que contenha o numero do CPF.',
    allowedTypes: personalAttachmentTypes,
  },
  endereco_e_contatos: {
    required: true,
    title: 'Comprovante de residência',
    description:
      'Conta de luz, água, gás, telefone fixo ou móvel, internet ou TV a cabo são aceitos.',
    allowedTypes: addressAttachmentTypes,
  },
  formacao_academica: {
    required: false,
    title: 'Certificado de formação',
    description:
      'Caso queira, você pode anexar aqui o comprovante de certificado de formação.',
    allowedTypes: grauInstrpersonalAttachmentTypes,
  },
};

export const documentReviewSections: DocumentSectionsType = {
  ...documentSections,
  dependentes: {
    required: true,
    title: 'Dependente',
    description: '',
    allowedTypes: dependentAttachmentTypes,
  }
}
