import { BiMap } from '@rimbu/core';

export class Mapper {
  protected readonly map: BiMap<number | string, string>;

  constructor(map: BiMap<number | string, string>) {
    this.map = map;
  }

  getByCode(code: number | string | undefined): string | undefined {
    if (code === undefined) {
      return undefined;
    }
    return this.map.getValue(code.toString());
  }

  findByName(name: string): string | undefined {
    const k = this.map.getKey(name);
    if (k === undefined) {
      return undefined;
    }
    return k.toString();
  }

  values(): string[] {
    return this.map.streamValues().toArray();
  }

  codes(): (string | number)[] {
    return this.map.streamKeys().toArray();
  }

  entries(): (readonly [string | number, string])[] {
    return this.map.stream().toArray();
  }

  static from(map: Record<number | string, string>) {
    const builder = BiMap.builder<number | string, string>();
    Object.entries(map).forEach(([key, value]) => builder.set(key, value));
    return new Mapper(builder.build());
  }
}
