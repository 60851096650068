import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import {
  Estados,
  MunicipiosByEstado,
  Paises,
  TipoLogradouro,
} from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  cepSchema,
  nonEmptyString,
  phoneSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../inputUtils';
import {
  AdmissionFormState,
  ENABLED_COUNTRIES,
  FormStepDefinition,
} from '../types';

import { estadoMunicipioSchema } from './enderecoEContatosSchema';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const {
    tpLograd,
    dscLograd,
    nrLograd,
    complemento,
    bairro,
    countryCode,
    uf,
    cep,
    fonePrinc,
  } = formState;

  return [
    {
      label: 'CEP',
      type: cepSchema,
      name: 'cep',
      uiType: UI_TYPE.TEXT,
      placeholder: '00000-000',
      value: cep || '',
      mask: 'cep',
    },
    {
      label: 'Logradouro',
      type: nonEmptyString,
      name: 'tpLograd',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Digite para buscar. Ex: Rua, Avenida...',
      options: getOptionsFromMapper({
        mapper: TipoLogradouro,
        currentValue: tpLograd,
      }),
    },
    {
      label: '',
      type: nonEmptyString,
      name: 'dscLograd',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: dos bonifácios',
      value: dscLograd || '',
      sx: { marginTop: '-24px' },
    },
    {
      label: 'Número',
      type: nonEmptyString,
      name: 'nrLograd',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: 123',
      value: nrLograd || '',
    },
    {
      label: 'Complemento',
      type: z.string().optional(),
      name: 'complemento',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: apto 31',
      value: complemento || '',
    },
    {
      label: 'Bairro',
      type: nonEmptyString,
      name: 'bairro',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: Vila formosa',
      value: bairro || '',
    },
    {
      label: 'País',
      type: nonEmptyString,
      name: 'countryCode',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Digite para buscar o país',
      options: getOptionsFromMapper({
        mapper: Paises,
        currentValue: countryCode,
        enabledKeys: ENABLED_COUNTRIES,
      }),
    },
    {
      label: 'Estado',
      type: estadoMunicipioSchema,
      name: 'uf',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Digite para buscar o estado',
      fieldsOptions: getOptionsFromMapper({
        mapper: Estados,
        currentValue: uf?.defaultValue,
      }).map((option) => ({
        ...option,
        fields: [
          {
            label: 'Cidade / Município',
            type: nonEmptyString,
            name: `codMunic`,
            uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
            placeholder: 'Digite para buscar a cidade',
            options: getOptionsFromMapper({
              mapper: MunicipiosByEstado[option.value],
              currentValue: uf?.defaultCodMunic,
            }),
          },
          {
            label: '',
            hidden: true,
            type: z.string().optional(),
            name: `uf_${option.value}`,
            value: '-',
            uiType: UI_TYPE.TEXT,
          },
        ],
      })),
    },
    {
      label: 'Telefone',
      type: phoneSchema.optional(),
      name: 'fonePrinc',
      uiType: UI_TYPE.TEXT,
      placeholder: '(00) 00000-0000',
      value: fonePrinc || '',
      mask: 'phone',
    },
  ];
};

export const getEnderecoEContatoFields = ({
  formState,
  enderecoFormSteps,
}: {
  formState: AdmissionFormState;
  enderecoFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({ formState }),
  options: {
    id: admissionDraftFormSteps.endereco_e_contatos,
    title: 'Endereço e contato',
    completed: enderecoFormSteps?.completed,
    reviewed: enderecoFormSteps?.reviewed,
  },
});
