import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import {
  ContractEntry,
  ContractOrgStructure,
  useGetContract,
} from '@octopus/api';
import { contractStatuses } from '@octopus/contract-types';

import { ContractSelector } from '../../contracts/ContractSelector';
import { Record, RecordEntry } from '../../Record';
import UserAvatar from '../../UserAvatar';
import { BaseRecordProps } from '../common';
import { useRecordEdit } from '../useRecordEdit';

type EstruturaOrganizacionalData = {
  orgStructure: ContractOrgStructure;
};

export type EstruturaOrganizacionalRecordProps = BaseRecordProps<
  EstruturaOrganizacionalData,
  {
    organizationId: string;
    contractId: string;
  }
>;

export function EstruturaOrganizacionalRecord(
  props: EstruturaOrganizacionalRecordProps,
) {
  const {
    data: { orgStructure },
    parameters: { organizationId, contractId },
  } = props;

  const { editing, editRecordProps, updateData, error } =
    useRecordEdit<EstruturaOrganizacionalData>(props);

  const managerContractQuery = useGetContract(
    {
      pathParams: {
        organizationId,
        contractId: orgStructure?.manager,
      },
    },
    {
      enabled: !!organizationId && !!orgStructure?.manager,
    },
  );
  const managerName =
    managerContractQuery?.data?.br?.pessoa?.nmSoc ??
    managerContractQuery?.data?.br?.pessoa?.nmTrab;

  const setNewManager = (contractId: string | undefined) =>
    updateData(() => ({
      orgStructure: {
        manager: contractId ? contractId : null,
      },
    }));

  return (
    <Record title="Estrutura organizacional" edit={editRecordProps}>
      <RecordEntry
        label="Gestor(a)"
        isLoading={orgStructure?.manager && managerContractQuery.isLoading}
      >
        {editing ? (
          <ManagerEdit
            organizationId={organizationId}
            contractId={contractId}
            managerContract={managerContractQuery.data}
            setNewManager={setNewManager}
            error={error('orgStructure/manager')}
          />
        ) : (
          <ManagerField managerName={managerName} />
        )}
      </RecordEntry>
    </Record>
  );
}

function ManagerField({ managerName }: { managerName: string | undefined }) {
  if (!managerName) {
    return (
      <Typography variant="body2" color="strokes.heavy">
        Sem gestor atribuído
      </Typography>
    );
  }
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <UserAvatar name={managerName} showFullName={false} />
      <Typography variant="body2">{managerName}</Typography>
    </Box>
  );
}

function ManagerEdit({
  organizationId,
  contractId,
  managerContract,
  setNewManager,
  error,
}: {
  organizationId: string;
  contractId: string;
  managerContract: ContractEntry | undefined;
  setNewManager: (contractId: string | undefined) => void;
  error: string | undefined;
}) {
  const [selectedContract, setSelectedContract] = useState(managerContract);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(error);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    setNewManager(selectedContract?.contractId);
    setErrorMessage(undefined);
  }, [selectedContract]);

  return (
    <Box width="100%">
      <ContractSelector
        organizationId={organizationId}
        activeContract={selectedContract}
        setActiveContract={setSelectedContract}
        searchFilters={{
          elements: {
            contractId: [{ not: contractId }],
            status: [{ not: contractStatuses.terminated }],
          },
        }}
        searchBarProps={{
          withStartAdornment: false,
          placeholder: 'Sem gestor atribuído',
        }}
        error={!!errorMessage}
      />
      {errorMessage && (
        <Box pl={1}>
          <Typography variant="caption" color="red">
            {error}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
