import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import {
  ContratoRegimeParcial,
  TipoJornada,
  TipoRegimeJornada,
} from '@octopus/esocial/mapper';
import { IFormDefinition, UI_TYPE, nonEmptyString } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../types';

const getFieldDefinition = ({
  formState,
  hideJornadaFields,
}: {
  formState: AdmissionFormState;
  hideJornadaFields: boolean;
}): IFormDefinition => {
  const { tpRegJor, tpJornada, horNoturno, qtdHrsSem, tmpParc, descJorn } =
    formState;

  return [
    hideJornadaFields
      ? undefined
      : {
          label: 'Regime jornada',
          type: z.number().int(),
          name: 'tpRegJor',
          uiType: UI_TYPE.SELECT,
          placeholder: 'Selecione o regime jornada',
          options: getOptionsFromMapper({
            mapper: TipoRegimeJornada,
            currentValue: tpRegJor,
          }),
        },
    {
      label: 'Tipo de jornada',
      type: z.number().int(),
      name: 'tpJornada',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de jornada',
      options: getOptionsFromMapper({
        mapper: TipoJornada,
        currentValue: tpJornada,
      }),
    },
    hideJornadaFields
      ? undefined
      : {
          label: 'Regime Parcial do Contrato',
          type: z.number().int(),
          name: 'tmpParc',
          uiType: UI_TYPE.SELECT,
          options: getOptionsFromMapper({
            mapper: ContratoRegimeParcial,
            currentValue: tmpParc,
          }),
        },
    {
      label: 'Jornada em horas semanais',
      type: z.number().min(1).max(44),
      name: 'qtdHrsSem',
      uiType: UI_TYPE.TEXT_NUMBER,
      placeholder: 'Ex: 44',
      value: qtdHrsSem,
    },
    {
      label: 'Descrição da Jornada',
      type: nonEmptyString,
      name: 'descJorn',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: Jornada padrão da empresa',
      value: descJorn,
    },
    hideJornadaFields
      ? undefined
      : {
          label: 'Hora noturna',
          type: z.boolean().default(false),
          uiType: UI_TYPE.SELECT,
          name: 'horNoturno',
          options: [
            {
              label: 'Não',
              selected: !horNoturno,
              value: false,
            },
            {
              label: 'Sim',
              selected: horNoturno,
              value: true,
            },
          ],
        },
  ].filter((item) => item);
};

export const getJornadaFields = ({
  formState,
  jornadaFormSteps,
  hideJornadaFields = false,
}: {
  formState: AdmissionFormState;
  jornadaFormSteps: AdmissionDraftInputFormStep;
  hideJornadaFields?: boolean;
}): FormStepDefinition => ({
  definition: getFieldDefinition({ formState, hideJornadaFields }),
  options: {
    id: admissionDraftFormSteps.jornada,
    title: 'Jornada de trabalho',
    completed: jornadaFormSteps?.completed,
    reviewed: jornadaFormSteps?.reviewed,
  },
});
