import { ReactElement } from 'react';

import { Box } from '@mui/material';

import BouncingPolygon from './BouncingPolygon';

export type BouncingHeaderProps = {
  color?: string;
  width?: string;
  height?: number;
  children?: ReactElement;
};

export function BouncingBackground({
  color = '#DBEDFB',
  width = '100%',
  height = 196,
  children,
}: BouncingHeaderProps) {
  return (
    <Box position="relative" width="100%">
      <Box
        position="absolute"
        top="0"
        left="0"
        height={`${height}px`}
        width={width}
        bgcolor={`${color}50`}
        overflow="hidden"
        zIndex={-1}
      >
        <BouncingPolygon
          speed={0.5}
          size={360}
          color={color}
          containerProps={{
            position: 'relative',
            width: `calc(${width} + 500px)`,
            height: `${height + 500}px`,
            top: '-250px',
            left: '-250px',
          }}
          blurProps={{
            width: `calc(${width} + 600px)`,
            height: `${height + 600}px`,
            top: '-300px',
            left: '-300px',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            width: { width },
            height: '80px',
          }}
          zIndex={1}
        ></Box>
      </Box>
      <Box position="relative" zIndex={1000}>
        {children}
      </Box>
    </Box>
  );
}
