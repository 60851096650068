import dayjs from 'dayjs';

import {
  PayrollCalculationOutputsTypeName,
  QueryPayrollCalculationOutputsPathParams,
  QueryPayrollCalculationOutputsRequestBody,
} from '@octopus/api';

export type ISubmitPayrollPayload = {
  pathParams: QueryPayrollCalculationOutputsPathParams;
  body: QueryPayrollCalculationOutputsRequestBody;
};

export function parseFormPayloadToPayrolInput(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formPayload: any,
  outputsType?: PayrollCalculationOutputsTypeName,
): ISubmitPayrollPayload {
  if (!formPayload || typeof formPayload !== 'object') return null;

  const companyId = formPayload.companyId;
  const organizationId = formPayload.organizationId;

  const contractId = `${formPayload.contractId}`;
  const startDateArg = `${formPayload.startDate}`;
  const anticipate13th = JSON.parse(`${formPayload.anticipate13th}`);

  const diasDeFeriasNumber = Number(formPayload.diasDeFerias);

  const diasDeAbonoNumber = Number(formPayload.diasDeAbono);

  const startDate = dayjs(startDateArg);

  const endDate = startDate.add(diasDeFeriasNumber - 1, 'days');

  const startDateString = startDate.format('YYYY-MM-DD');
  const endDateString = endDate.format('YYYY-MM-DD');

  const paymentDate = formPayload.paymentDate
    ? `${formPayload.paymentDate}`
    : undefined;

  const period = paymentDate
    ? dayjs(paymentDate).format('YYYY-MM')
    : startDate.format('YYYY-MM');

  return {
    pathParams: {
      companyId,
      organizationId,
    },
    body: {
      period,
      type: 'vacations' as const,
      contractId,
      outputsType,
      inputs: {
        vacations: {
          startDate: startDateString,
          endDate: endDateString,
          anticipate13th,
          daysSold: diasDeAbonoNumber,
          ...(paymentDate ? { paymentDate } : {}),
          // TODO O PAULO OU O LUCAS VAI RESOLVER ISSO AQUI
          sequence: Math.ceil(Math.random() * 50000),
        },
      },
    },
  };
}
