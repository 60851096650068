import {
  DatePeriodFormat,
  PayrollConfigurationEntry,
  PayrollEntry,
  PayrollPeriodEntry,
  fetchGetCompanyPayrollConfiguration,
  fetchGetPayroll,
  fetchGetPeriod,
  fetchPostCompanyPayrollConfiguration,
  fetchPostPayroll,
  fetchPostPeriod,
  fetchPutPayroll,
  fetchSearchAllPayrolls,
} from '@octopus/api';
import { payrollTypes } from '@octopus/payroll-types';

import {
  ISubmitPayrollPayload,
  parseFormPayloadToPayrolInput,
} from '../adapters/parseFormPayloadToPayrolInput';

function firstDayOnPeriod(period: DatePeriodFormat): string {
  const [year, month] = period.split('-');
  if (month === undefined) {
    return `${year}-12-01`;
  }

  return `${year}-${month}-01`;
}

async function getOrCreateConfiguration({
  organizationId,
  companyId,
  period,
}: {
  organizationId: string;
  companyId: string;
  period: DatePeriodFormat;
}): Promise<
  | {
      data: PayrollConfigurationEntry;
    }
  | {
      error: unknown;
    }
> {
  const effectiveDate = firstDayOnPeriod(period);

  try {
    return {
      data: await fetchGetCompanyPayrollConfiguration({
        pathParams: {
          organizationId,
          companyId,
          effectiveDate,
        },
      }),
    };
  } catch (error) {
    const is404 = error && 'statusCode' in error && error.statusCode === 404;
    if (!is404) {
      return {
        error,
      };
    }
  }

  try {
    return {
      data: await fetchPostCompanyPayrollConfiguration({
        body: {
          modules: ['brazil'],
          effectiveDate,
          basisOverrides: {},
          paymentConfig: {
            baseDay: 5,
            baseMonth: 'next',
          },
        },
        pathParams: {
          organizationId,
          companyId,
        },
      }),
    };
  } catch (error) {
    return {
      error,
    };
  }
}

async function getOrCreatePeriod({
  organizationId,
  companyId,
  period,
}: {
  organizationId: string;
  companyId: string;
  period: DatePeriodFormat;
}): Promise<
  | {
      data: PayrollPeriodEntry;
    }
  | {
      data?: PayrollPeriodEntry;
      error: unknown;
    }
> {
  try {
    return {
      data: await fetchGetPeriod({
        pathParams: {
          organizationId,
          companyId,
          period,
        },
      }),
    };
  } catch (error) {
    const is404 = error && 'statusCode' in error && error.statusCode === 404;
    if (!is404) {
      return {
        error,
      };
    }
  }

  return {
    data: await fetchPostPeriod({
      pathParams: {
        organizationId,
        companyId,
        period,
      },
    }),
  };
}

async function submitPayroll(payload: ISubmitPayrollPayload) {
  const configurationResult = await getOrCreateConfiguration({
    organizationId: payload.pathParams.organizationId,
    companyId: payload.pathParams.companyId,
    period: payload.body.period,
  });

  const periodResult = await getOrCreatePeriod({
    organizationId: payload.pathParams.organizationId,
    companyId: payload.pathParams.companyId,
    period: payload.body.period,
  });

  if ('error' in configurationResult) {
    return configurationResult;
  }

  if ('error' in periodResult) {
    return periodResult;
  }

  const period = periodResult.data;
  if (period && period.status !== 'open') {
    return {
      error: new Error('Período não está aberto'),
    };
  }

  try {
    const searchPayrollsResult = await fetchSearchAllPayrolls({
      body: {
        filtering: {
          elements: {
            contractId: [payload.body.contractId],
            type: [payload.body.type],
            period: [payload.body.period],
          },
        },
      },
      pathParams: {
        organizationId: payload.pathParams.organizationId,
        companyId: payload.pathParams.companyId,
      },
      queryParams: payload,
    });

    const vacationsWithDifferentStartDates =
      searchPayrollsResult.data.length > 0 &&
      searchPayrollsResult.data[0].type === payrollTypes.vacations &&
      searchPayrollsResult.data[0].date !==
        payload.body.inputs.vacations.startDate;

    if (
      searchPayrollsResult.data.length === 0 ||
      vacationsWithDifferentStartDates
    ) {
      return {
        data: await fetchPostPayroll({
          body: payload.body,
          pathParams: payload.pathParams,
        }),
      };
    }

    const [summary] = searchPayrollsResult.data;

    const isApproved = summary.status === 'approved';

    if (isApproved) {
      return {
        error: new Error('Foha aprovada'),
      };
    }

    const payroll = await fetchGetPayroll({
      pathParams: {
        ...payload.pathParams,
        payrollId: summary.payrollId,
      },
    });

    await fetchPutPayroll({
      body: {
        inputs: payload.body.inputs,
        version: payroll.version,
      },
      pathParams: {
        ...payload.pathParams,
        payrollId: summary.payrollId,
      },
    });

    return {
      data: payroll,
    };
  } catch (error) {
    return {
      error,
    };
  }
}

async function getOpenPayroll(payroll: PayrollEntry, pollingWaitTimeMs = 500) {
  const payrollResult = await fetchGetPayroll({
    pathParams: {
      companyId: payroll.companyId,
      organizationId: payroll.organizationId,
      payrollId: payroll.payrollId,
    },
  });

  if (payrollResult.status === 'open') {
    return {
      data: payrollResult,
    };
  }

  if (payrollResult.status === 'calculating') {
    await new Promise((resolve) => {
      setTimeout(() => resolve(undefined), pollingWaitTimeMs);
    });
    return await getOpenPayroll(payrollResult);
  }

  return {
    error: 'Payroll not open',
    data: payrollResult,
  };
}

export async function submitNewVacation(arg: unknown) {
  const payload = parseFormPayloadToPayrolInput(arg);
  const submitPayrollResult = await submitPayroll(payload);
  if (submitPayrollResult.error) {
    return {
      data: submitPayrollResult,
      error: submitPayrollResult.error,
    };
  }

  const getPayslipResult = await getOpenPayroll(submitPayrollResult.data);

  return getPayslipResult;
}
