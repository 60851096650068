import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { MobileDrawer } from '../../../modules/components/onboarding/dialogs';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  isSmallDevice: boolean;
};

export function WorkerSubmitAdmissionDialog({
  open,
  setOpen,
  isSmallDevice,
}: Props) {
  const actions = (
    <Button
      variant={isSmallDevice ? 'outlined' : 'contained'}
      color="secondary"
      sx={{ px: isSmallDevice ? 1.25 : 2.25, py: 1.25 }}
      onClick={() => {
        setOpen(false);
        window.location.reload();
      }}
    >
      Ver meus dados
    </Button>
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: isSmallDevice ? 1 : 0,
        alignItems: isSmallDevice ? 'center' : 'flex-start',
        textAlign: isSmallDevice ? 'center' : 'left',
      }}
    >
      <Typography variant="h2">Dados enviados</Typography>
      <Typography variant="body1">
        Seus dados de admissão foram enviados ao departamento pessoal. Se houver
        necessidade, entraremos em contato com você.
      </Typography>
    </Box>
  );
  return (
    <>
      <MobileDrawer
        open={open}
        loading={false}
        setOpen={setOpen}
        actions={actions}
        content={content}
      ></MobileDrawer>
      <Dialog
        open={open}
        onClose={setOpen}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0,0,0, 0.8)',
            },
          },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </>
  );
}
