import { TFieldRenderProps } from './types';

export function getFieldOnlyProps(field: TFieldRenderProps) {
  return 'input' in field
    ? field.input
    : 'select' in field
      ? field.select
      : 'textarea' in field
        ? field.textarea
        : null;
}
