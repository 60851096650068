import React, { useState } from 'react';

import dayjs from 'dayjs';

import { Box, Container, Dialog, Skeleton } from '@mui/material';

import { ContractEntry } from '@octopus/api';

import { TakoProgress } from '../../TakoProgress';

import { AddReportsReview } from './AddReportsReview';
import { AddedReportsStatus } from './AddReportsStatus';
import { AddReportEntry, AddReportInfo } from './types';
import {
  ChangeManagerForReportsMutationParameters,
  useChangeManagerForReports,
} from './useChangeManagerForReports';
import { usePrepareAddReportInfo } from './usePrepareAddReportInfo';

export type AddReportsDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSuccess: (showSnackbar: boolean) => void;
  newManagerContract: ContractEntry;
  reportsToAdd: AddReportEntry[];
};

export function AddReportsDialog({
  open,
  onCancel,
  onSuccess,
  newManagerContract,
  reportsToAdd,
}: AddReportsDialogProps) {
  const { isLoading, data } = usePrepareAddReportInfo({
    organizationId: newManagerContract.organizationId,
    reports: reportsToAdd,
  });
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullScreen={true}
      PaperProps={{ sx: { borderRadius: 0 } }}
    >
      <Container maxWidth="md">
        <Box pt={11} px={3} height="100vh" boxSizing="border-box">
          {isLoading || !data ? (
            <Skeleton variant="rounded" height="70vh" />
          ) : (
            <AddReportsDialogContent
              newManagerContract={newManagerContract}
              reportsToAdd={data}
              onCancel={onCancel}
              onSuccess={onSuccess}
            />
          )}
        </Box>
      </Container>
    </Dialog>
  );
}

function AddReportsDialogContent({
  newManagerContract,
  reportsToAdd,
  onCancel,
  onSuccess,
}: {
  newManagerContract: ContractEntry;
  reportsToAdd: AddReportInfo[];
  onCancel: () => void;
  onSuccess: (showSnackbar: boolean) => void;
}) {
  const [effectiveDate, setEffectiveDate] = useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const [confimed, setConfimed] = useState(false);
  const [executedChanges, setExecutedChanges] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const { mutate, isLoading, data } = useChangeManagerForReports();

  const incrementProgress = () => setProgress((p) => ++p);

  if (!isLoading && !confimed) {
    return (
      <AddReportsReview
        newManagerContract={newManagerContract}
        reportsToAdd={reportsToAdd}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
        onConfirm={() => {
          setConfimed(true);
          mutate(
            {
              organizationId: newManagerContract.organizationId,
              newManagerContractId: newManagerContract.contractId,
              effectiveDate,
              reports: reportsToAdd,
              incrementProgress,
            } as ChangeManagerForReportsMutationParameters,
            {
              onSuccess: ({ error }) => {
                incrementProgress();
                setTimeout(() => {
                  if (error.length === 0) {
                    onSuccess(true);
                  } else {
                    setExecutedChanges(true);
                  }
                }, 300);
              },
            },
          );
        }}
        onCancel={onCancel}
      />
    );
  }
  if ((confimed && !executedChanges) || !data) {
    return (
      <AddingReportsLoading
        current={progress}
        total={reportsToAdd.length + 1}
      />
    );
  }

  return (
    <AddedReportsStatus
      newManager={newManagerContract}
      effectiveDate={effectiveDate}
      success={data.success}
      error={data.error}
      onConfirm={() => onSuccess(false)}
    />
  );
}

function AddingReportsLoading({
  current,
  total,
}: {
  current: number;
  total: number;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      position="relative"
      top="-100px"
    >
      <TakoProgress
        width="340px"
        height="284px"
        progress={{ current, total }}
        label={{
          phrases: [
            'Consultando permissões para mudança',
            'Verificando novos gestores',
            'Atribuindo pessoas a novos gestores',
            'Criando a fonte da verdade',
            'Atribuindo pessoas a novos gestores',
            'Concluindo mudanças',
            'Lutando contra a correnteza',
            'Colocando tinta na caneta',
            'Lutando contra piratas',
            'Desviando do Kraken',
            'Mergulhando para águas profundas',
          ],
          cycleTimeInMs: 3000,
        }}
      />
    </Box>
  );
}
