import CancelIcon from '@mui/icons-material/Cancel';
import { Alert, Snackbar } from '@mui/material';

export type ErrorToastProps = {
  show: boolean;
  close: () => void;
};

export function ErrorToast({ show, close }: ErrorToastProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={show}
      onClose={close}
      key={'errorToast'}
    >
      <Alert
        onClose={close}
        severity="error"
        variant="filled"
        sx={{ width: '100%', py: 1.5 }}
        icon={<CancelIcon />}
      >
        Não foi possível aplicar as mudanças, tente novamente em instantes.
      </Alert>
    </Snackbar>
  );
}
