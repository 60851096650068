import React, { useState } from 'react';

import type { Submission } from '@conform-to/react';
import { Big } from 'big.js';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { brTaxesLabels } from '@octopus/contract-types';
import { formatMoney } from '@octopus/formatters';
import { z } from '@octopus/i18n';
import {
  BrasilAmountType,
  BrasilAmountTypeOptional,
  IFormDefinition,
  UI_TYPE,
  parseFormAmount,
  yearMonthSchema,
} from '@octopus/libs/forms';
import { Button } from '@octopus/ui/design-system';

import { Form } from '../../form/Form';
import { useFormFromDefinition } from '../../form/useFormFromDefinition';

const commonFields: IFormDefinition = [
  {
    label: 'Número da nota',
    name: 'invoiceNumber',
    type: z.number(),
    uiType: UI_TYPE.TEXT_NUMBER,
  },
  {
    label: 'Competência',
    name: 'period',
    uiType: UI_TYPE.DATE_PICKER_YEAR_MONTH,
    type: yearMonthSchema,
  },
  {
    label: 'Valor do serviço',
    name: 'grossAmount',
    uiType: UI_TYPE.TEXT_MONEY,
    type: BrasilAmountType,
  },
];

const simplesNacionalInputFormDefinition: IFormDefinition = [
  ...commonFields,
  {
    label: 'Descrição',
    name: 'description',
    uiType: UI_TYPE.TEXTAREA,
    type: z.string().optional(),
  },
];

export function SimplesNacionalInputForm({
  onSubmit,
}: {
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData: Submission<any, string[], any>,
  ) => void;
}) {
  const simplesForm = useFormFromDefinition(
    simplesNacionalInputFormDefinition,
    {
      id: 'simplesNacionalForm',
      onSubmit,
      persistLocal: false,
    },
  );
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const errors = simplesForm.payloadForm.allErrors;

  return (
    <Form for={simplesForm}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Form.Field for={simplesForm.fields.invoiceNumber} />
          {errors['invoiceNumber'] && (
            <Typography variant={'body2'} color={'error'} lineHeight={'20px'}>
              Número da nota inválido.
            </Typography>
          )}
        </Box>
        <Box display={'flex'} gap={1} flexDirection={'column'} width={'100%'}>
          <Form.Field for={simplesForm.fields.period} />
          {errors['period'] && (
            <Typography variant={'body2'} color={'error'} lineHeight={'20px'}>
              Por favor selecione a competência.
            </Typography>
          )}
        </Box>
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Form.Field for={simplesForm.fields.grossAmount} />
          {errors['grossAmount'] && (
            <Typography variant={'body2'} color={'error'} lineHeight={'20px'}>
              Valor do serviço inválido.
            </Typography>
          )}
        </Box>
        <Form.Field
          for={simplesForm.fields.description}
          sx={{
            '--FieldTextAreaMinHeight': '3rem',
          }}
        />

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            type="submit"
            form={simplesForm.id}
            variantSemantic={'primary'}
            sx={{
              px: 4,
              py: 1,
              width: isSmall ? '100%' : '104px',
              height: '40px',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                lineHeight: '20px',
                color: (theme) => theme.palette.primaryAlt.contrastText,
              }}
            >
              Enviar
            </Typography>
          </Button>
        </Box>
      </Box>
    </Form>
  );
}

const lucroPresumidoInputFormDefinition: IFormDefinition = [
  ...commonFields,
  ...Object.entries(brTaxesLabels)
    .sort()
    .map(([key, label]) => ({
      label: label,
      name: key,
      type: BrasilAmountTypeOptional,
      uiType: UI_TYPE.TEXT_MONEY,
    })),
  {
    label: 'Descrição',
    name: 'description',
    uiType: UI_TYPE.TEXTAREA,
    type: z.string().optional(),
  },
];

export function LucroPresumidoInputForm({
  onSubmit,
}: {
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData: Submission<any, string[], any>,
  ) => void;
}) {
  const lucroPresumidoForm = useFormFromDefinition(
    lucroPresumidoInputFormDefinition,
    {
      onSubmit,
      id: 'lucroPresumidoForm',
      persistLocal: false,
    },
  );

  const errors = lucroPresumidoForm.payloadForm.allErrors;

  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const taxesKeys = new Set(Object.keys(brTaxesLabels));

  const totalTaxes = Object.entries(lucroPresumidoForm.payloadForm.value ?? {})
    .filter(([key]) => {
      return taxesKeys.has(key);
    })
    .reduce((acc, [_, value]) => {
      const parsedAmount = parseFormAmount(value);

      if (!parsedAmount) {
        return acc;
      }

      const amount = new Big(parsedAmount);
      return acc.plus(amount);
    }, new Big('0'));

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Form for={lucroPresumidoForm}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Form.Field for={lucroPresumidoForm.fields.invoiceNumber} />
          {errors['invoiceNumber'] && (
            <Typography variant={'body2'} color={'error'} lineHeight={'20px'}>
              Número da nota inválido
            </Typography>
          )}
        </Box>
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Form.Field for={lucroPresumidoForm.fields.period} />
          {errors['period'] && (
            <Typography variant={'body2'} color={'error'} lineHeight={'20px'}>
              Por favor selecione a competência.
            </Typography>
          )}
        </Box>

        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Form.Field for={lucroPresumidoForm.fields.grossAmount} />
          {errors['grossAmount'] && (
            <Typography variant={'body2'} color={'error'} lineHeight={'20px'}>
              Valor do serviço inválido
            </Typography>
          )}
        </Box>

        <Accordion
          expanded={expanded}
          sx={{
            border: '1px solid #EDEDED',
            borderRadius: '8px',
          }}
        >
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Box
              id={'header'}
              display={'flex'}
              justifyContent={'space-between'}
              width={'100%'}
              boxSizing={'border-box'}
              px={1}
              onClick={handleExpansion}
              alignItems={'center'}
            >
              <Box display={'flex'} alignItems={'center'} gap={0.5}>
                <Typography variant="caption" fontWeight={700}>
                  Impostos e retenções
                </Typography>
                <Box p={0.5} display={'flex'} alignItems={'center'}>
                  {expanded ? (
                    <KeyboardArrowUpOutlinedIcon />
                  ) : (
                    <KeyboardArrowDownOutlinedIcon />
                  )}
                </Box>
              </Box>
              <Typography variant="caption" fontWeight={700}>
                {formatMoney(totalTaxes.toString())}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={'flex'} flexWrap={'wrap'} key={'taxes'} gap={3}>
              {Object.entries(brTaxesLabels).map(([key, value]) => (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  px={1}
                  key={key}
                  flexBasis={'45%'}
                  boxSizing={'border-box'}
                >
                  <Box display={'flex'} gap={1} flexDirection={'column'}>
                    <Form.Field
                      for={lucroPresumidoForm.fields[key]}
                    ></Form.Field>
                    {errors[key] && (
                      <Typography
                        variant={'body2'}
                        color={'error'}
                        lineHeight={'20px'}
                      >
                        {value} inválido
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Form.Field
          for={lucroPresumidoForm.fields.description}
          sx={{
            '--FieldTextAreaMinHeight': '3rem',
          }}
        />

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            type="submit"
            form={lucroPresumidoForm.id}
            variantSemantic={'primary'}
            sx={{
              px: 4,
              py: 1,
              width: isSmall ? '100%' : '104px',
              height: '40px',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                lineHeight: '20px',
                color: (theme) => theme.palette.primaryAlt.contrastText,
              }}
            >
              Enviar
            </Typography>
          </Button>
        </Box>
      </Box>
    </Form>
  );
}
