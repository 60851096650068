import { useId, useState } from 'react';

import { Submission, SubmissionResult } from '@conform-to/react';

import { usePersistedValue } from './usePersistedValue';

type IFormSubmission<Schema = Record<PropertyKey, unknown>> =
  Submission<Schema>;
type IFormSubmissionResult = SubmissionResult;

type IFormPersistedState = {
  submission?: IFormSubmission;
  submissionResult?: IFormSubmissionResult;
};

type IFormState = {
  submission?: IFormSubmission;
  lastResult?: IFormSubmissionResult;
  set: (newState: IFormPersistedState) => void;
  id: string;
};

type IUseFormSetupResult = {
  metaFormState: IFormState;
  payloadFormState: IFormState;
};

export function useFormFromDefinitionState(
  formIdArg?: string,
): IUseFormSetupResult {
  const persistLocal = formIdArg != null;
  const reactId = useId();
  const id = formIdArg || reactId;

  const metaFormId = `meta:${id}`;
  const payloadFormId = `payload:${id}`;

  const metaFormPersistenceId = `formp:meta:${id}`;
  const payloadFormPersistenceId = `formp:payload:${id}`;

  const metaFormPersistedState = usePersistedValue(
    persistLocal && metaFormPersistenceId,
  );
  const payloadFormPersistedState = usePersistedValue(
    persistLocal && payloadFormPersistenceId,
  );

  const [payloadFormSubmission, setPayloadFormSubmission] =
    useState<IFormSubmission>(payloadFormPersistedState?.submission);
  const [payloadFormSubmissionResult, setPayloadFormSubmissionResult] =
    useState<IFormSubmissionResult>(
      payloadFormPersistedState?.submissionResult,
    );

  const [metaFormSubmission, setMetaFormSubmission] = useState<IFormSubmission>(
    metaFormPersistedState?.submission,
  );
  const [metaFormSubmissionResult, setMetaFormSubmissionResult] =
    useState<IFormSubmissionResult>(metaFormPersistedState?.submissionResult);

  usePersistedValue(persistLocal && metaFormPersistenceId, {
    submission: metaFormSubmission,
    submissionResult: metaFormSubmissionResult,
  });

  usePersistedValue(persistLocal && payloadFormPersistenceId, {
    submission: payloadFormSubmission,
    submissionResult: payloadFormSubmissionResult,
  });

  return {
    metaFormState: {
      id: metaFormId,
      submission: metaFormSubmission,
      lastResult: metaFormSubmissionResult,
      set: (newState: IFormPersistedState) => {
        if (newState.submission) {
          setMetaFormSubmission({ ...newState.submission });
        }
        if (newState.submissionResult) {
          setMetaFormSubmissionResult({ ...newState.submissionResult });
        }
      },
    },
    payloadFormState: {
      id: payloadFormId,
      submission: payloadFormSubmission,
      lastResult: payloadFormSubmissionResult,
      set: (newState: IFormPersistedState) => {
        if (newState.submission) {
          setPayloadFormSubmission({ ...newState.submission });
        }
        if (newState.submissionResult) {
          setPayloadFormSubmissionResult({ ...newState.submissionResult });
        }
      },
    },
  };
}
