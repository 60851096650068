import { v4 } from 'uuid';

import { EnabledSSOConfig } from './config';

export type SSOAuthorizeUrlParams = EnabledSSOConfig & {
  readonly identityProvider: string;
  readonly origin: string;
};

function serializeOrigin(origin: string): string {
  return btoa(JSON.stringify({ origin: origin, id: v4() }));
}

export function buildAuthorizeUrl({
  scope,
  origin,
  clientId,
  redirectPath,
  authorizationUri,
  identityProvider,
}: SSOAuthorizeUrlParams): string {
  const params = new URLSearchParams({
    response_type: 'code',
    scope: scope,
    client_id: clientId,
    redirect_uri: `${origin}/${redirectPath}`,
    identity_provider: identityProvider,
    state: serializeOrigin(origin),
  });
  return `${authorizationUri}?${params.toString()}`;
}
