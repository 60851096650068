import { useMaskito } from '@maskito/react';

import { ContractBRCltEntryRegime } from '@octopus/api';
import {
  MONTHS_BR,
  formatCNPJ,
  formatMonthBR,
  getOnlyDigits,
} from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../../form/Field/MaskitoOptions';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type SindicatoRecordData = {
  regime: ContractBRCltEntryRegime;
};

export type SindicatoRecordProps = BaseRecordProps<SindicatoRecordData>;

export function SindicatoRecord(props: SindicatoRecordProps) {
  const {
    data: { regime },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        regime: {
          ...data.regime,
          [key]: formatter(value),
        },
      }));
  };

  const cnpjMask = useMaskito({
    options: MaskitoOptionsBR.cnpj,
  });

  return (
    <Record title="Sindicato" edit={editRecordProps}>
      <RecordEntry
        label="CNPJ do sindicato"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('cnpjSindCategProf', getOnlyDigits),
          value: formatCNPJ(formData.regime.cnpjSindCategProf),
          mask: cnpjMask,
          hasError: hasError('br/regime/cnpjSindCategProf'),
        }}
      >
        {regime.cnpjSindCategProf === '37115367003500'
          ? 'Não filiado'
          : formatCNPJ(regime.cnpjSindCategProf)}
      </RecordEntry>
      <RecordEntry
        label="Mês base da categoria"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('dtBase', parseInt),
          value: formData.regime.dtBase,
          options: MONTHS_BR.map((month, index) => ({
            label: month,
            value: index + 1,
          })),
          hasError: hasError('br/regime/dtBase'),
        }}
      >
        {formatMonthBR(regime.dtBase)}
      </RecordEntry>
    </Record>
  );
}
