export const TERMS_OF_SERVICE = `
<br>
<div style="text-align: left;font-size:12px"> Data da última atualização: 22 de Março de 2024</div>
<br>
**RESUMO DOS TERMOS DE USO**

Neste item você terá um resumo dos principais pontos dos nossos Termos de Uso sobre o acesso aos serviços de processamento de documentos e informações relacionados à contratação do Colaborador pela Empresa, criando um diretório de informações do Colaborador, podendo emitir relatórios, bem como acessar outros benefícios, serviços e soluções oferecidos na Plataforma. Lembrando que isso é apenas um resumo para facilitar a sua leitura dos Termos de Uso como um todo.


Ao aceitar estes Termos de Uso, Você poderá ter acesso, dentre outros, aos seguintes Serviços:


- (i) Processamento de folha de pagamento dos Colaboradores;
* (ii) Emissão de holerites dos Colaboradores;
- (iii) Tecnologia que permite o controle e automatização do pagamento de salários e encargos trabalhistas e previdenciários;
* (iv) Emissão de guias governamentais relacionadas ao pagamento de encargos trabalhistas e previdenciários;
- (v) Consolidação de informações do Colaborador registradas pelo Usuário Autorizado, e
* (vi) Emissão de relatórios com base nos dados e informações dos Colaboradores.


Em geral, para utilização de tais Serviços, Você deverá:


* (i) Manter seus dados cadastrais sempre atualizados junto à WGMI;
- (ii) Utilizar os Serviços conforme previsto nestes Termos de Uso, não podendo praticar as práticas previstas no item 8;
* (iii) Zelar pela manutenção da segurança e confidencialidade de suas Credenciais de Acesso;
- (iv) Respeitar os Direitos de Propriedade Intelectual da WGMI; e
* (v) Zelar para que o acesso à Plataforma e aos Serviços se dê sempre em condições técnicas adequadas.

**TERMOS DE USO**

1. **INTRODUÇÃO**

1.1. Estes Termos e Condições Gerais de Uso (“**Termos de Uso**”) contêm os termos e condições para a utilização desta plataforma, juntamente com todo o seu conteúdo, o que inclui o aplicativo (“**Plataforma**”), e têm por objeto definir as regras de acesso e utilização da Plataforma pelos **USUÁRIOS**. A Plataforma é desenvolvida, controlada e operada pela **WGMI TECNOLOGIAS LTDA.**, sociedade limitada, com sede na Rua Professor Atílio Innocenti, 165 – 6o andar, Sala 102 na cidade de São Paulo, Estado de São Paulo, Brasil, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Fazenda (“**CNPJ**”) sob o nº 45.645.560/0001-31 (“WGMI”).

1.2. Toda vez que houver menção aos termos “WGMI”, “nós” ou “nossos” estaremos nos referindo à WGMI; bem como toda vez que houver menção aos termos “USUÁRIO”, “você”, “seu” e “sua”, estaremos nos referindo a você USUÁRIO, que está consentindo com estes Termos de Uso e com a Política de Privacidade para fazer uso e acesso à Plataforma.

1.3. Para a utilização dos Serviços ofertados pela **WGMI** (tal como adiante definidos) e das funcionalidades disponibilizadas através da Plataforma, é necessário que a **WGMI** tenha acesso a determinados dados pessoais dos **USUÁRIOS**. Maiores detalhes sobre os tipos de dados pessoais coletados e as suas respectivas finalidades são apresentados na Política de Privacidade da WGMI, disponível em [www.usetako.com/politica-de-privacidade](https://www.usetako.com/politica-de-privacidade).

1.4. **AO UTILIZAR A PLATAFORMA, VOCÊ AUTOMATICAMENTE CONCORDA COM TODAS AS REGRAS DESTES TERMOS DE USO E DEMAIS CONDIÇÕES AQUI MENCIONADAS, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS POR SI PRATICADOS NA PLATAFORMA, BEM COMO PELAS CONSEQUÊNCIAS RELACIONADAS AO SEU USO. VOCÊ TAMBÉM NÃO PODERÁ SE ESCUSAR DESTES TERMOS DE USO, ALEGANDO IGNOR NCIA SOBRE AS SUAS REGRAS E CONDIÇÕES, INCLUSIVE QUANTO A EVENTUAIS MODIFICAÇÕES NAS SUAS DISPOSIÇÕES. CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ
<span style="text-decoration:underline;">NÃO</span> DEVE UTILIZAR A PLATAFORMA.**

1.5. Estes Termos de Uso estão integralmente disponíveis para consulta em nosso Aplicativo **WGMI** e no endereço eletrônico [www.usetako.com](https://www.usetako.com), podendo ser acessados pelo **USUÁRIO** a qualquer momento.

2. **DEFINIÇÕES**

2.1. Para facilitar o seu entendimento e evitar repetições, a lista completa dos termos definidos utilizados e seus respectivos significados está disponibilizada ao final destes Termos de Uso.


3. **VINCULAÇÃO DO USUÁRIO**


3.1. O **USUÁRIO** reconhece e declara que: (i) acessa a Plataforma por livre e espontânea vontade; e (ii) realizou todos os passos indicados na Plataforma para finalização do seu cadastro junto à **WGMI**, conforme definido abaixo, incluindo, mas não se limitando, ao preenchimento de dados cadastrais e ao aceite dos Termos de Uso e da Política de Privacidade da **WGMI**.

3.2. Os presentes Termos de Uso terão início na data de seu aceite e têm como objetivo regular os direitos e obrigações atribuíveis à **WGMI** e ao **USUÁRIO** no âmbito do acesso à Plataforma e da utilização / prestação dos Serviços. O **USUÁRIO** estará automática e expressamente vinculado às condições previstas nestes Termos de Uso ao clicar em “Declaro que li e aceito o inteiro teor destes Termos de Uso, ficando desde já obrigado(a) e sujeito(a) aos direitos e obrigações aqui previstos”, conforme indicado abaixo.

3.3. São ainda consideradas parte integrante destes Termos de Uso quaisquer comunicações enviadas e/ou disponibilizadas pela **WGMI** ao **USUÁRIO** por meio da Plataforma, e-mail, SMS ou telefones informados pelo **USUÁRIO** no momento do seu cadastro. O cadastro do **USUÁRIO** na Plataforma implicará no recebimento de comunicações eletrônicas enviadas pela ou em nome da **WGMI**. Caso o **USUÁRIO** não queira receber comunicações promocionais, o **USUÁRIO** poderá efetuar seu descredenciamento a qualquer momento, usando o link para opt-out que será enviado conjuntamente à comunicação eletrônica da **WGMI**.

3.4 A **WGMI** poderá, a qualquer momento, alterar os presentes Termos de Uso, o que fará mediante publicação da versão atualizada na Plataforma e prévia comunicação ao **USUÁRIO** por meio (i) do envio de comunicação ao endereço de e-mail cadastrado pelo **USUÁRIO** na Plataforma; (ii) de aviso no momento de acesso à Plataforma; e/ou (iii) de comunicação de qualquer outra forma aos **USUÁRIOS**. Caso o **USUÁRIO** não concorde com as modificações, poderá solicitar o encerramento de sua Conta.

3.4.1. As atualizações destes Termos de Uso entrarão em vigor na data de sua publicação, a não ser que seja informado em sentido diverso.

3.4.2. Para identificar a data e a versão dos Termos de Uso vigentes, o **USUÁRIO** deverá verificar a seção “Data da última atualização”, no início deste documento.

3.5. O **USUÁRIO** declara reconhecer o meio eletrônico e todas as suas etapas como sendo um meio válido de contratação, bem como a sua identificação no momento da contratação com a **WGMI** como prova de sua concordância com esse formato de contratação.

4. **O QUE A PLATAFORMA OFERECE?**

4.1. **Funcionalidade**. A Plataforma permite ao **USUÁRIO** o acesso a serviços de processamento de documentos e informações relacionados à contratação do Colaborador pela Empresa, criando um diretório de informações do Colaborador, podendo emitir relatórios, bem como acessar outros benefícios, serviços e soluções oferecidos na Plataforma (“**Serviços**”).

4.2. Ao se cadastrar na Plataforma e aderir a estes Termos de Uso, a Empresa e o Usuário Autorizado terão acesso, dentre outros, aos seguintes serviços:

- (i) Processamento de folha de pagamento dos Colaboradores;
* (ii) Emissão de holerites dos Colaboradores;
- (iii) Tecnologia que permite o controle e automatização do pagamento de salários e encargos trabalhistas e previdenciários;
* (iv) Emissão de guias governamentais relacionadas ao pagamento de encargos trabalhistas e previdenciários;
- (v) Consolidação de informações do Colaborador registradas pelo Usuário Autorizado, e
* (vi) Emissão de relatórios com base nos dados e informações dos Colaboradores;

4.3. A **WGMI** poderá, a qualquer momento, ampliar as funcionalidades e/ou serviços oferecidos na Plataforma. O uso por parte do **USUÁRIO** de qualquer nova funcionalidade ou serviço significará que o **USUÁRIO** aceitou e aderiu integralmente aos Termos de Uso aplicáveis a tais funcionalidades ou serviços.

4.4. A **WGMI** poderá, a qualquer tempo, descontinuar de forma definitiva ou temporária os Serviços disponibilizados por meio da Plataforma, mediante comunicação prévia ao **USUÁRIO** e sem que lhe seja devida qualquer indenização.

4.5. **Acesso**. A Plataforma pode ser acessada através do endereço eletrônico www.8arm.io ou do aplicativo da **WGMI**.

4.6. **Funcionamento**. A Plataforma funciona mediante conexão à internet e o seu acesso e uso são destinados (i) aos Usuários Autorizados que desejam realizar o registro das informações dos Colaboradores e (ii) a Colaboradores que desejam acessar suas informações registradas pela Empresa, nos limites destes Termos de Uso.

5. **ACESSO E USO DA PLATAFORMA**

5.1. Para fazer uso dos Serviços oferecidos pela Plataforma, o Colaborador deverá: (i) ter 18 (dezoito) anos ou mais, (ii) ser residente no Brasil, e (iii) possuir inscrição ativa no Cadastro de Pessoas Físicas do Ministério da Fazenda (“**CPF**”) junto à Receita Federal, e apresentar cópia dos seguintes documentos: (a) RG; (b) CPF; (c) comprovante de residência

5.1.1. Conforme aplicável, a WGMI poderá solicitar documentos adicionais ao Colaborador no momento do cadastro para fazer o uso dos Serviços oferecidos pela Plataforma.

5.1.2. Menores de 18 (dezoito) anos deverão ser assistidos por seus responsáveis legais, os quais deverão preencher a correspondente autorização por escrito, nos termos da legislação vigente, estando o menor sob sua autoridade e em sua companhia.

5.2. Para fazer uso dos Serviços oferecidos pela Plataforma, a Empresa deverá (i) possuir inscrição ativa no junto à Receita Federal (CNPJ), e apresentar (ii) cópia do estatuto ou contrato social e (iii) dados pessoais referentes aos seus Usuários Autorizados.

5.3. No acesso à Plataforma, o **USUÁRIO** deverá fornecer as informações cadastrais, que contemplarão, sem prejuízo de outros que venham a ser solicitados, os dados que permitam a identificação do **USUÁRIO**, tais como, conforme aplicável: (i) nome completo ou razão social; (ii) CPF ou CNPJ; (iii) informações para contato (e-mail e telefone); (iv) endereço residencial ou da sede; (v) data de nascimento; (vi) documento de identidade com foto (RG, CNH ou passaporte); (vii) cópia do estatuto ou contrato social; e (viii) fotografia (selfie). Para mais informações sobre dados de cadastro coletados, visite a Política de Privacidade da **WGMI**.

5.3.1. Será permitido apenas um cadastro por CPF ou CNPJ, conforme aplicável.

5.4. O **USUÁRIO** declara que os documentos fornecidos para efetuar os cadastros junto à Plataforma são completos e todas as informações enviadas à **WGMI** são verdadeiras, exatas, atuais e completas.

5.4.1. O **USUÁRIO** é o único responsável pelos dados fornecidos, de maneira que se responsabiliza criminal e civilmente pela veracidade, completude, atualização e exatidão das informações fornecidas. A **WGMI** não se responsabiliza por erros nos cadastros efetuados pelos **USUÁRIOS**.

5.5. O acesso à Plataforma e à Conta será feito por meio de um conjunto único de credenciais composto por login e senha (“**Credenciais de Acesso**”), criado pela **WGMI** e enviado ao Colaborador ou ao Usuário Autorizado, conforme aplicável, que são os únicos responsáveis pela manutenção de sua segurança e confidencialidade.

5.5.1. O Colaborador ou o Usuário Autorizado poderão solicitar a alteração das Credenciais de Acesso através da Plataforma ou via Canais de Atendimento.

5.5.2. O **USUÁRIO** compromete-se a cumprir com quaisquer alterações nos requisitos de acesso que a **WGMI** venha a aplicar, adicionando métodos e/ou critérios adicionais de autenticação ou alterando os existentes, a fim de aumentar a segurança da Plataforma e Serviços e/ou cumprir com novos requisitos de segurança ou técnicos decorrentes da legislação aplicável.

6. **ANÁLISE DE CADASTRO E INFORMAÇÕES**

6.1. A **WGMI** poderá solicitar e o **USUÁRIO** se compromete a fornecer, a qualquer tempo, informações e documentos adicionais a fim de garantir a veracidade e a atualização das informações cadastrais, bem como o cumprimento da regulamentação de cadastro de clientes e demais obrigações legais e regulatórias às quais a **WGMI** se sujeita.

6.2. O **USUÁRIO** está ciente de que a **WGMI** poderá realizar todas as consultas e/ou solicitações que sejam necessárias, diretamente ou por meio de terceiros, para validar as informações e os documentos fornecidos pelo **USUÁRIO**, com base nestes Termos de Uso e na Política de Privacidade, incluindo, mas não se limitando, a checagem de informações na Receita Federal do Brasil e a análise de dados no Cadastro de Clientes do Sistema Financeiro Nacional – CCS, bem como bases de restrições creditícias, tais como o SPC e/ou o SERASA.

6.3. As informações do **USUÁRIO** poderão, ainda, ser processadas conjuntamente com as de outros **USUÁRIOS** para o fim de gerar relatórios de análise de segmento por meio de big data, os quais poderão ser compartilhados com terceiros.

7. **USO INDEVIDO DA PLATAFORMA**

7.1. Em caso de suspeita de acesso indevido à Plataforma por terceiros, o **USUÁRIO** deve informar o fato à **WGMI** imediatamente através de um dos Canais de Comunicação. O uso e acesso da Conta poderão ser temporariamente bloqueados até que o **USUÁRIO** receba novas Credenciais de Acesso.

8. **PRÁTICAS PROIBIDAS**

8.1. **Proibições**. Você fica ciente e concorda que, durante a utilização da Plataforma, é terminantemente proibido:

- (i) ceder, sublicenciar, vender, doar, alienar, alugar, distribuir, transmitir ou transferir, total ou parcialmente, a terceiros, sob quaisquer modalidades, a qualquer título, bem como copiar, modificar, ampliar, reduzir, adaptar, traduzir, decompilar, desmontar, executar engenharia reversa ou de qualquer forma explorar economicamente a Plataforma, incluindo os dados e as informações disponibilizadas pela Plataforma, bem como utilizá-los (a) para finalidades que não estejam previstas nestes Termos de Uso, no Contrato ou, ainda, (b) para a criação ou fornecimento de outros produtos ou serviços concorrentes à Plataforma;
* (ii) disseminar ou instalar vírus ou qualquer outro código, arquivo ou software malicioso com o propósito de interromper, destruir, acessar indevidamente, limitar ou interferir no funcionamento ou segurança da Plataforma, bem como das informações, dados e equipamentos da **WGMI**, de outros **USUÁRIOS** da Plataforma ou de terceiros, ou, ainda, para qualquer outra finalidade ilícita;
- (iii) usar e acessar a Plataforma, ou praticar atos, de forma que prejudique a segurança, integridade, disponibilidade e/ou o funcionamento normal da Plataforma;
* (iv) acessar a Plataforma, ou quaisquer dados nela contidos, de maneira não autorizada, ou praticar qualquer ato que resulte em violação da privacidade de outros **USUÁRIOS** da Plataforma;
- (v) utilizar as Credenciais de Acesso de terceiro, ou compartilhar as suas Credenciais de Acesso com terceiro;
* (vi) carregar, transmitir, divulgar, exibir, enviar, ou de qualquer outra forma tornar disponível qualquer conteúdo que viole direitos de terceiros ou seja ilegal, incluindo, sem limitação, conteúdo ofensivo à honra e à privacidade de terceiros, racista, discriminatório, pornográfico, obsceno, difamatório ou calunioso, vulgar, preconceituoso, ou que possa gerar responsabilidade civil ou criminal; e
- (vii) praticar qualquer ato contrário à legislação em vigor e a estes Temos de Uso.

8.2. A **WGMI** reserva-se o direito de, mediante simples comunicação ao **USUÁRIO** em questão, suspender preventivamente ou cancelar, a qualquer momento, o respectivo cadastro e a correspondente prestação dos Serviços, em caso de mera suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas nestes Termos de Uso ou na legislação aplicável.

9. **RESPONSABILIDADES DO USUÁRIO E DA WGMI**

9.1. **Uso da Plataforma**. O **USUÁRIO** é exclusivamente responsável pelo uso da Plataforma, devendo respeitar as regras destes Termos de Uso, bem como a legislação aplicável. O **USUÁRIO** concorda em utilizar a Plataforma de forma adequada, com boa-fé, apenas para fins lícitos e de acordo com as regras previstas nestes Termos de Uso.

9.2. **Obrigações do USUÁRIO**. O **USUÁRIO** está ciente de que, para acessar e usar a Plataforma, deverá:

- (i) fornecer, sempre que solicitado pela **WGMI**, informações verdadeiras, exatas, atuais e completas;


- (ii) atualizar imediatamente as informações fornecidas, sempre que estas sofrerem qualquer alteração, inclusive para assegurar o recebimento pelo **USUÁRIO** de quaisquer comunicações da **WGMI**;
* (iii) zelar pela manutenção da segurança e confidencialidade de suas Credenciais de Acesso;
- (iv) respeitar, entre outros, os Direitos de Propriedade Intelectual da **WGMI**; e
* (v) zelar para que o acesso à Plataforma e aos Serviços se dê sempre em condições técnicas adequadas.

9.3. **Meios de acesso à Plataforma e aos Serviços**. A **WGMI** não oferece aos **USUÁRIOS** dispositivos de hardware, tampouco serviços de conexão à internet ou qualquer outro serviço técnico necessário para uso da Plataforma, isentando-se de qualquer responsabilidade nesse sentido. É de inteira responsabilidade do **USUÁRIO**:

- (i) equipar-se e responsabilizar-se pelos dispositivos de hardware (computador, celular, tablet, entre outros) necessários para o acesso à Plataforma, bem como pelo acesso destes à internet;
* (ii) manter seguro o ambiente de seus dispositivos de acesso à Plataforma, valendo-se de ferramentas específicas para tanto, tais como antivírus, firewall, entre outras, de modo a contribuir para a prevenção de riscos eletrônicos;
- (iii) utilizar navegadores e/ou, conforme o caso, sistemas operacionais atualizados e eficientes para a plena utilização da Plataforma, de acordo com as especificações fornecidas pela **WGMI**;
* (iv) manter o Aplicativo sempre atualizado, de acordo com a última versão disponível nas lojas oficiais para dispositivos Apple (App Store) e Android (Google Play Store);
- (v) manter seus sistemas antispam configurados de modo que não interfiram no recebimento pelo **USUÁRIO** das comunicações feitas pela **WGMI**; e
* (vi) arcar com eventuais custos de conexão e acesso à internet para o uso da Plataforma.

9.4. **PENALIDADES**. O **USUÁRIO** É RESPONSÁVEL POR TODA E QUALQUER VIOLAÇÃO DESTES TERMOS DE USO E DA LEI APLICÁVEL, SEJA POR AÇÃO OU OMISSÃO. EM CASO DE INFRAÇÃO COMETIDA PELO **USUÁRIO**, EM RAZÃO DO DESCUMPRIMENTO DESTES TERMOS DE USO, VIOLAÇÃO DE QUALQUER LEI APLICÁVEL OU DE DIREITOS DA **WGMI** E/OU DE TERCEIROS, O USUÁRIO RESPONDERÁ, PERANTE A **WGMI** E TERCEIROS, POR TODAS AS PERDAS, DANOS E PREJUÍZOS, DIRETOS E/OU INDIRETOS, A QUE DER CAUSA, SUJEITANDO-SE, INCLUSIVE, ÀS SANÇÕES CIVIS E PENAIS PREVISTAS NA LEGISLAÇÃO APLICÁVEL. O **USUÁRIO** COMPROMETE-SE A INDENIZAR A **WGMI** PELOS DANOS CAUSADOS, INCLUSIVE NOS CASOS DE DEMANDAS PROMOVIDAS POR OUTROS **USUÁRIOS** E/OU TERCEIROS. APLICAM-SE A ESTAS DISPOSIÇÕES OS ARTIGOS 186 E 927 DO CÓDIGO CIVIL BRASILEIRO (LEI N. 10.406/2002).

9.5. **ISENÇÃO DE RESPONSABILIDADE**. A **WGMI**, SUAS AFILIADAS, PARCEIROS, EMPREGADOS OU COLABORADORES, NÃO PODERÃO SER, EM HIPÓTESE ALGUMA, RESPONSABILIZADOS (I) POR ACESSOS OU MOVIMENTAÇÕES INDEVIDAS EM SUA CONTA; (II) POR DANOS, DIRETOS OU INDIRETOS, QUE RESULTEM DE, OU QUE TENHAM RELAÇÃO COM O MAU USO OU INABILIDADE NO USO DA PLATAFORMA, DA CONTA PELO **USUÁRIO** OU POR QUAISQUER TERCEIROS; OU (III) PELA VERACIDADE DAS INFORMAÇÕES PESSOAIS PRESTADAS PELO **USUÁRIO**.

9.6. Serviços de Terceiros. A **WGMI** utiliza serviços de terceiros para manter o funcionamento da Plataforma (por exemplo, serviço de hospedagem). Esses serviços são de responsabilidade dos terceiros que os disponibilizam, e por essa razão são regidos por termos de uso próprios. A **WGMI**, na medida do possível, manterá o **USUÁRIO** informado sobre prazos e providências tomadas para sanar eventuais falhas de tais serviços.

9.7. **INDISPONIBILIDADE DA PLATAFORMA**. TENDO EM VISTA AS CARACTERÍSTICAS INERENTES AO AMBIENTE DA INTERNET, A **WGMI** NÃO SE RESPONSABILIZA POR FALHAS DE ACESSO OU NAVEGAÇÃO NA PLATAFORMA DECORRENTES DE CIRCUNST NCIAS ALHEIAS À SUA VONTADE E CONTROLE, TAIS COMO INTERRUPÇÕES OU SUSPENSÕES DE CONEXÃO, TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU QUE FALHEM, BEM COMO POR FALHA TÉCNICA, INCLUINDO, SEM LIMITAÇÃO, O MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE, HARDWARE OU SOFTWARE DO **USUÁRIO** OU DE TERCEIROS, FALHAS NA INTERNET EM GERAL, QUEDAS DE ENERGIA, MAU FUNCIONAMENTO ELETRÔNICO E/OU FÍSICO DE QUALQUER REDE DE TELECOMUNICAÇÕES.

10. **DIREITO DE RECUSA, CANCELAMENTO E SUSPENSÃO DE CADASTRO**

10.1. **Cancelamento do Cadastro do Colaborador**. O cancelamento do cadastro do Colaborador poderá ser feito pelo próprio Colaborador através de contato via email para o endereço ajuda@8arm.io, a qualquer tempo, desde que não esteja vinculado a uma Empresa.

10.2. **Cancelamento do Cadastro da Empresa**. O cancelamento do cadastro da Empresa poderá ser requisitado pela mesma explicitando o interesse via email para com o responsável pela conta na WGMI ou através do endereço [ajuda@8arm.io](mailto:ajuda@8arm.io).

10.3. **Cancelamento do Cadastro pela WGMI**. A **WGMI** reserva-se o direito de, mediante simples comunicação ao **USUÁRIO** em questão, suspender preventivamente ou cancelar, a qualquer momento, o respectivo cadastro e a correspondente prestação dos Serviços, caso identifique a ocorrência de desvio de finalidade na utilização da Plataforma, operações fora do padrão de uso, transações fraudulentas ou ilícitas, obtenção de benefício ou vantagem de forma ilícita, fornecimento de informações falsas, imprecisas, incompletas ou enganosas, não fornecimento de informações e/ou documentos adicionais ou o não cumprimento de quaisquer das condições previstas nestes Termos de Uso ou na legislação aplicável. Nesses casos, nenhuma indenização será devida pela **WGMI** ao **USUÁRIO** ou a terceiros pelos danos diretos e indiretos porventura causados. Na hipótese de transações ilícitas ou fraudulentas que gerem prejuízos a terceiros, o **USUÁRIO** será responsável por quaisquer indenizações e/ou reposições que venham a ser necessárias em face de tais terceiros de boa-fé. O **USUÁRIO** está ciente e de acordo que a **WGMI** poderá promover todas as medidas necessárias para perseguir e resguardar seus direitos, incluindo medidas judiciais.

10.4. A **WGMI** poderá cancelar a prestação dos Serviços, comunicando o **USUÁRIO**, nas seguintes hipóteses: (i) imotivadamente, mediante comunicação ao **USUÁRIO** com, no mínimo, 30 (trinta) dias de antecedência; e (ii) em caso de falecimento do **USUÁRIO** e, no caso de pessoa jurídica, na hipótese de falência ou insolvência da empresa.

11. **DIREITOS DA WGMI SOBRE A PLATAFORMA**

11.1. **Licença de Uso**. Sujeito a estes Termos de Uso, a **WGMI** concede ao USUÁRIO uma licença limitada, temporária, não exclusiva e não transferível para usar a Plataforma somente para usufruir dos Serviços, desde que o **USUÁRIO** cumpra com as suas obrigações dispostas nestes Termos de Uso. A autorização aqui concedida não permite a exploração comercial da Plataforma.

11.2. **Conteúdo WGMI**. Todos os direitos de propriedade intelectual relativos à Plataforma, bem como todas as suas funcionalidades, são de propriedade exclusiva da e/ou são licenciados à **WGMI**, inclusive no que diz respeito aos seus textos, imagens, marcas, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias, vídeos, obras audiovisuais e demais conteúdos produzidos direta ou indiretamente pela **WGMI** (“Conteúdo **WGMI**”).

11.3. A Plataforma e o Conteúdo **WGMI** são protegidos pela legislação de direitos de propriedade intelectual, incluindo direitos autorais, direitos de propriedade industrial, dentre outros direitos. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender, explorar, fazer scraping e/ou engenharia reversa da Plataforma e do Conteúdo **WGMI**, para qualquer finalidade, sem o consentimento prévio e expresso da **WGMI**. Qualquer uso não autorizado da Plataforma e do Conteúdo **WGMI** será considerado como violação aos direitos de propriedade intelectual da **WGMI**.

11.4. A **WGMI** reserva-se o direito de alterar ou remover da Plataforma, total ou parcialmente, o Conteúdo **WGMI**, incluindo funcionalidades, sem comunicação prévia ao **USUÁRIO**, sem que lhe seja devida qualquer indenização, mas, em nenhum caso, os direitos do **USUÁRIO** serão afetados.

11.5. Nenhuma disposição destes Termos de Uso deve ser entendida como restrição ou renúncia de quaisquer direitos da **WGMI** sobre a Plataforma, tampouco cessão aos **USUÁRIOS** dos direitos de propriedade intelectual da **WGMI**.

11.6. **Melhorias**. Todo e qualquer desenvolvimento, arranjo, melhoria, atualização ou nova versão realizados pela **WGMI** na Plataforma, nas suas funcionalidades e/ou no Conteúdo **WGMI**, ainda que por sugestão ou solicitação do **USUÁRIO**, serão de titularidade da **WGMI**, podendo a **WGMI** empregá-los livremente e, sem limitação, adaptá-los, aperfeiçoá-los, transformá-los, reproduzi-los, distribui-los, comercializá-los, levá-los a registro, cedê-los e licenciá-los, a qualquer título e a seu exclusivo critério.

11.7. **Feedback**. Você reconhece que a **WGMI** é livre para usar, aplicar, modificar, publicar, reproduzir e comercializar quaisquer comentários, informações, ideias, conceitos, opiniões, técnicas e/ou quaisquer outros materiais contidos em qualquer comunicação que Você envie à **WGMI**, seja por meio da Plataforma ou por outros canais de comunicação, incluindo, sem limitação, qualquer sugestão, solicitação de melhorias, recomendações oferecidas por Você à **WGMI**, sejam ou não relacionadas à Plataforma ("**Feedback**"). Você, neste ato, cede à **WGMI** todos os direitos patrimoniais de propriedade intelectual relacionados aos Feedbacks, de forma gratuita, irrevogável, irretratável, total, perpétua e global, sem que seja devido a Você qualquer remuneração, reconhecimento, pagamento ou indenização. Você declara e garante que, anteriormente à presente cessão, era o titular de todos os direitos de propriedade intelectual sobre os Feedbacks (na medida do aplicável) e que o oferecimento dos Feedbacks à **WGMI**, incluindo a presente cessão, não viola quaisquer direitos de terceiros.

11.8. Todos os anúncios, ofertas, promoções, marcas, textos e conteúdo de terceiros veiculados através da Plataforma são de propriedade de seus respectivos titulares, sendo expressamente proibida a utilização indevida de quaisquer conteúdo ou marcas apresentadas na Plataforma.

12. **COMO ENTRAR EM CONTATO COM O SUPORTE TÉCNICO DA PLATAFORMA?**

12.1. **Suporte técnico**. A prestação do serviço de suporte técnico se limita a esclarecimentos acerca do funcionamento da Plataforma, pressupondo o conhecimento por parte dos **USUÁRIOS** do uso do equipamento pelo qual se acessa a Plataforma, bem como do sistema operacional sob o qual a Plataforma será utilizada. Pressupõe-se, ainda, a configuração adequada do computador ou do dispositivo móvel para permitir a utilização da Plataforma e o bom estado de funcionamento do referido equipamento.

12.1.1. **Limitações do suporte**. O Suporte Técnico fornecido pela **WGMI** não abrange a conexão com a internet, rede interna e computadores ou dispositivos móveis dos **USUÁRIOS**. A **WGMI** não se responsabiliza pelo não funcionamento da Plataforma oriundo de falhas de conexão à Internet ou de funcionamento dos computadores ou dispositivos móveis que você utilizar para acessar a Plataforma.

13. **CANAIS DE ATENDIMENTO E COMUNICAÇÃO**

13.1. **Canais de Atendimento**. Somente os contatos realizados por meio dos Canais de Atendimento oficiais da **WGMI** serão reconhecidos. Sendo eles através de email pelo endereço [ajuda@8arm.io](mailto:ajuda@8arm.io).

13.2. **Comunicações ao USUÁRIO**. Serão consideradas válidas todas as comunicações e notificações enviadas pela **WGMI** ao endereço de correio eletrônico constante do cadastro do **USUÁRIO**, cabendo a este a responsabilidade por mantê-lo atualizado.

14. **O QUE MAIS O USUÁRIO PRECISA SABER SOBRE ESSES TERMOS DE USO?**

14.1. **Autoridades**. Qualquer ação que configure, ou possa configurar, prática de crime ou ato de má-fé ou contrário aos bons costumes ensejará a comunicação pela **WGMI** às autoridades competentes, sem prejuízo das demais medidas cabíveis conforme os presentes Termos de Uso e legislação aplicável, especialmente as operações que possam estar configuradas na legislação que dispõe sobre os crimes de lavagem ou ocultação de bens, direitos e valores.

14.2. **Autonomia**. Estes Termos de Uso não criam qualquer outra modalidade de vínculo entre o **USUÁRIO** e a **WGMI**, inclusive, sem limitação, mandato, representação, parceria, associação, vínculo empregatício ou similar. A WGMI permanecerá uma entidade independente e autônoma.

14.3. **Cessão**. A **WGMI** poderá, a qualquer tempo, mediante prévia comunicação ao **USUÁRIO**, ceder os direitos e obrigações referentes a estes Termos de Uso a empresas de seu mesmo grupo econômico ou societário, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações cedidas.

14.4. **Invalidade parcial**. Se alguma disposição destes Termos de Uso for considerada inválida, a validade das demais disposições destes Termos de Uso não será afetada e estas continuarão produzindo efeitos.

14.5. **Tolerância**. A omissão ou tolerância da **WGMI** em exigir o estrito cumprimento das obrigações previstas nestes Termos de Uso não constituirá, em nenhuma hipótese, novação ou renúncia, nem impedirá que a **WGMI** cobre esses direitos do **USUÁRIO** a qualquer tempo.

14.6. **Lei aplicável**. Esses Termos de Uso são regidos pelas leis da República Federativa do Brasil.

14.7. **Conflitos**. Quaisquer dúvidas e situações não previstas nestes Termos de Uso deverão ser preferencialmente resolvidas pela **WGMI** e, caso persistam, deverão ser solucionadas pelo foro do domicílio do **USUÁRIO**.

14.8. **Contato**. Caso você tenha qualquer dúvida ou deseje fazer um comentário, sugestão, reclamação ou elogio à **WGMI**, Você poderá entrar em contato com a **WGMI** por meio do e-mail [ajuda@8arm.io](mailto:ajuda@8arm.io).

**<span style="text-decoration:underline;">ANEXO</span>**

**Definições**

“**CNPJ**” significa o Cadastro Nacional de Pessoa Jurídica do Ministério da Fazenda.

“**Colaborador**” significa o colaborador da Empresa que possui cadastro na Plataforma.

“**Conta**” significa a conta detida pelo **USUÁRIO** na Plataforma, de forma a possibilitara utilização dos Serviços.

“**CPF**” significa o Cadastro de Pessoa Física do Ministério da Fazenda;

“**Empresa**” significa a pessoa jurídica que contrata os Serviços da **WGMI**.

“**Plataforma**” significa a plataforma controlada e operada pela **WGMI**, por meio da qual os **USUÁRIOS** podem acessar os Serviços.

"**Serviços**" significa os a serviços de processamento de documentos e informações relacionados à contratação do Colaborador pela Empresa, descritos no item 4 dos Termos de Uso.

“**Termos de Uso**” significa estes Termos e Condições Gerais de Uso.

“**USUÁRIO**” significa o Colaborador, a Empresa ou o Usuário Autorizado, conforme aplicável.

"**WGMI**" significa a WGMI TECNOLOGIAS LTDA., sociedade limitada, com sede na Cidade de São Paulo, Estado de São Paulo, inscrita no CNPJ sob o nº 45.645.560/0001-31.
`;
