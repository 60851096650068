import dayjs from 'dayjs';

import {
  PostValidateVacationsSchedulePathParams,
  VacationsScheduleInput,
} from '@octopus/api';

import { IVacationFormInputs } from '../form/types';

export type ISubmitPayrollPayload = {
  pathParams: PostValidateVacationsSchedulePathParams;
  body: VacationsScheduleInput;
};

export function parseFormPayloadToPayrolInput(
  formPayload: IVacationFormInputs,
): ISubmitPayrollPayload {
  if (!formPayload || typeof formPayload !== 'object') return null;

  const {
    organizationId,
    contractId,
    sequence,
    accrualPeriodStart,
    thirteenthAdvance,
    vacationDays,
    daysSold: daysSoldPayload,
  } = formPayload;

  const daysSold = daysSoldPayload ? 10 : 0;
  const startDateString = dayjs(`${formPayload.startDate}`);
  const startDate = startDateString.format('YYYY-MM-DD');
  const endDate = startDateString
    .add(vacationDays - 1, 'days')
    .format('YYYY-MM-DD');

  return {
    pathParams: {
      organizationId,
      contractId,
    },
    body: {
      startDate,
      endDate,
      sequence,
      daysSold,
      thirteenthAdvance,
      accrualPeriodStart,
    },
  };
}
