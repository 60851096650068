export type TFormEffectsInput = {
  warn: Array<{
    message: string;
    when: boolean;
    field?: string;
    value?: unknown;
  }>;
  disable: Array<{
    message: string;
    when: boolean;
    field?: string;
    value?: unknown;
  }>;
  throw: Array<{
    message: string;
    when: boolean;
    field?: string;
    value?: unknown;
  }>;
};

export type TFormEffects = {
  all: TFormEffectsInput;
  global: TFormEffectsInput;
};

export function parseFormEffects(
  effects: TFormEffectsInput | null,
): TFormEffects {
  return {
    all: effects,
    get global() {
      return {
        warn: effects.warn.filter(
          (effect) => !('field' in effect) && effect.when,
        ),
        disable: effects.disable.filter(
          (effect) => !('field' in effect) && effect.when,
        ),
        throw: effects.throw.filter(
          (effect) => !('field' in effect) && effect.when,
        ),
      } satisfies TFormEffectsInput;
    },
  };
}
