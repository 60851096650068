import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import {
  Categorias,
  IndicativoAdmissao,
  TipoAdmissao,
  TipoRegimePrevidenciario,
  TipoRegimeTrabalho,
} from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  UI_TYPE,
  nonEmptyString,
  validAdmissionDateSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../inputUtils';
import {
  AdmissionFormState,
  ENABLED_TIPO_REGIME_TRABALHO,
  FormStepDefinition,
  getEnabledCodigoCategoria,
} from '../types';

import { indicativoAdmissaoSchema } from './regimeSchema';

const getIndicativoAdmissaoFields = (
  nrProcTrab: string,
): Record<string, IFormDefinition> => ({
  '1': [] as IFormDefinition,
  '2': [] as IFormDefinition,
  '3': [
    {
      label: 'Número do processo trabalhista',
      type: z.string().length(20),
      name: 'nrProcTrab',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Preencha somente se indicativo for Decisão Judicial',
      value: nrProcTrab || '',
    },
  ],
});

const getFieldDefinition = ({
  formState,
  hideRegimeTrabFields,
}: {
  formState: AdmissionFormState;
  hideRegimeTrabFields: boolean;
}): IFormDefinition => {
  const {
    admissionDate,
    tpRegTrab,
    tpAdmissao,
    tpRegPrev,
    indAdmissao,
    nrProcTrab,
    codCateg,
    workerCategory,
  } = formState;

  return [
    {
      label: 'Data de admissão',
      type: validAdmissionDateSchema,
      name: 'admissionDate',
      uiType: UI_TYPE.DATE_PICKER,
      placeholder: '00/00/0000',
      value: admissionDate || '',
    },
    hideRegimeTrabFields
      ? undefined
      : {
          label: 'Tipo de regime trabalhista',
          type: z.number().int(),
          name: 'tpRegTrab',
          uiType: UI_TYPE.SELECT,
          placeholder: 'Selecione o regime trabalhista',
          options: getOptionsFromMapper({
            mapper: TipoRegimeTrabalho,
            currentValue: tpRegTrab,
            enabledKeys: ENABLED_TIPO_REGIME_TRABALHO,
          }),
        },
    {
      label: 'Tipo de admissão',
      type: z.number().int(),
      name: 'tpAdmissao',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de admissão',
      options: getOptionsFromMapper({
        mapper: TipoAdmissao,
        currentValue: tpAdmissao,
      }),
    },
    hideRegimeTrabFields
      ? undefined
      : {
          label: 'Regime previdenciário',
          type: z.number().int(),
          name: 'tpRegPrev',
          uiType: UI_TYPE.SELECT,
          placeholder: 'Selecione o regime previdenciário',
          options: getOptionsFromMapper({
            mapper: TipoRegimePrevidenciario,
            currentValue: tpRegPrev,
          }),
        },
    {
      label: 'Codigo de categoria',
      type: nonEmptyString,
      name: 'codCateg',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione um código',
      value: codCateg,
      options: getOptionsFromMapper({
        mapper: Categorias,
        currentValue: codCateg,
        enabledKeys: getEnabledCodigoCategoria({ workerCategory }),
      }),
    },
    {
      label: 'Indicativo de admissão',
      type: indicativoAdmissaoSchema,
      name: 'indAdmissao',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione a categoria',
      fieldsOptions: getOptionsFromMapper({
        mapper: IndicativoAdmissao,
        currentValue: indAdmissao?.defaultValue ?? '1',
      }).map((option) => {
        return {
          ...option,
          fields: [
            {
              label: '',
              type: z.number().optional(),
              name: `indAdmissao_${option.value}`,
              value: parseInt(option.value),
              uiType: UI_TYPE.TEXT_NUMBER,
              hidden: true,
            },
            ...getIndicativoAdmissaoFields(nrProcTrab)[option.value],
          ],
        };
      }),
    },
  ].filter((item) => item);
};

export const getRegimeFields = ({
  formState,
  regimeFormSteps,
  hideRegimeTrabFields = false,
}: {
  formState: AdmissionFormState;
  regimeFormSteps: AdmissionDraftInputFormStep;
  hideRegimeTrabFields?: boolean;
}): FormStepDefinition => ({
  definition: getFieldDefinition({ formState, hideRegimeTrabFields }),
  options: {
    id: admissionDraftFormSteps.regime_trabalho,
    title: hideRegimeTrabFields
      ? 'Admissão e categoria'
      : 'Admissão e regime de trabalho',
    completed: regimeFormSteps?.completed,
    reviewed: regimeFormSteps?.reviewed,
  },
});
