import {
  CompanySummary,
  MembershipTypes,
  fetchContractsPerson,
  fetchGetAdmissionDraftByUser,
  fetchGetAllCompanies,
  fetchGetOrganizationEntry,
  fetchGetUserMembership,
} from '@octopus/api';

import { MembershipOption } from '../types';

export async function fetchMembershipOptions(
  userId: string,
  personId: string | undefined,
): Promise<MembershipOption[]> {
  const response = await fetchMembershipData(userId, personId);
  return parseMembershipOptions(response);
}

type MembershipOptionResponse = {
  organizationId: string;
  organizationLabel: string;
  membershipType: MembershipTypes;
  organizationLogo: string | undefined;
  companies: {
    companyId: string;
    companyLabel: string;
    companyLogo: string | undefined;
  }[];
}[];

async function fetchMembershipData(
  userId: string,
  personId: string | undefined,
): Promise<MembershipOptionResponse> {
  const { data } = await fetchGetUserMembership({
    pathParams: {
      userId,
    },
  });
  return Promise.all(
    data.map(async (membership) => {
      const [organization, companies] = await Promise.all([
        fetchGetOrganizationEntry({
          pathParams: {
            organizationId: membership.organizationId,
          },
        }),
        fetchGetAllCompanies({
          pathParams: {
            organizationId: membership.organizationId,
          },
        }),
      ]);
      return {
        organizationId: organization.organizationId,
        organizationLabel: organization.name,
        membershipType: membership.type,
        organizationLogo: organization.logo,
        companies: await filterCompaniesByContract(
          companies.data ?? [],
          membership.type,
          membership.organizationId,
          personId,
          userId,
        ),
      };
    }),
  );
}

async function filterCompaniesByContract(
  companies: CompanySummary[],
  membershipType: MembershipTypes,
  organizationId: string,
  personId: string | undefined,
  userId: string,
) {
  let filteredCompanies = companies;
  if (membershipType === 'internal') {
    const [personContractsQuery, userIdAdmissionDraft] = await Promise.all([
      personId
        ? fetchContractsPerson({
            pathParams: {
              organizationId: organizationId,
              personId: personId,
            },
          })
        : undefined,
      fetchGetAdmissionDraftByUser({
        pathParams: {
          organizationId: organizationId,
          userId: userId,
        },
      }).catch(() => {
        return null;
      }),
    ]);
    const contracts = personContractsQuery?.data ?? [];

    filteredCompanies = companies.filter(
      (company) =>
        contracts.find(
          (contract) => contract.companyId === company.companyId,
        ) || userIdAdmissionDraft?.companyId === company.companyId,
    );
  }
  return filteredCompanies.map((company) => ({
    companyId: company.companyId,
    companyLabel: company.name,
    companyLogo: company.logo,
  }));
}

function parseMembershipOptions(
  response: MembershipOptionResponse,
): MembershipOption[] {
  const options: MembershipOption[] = [];
  for (const {
    organizationId,
    organizationLabel,
    membershipType,
    organizationLogo,
    companies,
  } of response.sort((a, b) =>
    a.organizationLabel?.localeCompare(b.organizationLabel),
  )) {
    for (const { companyId, companyLabel, companyLogo } of companies.sort(
      (a, b) => a.companyLabel?.localeCompare(b.companyLabel),
    )) {
      options.push({
        label: companyLabel,
        subLabel: companies.length > 1 ? organizationLabel : undefined,
        logo: companyLogo ?? organizationLogo,
        organizationId,
        companyId,
        membershipType,
      });
    }
  }
  return options;
}
