export type PollUntilProps<T> = {
  action: () => Promise<T>;
  assertion: (data: T) => boolean;
  intervalMillis: number;
  timeoutSeconds: number;
  initialWaitMillis?: number;
};

export async function pollUntil<T>({
  action,
  assertion,
  initialWaitMillis,
  timeoutSeconds,
  intervalMillis,
}: PollUntilProps<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    const initialWait =
      initialWaitMillis !== undefined ? initialWaitMillis : intervalMillis;
    const timeoutMillis = timeoutSeconds * 1000;

    const poll = () => {
      action()
        .then((result) => {
          if (assertion(result)) {
            resolve(result);
          } else if (Date.now() - startTime >= timeoutMillis) {
            reject(new Error('Polling timed out'));
          } else {
            setTimeout(poll, intervalMillis);
          }
        })
        .catch((error) => {
          reject(error);
        });
    };

    setTimeout(poll, initialWait);
  });
}
