import React, { useMemo, useState } from 'react';

type SnackbarQueueProps = React.PropsWithChildren;

export function SnackbarQueue(props: SnackbarQueueProps) {
  const isOpenSnapshot = React.Children.map(props.children, (child) => {
    if (!React.isValidElement(child)) return false;
    return Boolean(child.props?.isOpen) || false;
  }) as boolean[];

  const [openChildrenIdxs, setOpenChildrenIdxs] = useState([] as number[]);

  const nextOpenIdx = isOpenSnapshot?.findIndex((isOpen) => isOpen);
  useMemo(() => {
    setOpenChildrenIdxs((current) => [nextOpenIdx, ...current]);
  }, [nextOpenIdx]);

  const currentChildIdx = openChildrenIdxs.pop();
  const CurrentSnackbarChild = React.Children.map(
    props.children,
    (child, idx) => {
      if (!React.isValidElement(child)) return false;
      if (currentChildIdx !== idx) return false;
      return child;
    },
  )?.filter(Boolean)[0];

  return CurrentSnackbarChild;
}
