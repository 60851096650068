import { useMaskito } from '@maskito/react';

import { ContractBRContatoDeEmergencia } from '@octopus/api';
import { formatPhoneBR, getOnlyDigits } from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../form/Field/MaskitoOptions';
import { Record, RecordEntry } from '../../Record';
import { BaseRecordProps } from '../common';
import { useRecordEdit } from '../useRecordEdit';

type ContatoDeEmergenciaRecordData = {
  contatoDeEmergencia: ContractBRContatoDeEmergencia | undefined;
};

export type ContatoDeEmergenciaRecordProps =
  BaseRecordProps<ContatoDeEmergenciaRecordData>;

export function ContatoDeEmergenciaRecord(
  props: ContatoDeEmergenciaRecordProps,
) {
  const {
    data: { contatoDeEmergencia },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<ContatoDeEmergenciaRecordData>(props);

  const phoneMask = useMaskito({
    options: MaskitoOptionsBR.phone,
  });

  const formPropSetter =
    (
      propName: keyof ContractBRContatoDeEmergencia,
      formatter: (val: string) => string = (val) => val,
    ) =>
    (propValue: string) =>
      updateData((data) => ({
        ...data,
        contatoDeEmergencia: {
          ...data.contatoDeEmergencia,
          [propName]: formatter(propValue),
        },
      }));

  return (
    <Record title="Contato de emergência" edit={editRecordProps}>
      <RecordEntry
        label="Nome do contato"
        edit={{
          type: 'text',
          editing,
          value: formData?.contatoDeEmergencia?.nome,
          onChange: formPropSetter('nome'),
          hasError: hasError('br/contatoDeEmergencia/nome'),
        }}
      >
        {contatoDeEmergencia?.nome}
      </RecordEntry>
      <RecordEntry
        label="Telefone"
        edit={{
          type: 'text',
          editing,
          value: formatPhoneBR(formData?.contatoDeEmergencia?.telefone),
          onChange: formPropSetter('telefone', getOnlyDigits),
          mask: phoneMask,
          hasError: hasError('br/contatoDeEmergencia/telefone'),
        }}
      >
        {formatPhoneBR(contatoDeEmergencia?.telefone)}
      </RecordEntry>
      <RecordEntry
        label="Relação"
        edit={{
          type: 'text',
          editing,
          value: formData?.contatoDeEmergencia?.relacao,
          onChange: formPropSetter('relacao'),
          hasError: hasError('br/contatoDeEmergencia/relacao'),
        }}
      >
        {contatoDeEmergencia?.relacao}
      </RecordEntry>
    </Record>
  );
}
