import { useParams } from 'react-router-dom';

import { Box, Divider, Skeleton, Typography } from '@mui/material';

import {
  PayrollInputsConfig,
  PayrollInputsList,
  PayrollTypes,
  useGetPayrollInputsConfiguration,
  useGetPayrollInputsForPeriod,
} from '@octopus/api';
import { formatPeriodDate } from '@octopus/formatters';

import { BackButton } from '../../../../../modules/components/BackButton';
import { PageAlert } from '../../../../../modules/components/PageAlert';
import { DataFetching } from '../../../../../modules/dataFetching';
import { formatPayrollType } from '../../../../../modules/formatters';
import { PeriodFormat } from '../../../../../modules/types';

import { PayrollSubmission } from './PayrollSubmission';

export type PayrollInputsProps = {
  organizationId: string;
  companyId: string;
};

export function PayrollInputs({
  organizationId,
  companyId,
}: PayrollInputsProps) {
  const { period, type } = useParams<{
    period: PeriodFormat;
    type: PayrollTypes;
  }>();

  const inputsConfiguration = useGetPayrollInputsConfiguration(
    {
      pathParams: {
        organizationId,
        companyId,
      },
    },
    {
      enabled: !!organizationId && !!companyId,
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );

  const payrollInputs = useGetPayrollInputsForPeriod(
    {
      pathParams: {
        organizationId,
        companyId,
        periodId: period,
        payrollType: type,
      },
    },
    {
      enabled: !!organizationId && !!companyId && !!period && !!type,
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );

  if (!organizationId || !companyId) {
    return (
      <Typography variant="h1">
        Selecione uma organização e companhia
      </Typography>
    );
  }

  if (!period || !type) {
    return (
      <Typography variant="h1">
        Folha do mês informado não encontrada
      </Typography>
    );
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
      mt={10}
      mx={10}
      display="flex"
      flexDirection="column"
      gap={3}
    >
      <BackButton destination={`/payrolls/${period}/${type}`} />
      <Title period={period} type={type} />
      <DataFetching<{
        inputsConfiguration: PayrollInputsConfig;
        payrollInputs: PayrollInputsList;
      }>
        fetchResult={{
          results: {
            inputsConfiguration: {
              ...inputsConfiguration,
              isLoading:
                inputsConfiguration.isLoading ||
                !inputsConfiguration.isFetchedAfterMount,
            },
            payrollInputs: {
              ...payrollInputs,
              isLoading:
                payrollInputs.isLoading || !payrollInputs.isFetchedAfterMount,
            },
          },
        }}
        Loading={() => (
          <Skeleton
            variant="rounded"
            width="100%"
            height="calc(100vh - 245px)"
          />
        )}
        Data={({ data }) => {
          if (Object.keys(data.inputsConfiguration.payload).length === 0) {
            return <ConfigurationNotFoundError />;
          }
          return (
            <PayrollSubmission
              organizationId={organizationId}
              companyId={companyId}
              period={period}
              type={type}
              config={data.inputsConfiguration}
              data={data.payrollInputs}
            />
          );
        }}
        Error={() => {
          if (
            inputsConfiguration.error &&
            'statusCode' in inputsConfiguration.error &&
            inputsConfiguration.error.statusCode === 404
          ) {
            return <ConfigurationNotFoundError />;
          }
          return <GenericError />;
        }}
      />
    </Box>
  );
}

function Title({ period, type }: { period: PeriodFormat; type: PayrollTypes }) {
  return (
    <Box display="flex" flexDirection="column" gap={0.5} my={0.5}>
      <Typography variant="caption" fontWeight="500" color="text.secondary">
        Editar lançamentos
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="h2" color="text.primary">
          {formatPeriodDate(period)}
        </Typography>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Typography variant="h2" color="strokes.heavy">
          {formatPayrollType(type, true)}
        </Typography>
      </Box>
    </Box>
  );
}

function ConfigurationNotFoundError() {
  return (
    <Box
      border={(theme) => `1px solid ${theme.palette.strokes.light}`}
      borderRadius={2}
      width="100%"
      height="calc(100vh - 245px)"
    >
      <PageAlert
        message="Configuração de lançamentos não encontrada para essa organização!"
        severity="warning"
        retryMessage="Entre em contato com o suporte da Tako para efetuar a configuração."
        showRetryMessage={true}
      />
    </Box>
  );
}

function GenericError() {
  return (
    <Box
      border={(theme) => `1px solid ${theme.palette.strokes.light}`}
      borderRadius={2}
      width="100%"
      height="calc(100vh - 245px)"
    >
      <PageAlert
        message="Falha ao carregar tabela de lançamentos!"
        severity="error"
        showRetryMessage={true}
      />
    </Box>
  );
}
