import React from 'react';

import { Box, Container, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import {
  DataGrid,
  DataGridToolbar,
  GridRenderCellParams,
} from '@octopus/ui/data-grid';
import { Button } from '@octopus/ui/design-system';

import { DataFetching } from '../../dataFetching';
import { ActionMenu } from '../ActionMenu';
import TabLabel from '../TabLabel';

import { usePayrollTypePage } from './usePayrollTypePage';

type PayrollTypePageControlledProps = ReturnType<typeof usePayrollTypePage>;

type PayrollTypePageRenderProps = {
  TitleIcon: React.ElementType;
  Title: React.ElementType;
};

export function PayrollTypePage(
  props: PayrollTypePageControlledProps & PayrollTypePageRenderProps,
) {
  const payrollCreateNewLink = props.data?.links?.create();

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Container>
        <Box py={9}>
          <Box
            data-testid={`payroll-type-header`}
            sx={{
              paddingBottom: 2,
              marginBottom: 5,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              component={props.TitleIcon}
              sx={{ height: '40px', width: 'auto', mr: 2 }}
            />

            <Typography variant="h1">
              <props.Title />
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <DataGridToolbar
              filters={props.data.datagridToolbar.filters}
              searchProps={props.data.datagridToolbar.searchProps}
              filteringProps={props.data.datagridToolbar.filteringProps}
              searchPlaceholder="Procurar..."
              sx={{
                width: '100%',
              }}
            >
              {payrollCreateNewLink && (
                <Button
                  variantLayout="small"
                  to={payrollCreateNewLink.href}
                  sx={{ marginLeft: 'auto', alignSelf: 'stretch' }}
                >
                  {payrollCreateNewLink.label}
                </Button>
              )}
            </DataGridToolbar>
          </Box>

          <Box
            my={2}
            sx={(theme) => ({
              boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
            })}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={props.data.tabs.currentTabName}
              onChange={(_event, newTabName) =>
                props.data.tabs.onTabChange(newTabName)
              }
              textColor="inherit"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: `${
                    props.data.tabs.propsByTab[props.data.tabs.currentTabName]
                      .color
                  }.main`,
                },
              }}
            >
              {Object.entries(props.data.tabs.propsByTab)
                .filter(
                  ([_tabName, tabConfig]) => tabConfig.isVisible !== false,
                )
                .map(([tabName, tabConfig]) => (
                  <Tab
                    key={tabName}
                    value={tabName}
                    label={
                      <TabLabel
                        isSelected={tabName === props.data.tabs.currentTabName}
                        {...tabConfig}
                      />
                    }
                  />
                ))}
            </Tabs>
            {props.data.pageActionMenuItems && (
              <ActionMenu
                menuItems={props.data.pageActionMenuItems}
                variant="inline-visible"
              />
            )}
          </Box>

          <DataFetching
            fetchResult={props.requests.search}
            Loading={() => (
              <Skeleton variant="rounded" height={400} width="100%" />
            )}
            Data={() => {
              //TODO stop using DataFetching and add skeleton rows while loading
              if (!props.data?.datagrid) return null;

              const contextMenuColumnIdx: number = props.data.datagrid?.columns
                ? props.data.datagrid.columns.findIndex((column: unknown) => {
                    if (typeof column !== 'object') return false;
                    if (!('field' in column)) return false;
                    return column.field === 'contextMenu';
                  })
                : -1;

              const hasContextMenu = contextMenuColumnIdx > -1;
              if (
                hasContextMenu &&
                !props.data.datagrid.columns[contextMenuColumnIdx].renderCell
              ) {
                props.data.datagrid.columns[contextMenuColumnIdx].renderCell = (
                  _params: GridRenderCellParams,
                ) => {
                  return <ActionMenu menuItems={_params.formattedValue} />;
                };
              }

              return (
                <DataGrid
                  {...props.data.datagrid}
                  getRowSx={() => {
                    if (!hasContextMenu) return {};

                    return {
                      '--ActionMenu-opacity': 0,
                      ':hover': {
                        '--ActionMenu-opacity': 1,
                      },
                      'td:nth-last-of-type(1), th:nth-last-of-type(1)': {
                        pl: 0,
                        pr: 1,
                        width: '1em',
                      },
                    };
                  }}
                  getHeadSx={() => {
                    if (!hasContextMenu) return {};
                    return {
                      'th:nth-last-of-type(1)': {
                        pl: 0,
                        pr: 1,
                      },
                    };
                  }}
                />
              );
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
