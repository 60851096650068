import React from 'react';

import {
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormSteps,
} from '@octopus/api';
import {
  EstadoCivil,
  GrauInstrucao,
  Nacionalidades,
  Paises,
  RacaCor,
  Sexo,
} from '@octopus/esocial/mapper';
import { z } from '@octopus/i18n';
import {
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  cpfSchema,
  nonEmptyString,
  validHiringBirthDateSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { AdmissionReviewDependent } from '../../../review/AdmissionReviewDependents';
import { AddNewDependent } from '../../dependentes/NewAdmissionDependents';
import { NewAdmissionDocuments } from '../../documents/NewAdmissionDocuments';
import {
  getDadosBancariosFields,
  getDeficienciaFields,
} from '../fieldDefinitions';
import { getEnderecoEContatoFields } from '../fieldDefinitions/enderecoEContatos';
import { getOptionsFromMapper } from '../inputUtils';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  ENABLED_COUNTRIES,
  FormStepDefinition,
} from '../types';

export const getWorkerSteps = ({
  formState,
  formSteps,
  finalizaAdmissao,
}: {
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
}): AdmissionFormSteps => {
  const {
    nome,
    nomeSocial,
    cpf,
    nomeMae,
    grauInstr,
    sexo,
    racaCor,
    estadoCivil,
    dtNascto,
    nacionalidade,
    paisNascto,
  } = formState;
  const dados_pessoais = {
    definition: [
      {
        label: 'Nome completo',
        type: nonEmptyString,
        name: 'nome',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Maria de Lourdes',
        value: nome || '',
      },
      {
        label: 'Nome social',
        type: z.string().optional(),
        name: 'nomeSocial',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Maria de Lourdes',
        value: nomeSocial || '',
      },
      {
        label: 'CPF',
        type: cpfSchema,
        name: 'cpf',
        uiType: UI_TYPE.TEXT,
        placeholder: '000.000.000-00',
        value: cpf || '',
        mask: 'cpf',
      },
      {
        label: 'Nome da mãe',
        type: nonEmptyString,
        name: 'nomeMae',
        uiType: UI_TYPE.TEXT,
        placeholder: 'Ex: Joana de Lourdes',
        value: nomeMae || '',
      },
      {
        label: 'Sexo',
        type: nonEmptyString,
        name: 'sexo',
        uiType: UI_TYPE.SELECT,
        info: 'Essa é uma informação requerida pelo eSocial.',
        options: getOptionsFromMapper({ mapper: Sexo, currentValue: sexo }),
      },
      {
        label: 'Identidade racial',
        type: z.number().int(),
        name: 'racaCor',
        uiType: UI_TYPE.SELECT,
        info: 'Essa é uma informação requerida pelo eSocial.',
        options: getOptionsFromMapper({
          mapper: RacaCor,
          currentValue: racaCor,
        }),
      },
      {
        label: 'Estado civil',
        type: z.number().int(),
        name: 'estadoCivil',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione',
        options: getOptionsFromMapper({
          mapper: EstadoCivil,
          currentValue: estadoCivil,
        }),
      },
      {
        label: 'Data de nascimento',
        type: validHiringBirthDateSchema,
        name: 'dtNascto',
        uiType: UI_TYPE.DATE_PICKER,
        placeholder: '00/00/0000',
        value: dtNascto || '',
      },
      {
        label: 'País de nascimento',
        type: nonEmptyString,
        name: 'paisNascto',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar o país',
        options: getOptionsFromMapper({
          mapper: Paises,
          currentValue: paisNascto,
        }),
      },
      {
        label: 'Nacionalidade',
        type: nonEmptyString,
        name: 'nacionalidade',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Digite para buscar a nacionalidade',
        options: getOptionsFromMapper({
          mapper: Nacionalidades,
          currentValue: nacionalidade,
          enabledKeys: ENABLED_COUNTRIES,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.dados_pessoais,
      title: 'Dados pessoais',
      completed: formSteps[admissionDraftFormSteps.dados_pessoais]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.dados_pessoais]?.reviewed,
    },
  };

  const formacao_academica = {
    definition: [
      {
        label: 'Escolaridade',
        type: z.number().int(),
        name: 'grauInstr',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione',
        options: getOptionsFromMapper({
          mapper: GrauInstrucao,
          currentValue: grauInstr,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.formacao_academica,
      title: 'Formação acadêmica',
      completed:
        formSteps[admissionDraftFormSteps.formacao_academica]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.formacao_academica]?.reviewed,
    },
  };

  const pessoa_com_deficiencia = getDeficienciaFields({
    formState,
    deficienciaFormSteps: formSteps[admissionDraftFormSteps.deficiencias],
  });

  const dados_bancarios = getDadosBancariosFields({
    formState,
    dadosBancariosFormSteps: formSteps[admissionDraftFormSteps.dados_bancarios],
  });

  const documents_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        onSubmitCallback,
        goToNextStep,
        goToPreviousStep,
        organizationId,
        companyId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        goToNextStep: () => void;
        goToPreviousStep: () => void;
        onSubmitCallback: (newFormState: AdmissionFormState) => void;
        organizationId: string;
        companyId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <NewAdmissionDocuments
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmitCallback={onSubmitCallback}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            submitLabel={submitLabel}
          />
        );
      },
      id: admissionDraftFormSteps.documentos,
      title: 'Documentos',
      completed: formSteps[admissionDraftFormSteps.documentos]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.documentos]?.reviewed,
    },
  };

  const dependentes_section = {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        updateDisabled,
        isLoading,
        formState,
        stepDefinition,
        onSubmit,
        goToPreviousStep,
        organizationId,
        companyId,
        draftId,
        submitLabel,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
        stepDefinition: FormStepDefinition;
        goToPreviousStep: () => void;
        organizationId: string;
        companyId: string;
        draftId: string;
        submitLabel: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <AddNewDependent
            disabled={updateDisabled}
            isLoading={isLoading}
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmit={onSubmit}
            goToPreviousStep={goToPreviousStep}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            submitLabel={submitLabel}
          />
        );
      },
      id: admissionDraftFormSteps.dependentes,
      title: 'Dependentes',
      completed: formSteps[admissionDraftFormSteps.dependentes]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.dependentes]?.reviewed,
    },
  };

  const endereco_e_contatos = getEnderecoEContatoFields({
    formState,
    enderecoFormSteps: formSteps[admissionDraftFormSteps.endereco_e_contatos],
  });

  return {
    dados_pessoais,
    formacao_academica,
    endereco_e_contatos,
    deficiencias: pessoa_com_deficiencia,
    dependentes: dependentes_section,
    dados_bancarios,
    documentos: documents_section,
    ...(finalizaAdmissao && { finaliza_admissao: finalizaAdmissao }),
  };
};

export const getReviewDependentStep = ({
  fieldStepsState,
  dependentId,
}: {
  fieldStepsState: AdmissionDraftInputFormInfo;
  dependentId: string;
}): FormStepDefinition => {
  const formSteps = fieldStepsState?.formSections?.['personal_data'] ?? {};
  return {
    definition: [] as IFormDefinition,
    options: {
      getCustomComponent: ({
        formState,
        stepDefinition,
        onSubmitCallback,
        organizationId,
        companyId,
        draftId,
        formId,
      }: {
        updateDisabled: boolean;
        isLoading: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmitCallback: (newFormState: AdmissionFormState) => void;
        organizationId: string;
        companyId: string;
        draftId: string;
        formId?: string;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }
        return (
          <AdmissionReviewDependent
            formState={formState}
            stepDefinition={stepDefinition}
            onSubmitCallback={onSubmitCallback}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            formId={formId}
            dependentId={dependentId}
          />
        );
      },
      id: admissionDraftFormSteps.dependentes,
      title: 'Dependentes',
      dependentId,
      completed: formSteps[admissionDraftFormSteps.dependentes]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.dependentes]?.reviewed,
    },
  };
};
