export type PolygonProps = {
  width?: number;
  height?: number;
  fill?: string;
};

export default function Polygon({
  width = 200,
  height = 200,
  fill = '#1E78FF',
}: PolygonProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_148_24645)">
        <path
          d="M64.7254 5.17598C69.2805 1.73304 75.0166 0.240608 80.672 1.02698L139.509 9.20814C145.165 9.99451 150.276 12.9953 153.719 17.5503L189.538 64.9395C192.981 69.4945 194.474 75.2307 193.687 80.8861L185.506 139.723C184.72 145.379 181.719 150.49 177.164 153.933L129.775 189.752C125.22 193.195 119.484 194.688 113.828 193.901L54.9909 185.72C49.3355 184.934 44.2241 181.933 40.7812 177.378L4.96193 129.989C1.51899 125.434 0.0265573 119.698 0.812929 114.042L8.99409 55.205C9.78046 49.5495 12.7812 44.4381 17.3363 40.9952L64.7254 5.17598Z"
          fill={fill}
        />
      </g>
      <path
        d="M64.9283 5.44433C69.4121 2.05519 75.0587 0.586078 80.6257 1.36016L139.463 9.54132C145.03 10.3154 150.062 13.2693 153.451 17.7532L189.27 65.1423C192.659 69.6262 194.128 75.2727 193.354 80.8398L185.173 139.677C184.399 145.244 181.445 150.276 176.961 153.665L129.572 189.484C125.088 192.873 119.442 194.342 113.874 193.568L55.0372 185.387C49.4702 184.613 44.4387 181.659 41.0495 177.175L5.23028 129.786C1.84114 125.302 0.372028 119.656 1.14611 114.089L9.32728 55.2513C10.1014 49.6842 13.0552 44.6527 17.5391 41.2636L64.9283 5.44433Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="0.672779"
      />
      <defs>
        <filter
          id="filter0_i_148_24645"
          x="0.607666"
          y="0.821777"
          width="193.285"
          height="197.285"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="23" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_148_24645"
          />
        </filter>
      </defs>
    </svg>
  );
}
