import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ContractEntry } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

export type UpdateDialogProps = {
  contractType: ContractEntry['contractType'];
  startDate: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
  isLoading: boolean;
  isError: boolean;
  mutate: (effectiveDate: string) => void;
};

export function UpdateDialog({
  contractType,
  startDate,
  open,
  onClose,
  onSuccess,
  onError,
  isLoading,
  isError,
  mutate,
}: UpdateDialogProps) {
  const now = dayjs();
  const [scene, setScene] = useState<1 | 2 | 3>(1);
  const [effectiveDate, setEffectiveDate] = useState(now.format('YYYY-MM-DD'));

  const contractStartDate = dayjs(startDate, 'YYYY-MM-DD');
  const startOfLastMonth = now.startOf('month').subtract(1, 'month');
  const minDate = contractStartDate.isAfter(startOfLastMonth)
    ? contractStartDate
    : startOfLastMonth;

  const maxDate = now.startOf('month').add(1, 'month').endOf('month');

  useEffect(() => {
    if (!open) {
      setScene(1);
      setEffectiveDate(now.format('YYYY-MM-DD'));
    }
  }, [open]);

  useEffect(() => {
    if (scene === 3 && !isLoading) {
      if (!isError) {
        onSuccess();
      } else {
        onError();
      }
    }
  }, [isLoading, isError]);

  const cltEffectiveDateScene = (
    <>
      <DialogTitle sx={{ px: 4, pt: 5, pb: 3 }}>
        <Typography variant="h1">Data de vigência</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="body1">
            As mudanças devem começar a ter efeito a partir de qual data?
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              defaultValue={dayjs(effectiveDate)}
              sx={{ width: '100%' }}
              onChange={(v) => setEffectiveDate(v.format('YYYY-MM-DD'))}
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
          <Typography variant="caption">
            Algumas mudanças dependem de sincronização com o eSocial e por isso
            podem não acontecer imediatamente.
          </Typography>
          {now.isBefore(dayjs(effectiveDate), 'day') && (
            <Alert
              severity="info"
              sx={{ py: 0.5, px: 3, gap: 1, alignItems: 'center' }}
              icon={<InfoIcon />}
            >
              <Typography variant="caption" fontWeight="500">
                Você selecionou uma data no futuro, portanto as alterações serão
                agendadas para acontecer na data escolhida.
              </Typography>
            </Alert>
          )}
          {now.isAfter(dayjs(effectiveDate), 'day') && (
            <Alert
              severity="warning"
              sx={{ py: 0.5, px: 3, gap: 1, alignItems: 'center' }}
              icon={<WarningIcon />}
            >
              <Typography variant="caption" fontWeight="500">
                Mudanças com data de vigência no passado não impactam folhas já
                aprovadas, apenas folhas abertas e folhas futuras.
              </Typography>
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={() => setScene(2)}
          disabled={
            dayjs(effectiveDate).isBefore(minDate, 'day') ||
            dayjs(effectiveDate).isAfter(maxDate, 'day')
          }
        >
          Continuar
        </Button>
      </DialogActions>
    </>
  );

  const pjEffectiveDateScene = (
    <>
      <DialogTitle sx={{ px: 4, pt: 5, pb: 3 }}>
        <Typography variant="h1">Data de vigência</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="body1">
            As mudanças devem começar a ter efeito a partir de qual data?
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              defaultValue={dayjs(effectiveDate)}
              sx={{ width: '100%' }}
              onChange={(v) => setEffectiveDate(v.format('YYYY-MM-DD'))}
            />
          </LocalizationProvider>
          {now.isBefore(dayjs(effectiveDate), 'day') && (
            <Alert
              severity="info"
              sx={{ py: 0.5, px: 3, gap: 1, alignItems: 'center' }}
              icon={<InfoIcon />}
            >
              <Typography variant="caption" fontWeight="500">
                Você selecionou uma data no futuro, portanto as alterações serão
                agendadas para acontecer na data escolhida.
              </Typography>
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primaryAlt" size="large" onClick={() => setScene(2)}>
          Continuar
        </Button>
      </DialogActions>
    </>
  );

  const cltConfirmationScene = (
    <>
      <DialogTitle sx={{ px: 4, pt: 5, pb: 3 }}>
        <Typography variant="h1">Alteração de dados de colaborador</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="body1" fontWeight="700">
            Ao confirmar, o que acontece em seguida:
          </Typography>
          <List sx={{ py: 0, px: 2, listStyleType: 'disc' }}>
            <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
              <Box>
                <Typography variant="body1" fontWeight="500">
                  Envios para aprovação do eSocial
                </Typography>
                <Typography variant="caption">
                  Alguns dados exigem aprovação obrigatória do eSocial para
                  terem efeito. A aprovação costuma levar algumas horas, mas
                  pode demorar mais que um dia em algumas ocasiões.
                </Typography>
              </Box>
            </ListItem>
            <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
              <Box>
                <Typography variant="body1" fontWeight="500">
                  Aplicação das alterações
                </Typography>
                <Typography variant="caption">
                  Após a aprovação do eSocial, as alterações serão efetivadas em
                  todo o sistema.
                </Typography>
              </Box>
            </ListItem>
          </List>
          <Alert severity="info">
            Se mudar de ideia sobre as alterações, você pode cancelar no
            histórico de mudanças.
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={() => {
            mutate(effectiveDate);
            setScene(3);
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </>
  );

  const pjConfirmationScene = (
    <>
      <DialogTitle sx={{ px: 4, pt: 5, pb: 3 }}>
        <Typography variant="h1">Alteração de dados de colaborador</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="body1" fontWeight="700">
            Ao confirmar, o que acontece em seguida:
          </Typography>
          <List sx={{ py: 0, px: 2, listStyleType: 'disc' }}>
            <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
              <Box>
                <Typography variant="body1" fontWeight="500">
                  Aplicação das alterações
                </Typography>
                <Typography variant="caption">
                  As alterações serão efetivadas imediatamente em todo o
                  sistema.
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={() => {
            mutate(effectiveDate);
            setScene(3);
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </>
  );

  const loadingScene = (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Atualizando o contrato...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: '600px' } }}
    >
      {scene === 1 && contractType === 'br:clt' && cltEffectiveDateScene}
      {scene === 1 && contractType === 'br:pj' && pjEffectiveDateScene}
      {scene === 2 && contractType === 'br:clt' && cltConfirmationScene}
      {scene === 2 && contractType === 'br:pj' && pjConfirmationScene}
      {scene === 3 && loadingScene}
    </Dialog>
  );
}
