import { ReactNode, createContext, memo, useEffect, useState } from 'react';

import { Snackbar } from '@octopus/ui/design-system';

import {
  SnackbarResult,
  SnackbarType,
  useSnackbarContext,
} from '../modules/hooks/snackbarContext';

export const SnackbarContext = createContext<SnackbarResult>(undefined);

export const SnackbarProvider = memo(
  ({ children }: { children: ReactNode }) => {
    const context = useSnackbarContext();

    const [snackbar, setSnackbar] = useState<SnackbarType>();

    useEffect(() => {
      const { currentSnackbar } = context;
      setSnackbar(currentSnackbar);
    }, [context, context.currentSnackbar]);

    return (
      <SnackbarContext.Provider value={context}>
        {children}
        {snackbar && <Snackbar {...snackbar} />}
      </SnackbarContext.Provider>
    );
  },
);
