import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { Submission } from '@conform-to/react';

import CloseRoundedIcon from '@mui/icons-material/CloseOutlined';
import { Box, IconButton, Typography } from '@mui/material';

import {
  ContractBRPjEntry,
  ContractEntry,
  PjPaymentAttachmentAllowedContentTypes,
  PjPaymentRequestInput,
  fetchPostPaymentRequests,
  fetchPostPaymentRequestsAttachment,
} from '@octopus/api';
import {
  brEnquadramentosTributarios,
  contractTypes,
  isBRPjContract,
} from '@octopus/contract-types';

import requestPaymentPj from '../../../assets/requestPaymentPj.svg';
import { ContractSelector } from '../../../modules/components/contracts/ContractSelector';
import {
  ConfirmationDialog,
  ErrorDialog,
  LoadingDialog,
} from '../../../modules/components/pj/dialogs';
import {
  LucroPresumidoInputForm,
  SimplesNacionalInputForm,
} from '../../../modules/components/pj/inputForms';
import { poolOpenSearch } from '../../../modules/components/pj/pjComponents';

import { FileComponent, uploadFile } from './fileComponents';

export function CreatePaymentRequestPage({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const [file, setFile] = useState(undefined as File);

  return (
    <Box display={'flex'} width={'100%'} height={'100vh'}>
      <FileComponent setFile={setFile} file={file} />

      <InputComponent
        organizationId={organizationId}
        companyId={companyId}
        file={file}
      />
    </Box>
  );
}

function InputComponent({
  organizationId,
  companyId,
  file,
}: {
  file: File | undefined;
  organizationId: string;
  companyId: string;
}) {
  const navigate = useNavigate();
  const [activeContract, setActiveContract] = useState<
    ContractEntry | undefined
  >(undefined);

  return (
    <Box
      width={'50%'}
      display={'flex'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      mb={5}
      pt={5}
      px={7}
      overflow={'scroll'}
    >
      <Box
        width={'100%'}
        display="flex"
        flexDirection="row"
        justifyContent={'space-between'}
        gap={5}
      >
        <Box
          display="flex"
          flexDirection="row"
          data-testid="new-payment-request-header"
          gap={1.5}
        >
          <img
            src={requestPaymentPj}
            height={32}
            width={32}
            alt="Nova solicitação de pagamento icon"
          />
          <Typography variant="h2" fontWeight={700}>
            Nova solicitação de pagamento
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              navigate('/payment-requests');
            }}
          >
            <CloseRoundedIcon
              sx={{
                width: '32px',
                height: '32px',
                color: '#25252D',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box pt={3} pb={2}>
        <Box gap={0.5} display={'flex'} px={0.75}>
          <Typography variant={'caption'} fontSize={'12px'}>
            Prestador(a)
          </Typography>
          <Typography
            variant={'caption'}
            fontWeight={700}
            color={'error'}
            fontSize={'16px'}
          >
            *
          </Typography>
        </Box>
        <ContractSelector
          organizationId={organizationId}
          setActiveContract={setActiveContract}
          activeContract={activeContract}
          searchFilters={{
            elements: {
              companyId: [companyId],
              contractType: [contractTypes.brPj],
            },
          }}
        />
        {activeContract && (
          <InputPaymentRequest
            contract={activeContract}
            companyId={companyId}
            organizationId={organizationId}
            file={file}
          />
        )}
      </Box>
    </Box>
  );
}

async function postPaymentRequest({
  organizationId,
  companyId,
  contract,
  period,
  grossAmount,
  description,
  invoice,
}: {
  organizationId: string;
  companyId: string;
  contract: ContractEntry;
  period: string;
  grossAmount: string;
  description: string;
  invoice?: PjPaymentRequestInput['invoice'];
}): Promise<string> {
  const hasTaxes =
    (contract.br as ContractBRPjEntry).empresa.enquadramentoTributario ===
    brEnquadramentosTributarios.lucroPresumido;

  const convertEmptyToZero = (value: string | undefined) => {
    return value === '' ? '0' : value;
  };

  const response = await fetchPostPaymentRequests({
    pathParams: {
      organizationId,
      companyId,
    },
    body: {
      contractId: contract.contractId,
      period: period,
      grossAmount: grossAmount,
      description: description,
      ...(invoice !== undefined && {
        invoice: {
          number: invoice.number,
          ...(hasTaxes && {
            taxes: {
              br: {
                inss: convertEmptyToZero(invoice.taxes.br.inss),
                irrf: convertEmptyToZero(invoice.taxes.br.irrf),
                csll: convertEmptyToZero(invoice.taxes.br.csll),
                cofins: convertEmptyToZero(invoice.taxes.br.cofins),
                pisPasep: convertEmptyToZero(invoice.taxes.br.pisPasep),
                others: convertEmptyToZero(invoice.taxes.br.others),
                iss: convertEmptyToZero(invoice.taxes.br.iss),
              },
            },
          }),
        },
      }),
    } as PjPaymentRequestInput,
  });

  return response.id;
}

function InputPaymentRequest({
  organizationId,
  companyId,
  contract,
  file,
}: {
  file: File | undefined;
  organizationId: string;
  companyId: string;
  contract: ContractEntry | undefined;
}) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [payload, setPayload] = useState({} as Record<string, unknown>);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const submitAction = async (payload: Record<string, unknown>) => {
    setLoading(true);

    try {
      const paymentRequestId = await postPaymentRequest({
        organizationId,
        companyId,
        contract: contract,
        period: payload.period as string,
        grossAmount: payload.grossAmount as string,
        description: payload.description as string,
        invoice: payload.invoiceNumber
          ? ({
              number: parseInt(payload.invoiceNumber as string),
              taxes: {
                br: {
                  inss: payload.inss,
                  irrf: payload.irrf,
                  csll: payload.csll,
                  cofins: payload.cofins,
                  pisPasep: payload.pisPasep,
                  others: payload.others,
                  iss: payload.iss,
                },
              },
            } as PjPaymentRequestInput['invoice'])
          : undefined,
      });

      if (!file) {
        await poolOpenSearch({
          organizationId,
          companyId,
          contractId: contract?.contractId,
          paymentRequestId: paymentRequestId,
        });

        navigate('/payment-requests');
        return;
      }

      const attachmentEntry = await fetchPostPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId: paymentRequestId,
        },
        body: {
          type: 'invoice',
          contentType: file.type as PjPaymentAttachmentAllowedContentTypes,
          contentLength: file.size,
          fileName: file.name,
        },
      });

      await uploadFile(attachmentEntry, file);
      await poolOpenSearch({
        organizationId,
        companyId,
        contractId: contract?.contractId,
        paymentRequestId: paymentRequestId,
        filters: {
          invoiceUploaded: ['true'],
        },
      });

      navigate('/payment-requests');
    } catch (e) {
      console.log(`Error ao realizar submit`, e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitForm = useCallback(
    (
      _event: React.FormEvent<HTMLFormElement>,
      submission?: Submission<unknown>,
    ) => {
      if (!('value' in submission)) {
        return;
      }

      const value = submission.value as Record<string, unknown>;

      if (!file) {
        setPayload(value);
        setShowConfirmationDialog(true);
      } else {
        submitAction(value).catch(() => {
          setError(true);
        });
      }
    },
    [organizationId, file],
  );

  if (!contract || !isBRPjContract(contract)) {
    return undefined;
  }

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} p={1}>
        {contract.br.empresa.enquadramentoTributario ===
          brEnquadramentosTributarios.simples && (
          <SimplesNacionalInputForm onSubmit={onSubmitForm} />
        )}
        {contract.br.empresa.enquadramentoTributario ===
          brEnquadramentosTributarios.lucroPresumido && (
          <LucroPresumidoInputForm onSubmit={onSubmitForm} />
        )}
      </Box>
      {showConfirmationDialog && (
        <ConfirmationDialog
          open={showConfirmationDialog}
          action={() => submitAction(payload)}
          setOpen={setShowConfirmationDialog}
          setError={setError}
        />
      )}
      {loading && (
        <LoadingDialog
          open={loading}
          message={'Criando a solicitação, aguarde alguns segundos...'}
        />
      )}
      {error && <ErrorDialog open={error} setOpen={setError} />}
    </>
  );
}
