import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { PjPaymentAttachmentEntry } from '@octopus/api';

export function FileComponent({
  file,
  setFile,
  showDeleteButton = true,
  isLoading = false,
}: {
  file: File | undefined;
  setFile: (file: File | undefined) => void;
  showDeleteButton?: boolean;
  isLoading?: boolean;
}) {
  return (
    <Box
      bgcolor={'#F7F7F8'}
      padding={'40px 56px'}
      border={'1px solid'}
      borderColor={'strokes.default'}
      width={'50%'}
      height={'100%'}
      boxSizing={'border-box'}
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      })}
    >
      {isLoading && <FileOperationScene type={'Carregando'} />}
      {!isLoading && !file && <UploadComponent setFile={setFile} />}
      {!isLoading && file && (
        <FilePreviewComponent
          file={file}
          setFile={setFile}
          showDeleteButton={showDeleteButton}
        />
      )}
    </Box>
  );
}

export function UploadComponent({
  setFile,
}: {
  setFile: (file: File) => void;
}) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0].size > 5000000) {
      setError('O arquivo deve ter no máximo 5000kb');
      return;
    }
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
  });

  const [error, setError] = useState(undefined as string);

  const bgColorDragging = '#EDEDED';
  const bgColorNotDragging = '#FFF';

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const bgColor = isSmall
    ? 'background.primary'
    : isDragActive
      ? bgColorDragging
      : bgColorNotDragging;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      borderRadius={1}
      bgcolor={bgColor}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      {...getRootProps()}
      boxSizing={'border-box'}
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          pb: 5,
          px: 3,
          pt: 4,
        },
        [theme.breakpoints.up('sm')]: {
          padding: 3,
        },
      })}
    >
      <Box display={'flex'} flexDirection={'row'} bgcolor={bgColor}>
        <FileUploadOutlinedIcon
          sx={{
            width: '24px',
            height: '24px',
            color: '#616161',
          }}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        bgcolor={bgColor}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <Box display={'flex'} bgcolor={bgColor}>
          <Button variant={'text'} component={'label'}>
            <Typography
              fontSize={'14px'}
              fontWeight={700}
              lineHeight={'20px'}
              color={'info.main'}
            >
              Clique aqui
              <input type={'file'} {...getInputProps()} />
            </Typography>
          </Button>
          <Typography
            fontSize={'14px'}
            fontWeight={700}
            lineHeight={'20px'}
            py={1}
          >
            ou arraste o arquivo da nota fiscal
          </Typography>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        bgcolor={bgColor}
        width={'100%'}
        maxWidth={'100%'}
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        <Box display={'flex'} bgcolor={bgColor} justifyContent={'center'}>
          <Button variant={'text'} component={'label'}>
            <label htmlFor={'fileInput'}>
              Toque aqui para enviar o arquivo da nota fiscal
            </label>
            <input
              type={'file'}
              hidden={true}
              accept={'.pdf,.png,.jpg,.jpeg'}
              id={'fileInput'}
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
            />
          </Button>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        bgcolor={bgColor}
        gap={2}
        maxWidth={'280px'}
      >
        <Typography
          fontSize={'12px'}
          lineHeight={'16px'}
          variant={'caption'}
          color={'text.secondary'}
          textAlign={'center'}
        >
          São aceitos arquivos no formato PDF, JPG e PNG, com tamanho máximo de
          5000kb
        </Typography>
        {error && (
          <Typography fontSize={'12px'} lineHeight={'16px'} color={'error'}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export function FilePreviewComponent({
  file,
  setFile,
  showDeleteButton,
}: {
  file?: File;
  setFile: (file?: File) => void;
  showDeleteButton: boolean;
}) {
  if (!file) {
    return undefined;
  }

  return (
    <Box
      width={1}
      height={1}
      display={'flex'}
      flex={'auto'}
      boxSizing={'border-box'}
      position={'relative'}
    >
      {file.type === 'application/pdf' && (
        <iframe
          src={URL.createObjectURL(file) + '#navpanes=0&scrollbar=0'}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {file.type.startsWith('image/') && (
        <Box border={'1px solid black'}>
          <img
            src={URL.createObjectURL(file)}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      )}
      {showDeleteButton && (
        <Box
          position={'absolute'}
          bottom={0}
          sx={{
            transform: 'translateX(-50%) translateY(50%)',
            left: '50%',
            bgcolor: '#FFF',
          }}
        >
          <IconButton
            onClick={() => {
              setFile(undefined);
            }}
            sx={{
              border: '1px solid #EDEDED',
              borderRadius: 1,
              boxShadow:
                '0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 16px 0px rgba(0, 0, 0, 0.03)',
            }}
          >
            <DeleteOutlineOutlinedIcon
              color={'action'}
              width={'16px'}
              height={'16px'}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export async function uploadFile(
  response: PjPaymentAttachmentEntry,
  file: File,
) {
  const data = await file.arrayBuffer();

  const resp = await fetch(response.uploadUrl, {
    method: 'PUT',
    body: data,
  });

  if (resp.status !== 200) {
    throw new Error('Erro ao enviar arquivo');
  }
}

function FileOperationScene({ type }: { type: string }) {
  return (
    <Box
      display={'flex'}
      height={'100%'}
      justifyContent={'center'}
      width={'100%'}
      alignItems={'center'}
    >
      <Box
        display={'flex'}
        height={'10vh'}
        px={5}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
      >
        <Typography variant={'caption'} color={'text.secondary'} noWrap={true}>
          {type} arquivo...
        </Typography>

        <CircularProgress />
      </Box>
    </Box>
  );
}
