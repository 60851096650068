import { useCallback, useEffect, useMemo } from 'react';

import { Submission, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

import {
  IFormDefinition,
  createMetaFormSchema,
  createValidationSchema,
  getDefaults,
  getParsedValue,
} from '@octopus/libs/forms';

import { getFields } from './getFields';
import { getFieldsRenderProps } from './getFieldsRenderProps';
import {
  TFieldRenderPropOptions,
  TFieldSelectRenderProps,
} from './parseField/types';
import { useFormFromDefinitionState } from './useFormFromDefinitionState';
import { useMetaFormAutocompleteOptionsState } from './useMetaFormAutocompleteOptionsState';

type IFormDefinitionOptions = {
  id: string;
  persistLocal?: boolean;
  useNewParser?: boolean;
  shouldValidate?: 'onBlur' | 'onInput' | 'onSubmit';
  onOptionsSearch?: (
    fieldName: string,
    queryFieldName: string,
    queryFieldValue: string,
    queryTimestamp: string,
  ) =>
    | TFieldSelectRenderProps['select']['options']
    | undefined
    | Promise<TFieldSelectRenderProps['select']['options'] | undefined>;
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData: Submission<any, string[], any>,
  ) => void;
};

const log = (..._args: unknown[]): void => {
  //console.log('[useFormFromDefinition]', _args);
};

export function useFormFromDefinition(
  definition: IFormDefinition,
  options: IFormDefinitionOptions,
) {
  const validationSchema = useMemo(
    () => createValidationSchema(definition),
    [definition],
  );
  const metaFormSchema = useMemo(
    () => createMetaFormSchema(definition),
    [definition],
  );

  const {
    onSubmit: onSubmitCallbackArg,
    id: formIdArg,
    shouldValidate = 'onSubmit',
  } = options;
  const { metaFormState, payloadFormState } = useFormFromDefinitionState(
    options.persistLocal ? formIdArg : null,
  );

  const [metaForm, metaFields] = useForm({
    id: metaFormState.id,
    defaultValue: metaFormState.lastResult?.initialValue,
    lastResult: metaFormState.lastResult,
    constraint: getZodConstraint(metaFormSchema),
    onSubmit(event, context) {
      event.preventDefault();
      const submission = parseWithZod(context.formData, {
        schema: metaFormSchema,
      });

      const submissionResult = submission.reply();

      metaFormState.set({
        submission,
        submissionResult,
      });

      log('===META SUBMISSION=', submission);
      log('===META CTX =', context);
    },
    onValidate({ formData }) {
      const submission = parseWithZod(formData, { schema: metaFormSchema });
      metaFormState.set({
        submission,
      });
      return submission;
    },
  });

  const defaultPayloadValue = useMemo(
    () => getDefaults(validationSchema as z.AnyZodObject),
    [validationSchema],
  );

  const [payloadForm, payloadFields] = useForm({
    shouldValidate,
    id: payloadFormState.id,
    defaultValue:
      payloadFormState.lastResult?.initialValue ?? defaultPayloadValue,
    lastResult: payloadFormState.lastResult,
    constraint: getZodConstraint(validationSchema),
    onSubmit(event, context) {
      event.preventDefault();
      const submission = parseWithZod(context.formData, {
        schema: validationSchema,
      });
      const submissionResult = submission.reply();

      document.forms.namedItem(metaFormState.id).requestSubmit();

      payloadFormState.set({
        submission,
        submissionResult,
      });

      log('===SUBMISSION=', submission);
      log('===SUBMISSION RESULT=', submissionResult);
      log('===CTX =', context);
      onSubmitCallbackArg(event, submission);
    },
    onValidate({ formData }) {
      const submission = parseWithZod(formData, { schema: validationSchema });
      payloadFormState.set({
        submission,
      });
      return submission;
    },
  });

  const payloadformValue = payloadForm.value;
  const payloadFormInitialValue = payloadForm.initialValue;
  const payloadFormErrors = payloadForm.allErrors;
  useEffect(() => {
    log('===FORM VALUE=', payloadFormState.id, payloadformValue);
    // log('===FORM INITIAL VALUE=', payloadFormSetup.id, payloadFormInitialValue);
    if (Object.keys(payloadFormErrors).length)
      log('===FORM ERRORS=', payloadFormState.id, payloadFormErrors);
  }, [
    payloadFormState.id,
    payloadformValue,
    payloadFormErrors,
    payloadFormInitialValue,
  ]);

  const metaFormValue = metaForm.value;
  const metaFormInitialValue = metaForm.initialValue;
  const metaFormErrors = metaForm.allErrors;

  const payloadValue = useMemo(() => {
    return getParsedValue(validationSchema, payloadForm.value);
  }, [validationSchema, payloadForm.value]);

  const metaValue = useMemo(() => {
    return getParsedValue(metaFormSchema, metaForm.value);
  }, [metaFormSchema, metaForm.value]);

  useEffect(() => {
    log('===META FORM VALUE=', metaFormState.id, metaFormValue);
    // log('===META FORM INITIAL VALUE=', metaFormSetup.id, metaFormInitialValue);
    if (Object.keys(metaFormErrors).length)
      log('===META FORM ERRORS=', metaFormState.id, metaFormErrors);
  }, [metaFormState.id, metaFormValue, metaFormErrors, metaFormInitialValue]);

  const fieldsAutocomplete = useMetaFormAutocompleteOptionsState(metaForm);

  useEffect(() => {
    if (!options.onOptionsSearch) return;
    const fieldAutocompleteEntries = Object.entries(fieldsAutocomplete);

    for (const [
      autocompleteFieldName,
      autocompleteState,
    ] of fieldAutocompleteEntries) {
      log(
        '===Field Options Search',
        autocompleteFieldName,
        autocompleteState.queryFieldValue,
      );
      const searchResult = options.onOptionsSearch(
        autocompleteState.targetFieldName,
        autocompleteState.queryFieldName,
        autocompleteState.queryFieldValue,
        autocompleteState.queryTimestamp,
      );

      autocompleteState.resolveQuery(searchResult);
    }
  }, [fieldsAutocomplete, options]);

  const fieldRenderOptions = options.useNewParser
    ? getFields({
        definition,
        payloadFields,
        metaFields,
        metaForm,
        payloadFormErrors,
      })
    : getFieldsRenderProps({
        definition,
        payloadFields,
        metaFields,
        metaForm,
        payloadFormErrors,
      });

  const fieldsRenderOptionsEntries = useMemo(
    () => fieldRenderOptions,
    [fieldRenderOptions],
  );

  const fieldOptionsByName = useMemo(
    () =>
      fieldsRenderOptionsEntries.reduce(
        (fieldOptionsByName, fieldsRenderOptionsEntry) => {
          const [fieldName] = fieldsRenderOptionsEntry;

          fieldOptionsByName[fieldName] = fieldsRenderOptionsEntry;
          return fieldOptionsByName;
        },
        {} as Record<string, TFieldRenderPropOptions>,
      ),
    [fieldsRenderOptionsEntries],
  );

  const reset = useCallback(() => {
    metaForm.reset();
    payloadForm.reset();
    payloadForm.initialValue = null;
    metaForm.initialValue = null;
    if (payloadFormState.lastResult) {
      payloadFormState.lastResult.initialValue = null;
    }
    if (metaFormState.lastResult) {
      metaFormState.lastResult.initialValue = null;
    }
    metaFormState.submission = null;
    payloadFormState.submission = null;
  }, [metaForm, payloadForm, payloadFormState, metaFormState]);

  const loadState = (state: Record<string, any>) => {
    if (state == null) {
      reset();
      return;
    }

    const formData = new FormData();
    Object.entries(state).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const payloadSubmission = parseWithZod(formData, {
      schema: validationSchema,
    });

    const payloadSubmissionResult = payloadSubmission.reply({
      resetForm: true,
    });

    payloadFormState.set({
      submission: payloadSubmission,
      submissionResult: payloadSubmissionResult,
    });

    const metaSubmission = parseWithZod(formData, {
      schema: metaFormSchema,
    });

    const metaSubmissionResult = metaSubmission.reply({ resetForm: true });

    metaFormState.set({
      submission: metaSubmission,
      submissionResult: metaSubmissionResult,
    });
  };

  return {
    id: payloadForm.id,
    fields: fieldOptionsByName,
    fieldsRenderOptions: fieldsRenderOptionsEntries,
    submission: payloadFormState.submission,
    submissionResult: payloadFormState.lastResult,
    payloadForm,
    metaForm,
    payloadValue,
    metaValue,
    reset,
    loadState,
  };
}
