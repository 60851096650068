import { ChronoUnit, LocalDate } from '@js-joda/core';
import {
  ColDef,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';

import { PayrollInputsEntry, PayrollInputsPayloadTypes } from '@octopus/api';

import { InputCellEditor } from './InputCellEditor';
import { InputCellHeader } from './InputCellHeader';
import { InputCellValue } from './InputCellValue';
import { ColumnInfo } from './types';
import { cellValueEquals, parseHours } from './utils';

export type InputColDefProps<T> = {
  entry: PayrollInputsEntry;
  showColumnInfo: (ref: HTMLElement, info: ColumnInfo) => void;
  hideInfoPoppers: () => void;
  valueGetter: (params: ValueGetterParams<T, string>) => string;
  valueSetter: (params: ValueSetterParams<T, string>) => boolean;
  hasBeenEdited: (
    data: T,
    inputId: string,
    value: string | undefined,
  ) => boolean;
};

export function newInputColDef<T>(
  props: InputColDefProps<T>,
): ColDef<T, string> {
  const { entry, hideInfoPoppers, hasBeenEdited, valueGetter, valueSetter } =
    props;
  return {
    colId: entry.id,
    headerName: entry.label,
    headerComponentParams: prepareHeaderComponentParams(props),
    headerComponent: InputCellHeader,
    cellEditorParams: {
      payloadType: entry.payloadType,
    },
    cellEditor: InputCellEditor,
    cellRendererParams: {
      inputId: entry.id,
      payloadType: entry.payloadType,
      hideInfoPoppers,
      hasBeenEdited,
    },
    cellRenderer: InputCellValue,
    valueGetter,
    valueSetter,
    comparator: newInputValueComparator(entry.payloadType),
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    wrapHeaderText: true,
    useValueFormatterForExport: false,
    minWidth: 140,
    editable: true,
    equals: (a, b) => cellValueEquals(a, b, entry.payloadType),
    valueParser: ({ newValue }) => (!newValue ? null : newValue),
    cellStyle: {
      padding: '0',
    },
  };
}

function prepareHeaderComponentParams({
  entry,
  showColumnInfo,
}: {
  entry: PayrollInputsEntry;
  showColumnInfo: (ref: HTMLElement, info: ColumnInfo) => void;
}) {
  return {
    tag: entry.tag,
    showColumnInfo: (ref: HTMLElement) => {
      showColumnInfo(ref, {
        colId: entry.id,
        label: entry.label,
        tag: entry.tag,
        payloadType: entry.payloadType,
      });
    },
  };
}

function newInputValueComparator(payloadType: PayrollInputsPayloadTypes) {
  return (a: string | undefined, b: string | undefined) => {
    if (!a || !b) {
      if (!a && !b) {
        return 0;
      }
      return a ? 1 : -1;
    }
    switch (payloadType) {
      case 'currency':
      case 'percentage':
      case 'number':
        return parseFloat(a) - parseFloat(b);
      case 'hours':
        return parseHours(a) - parseHours(b);
      case 'days':
        return a.split(',').length - b.split(',').length;
      case 'dateRange':
        return parseDaysInDateRange(a) - parseDaysInDateRange(b);
      case 'date':
        return LocalDate.parse(a).compareTo(LocalDate.parse(b));
    }
  };
}

function parseDaysInDateRange(value: string) {
  const [start, end] = value.split('/');
  return LocalDate.parse(start).until(LocalDate.parse(end), ChronoUnit.DAYS);
}
