import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { Button } from '@octopus/ui/design-system';
export function RejectModal({
  open,
  isSubmitting,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  isSubmitting: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      data-testid="confirm-vacations-modal"
    >
      <DialogTitle mt={3}>
        <Typography fontWeight={700} fontSize="32px">
          Cancelar férias
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography fontWeight={500} fontSize="16px" color="allVariants">
            Ao cancelar este agendamento de férias seu pagamento não será criado
            e um aviso do cancelamento será enviado para o colaborador.
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="16px"
            color="error.lowEmphasis"
            sx={{ mt: 2 }}
          >
            Atenção, esta ação não pode ser revertida.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variantSemantic="secondary">
          Cancelar
        </Button>
        <Button
          isLoading={isSubmitting}
          type="submit"
          variantSemantic="error"
          onClick={onConfirm}
        >
          Recusar solicitação
        </Button>
      </DialogActions>
    </Dialog>
  );
}
