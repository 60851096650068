import Big from 'big.js';

import { PayrollEntry, useGetPayroll } from '@octopus/api';

import { FLAGS } from '../../flags';

export function useFlagsParsedPayroll({
  organizationId,
  companyId,
  payrollId,
}: {
  organizationId: string;
  companyId: string;
  payrollId: string;
}): ReturnType<typeof useGetPayroll> {
  const useGetPayrollResult = useGetPayroll({
    pathParams: {
      organizationId,
      companyId,
      payrollId,
    },
  });

  return {
    ...useGetPayrollResult,
    data: getFlagsParsedPayrollEntry(useGetPayrollResult.data),
  } as ReturnType<typeof useGetPayroll>;
}

export function getFlagsParsedPayrollEntry(
  payroll?: PayrollEntry,
): PayrollEntry {
  if (!payroll) return payroll;

  const isTotalCostProvisionsEnabled =
    FLAGS.ENABLE_PAYROLL_TOTAL_COST &&
    FLAGS.ENABLE_PAYROLL_TOTAL_COST_PROVISIONS;

  const payrollWithoutTotalCostProvisions = parseIf(
    !isTotalCostProvisionsEnabled,
    parsePayrollWithoutTotalCostProvisions,
    payroll,
  );

  return payrollWithoutTotalCostProvisions;
}

function parseIf<T>(
  shouldParse: boolean,
  parseFunction: (arg: T) => T,
  target: T,
) {
  if (!shouldParse || !target) return target;
  return parseFunction(target);
}

function parsePayrollWithoutTotalCostProvisions(
  payroll: PayrollEntry,
): PayrollEntry {
  if (
    !(payroll?.outputs && 'totalCost' in payroll.outputs) ||
    FLAGS.ENABLE_PAYROLL_TOTAL_COST_PROVISIONS
  ) {
    return payroll;
  }

  const newTotalCostElements = payroll.outputs.totalCost.elements.filter(
    (elementId) => !elementId.match(/provisoes/),
  );
  const newTotalCostTotal = newTotalCostElements
    .reduce((newTotal, elementId) => {
      const amount = payroll.outputs.elements[elementId]?.amount || '0';
      return newTotal.plus(amount);
    }, new Big('0'))
    .toString();

  const newTotalCost = {
    elements: newTotalCostElements,
    total: newTotalCostTotal,
  };

  const parsedOutputs = {
    ...payroll.outputs,
    totalCost: newTotalCost,
  };

  return {
    ...payroll,
    outputs: parsedOutputs,
  };
}
