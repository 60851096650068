import { MouseEvent, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';

import { startPasswordRecovery } from '../../../modules/login';

export function ForgotPassword() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    username: '',
  });

  const { mutate, isError, isLoading, error } = useMutation(
    async (e: MouseEvent) => {
      e.preventDefault();
      if (state.username === '') {
        throw new Error('O campo de email não pode estar vazio');
      }
      await startPasswordRecovery(state.username);
      navigate('sent', {
        state: {
          username: state.username,
        },
      });
    },
  );

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="h3"
          sx={{ fontWeight: '700' }}
          data-testid="forgot-password-title"
        >
          Esqueci a senha
        </Typography>
        <Typography variant="body2">
          Insira o seu email cadastrado para receber instruções de como
          redefinir a senha.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="caption" color="text.secondary" fontWeight="500">
          Email
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Seu email"
          error={isError}
          onChange={(e) =>
            setState((oldState) => ({
              ...oldState,
              username: e.target.value,
            }))
          }
          data-testid="username"
        />
      </Box>
      {isError && (
        <Alert severity="error">
          <Typography
            variant="caption"
            fontWeight="500"
            data-testid="login-error"
          >
            {(error as Error).message}
          </Typography>
        </Alert>
      )}
      <Box display="flex" flexDirection="column" gap={1.5}>
        <LoadingButton
          fullWidth
          loading={isLoading}
          onClick={mutate}
          variant="contained"
          data-testid="submit"
          sx={{ p: 1.5 }}
          color="primaryAlt"
        >
          <Typography variant="body1" color="secondary" fontWeight="700">
            Enviar
          </Typography>
        </LoadingButton>
        <Button
          fullWidth
          color="secondary"
          component={RouterLink}
          to="/login"
          data-testid="back"
          sx={{ p: 1.5 }}
        >
          <Typography variant="body1" color="text.secondary" fontWeight="700">
            Voltar para o login
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
