const FormErrorCodes: Record<string, string> = {
  form_invalid_cpf: 'CPF inválido',
  form_invalid_cnpj: 'CNPJ inválido',
  form_invalid_date: 'Data inválida',
  form_invalid_birth_date: 'Data de nascimento inválida, mínimo é 14 anos',
  form_invalid_empty_string: 'Valor inválido',
  form_invalid_dt_prev_term_string_with_value: 'Data prevista de término deve ser maior que a data de admissão ({{admissionDate}})',
};

export default FormErrorCodes;
