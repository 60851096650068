import { z } from 'zod';

import { nonEmptyString } from '@octopus/libs/forms';

export const cargoConfiancaSchema = z
  .object({
    enabled: z.literal('true').optional(),
    disabled: z.literal('true').optional(),
    CBOFuncao: nonEmptyString.optional(),
    nmFuncao: nonEmptyString.optional(),
  })
  .superRefine((formstate, ctx) => {
    const { disabled, enabled, CBOFuncao, nmFuncao } = formstate || {};

    if (disabled) {
      return;
    }

    if (enabled) {
      if (CBOFuncao == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Obrigatório',
          path: ['CBOFuncao'],
        });
      }

      if (nmFuncao == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Obrigatório',
          path: ['nmFuncao'],
        });
      }
    }

    return;
  });
