import { LocalDate } from '@js-joda/core';
import { z } from 'zod';

export const validAdmissionDateSchema = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}$/)
  .refine(
    (val: string) => {
      try {
        const date = LocalDate.parse(val);
        const now = LocalDate.now();

        const minimumAdmissionDate = now.minusYears(3);
        const maximumAdmissionDate = now.plusMonths(11);
        return (
          date.isAfter(minimumAdmissionDate) &&
          date.isBefore(maximumAdmissionDate)
        );
      } catch (_) {
        return false;
      }
    },
    {
      params: {
        i18n: { key: 'form_invalid_date' },
      },
    },
  );
