import { IFieldAnyDefinition } from './formFieldDefinitions';
import { NEW_UI_TYPE } from './UI_TYPE';
import { ILegacySafeUiType, uiTypeByLegacyUiType } from './uiType';

export function parseFieldUiInfo(
  arg: IFieldAnyDefinition,
): IParsedUiTypeProps | undefined {
  const legacySafeUiType = arg.uiType
    ? uiTypeByLegacyUiType[arg.uiType]
    : undefined;
  return legacySafeUiType;
}

export type IParsedUiTypeProps = ILegacySafeUiType | typeof NEW_UI_TYPE;
