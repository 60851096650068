import {
  AssociationMemberEntry,
  CostCenterSummary,
  JobTitleSummary,
  LegalEntitySummary,
  fetchGetAllJobTitles,
  fetchGetLegalEntities,
  fetchListCompanyMemberAssociations,
  fetchSearchAllCostCenters,
} from '@octopus/api';
import { formatCNPJ } from '@octopus/formatters';

import {
  BaseContext,
  CompanyContext,
  CostCenterContext,
  SindicatoContext,
} from '../types';

type paginatedResult<T> = {
  data?: T[];
  page: number;
  size: number;
  total: number;
};

type CompanyFetcher<T> = {
  type: 'GET' | 'POST';
  fetch: (params: any) => Promise<paginatedResult<T> | T[]>;
  hasPagination: boolean;
  pathParams: {
    organizationId: string;
    companyId?: string;
  };
  parseItem: (item: T) => BaseContext<T>;
};

const fetchCompanyContext = async ({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}): Promise<CompanyContext> => {
  const legalEntities: CompanyFetcher<LegalEntitySummary> = {
    fetch: fetchGetLegalEntities,
    hasPagination: true,
    type: 'GET',
    pathParams: {
      organizationId,
      companyId,
    },
    parseItem: (item: LegalEntitySummary) => ({
      id: item.legalEntityId,
      name: item.br.nomeFantasia,
      summary: item,
    }),
  };

  const jobTitles: CompanyFetcher<JobTitleSummary> = {
    fetch: fetchGetAllJobTitles,
    hasPagination: true,
    type: 'GET',
    pathParams: {
      organizationId,
    },
    parseItem: (item: JobTitleSummary) => ({
      id: item.jobTitleId,
      name: `${item.name} - ${item.occupationCode ?? ''}`,
      summary: item,
    }),
  };

  const costCenters: CompanyFetcher<CostCenterSummary> = {
    fetch: fetchSearchAllCostCenters,
    hasPagination: true,
    type: 'POST',
    pathParams: {
      organizationId,
    },
    parseItem: (item: CostCenterSummary): CostCenterContext => ({
      id: item.costCenterId,
      name: `${item.name} - ${item.code ?? ''}`,
      summary: item,
    }),
  };

  const sindicatos: CompanyFetcher<AssociationMemberEntry> = {
    fetch: fetchListCompanyMemberAssociations,
    hasPagination: false,
    type: 'GET',
    pathParams: {
      organizationId,
      companyId,
    },
    parseItem: (item: AssociationMemberEntry): SindicatoContext => ({
      id: formatCNPJ(item.associationCnpj),
      name: `${item.associationName} - ${formatCNPJ(item.associationCnpj)}`,
      summary: item,
    }),
  };

  const companyFetchers: {
    [key in keyof CompanyContext]: CompanyFetcher<any>;
  } = {
    legalEntities,
    jobTitles,
    costCenters,
    sindicatos,
  };

  const companyContext: CompanyContext = {};
  await Promise.all(
    Object.entries(companyFetchers).map(([key, fetcher]) => {
      return fetchAllPages({ fetcher }).then((result) => {
        const paginatedResult = 'data' in result && result.data;
        if (paginatedResult) {
          const response = paginatedResult;
          if (response) {
            const items = response
              .map(fetcher.parseItem)
              .sort((a, b) =>
                a.name
                  .toLocaleLowerCase()
                  .localeCompare(b.name.toLocaleLowerCase()),
              );
            companyContext[key as keyof CompanyContext] = items;
          }
        } else {
          const response = result as any[];
          if (response) {
            const items = response
              .map(fetcher.parseItem)
              .sort((a, b) =>
                a.name
                  .toLocaleLowerCase()
                  .localeCompare(b.name.toLocaleLowerCase()),
              );
            companyContext[key as keyof CompanyContext] = items;
          }
        }
      });
    }),
  );

  return companyContext;
};

async function fetchAllPages<T>({
  size = 100,
  fetcher,
}: {
  size?: number;
  fetcher: CompanyFetcher<T>;
}) {
  const { fetch, pathParams } = fetcher;

  const requestParams = (page: number, type: string) => ({
    pathParams,
    ...(fetcher.hasPagination &&
      type === 'GET' && {
        queryParams: { page: page.toString(), size: size.toString() },
      }),
    ...(fetcher.hasPagination &&
      type === 'POST' && { body: { pagination: { page, size } } }),
  });

  const initialState = {
    page: 0,
    data: [] as T[],
    size: 0,
    total: 0,
  };

  const fetchUntilEnd = async (
    page = 0,
    acc = initialState,
  ): Promise<paginatedResult<T> | T[]> => {
    const params = requestParams(page, fetcher.type);
    const response = await fetch({
      ...params,
    });

    const paginatedResponse = response as paginatedResult<T>;
    const isArrayReponse = response instanceof Array;
    const newData = isArrayReponse ? response : paginatedResponse.data;

    if (!newData || newData.length === 0) {
      return acc;
    }

    const allVisited = acc.size + (isArrayReponse ? newData.length : size);
    const nextResult = {
      ...acc,
      data: acc.data.concat(newData),
      size: allVisited,
    };

    const hasNextPage = paginatedResponse.total > allVisited;
    if (!hasNextPage) {
      return nextResult;
    }

    return fetchUntilEnd(page + 1, nextResult);
  };

  return fetchUntilEnd(0, initialState);
}

export { fetchCompanyContext };
