import { TypographyProps } from '@mui/material';

import { ContractEntry, ContractStatus } from '@octopus/api';
import {
  ContractTypes,
  contractStatuses,
  contractTypes,
} from '@octopus/contract-types';
import { Tag } from '@octopus/ui/design-system';

export type StatusBadgeProps = {
  contractType: ContractTypes;
  status: ContractStatus | undefined;
  TypographyProps?: TypographyProps;
};

export function StatusBadge({ contractType, status }: StatusBadgeProps) {
  if (!status) {
    return null;
  }
  const { color, label } = stylizeStatus(status, contractType);
  return <Tag color={color}>{label}</Tag>;
}

export type ContractTypeProps = {
  contract: ContractEntry;
  TypographyProps?: TypographyProps;
};

function stylizeStatus(
  status: ContractStatus,
  contractType: ContractTypes,
): {
  color: 'default' | 'info' | 'success' | 'warning' | 'error' | 'secondary';
  label: string;
} {
  if (status === contractStatuses.onLeave) {
    return {
      color: 'warning',
      label: 'Afastado(a)',
    };
  }
  if (status === contractStatuses.onAdmission) {
    return {
      color: 'warning',
      label: 'Em admissão',
    };
  }
  if (status === contractStatuses.terminated) {
    if (contractType === contractTypes.brPj) {
      return {
        color: 'default',
        label: 'Inativo(a)',
      };
    }

    return {
      color: 'error',
      label: 'Desligado(a)',
    };
  }

  if (status === contractStatuses.active) {
    return {
      color: 'success',
      label: 'Ativo(a)',
    };
  }

  return {
    color: 'error',
    label: 'Não encontrado: ' + status,
  };
}
