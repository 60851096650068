import {
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
} from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';

import { CompanyContext } from '../../../../../modules/types';
import { documentReviewSections } from '../../documents/types';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepDefinition,
} from '../types';

import { cltEstagiario } from './cltEstagiario';
import { cltGeral } from './cltGeral';
import * as common from './common';
import { IFormSteps } from './types';

export const FORM_STEP_INPUT_BY_WORKER_CATEGORY: Partial<
  Record<WorkerCategory, IFormSteps>
> = {
  'clt:geral': cltGeral,
  'clt:estagiario': cltEstagiario,
};

const getFormStepInputs = (workerCategory?: WorkerCategory) => {
  const categ = workerCategory
    ? (workerCategory as WorkerCategory)
    : 'clt:geral';

  const formStepInput = FORM_STEP_INPUT_BY_WORKER_CATEGORY[categ];
  if (!formStepInput) {
    throw new Error(
      `FORM_STEPS_BY_WORKER_CATEGORY not implemented for workerCategory = ${workerCategory}`,
    );
  }
  return formStepInput;
};

export const getAdminSteps = ({
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
  probationPeriodEnabled,
  estagiarioEnabled,
  pjEnabled,
}: {
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
  probationPeriodEnabled: boolean;
  estagiarioEnabled: boolean;
  pjEnabled: boolean;
}): AdmissionFormSteps => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities
  ) {
    return {};
  }

  const formStepInput = getFormStepInputs(formState.workerCategory);

  return formStepInput.getAdminSteps({
    draftStatus,
    formState,
    companyContext,
    formSteps,
    isWorkerExperience,
    probationPeriodEnabled,
    estagiarioEnabled,
    pjEnabled,
  });
};

export const getWorkerSteps = ({
  draftStatus,
  formState,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): AdmissionFormSteps => {
  const finalizaAdmissao = getSummaryStep({
    draftStatus,
    formState,
    stepName: 'finaliza_admissao',
    formSteps,
    isWorkerExperience,
  });

  return common.getWorkerSteps({
    formState,
    formSteps,
    finalizaAdmissao,
  });
};

export const getDocumentSteps = ({
  draftStatus,
  formState,
  fieldStepsState,
  companyContext,
  probationPeriodEnabled,
  estagiarioEnabled,
  pjEnabled,
}: {
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  fieldStepsState: AdmissionDraftInputFormInfo;
  companyContext: CompanyContext;
  probationPeriodEnabled: boolean;
  estagiarioEnabled: boolean;
  pjEnabled: boolean;
}): AdmissionFormSteps => {
  const adminSteps = getAdminSteps({
    draftStatus,
    formState,
    formSteps: fieldStepsState?.formSections?.['payroll_data'] ?? {},
    companyContext,
    isWorkerExperience: false,
    probationPeriodEnabled,
    estagiarioEnabled,
    pjEnabled,
  });

  const finalizaAdmissao = getSummaryStep({
    draftStatus,
    formState,
    stepName: 'finaliza_admissao',
    formSteps: fieldStepsState?.formSections?.['personal_data'] ?? {},
    isWorkerExperience: false,
  });

  const workerSteps = common.getWorkerSteps({
    formState,
    formSteps: fieldStepsState?.formSections?.['personal_data'] ?? {},
    finalizaAdmissao,
  });

  const documentSteps = Object.keys(documentReviewSections)
    .filter((key) => key !== 'dependentes')
    .reduce((acc, key) => {
      const enumKey = key as AdmissionDraftInputFormStepEnum;
      const adminStep = adminSteps[enumKey];
      const workerStep = workerSteps[enumKey];
      return { ...acc, [key]: adminStep || workerStep };
    }, {});

  return documentSteps;
};

export const getReviewDependentStep = ({
  fieldStepsState,
  dependentId,
}: {
  fieldStepsState: AdmissionDraftInputFormInfo;
  dependentId: string;
}): FormStepDefinition => {
  return common.getReviewDependentStep({ fieldStepsState, dependentId });
};

export const getSummaryStep = ({
  draftStatus,
  formState,
  stepName,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  stepName: string;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): FormStepDefinition => {
  const { admissionType } = formState;
  const formStepInput = getFormStepInputs(formState.workerCategory);

  return formStepInput.getSummaryStep({
    draftStatus,
    admissionType,
    stepName,
    formSteps,
    isWorkerExperience,
  });
};
