import React from 'react';

import { LoadingButton } from '@mui/lab';

import { getAuthToken } from '@octopus/api';

import { download } from '../../spreadsheets/downloader';

type ContractsDownloadProps = {
  organizationId: string;
  setError: (error: boolean) => void;
};

export function ActiveContractsDownload({
  organizationId,
  setError,
}: ContractsDownloadProps) {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    setError(false);

    download(getAuthToken()!, organizationId, 'contracts')
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} variant="contained">
      <span>Contratos - Ativos</span>
    </LoadingButton>
  );
}

export function AllContractsDownload({
  organizationId,
  setError,
}: ContractsDownloadProps) {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    setError(false);

    download(getAuthToken()!, organizationId, 'contracts', {
      includeTerminated: 'true',
    })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} variant="contained">
      <span>Contratos - Todos</span>
    </LoadingButton>
  );
}

export function PJContractsDownload({
  organizationId,
  setError,
}: ContractsDownloadProps) {
  //
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    setError(false);

    download(getAuthToken()!, organizationId, 'pjContracts')
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} variant="contained">
      <span>Contratos PJ</span>
    </LoadingButton>
  );
}
