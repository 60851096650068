import { ContractBRCltEntrySucessao } from '@octopus/api';
import { formatCNPJ, formatCPF, formatDateBR } from '@octopus/formatters';

import { Record, RecordEntry } from '../../../Record';

export type SucessaoDeVinculoTrabalhistaRecordProps = {
  sucessao: ContractBRCltEntrySucessao;
};

export function SucessaoDeVinculoTrabalhistaRecord({
  sucessao,
}: SucessaoDeVinculoTrabalhistaRecordProps) {
  if (!sucessao) {
    return null;
  }
  return (
    <Record title="Sucessão de vínculo trabalhista">
      <RecordEntry label="Empregador anterior">
        {sucessao.tpInsc === 1
          ? formatCNPJ(sucessao.nrInsc)
          : sucessao.tpInsc === 2
            ? formatCPF(sucessao.nrInsc)
            : sucessao.nrInsc}
      </RecordEntry>
      <RecordEntry label="Matrícula anterior">{sucessao.matricAnt}</RecordEntry>
      <RecordEntry label="Data de transferência">
        {formatDateBR(sucessao.dtTransf)}
      </RecordEntry>
    </Record>
  );
}
