import React from 'react';

import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { useMaskito } from '@maskito/react';

import { SxProps, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldTextNumericProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
  sx?: SxProps<Theme>;
};
export function FieldTextNumeric(props: FieldTextNumericProps) {
  const { field } = props;

  const options = maskitoNumberOptionsGenerator({
    decimalZeroPadding: false,
    precision: 0,
    min: 0,
    thousandSeparator: '',
  });

  const maskito = useMaskito({
    options,
  });

  const hasError = field.errors?.length > 0;

  return (
    <FieldWrapper field={field} sx={field.props.sx}>
      <TextField
        fullWidth
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        variant="outlined"
        inputProps={{
          ...field.input.props,
          inputMode: 'numeric',
        }}
        autoComplete="off"
        ref={maskito}
        error={hasError}
      />
    </FieldWrapper>
  );
}

FieldTextNumeric.uiType = UI_TYPE.TEXT_NUMBER;
FieldTextNumeric.canRender = (
  field: TFieldRenderProps,
): field is FieldTextNumericProps['field'] => {
  return (
    'input' in field &&
    field.input.props.type !== 'checkbox' &&
    field.input.props.type !== 'radio'
  );
};
