import { PayrollRecord } from '../../core';
import { CalculateProvisionsInput } from '../../provisions';

import { recordTypes } from './constants';

const encargosPatronais = <Literal extends string>(str: Literal) =>
  ({
    inssPatronal: `${str}/inssPatronal`,
    inssRat: `${str}/inssRat`,
    inssOutrasEntidades: `${str}/inssOutrasEntidades`,
    fgts: `${str}/fgts`,
  }) as const;

const tiposDeProvisoesPadroes = {
  decimoTerceiro: {
    decimoTerceiro: 'decimoTerceiro/decimoTerceiro',
    ...encargosPatronais('decimoTerceiro' as const),
  },

  ferias: {
    ferias: 'ferias/ferias',
    ...encargosPatronais('ferias' as const),
  },
} as const;

type ValueOf<T> = T[keyof T];
type TiposDeProvisoes = {
  [P in keyof typeof tiposDeProvisoesPadroes]: ValueOf<
    (typeof tiposDeProvisoesPadroes)[P]
  >;
}[keyof typeof tiposDeProvisoesPadroes];

type ProvisoesRecord = PayrollRecord & {
  type: typeof recordTypes.provisoes;
  payload: {
    [key in TiposDeProvisoes]: {
      state: CalculateProvisionsInput['state'];
      oldCalculationInputs: CalculateProvisionsInput['calculationInputs']['prior'];
    };
  };
};

export { tiposDeProvisoesPadroes };
export type { ProvisoesRecord, TiposDeProvisoes };
