import { Theme } from '@mui/material/styles';

function hashCode(str: string | undefined) {
  if (!str || str.length === 0) {
    return 0;
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return Math.abs(hash);
}

export function getAvatarColor(str: string | undefined, theme: Theme) {
  const colors = theme.palette.random;
  return colors[hashCode(str) % colors.length];
}
