import { ErrorOutlineOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

type Props = { hasError: boolean };
export function ErrorWarningIcon({ hasError }: Props) {
  if (!hasError) return null;
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="há um erro de preenchimento neste campo"
        edge="end"
      >
        <ErrorOutlineOutlined color="error" />
      </IconButton>
    </InputAdornment>
  );
}
