import Big from 'big.js';

export function formatPercentage(
  num: Big | number | string | undefined,
): string {
  if (!num) {
    num = Big(0);
  }
  if (typeof num === 'string') {
    num = Big(num);
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(+num);
}

export function formatInteger(
  num: Big | string | number | undefined,
  options?: Omit<Intl.NumberFormatOptions, 'style' | 'maximumFractionDigits'>,
): string {
  if (!num) {
    num = Big(0);
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: 0,
    ...options,
  });

  return formatter.format(+num);
}

export function formatDecimal(
  num: string | number | undefined,
  options?: Omit<Intl.NumberFormatOptions, 'style'>,
): string {
  if (!num) {
    num = '0';
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    ...options,
  });

  return formatter.format(+num);
}
