import ApartmentOutlined from '@mui/icons-material/ApartmentOutlined';
import { Box, Container, Typography } from '@mui/material';

export default function Organizations() {
  return (
    <Box
      pt={9}
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="organizations-header"
        >
          <ApartmentOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Organização</Typography>
        </Box>
      </Container>
    </Box>
  );
}
