import { LegalEntitySummary, fetchGetLegalEntities } from '@octopus/api';

type loadLegalEntitiesFn = (
  organizationId: string,
  companyId: string,
) => Promise<LegalEntitySummary[]>;

const cachedLegalEntities: Record<string, LegalEntitySummary[]> = {};

export function loadLegalEntitiesInstance(): loadLegalEntitiesFn {
  return async (organizationId, companyId) => {
    if (!organizationId || !companyId) {
      return [];
    }

    if (cachedLegalEntities[`${organizationId}/${companyId}`]) {
      return cachedLegalEntities[`${organizationId}/${companyId}`];
    }

    const response = await fetchGetLegalEntities({
      pathParams: {
        organizationId,
        companyId,
      },
    });

    cachedLegalEntities[`${organizationId}/${companyId}`] = response.data;

    return response.data;
  };
}
