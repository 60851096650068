import { RefObject, useMemo, useState } from 'react';

import { IconIdBadge2, IconUsersGroup } from '@tabler/icons-react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Tooltip, Typography } from '@mui/material';

import {
  ContractEntry,
  CostCenterEntry,
  JobTitleEntry,
  LegalEntityEntry,
  useGetCostCenterEntry,
  useGetJobTitleEntry,
  useGetLegalEntity,
} from '@octopus/api';
import {
  contractTypes,
  getAdmissionDate,
  getContractJobTitleId,
  getCorporateEmail,
  getCostCenterId,
  getDepartment,
  getWorkerId,
} from '@octopus/contract-types';
import { getWorkerCategory } from '@octopus/esocial/contracts';
import { formatDateBR } from '@octopus/formatters';

import WorkerCategoryTag from '../../../modules/components/contracts/WorkerCategoryTag';
import { PersonInfoCard } from '../../../modules/components/people/PersonInfoCard';
import {
  SidePanel,
  SidePanelMenu,
  SidePanelMenuItem,
  SidePanelMenuSubItem,
} from '../../../modules/components/people/SidePanel';
import { StatusBadge } from '../../../modules/people/person/components/StatusBadge';
import { QueryResult } from '../../../modules/types';
import { useFFlags } from '../../fflags';

import { PageContent, pageContents } from './types';

export type PersonSidePanelProps = {
  refs: {
    pageRef: RefObject<Element>;
    personalDetailsRef: RefObject<Element>;
    contractDetailsRef: RefObject<Element>;
    paymentDetailsRef: RefObject<Element>;
  };
  pageContent: PageContent;
  setPageContent: (pageContent: PageContent) => void;
  contractQuery: QueryResult<ContractEntry>;
};

export function PersonSidePanel({
  refs,
  contractQuery,
  pageContent,
  setPageContent,
}: PersonSidePanelProps) {
  const name = useMemo(() => {
    return (
      contractQuery.data?.br?.pessoa.nmSoc ??
      contractQuery.data?.br?.pessoa.nmTrab ??
      ''
    );
  }, [contractQuery.data]);
  const { FFlags } = useFFlags();

  const contract = contractQuery.data;
  const { contractType, br } = contract ?? ({} as ContractEntry);
  const trabalho = br != null && 'trabalho' in br ? br.trabalho : undefined;

  const scrollToRef = (ref: RefObject<Element>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const { pageRef, personalDetailsRef, contractDetailsRef, paymentDetailsRef } =
    refs;
  const workerCategory: string = getWorkerCategory(contractType, trabalho);

  return (
    <Box data-testid="person-side-panel">
      <SidePanel
        isLoading={contractQuery.isLoading}
        name={name}
        InfoCard={
          <PersonInfoCard name={name}>
            <CardContent contractQuery={contractQuery} />
          </PersonInfoCard>
        }
        Menu={
          <SidePanelMenu>
            <SidePanelMenuItem
              Icon={IconIdBadge2}
              title={
                contractType === contractTypes.brClt
                  ? 'Dados do colaborador(a)'
                  : 'Dados do prestador(a)'
              }
              selected={pageContent === pageContents.details}
              onClick={() => {
                setPageContent(pageContents.details);
                scrollToRef(pageRef);
              }}
              testId="person-page-button"
            >
              <SidePanelMenuSubItem
                title="Dados pessoais"
                onClick={() => scrollToRef(personalDetailsRef)}
                testId="person-data-button"
              />
              <SidePanelMenuSubItem
                title="Dados contratuais"
                onClick={() => scrollToRef(contractDetailsRef)}
                testId="contract-data-button"
              />
              <SidePanelMenuSubItem
                title={
                  contractType === contractTypes.brClt
                    ? 'Remuneração'
                    : 'Pagamento'
                }
                onClick={() => scrollToRef(paymentDetailsRef)}
                testId="payment-data-button"
              />
            </SidePanelMenuItem>
            {workerCategory !== 'clt:estagiario' && (
              <FFlags canAccessOrgStructure>
                <SidePanelMenuItem
                  Icon={IconUsersGroup}
                  title="Liderados"
                  selected={pageContent === pageContents.reports}
                  onClick={() => {
                    setPageContent(pageContents.reports);
                    scrollToRef(pageRef);
                  }}
                  testId="reports-page-button"
                />
              </FFlags>
            )}
          </SidePanelMenu>
        }
      />
    </Box>
  );
}

function CardContent({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const [showHighlights, setShowHighlights] = useState(false);
  const { data: contractInfo } = contractQuery;

  const jobTitleId = getContractJobTitleId(contractInfo);
  const jobTitleQuery = useGetJobTitleEntry(
    {
      pathParams: {
        organizationId: contractInfo?.organizationId ?? '',
        jobTitleId: jobTitleId ?? '',
      },
    },
    {
      enabled: !!contractInfo?.organizationId && !!jobTitleId,
    },
  );

  const costCenterId = getCostCenterId(contractInfo);
  const costCenterQuery = useGetCostCenterEntry(
    {
      pathParams: {
        organizationId: contractInfo?.organizationId ?? '',
        costCenterId: costCenterId ?? '',
      },
    },
    {
      enabled: !!contractInfo?.organizationId && !!costCenterId,
    },
  );

  const legalEntityQuery = useGetLegalEntity(
    {
      pathParams: {
        organizationId: contractInfo?.organizationId ?? '',
        companyId: contractInfo?.companyId ?? '',
        legalEntityId: contractInfo?.legalEntityId ?? '',
      },
    },
    {
      enabled:
        !!contractInfo?.organizationId &&
        !!contractInfo?.companyId &&
        !!contractInfo?.legalEntityId,
    },
  );

  if (!contractInfo) {
    return null;
  }

  const { status } = contractInfo;
  const highlights = prepareHighlights(
    showHighlights,
    contractQuery,
    jobTitleQuery,
    costCenterQuery,
    legalEntityQuery,
  );

  const trabalho =
    'trabalho' in contractInfo.br ? contractInfo.br.trabalho : null;

  const workerCategory = getWorkerCategory(contractInfo.contractType, trabalho);

  const Icon = showHighlights ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        position="absolute"
        right="40px"
        sx={{ cursor: 'pointer' }}
        onClick={() => setShowHighlights((value) => !value)}
      >
        <Tooltip
          title="Mais informações"
          placement="top"
          enterDelay={500}
          enterNextDelay={500}
        >
          <Icon
            sx={{
              height: '16px',
              width: '16px',
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
              },
            }}
          />
        </Tooltip>
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        {highlights.map(({ caption, highlight }, i) => (
          <Tooltip
            title={caption}
            placement="top"
            enterDelay={500}
            enterNextDelay={500}
          >
            <Typography
              key={i}
              variant="caption"
              color="text.secondary"
              fontWeight="500"
              sx={{
                cursor: 'default',
                '&:hover': {
                  color: 'text.primary',
                },
              }}
            >
              {highlight}
            </Typography>
          </Tooltip>
        ))}
      </Box>

      <Box display={'flex'} gap={0.5}>
        <Box pt={1}>
          <StatusBadge
            status={status}
            contractType={contractInfo.contractType}
            TypographyProps={{ variant: 'caption' }}
          />
        </Box>

        <Box pt={1}>
          <WorkerCategoryTag workerCategory={workerCategory} />
        </Box>
      </Box>
    </Box>
  );
}

function prepareHighlights(
  showHighlights: boolean,
  contractQuery: QueryResult<ContractEntry>,
  jobTitleQuery: QueryResult<JobTitleEntry>,
  costCenterQuery: QueryResult<CostCenterEntry>,
  legalEntityQuery: QueryResult<LegalEntityEntry>,
): {
  highlight: string;
  caption: string;
}[] {
  const highlights = [];

  const workerId = getWorkerId(contractQuery.data);
  const workerIdHighlight = `${
    contractQuery.data.contractType === 'br:clt' ? 'Matrícula' : 'Identificador'
  } #${workerId}`;

  if (workerId) {
    highlights.push({
      highlight: workerIdHighlight,
      caption:
        contractQuery.data.contractType === 'br:clt'
          ? 'Matrícula'
          : 'Identificador',
    });
  }

  if (!jobTitleQuery.isLoading) {
    highlights.push({
      highlight: jobTitleQuery.data?.name,
      caption: 'Cargo',
    });
  }

  const department = getDepartment(contractQuery.data);
  if (department) {
    highlights.push({
      highlight: department,
      caption: 'Departamento',
    });
  }

  if (!costCenterQuery.isLoading) {
    highlights.push({
      highlight: costCenterQuery.data?.name,
      caption: 'Centro de Custo',
    });
  }

  const admissionHighlight = `${
    contractQuery.data.contractType === 'br:clt' ? 'Admissão' : 'Início'
  } em ${formatDateBR(getAdmissionDate(contractQuery.data))}`;
  highlights.push({
    highlight: admissionHighlight,
    caption:
      contractQuery.data.contractType === 'br:clt' ? 'Admissão' : 'Início',
  });

  if (!legalEntityQuery.isLoading) {
    highlights.push({
      highlight: legalEntityQuery.data?.br.nomeFantasia,
      caption:
        contractQuery.data.contractType === 'br:clt'
          ? 'Empregador'
          : 'Contratante',
    });
  }

  const corpEmail = getCorporateEmail(contractQuery.data);
  if (corpEmail) {
    highlights.push({
      highlight: corpEmail,
      caption: 'E-mail Corporativo',
    });
  }

  return showHighlights ? highlights : highlights.slice(0, 3);
}
