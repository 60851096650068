import React from 'react';

import { useMaskito } from '@maskito/react';

import { OutlinedInput } from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

import { ErrorWarningIcon } from './commons/ErrorWarningIcon';
import { FieldWrapper } from './commons/FieldWrapper';
import { MaskitoOptionsBR } from './MaskitoOptions';

type FieldTextProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
};

export function FieldText(props: FieldTextProps) {
  const { field } = props;

  const hasError = field.errors?.length > 0;

  const mask = field.props.mask as keyof typeof MaskitoOptionsBR;
  const options = mask ? MaskitoOptionsBR[mask] : null;

  const maskito = useMaskito({
    options,
  });

  return (
    <FieldWrapper field={field} sx={field.props.sx}>
      <OutlinedInput
        fullWidth
        onAnimationStart={null}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        inputProps={field.input.props}
        disabled={field.input.props.disabled}
        autoComplete="off"
        error={hasError}
        endAdornment={<ErrorWarningIcon hasError={hasError} />}
        ref={maskito}
      />
    </FieldWrapper>
  );
}

FieldText.uiType = UI_TYPE.TEXT;
FieldText.canRender = (
  field: TFieldRenderProps,
): field is FieldTextProps['field'] => {
  return (
    'input' in field &&
    field.input.props.type !== 'checkbox' &&
    field.input.props.type !== 'radio'
  );
};
