import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Big } from 'big.js';

import { ReceiptLongOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Container,
  Divider,
  Drawer,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  ContractEntry,
  PayrollElement,
  PayrollPayslipEntry,
  PayrollTypes,
  useGetCompanyEntry,
  useGetContract,
  useGetPayrollPayslip,
} from '@octopus/api';
import { getWorkerId, isBRCltContract } from '@octopus/contract-types';
import {
  capitalize,
  formatCNPJ,
  formatDateBR,
  formatMoney,
  formatPercentage,
  formatPeriodDate,
} from '@octopus/formatters';
import { payrollTypes } from '@octopus/payroll-types';

import { BackButton } from '../../../../../../modules/components/BackButton';
import { CalculationExplanation } from '../../../../../../modules/components/payrolls/CalculationExplanation';
import { ElementsTable } from '../../../../../../modules/components/payrolls/ElementsTable';
import { PayslipActionsMenu } from '../../../../../../modules/components/payslips/ActionsMenu';
import { DataFetching } from '../../../../../../modules/dataFetching';
import { parsePixKey } from '../../../../../../modules/payrolls/parser';

function getPayslipTypeLabel(type: PayrollTypes): string {
  switch (type) {
    case 'monthly':
      return 'Holerite mensal';
    case 'advance':
      return 'Recibo de adiantamento';
    case 'thirteenthFirst':
      return 'Recibo de 13º salário - 1ª parcela';
    case 'thirteenthSecond':
      return 'Recibo de 13º salário - 2ª parcela';
    default:
      return 'Recibo de pagamento complementar';
  }
}

function Status({ status }: { status: string }) {
  const statusMap: Record<string, Record<string, string>> = {
    approved: {
      label: 'Não enviado',
      color: '#93500B',
    },
    sent: {
      label: 'Enviado',
      color: '#0058DB',
    },
    archived: {
      label: 'Arquivado',
      color: '#616161',
    },
  };

  const { label, color } = statusMap[status] || statusMap['archived'];
  return (
    <Typography variant="body2" color={color}>
      <Typography fontSize="150%" display="inline" color={color}>
        •
      </Typography>
      {label}
    </Typography>
  );
}

function ElementsTables({ payslip }: { payslip: PayrollPayslipEntry }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [elementStack, setElementStack] = useState<PayrollElement[]>([]);

  const onClickElement = (element: PayrollElement) => {
    if (element.id === elementStack[elementStack.length - 1]?.id) {
      setElementStack([]);
      return;
    }
    setElementStack((state) => [
      ...state,
      payslip.outputs?.elements[element.id],
    ]);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Box
        borderRadius={1.5}
        borderColor="strokes.light"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          mx: 0.5,
        }}
      >
        <Box px={2}>
          <Typography
            variant="h3"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Proventos
          </Typography>
          <ElementsTable
            payroll={payslip}
            summary="workerEarningsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />

          <Typography
            variant="h3"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Deduções
          </Typography>

          <ElementsTable
            payroll={payslip}
            summary="workerDeductionsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />
        </Box>

        <Box px={4} pt={3}>
          <Divider />
        </Box>

        <Box px={4} py={3} display="flex" justifyContent="space-between">
          <table
            width="100%"
            style={{
              tableLayout: 'fixed',
              borderCollapse: 'collapse',
            }}
          >
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Typography variant="h4" color="text.primary">
                    Valor líquido total
                  </Typography>
                </td>
                <td colSpan={1}>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    textAlign="right"
                  >
                    {formatMoney(payslip.outputs?.netPay?.total)}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>

      <Drawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        elevation={2}
      >
        <Box
          width="520px"
          height="100%"
          sx={{
            opacity: elementStack.length > 0 ? 1 : 0,
            transition: 'all 0.2s',
            backgroundColor: 'background.default',
            overflowY: 'overlay',
          }}
        >
          <CalculationExplanation
            element={elementStack[elementStack.length - 1]}
            onClickElement={onClickElement}
            canGoBack={elementStack.length > 1}
            goBack={() => setElementStack((state) => state.slice(0, -1))}
            onExit={() => {
              setElementStack([]);
              setIsDrawerOpen(false);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

function PayslipWorkerInformation({
  payslip,
}: {
  payslip: PayrollPayslipEntry;
}) {
  const useFetch = () => {
    return useGetContract({
      pathParams: {
        organizationId: payslip.organizationId,
        contractId: payslip.contractId,
      },
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ReceiptLongOutlined fontSize="inherit" /> &nbsp;
            {getPayslipTypeLabel(payslip.type)}
            <Typography variant="caption" color="strokes.heavy" sx={{ mx: 1 }}>
              •
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontWeight: 700 }}
            >
              {formatPeriodDate(payslip.period)}
            </Typography>
          </Typography>

          <Typography variant="h1" color="text.primary">
            {payslip.workerData.name}
          </Typography>
        </Box>

        <Box display="flex">
          <Status status={payslip.status}></Status>
          <Box mr={1}></Box>
          <PayslipActionsMenu payslip={payslip} />
        </Box>
      </Box>

      <DataFetching
        useHook={useFetch}
        Loading={() => (
          <>
            <Typography variant="caption" color="text.secondary">
              <Skeleton width="100%" />
            </Typography>
            <Typography variant="caption" color="text.secondary">
              <Skeleton width="100%" />
            </Typography>
          </>
        )}
        Data={({ data }) => (
          <CltContractInfo payslip={payslip} contract={data} />
        )}
      />
    </Box>
  );
}

function CltContractInfo({
  payslip,
  contract,
}: {
  payslip: PayrollPayslipEntry;
  contract: ContractEntry | undefined;
}) {
  if (!isBRCltContract(contract)) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="caption" component="p" color="text.secondary">
          {capitalize(payslip.workerData.jobTitle)} |{' '}
          {contract?.br?.trabalho?.CBOFuncao
            ? `CBO: ${contract?.br?.trabalho?.CBOFuncao} | `
            : ''}
          {formatMoney(payslip.workerData.salary)} / mês
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Admissão em {formatDateBR(payslip.workerData.admissionDate)} |{' '}
          Matrícula {getWorkerId(contract)}
        </Typography>
      </Box>

      {contract?.br?.pagamento && (
        <Box style={{ textAlign: 'right' }}>
          {contract?.br?.pagamento?.nomeBanco &&
            contract?.br?.pagamento?.agencia &&
            contract?.br?.pagamento?.conta && (
              <>
                <Typography
                  variant="caption"
                  component="p"
                  color="text.secondary"
                >
                  {`Depósito em ${contract?.br?.pagamento?.codigoBanco} | ${contract?.br?.pagamento?.nomeBanco}`}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`Agência: ${contract?.br?.pagamento?.agencia} | Conta: ${contract?.br?.pagamento?.conta}`}
                </Typography>
              </>
            )}
          {!contract?.br?.pagamento?.agencia &&
            contract?.br?.pagamento?.chavePix && (
              <>
                <Typography
                  variant="caption"
                  component="p"
                  color="text.secondary"
                >
                  Envio para chave Pix:
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`${contract?.br?.pagamento?.chavePix} | ${parsePixKey(
                    contract?.br?.pagamento?.chavePix,
                  )}`}
                </Typography>
              </>
            )}
        </Box>
      )}
    </Box>
  );
}

function PayslipSummary({ payslip }: { payslip: PayrollPayslipEntry }) {
  const irrfMensal =
    payslip.outputs?.elements['irrf.remuneracaoMensal']?.amount;
  const irrfBase =
    payslip.outputs?.elements['irrf.remuneracaoMensal']?.meta?.explanation
      ?.basis?.total;
  const faixaIRRF =
    irrfMensal && irrfBase ? new Big(irrfMensal).div(new Big(irrfBase)) : 0;
  const tipoFolha = payslip.type;
  const elementIdSalarioContribuicao =
    tipoFolha === payrollTypes.thirteenthFirst ||
    tipoFolha === payrollTypes.thirteenthSecond
      ? 'inss.salarioContribuicao13o'
      : 'inss.salarioContribuicaoMensal';

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', px: 1.5, mt: 3, mb: 7 }}
    >
      <Box sx={{ width: '100%', mr: 2.5 }}>
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Salário base
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(payslip.outputs?.workerEarningsTotal?.total)}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Salário de Contribuição p/ INSS
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(
                    payslip.outputs?.elements[elementIdSalarioContribuicao]
                      ?.amount ??
                      // Fallback to old element name
                      payslip.outputs?.elements['inss.salarioContribuicao']
                        ?.amount ??
                      '0',
                  )}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Base FGTS
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(
                    payslip.outputs?.elements?.fgts?.meta?.explanation?.basis
                      ?.total,
                  )}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box width="100%">
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  FGTS do mês
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(payslip.outputs?.elements?.fgts?.amount)}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Base IRRF
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatMoney(
                    payslip.outputs?.elements['irrf.remuneracaoMensal']?.meta
                      ?.explanation?.basis?.total,
                  )}
                </Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="caption" color="text.secondary">
                  Faixa no IRRF
                </Typography>
              </td>
              <td align="right">
                <Typography variant="caption" color="text.secondary">
                  {formatPercentage(faixaIRRF)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

function CompanyFooter({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const useFetchCompany = () => {
    return useGetCompanyEntry({
      pathParams: {
        organizationId,
        companyId,
      },
    });
  };

  return (
    <DataFetching
      useHook={useFetchCompany}
      Loading={() => (
        <>
          <Skeleton width="100%" />
          <Skeleton width="100%" />
        </>
      )}
      Data={({ data }) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.5 }}>
          <Box sx={{ width: '100%' }}>
            <Box display="flex" flexDirection="row">
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  m: 0.5,
                  mr: '12px',
                  border: '1px solid rgba(237, 237, 237, 1)',
                }}
                variant="rounded"
              >
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  {data?.br?.razaoSocial.charAt(0).toUpperCase()}
                </Typography>
              </Avatar>

              <Box display="flex" flexDirection="column">
                <Typography
                  variant="h2"
                  color="text.primary"
                  sx={{
                    textOverflow: 'ellipsis',
                    mt: 0.5,
                  }}
                >
                  {data?.br?.razaoSocial && data?.br?.razaoSocial?.length > 30
                    ? data?.br?.razaoSocial.substring(0, 30) + '...'
                    : data?.br?.razaoSocial}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box width="100%" sx={{ textAlign: 'right' }}>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              {formatCNPJ(data?.br?.cnpj)}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              {data?.br?.endereco?.logradouro}, {data?.br?.endereco?.numero},{' '}
              {data?.br?.endereco?.complemento}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              {data?.br?.endereco?.bairro}, {data?.br?.endereco?.municipio},{' '}
              {data?.br?.endereco?.uf}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              color="text.secondary"
            >
              CEP {data?.br?.endereco?.cep}
            </Typography>
          </Box>
        </Box>
      )}
    />
  );
}

function Payslip({ payslip }: { payslip: PayrollPayslipEntry }) {
  return (
    <Box pt={13} pb={10} data-testid="payslips-details">
      <PayslipWorkerInformation payslip={payslip} />

      <Divider light sx={{ my: 3 }} />

      <ElementsTables payslip={payslip} />

      <PayslipSummary payslip={payslip} />

      <Divider light sx={{ my: 2 }} />

      <CompanyFooter
        organizationId={payslip.organizationId}
        companyId={payslip.companyId}
      />
    </Box>
  );
}

export function PayslipPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const { payrollId } = useParams<{
    payrollId: string;
  }>();

  const useFetch = () => {
    return useGetPayrollPayslip(
      {
        pathParams: {
          organizationId: organizationId!,
          companyId: companyId!,
          payrollId: payrollId!,
        },
      },
      {
        enabled: !!organizationId && !!companyId && !!payrollId,
      },
    );
  };

  return (
    <>
      <BackButton />
      <Container maxWidth="md">
        <Container sx={{ padding: '0 80px' }}>
          <DataFetching
            useHook={useFetch}
            Loading={() => (
              <Box pt={12}>
                <Typography variant="h3" color="text.primary">
                  <Skeleton width={200} />
                </Typography>
                <Typography variant="h1" color="text.primary">
                  <Skeleton width="100%" />
                </Typography>
                <Skeleton width={200} />
                <Skeleton width={100} />
                <Divider sx={{ my: 2 }} />
                <Skeleton variant="rounded" height={300} />
                <Divider sx={{ my: 2 }} />
                <Skeleton variant="rounded" height={300} />
              </Box>
            )}
            Data={({ data }) => data && <Payslip payslip={data} />}
          />
        </Container>
      </Container>
    </>
  );
}
