import { contractStatuses } from '../status';
import { contractTypes } from '../types';

import { Mapper } from './mapper';

export * from './mapper';

export const EnquadramentoTributario = Mapper.from({
  simples: 'Simples',
  lucroPresumido: 'Lucro presumido',
});

export const PeriodicidadePagamento = Mapper.from({
  mensal: 'Mensal',
  quinzenal: 'Quinzenal',
  semanal: 'Semanal',
});

export const RenovacaoContrato = Mapper.from({
  automatica: 'Automática',
  manual: 'Manual',
});

export const ContractStatusMap = Mapper.from({
  [contractStatuses.active]: 'Ativo',
  [contractStatuses.onLeave]: 'Afastado',
  [contractStatuses.terminated]: 'Desligado',
  [contractStatuses.onAdmission]: 'Em admissão',
});

export const ContractTypeMap = Mapper.from({
  [contractTypes.brClt]: 'Colaborador',
  [contractTypes.brPj]: 'Prestador de serviço (PJ)',
});
