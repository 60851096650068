import { Mapper } from './mapper';

export const CondicaoIngresso = Mapper.from({
  1: 'Refugiado',
  2: 'Solicitante de refúgio',
  3: 'Permanência no Brasil em razão de reunião familiar',
  4: 'Beneficiado pelo acordo entre países do Mercosul',
  5: 'Dependente de agente diplomático e/ou consular de países que mantêm acordo de reciprocidade para o exercício de atividade remunerada no Brasil',
  6: 'Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil e a República Portuguesa',
  7: 'Outra condição',
});
