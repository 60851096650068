import {
  PayrollElement,
  PayrollEntry,
  PayrollPayslipEntry,
} from '@octopus/api';

import { ElementsTableItem } from './ElementsTableItem';

export function ElementsTableItems({
  payroll,
  comparisonPeriod,
  isLoadingComparisonPayroll,
  comparisonPayroll,
  summary,
  onClickElement,
  selectedElementId,
  selectorIconPosition = 'left',
}: {
  payroll: PayrollEntry | PayrollPayslipEntry;
  comparisonPeriod?: string;
  comparisonPayroll?: PayrollEntry;
  isLoadingComparisonPayroll?: boolean;
  summary: 'totalCost' | 'workerEarningsTotal' | 'workerDeductionsTotal';
  onClickElement: (element: PayrollElement) => void;
  selectedElementId?: string | undefined;
  selectorIconPosition?: 'left' | 'right';
}) {
  const allElements = payroll?.outputs?.elements || {};
  const { elements }: { elements: string[] } = payroll?.outputs?.[summary] || {
    elements: [],
  };

  const hasComparison = Boolean(comparisonPeriod);
  const extraElements = [];
  const extraAllElements: {
    [key: string]: PayrollElement;
  } = {};

  if (hasComparison && comparisonPayroll) {
    const { elements: comparisonElements }: { elements: string[] } =
      comparisonPayroll.outputs?.[summary] || {
        elements: [],
      };

    extraElements.push(
      ...comparisonElements.filter((el) => !elements.find((e) => e === el)),
    );

    const comparisonAllElements = comparisonPayroll.outputs?.elements || {};
    const allElementKeys = Object.keys(allElements);

    for (const el in comparisonAllElements) {
      if (!allElementKeys.find((e) => e === el)) {
        extraAllElements[el] = comparisonAllElements[el];
      }
    }
  }

  const rows = elements.map((elementId) => {
    const element: PayrollElement = allElements[elementId]!;
    const comparisonElement: PayrollElement =
      comparisonPayroll?.outputs?.elements[elementId];
    return (
      <ElementsTableItem
        currentElement={element}
        comparisonElement={comparisonElement}
        onClickElement={onClickElement}
        selectedElementId={selectedElementId}
        selectorIconPosition={selectorIconPosition}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        hasComparison={hasComparison}
      />
    );
  });

  const extraRows = extraElements.map((elementId) => {
    const comparisonElement: PayrollElement =
      comparisonPayroll?.outputs?.elements[elementId];
    return (
      <ElementsTableItem
        currentElement={null}
        comparisonElement={comparisonElement}
        onClickElement={onClickElement}
        selectedElementId={selectedElementId}
        selectorIconPosition={selectorIconPosition}
        isLoadingComparisonPayroll={isLoadingComparisonPayroll}
        comparisonPayroll={comparisonPayroll}
        hasComparison={hasComparison}
      />
    );
  });

  return rows.concat(extraRows.flat());
}
