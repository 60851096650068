import { useEffect } from 'react';

import { useLottie } from 'lottie-react';

import { Box } from '@mui/material';

import octopusLoadingJson from './octopusLoading.json';

export const OctopusLoading = () => {
  const options = {
    animationData: octopusLoadingJson,
    loop: true,
  };

  const { View, setSpeed } = useLottie(options);

  useEffect(() => {
    setSpeed(0.75);
  }, []);

  return (
    <Box
      sx={{
        filter: 'hue-rotate(216deg) saturate(100%)',
      }}
    >
      {View}
    </Box>
  );
};

export const DotsLoading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width="51" height="51" viewBox="0 0 51 51" fill="none">
        <circle
          cx="25.5"
          cy="25.5"
          r="23"
          stroke="#2D2D2D"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="1 11 1 11"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="360 0 0"
          to="0 0 0"
          dur="4s"
          repeatCount="indefinite"
        />
      </svg>
    </Box>
  );
};
