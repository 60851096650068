import React, { useRef } from 'react';

import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

import { FieldWrapper } from './commons/FieldWrapper';

type FieldProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
  sx?: SxProps<Theme>;
};

export function FieldTextDateYearMonth(props: FieldProps) {
  const { field } = props;

  const payloadInputRef = useRef(null);
  const datepickerInputRef = useRef(null);
  const hasError = field.errors?.length > 0;

  return (
    <FieldWrapper field={field} sx={field.props.sx}>
      <Box {...field.props} display={'flex'} flexDirection={'column'}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="pt-br"
          localeText={{
            fieldMonthPlaceholder: () => 'mês',
            fieldYearPlaceholder: () => 'ano',
          }}
        >
          <input
            {...field.input.props}
            type="hidden"
            ref={payloadInputRef}
            tabIndex={-1}
            onFocus={() => {
              datepickerInputRef.current?.focus();
            }}
          />
          <DatePicker
            label={undefined}
            format="MM/YYYY"
            views={['year', 'month']}
            openTo="month"
            ref={datepickerInputRef}
            slotProps={{
              textField: {
                placeholder: 'mês e ano',
                error: hasError ?? undefined,
              },
            }}
            onChange={(value) => {
              if (!value) {
                payloadInputRef.current.value = null;
              } else if (
                payloadInputRef.current &&
                typeof value === 'object' &&
                'format' in value &&
                typeof value.format === 'function'
              ) {
                payloadInputRef.current.value = value.format('YYYY-MM');
              }
            }}
          />
        </LocalizationProvider>
      </Box>
    </FieldWrapper>
  );
}

FieldTextDateYearMonth.uiType = UI_TYPE.TEXT_DATE_PICKER_YEAR_MONTH;
FieldTextDateYearMonth.canRender = (arg: TFieldRenderProps) => {
  return 'input' in arg && arg.uiType === UI_TYPE.TEXT_DATE_PICKER_YEAR_MONTH;
};
