import React from 'react';

import { Circle } from '@mui/icons-material';
import { Box, SxProps, Typography } from '@mui/material';

export function SendStatus({
  isSent,
  SentMessage,
  NotSentMessage,
  sx,
}: {
  sx?: SxProps;
} & (
  | {
      isSent: true;
      SentMessage: React.ElementType;
      NotSentMessage?: never;
    }
  | {
      isSent: false;
      NotSentMessage: React.ElementType;
      SentMessage?: never;
    }
)) {
  return (
    <Box display="flex" alignItems="center" sx={sx}>
      <Circle
        sx={(theme) => ({
          width: '8px',
          color: isSent ? theme.palette.info.main : theme.palette.warning.main,
        })}
      />
      <Box pl={1}>
        <Typography
          variant="body2"
          color={isSent ? 'info.main' : 'warning.main'}
          whiteSpace="nowrap"
        >
          {isSent && <SentMessage />}
          {!isSent && <NotSentMessage />}
        </Typography>
      </Box>
    </Box>
  );
}
