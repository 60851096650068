import { Box } from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';

import { Label } from '../Field/commons/Label';
// eslint-disable-next-line import/no-cycle
import { FormFields } from '../FormFields';
import { TFieldRenderProps, TFieldsetRenderProps } from '../parseField/types';

type FieldsetProps = {
  field: TFieldRenderProps & TFieldsetRenderProps;
};

export function FieldsetInline(props: FieldsetProps) {
  const { field: fieldset } = props;

  return (
    <Box
      component="fieldset"
      {...fieldset.props}
      sx={{
        margin: 0,
        padding: 0,
        border: 'none',
      }}
    >
      <Label field={fieldset} />
      <Box
        sx={{
          '--FieldsetInline-spaceBetween': '12px',
          display: 'grid',
          flexWrap: 'wrap',
          gridTemplateColumns:
            'repeat(auto-fit, minmax(calc(25% - var(--FieldsetInline-spaceBetween)), 1fr))',
          gap: 'var(--FieldsetInline-spaceBetween)',
        }}
      >
        {FormFields({
          fields: fieldset.fields.map((field) => ['', field]),
        }).map(([, field]) => field)}
      </Box>
    </Box>
  );
}

FieldsetInline.uiType = UI_TYPE.FIELDSET;
FieldsetInline.canRender = (
  field: TFieldRenderProps,
): field is FieldsetProps['field'] => {
  return 'fields' in field;
};
