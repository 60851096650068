import { PayrollOutputsOutputs } from '@octopus/api';

export function getAmountFromElement(
  element: string,
  calculationOutputs: PayrollOutputsOutputs,
): string | undefined {
  return calculationOutputs.elements[element]?.amount;
}

export function getBasisFromElement(
  element: string,
  calculationOutputs: PayrollOutputsOutputs,
): string | undefined {
  return calculationOutputs.elements[element]?.meta?.explanation?.basis?.total;
}

export function getRateFromElement(
  element: string,
  calculationOutputs: PayrollOutputsOutputs,
): string | undefined {
  const body = calculationOutputs.elements[element]?.meta?.explanation?.body;
  if (!body) {
    return undefined;
  }
  switch (body.type) {
    case 'progressive':
      return body.brackets
        .map((bracket) => bracket.rate)
        .reduce((max, rate) => {
          if (rate > max) {
            return rate;
          }
          return max;
        }, '0');
    case 'proportion':
      return undefined;
    case 'flatTable':
    case 'progressiveTableWithDeduction':
      return body.brackets.find((bracket) => bracket.isRateEffective).rate;
    default:
      return undefined;
  }
}
