import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Button,
  Container,
  IconButton,
  Popover,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import Box from '@mui/material/Box';

import {
  PjPaymentRequestSummary,
  fetchSearchAllPjPaymentRequests,
} from '@octopus/api';
import {
  formatDateBR,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  GridColDef,
  makeDateRangeFilter,
  makeElementListFilter,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import receiptDelete from '../../assets/receiptDelete.svg';
import requestPaymentPj from '../../assets/requestPaymentPj.svg';
import requestPaymentPjSecondary from '../../assets/requestPaymentPj_secondary.svg';
import UserAvatar from '../../modules/components/UserAvatar';
import { DataFetching } from '../../modules/dataFetching';
import { getActiveElementFilters, getActiveRangeFilters } from '../../utils';

export type PaymentRequestsProps = {
  organizationId: string;
  companyId: string;
};

const statusGroupsConfig = {
  created: { color: 'primary', label: 'Recebidas' },
  approved: {
    color: 'success',
    label: 'Aprovadas',
  },
  rejected: { color: 'error', label: 'Recusadas' },
};

export function PaymentRequestListPage({
  organizationId,
  companyId,
}: PaymentRequestsProps) {
  const navigate = useNavigate();
  const [tab, setTab] = useState<keyof typeof statusGroupsConfig>('created');
  const [countByStatus, setCountByStatus] = useState<{
    [key in keyof typeof statusGroupsConfig]: number;
  }>({
    created: 0,
    approved: 0,
    rejected: 0,
  });

  const [showNotasRecusadas, setShowNotasRecusadas] = useState(true);

  const filters = useFilters();
  const { filteringProps, searchProps, sortingProps, paginationProps } =
    useDataGrid({
      filters,
    });

  const elementFilters = getActiveElementFilters(filteringProps);
  const rangeFilters = getActiveRangeFilters(filteringProps);

  const searchQuery = useQuery({
    queryKey: [
      organizationId,
      companyId,
      paginationProps,
      tab,
      elementFilters,
      rangeFilters,
      searchProps.searchTerm,
      sortingProps,
    ],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return fetchSearchAllPjPaymentRequests({
        pathParams: {
          organizationId: organizationId ?? '',
          companyId: companyId ?? '',
        },
        body: {
          pagination: {
            page: paginationProps.page,
            size: paginationProps.rowsPerPage,
          },
          query: searchProps.searchTerm,
          sorting: {
            field: sortingProps.field ?? 'createdOn',
            order: sortingProps.order ?? 'desc',
          },
          filtering: {
            elements: {
              ...elementFilters,
              status: [tab],
              active: ['true'],
            },
            ...(rangeFilters ? { ranges: rangeFilters } : {}),
          },
          counting: {
            filtered: {
              total: true,
              byProp: {
                status: ['created', 'approved', 'rejected'],
              },
            },
          },
        },
      });
    },
    enabled: !!organizationId && !!companyId,
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar />

      <Container>
        <Box pt={5}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            data-testid="payment-requests-header"
            mb={5}
            gap={1}
          >
            <img
              src={requestPaymentPj}
              height={40}
              width={40}
              alt="Solicitações de pagamento"
            />
            <Typography variant="h1">Solicitações de pagamento</Typography>
          </Box>

          <Box display={'flex'} flexDirection={'row'}>
            <Box display={'flex'} width={'100%'} bgcolor={'background.paper'}>
              <Box alignSelf="stretch" width={'100%'}>
                <DataGridToolbar
                  filters={filters}
                  searchProps={searchProps}
                  filteringProps={filteringProps}
                  searchPlaceholder="Procurar..."
                >
                  <Box marginLeft={'auto'} py={0.5}>
                    <IconButton
                      sx={{
                        borderRadius: '8px',
                        padding: '4px',
                        border: '1px solid #EDEDED',
                        px: 2,
                        py: 1,
                        gap: 1,
                      }}
                      onClick={() => {
                        navigate('/payment-requests/new');
                      }}
                    >
                      <img
                        width={'16px'}
                        height={'16px'}
                        src={requestPaymentPjSecondary}
                      ></img>
                      <Typography variant={'body2'} color={'text.secondary'}>
                        Nova solicitação
                      </Typography>
                    </IconButton>
                  </Box>
                </DataGridToolbar>
              </Box>
            </Box>
          </Box>

          <Box
            my={2}
            sx={(theme) => ({
              boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
            })}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={tab}
              onChange={(_, newTab) => setTab(newTab)}
              textColor="inherit"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: `${statusGroupsConfig[tab].color}.main`,
                },
              }}
              data-testid="payrolls-period-tabs"
            >
              {Object.entries(statusGroupsConfig)
                .filter(([key, _]) => {
                  return key !== 'rejected' || showNotasRecusadas;
                })
                .map(([groupName, groupConfig]) => (
                  <Tab
                    key={groupName}
                    value={groupName}
                    icon={
                      <PaymentRequestsTabLabel
                        isSelected={groupName === tab}
                        config={groupConfig}
                        count={
                          countByStatus[
                            groupName as keyof typeof statusGroupsConfig
                          ]
                        }
                      />
                    }
                    data-testid={`payrolls-period-tab-${groupName}`}
                  />
                ))}
            </Tabs>
            <ActionMenu
              showNotasRecusadas={showNotasRecusadas}
              setShowNotasRecusadas={setShowNotasRecusadas}
              tab={tab}
              setTab={setTab}
            />
          </Box>
          <DataFetching
            fetchResult={searchQuery}
            Loading={() => (
              <Skeleton variant="rounded" height={400} width="100%" />
            )}
            onData={(data) => {
              if (data?.metadata?.filtered.counters.byProp) {
                setCountByStatus({
                  ...{
                    created: 0,
                    approved: 0,
                    rejected: 0,
                  },
                  ...data?.metadata?.filtered.counters.byProp.status,
                });
              }
            }}
            Data={({ data: list }) => {
              return (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={list?.total ?? 0}
                  rows={list?.data ?? []}
                  getRowId={(row) => `${row.id}`}
                  onRowClick={({ row }) => {
                    navigate(`/payment-requests/${row.id}`);
                  }}
                  columns={getColumnsForStatus(tab)}
                  emptyMessage={getEmptyMessageForStatus(tab)}
                />
              );
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}

function getEmptyMessageForStatus(state: keyof typeof statusGroupsConfig) {
  switch (state) {
    case 'created':
      return 'Não existem solicitações de pagamento criadas no momento.';
    case 'approved':
      return 'Não existem solicitações de pagamento aprovadas no momento.';
    case 'rejected':
      return 'Não existem solicitações de pagamento recusadas no momento.';
    default:
      return 'Não existem solicitações de pagamento no momento.';
  }
}

function useFilters(): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeMoneyRangeFilter({
      label: 'Valor da nota',
      propertyToFilter: 'grossAmount',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
    makeDateRangeFilter({
      label: 'Data de envio',
      propertyToFilter: 'createdOn',
    }),
    makeDateRangeFilter({
      label: 'Data de aprovação',
      propertyToFilter: 'approvedOn',
    }),
    makeElementListFilter({
      label: 'Arquivo da NF',
      propertyToFilter: 'invoiceUploaded',
      elements: ['true', 'false'],
      labels: {
        true: 'Com arquivo de NF',
        false: 'Sem arquivo de NF',
      },
      disableSearch: true,
      disableSelectAll: true,
      sortElements: false,
    }),
  ].filter(Boolean);
}

type PaymentRequestsTabLabelProps = {
  isSelected: boolean;
  config: (typeof statusGroupsConfig)[keyof typeof statusGroupsConfig];
  count: number;
};

function PaymentRequestsTabLabel({
  isSelected,
  config: { label, color },
  count,
}: PaymentRequestsTabLabelProps) {
  const fontWeight = isSelected ? 700 : 500;
  const textColor = isSelected ? `${color}.main` : 'text.secondary';
  const bgColor = isSelected ? `background.${color}` : 'background.default';
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography color={textColor} variant="body1" fontWeight={fontWeight}>
        {label}
      </Typography>

      <Typography
        bgcolor={bgColor}
        color={textColor}
        py={0.25}
        px={1}
        borderRadius={2}
        variant="caption"
        fontWeight={fontWeight}
      >
        {count}
      </Typography>
    </Box>
  );
}

const columns: GridColDef<PjPaymentRequestSummary>[] = [
  {
    field: 'contractorData.name',
    headerName: 'Prestador / Número da nota',
    sortable: true,
    renderHeader: (params) => {
      return <Box ml={2}>{params.field}</Box>;
    },
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          gap={1}
          paddingX={1}
          alignSelf={'stretch'}
          justifyContent={'space-between'}
        >
          <Box
            padding={'0px 8px'}
            gap={1.5}
            display={'flex'}
            alignItems={'center'}
          >
            <Box>
              <UserAvatar
                name={params.row.contractorData.name}
                showFullName={false}
                avatarTextProps={{
                  fontWeight: '700',
                  fontSize: '10px',
                  lineHeight: '16px',
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              paddingBottom="1px"
              alignItems={'flex-start'}
              justifyContent={'center'}
            >
              <Typography variant="body2" fontSize="14px" lineHeight="20px">
                {params.row.contractorData.name}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                fontSize="12px"
                lineHeight="16px"
              >
                {params.row.invoice?.number ?? '--'}
              </Typography>
            </Box>
          </Box>
          {!params.row.invoiceUploaded && (
            <Box display={'flex'} alignItems={'center'}>
              <Avatar
                sx={{
                  backgroundColor: '#FDF2F4',
                  width: '24px',
                  height: '24px',
                }}
              >
                <Box>
                  <HtmlTooltip
                    title={
                      <Box
                        px={1.5}
                        pt={0.5}
                        gap={2}
                        display={'flex'}
                        flexDirection={'row'}
                      >
                        <Box py={'2px'}>
                          <img
                            src={receiptDelete}
                            width={'16px'}
                            height={'16px'}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            fontSize="14px"
                            lineHeight="20px"
                          >
                            Solicitação enviada sem o arquivo da nota fiscal.
                          </Typography>
                        </Box>
                      </Box>
                    }
                  >
                    <img src={receiptDelete} width={'12px'} height={'12px'} />
                  </HtmlTooltip>
                </Box>
              </Avatar>
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    field: 'period',
    headerName: 'Competência',
    sortable: true,
    valueGetter: (params) => {
      return formatPeriodDate(params.row.period);
    },
  },
  {
    field: 'amount',
    headerName: 'Valor do pagamento',
    sortable: false,
    valueGetter: (params) => formatMoney(params.row.grossAmount) || '--',
  },
];

function getColumnsForStatus(
  state: keyof typeof statusGroupsConfig,
): GridColDef<PjPaymentRequestSummary>[] {
  switch (state) {
    case 'created':
      return [
        ...columns,
        {
          field: 'createdOn',
          headerName: 'Data do envio',
          sortable: false,
          valueGetter: (params) => formatDateBR(params.row.createdOn) || '--',
        },
        {
          field: 'action',
          headerName: 'Ação',
          renderHeader: (params) => {
            return <Box ml={1}>{params.field}</Box>;
          },
          renderCell: () => {
            return (
              <Button
                variant="text"
                color="primary"
                sx={{
                  fontWeight: 500,
                  lineHeight: '20px',
                  fontSize: '14px',
                }}
              >
                Aprovar ou recusar
              </Button>
            );
          },
        },
      ];
    case 'approved':
      return [
        ...columns,
        {
          field: 'approvedOn',
          headerName: 'Data da aprovação',
          sortable: false,
          valueGetter: (params) => formatDateBR(params.row.approvedOn) || '--',
        },
      ];
    case 'rejected':
      return [
        ...columns,
        {
          field: 'rejectedOn',
          headerName: 'Recusada em',
          sortable: false,
          valueGetter: (params) => formatDateBR(params.row.rejectedOn) || '--',
        },
      ];
    default:
      return columns;
  }
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '600px',
    color: '#25252D',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #0000001A',
    borderRadius: '8px',
    background: '#FFFFFF',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 16px 0px rgba(0, 0, 0, 0.03);',
  },
}));

function ActionMenu({
  showNotasRecusadas,
  setShowNotasRecusadas,
  tab,
  setTab,
}: {
  showNotasRecusadas: boolean;
  setShowNotasRecusadas: (value: boolean) => void;
  tab: keyof typeof statusGroupsConfig;
  setTab: (value: keyof typeof statusGroupsConfig) => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        sx={{
          m: 1,
        }}
        data-testid="notas-recusadas-popover"
      >
        <Box display={'flex'} flexDirection={'row'} p={1} gap={2}>
          <Typography
            fontSize={'14px'}
            paddingLeft={1.5}
            py={1}
            variant={'body1'}
          >
            Mostrar notas recusadas
          </Typography>
          <Box paddingRight={1}>
            <Switch
              checked={showNotasRecusadas}
              onChange={() => {
                setShowNotasRecusadas(!showNotasRecusadas);
                if (tab === 'rejected') {
                  setTab('created');
                }
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
