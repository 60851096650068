import {
  ContractBRPjEntryNascimento,
  ContractBRPjEntryPessoa,
} from '@octopus/api';
import { Nacionalidades, Paises } from '@octopus/esocial/mapper';
import { formatCPF, formatDateBR } from '@octopus/formatters';

import { Record, RecordEntry, mapperToOptions } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type InformacoesPessoaisRecordData = {
  pessoa: ContractBRPjEntryPessoa;
  nascimento: ContractBRPjEntryNascimento | undefined;
};

export type InformacoesPessoaisRecordProps =
  BaseRecordProps<InformacoesPessoaisRecordData>;

export function InformacoesPessoaisRecord(
  props: InformacoesPessoaisRecordProps,
) {
  const {
    data: { pessoa, nascimento },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<InformacoesPessoaisRecordData>(props);

  const prepareOnChange = (
    group: 'pessoa' | 'nascimento',
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        [group]: { ...data[group], [key]: formatter(value) },
      }));
  };
  return (
    <Record title="Informações pessoais" edit={editRecordProps}>
      <RecordEntry
        label="Nome completo"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('pessoa', 'nmTrab'),
          value: formData.pessoa.nmTrab,
          hasError: hasError('br/pessoa/nmTrab'),
        }}
      >
        {pessoa.nmTrab}
      </RecordEntry>
      <RecordEntry
        label="Nome social"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('pessoa', 'nmSoc'),
          value: formData.pessoa.nmSoc,
          hasError: hasError('br/pessoa/nmSoc'),
        }}
      >
        {pessoa.nmSoc}
      </RecordEntry>
      <RecordEntry
        label="CPF"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('pessoa', 'cpfTrab'),
          value: formatCPF(formData.pessoa.cpfTrab),
          disabled: true,
          hasError: hasError('br/pessoa/cpfTrab'),
        }}
      >
        {formatCPF(pessoa.cpfTrab)}
      </RecordEntry>
      <RecordEntry
        label="Data de nascimento"
        edit={{
          type: 'date',
          editing,
          valueFormat: 'YYYY-MM-DD',
          value: nascimento?.dtNascto,
          onChange: prepareOnChange('nascimento', 'dtNascto'),
          hasError: hasError('br/nascimento/dtNascto'),
        }}
      >
        {nascimento?.dtNascto ? formatDateBR(nascimento.dtNascto) : undefined}
      </RecordEntry>
      <RecordEntry
        label="País de nascimento"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('nascimento', 'paisNascto', parseInt),
          value: formData.nascimento?.paisNascto ?? 105,
          options: mapperToOptions({ mapper: Paises, sortBy: 'label' }),
          hasError: hasError('br/nascimento/paisNascto'),
        }}
      >
        {Paises.getByCode(nascimento?.paisNascto)}
      </RecordEntry>
      <RecordEntry
        label="Nacionalidade"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('nascimento', 'paisNac', parseInt),
          value: formData.nascimento?.paisNac ?? 105,
          options: mapperToOptions({
            mapper: Paises,
            sortBy: 'label',
            getLabel: (code) => Nacionalidades.getByCode(code as number),
          }),
          hasError: hasError('br/nascimento/paisNac'),
        }}
      >
        {Nacionalidades.getByCode(nascimento?.paisNac)}
      </RecordEntry>
    </Record>
  );
}
