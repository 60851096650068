import { init } from 'i18next';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import enTranslation from 'zod-i18n-map/locales/en/zod.json';
import ptTranslation from 'zod-i18n-map/locales/pt/zod.json';

import { Locale } from '../types';

import { EnglishErrorCodes } from './en';
import { PortugueseErrorCodes } from './pt';

const initZod = (locale: Locale) => {
  let resources = null;

  switch (locale) {
    case 'en':
      resources = {
        en: {
          zod: enTranslation,
          custom: EnglishErrorCodes,
        },
      };
      break;
    case 'pt':
      resources = {
        pt: {
          zod: ptTranslation,
          custom: PortugueseErrorCodes,
        },
      };
      break;
  }

  if (resources === null) {
    throw new Error(`Locale ${locale} not implemented.`);
  }

  return init({
    lng: locale,
    resources,
  });
};

z.setErrorMap(makeZodI18nMap({ ns: ['zod', 'custom'] }));
export { initZod, z };
