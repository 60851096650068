import { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import {
  PayrollList,
  PayrollSummary,
  fetchSearchAllPayrolls,
  useArchivePayroll,
} from '@octopus/api';
import {
  DataGrid,
  DataGridToolbar,
  FilterOption,
  GridColDef,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { getActiveElementFilters, getActiveRangeFilters } from '../../../utils';
import { ActionMenuItem } from '../ActionMenu';

export type ArrayElement<TArray extends readonly unknown[]> =
  TArray extends readonly (infer TArrayElementType)[]
    ? TArrayElementType
    : never;

type LinkPropsGetter = () => {
  href: string;
  label: string;
};

type PayrollPageLinks = {
  create?: LinkPropsGetter;
};

type PayrollTypePageControlledProps<TabsNames extends readonly string[]> = {
  requests: {
    search: ReturnType<typeof useQuery<PayrollList>>;
  };
  data: {
    tabs: TabsData<TabsNames>;
    datagrid: DataGridData;
    datagridToolbar: DataGridToolbarData;
    pageActionMenuItems: ActionMenuItem[];
    payrollsBeingArchived: Set<string>;
    links?: {
      create?: LinkPropsGetter;
    };
  };
  actions: {
    openTab: (tab: TabInputProps) => void;
    archivePayroll: (payrollId: string) => void;
  };
};

type TabsData<TabsNames extends readonly string[]> = {
  currentTabName: ArrayElement<TabsNames>;
  propsByTab: {
    [k in ArrayElement<TabsNames>]: TabComponentProps;
  };
  onTabChange: (newTabName: ArrayElement<TabsNames>) => unknown;
};

type DataGridData = Pick<
  DataGridComponentProps,
  | 'columns'
  | 'rows'
  | 'onRowClick'
  | 'totalRowCount'
  | 'getRowId'
  | 'sortingProps'
  | 'paginationProps'
>;

type DataGridToolbarData = Pick<
  DataGridToolbarComponentProps,
  'searchProps' | 'filteringProps' | 'filters'
>;

type TabInputProps = {
  color: string;
  label: string;
  statuses: string[];
  count?: number;
};

type TabComponentProps = TabInputProps & {
  isVisible?: boolean;
};

type DataGridComponentProps = Parameters<typeof DataGrid<PayrollSummary>>[0];
type DataGridToolbarComponentProps = Parameters<typeof DataGridToolbar>[0];

export function usePayrollTypePage<
  TTabInputPropsByName extends {
    [k in ArrayElement<TTabsNames>]: TabInputProps;
  },
  TTabsNames extends ReadonlyArray<keyof TTabInputPropsByName & string>,
>({
  payrollType,
  links,
  tabs,
  defaultTab,
  visibleTabs,
  getColumnsForTab,
  filters,
  organizationId,
  companyId,
  onRowClick,
  getRowActionMenu,
  getPageActionMenu,
}: {
  organizationId: string;
  companyId: string;
  payrollType: 'termination' | 'vacations';
  links?: PayrollPageLinks;
  tabs: TTabInputPropsByName;
  visibleTabs?: TTabInputPropsByName[ArrayElement<TTabsNames>][];
  defaultTab?: TTabInputPropsByName[ArrayElement<TTabsNames>];
  getColumnsForTab: (
    tab: ArrayElement<TTabsNames>,
  ) => GridColDef<PayrollSummary>[];
  getRowActionMenu?: GridColDef<PayrollSummary>['valueGetter'] &
    ((...args: any[]) => ActionMenuItem[]); // eslint-disable-line @typescript-eslint/no-explicit-any
  getPageActionMenu?: (...args: any[]) => ActionMenuItem[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  filters: Array<FilterOption>;
  onRowClick: DataGridComponentProps['onRowClick'];
}): PayrollTypePageControlledProps<TTabsNames> {
  const defaulTabName = useMemo(() => {
    const tabConfigsEntries = Object.entries(tabs) as [
      ArrayElement<TTabsNames>,
      TTabInputPropsByName[ArrayElement<TTabsNames>],
    ][];

    const [firstTabName] =
      tabConfigsEntries.find(([_tabName, tabProps]) =>
        visibleTabs.includes(tabProps),
      ) || [];
    const [defaultTabName] =
      tabConfigsEntries.find(
        ([_tabName, tabProps]) => defaultTab === tabProps,
      ) || [];
    return defaultTabName || firstTabName;
  }, [tabs]);

  const [currentTabName, setCurrentTabName] = useState(defaulTabName);

  const openTabAction = (toOpenTabProps: TabInputProps) => {
    const [tabName] =
      Object.entries(tabs).find(
        ([_, tabProps]) => tabProps === toOpenTabProps,
      ) || [];
    setCurrentTabName(tabName as ArrayElement<TTabsNames>);
  };

  const archivePayrollMutation = useArchivePayroll();

  const [payrollsBeingArchived, setPayrollsBeingArchived] = useState(
    () => new Set<string>(),
  );

  const archivePayrollAction = (payrollId: string) => {
    setPayrollsBeingArchived(new Set([...payrollsBeingArchived, payrollId]));

    const archivePromise = archivePayrollMutation.mutateAsync({
      pathParams: {
        organizationId,
        companyId,
        payrollId,
      },
    });

    archivePromise.finally(() => {
      setTimeout(() => {
        //TODO optmistic update search query result data
        const newPayrollsBeingArchived = new Set(payrollsBeingArchived);
        newPayrollsBeingArchived.delete(payrollId);
        setPayrollsBeingArchived(newPayrollsBeingArchived);
        setRefetchCount((count) => count + 1);
      }, 1000);
    });
  };

  const tabNames = Object.keys(tabs) as ArrayElement<TTabsNames>[];
  const statuses = tabNames.map((tabName) => tabs[tabName].statuses).flat();

  const dataGridProps = useDataGrid({
    filters,
  });

  const [refetchCount, setRefetchCount] = useState(0);

  const elementFilters = getActiveElementFilters(dataGridProps.filteringProps);
  const rangeFilters = getActiveRangeFilters(dataGridProps.filteringProps);

  const searchPayrollsResult = useQuery({
    queryKey: [
      refetchCount,
      organizationId,
      companyId,
      ...tabNames,
      currentTabName,
      rangeFilters,
      elementFilters,
      dataGridProps.paginationProps,
      dataGridProps.sortingProps,
      dataGridProps.searchProps,
    ],
    keepPreviousData: true,
    queryFn: () =>
      fetchSearchAllPayrolls({
        pathParams: {
          organizationId: organizationId!,
          companyId: companyId!,
        },
        body: {
          query: dataGridProps.searchProps.searchTerm,
          pagination: {
            size: dataGridProps.paginationProps.rowsPerPage,
            page: dataGridProps.paginationProps.page,
          },
          ...(dataGridProps.sortingProps.field
            ? {
                sorting: {
                  field: dataGridProps.sortingProps.field,
                  order: dataGridProps.sortingProps.order,
                },
              }
            : {}),
          filtering: {
            elements: {
              ...elementFilters,
              status: tabs[currentTabName].statuses,
              type: [payrollType],
            },
            ...(rangeFilters && { ranges: rangeFilters }),
          },
          counting: {
            filtered: {
              byProp: {
                status: statuses,
              },
            },
          },
        },
      }),
    enabled: !!organizationId && !!companyId,
  });

  const columnsWithContextMenuCol: GridColDef<PayrollSummary>[] = [
    ...getColumnsForTab(currentTabName),
    Boolean(getRowActionMenu) && {
      field: 'contextMenu',
      headerName: '',
      sortable: false,
      valueGetter: getRowActionMenu,
    },
  ].filter(Boolean);

  const datagridToolbarData = {
    ...dataGridProps,
    filters: filters,
  };

  const pageActionMenuItems = getPageActionMenu();

  const resultDataGridData: DataGridData = {
    sortingProps: dataGridProps.sortingProps,
    paginationProps: dataGridProps.paginationProps,
    columns: columnsWithContextMenuCol,
    rows: Object.values(searchPayrollsResult.data?.data || []),
    totalRowCount: searchPayrollsResult.data?.total,
    getRowId: (row) => row?.payrollId,
    onRowClick: onRowClick,
  };

  const getCountByStatus = (statuses: string[]) => {
    const statusCounters =
      searchPayrollsResult.data?.metadata?.filtered?.counters?.byProp?.status;
    if (!statusCounters) return undefined;

    const count = statuses.reduce((acc: number, status: string) => {
      return acc + (statusCounters[status] || 0);
    }, 0);

    return count !== 0 ? count : undefined;
  };

  const propsByTabName = useMemo(() => {
    const tabEntries = Object.entries(tabs) as [
      ArrayElement<TTabsNames>,
      TTabInputPropsByName[ArrayElement<TTabsNames>],
    ][];

    return tabEntries.reduce(
      (propsByTabName, [tabName, tabProps]) => {
        propsByTabName[tabName] = {
          ...tabProps,
          isVisible: visibleTabs.includes(tabProps),
          count: getCountByStatus(tabProps.statuses),
        };
        return propsByTabName;
      },
      {} as TabsData<TTabsNames>['propsByTab'],
    );
  }, [tabs, visibleTabs, searchPayrollsResult.data]);

  const tabsData: TabsData<TTabsNames> = {
    currentTabName,
    propsByTab: propsByTabName,
    onTabChange: (newTabName: string) =>
      setCurrentTabName(newTabName as ArrayElement<TTabsNames>),
  };

  return {
    requests: {
      search: searchPayrollsResult,
    },
    data: {
      datagridToolbar: datagridToolbarData,
      datagrid: resultDataGridData,
      tabs: tabsData,
      pageActionMenuItems,
      payrollsBeingArchived,
      ...(Boolean(links) && { links }),
    },
    actions: {
      openTab: openTabAction,
      archivePayroll: archivePayrollAction,
    },
  };
}
