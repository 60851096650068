import { useRef } from 'react';

import { FormMetadata } from '@conform-to/react';

import { TFieldSelectRenderProps } from './parseField/types';

export type TFormFieldAutocompleteOptionsState = {
  targetFieldName: string;
  queryFieldName: string;
  queryFieldValue: string;
  queryTimestamp: string;
  resolveQuery: (
    options:
      | TFieldSelectRenderProps['select']['options']
      | Promise<TFieldSelectRenderProps['select']['options']>,
  ) => boolean | Promise<boolean>;
};

export function useMetaFormAutocompleteOptionsState(metaform: FormMetadata) {
  const currentSearchQueriesRef = useRef<
    Record<string, TFormFieldAutocompleteOptionsState>
  >({});

  const searchFields = Object.entries(metaform.value || {}).filter(([name]) =>
    name.endsWith('autocomplete_input'),
  );

  const queryByFieldName = searchFields.reduce(
    (byFieldName, [name, value]) => {
      byFieldName[name] = {
        targetFieldName: name.replace(/_autocomplete_input$/, ''),
        queryFieldName: '',
        queryFieldValue: `${value}`,
      };
      return byFieldName;
    },
    {} as Record<
      string,
      Pick<
        TFormFieldAutocompleteOptionsState,
        'targetFieldName' | 'queryFieldName' | 'queryFieldValue'
      >
    >,
  );

  const newSearchQueriesEntries = Object.entries(queryByFieldName).filter(
    ([searchFieldName, searchFieldQueryState]) => {
      const currentQueryState =
        currentSearchQueriesRef.current[searchFieldName];
      const isNewQuery =
        currentQueryState?.queryFieldValue !==
        searchFieldQueryState.queryFieldValue;
      return isNewQuery;
    },
  );

  if (!newSearchQueriesEntries.length) return currentSearchQueriesRef.current;

  const newSearchQueries = newSearchQueriesEntries.reduce(
    (newSearchQueries, [searchFieldName, searchFieldQueryState]) => {
      newSearchQueries[searchFieldName] = {
        ...searchFieldQueryState,
        queryTimestamp: undefined,
        resolveQuery: async (arg) => {
          const thisQuery = newSearchQueries[searchFieldName];
          if (thisQuery.queryTimestamp) return true;
          thisQuery.queryTimestamp = Date.now().toString();
          const optionsTimestampFieldName = `${searchFieldQueryState.targetFieldName}_autocomplete_timestamp`;
          metaform.update({
            name: optionsTimestampFieldName,
            value: thisQuery.queryTimestamp,
          });

          const options = await arg;
          const currentQuery = currentSearchQueriesRef.current[searchFieldName];

          const isStaleQuery = currentQuery !== thisQuery;
          if (isStaleQuery) return false;

          const optionsSelectFieldName = `${searchFieldQueryState.targetFieldName}_autocomplete_options`;

          metaform.update({
            name: optionsSelectFieldName,
            value: options,
          });

          return true;
        },
      };
      return newSearchQueries;
    },
    {} as Record<string, TFormFieldAutocompleteOptionsState>,
  );

  Object.assign(currentSearchQueriesRef.current, newSearchQueries);
  return currentSearchQueriesRef.current;
}
