import { MouseEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import { startPasswordRecovery } from '../../../../modules/login';

export function ForgotPasswordSent() {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [state, setState] = useState({
    username: '',
  });

  useEffect(() => {
    if (!locationState) {
      navigate('/login');
    }
    setState((oldState) => ({
      ...oldState,
      username: locationState.username,
    }));
  }, [locationState]);

  const { mutate, isLoading } = useMutation(async (e: MouseEvent) => {
    e.preventDefault();
    await startPasswordRecovery(state.username);
  });

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={5.5}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="h3"
          fontWeight="700"
          data-testid="forgot-password-sent-title"
        >
          Enviado
        </Typography>
        <Typography variant="body2" component="span">
          Um link foi enviado para{' '}
          <Typography variant="body2" fontWeight="700" display="inline">
            {locationState?.username}
          </Typography>
          , caso esteja cadastrado.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Typography variant="body2" fontWeight="500" color="text.secondary">
          Não recebeu o email?
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <LoadingButton
            fullWidth
            color="primaryAlt"
            loading={isLoading}
            onClick={mutate}
            variant="contained"
            data-testid="resend"
            sx={{ p: 1.5 }}
          >
            <Typography variant="body1" color="secondary" fontWeight="700">
              Enviar novamente
            </Typography>
          </LoadingButton>
          <Button
            fullWidth
            color="secondary"
            component={Link}
            to="/login"
            data-testid="back"
            sx={{ p: 1.5 }}
          >
            <Typography variant="body1" color="text.secondary" fontWeight="700">
              Voltar para o login
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
