import { useRef } from 'react';

import { useField } from '@conform-to/react';

import { Close, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';

import {
  TFieldRenderProps,
  TInputFieldsetRenderProps,
} from '../parseField/types';

import { Label } from './commons/Label';

type FieldProps = {
  field: TFieldRenderProps & TInputFieldsetRenderProps;
  sx?: SxProps<Theme>;
};

const chipStyles = {
  display: 'inline-flex',
  height: '32px',
  padding: '0 8px',
  alignItems: 'center',
  borderRadius: '32px',
  userSelect: 'none',
};

const chipRequiredStyles = {
  border: '1px solid transparent',
  backgroundColor: (theme: Theme) => theme.palette.strokes.secondary,
  cursor: 'default',
  pointerEvents: 'none',
};

const chipNotRequiredStyles = {
  border: '1px solid',
  borderColor: (theme: Theme) => theme.palette.strokes.light,
  cursor: 'pointer',

  '&:focus': {
    outline: 'none',
    borderColor: (theme: Theme) => theme.palette.strokes.info,
  },
  '&:hover, &:focus': {
    boxShadow:
      '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
  },
};

const chipInternalStyles = {
  padding: 1,
  fontWeight: '500',
  color: (theme: Theme) => theme.palette.text.secondary,
};

export function FieldSelectCheckboxTagCloud(props: FieldProps) {
  const [fieldMeta, formMeta] = useField(props.field.props.name, {
    formId: props.field.props.form,
  });

  const selectedValues =
    fieldMeta.value instanceof Array ? fieldMeta.value : [fieldMeta.value];

  const selectedValuesSet = new Set(selectedValues);

  const fieldByValue = props.field.fields.reduce(
    (fieldByValue, field) => {
      fieldByValue[field.input.props.value] = field;
      return fieldByValue;
    },
    {} as Record<string, FieldProps['field']['fields'][number]>,
  );

  const requiredFields = selectedValues
    .map((value) => fieldByValue[value])
    .filter((childField) => childField.input.props.required);
  const notRequiredSelectedFields = selectedValues
    .map((value) => fieldByValue[value])
    .filter((childField) => !childField.input.props.required);

  const notSelectedFields = props.field.fields.filter(
    (childField) => !selectedValuesSet.has(childField.input.props.value),
  );
  const searchFieldOptions = [
    ...notSelectedFields,
    ...requiredFields,
    ...notRequiredSelectedFields,
  ];

  const searchInputRef = useRef(null);

  const onKeyDownTagSelect = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key !== ' ' && event.key !== 'Enter') return;
    const fieldElement = document.activeElement;
    if (fieldElement instanceof HTMLElement) {
      fieldElement.click();
    }

    searchInputRef.current?.focus();
    event.preventDefault();
  };

  return (
    <Box
      component="fieldset"
      {...props.field.props}
      sx={{
        borderWidth: 0,
        padding: 0,
        margin: 0,
      }}
    >
      <Label field={props.field} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          flexDirection: 'column',
          padding: 2,
          borderRadius: '8px',
          border: '1px solid',
          borderColor: (theme) => theme.palette.strokes.default,
        }}
      >
        <Autocomplete
          fullWidth
          id={`${props.field.props.id}-search`}
          options={searchFieldOptions}
          autoHighlight
          autoComplete
          getOptionLabel={(field) => field.label.textContent}
          getOptionDisabled={(option) =>
            selectedValuesSet.has(option?.input.props?.value)
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option?.label.textContent}{' '}
              {selectedValuesSet.has(option?.input.props.value) &&
                '(selecionado)'}
            </Box>
          )}
          onChange={(event, selectedField) => {
            const newValue = [
              ...selectedValues,
              selectedField.input.props.value,
            ];

            requestAnimationFrame(() => {
              if (searchInputRef.current && 'value' in searchInputRef.current) {
                searchInputRef.current.value = '';
              }
            });

            formMeta.update({
              name: fieldMeta.name,
              value: newValue,
            });
          }}
          value={null}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              variant="standard"
              label=""
              placeholder="Procurar dado"
              sx={{
                padding: '0',
              }}
              InputProps={{
                ...params.InputProps,
                inputRef: searchInputRef,
                endAdornment: '',
                sx: {
                  padding: 0,
                },
                startAdornment: (
                  <Search
                    sx={{
                      height: '1em',
                      fill: (theme) => theme.palette.text.secondary,
                    }}
                  />
                ),
                disableUnderline: true,
                inputProps: {
                  ...params.inputProps,
                  autoComplete: 'off',
                },
              }}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          }}
        >
          {requiredFields.map((childField) => {
            return (
              'input' in childField && (
                <Box
                  {...childField.props}
                  sx={{
                    flex: '0 0 auto',
                  }}
                >
                  <Box
                    component="input"
                    {...childField.input.props}
                    sx={{
                      display: 'none',
                    }}
                  />
                  <Box
                    component="label"
                    tabIndex={-1}
                    onClick={(event) => event.preventDefault()}
                    sx={{
                      ...chipStyles,
                      ...chipRequiredStyles,
                    }}
                    {...childField.label.props}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        ...chipInternalStyles,
                      }}
                    >
                      {childField.label.textContent}
                    </Typography>
                  </Box>
                </Box>
              )
            );
          })}
          {notRequiredSelectedFields.map((childField) => {
            return (
              'input' in childField && (
                <Box
                  {...childField.props}
                  sx={{
                    flex: '0 0 auto',
                  }}
                >
                  <Box
                    component="input"
                    {...childField.input.props}
                    sx={{
                      display: 'none',
                    }}
                  />
                  <Box
                    component="label"
                    tabIndex={0}
                    onKeyDown={onKeyDownTagSelect}
                    sx={{
                      ...chipStyles,
                      ...chipNotRequiredStyles,
                    }}
                    {...childField.label.props}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        ...chipInternalStyles,
                      }}
                    >
                      {childField.label.textContent}
                    </Typography>
                    <Close
                      sx={{
                        height: '.57em',
                        fill: (theme) => theme.palette.text.secondary,
                      }}
                    />
                  </Box>
                </Box>
              )
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

FieldSelectCheckboxTagCloud.uiType = UI_TYPE.SELECT_CHECKBOX_TAG_CLOUD_SEARCH;

FieldSelectCheckboxTagCloud.canRender = (
  arg: TFieldRenderProps,
): arg is FieldProps['field'] => {
  if (!('fields' in arg)) return false;

  const hasEnoughOptions = arg.fields.length > 5;
  if (!hasEnoughOptions) return false;

  const isCheckboxSelect = arg.fields.reduce(
    (isCheckboxSelect, fieldOption) => {
      return (
        isCheckboxSelect &&
        'input' in fieldOption &&
        fieldOption.input.props.type === 'checkbox'
      );
    },
    true,
  );

  return isCheckboxSelect;
};
