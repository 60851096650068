import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

export function NewPasswordSuccess() {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap={4}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="h3"
          fontWeight="700"
          data-testid="new-password-success-title"
        >
          Senha alterada com sucesso!
        </Typography>
        <Typography variant="body2">
          Você já pode acessar sua conta com sua nova senha
        </Typography>
      </Box>
      <Button
        fullWidth
        color="primaryAlt"
        component={Link}
        to="/login"
        sx={{ p: 1.5 }}
        data-testid="back"
      >
        <Typography variant="body1" color="secondary" fontWeight="700">
          Fazer login
        </Typography>
      </Button>
    </Box>
  );
}
