import { Dialog } from '@mui/material';

import { ApprovalConfirmationModal } from './ApprovalConfirmationModal';

export function ApprovalModal({
  open,
  setOpen,
  organizationId,
  companyId,
  payrollId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  payrollId: string;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      <ApprovalConfirmationModal
        organizationId={organizationId}
        companyId={companyId}
        payrollId={payrollId}
        close={() => setOpen(false)}
      />
    </Dialog>
  );
}
