import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { OrganizationAvatar } from '../../../modules/components/OrganizationAvatar';

import { Stepper } from './components/Stepper';

type Props = {
  companyName: string;
  logoUrl: string;
  setCompleted: () => void;
};

export function WelcomeNewEmployee({
  companyName,
  logoUrl,
  setCompleted,
}: Props) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (steps: any[]) => {
    if (activeStep === steps.length - 1) {
      setCompleted();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const getSteps = () => [
    {
      description: (
        <span>
          Você foi convidado(a) para se cadastrar na plataforma da{' '}
          <strong>Tako</strong> pela empresa <strong>{companyName}</strong>.
        </span>
      ),
    },
    {
      description: (
        <span>
          A <strong>Tako</strong> é a plataforma escolhida para gerenciar
          processos de admissão, folha de pagamento, holerites e comunicação com
          a empresa <strong>{companyName}</strong>.
        </span>
      ),
    },
    {
      description: (
        <span>
          Nesta primeira etapa, crie seu cadastro para salvar suas informações e
          permitir que o departamento pessoal acompanhe sua jornada!
        </span>
      ),
    },
  ];

  const steps = getSteps();
  const currentStep = steps[activeStep];

  return (
    <Box
      sx={{
        maxWidth: 400,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        gap: 4,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        {' '}
        {logoUrl && (
          <OrganizationAvatar
            name={companyName}
            logo={logoUrl}
            size="110px"
            allowRectangularImage={true}
          />
        )}
      </Box>

      <Typography variant="body1">{currentStep.description}</Typography>

      <Button
        fullWidth
        color="primaryAlt"
        sx={{ p: 1.5 }}
        onClick={() => handleNext(steps)}
      >
        Próximo
      </Button>

      <Stepper
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </Box>
  );
}
