import React from 'react';

import { Box, Stepper as MUIStepper, Step } from '@mui/material';

type Props = {
  steps: any[];
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
};

export function Stepper({ steps, activeStep, setActiveStep }: Props) {
  return (
    <MUIStepper
      activeStep={activeStep}
      connector={null}
      sx={{ transition: 'all 2s' }}
    >
      {steps.map((_step, index) => (
        <Step key={index}>
          {index === activeStep ? (
            <Box
              sx={(theme) => ({
                width: 36,
                height: 8,
                background: theme.palette.text.primary,
                borderRadius: 4,
              })}
            />
          ) : (
            <Box
              sx={(theme) => ({
                width: 8,
                height: 8,
                background: theme.palette.strokes.heavy,
                borderRadius: '50%',
              })}
              onClick={() => setActiveStep(index)}
            />
          )}
        </Step>
      ))}
    </MUIStepper>
  );
}
