export function whenDefined<P, R>(fn: (arg: P) => R) {
  return (value: P | undefined): R | undefined =>
    !value ? undefined : fn(value);
}

export function unary<F, S, R>(
  fn: (firstArg: F, secondArg: S | undefined) => R,
) {
  return (f: F): R => fn(f, undefined);
}
