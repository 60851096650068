import { LocalDate } from '@js-joda/core';
import dayjs from 'dayjs';

import { useGetContract, useGetJobTitleEntry } from '@octopus/api';
import { getContractJobTitleId } from '@octopus/contract-types';
import { Municipios, Paises } from '@octopus/esocial/mapper';

import { formatOptionalDayjsDate } from '../../../../modules/formatters';
import { findNearestBankingDate } from '../../../../utils/dateUtils';

import {
  IVacationFormInputs,
  IVacationFormOutputsQueryResult,
  IVacationFormOverview,
} from './types';

export function useVacationFormOverview({
  inputs,
  outputsQueryResult,
}: {
  inputs: IVacationFormInputs;
  outputsQueryResult: IVacationFormOutputsQueryResult;
}): IVacationFormOverview {
  const getContractResult = useGetContract(
    {
      pathParams: {
        organizationId: inputs.organizationId,
        contractId: inputs.contractId,
      },
    },
    {
      enabled: !!inputs.organizationId && !!inputs.contractId,
    },
  );

  const jobTitleId = getContractJobTitleId(getContractResult.data);
  const getJobTitleEntryResult = useGetJobTitleEntry(
    {
      pathParams: {
        organizationId: inputs.organizationId,
        jobTitleId: jobTitleId,
      },
    },
    {
      enabled: !!inputs.organizationId && !!jobTitleId,
    },
  );

  const isLoadingQueries =
    getContractResult.isLoading ||
    getJobTitleEntryResult.isLoading ||
    outputsQueryResult.isFetching;

  const hasFetchErrors =
    !isLoadingQueries &&
    (getContractResult.isError || getJobTitleEntryResult.isError);

  if (isLoadingQueries && (!inputs.contractId || !inputs.employeeName)) {
    return {
      isLoading: true,
    };
  }

  if (hasFetchErrors) {
    return {
      isLoading: false,
      errors: [getContractResult.error, getJobTitleEntryResult.error].filter(
        Boolean,
      ),
      retry: () => window.location.reload(),
    };
  }

  const startDate = dayjs(inputs.startDate);

  const contractData = getContractResult.data;
  const jobTitleData = getJobTitleEntryResult.data;

  const employeeNameArg = inputs.employeeName;

  const startDateFromInputsFormatted = formatOptionalDayjsDate({
    date: startDate,
  });

  if (isLoadingQueries) {
    return {
      isLoading: true,
      data: {
        employeeName: employeeNameArg,
        startDate: startDateFromInputsFormatted,
      },
    };
  }

  const calculationOutputs = outputsQueryResult.data;

  const employeeName =
    contractData.br.pessoa.nmSoc ?? contractData.br.pessoa.nmTrab;

  const [city, state] = (() => {
    if (!contractData.br?.endereco) return [];
    if (contractData.br.endereco.tipo === 'brasil') {
      return [
        Municipios.getByCode(contractData.br?.endereco.codMunic) ?? '',
        contractData.br?.endereco.uf,
      ];
    } else {
      return [
        contractData.br?.endereco.nmCid,
        Paises.getByCode(contractData.br?.endereco.paisResid) ?? '',
      ];
    }
  })();

  const employeeLocation = city && state ? `${city} - ${state}` : '';

  const employeeUnderNameDetails = [
    jobTitleData?.name,
    employeeLocation,
  ].filter(Boolean);

  const feriasNoPrimeiroPeriodoDisponivel =
    calculationOutputs?.vacationInputsPreview?.br
      .feriasPorPeriodoAquisitivo?.[0];

  const isDescontarFaltasEnabled = inputs.discountLeaves || false;

  const dadosEspecificosDasFeriasSelecionadas = isDescontarFaltasEnabled
    ? feriasNoPrimeiroPeriodoDisponivel?.feriasQueTemDireito
    : feriasNoPrimeiroPeriodoDisponivel?.feriasMaximo;

  const { dataDeInicio, dataDeFim, dataLimiteParaPagamento } =
    dadosEspecificosDasFeriasSelecionadas ?? {};

  const startDateFromApi = dataDeInicio ? dayjs(dataDeInicio) : null;
  const startDateFromApiFormatted = formatOptionalDayjsDate({
    date: startDateFromApi,
  });

  const endDateFromApi = dataDeFim ? dayjs(dataDeFim) : null;
  const endDateFromApiFormatted = formatOptionalDayjsDate({
    date: endDateFromApi,
  });

  const nextPaymentDate = dataLimiteParaPagamento
    ? findNearestBankingDate(LocalDate.parse(dataLimiteParaPagamento))
    : null;
  const paymentDate = nextPaymentDate ? dayjs(nextPaymentDate) : null;
  const paymentDateFormatted = formatOptionalDayjsDate({ date: paymentDate });

  const anoDeAdiantamentoDoDecimoTerceiro = formatOptionalDayjsDate({
    date: paymentDate,
    format: 'YYYY',
  });

  const adiantouDecimoTerceiro =
    dadosEspecificosDasFeriasSelecionadas?.adiantouDecimoTerceiroNoAnoVigente;

  const periodoAquisitivo = {
    dataDeInicio: formatOptionalDayjsDate({
      date: dayjs(
        feriasNoPrimeiroPeriodoDisponivel?.periodoAquisitivo.dataDeInicio,
      ),
    }),
    dataDeFim: formatOptionalDayjsDate({
      date: dayjs(
        feriasNoPrimeiroPeriodoDisponivel?.periodoAquisitivo.dataDeFim,
      ),
    }),
  };

  const periodoConcessivo = {
    dataDeInicio: formatOptionalDayjsDate({
      date: dayjs(
        feriasNoPrimeiroPeriodoDisponivel?.periodoConcessivo.dataDeInicio,
      ),
    }),
    dataDeFim: formatOptionalDayjsDate({
      date: dayjs(
        feriasNoPrimeiroPeriodoDisponivel?.periodoConcessivo.dataDeFim,
      ),
    }),
    dataLimiteParaConcessao: formatOptionalDayjsDate({
      date: dayjs(
        feriasNoPrimeiroPeriodoDisponivel?.periodoConcessivo
          .dataLimiteParaConcessao,
      ),
    }),
  };

  const qtDiasDeAbono = inputs.diasDeAbono;

  const qtDiasDisponiveis =
    dadosEspecificosDasFeriasSelecionadas?.qtDiasTotalDisponiveis;
  const qtDiasDisponiveisAposGozo =
    dadosEspecificosDasFeriasSelecionadas?.qtDiasTotalDisponiveisAposGozo;
  const qtDiasDeFaltasInjustificadas =
    feriasNoPrimeiroPeriodoDisponivel?.periodoAquisitivo
      .qtDiasDeFaltasInjustificadas;

  const result: IVacationFormOverview = {
    isLoading: false,
    data: {
      employeeName,
      employeeUnderNameDetails,
      startDate: startDateFromApiFormatted,
      endDate: endDateFromApiFormatted,
      paymentDate: paymentDateFormatted,
      feriasPorPeriodo: [
        {
          anoDeAdiantamentoDoDecimoTerceiro,
          adiantouDecimoTerceiro,
          periodoAquisitivo,
          periodoConcessivo,
          qtDiasDeAbono,
          qtDiasDisponiveis,
          qtDiasDisponiveisAposGozo,
          qtDiasDeFaltasInjustificadas: qtDiasDeFaltasInjustificadas,
        },
      ],
    },
  };

  return result;
}
