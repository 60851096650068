import { useMemo } from 'react';

import { Submission } from '@conform-to/react';

import { Box } from '@mui/material';

import { IFormDefinition } from '@octopus/libs/forms';

import { FormFields } from '../../../modules/form';
import { FormFromLayout } from '../../../modules/form/FormFromLayout';
import { Form } from '../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../modules/form/useFormFromDefinition';

type Props = {
  definition: IFormDefinition;
  id: string;
  onSubmit: (
    _event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => void;
};

export const AdmissionReviewDocumentsForm = ({
  definition,
  id,
  onSubmit,
}: Props) => {
  const fullForm = useFormFromDefinition(definition, {
    id,
    onSubmit,
    persistLocal: true,
    useNewParser: true,
    shouldValidate: 'onBlur',
  });

  const form = useMemo(
    () => ({
      payloadForm: fullForm.payloadForm,
      metaForm: fullForm.metaForm,
      id: fullForm.id,
      fieldsRenderOptions: fullForm.fieldsRenderOptions,
    }),
    [
      fullForm.id,
      fullForm.payloadForm,
      fullForm.metaForm,
      fullForm.fieldsRenderOptions,
    ],
  );

  return (
    <Form payloadForm={form.payloadForm} metaForm={form.metaForm}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          width: '100%',
          [theme.breakpoints.down('md')]: {
            height: `calc(100svh - ${theme.spacing(36)})`,
            overflow: 'scroll',
            '::-webkit-scrollbar': { display: 'none' },
          },
        })}
      >
        <FormFromLayout
          fields={FormFields({
            fields: form.fieldsRenderOptions,
            formId: form.id,
          })}
          fieldsetInline={false}
        />
      </Box>
    </Form>
  );
};
