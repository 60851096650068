export const getNextButtonLabel = ({
  draftId,
  draftStatus,
  currentStepName,
  isWorkerExperience,
  isLastStep,
  isSmallDevice,
}: {
  draftId: string;
  draftStatus: string;
  currentStepName: string;
  isWorkerExperience: boolean;
  isLastStep: boolean;
  isSmallDevice: boolean;
}) => {
  if (draftId != null) {
    if (isWorkerExperience && isLastStep && !isSmallDevice)
      return 'Enviar para o departamento pessoal';

    if (draftStatus === 'admission_submitted') {
      switch (currentStepName) {
        case 'finaliza_admissao':
          return 'Salvar revisão';
        default:
          return 'Salvar e avançar';
      }
    }
  }

  return 'Avançar';
};
