import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, Button, Typography, useTheme } from '@mui/material';

import {
  VacationsScheduleEntry,
  fetchDeleteVacationsSchedule,
  fetchGetSentPayslip,
  fetchGetVacationsSchedule,
} from '@octopus/api';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { BackButton } from '../../../../../modules/components/BackButton';
import { CancelConfirmationDialog } from '../../../../../modules/components/vacation-scheduler/dialogs';
import VacationsRequestedCard from '../../../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { LoadingScene } from '../utils/RequestVacationLoadingScene';
import { canCancelRequestStatuses } from '../utils/types';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function VacationRequestDetailsPage({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { contractId, sequence } = useParams();
  const [vacationRequest, setVacationRequest] =
    useState<VacationsScheduleEntry>(null);
  const [vacationPayslipLink, setVacationPayslipLink] = useState<string>(null);

  const [showCancelRequestDialog, setShowCancelRequestDialog] =
    useState<boolean>(false);

  const vacationsScheduledQuery = useQuery({
    queryKey: [organizationId, contractId, sequence, 'details'],
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    queryFn: () => {
      return fetchGetVacationsSchedule({
        pathParams: {
          organizationId: organizationId,
          contractId: contractId,
          sequence: sequence,
        },
      });
    },
    enabled: !!organizationId,
  });

  const vacationsPaySlipQuery = useQuery({
    queryKey: [organizationId, contractId, sequence, 'payslip'],
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    queryFn: () => {
      return fetchGetSentPayslip({
        pathParams: {
          organizationId: organizationId,
          contractId: vacationRequest.contractId,
          payrollId: vacationRequest.payrollId,
        },
      });
    },
    enabled:
      vacationRequest?.status === vacationsScheduleStatuses.payrollApproved,
  });

  useEffect(() => {
    if (vacationsScheduledQuery.data) {
      setVacationRequest(vacationsScheduledQuery.data);
    }
  }, [vacationsScheduledQuery.data, sequence]);

  useEffect(() => {
    if (
      vacationsPaySlipQuery.data &&
      vacationsPaySlipQuery.data.status === 'sent'
    ) {
      setVacationPayslipLink(
        `/receipts/${vacationsPaySlipQuery.data.companyId}/${vacationsPaySlipQuery.data.contractId}/${vacationsPaySlipQuery.data.payrollId}`,
      );
    }
  }, [vacationsPaySlipQuery.data]);

  if (vacationRequest === null) {
    return <LoadingScene title="Carregando detalhes do pedido" />;
  }

  function shouldShowDisclaimer(
    vacationRequest: VacationsScheduleEntry,
  ): boolean {
    return (
      ![...canCancelRequestStatuses, 'rejected', 'canceled'].includes(
        vacationRequest.status,
      ) && dayjs().isBefore(vacationRequest.startDate)
    );
  }

  function shouldShowCancelButton(vacationRequest: VacationsScheduleEntry) {
    return canCancelRequestStatuses.includes(vacationRequest.status);
  }
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.secondary,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        height: '100%',
      }}
      height={'100%'}
    >
      <BackButton position="static" />
      <Box sx={{ px: '20px', pt: 1, pb: 3 }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
          Solicitação de férias
        </Typography>
      </Box>
      <Box
        data-testid={`vacation-request-type-header`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: '20px',
          gap: 2,
        }}
      >
        <VacationsRequestedCard
          scheduleSummary={vacationRequest}
          detailsMode={true}
          receiptLink={vacationPayslipLink}
        />
        {shouldShowDisclaimer(vacationRequest) ? (
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '12px', fontWeight: 450, textAlign: 'center' }}
          >
            {`Não é possível cancelar este agendamento de férias.
          Caso necessário, entre em contato com seu Departamento Pessoal.`}
          </Typography>
        ) : null}
        {shouldShowCancelButton(vacationRequest) ? (
          <Button
            variant="text"
            onClick={() => {
              setShowCancelRequestDialog(true);
            }}
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignContent: 'flex-end',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: 3,
              color: 'error.lowEmphasis',
            }}
          >
            Cancelar solicitação
          </Button>
        ) : null}
      </Box>
      <CancelConfirmationDialog
        open={showCancelRequestDialog}
        action={() =>
          fetchDeleteVacationsSchedule({
            body: { version: vacationRequest.version },
            pathParams: {
              organizationId: organizationId,
              contractId: contractId,
              sequence: sequence,
            },
          })
        }
        onConfirmation={() => {
          navigate(`/vacations/${contractId}/scheduler`);
        }}
        setOpen={setShowCancelRequestDialog}
      />
    </Box>
  );
}

export default VacationRequestDetailsPage;
