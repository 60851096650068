const WAITING_TIME = 10 * 60 * 1000;
const INTERVAL_TIME = 10 * 1000;

async function request(
  authToken: string,
  url: string,
  method: string,
): Promise<Response> {
  const headers: HeadersInit = {
    Authorization: `Bearer ${authToken}`,
  };

  return await window.fetch(url, {
    redirect: 'manual',
    method: method,
    headers,
  });
}

function loop(
  resolve: (value?: unknown) => void,
  reject: (reason?: unknown) => void,
  authToken: string,
  url: string,
  stopTime: number,
) {
  setTimeout(async function () {
    const response = await request(authToken, url, 'GET');
    if (response.status === 200) {
      const { signedUrl } = await response.json();
      window.location.href = signedUrl;
      return resolve();
    }

    if (response.status !== 204) {
      return reject('Failed to download spreadsheet');
    }

    if (stopTime > Date.now()) {
      return loop(resolve, reject, authToken, url, stopTime);
    }

    reject('Failed to download spreadsheet: timeout');
  }, INTERVAL_TIME);
}

export async function download(
  authToken: string,
  organizationId: string,
  type: string,
  params?: Record<string, string>,
) {
  let url = `/api/organizations/${organizationId}/spreadsheets/${type}`;
  if (params) {
    url = `${url}?${new URLSearchParams(params).toString()}`;
  }

  const response = await request(authToken, url, 'POST');

  if (response.status !== 200) {
    throw new Error('Failed to download spreadsheet');
  }

  const { fileUrl } = await response.json();
  const { companyId, legalEntityId } = params || {};
  const stopTime = Date.now() + WAITING_TIME;

  if (companyId && legalEntityId) {
    return new Promise((resolve, reject) =>
      loop(
        resolve,
        reject,
        authToken,
        `${fileUrl}?companyId=${companyId}&legalEntityId=${legalEntityId}`,
        stopTime,
      ),
    );
  }

  return new Promise((resolve, reject) =>
    loop(resolve, reject, authToken, fileUrl, stopTime),
  );
}

export async function downloadOriginalFile(
  authToken: string,
  organizationId: string,
  type: string,
  fileName: string,
) {
  const url = `/api/organizations/${organizationId}/spreadsheets/${type}/origin/uploaded/${fileName}`;

  const stopTime = Date.now();

  return new Promise((resolve, reject) =>
    loop(resolve, reject, authToken, url, stopTime),
  );
}
