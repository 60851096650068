import { NEW_UI_TYPE } from '@octopus/libs/forms';

import * as FieldComponents from './Field';
import { FieldSelectWithAutoComplete } from './Field/FieldSelectWithAutocomplete';
// eslint-disable-next-line import/no-cycle
import * as FieldsetComponents from './Fieldset';
import { TFormRenderPropOptions } from './parseField/types';

const Components = Object.assign({}, FieldComponents, FieldsetComponents);

export function FormFields(props: {
  fields: TFormRenderPropOptions;
  isInFieldset?: boolean;
  formId?: string;
}) {
  const { fields } = props;
  const FieldsToRender = fields.map(([_fieldName, ...fieldDataOptions]) => {
    const fieldData = fieldDataOptions?.[0];
    if ('uiType' in fieldData) {
      switch (fieldData.uiType) {
        case NEW_UI_TYPE.SELECT_AUTOCOMPLETE: {
          return [
            fieldData.fieldsetName,
            /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
            <FieldSelectWithAutoComplete
              key={fieldData.props.key}
              field={fieldData as any}
              formId={props.formId!}
            />,
          ] as [string, React.ReactElement];
        }
        default:
          break;
      }
    }

    const toRenderUiMatchFieldJSX = Object.values(Components).reduce(
      (toRenderUiMatchFieldJSX, FieldComponent) => {
        if (toRenderUiMatchFieldJSX) return toRenderUiMatchFieldJSX;
        if (!('uiType' in FieldComponent)) return toRenderUiMatchFieldJSX;
        const fieldData = fieldDataOptions.find(
          (fieldData) =>
            FieldComponent.uiType === fieldData.uiType &&
            FieldComponent.canRender(fieldData),
        );
        if (!fieldData) return toRenderUiMatchFieldJSX;
        return [
          fieldData.fieldsetName,
          /*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
          <FieldComponent key={fieldData.props.key} field={fieldData as any} />,
        ] as [string, React.ReactElement];
      },
      undefined as [string, React.ReactElement] | undefined,
    );

    const toRenderFieldJSX =
      toRenderUiMatchFieldJSX ||
      Object.values(Components).reduce(
        (toRenderFieldJSX, FieldComponent) => {
          if (toRenderFieldJSX) return toRenderFieldJSX;
          const fieldOptionToRender = fieldDataOptions.find((field) =>
            FieldComponent.canRender(field),
          );

          if (!fieldOptionToRender) {
            console.error(
              'cannot render field:',
              JSON.stringify(fieldDataOptions?.[0]),
              fieldDataOptions[0]?.fieldsetName,
            );
            return toRenderFieldJSX;
          }

          return [
            fieldOptionToRender.fieldsetName,
            <FieldComponent
              key={fieldOptionToRender.props.key}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              field={fieldOptionToRender as any}
            />,
          ] as const;
        },
        undefined as [string, React.ReactElement] | undefined,
      );
    return toRenderFieldJSX;
  });

  return FieldsToRender;
}
