import { Box, Typography, TypographyProps } from '@mui/material';

export type Props = {
  type: 'termination' | 'complementaryTermination';
  TypographyProps?: TypographyProps;
};

export function TerminationTypeBadge({ type, TypographyProps = {} }: Props) {
  const { color, bgcolor, label } = stylizeColors(type);
  const props: TypographyProps = {
    display: 'inline-block',
    borderRadius: '4px',
    variant: 'body2',
    fontWeight: '500',
    px: 1,
    py: 0.5,
    color,
    bgcolor,
    ...TypographyProps,
  };
  return (
    <Box>
      <Typography {...props}>{label}</Typography>
    </Box>
  );
}

function stylizeColors(type: string) {
  if (type === 'Regular') {
    return {
      color: 'text.secondary',
      bgcolor: 'background.default',
      label: type,
    };
  }

  return {
    color: 'warning.main',
    bgcolor: 'background.warning',
    label: type,
  };
}
