import { ContractBRPjEntryContrato } from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';

import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type DuracaoDoContratoRecordData = {
  contrato: ContractBRPjEntryContrato;
};

type DuracaoDoContratoRecordProps =
  BaseRecordProps<DuracaoDoContratoRecordData>;

export function DuracaoDoContratoRecord(props: DuracaoDoContratoRecordProps) {
  const { contrato } = props.data;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (key: string) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        contrato: {
          ...data.contrato,
          [key]: value,
        },
      }));
  };

  return (
    <Record title="Duração do contrato" edit={editRecordProps}>
      <RecordEntry
        label="Assinado em"
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: formData.contrato.dataAssinatura,
          onChange: prepareOnChange('dataAssinatura'),
          hasError: hasError('br/contrato/dataAssinatura'),
        }}
      >
        {formatDate(contrato.dataAssinatura)}
      </RecordEntry>
      <RecordEntry
        label="Validade"
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: formData.contrato.validade,
          onChange: prepareOnChange('validade'),
          hasError: hasError('br/contrato/validade'),
        }}
      >
        {formatDate(contrato.validade)}
      </RecordEntry>
      <RecordEntry
        label="Renovação"
        edit={{
          type: 'options',
          editing,
          value: formData.contrato.renovacao ?? 'automatica',
          onChange: prepareOnChange('renovacao'),
          options: [
            {
              label: 'Automática',
              value: 'automatica',
            },
            {
              label: 'Manual',
              value: 'manual',
            },
          ],
          hasError: hasError('br/contrato/renovacao'),
        }}
      >
        {contrato.renovacao !== 'automatica' ? 'Manual' : 'Automática'}
      </RecordEntry>
      <RecordEntry
        label="Data de início do contrato"
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: formData.contrato.inicio,
          onChange: prepareOnChange('inicio'),
          hasError: hasError('br/contrato/inicio'),
        }}
      >
        {formatDate(contrato.inicio)}
      </RecordEntry>
      <RecordEntry
        label="Data de término do contrato"
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: formData.contrato.termino,
          onChange: prepareOnChange('termino'),
          disabled: true,
          hasError: hasError('br/contrato/termino'),
        }}
      >
        {formatDate(contrato.termino)}
      </RecordEntry>
    </Record>
  );
}

function formatDate(date: string | undefined) {
  if (!date) {
    return undefined;
  }
  return formatDateBR(date);
}
