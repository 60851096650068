import { WorkerCategory, workerCategoryLabels } from '@octopus/contract-types';
import { Tag } from '@octopus/ui/design-system';

const configByStatus: Partial<
  Record<WorkerCategory, { color: 'info' | 'default' }>
> = {
  'clt:geral': {
    color: 'info',
  },
  'clt:estagiario': {
    color: 'default',
  },
  pj: {
    color: 'default',
  },
};

export default function WorkerCategoryTag({
  workerCategory,
}: {
  workerCategory: WorkerCategory;
}) {
  return (
    <Tag color={configByStatus[workerCategory]?.color ?? 'info'}>
      {workerCategoryLabels[workerCategory] ?? 'Colaborador'}
    </Tag>
  );
}
