import { TFlagNameSetup } from './TFlagNameSetup';
export type TFFlagsProviderProps = React.PropsWithChildren;
export type TFFlagValue = boolean;
export type TFFlagsContext<TFlagName extends string = ''> = {
  onPush: (cb: () => void) => void;
  push: (fflags: Partial<Record<TFlagName, TFFlagValue>>) => void;
  pushError: (error: unknown) => void;
  pull: () => Promise<Partial<Record<TFlagName, TFFlagValue>>>;
};

export function createFFlagsContext<const TFlagName extends string>(
  _flagNames: TFlagNameSetup<TFlagName>,
) {
  type TFFlagsInstance = Partial<Record<TFlagName, TFFlagValue>>;

  let flagsPushPromiseResolve: (value: TFFlagsInstance) => void;
  let flagsPushPromiseReject: (reason: unknown) => void;
  let flagsPushPromise = new Promise<TFFlagsInstance>((resolve, reject) => {
    flagsPushPromiseResolve = resolve;
    flagsPushPromiseReject = reject;
  });

  const pushCallbacks: Array<() => void> = [];
  const onPushFFlags = (cb: () => void) => {
    cb();
    pushCallbacks.push(cb);
  };

  const pushNewFFlags = (newFflags: TFFlagsInstance) => {
    flagsPushPromise = new Promise<TFFlagsInstance>((resolve, reject) => {
      flagsPushPromiseResolve = resolve;
      flagsPushPromiseReject = reject;
    });
    flagsPushPromiseResolve({ ...newFflags });
    pushCallbacks.forEach((cb) => cb());
  };

  const pushFFlagsError = (error: unknown) => {
    flagsPushPromiseReject(error);
  };

  const pullCurrentFFlags = () => {
    return flagsPushPromise;
  };

  const flagsContext: TFFlagsContext<TFlagName> = {
    onPush: onPushFFlags,
    push: pushNewFFlags,
    pushError: pushFFlagsError,
    pull: pullCurrentFFlags,
  };

  function FFlagsProvider(props: TFFlagsProviderProps) {
    return props.children;
  }

  return {
    FFlagsProvider,
    flagsContext,
  };
}
