import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { NaturezaEstagio, NivelEstagio } from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  UI_TYPE,
  cnpjSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../../types';

import { getDtPrevTermSchema } from './dtPrevTermSchema';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const {
    areaAtuacao,
    nrApol,
    natEstagio,
    nivEstagio,
    cnpjAgntInteg,
    cnpjInstEnsino,
    dtPrevTerm,
    admissionDate
  } = formState;

  return [
    {
      label: 'Natureza do estágio',
      type: z.string(),
      name: 'natEstagio',
      uiType: UI_TYPE.RADIO,
      options: getOptionsFromMapper({
        mapper: NaturezaEstagio,
        currentValue: natEstagio,
      }),
    },
    {
      label: 'Data prevista de término',
      type: getDtPrevTermSchema(admissionDate),
      name: 'dtPrevTerm',
      uiType: UI_TYPE.DATE_PICKER,
      placeholder: '00/00/0000',
      value: dtPrevTerm,
    },
    {
      label: 'Nível do estágio',
      type: z.string(),
      name: 'nivEstagio',
      uiType: UI_TYPE.SELECT,
      options: getOptionsFromMapper({
        mapper: NivelEstagio,
        currentValue: nivEstagio,
      }),
    },
    {
      label: 'Área de atuação',
      placeholder: 'Digite a área de atuação',
      type: z.string().min(1).max(100).optional(),
      name: 'areaAtuacao',
      uiType: UI_TYPE.TEXT,
      value: areaAtuacao,
    },
    {
      label: 'Número da apólice de seguro',
      placeholder: 'Digite número da apólice',
      type: z.string().min(1).max(30).optional(),
      name: 'nrApol',
      uiType: UI_TYPE.TEXT,
      value: nrApol,
    },
    {
      label: 'CNPJ do agente de integração',
      placeholder: '00.000.000/0000-00',
      mask: 'cnpj',
      type: cnpjSchema,
      name: 'cnpjAgntInteg',
      uiType: UI_TYPE.TEXT,
      value: cnpjAgntInteg,
    },
    {
      label: 'CNPJ da Instituição de ensino',
      placeholder: '00.000.000/0000-00',
      mask: 'cnpj',
      type: cnpjSchema,
      name: 'cnpjInstEnsino',
      uiType: UI_TYPE.TEXT,
      value: cnpjInstEnsino,
    },
  ];
};

export const getEstagioFields = ({
  formState,
  estagioFormSteps,
}: {
  formState: AdmissionFormState;
  estagioFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
  }),
  options: {
    id: admissionDraftFormSteps.estagio_info,
    title: 'Informações sobre o estágio',
    completed: estagioFormSteps?.completed,
    reviewed: estagioFormSteps?.reviewed,
  },
});
