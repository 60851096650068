import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { fetchQueryPayrollCalculationOutputs } from '@octopus/api';

import { parseFormPayloadToPayrolInput } from '../adapters/parseFormPayloadToPayrolInput';

import { IVacationFormInputs } from './types';

export function useQueryVacationFormOutputs({
  inputs,
}: {
  inputs: IVacationFormInputs;
}) {
  const startDateFromInputs = dayjs(inputs.startDate);
  const startDateApiFormatted = startDateFromInputs.format('YYYY-MM-DD');

  const paymentDateFromInputs = dayjs(inputs.paymentDate);
  const paymentDateApiFormatted = paymentDateFromInputs.format('YYYY-MM-DD');

  const key = [
    inputs.companyId,
    inputs.organizationId,
    inputs.contractId,
    startDateApiFormatted,
    inputs.diasDeFerias ?? 0,
    inputs.diasDeAbono ?? 0,
    inputs.anticipate13th,
    paymentDateApiFormatted,
  ];

  const getCalculationOutputsResult = useQuery({
    queryKey: key,
    queryFn: () => {
      const payload = parseFormPayloadToPayrolInput(
        inputs,
        'vacationInputsPreview',
      );
      return fetchQueryPayrollCalculationOutputs(payload);
    },
    enabled: [
      inputs.organizationId,
      inputs.companyId,
      inputs.contractId,
      inputs.anticipate13th,
      inputs.diasDeAbono,
      paymentDateApiFormatted,
    ].reduce(
      (arePropsValid: boolean, prop) => arePropsValid && prop !== undefined,
      true,
    ),
  });

  return getCalculationOutputsResult;
}
