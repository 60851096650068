import { useEffect, useMemo, useState } from 'react';

import { FormMetadata, Submission } from '@conform-to/react';

import { Box, Button, useMediaQuery, useTheme } from '@mui/material';

import { dependentAttachmentTypes } from '@octopus/onboarding-types';

import { FormFields } from '../../../../modules/form';
import { FormFromLayout } from '../../../../modules/form/FormFromLayout';
import { Form } from '../../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../../modules/form/useFormFromDefinition';
import { UploadAdmissionAttachment } from '../../components/UploadAdmissionAttachment';
import { AdmissionFile } from '../../utils/types';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { DocumentHelperDialog } from './DocumentHelperDialog';
import { getDefinition } from './form/formStepInputs';
import { Dependent } from './form/types';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  currentDependente: Dependent;
  organizationId: string;
  companyId: string;
  draftId: string;
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => void;
  setCurrentDependent: (dependent: Dependent) => void;
  setForm: (
    form: FormMetadata<
      {
        [k: string]: any;
      },
      string[]
    >,
  ) => void;
  hideFileComponent?: boolean;
  formId: string;
};

export const NewAdmissionDependentForm = ({
  disabled,
  isLoading,
  formState,
  stepDefinition,
  currentDependente,
  organizationId,
  companyId,
  onSubmit,
  draftId,
  setForm,
  setCurrentDependent,
  formId,
  hideFileComponent = false,
}: Props) => {
  const [dependente, setDependente] = useState<Dependent>(currentDependente);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [openDocumentHelperDialog, setOpenDocumentHelperDialog] =
    useState<boolean>(false);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const definition = getDefinition(currentDependente);
  const fullForm = useFormFromDefinition(definition, {
    id: formId,
    onSubmit: onSubmit,
    persistLocal: true,
    shouldValidate: 'onBlur',
  });

  const form = useMemo(
    () => ({
      payloadForm: fullForm.payloadForm,
      metaForm: fullForm.metaForm,
      id: fullForm.id,
      fieldsRenderOptions: fullForm.fieldsRenderOptions,
      loadState: fullForm.loadState,
    }),
    [fullForm],
  );

  useEffect(() => {
    setForm(form.payloadForm);
  }, [form.payloadForm, setForm]);

  useEffect(() => {
    if (dependente?.id !== currentDependente?.id) {
      setDependente(currentDependente);
      form.loadState(currentDependente);
    }
  }, [form, dependente, currentDependente]);

  if (!formState || !stepDefinition) {
    return null;
  }

  return (
    <>
      <Form payloadForm={form.payloadForm} metaForm={form.metaForm}>
        <FormFromLayout
          fields={FormFields({
            fields: form.fieldsRenderOptions,
            formId: form.id,
          })}
          fieldsetInline={false}
        />
      </Form>

      {!hideFileComponent && (
        <Box sx={{ my: 2 }}>
          <UploadAdmissionAttachment
            dependentId={dependente?.id}
            setFileCallback={(file) => {
              const dep = { ...dependente, file };
              setDependente(dep);
              setCurrentDependent(dep);
            }}
            deleteFileCallback={(_fileId) => {
              const dep = { ...dependente, file: null as AdmissionFile };
              setDependente(dep);
              setCurrentDependent(dep);
            }}
            disabled={disabled || isLoading}
            isLoading={isLoadingFile}
            setIsLoading={setIsLoadingFile}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            allowedTypes={dependentAttachmentTypes}
            title="Documento"
            description="Preferencialmente RG ou CNH que contenha o numero do CPF."
            required={true}
            formStep="dependentes"
          />
        </Box>
      )}

      <Box
        sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 3 }}
      >
        <Button
          variant="text"
          color="info"
          onClick={() => setOpenDocumentHelperDialog(true)}
        >
          Quais arquivos devo enviar?
        </Button>
        <DocumentHelperDialog
          open={openDocumentHelperDialog}
          setOpen={setOpenDocumentHelperDialog}
          isSmallDevice={isSmallDevice}
        />
      </Box>
    </>
  );
};
