import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { CBOs } from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  nonEmptyString,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import {
  getOptionsFromCompanyContext,
  getOptionsFromMapper,
} from '../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../types';

import { cargoConfiancaSchema } from './profissionalSchema';

const getCargoConfianca = ({
  hideCargoConfianca,
  formState,
}: {
  hideCargoConfianca: boolean;
  formState: AdmissionFormState;
}) => {
  if (hideCargoConfianca) return undefined;

  const { cargoConfianca } = formState;

  return {
    label: 'É cargo de confiança?',
    type: cargoConfiancaSchema,
    name: 'cargoConfianca',
    uiType: UI_TYPE.SELECT,
    fieldsOptions: [
      {
        label: 'Não',
        selected:
          cargoConfianca?.enabled == null ||
          cargoConfianca?.enabled === 'false',
        fields: [
          {
            label: '-',
            hidden: true,
            type: nonEmptyString,
            name: 'disabled',
            value: 'true',
            uiType: UI_TYPE.TEXT,
          },
        ],
      },
      {
        label: 'Sim',
        selected: cargoConfianca?.enabled === 'true',
        fields: [
          {
            label: '-',
            hidden: true,
            type: nonEmptyString,
            name: 'enabled',
            value: 'true',
            uiType: UI_TYPE.TEXT,
          },
          {
            label: 'CBO da Função',
            type: nonEmptyString,
            name: 'CBOFuncao',
            uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
            placeholder: 'Selecione uma função',
            options: getOptionsFromMapper({
              mapper: CBOs,
              currentValue: cargoConfianca?.CBOFuncao,
              showKey: true,
            }),
          },
          {
            label: 'Nome da Função',
            type: nonEmptyString,
            name: 'nmFuncao',
            uiType: UI_TYPE.TEXT,
            value: cargoConfianca?.nmFuncao || '',
          },
        ],
      },
    ],
  };
};

const getFieldDefinition = ({
  companyContext,
  formState,
  hideCargoConfianca,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  hideCargoConfianca: boolean;
}): IFormDefinition => {
  const {
    workerId,
    emailCorp,
    matricula,
    centro_de_custo,
    departamento,
    cargo,
  } = formState;

  const cargoConfianca = getCargoConfianca({ hideCargoConfianca, formState });

  return [
    {
      label: 'Matrícula',
      type: nonEmptyString,
      name: 'workerId',
      uiType: UI_TYPE.TEXT,
      placeholder: '000000',
      value: workerId || '',
    },
    {
      label: 'Matrícula do eSocial',
      type: nonEmptyString,
      name: 'matricula',
      uiType: UI_TYPE.TEXT,
      placeholder: '000000',
      value: matricula || '',
    },
    {
      label: 'Cargo',
      type: nonEmptyString,
      name: 'cargo',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Selecione um cargo',
      options: getOptionsFromCompanyContext({
        baseContext: companyContext?.jobTitles,
        currentValue: cargo,
      }),
    },
    cargoConfianca,
    {
      label: 'Departamento',
      type: nonEmptyString,
      name: 'departamento',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Nome do departamento',
      value: departamento || '',
    },
    {
      label: 'Centro de custo',
      type: nonEmptyString,
      name: 'centro_de_custo',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione um centro de custo',
      options: getOptionsFromCompanyContext({
        baseContext: companyContext?.costCenters,
        currentValue: centro_de_custo,
      }),
    },
    {
      label: 'Email corporativo',
      type: z.string().email().optional(),
      name: 'emailCorp',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: exemplo@empresa.com.br',
      value: emailCorp || '',
    },
  ].filter((item) => item);
};

export const getProfissionalFields = ({
  companyContext,
  formState,
  profissionalFormSteps,
  hideCargoConfianca = false,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  profissionalFormSteps: AdmissionDraftInputFormStep;
  hideCargoConfianca?: boolean;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    companyContext,
    formState,
    hideCargoConfianca,
  }),
  options: {
    id: admissionDraftFormSteps.profissional,
    title: 'Informações profissionais',
    completed: profissionalFormSteps?.completed,
    reviewed: profissionalFormSteps?.reviewed,
  },
});
