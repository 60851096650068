import {
  ContractBRCltTipoDependente,
  PayrollInputsPayloadTypes,
  PayrollInputsTags,
} from '@octopus/api';

export const EMPLOYEE_COLUMN_ID = 'employee';
export const DEPENDENT_COLUMN_ID = 'dependent';

export type PayrollEmployeeData = {
  payrollId: string;
  contractId: string;
  personId: string;
  name: string;
  employeeId: string;
  inputs: {
    [key: string]: string;
  };
};

export type PayrollDependentData = PayrollEmployeeData & {
  dependentId: string;
  nmDependent: string;
  tpDependent: ContractBRCltTipoDependente;
};

export type ColumnInfo = {
  colId: string;
  label: string;
  tag: PayrollInputsTags;
  payloadType: PayrollInputsPayloadTypes;
};

export type RowInfo = {
  rowIndex: number;
  name: string;
  employeeId: string;
  contractId: string;
  personId: string;
  inputCount: number;
};
