import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

export type ArrowNavigation = {
  canGoBackwards: boolean;
  canGoForward: boolean;
  goBackwards: () => void;
  goForward: () => void;
};

export function Navigator({
  label,
  arrowNavigation,
  arrowsDirection,
  iconSize,
  noPadding,
}: {
  label?: string | undefined;
  arrowNavigation: ArrowNavigation;
  arrowsDirection: 'vertical' | 'horizontal';
  iconSize: 'small' | 'medium' | 'large';
  noPadding?: boolean;
}) {
  const { canGoBackwards, canGoForward, goBackwards, goForward } =
    arrowNavigation;

  const isHorizontal = arrowsDirection === 'horizontal';
  const GoBackArrow = isHorizontal ? ArrowBackIosOutlined : KeyboardArrowUp;
  const GoForwardArrow = isHorizontal
    ? ArrowForwardIosOutlined
    : KeyboardArrowDown;

  const size = isHorizontal ? 36 : 32;

  return (
    <Box py={1} px={noPadding ? 0 : 3} display="flex">
      <Button
        color="secondary"
        size={iconSize}
        disabled={!canGoBackwards}
        onClick={goBackwards}
        sx={[
          (theme) => ({
            p: 1,
            borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
            minWidth: size,
            height: size,
          }),
        ]}
      >
        <GoBackArrow
          fontSize={iconSize}
          color={canGoBackwards ? 'primary' : 'disabled'}
        />
      </Button>
      {isHorizontal && label && (
        <Box
          sx={{
            flexGrow: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid',
            borderColor: 'strokes.default',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <Typography
            variant="body2"
            sx={(theme) => ({
              color:
                canGoForward || canGoBackwards
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
            })}
          >
            {label}
          </Typography>
        </Box>
      )}
      <Button
        color="secondary"
        size={iconSize}
        disabled={!canGoForward}
        onClick={goForward}
        sx={[
          (theme) => ({
            p: 1,
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
            minWidth: size,
            height: size,
          }),
        ]}
      >
        <GoForwardArrow
          fontSize={iconSize}
          color={canGoForward ? 'primary' : 'disabled'}
        />
      </Button>
    </Box>
  );
}
