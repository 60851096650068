import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import {
  PayrollTypes,
  fetchGetPayrollInputsSubmissionDetails,
} from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { useInterval } from '../../../../../../modules/hooks/useInterval';
import { PeriodFormat } from '../../../../../../modules/types';

export type PayrollInputsSubmissionProps = {
  organizationId: string;
  companyId: string;
};

export function PayrollInputsSubmission({
  organizationId,
  companyId,
}: PayrollInputsSubmissionProps) {
  const navigate = useNavigate();
  const { period, type, submissionId } = useParams<{
    period: PeriodFormat;
    type: PayrollTypes;
    submissionId: string;
  }>();

  const evaluateSubmissionStatus = async () => {
    const { status } = await fetchGetPayrollInputsSubmissionDetails({
      pathParams: {
        organizationId,
        companyId,
        periodId: period,
        payrollType: type,
        submissionId,
      },
    });
    if (status === 'success' || status === 'failure') {
      navigate(`/payrolls/${period}/${type}`, {
        state: { submission: status },
      });
    }
  };

  useInterval(evaluateSubmissionStatus, 5000);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
        position="relative"
        top="-50px"
      >
        <Box width="150px" height="150px">
          <OctopusLoading />
        </Box>
        <Box>
          <Typography variant="h4">
            Calculando, aguarde alguns instantes...
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
