export const paymentRequestStatuses = {
  created: 'created',
  rejected: 'rejected',
  approved: 'approved',
  deleted: 'deleted',
} as const;

export type PaymentRequestStatus =
  (typeof paymentRequestStatuses)[keyof typeof paymentRequestStatuses];

export const attachmentStatuses = {
  waitingUpload: 'waitingUpload',
  uploaded: 'uploaded',
  deleted: 'deleted',
} as const;

export type AttachmentStatus =
  (typeof attachmentStatuses)[keyof typeof attachmentStatuses];

export const paymentRequestStatusesLabels = {
  [paymentRequestStatuses.created]: 'Aguardando aprovação',
  [paymentRequestStatuses.rejected]: 'Recusada',
  [paymentRequestStatuses.approved]: 'Aprovada',
  [paymentRequestStatuses.deleted]: 'Cancelada',
} as const;
