import { useNavigate } from 'react-router-dom';

import { Box, Skeleton, Typography } from '@mui/material';

import { PayrollPayslipSummary, useListSentPayslips } from '@octopus/api';
import { formatMoney, formatPeriodDate } from '@octopus/formatters';

import { formatPayslipType } from '../../modules/formatters';

export type ReceiptsCardsProps = {
  organizationId: string;
  contractId: string | undefined;
};

//TODO: Add Infinite Scroll?
export function ReceiptsCards({
  organizationId,
  contractId,
}: ReceiptsCardsProps) {
  const navigate = useNavigate();
  const { isLoading, data } = useListSentPayslips(
    {
      pathParams: {
        organizationId,
        contractId,
      },
    },
    {
      enabled: !!contractId,
    },
  );
  if (isLoading || !data) {
    return (
      <Box>
        <Skeleton variant="rounded" height="65svh" />
      </Box>
    );
  }
  if (data.total === 0) {
    return (
      <Box
        position="fixed"
        top="50%"
        left="50%"
        sx={{
          transform: 'translate(-50%, -50%)',
        }}
        minWidth="250px"
      >
        <Typography variant="caption" fontWeight="700" color="strokes.heavy">
          Você ainda não possui holerites ou recibos.
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        '& .receipt-card:first-of-type': {
          borderRadius: '8px 8px 0 0',
        },
        '& .receipt-card:last-of-type': {
          borderRadius: '0 0 8px 8px',
          borderBottomWidth: '1px',
        },
      }}
      data-testid="receipt-cards"
    >
      {data.data.map((summary) => (
        <ReceiptCard
          key={summary.payrollId}
          summary={summary}
          onClick={() => {
            navigate(
              `${summary.companyId}/${summary.contractId}/${summary.payrollId}`,
            );
          }}
        />
      ))}
    </Box>
  );
}

type ReceiptCardProps = {
  summary: PayrollPayslipSummary;
  onClick: () => void;
};

function ReceiptCard({ summary, onClick }: ReceiptCardProps) {
  return (
    <Box
      className="receipt-card"
      display="flex"
      justifyContent="space-between"
      border="1px solid rgba(0, 0, 0, 0.07)"
      sx={{
        borderBottomWidth: '0px',
      }}
      onClick={onClick}
      p={2}
      gap={1}
      data-testid="receipt-card"
    >
      <Box display="flex" flexDirection="column" alignItems="start" gap={0.5}>
        <Typography variant="body1" color="text.primary" fontWeight="700">
          {formatPeriodDate(summary.period)}
        </Typography>
        <Typography variant="caption" color="text.secondary" fontWeight="500">
          {formatPayslipType(summary.type)}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1" color="text.primary" fontWeight="500">
          {formatMoney(summary.netPay) || '--'}
        </Typography>
      </Box>
    </Box>
  );
}
