import { Box, Typography } from '@mui/material';

import { ContractBRCltEntry } from '@octopus/api';

export type BankInfoProps = {
  contract: ContractBRCltEntry;
};

export function BankInfo({ contract }: BankInfoProps) {
  const { pagamento } = contract;
  if (
    !pagamento?.chavePix &&
    !(pagamento?.nomeBanco && pagamento?.agencia && pagamento?.conta)
  ) {
    return null;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          alignItems: 'start',
          backgroundColor: theme.palette.background.default,
          px: 2,
          py: 1.5,
          borderRadius: 1,
          mx: 3,
          my: 1,
        },
        [theme.breakpoints.up('md')]: {
          alignItems: 'end',
          maxWidth: '280px',
        },
      })}
      data-testid="payslip-bank-info"
    >
      <Info contract={contract} />
    </Box>
  );
}

function Info({ contract }: BankInfoProps) {
  const { pagamento } = contract;
  if (pagamento?.chavePix) {
    return <PixInfo chavePix={pagamento.chavePix} />;
  }
  if (pagamento?.nomeBanco && pagamento?.agencia && pagamento?.conta) {
    return (
      <BankAccountInfo
        banco={pagamento.nomeBanco}
        agencia={pagamento.agencia}
        conta={pagamento.conta}
      />
    );
  }
  return null;
}

function PixInfo({ chavePix }: { chavePix: string }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="inherit">
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        Enviado para chave Pix
      </Typography>
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {chavePix}
      </Typography>
    </Box>
  );
}

function BankAccountInfo({
  banco,
  agencia,
  conta,
}: {
  banco: string;
  agencia: string;
  conta: string;
}) {
  return (
    <Box display="flex" flexDirection="column" alignItems="inherit">
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        Depósito na conta {conta}
      </Typography>
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {banco} - Agência {agencia}
      </Typography>
    </Box>
  );
}
