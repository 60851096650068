import { useNavigate } from 'react-router-dom';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Typography } from '@mui/material';

export function BackButton({
  destination,
  label,
  beforeClick,
  position,
}: {
  destination?: string;
  label?: string;
  beforeClick?: () => void;
  position?: string;
}) {
  const navigate = useNavigate();

  const onClick = () => {
    beforeClick && beforeClick();
    return destination ? navigate(destination) : navigate(-1);
  };

  return (
    <Box
      display="flex"
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          justifyContent: 'center',
          alignItems: 'center',
          position: position ?? 'fixed',
          top: 0,
          left: '56px',
          width: '64px',
          height: '24px',
          zIndex: 100,
          px: 3,
          py: 2,
        },
        [theme.breakpoints.down('md')]: {
          px: 2.5,
          py: 1,
          mt: 1,
        },
      })}
    >
      <Box
        display="flex"
        gap={1}
        sx={(theme) => ({
          cursor: 'pointer',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: theme.palette.strokes.light,
          },
          '&:active': {
            backgroundColor: theme.palette.strokes.heavy,
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
            px: 3,
            py: 1,
            pr: 1.5,
            pl: 1,
          },
          [theme.breakpoints.down('md')]: {
            p: 0.5,
          },
        })}
        onClick={onClick}
        data-testid="back-button"
      >
        <ArrowBackOutlinedIcon
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: { height: '16px', width: '16px' },
            [theme.breakpoints.down('md')]: { height: '24px', width: '24px' },
          })}
        />
        <Typography
          variant="body2"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: { display: 'none' },
            fontWeight: 'bold',
          })}
        >
          {label ?? 'Voltar'}
        </Typography>
      </Box>
    </Box>
  );
}
