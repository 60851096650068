import { useMemo } from 'react';

export function usePersistedValue<T = unknown>(name: string, sourceValue?: T) {
  const isValueGetUse = arguments.length === 1;

  const timestampStorageKey = `ms:${name}`;
  const persistedValueTimestamp =
    window?.sessionStorage.getItem(timestampStorageKey);

  const persistedValue = useMemo(() => {
    if (!name) return null;
    const parsedSourceValue = (() => {
      if (sourceValue === undefined || sourceValue === null) return null;
      return JSON.stringify(sourceValue);
    })();
    const persistedValue = window?.sessionStorage.getItem(name);

    const isDeleteOperation = !isValueGetUse && parsedSourceValue === null;
    const isSetOperation =
      !isValueGetUse &&
      parsedSourceValue !== null &&
      parsedSourceValue !== persistedValue;
    const isValueChange = isDeleteOperation || isSetOperation;

    if (isValueGetUse || !isValueChange) {
      try {
        return JSON.parse(persistedValue);
      } catch (error) {
        console.error(
          `=== usePersistedValue = Couldn't parse persisted param "${name}"`,
        );
      }
      return sourceValue;
    }

    const valueTimestamp = Date.now().toString();
    if (isDeleteOperation) {
      window?.sessionStorage.removeItem(name);
    } else if (isSetOperation) {
      window?.sessionStorage.setItem(name, parsedSourceValue);
    }

    window?.sessionStorage.setItem(timestampStorageKey, valueTimestamp);

    return sourceValue;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    isValueGetUse,
    timestampStorageKey,
    sourceValue,
    persistedValueTimestamp,
  ]);

  return persistedValue;
}
