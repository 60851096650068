import { useMaskito } from '@maskito/react';

import { ContractBRPjEntryEmpresa } from '@octopus/api';
import { formatCNAE, formatCNPJ, getOnlyDigits } from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../../form/Field/MaskitoOptions';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type ContratadaRecordData = {
  empresa: ContractBRPjEntryEmpresa;
};

export type ContratadaRecordProps = BaseRecordProps<ContratadaRecordData>;

export function ContratadaRecord(props: ContratadaRecordProps) {
  const { empresa } = props.data;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        empresa: {
          ...data.empresa,
          [key]: formatter(value),
        },
      }));
  };

  const cnpjMask = useMaskito({
    options: MaskitoOptionsBR.cnpj,
  });

  const cnaeMask = useMaskito({
    options: MaskitoOptionsBR.cnae,
  });

  return (
    <Record title="Contratada" edit={editRecordProps}>
      <RecordEntry
        label="Razão social"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('razaoSocial'),
          value: formData.empresa.razaoSocial,
          hasError: hasError('br/empresa/razaoSocial'),
        }}
      >
        {empresa.razaoSocial}
      </RecordEntry>
      <RecordEntry
        label="CNPJ"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('cnpj', getOnlyDigits),
          value: formatCNPJ(formData.empresa.cnpj),
          mask: cnpjMask,
          hasError: hasError('br/empresa/cnpj'),
        }}
      >
        {formatCNPJ(empresa.cnpj)}
      </RecordEntry>
      <RecordEntry
        label="CNAE principal"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('cnae', getOnlyDigits),
          value: formatCNAE(formData.empresa.cnae),
          mask: cnaeMask,
          hasError: hasError('br/empresa/cnae'),
        }}
      >
        {formatCNAE(empresa.cnae)}
      </RecordEntry>
      <RecordEntry
        label="Enquadramento tributário"
        edit={{
          type: 'options',
          editing,
          value: formData.empresa.enquadramentoTributario ?? 'lucroPresumido',
          onChange: prepareOnChange('enquadramentoTributario'),
          options: [
            {
              label: 'Lucro Presumido',
              value: 'lucroPresumido',
            },
            {
              label: 'SIMPLES Nacional',
              value: 'simples',
            },
          ],
          hasError: hasError('br/empresa/enquadramentoTributario'),
        }}
      >
        {empresa.enquadramentoTributario === 'simples'
          ? 'SIMPLES Nacional'
          : 'Lucro Presumido'}
      </RecordEntry>
    </Record>
  );
}
