import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Alert, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useCloseProvisionsPeriod } from '@octopus/api';
import { formatPeriodDate } from '@octopus/formatters';

function ProvisionsPage({ organizationId }: { organizationId: string }) {
  const { period } = useParams<{
    period: string;
  }>();

  const { isSuccess, isError, error, isLoading, mutate } =
    useCloseProvisionsPeriod();

  return (
    <Box px={8} py={4}>
      <Typography variant="h1">Provisões {formatPeriodDate(period)}</Typography>
      <Box py={2}>
        {isError && <Alert severity="error">{JSON.stringify(error)}</Alert>}
        {isSuccess && (
          <Alert severity="success">Provisões fechadas com sucesso!</Alert>
        )}
      </Box>
      <Box py={2}>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={() => {
            mutate({
              pathParams: { organizationId, periodId: period },
            });
          }}
        >
          Fechar todas as provisões do período
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default ProvisionsPage;
