import { FieldMetadata, getTextareaProps } from '@conform-to/react';

import {
  IFieldSingleValueWithoutOptionsDefinition,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { TFieldRenderProps, TFieldTextareaRenderProps } from './types';

export function parseFieldTextArea(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  fieldDefinition: IFieldSingleValueWithoutOptionsDefinition,
  fieldsetName = '',
): TFieldRenderProps & TFieldTextareaRenderProps {
  const inputProps = getTextareaProps(fieldMetadata);

  const currentValue =
    (fieldMetadata.dirty && fieldMetadata.value?.toString()) || '';

  const fieldRenderProps: TFieldRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName,
    errors: fieldDefinition.errors,
    props: {
      key: inputProps.id,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: inputProps.id,
      },
    },
    textarea: {
      props: {
        ...inputProps,
        ...(currentValue && { defaultValue: currentValue }),
      },
    },
    extraProps:
      fieldDefinition?.extraProps as TFieldTextareaRenderProps['extraProps'],
  };

  return fieldRenderProps;
}
