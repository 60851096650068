import { LocalDate } from '@js-joda/core';
import dayjs from 'dayjs';
import { z } from 'zod';

import { formatOptionalDayjsDate } from '../../../../../../modules/formatters';

export const getDtPrevTermSchema = (admissionDate: string) => {
  return z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/)
    .refine(
      (val) => {
        try {
          const dtPrevTerm = LocalDate.parse(val);
          const dtAdm = LocalDate.parse(admissionDate);

          return dtAdm.isBefore(dtPrevTerm);
        } catch (_) {
          return false;
        }
      },
      {
        params: {
          i18n: {
            key: 'form_invalid_dt_prev_term_string_with_value', values: {
              admissionDate: formatOptionalDayjsDate({ date: dayjs(admissionDate), format: 'DD-MM-YYYY' })
            }
          },
        },
      },
    )
};
