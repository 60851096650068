import {
  TFormEffectsInput,
  parseFormEffects,
} from '../../../../modules/form/parseFormEffects';

import { IVacationFormInputs, IVacationFormOutputs } from './types';

export function useVacationFormEffects({
  inputs,
  outputs,
}: {
  inputs: IVacationFormInputs;
  outputs: IVacationFormOutputs;
}) {
  if (!outputs || !inputs) return null;

  const feriasDisponiveis =
    outputs.vacationInputsPreview?.br.feriasPorPeriodoAquisitivo.filter(
      (ferias) =>
        inputs.discountLeaves
          ? ferias.feriasQueTemDireito.qtDiasTotalDisponiveis
          : ferias.feriasMaximo.qtDiasTotalDisponiveis,
    );

  const feriasMaisAntigasDisponiveis = feriasDisponiveis[0];
  const dadosFeriasMaisAntigasDisponiveis = inputs.discountLeaves
    ? feriasMaisAntigasDisponiveis.feriasQueTemDireito
    : feriasMaisAntigasDisponiveis.feriasMaximo;

  const isAbonoSelected = inputs.diasDeAbono > 0;
  const isSelectingOldestOfMoreThanOnePeriod = feriasDisponiveis.length > 1;
  const isFractionOfTotal =
    inputs.diasDeFerias &&
    dadosFeriasMaisAntigasDisponiveis.qtDiasTotal <
      dadosFeriasMaisAntigasDisponiveis.qtDiasTotalDisponiveis;
  const hasEnoughFaltasToDiscount =
    feriasMaisAntigasDisponiveis.feriasMaximo.qtDiasTotalDisponiveis >
    feriasMaisAntigasDisponiveis.feriasQueTemDireito.qtDiasTotalDisponiveis;
  const isStartDateDSR = false;
  const hasNetworkError = false;

  const didAlreadyAnticipated13th =
    dadosFeriasMaisAntigasDisponiveis.adiantouDecimoTerceiroNoAnoVigente;
  const isEligibleForLeavesDiscount = !hasEnoughFaltasToDiscount;
  const hasAvailableAbono =
    dadosFeriasMaisAntigasDisponiveis.qtDiasTotalDisponiveis -
      (inputs.diasDeFerias ?? 0) <
    dadosFeriasMaisAntigasDisponiveis.qtDiasDeAbonoDisponiveis;

  const effects: TFormEffectsInput = {
    warn: [
      {
        message:
          'O cálculo do abono de férias é baseado em um saldo de 30 dias.',
        when: isAbonoSelected,
      },
      {
        message:
          'Ao programar as férias deste colaborador, os dias de férias disponíveis do período mais antigo serão utilizados primeiro.',
        when: isSelectingOldestOfMoreThanOnePeriod,
      },
      {
        message:
          'Ao deixar dias disponíveis, você estará fracionando as férias deste colaborador.',
        when: isFractionOfTotal,
      },
      {
        message:
          'De acordo com o registro de faltas, haverá desconto no total de dias disponíveis.',
        when: hasEnoughFaltasToDiscount,
      },
      {
        message:
          'Observe que a data de início das férias deste colaborador coincide com um Dia de Descanso Semanal Remunerado (DSR)',
        when: isStartDateDSR,
      },
    ],
    disable: [
      {
        message: 'Algo deu errado, tente novamente.',
        when: hasNetworkError,
      },
      {
        field: 'anticipate13th',
        message: 'Colaborador já adiantou o 13º no ano vigente',
        when: didAlreadyAnticipated13th,
        value: false,
      },
      {
        field: 'discountLeaves',
        message:
          'Colaborador não elegível a desconto por faltas não justificadas',
        when: isEligibleForLeavesDiscount,
        value: false,
      },
      {
        field: 'sellingVacations',
        message: 'Saldo insuficiente para abono',
        when: hasAvailableAbono,
        value: false,
      },
    ],
    throw: [],
  };

  return parseFormEffects(effects);
}
