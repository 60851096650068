import { PjPaymentRequestInvoiceBrTaxes } from '@octopus/api';

export const brEnquadramentosTributarios = {
  simples: 'simples',
  lucroPresumido: 'lucroPresumido',
} as const;

export const brTaxesLabels: Record<
  keyof PjPaymentRequestInvoiceBrTaxes,
  string
> = {
  cofins: 'COFINS',
  csll: 'CSLL',
  inss: 'INSS',
  irrf: 'IRRF',
  iss: 'ISS',
  others: 'Outras retenções',
  pisPasep: 'PIS/PASEP',
} as const;

export type BrTaxLabel = keyof typeof brTaxesLabels;
