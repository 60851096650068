import React, { ReactElement } from 'react';

import { Box } from '@mui/material';

import { Button } from '@octopus/ui/design-system';

type IActionBarProps = {
  justifyContent?: 'flex-start' | 'space-between' | 'flex-end';
  children:
    | ReactElement<IActionBarActionProps, typeof ActionBar.Action>
    | ReactElement<IActionBarActionProps, typeof ActionBar.Action>[];
};

type IActionBarActionProps = React.PropsWithChildren &
  React.ComponentProps<typeof Button>;

export function ActionBar(props: IActionBarProps) {
  const justifyContent = props.justifyContent || 'flex-end';
  const ActionElements = React.Children.map(props.children, (childAction) => {
    const isRightAlignedButton =
      !childAction.props.variantSemantic ||
      childAction.props.variantSemantic === 'primary' ||
      childAction.props.variantSemantic === 'highlight' ||
      childAction.props.variantSemantic === 'secondary';

    return React.cloneElement(
      childAction,
      {
        ...childAction.props,
        sx: {
          ...childAction.props.sx,
          ...(!isRightAlignedButton && {
            marginLeft: 3,
            marginRight: 'auto',
          }),
          '* + &': {
            marginLeft: '8px',
          },
        },
      },
      childAction.props.children,
    );
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent,
        alignItems: 'center',
        background: 'rgba(247, 247, 248, 0.8)',
        backdropFilter: 'blur(4px)',
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
    >
      {ActionElements}
    </Box>
  );
}

ActionBar.Action = Button;
