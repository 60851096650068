const lancamentosDeEncargos = {
  fgts: {
    id: 'encargo.fgts',
    descricao: 'Encargos - FGTS',
  },
  grrf: {
    id: 'encargo.grrf',
    descricao: 'Encargos - FGTS GRRF',
  },
  inssPatronal: {
    id: 'encargo.inssPatronal',
    descricao: 'Encargos - INSS Patronal',
  },
  inssOutrasEntidades: {
    id: 'encargo.inssOutrasEntidades',
    descricao: 'Encargos - INSS Outras Entidades',
  },
  inssRat: {
    id: 'encargo.inssRat',
    descricao: 'Encargos - INSS RAT',
  },
};

const lancamentosDerivados = {
  liquidoDeRescisao: {
    id: 'derivados.liquidoDeRescisao',
    descricao: 'Líquido de Rescisão',
  },
};

export { lancamentosDeEncargos, lancamentosDerivados };
