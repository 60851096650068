import { useNavigate } from 'react-router-dom';

import { WarningRounded } from '@mui/icons-material';
import { Alert, Box, Button, Card, Typography } from '@mui/material';

export type nextVacationsCardSaldoProps = {
  due: 'unacquired' | 'acquired' | 'closeToDue' | 'overdue';
  days: number;
  dueDate: string;
  requestLink: string;
};

function closeToDueAlert() {
  return (
    <Alert
      severity="warning"
      icon={<WarningRounded fontSize="large" />}
      sx={{ alignItems: 'center', justifyContent: 'center' }}
    >
      Atenção, a data limite para solicitação está próxima, faça a sua
      solicitação.
    </Alert>
  );
}

function dueAlert() {
  return (
    <Alert
      severity="error"
      icon={<WarningRounded fontSize="large" />}
      sx={{ alignItems: 'center', justifyContent: 'center' }}
    >
      A data limite para solicitação venceu, entre em contato com seu
      departamento pessoal.
    </Alert>
  );
}

export default function NextVacationsCardSaldo({
  props,
}: {
  props: nextVacationsCardSaldoProps;
}) {
  const navigate = useNavigate();

  const shouldEnableClick = ['closeToDue', 'acquired'].includes(props.due);
  const content = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography fontWeight={700} fontSize={'20px'}>
          {props.days} dias
        </Typography>
        <Typography fontWeight={500} fontSize={'14px'} variant={'subtitle1'}>
          {shouldEnableClick
            ? `Solicitar até ${props.dueDate}`
            : `Disponíveis a partir de ${props.dueDate}`}
        </Typography>
      </Box>
      {shouldEnableClick ? (
        <Button variant="text" onClick={() => navigate(props.requestLink)}>
          Solicitar
        </Button>
      ) : null}
    </Box>
  );
  const disabledProps = {
    backgroundColor: '#F7F7F8',
    border: '1px solid #EDEDED',
    boxShadow: 'none',
  };

  return (
    <Card
      variant={'outlined'}
      onClick={() => (shouldEnableClick ? navigate(props.requestLink) : null)}
      sx={{
        boxSizing: 'border-box',
        py: 1,
        px: 1,
        borderRadius: 2,
        border: 'none',
        mb: 1.5,
        ...(['unacquired', 'overdue'].includes(props.due) || !shouldEnableClick
          ? disabledProps
          : {}),
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
        {content}
        {props.due === 'closeToDue' ? closeToDueAlert() : null}
        {props.due === 'overdue' ? dueAlert() : null}
      </Box>
    </Card>
  );
}
