import { vacationsScheduleStatuses } from '@octopus/vacations-types';

export enum VacationsDetailsTabs {
  available = 'available',
  requests = 'requests',
  scheduled = 'scheduled',
  history = 'history',
}

export const vacationsDetailsTabsConfig: {
  [key in VacationsDetailsTabs]: {
    key: string;
    label: string;
    count?: number;
    emptyMessage?: string;
  };
} = {
  [VacationsDetailsTabs.available]: {
    key: VacationsDetailsTabs.available,
    label: 'Dias disponíveis',
  },
  [VacationsDetailsTabs.requests]: {
    key: VacationsDetailsTabs.requests,
    label: 'Solicitações',
    emptyMessage: 'Não existem solicitações para esta pessoa neste momento.',
  },
  [VacationsDetailsTabs.scheduled]: {
    key: VacationsDetailsTabs.scheduled,
    label: 'Agendadas',
    emptyMessage:
      'Não existem férias agendadas para esta pessoa neste momento.',
  },
  [VacationsDetailsTabs.history]: {
    key: VacationsDetailsTabs.history,
    label: 'Histórico',
    emptyMessage: 'Esta pessoa ainda não usufruiu de férias.',
  },
};

export enum VacationsTabs {
  people = 'people',
  vacationsHistory = 'vacationsHistory',
  requests = 'requests',
  calculations = 'calculations',
  // payslips = 'payslips',
}

export const vacationsTabsConfig: {
  [key in VacationsTabs]: {
    key: string;
    label: string;
    detailsTab?: VacationsDetailsTabs;
    emptyMessage?: string;
  };
} = {
  [VacationsTabs.people]: {
    key: VacationsTabs.people,
    label: 'Colaboradores',
    detailsTab: VacationsDetailsTabs.available,
  },
  [VacationsTabs.vacationsHistory]: {
    key: VacationsTabs.vacationsHistory,
    label: 'Histórico de férias',
    detailsTab: VacationsDetailsTabs.history,
    emptyMessage: 'Não existem férias agendadas ainda.',
  },
  [VacationsTabs.requests]: {
    key: VacationsTabs.requests,
    label: 'Solicitações',
    detailsTab: VacationsDetailsTabs.requests,
    emptyMessage:
      'Não existem solicitações aguardando aprovação neste momento.',
  },
  [VacationsTabs.calculations]: {
    key: VacationsTabs.calculations,
    label: 'Cálculos',
    detailsTab: VacationsDetailsTabs.scheduled,
    emptyMessage: 'Não existem cálculos para serem aprovados neste momento.',
  },
  // [VacationsTabs.payslips]: {
  //   key: 'payslips',
  //   label: 'Pagamentos e recibos',
  // },
};

export type DeclinedVacationStatus =
  | typeof vacationsScheduleStatuses.rejected
  | typeof vacationsScheduleStatuses.canceled
  | typeof vacationsScheduleStatuses.payrollArchived;

export const declinedStatus = [
  vacationsScheduleStatuses.rejected,
  vacationsScheduleStatuses.canceled,
  vacationsScheduleStatuses.payrollArchived,
];

export type ScheduledVacationsStatus =
  | typeof vacationsScheduleStatuses.approved
  | typeof vacationsScheduleStatuses.payrollCreated
  | typeof vacationsScheduleStatuses.payrollApproved;

export const scheduledStatus = [
  vacationsScheduleStatuses.approved,
  vacationsScheduleStatuses.payrollCreated,
  vacationsScheduleStatuses.payrollApproved,
];
