import { Help } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';

import { getFieldOnlyProps } from '../../parseField/getFieldOnlyProps';
import { TFieldRenderProps } from '../../parseField/types';

type LabelProps = {
  field: TFieldRenderProps;
};
export function Label({ field }: LabelProps) {
  const fieldProps = getFieldOnlyProps(field);

  const isFieldset = 'fields' in field;
  const label = field.label.textContent;
  return (
    <Typography
      component={!isFieldset ? 'label' : 'legend'}
      variant="caption"
      sx={{
        display: 'flex',
        marginBottom: 1,
      }}
      {...field.label.props}
    >
      {label}
      {fieldProps?.props.required && label && (
        <Typography
          variant={'caption'}
          sx={{
            fontSize: '12px',
            fontWeight: 700,
            color: (theme) => theme.palette.error.main,
            marginLeft: 0.5,
          }}
        >
          *
        </Typography>
      )}
      {fieldProps?.props.info && (
        <Tooltip
          title={fieldProps?.props.info}
          placement="right"
          sx={{ marginLeft: 0.5 }}
        >
          <Help color="disabled" fontSize="tiny" />
        </Tooltip>
      )}
    </Typography>
  );
}
