import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { Box, Typography } from '@mui/material';

export function PersonalDataTitle() {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pb={2}
      borderBottom="1px solid #EDEDED"
    >
      <Typography
        variant="h2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <BadgeOutlinedIcon
          sx={{
            width: '24px',
            height: '24px',
          }}
        />{' '}
        Meus Dados
      </Typography>
    </Box>
  );
}
