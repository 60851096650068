import { useMaskito } from '@maskito/react';

import {
  ContractBRCltEntryEstagio,
  ContractBRCltEntryTrabalho,
} from '@octopus/api';
import { NaturezaEstagio, NivelEstagio } from '@octopus/esocial/mapper';
import {
  formatCNPJ,
  formatCPF,
  formatDateBR,
  getOnlyDigits,
} from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../../form/Field/MaskitoOptions';
import { Record, RecordEntry, RecordEntryGroup } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type EstagioRecordData = {
  trabalho: ContractBRCltEntryTrabalho;
  estagio: ContractBRCltEntryEstagio | undefined;
};

export type EstagioRecordProps = BaseRecordProps<EstagioRecordData>;

export function EstagioRecord(props: EstagioRecordProps) {
  const { data } = props;
  const { trabalho, estagio } = data;

  const initialFormData = {
    ...data,
    estagio: applyFormating(data.estagio),
  };

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<EstagioRecordData>({
      data: initialFormData,
      edit: props.edit,
    });

  const updateEstagio =
    (prop: string, formatter?: (val: string) => string | number) =>
    (value: string) =>
      updateData((data) => ({
        ...data,
        estagio: {
          ...data.estagio,
          [prop]: formatter ? formatter(value) : value,
        },
      }));

  const updateInstEnsino =
    <K extends keyof ContractBRCltEntryEstagio['instEnsino']>(
      prop: K,
      formatter?: (val: string) => string | number,
    ) =>
    (value: ContractBRCltEntryEstagio['instEnsino'][K]) =>
      updateData((data) => ({
        ...data,
        estagio: {
          ...data.estagio,
          instEnsino: {
            ...data.estagio?.instEnsino,
            [prop]: formatter ? formatter(value) : value,
          },
        },
      }));

  const Masks = {
    apolice: useMaskito({
      options: MaskitoOptionsBR.insurancePolicy,
    }),
    cnpjAgntInteg: useMaskito({
      options: MaskitoOptionsBR.cnpj,
    }),
    cnpjInstEnsino: useMaskito({
      options: MaskitoOptionsBR.cnpj,
    }),
    cpf: useMaskito({
      options: MaskitoOptionsBR.cpf,
    }),
  };

  return (
    <Record
      title="Estágio"
      hide={trabalho.codCateg !== 901}
      edit={editRecordProps}
    >
      <RecordEntryGroup>
        <RecordEntry
          label="Natureza do estágio"
          edit={{
            editing,
            type: 'options',
            value: formData.estagio?.natEstagio,
            options: NaturezaEstagio.codes()
              .sort()
              .map((code) => ({
                value: code,
                label: NaturezaEstagio.getByCode(code),
              })),
            onChange: updateEstagio('natEstagio'),
            hasError: hasError('br/estagio/natEstagio'),
          }}
        >
          {NaturezaEstagio.getByCode(estagio?.natEstagio)}
        </RecordEntry>
        <RecordEntry
          label="Nível"
          edit={{
            editing,
            type: 'options',
            value: formData.estagio?.nivEstagio,
            options: NivelEstagio.codes()
              .sort()
              .map((code) => ({
                value: code,
                label: NivelEstagio.getByCode(code),
              })),
            onChange: updateEstagio('nivEstagio', parseInt),
            hasError: hasError('br/estagio/nivEstagio'),
          }}
        >
          {NivelEstagio.getByCode(estagio?.nivEstagio)}
        </RecordEntry>
        <RecordEntry
          label="Área de atuação"
          edit={{
            editing,
            type: 'text',
            value: formData.estagio?.areaAtuacao,
            onChange: updateEstagio('areaAtuacao'),
            hasError: hasError('br/estagio/areaAtuacao'),
          }}
        >
          {estagio?.areaAtuacao}
        </RecordEntry>
        <RecordEntry
          label="Data de termino"
          edit={{
            editing,
            type: 'date',
            valueFormat: 'YYYY-MM-DD',
            value: formData.estagio?.dtPrevTerm,
            onChange: updateEstagio('dtPrevTerm'),
            hasError: hasError('br/estagio/dtPrevTerm'),
          }}
        >
          {estagio?.dtPrevTerm ? formatDateBR(estagio?.dtPrevTerm) : undefined}
        </RecordEntry>
        <RecordEntry
          label="Número da apólice do seguro"
          edit={{
            editing,
            type: 'text',
            value: formData.estagio?.nrApol,
            onChange: updateEstagio('nrApol', getOnlyDigits),
            mask: Masks.apolice,
            hasError: hasError('br/estagio/nrApol'),
          }}
        >
          {formatEnsurancePolicy(estagio?.nrApol)}
        </RecordEntry>
      </RecordEntryGroup>
      <RecordEntryGroup title="Instituição de ensino">
        <RecordEntry
          label="CNPJ"
          edit={{
            editing,
            type: 'text',
            value: formData?.estagio?.instEnsino?.cnpjInstEnsino,
            onChange: updateInstEnsino('cnpjInstEnsino', getOnlyDigits),
            mask: Masks.cnpjInstEnsino,
            hasError: hasError('br/estagio/instEnsino/cnpjInstEnsino'),
          }}
        >
          {formatCNPJ(estagio?.instEnsino?.cnpjInstEnsino)}
        </RecordEntry>
        <RecordEntry
          label="CNPJ do agente de integração"
          edit={{
            editing,
            type: 'text',
            value: formData.estagio?.cnpjAgntInteg,
            onChange: updateEstagio('cnpjAgntInteg', getOnlyDigits),
            mask: Masks.cnpjAgntInteg,
            hasError: hasError('br/estagio/cnpjAgntInteg'),
          }}
        >
          {formatCNPJ(estagio?.cnpjAgntInteg)}
        </RecordEntry>
        <RecordEntry
          label="CPF da pessoa supervisora"
          edit={{
            editing,
            type: 'text',
            value: formData.estagio?.cpfSupervisor,
            onChange: updateEstagio('cpfSupervisor', getOnlyDigits),
            mask: Masks.cpf,
            hasError: hasError('br/estagio/cpfSupervisor'),
          }}
        >
          {formatCPF(estagio?.cpfSupervisor)}
        </RecordEntry>
      </RecordEntryGroup>
    </Record>
  );
}

function formatEnsurancePolicy(str: string | undefined): string | undefined {
  return !str ? str : str.match(/.{5}/g)?.join(' ');
}

function applyFormating(estagio: ContractBRCltEntryEstagio | undefined) {
  return !estagio
    ? estagio
    : {
        ...estagio,
        cnpjAgntInteg: formatCNPJ(estagio?.cnpjAgntInteg),
        cpfSupervisor: formatCPF(estagio?.cpfSupervisor),
        nrApol: formatEnsurancePolicy(estagio?.nrApol),
        instEnsino: !estagio?.instEnsino
          ? estagio?.instEnsino
          : {
              ...estagio?.instEnsino,
              cnpjInstEnsino: formatCNPJ(estagio?.instEnsino?.cnpjInstEnsino),
            },
      };
}
