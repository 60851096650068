import { useEffect, useState } from 'react';

import { useMaskito } from '@maskito/react';

import { InputAdornment, Typography } from '@mui/material';

import { ContractBRCltEntryJornada } from '@octopus/api';
import { ContratoRegimeParcial, TipoJornada } from '@octopus/esocial/mapper';
import { formatBooleanBR } from '@octopus/formatters';

import { Record, RecordEntry, mapperToOptions } from '../../../Record';
import { BaseRecordProps, booleanOptions } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type JornadaDeTrabalhoRecordData = {
  jornada: ContractBRCltEntryJornada;
};

export type JornadaDeTrabalhoProps =
  BaseRecordProps<JornadaDeTrabalhoRecordData>;

export function JornadaDeTrabalho(props: JornadaDeTrabalhoProps) {
  const {
    data: { jornada },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<JornadaDeTrabalhoRecordData>(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number | boolean = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        jornada: { ...data.jornada, [key]: formatter(value) },
      }));
  };

  const [strQtdHrsSem, setStrQtdHrsSem] = useState<string>(
    `${formData.jornada.qtdHrsSem}`.replace('.', ','),
  );

  useEffect(() => {
    const qtdHrsSemValue = parseFloat(strQtdHrsSem.replace(',', '.'));
    updateData((data) => ({
      ...data,
      jornada: { ...data.jornada, qtdHrsSem: qtdHrsSemValue },
    }));
  }, [strQtdHrsSem]);

  const hoursMask = useMaskito({
    options: {
      mask: /^\d*(,\d?\d?)?$/,
    },
  });

  return (
    <Record title="Jornada de trabalho" edit={editRecordProps}>
      <RecordEntry
        label="Jornada em horas"
        edit={{
          type: 'text',
          editing,
          onChange: setStrQtdHrsSem,
          value: strQtdHrsSem,
          mask: hoursMask,
          inputProps: {
            endAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" color="strokes.heavy">
                  horas / semana
                </Typography>
              </InputAdornment>
            ),
          },
          hasError: hasError('br/jornada/qtdHrsSem'),
        }}
      >
        {jornada.qtdHrsSem ? `${jornada.qtdHrsSem} horas` : undefined}
      </RecordEntry>
      <RecordEntry
        label="Tipo de jornada"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('tpJornada'),
          value: formData.jornada.tpJornada,
          options: mapperToOptions({ mapper: TipoJornada }),
          hasError: hasError('br/jornada/tpJornada'),
        }}
      >
        {TipoJornada.getByCode(jornada.tpJornada)}
      </RecordEntry>
      <RecordEntry
        label="Tempo parcial"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('tmpParc'),
          value: formData.jornada.tmpParc,
          options: mapperToOptions({ mapper: ContratoRegimeParcial }),
          hasError: hasError('br/jornada/tmpParc'),
        }}
      >
        {ContratoRegimeParcial.getByCode(jornada.tmpParc)}
      </RecordEntry>
      <RecordEntry
        label="Possui horário noturno?"
        edit={{
          type: 'options',
          editing,
          value: formData.jornada.horNoturno?.toString(),
          onChange: prepareOnChange('horNoturno', (str) => str === 'true'),
          options: booleanOptions,
          hasError: hasError('br/jornada/horNoturno'),
        }}
      >
        {formatBooleanBR(jornada.horNoturno)}
      </RecordEntry>
      <RecordEntry
        label="Descrição da jornada semanal"
        edit={{
          type: 'text',
          editing,
          onChange: prepareOnChange('descJorn'),
          value: formData.jornada.descJorn,
          hasError: hasError('br/jornada/descJorn'),
        }}
      >
        {jornada.descJorn}
      </RecordEntry>
    </Record>
  );
}
