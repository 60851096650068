import { useEffect, useState } from 'react';

import { useMaskito } from '@maskito/react';

import { InputAdornment, Typography } from '@mui/material';

import { ContractBRPjEntryPagamento } from '@octopus/api';
import { capitalize, formatMoney } from '@octopus/formatters';

import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type PagamentoRecordData = {
  pagamento: ContractBRPjEntryPagamento;
};

export type PagamentoRecordProps = BaseRecordProps<PagamentoRecordData>;

export function PagamentoRecord(props: PagamentoRecordProps) {
  const {
    data: { pagamento },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        pagamento: { ...data.pagamento, [key]: formatter(value) },
      }));
  };

  const [strHonValue, setStrHonValue] = useState<string>(
    `${formData.pagamento.honorarios}`.replace('.', ','),
  );

  useEffect(() => {
    setStrHonValue(`${formData.pagamento.honorarios}`.replace('.', ','));
  }, [editing]);

  useEffect(() => {
    const salValue = parseFloat(strHonValue.replace(',', '.'));
    updateData((data) => ({
      ...data,
      pagamento: { ...data.pagamento, honorarios: salValue },
    }));
  }, [strHonValue]);

  const moneyMask = useMaskito({
    options: {
      mask: /^\d*(,\d?\d?)?$/,
    },
  });

  return (
    <Record title="Pagamento" edit={editRecordProps}>
      <RecordEntry
        label="Honorários"
        edit={{
          editing,
          type: 'text',
          onChange: setStrHonValue,
          value: strHonValue,
          mask: moneyMask,
          inputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" color="strokes.heavy">
                  R$
                </Typography>
              </InputAdornment>
            ),
          },
          hasError: hasError('br/pagamento/honorarios'),
        }}
      >
        {formatMoney(`${pagamento?.honorarios}`)}
      </RecordEntry>
      <RecordEntry
        label="Periodicidade"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('periodicidade'),
          value: formData.pagamento.periodicidade,
          options: [
            {
              label: 'Mensal',
              value: 'mensal',
            },
            {
              label: 'Quinzenal',
              value: 'quinzenal',
            },
            {
              label: 'Semanal',
              value: 'semanal',
            },
          ],
          hasError: hasError('br/pagamento/periodicidade'),
        }}
      >
        {capitalize(pagamento?.periodicidade)}
      </RecordEntry>
    </Record>
  );
}
