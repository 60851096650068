import React from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { Box, Button, Typography, capitalize } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { FilterOption } from '../filtering';

export function makeYearMonthPickerFilter({
  label,
  icon,
  propertyToFilter,
}: {
  label: string;
  icon?: React.ReactNode;
  propertyToFilter: string;
}): FilterOption {
  return {
    id: propertyToFilter,
    label,
    icon,
    filterFn: () => true,
    canInvertSelection: false,
    component: (props) => {
      const currentState =
        props.filterState.elementFilters[propertyToFilter] ?? [];
      const setCurrent = (value: string | undefined) => {
        props.changeFilterState({
          elementFilters: {
            [propertyToFilter]: value ? [value] : [],
          },
        });
      };
      return (
        <YearMonthPickerFilter
          current={currentState[0]}
          setCurrent={setCurrent}
          closePopover={() => props.closePopover()}
          data-testid="date-range-filter"
        />
      );
    },
    preview: (filterState) => {
      const filter = filterState.elementFilters[propertyToFilter];
      if (!filter || filter.length === 0) {
        return '-';
      }
      return capitalize(formatDate(filter[0]));
    },
  };
}

function YearMonthPickerFilter({
  current,
  setCurrent,
  closePopover,
}: {
  current: string | undefined;
  setCurrent: (value: string | undefined) => void;
  closePopover: () => void;
}) {
  const [value, setValue] = React.useState<string | undefined>(current);

  return (
    <Box width="320px">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Box display="flex" flexDirection="column" gap={1}>
          <DateCalendar
            value={value ? dayjs(value, { format: 'YYYY-MM' }) : dayjs()}
            views={['month', 'year']}
            openTo="month"
            onChange={(val) => setValue(val?.format('YYYY-MM'))}
          />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap={1}
          >
            <Button
              color="secondary"
              sx={{ height: '40px', width: '104px' }}
              onClick={closePopover}
            >
              <Typography variant="body2">Cancelar</Typography>
            </Button>
            <Button
              color="primary"
              sx={{ height: '40px', width: '104px' }}
              onClick={() => {
                setCurrent(value);
                closePopover();
              }}
            >
              <Typography variant="body2" color="secondary.main">
                Aplicar
              </Typography>
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    </Box>
  );
}

const dateTimeFormat = new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric',
  month: 'long',
  timeZone: 'UTC',
});

function formatDate(date: string | undefined) {
  if (!date) {
    return '';
  }
  return dateTimeFormat.format(new Date(date));
}
