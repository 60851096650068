import { ZodTypeAny, z } from 'zod';

import {
  IFormDefinitionEntry,
  isFieldSingleWithAutoCompleteOption,
  isFieldSingleWithOptions,
  isFieldset,
  isFieldsetBranching,
  isNamedFieldDefinition,
} from './formFieldDefinitions/formFieldDefinitions';

const INTERSECTION_FIELDS = Symbol.for('forms_intersection');

type IFieldShape = Record<string, ZodTypeAny> &
  Partial<Record<typeof INTERSECTION_FIELDS, ZodTypeAny[]>>;
type IFieldShapes = IFieldShapesSingle | IFieldShapesUnion;
type IFieldShapesSingle = [IFieldShape];
type IFieldShapesUnion = [IFieldShape, IFieldShape, ...IFieldShape[]];

function getFieldsOptionsSchema() {
  return z.number();
}

function parseToZodSchema(zodTypeShape: IFieldShape) {
  const shapeWithoutIntersections = Object.fromEntries(
    Object.entries(zodTypeShape),
  );
  const intersectionShapes = zodTypeShape[INTERSECTION_FIELDS];

  const mainShape = z.object(shapeWithoutIntersections);
  if (!intersectionShapes?.length) return mainShape;

  const shapeWithIntersections = intersectionShapes.reduce((shapeA, shapeB) => {
    return z.intersection(shapeA, shapeB);
  }, mainShape);

  return shapeWithIntersections;
}
function parseToObjectShapes(
  fieldDefinitions: IFormDefinitionEntry[],
  shapes: IFieldShapes = [{}],
  currentShapeIndex = 0,
  levelId = '',
) {
  const currentShape = shapes[currentShapeIndex];
  if (!currentShape) return shapes;

  let lastFieldCount = 0;
  for (const field of fieldDefinitions) {
    const currentLevelId = `${levelId}${lastFieldCount++}`;
    if (isFieldset(field)) {
      const isNamedField = isNamedFieldDefinition(field);
      const nestedShapes = [{}] as IFieldShapes;
      parseToObjectShapes(
        field.fields,
        nestedShapes,
        nestedShapes.length - 1,
        currentLevelId,
      );

      const shape = nestedShapes[0];
      const nestedSchema = Object.keys(shape).length
        ? parseToZodSchema(shape)
        : null;

      if (!nestedSchema) continue;

      if (isNamedField) {
        currentShape[field.name] = nestedSchema;
      } else {
        const intersectionFields = currentShape[INTERSECTION_FIELDS] || [];
        intersectionFields.push(nestedSchema);
        currentShape[INTERSECTION_FIELDS] = intersectionFields;
      }
    } else if (isFieldsetBranching(field)) {
      const isNamedField = isNamedFieldDefinition(field);
      const branchingShapes: IFieldShapesUnion =
        [] as unknown as IFieldShapesUnion;

      for (const branchingOptions of field.fieldsOptions) {
        branchingShapes.push({});
        parseToObjectShapes(
          branchingOptions.fields,
          branchingShapes,
          branchingShapes.length - 1,
          currentLevelId,
        );
      }

      const branchingSchemas = branchingShapes
        .filter((shape) => Object.keys(shape).length)
        .map((shape) => parseToZodSchema(shape)) as unknown as [
        ZodTypeAny,
        ZodTypeAny,
        ...ZodTypeAny[],
      ];

      const branchingSchemasUnion = branchingSchemas.length
        ? z.union(branchingSchemas)
        : null;

      if (isNamedField) {
        currentShape[`${field.name}_${currentLevelId}_fieldsOptions_idx`] =
          getFieldsOptionsSchema();
        if (branchingSchemasUnion) {
          currentShape[field.name] = branchingSchemasUnion;
        }
      } else {
        currentShape[`unamed_${currentLevelId}_fieldsOptions_idx`] =
          getFieldsOptionsSchema();
        if (branchingSchemasUnion) {
          const intersectionFields = currentShape[INTERSECTION_FIELDS] || [];
          intersectionFields.push(branchingSchemasUnion);
          currentShape[INTERSECTION_FIELDS] = intersectionFields;
        }
      }
    } else if (
      isFieldSingleWithOptions(field) &&
      isFieldSingleWithAutoCompleteOption(field)
    ) {
      const autoCompleteFieldName = `${field.name}_autocomplete_input`;
      currentShape[autoCompleteFieldName] = field.type;
    }
    // else if (isTypedFieldDefinition(field) && isNamedFieldDefinition(field)) {
    //   currentShape[field.name] = field.type; //TODO parse from serialization
    // }
  }

  return shapes;
}

export function createMetaFormSchema(
  fieldDefinitions: IFormDefinitionEntry[],
): Zod.ZodTypeAny {
  const zodTypeShapes: IFieldShapes = parseToObjectShapes(fieldDefinitions);
  const validationSchema = parseToZodSchema(zodTypeShapes[0]);
  return validationSchema;
}
