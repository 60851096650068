export enum VacationSchedulerTabs {
  next = 'next',
  history = 'history',
}

export const vacationScheduleTabsConfig: {
  [key in VacationSchedulerTabs]: {
    key: string;
    label: string;
  };
} = {
  [VacationSchedulerTabs.next]: {
    key: 'next',
    label: 'Próximas',
  },
  [VacationSchedulerTabs.history]: {
    key: 'history',
    label: 'Histórico',
  },
};
