import { Outlet, useOutletContext } from 'react-router-dom';

import { Box, Container, Typography } from '@mui/material';

import LogoBackground from '../../assets/LogoBackground.gif';
import TakoLogo from '../../assets/takoLogo.svg';
import TakoSymbol from '../../assets/takoSymbol.svg';

export type LoginProps = {
  onSuccess: (token: string) => void;
};

export function Login(props: LoginProps) {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" flexGrow={1}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRight: 1,
            borderRightColor: 'divider',
            py: 5,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            maxWidth="400px"
            width="100%"
            minHeight="500px"
          >
            <img src={TakoLogo} alt="Tako" />
            <Typography
              variant="body2"
              color="text.secondary"
              mb={2}
              align="center"
              data-testid="welcome"
            >
              Seja bem-vindo(a)!
            </Typography>
            <Outlet context={props} />
          </Box>
        </Container>
        <Box
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          padding={5}
          boxSizing="border-box"
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
            [theme.breakpoints.up('lg')]: {
              display: 'flex',
            },
          })}
        >
          <Box
            height="100%"
            width="100%"
            borderRadius={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            sx={(theme) => ({
              boxShadow: theme.palette.shadows.light,
              backgroundColor: 'black',
              backgroundImage: `url(${LogoBackground})`,
              backgroundSize: 'cover',
              filter: 'brightness(240%)',
            })}
          >
            <img width="290px" height="290px" src={TakoSymbol} alt="Tako" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function useLoginProps() {
  return useOutletContext<LoginProps>();
}

export default Login;
