import { ReactNode, createContext, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContextResult, useAppContext } from '../modules/hooks/appContext';

export const AppContext = createContext<AppContextResult>(undefined);

export const AppContextProvider = memo(
  ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate();
    const context = useAppContext({
      onAuthFailure: () => navigate('/login/logout'),
    });
    return (
      <AppContext.Provider value={context}>{children}</AppContext.Provider>
    );
  },
);
