import { useState } from 'react';

import { useMaskito } from '@maskito/react';

import { BREstados, ContractBRPjAddress } from '@octopus/api';
import {
  Estados,
  Mapper,
  Municipios,
  MunicipiosByEstado,
  TipoLogradouro,
} from '@octopus/esocial/mapper';
import { formatCEP, getOnlyDigits } from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../../form/Field/MaskitoOptions';
import { Record, RecordEntry, mapperToOptions } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type EnderecoEmpresaRecordData = {
  empresa: {
    endereco?: ContractBRPjAddress;
  };
};

export type EnderecoEmpresaRecordProps =
  BaseRecordProps<EnderecoEmpresaRecordData>;

export function EnderecoEmpresaRecord(props: EnderecoEmpresaRecordProps) {
  const {
    data: {
      empresa: { endereco },
    },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        empresa: {
          ...data.empresa,
          endereco: {
            ...data.empresa.endereco,
            [key]: formatter(value),
          },
        },
      }));
  };

  const tipoLogradouro = TipoLogradouro.getByCode(endereco?.tpLograd);
  const logradouro = tipoLogradouro
    ? `${tipoLogradouro} ${endereco?.dscLograd}`
    : endereco?.dscLograd;

  const [municipioMapper, setMunicipioMapper] = useState<Mapper>(
    MunicipiosByEstado[endereco?.uf],
  );

  const cepMask = useMaskito({
    options: MaskitoOptionsBR.cep,
  });

  return (
    <Record title="Endereço da empresa" edit={editRecordProps}>
      <RecordEntry
        label="CEP"
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('cep', getOnlyDigits),
          value: formatCEP(formData.empresa.endereco?.cep),
          disabled: false,
          mask: cepMask,
          hasError: hasError('br/empresa/endereco/cep'),
        }}
      >
        {formatCEP(endereco?.cep)}
      </RecordEntry>
      <RecordEntry
        label="Logradouro"
        edit={{
          editing,
          type: 'options-and-text',
          options: {
            value: formData.empresa.endereco?.tpLograd,
            options: mapperToOptions({ mapper: TipoLogradouro }),
            onChange: prepareOnChange('tpLograd'),
          },
          text: {
            value: formData.empresa.endereco?.dscLograd,
            onChange: prepareOnChange('dscLograd'),
          },
          hasError:
            hasError('br/empresa/endereco/tpLograd') ||
            hasError('br/empresa/endereco/dscLograd'),
        }}
      >
        {logradouro}
      </RecordEntry>
      <RecordEntry
        label="Número"
        edit={{
          editing,
          type: 'text',
          value: formData.empresa.endereco?.nrLograd,
          onChange: prepareOnChange('nrLograd'),
          hasError: hasError('br/empresa/endereco/nrLograd'),
        }}
      >
        {endereco?.nrLograd}
      </RecordEntry>
      <RecordEntry
        label="Complemento"
        edit={{
          editing,
          type: 'text',
          value: formData.empresa.endereco?.complemento,
          onChange: prepareOnChange('complemento'),
          hasError: hasError('br/empresa/endereco/complemento'),
        }}
      >
        {endereco?.complemento}
      </RecordEntry>
      <RecordEntry
        label="Bairro"
        edit={{
          editing,
          type: 'text',
          value: formData.empresa.endereco?.bairro,
          onChange: prepareOnChange('bairro'),
          hasError: hasError('br/empresa/endereco/bairro'),
        }}
      >
        {endereco?.bairro}
      </RecordEntry>
      <RecordEntry
        label="Unidade Federal"
        edit={{
          editing,
          type: 'options',
          value: formData.empresa.endereco?.uf,
          onChange: (value) => {
            updateData((data) => ({
              ...data,
              empresa: {
                ...data.empresa,
                endereco: {
                  ...data.empresa.endereco,
                  uf: value as BREstados,
                  codMunic: undefined,
                },
              },
            }));
            setMunicipioMapper(MunicipiosByEstado[value]);
          },
          options: mapperToOptions({ mapper: Estados }),
          hasError: hasError('br/empresa/endereco/uf'),
        }}
      >
        {endereco?.uf}
      </RecordEntry>
      <RecordEntry
        label="Cidade / Município"
        edit={{
          editing,
          type: 'options',
          value: formData.empresa.endereco?.codMunic,
          disabled: !formData.empresa.endereco?.uf,
          onChange: prepareOnChange('codMunic', parseInt),
          options: municipioMapper
            ?.codes()
            ?.sort()
            ?.map((value) => ({
              value,
              label: municipioMapper.getByCode(value),
            })),
          hasError: hasError('br/empresa/endereco/codMunic'),
        }}
      >
        {Municipios.getByCode(endereco?.codMunic)}
      </RecordEntry>
      <RecordEntry
        label="Pais"
        edit={{
          editing,
          type: 'text',
          disabled: true,
          value: 'Brasil',
          onChange: () => undefined,
        }}
      >
        Brasil
      </RecordEntry>
    </Record>
  );
}
