import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

export default function TabLabel({
  isSelected,
  label,
  color,
  count,
  variant = 'body1',
}: {
  color: string;
  label: string;
  isSelected: boolean;
  count?: number;
  variant?: Variant;
}) {
  const fontWeight = isSelected ? 700 : 500;
  const textColor = isSelected ? `${color}.main` : 'text.secondary';
  const bgColor = isSelected ? `background.${color}` : 'background.default';
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography
        component="span"
        color={textColor}
        variant={variant}
        fontWeight={fontWeight}
      >
        {label}
      </Typography>
      {count && (
        <Typography
          component="span"
          bgcolor={bgColor}
          color={textColor}
          py={0.25}
          px={1}
          borderRadius={2}
          variant="caption"
          fontWeight={fontWeight}
        >
          {count}
        </Typography>
      )}
    </Box>
  );
}
