import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton } from '@mui/material';

import { SearchInput, fetchSearchAdmissionDrafts } from '@octopus/api';

import { CountProps, Counts } from '../../modules/components/Counts';

export function AdmissionCount({ organizationId }: { organizationId: string }) {
  const queryTotals = useQuery({
    queryKey: ['getContractTotals', { organizationId }],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const searchFilter: SearchInput = {
        counting: {
          filtered: {
            total: true,
            byProp: {
              contractType: ['br:clt', 'br:pj'],
            },
          },
        },
        filtering: {
          elements: {
            draftStatus: [{ not: 'archived' }],
          },
        },
      };

      const totals = await fetchSearchAdmissionDrafts({
        pathParams: {
          organizationId,
        },
        body: searchFilter,
      });

      const counts: CountProps = {};
      counts['all'] = totals?.metadata?.filtered?.counters?.total ?? 0;

      if (counts['all'] === 0) {
        return counts;
      }

      Object.entries(
        totals.metadata.filtered.counters.byProp['contractType'],
      ).forEach(([key, value]) => {
        counts[key] = value;
      });

      if (
        counts['all'] === counts['br:pj'] ||
        counts['all'] === counts['br:clt']
      ) {
        return counts;
      }

      Object.entries(counts).forEach(([key, value]) => {
        counts[key] = value;
      });

      return counts;
    },
  });

  if (queryTotals?.isLoading || queryTotals?.isFetching) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        px={0.5}
        paddingTop={3}
        pb={2}
        width={'40%'}
      >
        <Skeleton variant="rounded" width={'100%'} height={'40px'} />
      </Box>
    );
  }

  return (
    <Box
      px={0.5}
      paddingTop={3}
      pb={2}
      width={'60%'}
      display="flex"
      data-testid={'people-counts'}
    >
      <Counts props={getContractTypeLabels(queryTotals.data)} />
    </Box>
  );
}

const contractLabels = {
  all: 'Total de pessoas',
  'br:clt': 'CLTs',
  'br:pj': 'Prestadores de serviço',
} as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getContractTypeLabels(counts: CountProps): CountProps {
  return Object.entries(contractLabels).reduce((acc, [key, label]) => {
    const count = (counts && counts[key]) || 0;
    return {
      ...acc,
      [label]: count,
    };
  }, {} as CountProps);
}
