import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQueries } from '@tanstack/react-query';
import { isNumber, merge } from 'lodash';

import { Box, Skeleton, Tab, Tabs } from '@mui/material';

import {
  VacationsAccrualPeriodList,
  VacationsScheduleList,
  fetchSearchAllScheduledVacations,
  fetchSearchVacationsAccrualPeriods,
} from '@octopus/api';
import {
  DataGrid,
  DataGridToolbar,
  makeElementListFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import TabLabel from '../../modules/components/TabLabel';
import { DataFetching, FetchResult } from '../../modules/dataFetching';
import { prepareDataGridSearchInput } from '../../utils';

import { columnsByTab } from './utils/columns';
import { VacationsTabs, vacationsTabsConfig } from './utils/types';
import { VacationsDrawerDetails } from './VacationsDetails';

export default function VacationsTable({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const initialTab = params.get('tab');
  const [vacationsStatus, setVacationsStatus] = useState<{
    [key: string]: number;
  }>({});
  const [tab, setTab] = useState<keyof typeof vacationsTabsConfig>(
    (initialTab || VacationsTabs.people) as keyof typeof vacationsTabsConfig,
  );
  const isInPeopleTab = tab === VacationsTabs.people;

  const filters = useFilters(tab) as [];
  const dataGridProps = useDataGrid({ filters });
  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;
  const searchInput = prepareDataGridSearchInput(dataGridProps);

  const addStatus = (tab: keyof typeof VacationsTabs) => {
    const filteredStatus: (
      | 'approved'
      | 'waitingApproval'
      | 'payrollCreated'
      | 'payrollApproved'
    )[] = {
      [VacationsTabs.people]: [],
      [VacationsTabs.vacationsHistory]: [],
      [VacationsTabs.requests]: [
        vacationsScheduleStatuses.waitingApproval,
        vacationsScheduleStatuses.approved,
      ],
      [VacationsTabs.calculations]: [
        vacationsScheduleStatuses.payrollCreated,
        vacationsScheduleStatuses.payrollApproved,
      ],
    }[tab];

    return {
      filtering: {
        elements: {
          status: filteredStatus,
        },
      },
    };
  };
  const searchStatus = addStatus(tab);

  const queryKey = (q: string) => [
    q,
    organizationId,
    searchInput,
    paginationProps,
    tab,
  ];
  const queryParams = {
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  };
  const searchParams = (searchInput: any) => ({
    pathParams: {
      organizationId: organizationId ?? '',
    },
    body: searchInput,
  });

  const searchVacationsAccrualPeriods = () =>
    fetchSearchVacationsAccrualPeriods(
      searchParams(isInPeopleTab ? searchInput : {}),
    );
  const searchAllScheduledVacations = () =>
    fetchSearchAllScheduledVacations(
      searchParams({
        ...(isInPeopleTab ? {} : searchInput),
        ...(searchInput?.filtering?.elements?.status?.length > 0
          ? {}
          : merge(searchStatus, searchInput)),
      }),
    );

  const results = useQueries({
    queries: [
      {
        queryKey: queryKey('searchAllScheduledVacations'),
        queryFn: searchVacationsAccrualPeriods,
        ...queryParams,
      },
      {
        queryKey: queryKey('searchVacationsAccrualPeriods'),
        queryFn: searchAllScheduledVacations,
        ...queryParams,
      },
    ],
  });

  const [allScheduledVacations, vacationsAccrualPeriods] = results;
  const switchTab = (tab: string) => {
    sortingProps.setField();
    sortingProps.setOrder();
    paginationProps.handleChangePage(null, 0);
    dataGridProps.selectionProps.clear();
    filteringProps.clearFilters();
    setTab(tab as keyof typeof vacationsTabsConfig);
  };

  const { waitingApproval, approved, payrollCreated, payrollApproved } =
    vacationsStatus || {};

  useEffect(() => {
    const { status } =
      vacationsAccrualPeriods?.data?.metadata?.buckets?.counters?.byProp || {};
    const { isLoading, isFetching, isRefetching } = vacationsAccrualPeriods;
    if (!isLoading && !isFetching && !isRefetching) {
      setVacationsStatus(status);
    }
  }, [vacationsAccrualPeriods]);

  const getTabCount = ({ key }: { key: string }) => {
    switch (key) {
      case VacationsTabs.requests:
        return waitingApproval;
      case VacationsTabs.calculations:
        return (
          [payrollCreated, payrollApproved, approved].reduce(
            (acc, val) => (isNumber(val) ? acc + Number(val) : acc),
            0,
          ) || undefined
        );
      default:
        return undefined;
    }
  };

  const [selectedPerson, setSelectedPerson] = useState<any>();
  const [open, setOpen] = useState(false);

  const onRowClick = (params: any) => {
    if (tab === VacationsTabs.calculations) {
      const { contractId, sequence } = params.row;
      navigate(`/vacations/${contractId}/receipt?sequence=${sequence}`);
    }
    setOpen(true);
    setSelectedPerson(params.row);
  };

  const onClose = () =>
    Promise.all([
      allScheduledVacations.refetch(),
      vacationsAccrualPeriods.refetch(),
    ]).then(() => setOpen(false));

  return (
    <>
      <VacationsDrawerDetails
        open={open}
        onClose={onClose}
        row={selectedPerson}
        detailsTab={vacationsTabsConfig[tab].detailsTab}
        controls={{
          onDismiss: onClose,
          hasNext: false,
          onNext: () => null,
          hasPrevious: false,
          onPrevious: () => null,
        }}
      />
      <Box
        paddingBottom={1.5}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box alignSelf="stretch" width={'100%'}>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            // totals={totals}
            typeOfResultLabel={'resultados'}
          />
        </Box>
      </Box>
      <Box
        my={2}
        sx={(theme) => ({
          boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
        })}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          value={tab}
          onChange={(_, newTab) => switchTab(newTab)}
          textColor="inherit"
          data-testid="vacations-status-tabs"
          TabIndicatorProps={{
            sx: {
              bgcolor: 'primary.main',
            },
          }}
        >
          {Object.entries(vacationsTabsConfig).map(([key, tabConfig]) => (
            <Tab
              key={key}
              value={key}
              icon={
                <TabLabel
                  isSelected={key === tab}
                  count={getTabCount({ key })}
                  color="primary"
                  label={tabConfig.label}
                />
              }
              data-testid={`people - contractType - tab - ${key}`}
            />
          ))}
        </Tabs>
      </Box>
      <DataFetching
        fetchResult={
          (isInPeopleTab
            ? allScheduledVacations
            : vacationsAccrualPeriods) as FetchResult<
            VacationsScheduleList | VacationsAccrualPeriodList
          >
        }
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          return (
            <Box mt={2}>
              {data ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={data.total || 0}
                  getRowId={(row) => row.id}
                  rows={data.data}
                  onRowClick={(params) => onRowClick(params)}
                  columns={columnsByTab[tab]}
                  emptyMessage={vacationsTabsConfig[tab].emptyMessage}
                />
              ) : null}
            </Box>
          );
        }}
      />
    </>
  );
}

const useFilters = (tab: string) => {
  if (tab === VacationsTabs.people) {
    return [
      makeElementListFilter({
        label: 'Férias disponíveis',
        propertyToFilter: 'hasAvailableVacations',
        elements: ['true', 'false'],
        labels: {
          true: 'Sim',
          false: 'Não',
        },
      }),
      makeElementListFilter({
        label: 'Próximas do vencimento',
        propertyToFilter: 'hasVacationsCloseToDueDate',
        elements: ['true', 'false'],
        labels: {
          true: 'Sim',
          false: 'Não',
        },
      }),
      makeElementListFilter({
        label: 'Vencidas',
        propertyToFilter: 'hasOverdueVacations',
        elements: ['true', 'false'],
        labels: {
          true: 'Sim',
          false: 'Não',
        },
      }),
    ];
  }
  return [
    makeElementListFilter({
      label: 'Status',
      propertyToFilter: 'status',
      elements: [
        ...(tab === VacationsTabs.calculations
          ? [
              vacationsScheduleStatuses.payrollCreated,
              vacationsScheduleStatuses.payrollApproved,
            ]
          : [
              vacationsScheduleStatuses.waitingApproval,
              vacationsScheduleStatuses.approved,
            ]),
      ],
      labels: {
        [vacationsScheduleStatuses.waitingApproval]: 'Aguardando aprovação',
        [vacationsScheduleStatuses.approved]: 'Aprovadas',
        [vacationsScheduleStatuses.payrollCreated]: 'Folha criada',
        [vacationsScheduleStatuses.payrollApproved]: 'Folha aprovada',
      },
    }),
    makeElementListFilter({
      label: 'Adiantamento 13º',
      propertyToFilter: 'thirteenthAdvance',
      elements: ['true', 'false'],
      labels: {
        true: 'Sim',
        false: 'Não',
      },
    }),
    makeElementListFilter({
      label: 'Dias vendidos',
      propertyToFilter: 'hasDaysSold',
      elements: ['true', 'false'],
      labels: {
        true: 'Sim',
        false: 'Não',
      },
    }),
  ];
};
