import React, { FormEvent, useEffect } from 'react';

import {
  Alert,
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { CompanyList, fetchPostCertificate } from '@octopus/api';
import { formatCNPJ } from '@octopus/formatters';

export function Upload({
  organizationId,
  companies,
}: {
  organizationId: string;
  companies: CompanyList;
}) {
  const [disabled, setDisabled] = React.useState(true);
  const [response, setResponse] = React.useState('');
  const [error, setError] = React.useState('');
  const [company, setCompany] = React.useState<string>(
    companies?.data?.[0]?.companyId || '',
  );
  const [certificate, setCertificate] = React.useState<
    | {
        name: string;
        body: string;
      }
    | undefined
  >(undefined);
  const [passphrase, setPassphrase] = React.useState<string>('');

  useEffect(() => {
    if (!company || !certificate || !passphrase) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [company, certificate, passphrase]);

  const handleFileImport = (event: FormEvent<HTMLInputElement>) => {
    readFile(event.currentTarget.files, setCertificate, setError);
  };

  const handleSubmit = () => {
    if (!company || !certificate || !passphrase) {
      return;
    }
    submitCertificate({
      organizationId,
      companyId: company,
      certificate: certificate.body,
      passphrase,
      setResponse,
      setError,
      setDisabled,
    });
  };

  return (
    <Paper elevation={1} sx={{ backgroundColor: 'white', mt: 2 }}>
      <Box py={4} px={4}>
        <Typography variant="h3">Upload de Certificado</Typography>
        <Divider sx={{ my: 2 }} />
        {error && (
          <Box pb={2}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Box>
        )}
        {response && (
          <Box pb={2}>
            <Alert onClose={() => setResponse('')}>{response}</Alert>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">Company</Typography>
            <Select
              fullWidth
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            >
              {companies.data &&
                companies.data.map((company) => (
                  <MenuItem key={company.companyId} value={company.companyId}>
                    {company.br?.razaoSocial} ({formatCNPJ(company.br?.cnpj)})
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">Certificado</Typography>
            <Box display="flex" gap={1}>
              <TextField
                fullWidth
                variant="outlined"
                value={certificate?.name ?? ''}
                disabled
              />
              <Button variant="outlined" color="primary" component="label">
                Selecionar
                <input
                  hidden
                  accept=".pfx"
                  type="file"
                  onChange={handleFileImport}
                />
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">Senha</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={passphrase}
              type="password"
              onChange={(e) => setPassphrase(e.target.value)}
            />
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="flex-end">
          <Button disabled={disabled} onClick={handleSubmit}>
            Fazer Upload
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

function readFile(
  files: FileList | null,
  setCertificate: (input: { name: string; body: string }) => void,
  setError: (error: string) => void,
) {
  setError('');
  if (!files || !files.length) {
    console.log('No file selected');
    return;
  }
  const file = files[0];

  const reader = new FileReader();

  reader.onload = (e) => {
    const body = e.target?.result;
    if (!body) {
      setError('Não foi possível ler o arquivo');
      return;
    }
    if (typeof body === 'string') {
      setCertificate({
        name: file.name,
        body: body,
      });
    } else {
      setCertificate({
        name: file.name,
        body: arrayBufferToBase64(body),
      });
    }
  };
  reader.readAsArrayBuffer(file);
}

function submitCertificate({
  organizationId,
  companyId,
  certificate,
  passphrase,
  setResponse,
  setError,
  setDisabled,
}: {
  organizationId: string;
  companyId: string;
  certificate: string;
  passphrase: string;
  setResponse: (response: string) => void;
  setError: (error: string) => void;
  setDisabled: (disabled: boolean) => void;
}) {
  setDisabled(true);
  setError('');
  setResponse('');

  fetchPostCertificate({
    pathParams: {
      organizationId,
      companyId,
    },
    body: {
      certificate,
      passphrase,
    },
  })
    .then((response) => {
      setDisabled(false);
      console.log(response);
      setResponse(`Certificado criado com sucesso!`);
    })
    .catch((err) => {
      setDisabled(false);
      console.error(err);
      setError(err.stack.message ?? err.message ?? 'Erro inesperado');
    });
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
