import { useEffect, useState } from 'react';

import { GridApi } from 'ag-grid-community';
import { CustomHeaderProps } from 'ag-grid-react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Typography } from '@mui/material';

export function IdentifierHeader({
  column,
  api,
  displayName,
}: CustomHeaderProps) {
  const sortDirection = getColumnSortDirection(column.getColId(), api);
  const [direction, setDirection] = useState<'asc' | 'desc' | null | undefined>(
    sortDirection,
  );

  useEffect(() => {
    setDirection(sortDirection);
  }, [sortDirection]);

  const changeSortDirection = () => {
    const nextDirection = (() => {
      if (direction === 'asc') {
        return 'desc';
      }
      if (direction === 'desc') {
        return null;
      }
      return 'asc';
    })();
    setDirection(nextDirection);
    api.applyColumnState({
      state: [{ colId: column.getColId(), sort: nextDirection }],
      defaultState: { sort: null },
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="100%"
      gap={1}
      onClick={changeSortDirection}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Typography variant="caption" fontWeight="700">
        {displayName}
      </Typography>
      {direction === 'asc' && (
        <ArrowUpwardIcon sx={{ height: '16px', width: '16px' }} />
      )}
      {direction === 'desc' && (
        <ArrowDownwardIcon sx={{ height: '16px', width: '16px' }} />
      )}
    </Box>
  );
}

function getColumnSortDirection(
  colId: string,
  api: GridApi<unknown>,
): 'asc' | 'desc' | undefined {
  const colState = api
    .getColumnState()
    .filter((colState) => colState.colId === colId)[0]!;
  if (colState.sort) {
    return colState.sort;
  }
  return undefined;
}
