import React from 'react';

import { Box, SxProps, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { UI_TYPE } from '@octopus/libs/forms';

import {
  TFieldRenderProps,
  TFieldTextareaRenderProps,
} from '../parseField/types';

import { Label } from './commons/Label';

type FieldTextareaProps = {
  field: TFieldRenderProps & TFieldTextareaRenderProps;
  sx?: SxProps<Theme>;
};

export function FieldTextarea(props: FieldTextareaProps) {
  const { field } = props;

  return (
    <Box {...field.props} sx={props.sx}>
      <Label field={field} />
      <TextField
        multiline
        fullWidth
        inputProps={{
          sx: { minHeight: 'var(--FieldTextAreaMinHeight)' },
          ...field.textarea.props,
        }}
        rows={field?.extraProps?.rows}
      ></TextField>
    </Box>
  );
}

FieldTextarea.uiType = UI_TYPE.TEXTAREA;
FieldTextarea.canRender = (
  field: TFieldRenderProps,
): field is FieldTextareaProps['field'] => {
  return 'textarea' in field;
};
