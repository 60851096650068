import { useMediaQuery, useTheme } from '@mui/material';

import { Bottombar } from './bottombar';
import { Sidebar } from './sidebar';
import { SidebarProps } from './types';

export function Navbar(props: SidebarProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  if (isSmall) {
    return <Bottombar {...props} />;
  }
  return <Sidebar {...props} />;
}
