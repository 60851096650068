import dayjs from 'dayjs';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Box, Card, Typography, useTheme } from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
} from '@octopus/api';
import { Button } from '@octopus/ui/design-system';

export default function InitialStepView({
  accrualPeriod,
  requestVacationMSV,
  vacationsConfiguration,
}: {
  accrualPeriod: VacationsAccrualPeriodEntry;
  requestVacationMSV: any;
  vacationsConfiguration: VacationsConfigurationEntry;
}) {
  const theme = useTheme();
  return (
    <Box
      test-id={'step-box'}
      sx={{
        px: '20px',
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        justifyContent: 'stretch',
        bgcolor: theme.palette.background.secondary,
        gap: 4,
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: '10',
          flexDirection: 'column',
          gap: 3,
          alignItems: 'stretch',
          justifyContent: 'center',
          pb: 1,
        }}
      >
        <Box sx={{ px: 1 }}>
          <Typography
            variant="body1"
            fontSize={16}
            fontWeight={450}
            display={'inline'}
          >
            {
              'Vamos iniciar o agendamento das suas férias. Lembre-se que você deve agendá-las com um '
            }
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={600}
            variant="body1"
            display={'inline'}
          >
            {`mínimo de ${vacationsConfiguration.rules['daysBeforeStart'].minimum} dias de antecedência.`}
          </Typography>
        </Box>
        {renderCard(accrualPeriod)}
      </Box>
      <Box sx={{ alignContent: 'flex-end' }}>
        <Button
          variantSemantic={'primary'}
          onClick={() => requestVacationMSV.goForward()}
          sx={{ width: '100%', height: '48px' }}
        >
          Vamos lá
        </Button>
      </Box>
    </Box>
  );
}

function renderCard(accrualPeriod: VacationsAccrualPeriodEntry) {
  const subtitle = dayjs().isAfter(
    dayjs(accrualPeriod.concessionPeriod.startDate),
  )
    ? `Agendar até ${dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations).format('DD/MM/YYYY')}`
    : `Agendar a partir de ${dayjs(accrualPeriod.concessionPeriod.startDate).format('DD/MM/YYYY')}`;
  return (
    <Card
      variant="elevation"
      sx={{
        padding: '20px',
        borderRadius: '12px',
        border: 'none',
      }}
    >
      <Box
        component={BeachAccessOutlined}
        sx={{ height: '32px', width: 'auto', mr: 0, color: '#BABABF', mb: 0.5 }}
      />
      <Typography fontWeight={700} fontSize={20} lineHeight={'28px'}>
        {accrualPeriod.daysAvailable} dias disponíveis
      </Typography>
      <Typography
        fontWeight={500}
        fontSize={14}
        variant={'subtitle1'}
        lineHeight={'20px'}
      >
        {subtitle}
      </Typography>
    </Card>
  );
}
