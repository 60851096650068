import AdmissionErrorCodes from './admission';
import CommonErrorCodes from './common';
import ContractErrorCodes from './contracts';
import LegalEntityErrorCodes from './legalEntity';
import PayrollErrorCodes from './payroll';

export const PortugueseErrorCodes = {
  ...AdmissionErrorCodes,
  ...CommonErrorCodes,
  ...ContractErrorCodes,
  ...LegalEntityErrorCodes,
  ...PayrollErrorCodes,
};
