import React, { useEffect, useState } from 'react';

import { Img } from '@react-email/components';

import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Paper,
  Typography,
} from '@mui/material';

import {
  OrganizationEntry,
  OrganizationPublicAssetsEntry,
  fetchGetOrganizationEntry,
  fetchHandlePostPublicAssets,
} from '@octopus/api';

import { FileComponent, uploadFile } from './fileComponents';

type ImagePreferencePayload = {
  type: 'image';
  defaultValue: string;
};
type Preference = {
  id: string;
  title: string;
  parent: 'brPreferences' | 'paymentConfig';
} & ImagePreferencePayload;

export const orgPreferences = {
  organizationLogo: {
    type: 'image',
    id: 'organizationLogo',
    title: 'Organization Logo',
    parent: 'brPreferences',
    defaultValue: '',
  },
} as const satisfies Record<string, Preference>;

type PreferenceKey = keyof typeof orgPreferences;

type PreferenceValues<Key> = Key extends PreferenceKey
  ? (typeof orgPreferences)[Key] extends ImagePreferencePayload
    ? string
    : never
  : never;

export function OrgPreferences({ organizationId }: { organizationId: string }) {
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [organization, setOrganization] = useState<OrganizationEntry>({
    name: 'loading org',
    organizationId: '',
    active: false,
    createdOn: undefined,
    createdBy: 'Mozart',
  });
  const [file, setFile] = useState(undefined as File);

  const [preferenceOptions, setPreferenceOptions] = useState(
    Object.keys(orgPreferences).reduce(
      (acc, key) => ({
        ...acc,
        [key]: orgPreferences[key as PreferenceKey].defaultValue,
      }),
      {} as { [key in PreferenceKey]: PreferenceValues<key> },
    ),
  );

  useEffect(() => {
    console.log('effect triggered');
    loadOrganization(organizationId).then((organizationEntry) => {
      setOrganization(organizationEntry);
      setPreferenceOption('organizationLogo', organizationEntry.logoUrl);
    });
  }, [organizationId, file, response]);

  const setPreferenceOption = <Key extends PreferenceKey>(
    key: Key,
    value: PreferenceValues<Key>,
  ) => {
    setPreferenceOptions({
      ...preferenceOptions,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    await submitPreferences({
      organizationId,
      file,
      setResponse,
    });
  };
  return (
    <Paper elevation={1} sx={{ backgroundColor: 'white', mt: 2 }}>
      <Box py={4} px={4}>
        <Typography variant="h3">
          Configurações de Organização - {organization.name}
        </Typography>
        <Divider sx={{ my: 2 }} />

        {error && (
          <Box pb={2}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Box>
        )}

        {response && (
          <Box pb={2}>
            <Alert onClose={() => setResponse('')}>{response}</Alert>
          </Box>
        )}

        {warning && (
          <Box pb={2}>
            <Alert severity="warning" onClose={() => setWarning('')}>
              {warning}
            </Alert>
          </Box>
        )}

        <Box display="flex" flexDirection="column" gap={1.5}>
          {Object.values(orgPreferences).map((preference) => {
            const form = (() => {
              if (preference.type === 'image') {
                return (
                  <Box display="flex" flexDirection="row" gap={1.5}>
                    <Img
                      src={preferenceOptions[preference.id]}
                      width="200"
                      height="250"
                    />
                    <FileComponent setFile={setFile} file={file} />
                  </Box>
                );
              }
              return null;
            })();

            return (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                key={preference.id}
              >
                <Typography variant="body2">{preference.title}</Typography>
                <Box display="flex" gap={1}>
                  <FormControl size={undefined} sx={{ height: '100%' }}>
                    {form}
                  </FormControl>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleSubmit}>Salvar configurações</Button>
        </Box>
      </Box>
    </Paper>
  );
}

async function loadOrganization(
  organizationId: string,
): Promise<OrganizationEntry> {
  return fetchGetOrganizationEntry({
    pathParams: {
      organizationId: organizationId,
    },
  });
}

async function submitPreferences({
  organizationId,
  file,
  setResponse,
}: {
  organizationId: string;
  file: File;
  setResponse: (response: string) => void;
}) {
  if (file) {
    type allowedContentType = 'image/jpeg' | 'image/jpg' | 'image/png';
    await fetchHandlePostPublicAssets({
      pathParams: {
        organizationId: organizationId,
      },
      body: {
        type: 'logo',
        fileName: file.name,
        contentType: file.type as allowedContentType,
        contentLength: file.size,
      },
    }).then((response: OrganizationPublicAssetsEntry) => {
      uploadFile(response.uploadUrl, file).then(() =>
        setResponse('Preferências salvas com sucesso!'),
      );
    });
  }
}
