import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { MobileDrawer } from '../../../../modules/components/onboarding/dialogs';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  isSmallDevice: boolean;
};

export function DocumentHelperDialog({ open, setOpen, isSmallDevice }: Props) {
  const actions = (
    <Button
      variant={isSmallDevice ? 'outlined' : 'contained'}
      color="secondary"
      sx={{ px: isSmallDevice ? 1.25 : 2.25, py: 1.25 }}
      onClick={() => setOpen(false)}
    >
      Fechar
    </Button>
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: isSmallDevice ? 1 : 0,
        alignItems: 'flex-start',
        textAlign: 'left',
      }}
    >
      <Typography variant="h2">Arquivos para dependentes</Typography>
      <Typography variant="body2">
        Para comprovar parentesco com a pessoa dependente recomendamos anexar os
        seguintes documentos:
        <ul>
          <li>Certidão de nascimento;</li>
          <li>Documento com CPF;</li>
          <li>Certidão de casamento ou união estável (para cônjuges);</li>
        </ul>
        Para dependentes considerados nos cálculos de salário família são
        exigidos também:
        <ul>
          <li>
            Caderneta de vacinação ou equivalente, para crianças de até 6 anos
            de idade;
          </li>
          <li>
            Comprovante de frequência à escola, para crianças com 7 anos ou
            mais;
          </li>
          <li>
            Comprovação de invalidez, confirmada pela perícia médica do INSS
            para dependentes maiores de 14 anos.
          </li>
        </ul>
      </Typography>
    </Box>
  );
  return (
    <>
      <MobileDrawer
        open={open}
        loading={false}
        setOpen={setOpen}
        actions={actions}
        content={content}
      ></MobileDrawer>
      <Dialog
        open={open}
        onClose={setOpen}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </>
  );
}
