import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Typography } from '@mui/material';

import { MobileDrawer } from '../../../../modules/components/onboarding/dialogs';
import {
  SecurePasswordValidator,
  ShowablePasswordField,
} from '../../../../modules/login';
import { TermsOfServiceBox } from '../../terms-of-service/TermsOfServiceBox';

export type Props = {
  hidden: boolean;
  submit: ({
    password,
    error,
  }: {
    password: string;
    error: string;
  }) => Promise<void>;
};

export function StepTwo({ submit, hidden }: Props) {
  const passwordRef = useRef<HTMLDivElement | null>();
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    showTooltip: false,
    showPassword: false,
    isValidPassword: false,
    acceptedTermsOfService: false,
    openTermsOfService: false,
    validation: {
      hasMinLength: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialCharacter: false,
    },
  });

  const showTooltip = () => {
    if (state.isValidPassword) {
      return;
    }
    setState((oldState) => ({
      ...oldState,
      showTooltip: true,
    }));
  };

  const closeTooltip = () => {
    if (state.isValidPassword) {
      setState((oldState) => ({
        ...oldState,
        showTooltip: false,
      }));
    }
  };

  const showPassword = () => {
    setState((oldState) => ({
      ...oldState,
      showPassword: !oldState.showPassword,
    }));
  };

  const setNewPasswordValue = (e: { target: { value: string } }) => {
    const password = e.target.value;
    const hasMinLength = password.length >= 12;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-={};':"\\|,.<>/?]/.test(
      password,
    );
    const isValidPassword =
      hasMinLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialCharacter;
    setState((oldState) => ({
      ...oldState,
      password,
      isValidPassword,
      validation: {
        hasMinLength,
        hasUpperCase,
        hasLowerCase,
        hasNumber,
        hasSpecialCharacter,
      },
    }));
  };

  const { mutate, isError, isLoading } = useMutation(async (e: MouseEvent) => {
    e.preventDefault();
    let error = null;
    if (!state.isValidPassword) {
      error = 'Senha inválida';
    } else if (state.password !== state.confirmPassword) {
      error = 'Senhas estão diferentes';
    } else if (!state.acceptedTermsOfService) {
      error =
        'Para continuar é necessário ler e declarar que aceita os Termos de Uso.';
    }

    if (error) {
      await submit({
        password: state.password,
        error,
      });
      throw new Error(error);
    }

    await submit({
      password: state.password,
      error: null,
    });
  });

  useEffect(() => {
    if (state.isValidPassword && state.showTooltip) {
      closeTooltip();
    }
    if (
      !state.isValidPassword &&
      !state.showTooltip &&
      state.password.length > 0
    ) {
      showTooltip();
    }
  }, [state.isValidPassword, state.showTooltip, state.password]);

  if (hidden) {
    return null;
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            Senha
          </Typography>
          <Box ref={passwordRef}>
            <ShowablePasswordField
              onChange={setNewPasswordValue}
              onMouseOver={showTooltip}
              onBlur={closeTooltip}
              show={state.showPassword}
              setShow={showPassword}
              error={isError && !state.isValidPassword}
              data-testid="password"
            />
          </Box>
          {state.showTooltip && (
            <Box
              boxSizing="border-box"
              px={2}
              py={1.5}
              width="100%"
              boxShadow="rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px"
              borderRadius={1}
            >
              <SecurePasswordValidator {...state.validation} />
            </Box>
          )}
        </Box>
        {!state.showTooltip && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight="500"
            >
              Repita a senha
            </Typography>
            <ShowablePasswordField
              onChange={(e) => {
                setState((oldState) => ({
                  ...oldState,
                  confirmPassword: e.target.value,
                }));
              }}
              show={state.showPassword}
              setShow={showPassword}
              error={isError && state.password !== state.confirmPassword}
              data-testid="confirm"
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
          padding: 1.5,
          borderRadius: 1,
          border: '1px solid',
          borderColor:
            isError && !state.acceptedTermsOfService
              ? 'strokes.error'
              : 'strokes.light',
        }}
      >
        <Checkbox
          checked={state.acceptedTermsOfService}
          color="primaryAlt"
          onChange={() =>
            setState((oldState) => ({
              ...oldState,
              acceptedTermsOfService: !state.acceptedTermsOfService,
            }))
          }
          sx={{
            p: 0,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 0.5,
            padding: 0.5,
          }}
        >
          <Typography variant="button" color="text.primary">
            Aceito os{' '}
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() =>
              setState((oldState) => ({
                ...oldState,
                openTermsOfService: true,
              }))
            }
            sx={{ padding: 0 }}
          >
            termos de uso
          </Button>{' '}
          <Typography variant="button" color="text.primary">
            da plataforma Tako
          </Typography>
        </Box>
      </Box>
      {!state.showTooltip && (
        <LoadingButton
          fullWidth
          color="primaryAlt"
          loading={isLoading}
          onClick={mutate}
          variant="contained"
          sx={{ p: 1.5 }}
          data-testid="submit"
        >
          <Typography variant="body1" color="secondary" fontWeight="700">
            Cadastrar
          </Typography>
        </LoadingButton>
      )}

      <MobileDrawer
        content={<TermsOfServiceBox />}
        open={state.openTermsOfService}
        setOpen={(open) =>
          setState((oldState) => ({ ...oldState, openTermsOfService: open }))
        }
        loading={false}
      />
    </>
  );
}
