import { z } from 'zod';

export function getParsedValue(
  schema: z.ZodTypeAny,
  input: Record<string, unknown>,
): Record<string, string | readonly string[] | number | undefined> {
  if (schema instanceof z.ZodIntersection) {
    return {
      ...getParsedValue(schema._def.left, input),
      ...getParsedValue(schema._def.right, input),
    };
  }

  if (schema instanceof z.ZodObject) {
    return Object.fromEntries(
      Object.entries(schema.shape).map(([key, value]) => {
        try {
          const parsedValue = (value as z.ZodTypeAny).parse(input[key]);
          return [key, parsedValue ?? undefined];
        } catch (error) {
          return [key, undefined];
        }
      }),
    ) satisfies Record<string, string | readonly string[] | number | undefined>;
  }

  return {};
}
