import {
  ColDef,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';

import { Box, ClickAwayListener } from '@mui/material';

import { PayrollInputsConfig } from '@octopus/api';

import { useRef } from 'react';

import { ColumnInfoPopper } from './ColumnInfoPopper';
import { DependentIdentifierCell } from './DependentIdentifierCell';
import { IdentifierHeader } from './IdentifierHeader';
import { newInputColDef } from './inputColDef';
import { RowInfoPopper } from './RowInfoPopper';
import {
  ColumnInfo,
  DEPENDENT_COLUMN_ID,
  PayrollDependentData,
  RowInfo,
} from './types';
import { useAutoSizeTable } from './useAutoSizeTable';
import { useInfoPoppers } from './useInfoPoppers';
import {
  DependentsEdit,
  DependentsState,
  HasPayrollBeenEdited,
} from './useSubmissionState';
import { NoRowsOverlay, copyCell, copyHeader, pasteCell } from './utils';

import { GridReadyEvent } from 'ag-grid-community/dist/types/core/events';

export type DependentsTableProps = {
  organizationId: string;
  companyId: string;
  config: PayrollInputsConfig;
  state: DependentsState;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
  show: boolean;
};

export function DependentsTable({
  organizationId,
  companyId,
  config,
  state: { data, edit },
  hasPayrollBeenEdited,
  show,
}: DependentsTableProps) {
  const gridRef = useRef<AgGridReact>();
  useAutoSizeTable({ gridRef, show });
  const {
    columnInfoState,
    rowInfoState,
    showColumnInfo,
    showRowInfo,
    hideInfoPoppers,
  } = useInfoPoppers();

  const colDefs = prepareColDef({
    config,
    showColumnInfo,
    showRowInfo,
    hideInfoPoppers,
    edit,
    hasPayrollBeenEdited,
  });

  return (
    <ClickAwayListener
      onClickAway={() => gridRef.current?.api.clearRangeSelection()}
    >
      <Box
        className="ag-theme-quartz"
        height="100%"
        width="100%"
        onMouseLeave={hideInfoPoppers}
      >
        <AgGridReact<PayrollDependentData>
          columnDefs={colDefs}
          columnMenu="new"
          copyHeadersToClipboard
          enableFillHandle
          enableRangeSelection
          fillHandleDirection="y"
          getContextMenuItems={() => []}
          getRowId={(data) => `${data.data.payrollId}/${data.data.dependentId}`}
          maintainColumnOrder
          noRowsOverlayComponent={NoRowsOverlay}
          onGridReady={initGrid}
          onUndoStarted={() => edit.undo()}
          onRedoStarted={() => edit.redo()}
          processCellForClipboard={(params) => copyCell(params, config)}
          processCellFromClipboard={(params) => pasteCell(params, config)}
          processHeaderForClipboard={copyHeader}
          reactiveCustomComponents
          ref={gridRef}
          rowData={data}
          suppressMultiRangeSelection
          undoRedoCellEditing
        />
        <ColumnInfoPopper
          open={columnInfoState.open}
          anchorEl={columnInfoState.ref}
          info={columnInfoState.info}
          data={data}
          api={gridRef.current?.api}
          handleClose={hideInfoPoppers}
          setAll={edit.setAll}
        />
        <RowInfoPopper
          organizationId={organizationId}
          companyId={companyId}
          open={rowInfoState.open}
          anchorEl={rowInfoState.ref}
          info={rowInfoState.info}
          api={gridRef.current?.api}
          handleClose={hideInfoPoppers}
        />
      </Box>
    </ClickAwayListener>
  );
}

type ColDefProps = {
  config: PayrollInputsConfig;
  showColumnInfo: (ref: HTMLElement, info: ColumnInfo) => void;
  showRowInfo: (ref: HTMLElement, info: RowInfo) => void;
  hideInfoPoppers: () => void;
  edit: DependentsEdit;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
};

function prepareColDef({
  config,
  showColumnInfo,
  showRowInfo,
  hideInfoPoppers,
  edit,
  hasPayrollBeenEdited,
}: ColDefProps): ColDef<PayrollDependentData, string>[] {
  return [
    {
      colId: DEPENDENT_COLUMN_ID,
      headerName: 'Colaborador / Dependente',
      headerComponent: IdentifierHeader,
      cellRendererParams: {
        showRowInfo,
        hasPayrollBeenEdited,
      },
      cellRenderer: DependentIdentifierCell,
      valueGetter: (params) =>
        `${params.data.name}\t${params.data.employeeId}\t${params.data.nmDependent}`,
      flex: 1,
      pinned: 'left',
      sortable: true,
      resizable: true,
      suppressMovable: true,
      initialSort: 'asc',
      comparator: (a, b) => a.localeCompare(b),
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      minWidth: 280,
      cellStyle: {
        padding: '0',
      },
    },
    ...Object.values(config.payload)
      .filter((entry) => entry.target === 'dependent')
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((entry) =>
        newInputColDef({
          entry,
          showColumnInfo,
          hideInfoPoppers,
          hasBeenEdited: (
            data: PayrollDependentData,
            inputId: string,
            value: string | null,
          ) =>
            edit.hasBeenEdited(
              data.payrollId,
              data.dependentId,
              inputId,
              value,
            ),
          valueGetter: ({
            data: { inputs },
          }: ValueGetterParams<PayrollDependentData, string>) =>
            inputs[entry.id],
          valueSetter: ({
            newValue,
            data: { payrollId, dependentId },
          }: ValueSetterParams<PayrollDependentData, string>) => {
            edit.set(payrollId, dependentId, entry.id, newValue);
            return true;
          },
        }),
      ),
  ];
}

function initGrid({ api }: GridReadyEvent) {
  api.applyColumnState({
    state: [{ colId: DEPENDENT_COLUMN_ID, sort: 'asc' }],
    defaultState: { sort: null },
  });
}
