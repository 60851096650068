import { useCallback, useMemo } from 'react';
import {
  NavigateOptions,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { createURLSearchParamString } from '../utils/createURLSearchParamString';

export function useSubroute<
  const TRouterName extends string = string,
  const TRouteNames extends string = string,
  const TRouteData = unknown,
>(
  routerNameArg: TRouterName,
  subrouteDataByName: Record<TRouteNames, TRouteData>,
) {
  type IRouteName = TRouteNames | `${TRouterName}-${number}`;

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const subroutesPosParam = searchParams.get(`${routerNameArg}navpos`);

  const currentSubRoutePosition = parseInt(subroutesPosParam) || 0;
  const subroutes = useMemo(() => {
    const subroutesWithData = (
      Object.entries(subrouteDataByName) as [TRouteNames, TRouteData][]
    ).map(([name, data]) => {
      return {
        name,
        data,
      };
    });

    return subroutesWithData.map(({ name, data }, i, list) => ({
      name: (name !== null ? name : `${routerNameArg}-${i}`) as IRouteName,
      idx: i,
      isFirst: i === 0,
      isLast: i === list.length - 1,
      data,
    }));
  }, [routerNameArg, subrouteDataByName]);

  const currentSubroute = useMemo(() => {
    if (subroutes.length === 0) {
      return null;
    }
    const currentSubroute = subroutes[currentSubRoutePosition];
    if (!currentSubroute) {
      return null;
    }

    return {
      ...currentSubroute,
      data: {
        ...currentSubroute.data,
        ...location.state,
      } as TRouteData,
    };
  }, [subroutes, currentSubRoutePosition, location.state]);

  const navigateRelative = useCallback(
    (
      amount: number,
      data?: Partial<TRouteData>,
      options?: Partial<NavigateOptions>,
    ) => {
      if (!currentSubroute) {
        throw new Error('No current subroute');
      }

      let newIdx = currentSubroute?.idx + amount;
      if (newIdx <= 0) {
        newIdx = 0;
      }
      if (newIdx >= subroutes.length - 1) {
        newIdx = subroutes.length - 1;
      }

      setSearchParams(
        (previousSearchParams) => {
          return createURLSearchParamString({
            ...Object.fromEntries(previousSearchParams.entries()),
            [`${routerNameArg}navpos`]: newIdx.toString(),
          });
        },
        {
          ...options,
          state: { ...data },
        },
      );
    },
    [routerNameArg, subroutes, currentSubroute, setSearchParams],
  );

  const navigateAbsolute = useCallback(
    (
      routeName: IRouteName,
      data?: Partial<TRouteData>,
      options?: Partial<NavigateOptions>,
    ) => {
      const targetSubRoute = subroutes.find(({ name }) => routeName === name);
      const newIdx = targetSubRoute?.idx;

      if (newIdx != null) {
        setSearchParams(
          (previousSearchParams) => {
            return createURLSearchParamString({
              ...Object.fromEntries(previousSearchParams.entries()),
              [`${routerNameArg}navpos`]: newIdx.toString(),
            });
          },
          {
            ...options,
            state: { ...data },
          },
        );
      }
    },
    [routerNameArg, subroutes, setSearchParams],
  );

  type INavigateRouteArg =
    | number
    | ((subroute: typeof currentSubroute) => number)
    | IRouteName
    | ((subroute: typeof currentSubroute) => IRouteName);
  type INavigateRouteDataArg =
    | Partial<TRouteData>
    | ((subroute: typeof currentSubroute) => Partial<TRouteData>);
  type INavigateRouteOptions =
    | Partial<NavigateOptions>
    | ((subroute: typeof currentSubroute) => Partial<NavigateOptions>);

  const subrouteNavigateCallback = useCallback(
    (
      navigateRouteArg: INavigateRouteArg,
      navigateStateArg?: INavigateRouteDataArg,
      navigateOptionsArg?: INavigateRouteOptions,
    ) => {
      const parsedRouteArg =
        typeof navigateRouteArg === 'function'
          ? navigateRouteArg(currentSubroute)
          : navigateRouteArg;

      const parsedRouteStateArg =
        typeof navigateStateArg === 'function'
          ? navigateStateArg(currentSubroute)
          : navigateStateArg;

      const parsedRouteOptionsArg =
        typeof navigateOptionsArg === 'function'
          ? navigateOptionsArg(currentSubroute)
          : navigateOptionsArg;

      if (typeof parsedRouteArg === 'number') {
        return navigateRelative(
          parsedRouteArg,
          parsedRouteStateArg,
          parsedRouteOptionsArg,
        );
      }

      return navigateAbsolute(
        parsedRouteArg,
        parsedRouteStateArg,
        parsedRouteOptionsArg,
      );
    },
    [navigateRelative, navigateAbsolute, currentSubroute],
  );

  return [currentSubroute, subrouteNavigateCallback, subroutes] as const;
}
