import { useEffect, useState } from 'react';

import { useMaskito } from '@maskito/react';

import { InputAdornment, Typography } from '@mui/material';

import { ContractBRCltEntryRemuneracao } from '@octopus/api';
import { UnidadeSalarioFixo } from '@octopus/esocial/mapper';
import { formatMoney } from '@octopus/formatters';

import { Record, RecordEntry, mapperToOptions } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type RemuneracaoRecordData = {
  remuneracao: ContractBRCltEntryRemuneracao;
};

export type RemuneracaoRecordProps = BaseRecordProps<RemuneracaoRecordData>;

export function RemuneracaoRecord(props: RemuneracaoRecordProps) {
  const { remuneracao } = props.data;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        remuneracao: { ...data.remuneracao, [key]: formatter(value) },
      }));
  };

  const [strSalValue, setStrSalValue] = useState<string>(
    `${formData.remuneracao.vrSalFx}`.replace('.', ','),
  );

  useEffect(() => {
    setStrSalValue(`${formData.remuneracao.vrSalFx}`.replace('.', ','));
  }, [editing]);

  useEffect(() => {
    const salValue = parseFloat(strSalValue.replace(',', '.'));
    updateData((data) => ({
      ...data,
      remuneracao: { ...data.remuneracao, vrSalFx: salValue },
    }));
  }, [strSalValue]);

  const moneyMask = useMaskito({
    options: {
      mask: /^\d*(,\d?\d?)?$/,
    },
  });

  return (
    <Record title="Remuneração" edit={editRecordProps}>
      <RecordEntry
        label="Salário base"
        edit={{
          editing,
          type: 'text',
          onChange: setStrSalValue,
          value: strSalValue,
          mask: moneyMask,
          inputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" color="strokes.heavy">
                  R$
                </Typography>
              </InputAdornment>
            ),
          },
          hasError: hasError('br/remuneracao/vrSalFx'),
        }}
      >
        {formatMoney(remuneracao.vrSalFx)}
      </RecordEntry>
      <RecordEntry
        label="Unidade para base de cálculo"
        edit={{
          type: 'options',
          editing,
          onChange: prepareOnChange('undSalFixo'),
          value: formData.remuneracao.undSalFixo,
          options: mapperToOptions({ mapper: UnidadeSalarioFixo }),
          disabled: true,
          hasError: hasError('br/remuneracao/undSalFixo'),
        }}
      >
        {UnidadeSalarioFixo.getByCode(remuneracao.undSalFixo)}
      </RecordEntry>
      <RecordEntry
        label="Descrição da remuneração variável"
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('dscSalVar'),
          value: formData.remuneracao.dscSalVar,
          hasError: hasError('br/remuneracao/dscSalVar'),
        }}
      >
        {remuneracao.dscSalVar}
      </RecordEntry>
    </Record>
  );
}
