import { EnglishErrorCodes } from './en';
import { PortugueseErrorCodes } from './pt';
import { Locale } from './types';

type EnErrorCodes = keyof typeof EnglishErrorCodes;

const existingKeys = new Set(Object.keys(EnglishErrorCodes));

const errorCodeKeys: Record<EnErrorCodes, EnErrorCodes> = Object.keys(
  EnglishErrorCodes,
).reduce((acc, key) => ({ ...acc, [key]: key }), {}) as Record<
  EnErrorCodes,
  EnErrorCodes
>;

type ErrorCodeKey = keyof typeof errorCodeKeys;

const ErrorCodes = {
  pt: {
    ...PortugueseErrorCodes,
  },
  en: {
    ...EnglishErrorCodes,
  },
};

const translate = (
  key: string | undefined,
  locale: Locale = 'en',
): string | undefined => {
  if (!isErrorCodeKey(key)) {
    return key;
  }
  return ErrorCodes[locale]?.[key];
};

function isErrorCodeKey(key: string | undefined): key is ErrorCodeKey {
  if (!key) {
    return false;
  }
  return existingKeys.has(key);
}

export type { ErrorCodeKey };

export { errorCodeKeys, translate };
