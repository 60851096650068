import { Box, Typography } from '@mui/material';

import UserAvatar from '../../components/UserAvatar';
import { TFieldSelectRenderProps } from '../parseField/types';

type TEmployeeOptionRenderProps = {
  props: React.HTMLAttributes<unknown>;
  option: TFieldSelectRenderProps['select']['options'][number] & {
    content: {
      employeeName: string;
      employeeId: string;
    };
  };
};

export function EmployeeOption({ props, option }: TEmployeeOptionRenderProps) {
  return (
    <Box
      component="li"
      sx={{
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: '12px',
        flex: '1 0 0',
        padding: 0,
      }}
      {...props}
    >
      {option.content.employeeName && (
        <UserAvatar
          avatarProps={{
            ml: 0,
            mr: 0,
            my: 0,
          }}
          name={option.content.employeeName}
          showFullName={false}
        />
      )}
      <Typography variant="body2">{option.content.employeeName}</Typography>
      <Typography
        variant="body2"
        sx={{
          color: '#616161',
        }}
      >
        {option.content.employeeId}
      </Typography>
    </Box>
  );
}

EmployeeOption.canRender = (
  option: TFieldSelectRenderProps['select']['options'][number],
): option is TEmployeeOptionRenderProps['option'] => {
  return option.content && 'employeeName' in option.content;
};
