import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box, Container, Typography } from '@mui/material';

import { PeopleCount } from './PeopleCount';
import { PeopleTable } from './PeopleTable';

export type PeopleProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

export default function People({ organizationId, companyId }: PeopleProps) {
  const navigate = useNavigate();
  const navigateToContract = (contractId: string) => {
    navigate(`${contractId}`);
  };

  const [showTerminated, setShowTerminated] = useState(false);

  return (
    <Box
      pt={9}
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="people-header"
          gap={1}
        >
          <PeopleAltOutlinedIcon
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Pessoas</Typography>
        </Box>

        <PeopleCount organizationId={organizationId} companyId={companyId} />

        <Box py={3.2}>
          <PeopleTable
            organizationId={organizationId}
            companyId={companyId}
            onContractClick={navigateToContract}
            setShowTerminated={setShowTerminated}
            showTerminated={showTerminated}
          />
        </Box>
      </Container>
    </Box>
  );
}
