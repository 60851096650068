import React from 'react';

import { Box, Typography } from '@mui/material';

export type CountProps = {
  [title: string]: number;
};

export function Counts({ props }: { props: CountProps }) {
  return (
    <Box px={0.5}>
      <Box display="flex" gap={7}>
        {Object.entries(props).map(([key, value]) => {
          return <CountItem title={key} count={value} key={key} />;
        })}
      </Box>
    </Box>
  );
}

type CountItemProps = {
  title: string;
  count: number;
};

function CountItem({ title, count }: CountItemProps) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {title}
      </Typography>
      <Typography variant="body1" fontWeight="700">
        {count}
      </Typography>
    </Box>
  );
}
