import { useField } from '@conform-to/react';

import { Check } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, SxProps, Theme, Typography } from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';

import {
  TFieldRenderProps,
  TInputFieldsetRenderProps,
} from '../parseField/types';

import { ErrorLabel } from './commons/ErrorLabel';
import { Label } from './commons/Label';

type FieldProps = {
  field: TFieldRenderProps & TInputFieldsetRenderProps;
  sx?: SxProps<Theme>;
};
const checkboxStyles = {
  width: '18px',
  height: '18px',
  flex: '0 0 auto',
  margin: 0.375,
  marginRight: 1.375,
  boxSizing: 'border-box',
  border: '1.5px solid',
  borderColor: (theme: Theme) => theme.palette.strokes.heavy,
  borderRadius: '4px',

  ':focus': {
    // outline: 'none',
    outline: '1px solid',
    outlineColor: (theme: Theme) => theme.palette.strokes.info,
    borderColor: (theme: Theme) => theme.palette.strokes.info,
  },
};

const checkboxSelectedStyles = {
  borderColor: (theme: Theme) => theme.palette.primary.main,
  backgroundColor: (theme: Theme) => theme.palette.primary.main,
};

const checkboxNotSelectedStyles = {};

export function FieldSelectCheckboxList(props: FieldProps) {
  const [_, formMeta] = useField(props.field.props.name, {
    formId: props.field.props.form,
  });

  const selectAll = () => {
    formMeta.update({
      name: props.field.props.name,
      value: props.field.fields.map((field) => field.input.props.value),
    });
  };

  const clearAll = () => {
    formMeta.update({
      name: props.field.props.name,
      value: [],
    });
  };

  const onKeyDownSelect = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key !== ' ' && event.key !== 'Enter') {
      return;
    }
    const fieldElement = document.activeElement;
    if (fieldElement instanceof HTMLElement) {
      fieldElement.click();
    }

    if (fieldElement instanceof HTMLElement) {
      const isSelectedCheckbox = Boolean(fieldElement.dataset.checkboxSelected);
      const nextFocusElement = fieldElement.parentElement.querySelector(
        isSelectedCheckbox
          ? '[data-checkbox-not-selected]'
          : '[data-checkbox-selected]',
      ) as HTMLElement | null;
      nextFocusElement?.focus();
    }

    event.preventDefault();
  };

  const hasError = props.field.errors?.length > 0;

  return (
    <Box
      component="fieldset"
      {...props.field.props}
      sx={{
        borderWidth: 0,
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Label field={props.field} />

        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Label field={props.field} />
          {hasError && (
            <ErrorLabel
              errorMessage={props.field.errors.join('; ')}
              sx={{ mt: 0, mb: 1, ml: 0.25 }}
            />
          )}
        </Box> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            component="legend"
            variant="caption"
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontWeight: 'bold',
              marginBottom: 2,
              cursor: 'pointer',
              px: 1,
            }}
            onClick={selectAll}
          >
            Selecionar todos
          </Typography>
          <CircleIcon
            sx={{
              width: '4px',
              color: 'strokes.heavy',
              marginBottom: 2,
            }}
          />
          <Typography
            component="legend"
            variant="caption"
            sx={{
              color: (theme) => theme.palette.strokes.heavy,
              fontWeight: 'bold',
              marginBottom: 2,
              cursor: 'pointer',
              px: 1,
            }}
            onClick={clearAll}
          >
            Limpar todos
          </Typography>
        </Box>
      </Box>
      {hasError && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <ErrorLabel
            errorMessage={props.field.errors.join('; ')}
            sx={{ mt: -1, mb: 1, ml: 0.5 }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          flexDirection: 'column',
        }}
      >
        <Box
          component="ul"
          sx={{
            margin: 0,
            padding: 0,
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {props.field.fields.map((childField) => (
            <Box
              component="li"
              {...childField.props}
              sx={{
                flex: '0 0 auto',
                paddingInline: 1,
              }}
            >
              <Box
                component="input"
                {...childField.input.props}
                sx={{
                  display: 'none',
                  [`
                            &:not(:checked) + label [data-checkbox-selected],
                            &:checked + label [data-checkbox-not-selected]
                          `]: {
                    display: 'none',
                  },
                }}
              />
              <Box
                component="label"
                onKeyDown={onKeyDownSelect}
                sx={{
                  display: 'flex',
                  height: '32px',
                  alignItems: 'flex-start',
                  borderRadius: '32px',
                  userSelect: 'none',
                }}
                {...childField.label.props}
              >
                <Box
                  data-checkbox-selected
                  tabIndex={0}
                  sx={{
                    ...checkboxStyles,
                    ...checkboxSelectedStyles,
                  }}
                >
                  <Check
                    sx={{
                      fill: (theme) => theme.palette.primaryAlt.contrastText,
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                  />
                </Box>
                <Box
                  tabIndex={0}
                  data-checkbox-not-selected
                  sx={{
                    ...checkboxStyles,
                    ...checkboxNotSelectedStyles,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    paddingTop: '.18em',
                  }}
                >
                  {childField.label.textContent}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

FieldSelectCheckboxList.uiType = UI_TYPE.SELECT_CHECKBOX_LIST;
FieldSelectCheckboxList.canRender = (
  arg: TFieldRenderProps,
): arg is FieldProps['field'] => {
  if (!('fields' in arg)) {
    return false;
  }

  const isCheckboxSelect = arg.fields.reduce(
    (isCheckboxSelect, fieldOption) => {
      return (
        isCheckboxSelect &&
        'input' in fieldOption &&
        fieldOption.input.props.type === 'checkbox'
      );
    },
    true,
  );

  return isCheckboxSelect;
};
