import { Box, Typography, TypographyProps } from '@mui/material';

export type ExpandableTypographyProps = TypographyProps & {
  children: string;
};

export function ExpandableTypography(props: ExpandableTypographyProps) {
  const { children, sx, ...typographyProps } = props;
  return (
    <Box sx={sx}>
      <Typography
        variant="body2"
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          maxWidth: 'var(--ExpandableTypography-max-width, 100%)',
          position: 'relative',
          mb: -0.75,
          '&:hover .ellipsisName': {
            opacity: 0,
          },
          '&:hover .fullName': {
            px: 0.5,
            backgroundColor: 'text.primary',
            color: 'background.paper',
            borderRadius: 0.5,
            boxShadow:
              '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
            contain: 'paint',
            opacity: 1,
            zIndex: 1000,
            userSelect: 'unset',
          },
        }}
        {...typographyProps}
      >
        <Box
          component={'span'}
          className="ellipsisName"
          sx={{
            display: 'inline-block',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </Box>
        <Box
          component={'span'}
          className="fullName"
          sx={{
            display: 'inline-block',
            opacity: 0,
            zIndex: -1,
            userSelect: 'none',
            position: 'absolute',
          }}
        >
          {children}
        </Box>
      </Typography>
    </Box>
  );
}
