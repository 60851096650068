import { ReactElement, useEffect, useState } from 'react';

import { useMaskito } from '@maskito/react';

import {
  ContractBRCltAddress,
  ContractBRCltEntryContato,
  ContractBRCltEntryEndereco,
  ContractBRCltForeignAddress,
  ContractBRPjEntryContato,
  ContractBRPjEntryEndereco,
} from '@octopus/api';
import {
  Estados,
  Mapper,
  Municipios,
  MunicipiosByEstado,
  Paises,
  TipoLogradouro,
} from '@octopus/esocial/mapper';
import { formatCEP, formatPhoneBR, getOnlyDigits } from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../form/Field/MaskitoOptions';
import { Record, RecordEntry, mapperToOptions } from '../../Record';
import { BaseRecordProps } from '../common';
import { useRecordEdit } from '../useRecordEdit';

type EnderecoEContatoRecordData = {
  endereco: ContractBRCltEntryEndereco | ContractBRPjEntryEndereco | undefined;
  contato: ContractBRCltEntryContato | ContractBRPjEntryContato | undefined;
};

export type EnderecoEContatoRecordProps =
  BaseRecordProps<EnderecoEContatoRecordData>;

export function EnderecoEContatoRecord(props: EnderecoEContatoRecordProps) {
  const {
    data: { endereco, contato },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const setEnderecoProp = (propName: string, propValue: string | number) =>
    updateData((data) => ({
      ...data,
      endereco: { ...data.endereco, [propName]: propValue },
    }));

  const contatoFieldChangeHandler =
    (propName: string, formatter: (val: string) => string = (val) => val) =>
    (propValue: string) =>
      updateData((data) => ({
        ...data,
        contato: { ...data.contato, [propName]: formatter(propValue) },
      }));

  const phoneMask = useMaskito({
    options: MaskitoOptionsBR.phone,
  });

  return (
    <Record title="Endereço e contato" edit={editRecordProps}>
      {[
        ...(endereco?.tipo === 'exterior'
          ? EnderecoExterior({ endereco })
          : EnderecoBrasil({
              endereco,
              editing,
              formState: formData.endereco as ContractBRCltAddress,
              onFormFieldChanged: setEnderecoProp,
              hasError,
            })),
        <RecordEntry
          key="tel"
          label="Telefone"
          edit={{
            editing,
            type: 'text',
            value: formatPhoneBR(formData.contato?.fonePrinc),
            onChange: contatoFieldChangeHandler('fonePrinc', getOnlyDigits),
            mask: phoneMask,
            hasError: hasError('br/contato/fonePrinc'),
          }}
        >
          {formatPhoneBR(contato?.fonePrinc)}
        </RecordEntry>,
        <RecordEntry
          key="email"
          label="Email pessoal"
          edit={{
            editing,
            type: 'text',
            value: formData.contato?.emailPrinc,
            onChange: contatoFieldChangeHandler('emailPrinc'),
            hasError: hasError('br/contato/emailPrinc'),
          }}
        >
          {contato?.emailPrinc}
        </RecordEntry>,
      ]}
    </Record>
  );
}

type EnderecoBrasilCltProps = {
  endereco: ContractBRCltAddress | undefined;
  editing: boolean;
  formState: ContractBRCltAddress | undefined;
  onFormFieldChanged: (
    propName: keyof ContractBRCltAddress,
    propValue: string | number,
  ) => void;
  hasError: (field: string) => boolean;
};

function EnderecoBrasil({
  endereco,
  editing,
  formState,
  onFormFieldChanged,
  hasError,
}: EnderecoBrasilCltProps): ReactElement[] {
  const tipoLogradouro = TipoLogradouro.getByCode(endereco?.tpLograd);
  const logradouro = tipoLogradouro
    ? `${tipoLogradouro} ${endereco?.dscLograd}`
    : endereco?.dscLograd;

  const fieldChangedHandler =
    (
      propName: keyof ContractBRCltAddress,
      formatter: (val: string) => string | number = (val) => val,
    ) =>
    (value: string) =>
      onFormFieldChanged(propName, formatter(value));

  useEffect(() => {
    onFormFieldChanged('tipo', 'brasil');
  }, []);

  const [municipioMapper, setMunicipioMapper] = useState<Mapper>(
    MunicipiosByEstado[endereco?.uf] || Municipios,
  );

  const cepMask = useMaskito({
    options: MaskitoOptionsBR.cep,
  });

  return [
    <RecordEntry
      key="cep"
      label="CEP"
      edit={{
        editing,
        type: 'text',
        onChange: fieldChangedHandler('cep', getOnlyDigits),
        value: formatCEP(formState?.cep),
        disabled: false,
        mask: cepMask,
        hasError: hasError('br/endereco/cep'),
      }}
    >
      {formatCEP(endereco?.cep)}
    </RecordEntry>,
    <RecordEntry
      label="Logradouro"
      edit={{
        editing,
        type: 'options-and-text',
        options: {
          value: formState?.tpLograd,
          options: mapperToOptions({ mapper: TipoLogradouro, sortBy: 'label' }),
          onChange: fieldChangedHandler('tpLograd'),
        },
        text: {
          value: formState?.dscLograd,
          onChange: fieldChangedHandler('dscLograd'),
        },
        hasError:
          hasError('br/empresa/endereco/tpLograd') ||
          hasError('br/empresa/endereco/dscLograd'),
      }}
    >
      {logradouro}
    </RecordEntry>,
    <RecordEntry
      key="num"
      label={'Número'}
      edit={{
        editing,
        type: 'text',
        value: formState?.nrLograd,
        onChange: fieldChangedHandler('nrLograd'),
        hasError: hasError('br/endereco/nrLograd'),
      }}
    >
      {endereco?.nrLograd}
    </RecordEntry>,
    <RecordEntry
      key="compl"
      label={'Complemento'}
      edit={{
        editing,
        type: 'text',
        value: formState?.complemento,
        onChange: fieldChangedHandler('complemento'),
        hasError: hasError('br/endereco/complemento'),
      }}
    >
      {endereco?.complemento}
    </RecordEntry>,
    <RecordEntry
      key="bairro"
      label={'Bairro'}
      edit={{
        editing,
        type: 'text',
        value: formState?.bairro,
        onChange: fieldChangedHandler('bairro'),
        hasError: hasError('br/endereco/bairro'),
      }}
    >
      {endereco?.bairro}
    </RecordEntry>,
    <RecordEntry
      key="uf"
      label={'Unidade Federal'}
      edit={{
        editing,
        type: 'options',
        value: formState?.uf,
        onChange: (value) => {
          onFormFieldChanged('uf', value);
          onFormFieldChanged('codMunic', '');
          setMunicipioMapper(MunicipiosByEstado[value]);
        },
        options: mapperToOptions({ mapper: Estados, sortBy: 'label' }),
        hasError: hasError('br/endereco/uf'),
      }}
    >
      {Estados.getByCode(endereco?.uf)}
    </RecordEntry>,
    <RecordEntry
      key="cidade"
      label={'Cidade / Município'}
      edit={{
        editing,
        type: 'options',
        value: formState?.codMunic,
        disabled: !formState?.uf,
        onChange: fieldChangedHandler('codMunic', parseInt),
        options: municipioMapper
          ?.codes()
          ?.map((value) => ({
            value,
            label: municipioMapper.getByCode(value),
          }))
          ?.sort((a, b) => a.label.localeCompare(b.label)),
        hasError: hasError('br/endereco/codMunic'),
      }}
    >
      {municipioMapper.getByCode(endereco?.codMunic)}
    </RecordEntry>,
    <RecordEntry
      key="pais"
      label={'Pais'}
      edit={{
        editing,
        type: 'text',
        disabled: true,
        value: 'Brasil',
        onChange: () => undefined,
      }}
    >
      Brasil
    </RecordEntry>,
  ];
}

function EnderecoExterior({
  endereco,
}: {
  endereco: ContractBRCltForeignAddress;
}): ReactElement[] {
  return [
    <RecordEntry key="codPost" label="Código Postal">
      {endereco.codPostal}
    </RecordEntry>,
    <RecordEntry key="lograd" label="Logradouro">
      {endereco.dscLograd}
    </RecordEntry>,
    <RecordEntry key="nr" label="Número">
      {endereco.nrLograd}
    </RecordEntry>,
    <RecordEntry key="compl" label="Complemento">
      {endereco.complemento}
    </RecordEntry>,
    <RecordEntry key="bairro" label="Bairro">
      {endereco.bairro}
    </RecordEntry>,
    <RecordEntry key="cidade" label="Cidade / Município">
      {endereco.nmCid}
    </RecordEntry>,
    <RecordEntry key="pais" label="País">
      {Paises.getByCode(endereco.paisResid)}
    </RecordEntry>,
  ];
}
