import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { ErrorOutline } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import sendSuccess from '../../../../../assets/sendSuccess.svg';
import { requestVacationObject } from '../utils/types';

export default function ResultStepView({
  request,
  requestVacationMSV,
}: {
  request: requestVacationObject;
  requestVacationMSV: any;
}) {
  const navigate = useNavigate();
  const { contractId } = useParams();

  if (request?.result?.ok) {
    return successPage(navigate, contractId);
  } else {
    return errorPage({ requestVacationMSV });
  }
}

function errorPage({ requestVacationMSV }: { requestVacationMSV: any }) {
  return (
    <Box
      test-id={'step-box'}
      sx={(theme) => ({
        px: 2,
        pt: 2,
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        bgcolor: theme.palette.background.secondary,
        gap: 2,
        height: '100%',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
          gap: 2,
        }}
      >
        <Box sx={{ flexGrow: 1, alignContent: 'center', pb: 10 }}>
          <Box
            component={ErrorOutline}
            sx={{ height: '64px', width: '64px', mr: 0, color: '#BABABF' }}
          />
          <Typography fontSize={22} fontWeight={650} lineHeight={2}>
            Solicitação com problemas
          </Typography>
          <Typography variant={'subtitle1'} fontWeight={450} fontSize={14}>
            {`Por favor tente novamente.`}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', alignContent: 'flex-end' }}>
          <Button
            color={'primaryAlt'}
            onClick={() => {
              const firstStepName = requestVacationMSV.steps[0].name;
              requestVacationMSV.goTo(firstStepName);
            }}
            sx={{ width: '100%', py: 1, px: 2.5, height: '48px' }}
          >
            Ok, entendi
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function successPage(navigate: NavigateFunction, contractId: string) {
  return (
    <Box
      test-id={'step-box'}
      sx={(theme) => ({
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: theme.palette.background.secondary,
        height: '100%',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexGrow: 1,
          py: 4,
        }}
      >
        <img src={sendSuccess} alt="Enviado com sucesso" />
        <Typography fontSize={24} fontWeight={650} lineHeight={2}>
          Solicitação enviada
        </Typography>
        <Typography variant={'body1'} fontWeight={450}>
          Sua solicitação foi enviada e vamos te notificar assim que todas as
          aprovações forem finalizadas.
        </Typography>
      </Box>
      <Box sx={{ width: '100%', mb: 2 }}>
        <Button
          color={'primaryAlt'}
          onClick={() => {
            navigate(`/vacations/${contractId}/scheduler`);
          }}
          sx={{ width: '100%', py: 1, px: 2.5, height: '48px', gap: '8px' }}
        >
          Ok, entendi
        </Button>
      </Box>
    </Box>
  );
}
