import {
  BASE_UI_TYPE,
  COMPLEX_UI_TYPE,
  LEGACY_UI_TYPE,
} from './UI_TYPE';

export type ILegacySafeUiType = IParsedUiTypeByUiType[IUiType];
export type IUiType = IUiTypesObject[keyof IUiTypesObject];

type ILegacyUiType = ILegacyUiTypeObject[keyof ILegacyUiTypeObject];

type IUiTypesObject = IBaseUiTypeObject &
  IComplexUiTypeObject &
  ILegacyUiTypeObject

type IBaseUiTypeObject = typeof BASE_UI_TYPE;
type ILegacyUiTypeObject = typeof LEGACY_UI_TYPE;
type IComplexUiTypeObject = typeof COMPLEX_UI_TYPE;

type IParsedUiTypeByUiType = Record<IUiType, Exclude<IUiType, ILegacyUiType>>;

function createParsedUiTypeConstant<const T extends IParsedUiTypeByUiType>(
  uiType: T,
): T {
  return uiType;
}

export const uiTypeByLegacyUiType = createParsedUiTypeConstant({
  ...BASE_UI_TYPE,
  ...COMPLEX_UI_TYPE,
  CHECKBOX_LIST: 'CHECKBOX',
  DROPDOWN: 'SELECT',
  DATE_PICKER: 'TEXT_DATE_PICKER',
  DATE_PICKER_YEAR_MONTH: 'TEXT_DATE_PICKER_YEAR_MONTH',
  SELECT_SEARCH_TAG_CLOUD: 'SELECT_CHECKBOX_TAG_CLOUD_SEARCH',
  CHECKBOX_TAG_CLOUD_SEARCH: 'SELECT_CHECKBOX_TAG_CLOUD_SEARCH',
});
