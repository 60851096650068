import { useContext } from 'react';

import { ContractBRPjEntryPrestador } from '@octopus/api';

import { AppContext } from '../../../../../app/context';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type PrestadorDeServicoRecordData = {
  organizationId: string;
  workerId: string;
  emailCorp: string;
  prestador: ContractBRPjEntryPrestador;
};

export type PrestadorDeServicoRecordProps =
  BaseRecordProps<PrestadorDeServicoRecordData>;

export function PrestadorDeServicoRecord(props: PrestadorDeServicoRecordProps) {
  const {
    data: {
      workerId,
      emailCorp,
      prestador: { posicao, gestao },
    },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const { appContext } = useContext(AppContext);

  const costCenters = appContext?.company?.costCenters;
  const costCenter = costCenters?.find(({ id }) => gestao?.costCenterId === id);

  const jobTitles = appContext?.company?.jobTitles;
  const jobTitle = jobTitles?.find(({ id }) => posicao.jobTitleId === id);

  return (
    <Record title="Prestador de serviço" edit={editRecordProps}>
      <RecordEntry
        label="Matrícula / Identificador"
        edit={{
          type: 'text',
          editing,
          value: formData.workerId,
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              workerId: value,
            })),
          hasError: hasError('workerId'),
        }}
      >
        {workerId}
      </RecordEntry>
      <RecordEntry
        label="Cargo"
        isLoading={!jobTitle}
        edit={{
          type: 'options',
          editing,
          value: formData.prestador.posicao.jobTitleId,
          disabled: !jobTitles,
          options: jobTitles
            ?.filter(({ summary: { active } }) => active)
            ?.map(({ id, summary: { name } }) => ({
              value: id,
              label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  jobTitleId: value,
                },
              },
            })),
          hasError: hasError('br/prestador/posicao/jobTitleId'),
        }}
      >
        {jobTitle?.summary?.name}
      </RecordEntry>
      <RecordEntry
        label="Departamento"
        edit={{
          type: 'text',
          editing,
          value: formData.prestador.posicao.departamento,
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  departamento: value,
                },
              },
            })),
          hasError: hasError('br/prestador/posicao/departamento'),
        }}
      >
        {posicao.departamento}
      </RecordEntry>
      <RecordEntry
        label="Centro de custo"
        edit={{
          type: 'options',
          editing,
          disabled: !costCenters,
          value: formData.prestador.gestao?.costCenterId,
          options: [
            { label: 'N/A', value: null },
            ...(costCenters?.map(({ id, name }) => ({
              value: id,
              label: name,
            })) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                gestao: {
                  ...data.prestador.gestao,
                  costCenterId: value,
                  nomeCentroCusto:
                    costCenters?.find(({ id }) => id === value)?.summary
                      ?.name ?? null,
                  codigoCentroCusto:
                    costCenters?.find(({ id }) => id === value)?.summary
                      ?.code ?? null,
                },
              },
            })),
          hasError: hasError('br/prestador/gestao/costCenterId'),
        }}
      >
        {costCenter?.name}
      </RecordEntry>
      <RecordEntry
        label="Email corporativo"
        edit={{
          type: 'text',
          editing,
          value: formData.emailCorp,
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              emailCorp: value,
            })),
          hasError: hasError('br/emailCorp'),
        }}
      >
        {emailCorp}
      </RecordEntry>
    </Record>
  );
}
