import { useCallback, useState } from 'react';

export function useDOMElement() {
  const [element, setElement] = useState(null);
  const elementCallbackRef = useCallback((newElement: Element) => {
    setElement(newElement);
  }, []);

  return {
    element,
    ref: elementCallbackRef,
  };
}
