import { SvgIconComponent } from '@mui/icons-material';
import { createSvgIcon } from '@mui/material';

export function AutonomoReceiptIcon(): SvgIconComponent {
  return createSvgIcon(
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="receipt2">
        <path
          id="Vector"
          d="M9.33398 5.33333H7.66732C7.4021 5.33333 7.14775 5.43869 6.96021 5.62623C6.77267 5.81376 6.66732 6.06812 6.66732 6.33333C6.66732 6.59855 6.77267 6.8529 6.96021 7.04044C7.14775 7.22798 7.4021 7.33333 7.66732 7.33333H8.33398C8.5992 7.33333 8.85356 7.43869 9.04109 7.62623C9.22863 7.81376 9.33398 8.06812 9.33398 8.33333C9.33398 8.59855 9.22863 8.8529 9.04109 9.04044C8.85356 9.22798 8.5992 9.33333 8.33398 9.33333H6.66732M8.00065 9.33333V10.3333M8.00065 4.33333V5.33333M3.33398 14V3.33333C3.33398 2.97971 3.47446 2.64057 3.72451 2.39052C3.97456 2.14048 4.3137 2 4.66732 2H11.334C11.6876 2 12.0267 2.14048 12.2768 2.39052C12.5268 2.64057 12.6673 2.97971 12.6673 3.33333V14L10.6673 12.6667L9.33398 14L8.00065 12.6667L6.66732 14L5.33398 12.6667L3.33398 14Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>,
    'AutonomoIcon',
  );
}
