import { ContractStatus } from '@octopus/api';

export const contractStatuses: {
  [key in ContractStatus]: ContractStatus;
} = {
  active: 'active',
  onAdmission: 'onAdmission',
  onLeave: 'onLeave',
  terminated: 'terminated',
} as const;
