import { ChangeEventHandler, MouseEvent, useState } from 'react';

import { TablePagination } from '@mui/material';

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];

export type PaginationOptions = {
  rowsPerPageOptions?: ReadonlyArray<number>;
  initialRowsPerPageOptionIndex?: number;
};

export type PaginationProps = {
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: ReadonlyArray<number>;
  handleChangePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement>;
};

export function usePagination(options?: PaginationOptions): PaginationProps {
  const rowsPerPageOptions =
    options?.rowsPerPageOptions ?? DEFAULT_ROWS_PER_PAGE_OPTIONS;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageOptions[options?.initialRowsPerPageOptionIndex ?? 0] as number,
  );

  const handleChangePage = (
    _: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return {
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
  };
}

export function TablePaginationFooter({
  totalRowCount,
  paginationProps,
}: {
  totalRowCount: number;
  paginationProps: PaginationProps;
}) {
  const {
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
  } = paginationProps;

  if (totalRowCount <= (rowsPerPageOptions[0] as number)) {
    return null;
  }

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={totalRowCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        '*': {
          color: 'text.secondary',
          fontWeight: '500',
        },
        borderTop: '1px solid',
        borderColor: 'strokes.default',
      }}
    />
  );
}
