import { createFFlagsComponent } from './createFFlagsComponent';
import { TFFlagsContext } from './createFFlagsContext';
import { createFFlagsInstance } from './createFFlagsInstance';
import { TFlagNameSetup } from './TFlagNameSetup';

export function createFFlagsHook<const TFlagName extends string>(
  flagNames: TFlagNameSetup<TFlagName>,
  flagContext: TFFlagsContext<TFlagName>,
) {
  const FFlags = createFFlagsComponent(flagNames, flagContext);

  return function useFFlags() {
    const fflags = createFFlagsInstance(flagContext);

    return {
      FFlags,
      fflags,
    };
  };
}
