import { KeyboardEvent, useRef } from 'react';

import { Check } from '@mui/icons-material';
import { Box, SxProps, Theme, Typography } from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

type FieldCheckboxProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
  sx?: SxProps<Theme>;
};

const commonCardSxProps: SxProps<Theme> = {
  display: 'inline-flex',
  paddingBlock: 1.375,
  paddingInline: 1,
  boxSizing: 'border-box',
  gap: 1,
  alignItems: 'center',
  width: '100%',
  border: '1px solid',
  borderRadius: '8px',
  borderColor: (theme) => theme.palette.strokes.light,
  cursor: 'pointer',
};

const commonLabelSxProps: SxProps<Theme> = {};

const commonCheckboxProps: SxProps<Theme> = {
  width: '18px',
  height: '18px',
  flex: '0 0 auto',
  margin: 0.375,
  boxSizing: 'border-box',
  border: '1.5px solid',
  borderColor: (theme) => theme.palette.strokes.heavy,
  borderRadius: '4px',
};

const LabelElement = ({
  field,
}: {
  field: TFieldRenderProps & TFieldInputRenderProps;
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>
    <Typography
      variant="body2"
      sx={{
        ...commonLabelSxProps,
      }}
    >
      {field.label.textContent}
    </Typography>
    <Typography
      variant="caption"
      color="text.primary"
      sx={{
        ...commonLabelSxProps,
      }}
    >
      {field.input.props.placeholder}
    </Typography>
  </Box>
);
export function FieldCheckboxCard(props: FieldCheckboxProps) {
  const { field } = props;

  const onKeyDownSelect = (event: KeyboardEvent) => {
    if (event.key !== ' ' && event.key !== 'Enter') return;
    const fieldElement = document.activeElement.parentElement;
    fieldRef.current?.click();
    const labelElement = fieldRef.current?.checked
      ? fieldElement.querySelector('[data-label-checked]')
      : fieldElement.querySelector('[data-label-unchecked]');

    if (labelElement instanceof HTMLElement) {
      labelElement.focus();
    }

    event.preventDefault();
  };

  const fieldRef = useRef(null);

  return (
    <Box
      {...field.props}
      tabIndex={0}
      onKeyDown={onKeyDownSelect}
      sx={{
        flex: '1 0 auto',
        outline: 'none',
        label: {
          transition: 'all 0.2s',
        },
        '&:focus label': {
          border: '1px solid',
          borderColor: (theme) => theme.palette.strokes.info,
        },
        '&:hover label, &:focus label': {
          boxShadow:
            '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
        },
      }}
    >
      <Box
        component="input"
        {...field.input.props}
        defaultChecked={field.input.props.defaultChecked}
        ref={fieldRef}
        sx={{
          display: 'none',
          [`
            &:checked ~ [data-label-unchecked],
            &:not(:checked) ~ [data-label-checked]
          `]: {
            display: 'none',
          },
        }}
      />
      <Box
        component="label"
        sx={{
          ...commonCardSxProps,
        }}
        {...field.label.props}
        data-label-checked
      >
        <Box
          component="span"
          role="presentation"
          sx={{
            ...commonCheckboxProps,
            backgroundColor: (theme) => theme.palette.primary.main,
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Check
            sx={{
              fill: '#fff',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </Box>
        <LabelElement field={field} />
      </Box>
      <Box
        component="label"
        sx={{
          ...commonCardSxProps,
        }}
        {...field.label.props}
        data-label-unchecked
      >
        <Box
          component="span"
          role="presentation"
          sx={{
            ...commonCheckboxProps,
          }}
        ></Box>
        <LabelElement field={field} />
      </Box>
    </Box>
  );
}

FieldCheckboxCard.uiType = UI_TYPE.CHECKBOX_CARD;
FieldCheckboxCard.canRender = (
  field: TFieldRenderProps,
): field is FieldCheckboxProps['field'] => {
  return 'input' in field && field.input.props.type === 'checkbox';
};
