import { Mapper } from './mapper';

export const DEFAULT_TIPO_ADMISSAO = 1;

export const TipoAdmissao = Mapper.from({
  1: 'Admissão comum',
  2: 'Transferência de empresa do mesmo grupo econômico ou transferência entre órgãos do mesmo Ente Federativo',
  3: 'Transferência de empresa consorciada ou de consórcio',
  4: 'Transferência por motivo de sucessão, incorporação, cisão ou fusão',
  5: 'Transferência do empregado doméstico para outro representante da mesma unidade familiar',
  6: 'Mudança de CPF',
  7: 'Transferência quando a empresa sucedida é considerada inapta por inexistência de fato',
});
