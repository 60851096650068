import React from 'react';

import { Box, Button, Typography } from '@mui/material';

export function SegmentedControls<T extends string>({
  options,
  initialValue,
  onChange,
}: {
  options: {
    label: string;
    target: T;
  }[];
  onChange: (value: T) => void;
  initialValue?: T;
}) {
  const [selected, setSelected] = React.useState<T>(
    initialValue ?? options[0].target,
  );

  React.useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bgcolor="background.default"
      width="100%"
      height="40px"
      borderRadius="12px"
    >
      {options.map((option) => (
        <SegmentedButton
          key={option.target}
          label={option.label}
          target={option.target}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </Box>
  );
}

function SegmentedButton<T extends string>({
  label,
  target,
  selected,
  setSelected,
}: {
  label: string;
  target: T;
  selected: T;
  setSelected: (mode: T) => void;
}) {
  return (
    <Button
      onClick={() => setSelected(target)}
      sx={(theme) => ({
        width: '100%',
        m: 0.5,
        backgroundColor:
          selected === target ? 'background.paper' : 'background.default',
        border: 'none',
        boxShadow: selected === target ? theme.palette.shadows.heavy : 0,
        '&:hover': {
          backgroundColor:
            selected === target ? 'background.paper' : 'background.default',
          boxShadow: selected === target ? theme.palette.shadows.heavy : 0,
        },
        '&:active': {
          backgroundColor: theme.palette.strokes.heavy,
        },
      })}
    >
      <Typography variant="caption">{label}</Typography>
    </Button>
  );
}
