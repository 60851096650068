import EnErrorCodes from '../en/payroll';

const PayrollErrorCodes: Record<keyof typeof EnErrorCodes, string> = {
  'RE002/PC001': 'Estagiario pode criar apenas folha mensal ou de rescisão',
  'RE002/PC002':
    'Diretor sem FGTS pode criar apenas folha mensal ou de rescisão',
  'RE002/PC003':
    'Não é possível criar rescisão para colaborador em gozo de férias',
  'RE002/PC004':
    'Não é possível criar rescisão para colaborador com férias planejadas',
  'RE002/PC005':
    'Não é possível criar rescisão para colaborador durante suas férias',
  'RE002/PC006': 'Autonomo só pode criar folha de RPA',
  'RE002/PC007': 'Contrato esta inativo, folha não pode ser criada',
  'RE002/PC008':
    'Contract é ativo, não pode criar folha de rescisão complementar',

  PAY00001: 'A data de pagamento não pode ser depois do 5o dia útil do mês',
  PAY00002: 'Tipo deve ser elemento para alvos dependentes',
  PAY00003: 'O tipo de payload deve ser moeda para elementos',
  PAY00004:
    'A etiqueta deve ser rendimentos, deduções ou informativa para elementos',
  PAY00005: 'A etiqueta deve ser eventos para tipo evento',
  PAY00006: 'O alvo deve ser funcionário para tipo evento',
  PAY00007:
    'O tipo de payload deve ser horas ou número para horasTrabalhadasEvent',
  PAY00008: 'SubTipo é necessário para horasTrabalhadasEvent',
  PAY00009: 'SubTipo inválido',
  PAY00010: 'Percentual de horas extras é necessário',
  PAY00011: 'O percentual de horas extras deve ser um valor único',
  PAY00012: 'O percentual de horas extras deve ser um número',
  PAY00013: 'Nenhum parâmetro extra é esperado para este evento',
  PAY00014: 'O tipo de payload deve ser dataRange para faltasJustificadas',
  PAY00015: 'Motivo é necessário para faltasJustificadas',
  PAY00016: 'Motivo inválido para faltasJustificadas',
  PAY00017: 'Apenas um parâmetro extra é permitido para faltasJustificadas',
  PAY00018: 'Parâmetro extra inválido para faltasJustificadas',
  PAY00019: 'O tipo de payload deve ser dias para faltasInjustificadas',
  PAY00020: 'Apenas um parâmetro extra é permitido para faltasInjustificadas',
  PAY00021: 'Parâmetro extra inválido para faltasInjustificadas',
  PAY00022: 'O tipo de payload deve ser moeda para adiantamentoSalarial',
  PAY00023: 'Apenas um parâmetro extra é permitido para adiantamentoSalarial',
  PAY00024: 'Parâmetro extra inválido para adiantamentoSalarial',
  PAY00025: 'O tipo de payload deve ser dataRange para afastamento',
  PAY00026: 'Dois parâmetros extras são necessários para afastamento',
  PAY00027: 'Motivo inválido para afastamento',
  PAY00028: 'faltasJustificadasDisponiveis deve ser um número',
  PAY00029:
    'O tipo de payload deve ser dataRange para afastamentoPrevidenciario',
  PAY00030: 'Um parâmetro extra é necessário para afastamentoPrevidenciario',
  PAY00031: 'Motivo inválido para afastamentoPrevidenciario',
  PAY00032: 'O tipo de payload deve ser dataRange para licencaMaternidade',
  PAY00033: 'Um parâmetro extra é permitido para licencaMaternidade',
  PAY00034: 'Parâmetro extra inválido para licencaMaternidade',
  PAY00035: 'Tipo de evento não suportado',
};

export default PayrollErrorCodes;
