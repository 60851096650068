import { type JsonSchema, jsonSchemaToZod } from 'json-schema-to-zod';
import { type ZodTypeAny, z } from 'zod';
import { zodToJsonSchema } from 'zod-to-json-schema';

import {
  FormDefinition,
  FormFieldBranchingDefinition,
  FormFieldNormalDefinition,
  FormFieldSetDefinition,
  FormUIType,
} from '@octopus/api';

import {
  IFieldAnyDefinition,
  IFieldsetBranchingDefinition,
  IFieldsetDefinition,
  IFormDefinition,
  ISerializedFieldDefinition,
  ISerializedFieldsetBranchingDefinition,
  ISerializedFieldsetDefinition,
  ISerializedFormDefinition,
  isField,
  isFieldset,
  isFieldsetBranching,
  isSerializedField,
  isSerializedFieldset,
  isSerializedFieldsetBranching,
} from './formFieldDefinitions/formFieldDefinitions';

function toApiModel(formDefinition: IFormDefinition): FormDefinition {
  return formDefinition.map((definition) => {
    if (isField(definition)) {
      return mapFieldNormalDefinitionToApiModel(definition);
    }
    if (isFieldset(definition)) {
      return mapFieldSetDefinitionToApiModel(definition);
    }
    if (isFieldsetBranching(definition)) {
      return mapFieldBranchingDefinitionToApiModel(definition);
    }
    throw Error('Unknown definition type');
  });
}

function mapFieldNormalDefinitionToApiModel(
  definition: IFieldAnyDefinition,
): FormFieldNormalDefinition {
  return {
    ...definition,
    type: zodToJsonSchema(definition.type),
    uiType: definition.uiType as FormUIType,
  } as FormFieldNormalDefinition;
}

function mapFieldSetDefinitionToApiModel(
  definition: IFieldsetDefinition,
): FormFieldSetDefinition {
  return {
    ...definition,
    fields: toApiModel(definition.fields),
  } as FormFieldSetDefinition;
}

function mapFieldBranchingDefinitionToApiModel(
  definition: IFieldsetBranchingDefinition,
): FormFieldBranchingDefinition {
  return {
    ...definition,
    uiType: definition.uiType as FormUIType,
    fieldsOptions: definition.fieldsOptions.map((fieldOption) => ({
      ...fieldOption,
      fields: toApiModel(fieldOption.fields),
    })),
  } as FormFieldBranchingDefinition;
}

function fromApiModel(
  formDefinition: ISerializedFormDefinition,
): IFormDefinition {
  return formDefinition.map((definition) => {
    if (isSerializedField(definition)) {
      return mapToFieldNormalDefinition(definition);
    }
    if (isSerializedFieldset(definition)) {
      return mapToFieldSetDefinition(definition);
    }
    if (isSerializedFieldsetBranching(definition)) {
      return mapToFieldBranchingDefinition(definition);
    }
    throw Error('Unknown definition type');
  });
}

const saferEval = ({
  args = {},
  expression,
}: {
  args?: {
    [k: string]: unknown;
  };
  expression: string;
}) => {
  const blockedGlobalProps = [
    'window',
    'document',
    'global',
    'eval',
    'location',
    'history',
    'localStorage',
    'sessionStorage',
    'XMLHttpRequest',
    'fetch',
    'console',
    'self',
    'setTimeout',
    'setInterval',
    'Promise',
  ];

  const allArgs = [...Object.keys(args), ...blockedGlobalProps];
  const evalFunction = new Function(...allArgs, `return ${expression}`).bind(
    '',
  );
  return evalFunction(...Object.values(args));
};

function mapToFieldNormalDefinition(
  definition: ISerializedFieldDefinition,
): IFieldAnyDefinition {
  const zodSchema = saferEval({
    args: { z },
    expression: jsonSchemaToZod(definition.type as JsonSchema, {
      module: 'none',
    }),
  }) as ZodTypeAny;

  return {
    ...definition,
    type: zodSchema,
  };
}

function mapToFieldSetDefinition(
  definition: ISerializedFieldsetDefinition,
): IFieldsetDefinition {
  return {
    ...definition,
    fields: fromApiModel(definition.fields),
  };
}

function mapToFieldBranchingDefinition(
  definition: ISerializedFieldsetBranchingDefinition,
): IFieldsetBranchingDefinition {
  return {
    ...definition,
    fieldsOptions: definition.fieldsOptions.map((fieldOption) => ({
      ...fieldOption,
      fields: fromApiModel(fieldOption.fields),
    })),
  };
}

export const formDefinitionsAdapter = {
  toApiModel,
  fromApiModel,
};
