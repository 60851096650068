import { FieldMetadata } from '@conform-to/react';

import {
  AUTO_COMPLETE_OPTION_VALUE,
  IFieldCollectionValueWithOptionsDefinition,
  IFieldSingleValueWithOptionsDefinition,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { parseFieldSelect } from './parseFieldSelect';
import { parseFieldText } from './parseFieldText';
import { TFieldRenderProps, TFieldsetRenderProps } from './types';

export function parseFieldSelectAutoComplete(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldMetadata: FieldMetadata<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchFieldMeta: FieldMetadata<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsSelectMeta: FieldMetadata<any>,
  // eslint-disable-next-lizne @typescript-eslint/no-explicit-any
  optionsTimestampMeta: FieldMetadata<any>,
  fieldDefinition:
    | IFieldCollectionValueWithOptionsDefinition
    | IFieldSingleValueWithOptionsDefinition,
  fieldGroupName = '',
): TFieldRenderProps & TFieldsetRenderProps {
  const autoCompleteOptionDefinition = fieldDefinition.options.filter(
    (option) => option.value === AUTO_COMPLETE_OPTION_VALUE,
  )[0];

  const payloadField = parseFieldText(fieldMetadata, {
    label: fieldDefinition.label,
    name: fieldDefinition.name,
  });

  const searchTextField = parseFieldText(
    searchFieldMeta,
    {
      label: `${autoCompleteOptionDefinition.label}`,
      name: searchFieldMeta.name,
      value: fieldDefinition.value,
      placeholder: fieldDefinition.placeholder,
      info: fieldDefinition.info,
    },
    fieldGroupName,
  );

  const searchResultSelectField = parseFieldSelect(
    optionsSelectMeta,
    {
      label: `${autoCompleteOptionDefinition.label} (options)`,
      name: optionsSelectMeta.name,
    },
    fieldGroupName,
  );

  const searchResultTimestampField = parseFieldText(
    optionsTimestampMeta,
    {
      label: `${autoCompleteOptionDefinition.label} (options time)`,
      name: optionsTimestampMeta.name,
    },
    fieldGroupName,
  );

  const fieldRenderProps: TFieldRenderProps & TFieldsetRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName: '',
    errors: fieldDefinition.errors,
    props: {
      key: fieldGroupName,
      id: fieldGroupName,
      name: fieldGroupName,
      form: payloadField.input.props.form,
    },
    label: {
      textContent: autoCompleteOptionDefinition.label,
      props: {
        htmlFor: fieldGroupName,
      },
    },
    fields: [
      payloadField,
      searchTextField,
      searchResultSelectField,
      searchResultTimestampField,
    ],
  };

  return fieldRenderProps;
}
