import { AdmissionDraftAttachmentType } from '@octopus/api';

export type Dependent = {
  id?: string; // uuid
  nome: string;
  parentesco: {
    type: string;
    descrDep: string;
  };
  dtNascto: string;
  cpf: string;
  sexo: string;
  incTrab: 'true' | 'false';
  calculos: string[];
  file?: {
    type: AdmissionDraftAttachmentType;
    name: string;
    url: string;
    id: string;
  };
};

export function extractParentescoType(dependent: Dependent): string {
  if (!dependent?.parentesco) {
    return '';
  }

  const found = Object.entries(dependent?.parentesco).find(([k]) =>
    k.startsWith('type'),
  );

  if (!found) {
    return '';
  }

  const [_, type] = found as string[];
  return type;
}
