import { DocumentReviewFinishDialog } from './components/DocumentReviewFinishDialog';
import { DocumentReviewStartDialog } from './components/DocumentReviewStartDialog';

type Props = {
  startDialog: {
    open: boolean;
    setOpen: (open: boolean) => void;
    cancelClick: () => void;
    confirmClick: () => void;
  };
  finishDialog: {
    open: boolean;
    setOpen: (open: boolean) => void;
    cancelClick: () => void;
    confirmClick: () => void;
  };
};

export function AdmissionReviewDialogs({ startDialog, finishDialog }: Props) {
  return (
    <>
      <DocumentReviewStartDialog {...startDialog} />
      <DocumentReviewFinishDialog {...finishDialog} />
    </>
  );
}
