import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from '@mui/material';

import { getAvatarColor } from '../../utils';

export type UserAvatarProps = {
  name: string;
  showFullName?: boolean;
  size?: number;
  avatarProps?: {
    border?: string;
    ml?: number;
    mr?: number;
    my?: number;
    color?: string;
  } & AvatarProps;
  avatarTextProps?: {
    fontSize?: string;
    fontWeight?: string;
  } & TypographyProps;
  textProps?: TypographyProps;
  expandNameOnHover?: boolean;
} & BoxProps;

export default function UserAvatar({
  name,
  showFullName = true,
  size = 24,
  avatarProps,
  avatarTextProps,
  textProps,
  expandNameOnHover = false,
  ...props
}: UserAvatarProps) {
  const {
    border = '0.5px solid rgba(0, 0, 0, 0.07)',
    ml = 2,
    mr = 1.5,
    my = 0.75,
    color,
    ...restAvatarProps
  } = avatarProps || {};
  const { fontWeight, fontSize, ...restAvatarTextProps } =
    avatarTextProps || {};
  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...props}>
      <Avatar
        sx={(theme) => ({
          width: `${size}px`,
          height: `${size}px`,
          my,
          mr,
          ml,
          backgroundColor: color ? color : getAvatarColor(name, theme),
          border,
          boxSizing: 'border-box',
        })}
        {...restAvatarProps}
      >
        <Typography
          variant="caption"
          sx={(theme) => ({
            fontWeight: fontWeight
              ? fontWeight
              : theme.typography.caption.fontWeight,
            fontSize: fontSize ? fontSize : theme.typography.caption.fontSize,
          })}
          {...restAvatarTextProps}
        >
          {name.length > 0 ? name.charAt(0).toUpperCase() : 'O'}
        </Typography>
      </Avatar>
      {showFullName && (
        <Typography
          component={'span'}
          variant="body2"
          {...textProps}
          sx={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            maxWidth: 'var(--UserAvatar-name-max-width, auto)',
            position: 'relative',
            ...(expandNameOnHover && {
              '&:hover .ellipsisName': {
                opacity: 0,
              },
              '&:hover .fullName': {
                px: 0.5,
                backgroundColor: 'text.primary',
                color: 'background.paper',
                borderRadius: 0.5,
                boxShadow:
                  '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
                contain: 'paint',
                opacity: 1,
                zIndex: 1000,
                userSelect: 'unset',
              },
            }),
          }}
        >
          <Box
            component={'span'}
            className="ellipsisName"
            sx={{
              display: 'inline-block',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Box>
          <Box
            component={'span'}
            className="fullName"
            sx={{
              display: 'inline-block',
              opacity: 0,
              zIndex: -1,
              userSelect: 'none',
              position: 'absolute',
            }}
          >
            {name}
          </Box>
        </Typography>
      )}
    </Box>
  );
}
