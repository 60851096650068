import { RefObject } from 'react';

import { Box } from '@mui/material';

import {
  ContractBRPjEntry,
  ContractBRPjEntryContrato,
  ContractEntry,
  ContractStatus,
  useGetCompanyEntry,
  useGetCostCenterEntry,
  useGetJobTitleEntry,
} from '@octopus/api';
import {
  banksList,
  cnaeList,
  contractStatuses,
  contractTypes,
  getWorkerId,
  isBRPjContract,
} from '@octopus/contract-types';
import {
  capitalize,
  formatCNAE,
  formatCNPJ,
  formatDateBR,
  formatMoney,
} from '@octopus/formatters';

import {
  Record,
  RecordEntry,
  RecordGroup,
} from '../../modules/components/Record';
import { StatusBadge } from '../../modules/people/person/components/StatusBadge';
import { QueryResult } from '../../modules/types';

export type ContractDetailsProps = {
  contractQuery: QueryResult<ContractEntry>;
  showExpandIcon: boolean;
  contractDetailsRef?: RefObject<Element>;
};

export function PjContractDetails({
  contractDetailsRef,
  showExpandIcon,
  contractQuery,
}: ContractDetailsProps) {
  const { isLoading, data: contractInfo } = contractQuery;
  if (
    (!isLoading && (!contractInfo || !contractInfo.br)) ||
    !isBRPjContract(contractInfo)
  ) {
    return null;
  }

  const { organizationId, companyId, br } = contractInfo ?? {};
  const { pagamento, contrato, empresa, prestador, emailCorp } = br ?? {};

  return (
    <Box ref={contractDetailsRef} data-testid="pj-contract-details">
      <RecordGroup showExpandIcon={showExpandIcon} title="Dados Contratuais">
        <Situacao
          status={contractInfo.status}
          contractPj={br.contrato}
          isLoading={isLoading}
        />
        <Prestador
          organizationId={organizationId}
          prestador={prestador}
          isLoading={isLoading}
          workerId={getWorkerId(contractInfo)}
        />
        <Contratada empresa={empresa} isLoading={isLoading} />
        <LocalPrestacao
          organizationId={organizationId}
          companyId={companyId}
          isLoading={isLoading}
        />
        <Contrato
          organizationId={organizationId}
          emailCorp={emailCorp}
          contrato={contrato}
          prestador={prestador}
          isLoading={isLoading}
        />
        <Pagamento pagamento={pagamento} isLoading={isLoading} />
        <DadosPagamento pagamento={pagamento} isLoading={isLoading} />
      </RecordGroup>
    </Box>
  );
}

function Situacao({
  status,
  isLoading,
  contractPj,
}: {
  contractPj: ContractBRPjEntryContrato;
  status: ContractStatus;
  isLoading: boolean;
}) {
  return (
    <Record title="Situação">
      <RecordEntry label={'Situação'} isLoading={isLoading}>
        <StatusBadge status={status} contractType={contractTypes.brPj} />
      </RecordEntry>
      {status === contractStatuses.terminated ? (
        <RecordEntry label={'Data de término do contrato'}>
          {formatDateBR(contractPj.termino)}
        </RecordEntry>
      ) : (
        // TODO add afastamento
        <div></div>
      )}
    </Record>
  );
}

function Prestador({
  organizationId,
  prestador,
  isLoading,
  workerId,
}: {
  organizationId: string;
  prestador: ContractBRPjEntry['prestador'];
  isLoading: boolean;
  workerId: string;
}) {
  const { data: jobTitleData, isLoading: loadingJobTitle } =
    useGetJobTitleEntry(
      {
        pathParams: {
          organizationId: organizationId ?? '',
          jobTitleId: prestador.posicao.jobTitleId ?? '',
        },
      },
      {
        enabled: !!organizationId && !!prestador.posicao.jobTitleId,
      },
    );

  return (
    <Record title="Prestador de serviço">
      <RecordEntry label="Identificador" isLoading={isLoading}>
        {workerId}
      </RecordEntry>

      <RecordEntry label="Cargo" isLoading={loadingJobTitle}>
        {jobTitleData?.name}
      </RecordEntry>
    </Record>
  );
}

function Contratada({
  empresa,
  isLoading,
}: {
  empresa: ContractBRPjEntry['empresa'];
  isLoading: boolean;
}) {
  return (
    <Record title="Contratada">
      <RecordEntry label="Razão social" isLoading={isLoading}>
        {empresa.razaoSocial}
      </RecordEntry>
      <RecordEntry label="CNPJ" isLoading={isLoading}>
        {formatCNPJ(empresa.cnpj)}
      </RecordEntry>
      <RecordEntry label="CNAE" isLoading={isLoading}>
        {`${formatCNAE(empresa.cnae)} - ${cnaeList[empresa.cnae]}`}
      </RecordEntry>
      <RecordEntry label="Enquadramento" isLoading={isLoading}>
        {capitalize(empresa.enquadramentoTributario)}
      </RecordEntry>
    </Record>
  );
}

function Contrato({
  organizationId,
  emailCorp,
  contrato,
  prestador,
  isLoading,
}: {
  organizationId: string;
  emailCorp: string;
  contrato: ContractBRPjEntry['contrato'];
  prestador: ContractBRPjEntry['prestador'];
  isLoading: boolean;
}) {
  const { data: costCenterData, isLoading: loadingCostCenter } =
    useGetCostCenterEntry(
      {
        pathParams: {
          organizationId: organizationId ?? '',
          costCenterId: prestador.gestao?.costCenterId ?? '',
        },
      },
      {
        enabled: !!organizationId && !!prestador.gestao?.costCenterId,
      },
    );

  return (
    <Record title="Contrato">
      <RecordEntry label="Assinado em" isLoading={isLoading}>
        {contrato.dataAssinatura ? formatDateBR(contrato.dataAssinatura) : '--'}
      </RecordEntry>
      <RecordEntry label="Validade" isLoading={isLoading}>
        {formatDateBR(contrato.validade)}
      </RecordEntry>
      <RecordEntry label="Renovação" isLoading={isLoading}>
        {renovacaoLabel[contrato.renovacao]}
      </RecordEntry>
      <RecordEntry label="Data de início do contrato" isLoading={isLoading}>
        {formatDateBR(contrato.inicio)}
      </RecordEntry>
      <RecordEntry label="Data de término do contrato" isLoading={isLoading}>
        {formatDateBR(contrato.termino)}
      </RecordEntry>
      <RecordEntry
        label="Código do centro de custos"
        isLoading={loadingCostCenter}
      >
        {costCenterData?.code ?? '--'}
      </RecordEntry>
      <RecordEntry label="Centro de custos" isLoading={loadingCostCenter}>
        {costCenterData?.name ?? '--'}
      </RecordEntry>
      <RecordEntry label="Departamento" isLoading={isLoading}>
        {prestador.posicao.departamento}
      </RecordEntry>
      <RecordEntry label="Email corporativo" isLoading={isLoading}>
        {emailCorp}
      </RecordEntry>
    </Record>
  );
}

function Pagamento({
  pagamento,
  isLoading,
}: {
  pagamento: ContractBRPjEntry['pagamento'];
  isLoading: boolean;
}) {
  return (
    <Record title="Pagamento">
      <RecordEntry label="Honorários" isLoading={isLoading}>
        {formatMoney(pagamento.honorarios.toString())}
      </RecordEntry>
      <RecordEntry label="Periodicidade" isLoading={isLoading}>
        {capitalize(pagamento.periodicidade)}
      </RecordEntry>
    </Record>
  );
}

function DadosPagamento({
  pagamento,
  isLoading,
}: {
  pagamento: ContractBRPjEntry['pagamento'];
  isLoading: boolean;
}) {
  const bank = pagamento.codigoBanco
    ? `${pagamento.codigoBanco} - ${banksList[pagamento.codigoBanco]}`
    : '--';

  return (
    <Record title="Dados para pagamento">
      <RecordEntry label="Banco" isLoading={isLoading}>
        {bank}
      </RecordEntry>
      <RecordEntry label="Agência" isLoading={isLoading}>
        {pagamento.agencia?.toString() ?? '--'}
      </RecordEntry>
      <RecordEntry label="Número da conta" isLoading={isLoading}>
        {pagamento.conta?.toString() ?? '--'}
      </RecordEntry>
      <RecordEntry label="Chave pix" isLoading={isLoading}>
        {pagamento.chavePix ?? '--'}
      </RecordEntry>
    </Record>
  );
}

function LocalPrestacao({
  organizationId,
  companyId,
  isLoading,
}: {
  organizationId: string;
  companyId: string;
  isLoading: boolean;
}) {
  const { data: companyData, isLoading: loadingCompany } = useGetCompanyEntry(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        companyId: companyId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );

  if (isLoading || loadingCompany || !companyData) {
    return null;
  }

  return (
    <Record title="Local de prestação de serviço">
      <RecordEntry label="Contratante" isLoading={loadingCompany}>
        {companyData.br.razaoSocial}
        {formatCNPJ(companyData.br.cnpj)}
      </RecordEntry>
    </Record>
  );
}

const renovacaoLabel = {
  automatica: 'Automática',
  manual: 'Manual',
};
