import { input, output, z } from 'zod';

export function getDerivationsType<
  const TInput extends z.ZodType = z.ZodType<unknown, z.ZodTypeDef, unknown>,
  const TDef extends z.ZodTypeDef = z.ZodTypeDef,
  const TOutput = z.ZodType<unknown, z.ZodTypeDef, unknown>,
>(innerType: z.ZodType<TInput, TDef, TOutput>) {
  type TFieldPSimpleType = z.infer<typeof innerType>;

  const derivationTypes = z.union([
    z
      .instanceof(z.ZodUnion)
      .refine(
        (
          unionSchema,
        ): unionSchema is InstanceType<
          typeof z.ZodUnion<[TFieldPSimpleType, ...TFieldPSimpleType[]]>
        > => {
          return unionSchema.options.reduce(
            (isValid, schema) =>
              isValid &&
              (innerType.safeParse(schema).success ||
                derivationTypes.safeParse(schema).success),
            true,
          );
        },
      ),
    z
      .instanceof(z.ZodIntersection)
      .refine(
        (
          intersectionSchema,
        ): intersectionSchema is InstanceType<
          typeof z.ZodIntersection<TFieldPSimpleType, TFieldPSimpleType>
        > => {
          const isLeftValid = innerType.safeParse(
            intersectionSchema._def.left,
          ).success;
          const isRightValid = innerType.safeParse(
            intersectionSchema._def.right,
          ).success;
          return isLeftValid && isRightValid;
        },
      ),
    z
      .instanceof(z.ZodOptional)
      .refine(
        (
          optionalSchema,
        ): optionalSchema is InstanceType<
          typeof z.ZodOptional<TFieldPSimpleType>
        > => {
          return (
            innerType.safeParse(optionalSchema.unwrap()).success ||
            derivationTypes.safeParse(optionalSchema.unwrap()).success
          );
        },
      ),
    z
      .instanceof(z.ZodEffects)
      .refine(
        (
          effectsSchema,
        ): effectsSchema is InstanceType<
          typeof z.ZodEffects<
            TFieldPSimpleType,
            output<TFieldPSimpleType>,
            input<TFieldPSimpleType> | unknown
          >
        > => {
          return (
            innerType.safeParse(effectsSchema.innerType()).success ||
            derivationTypes.safeParse(effectsSchema.innerType()).success
          );
        },
      ),
    z
      .instanceof(z.ZodDefault)
      .refine(
        (
          effectsSchema,
        ): effectsSchema is InstanceType<
          typeof z.ZodDefault<TFieldPSimpleType>
        > => {
          return (
            innerType.safeParse(effectsSchema._def.innerType).success ||
            derivationTypes.safeParse(effectsSchema._def.innerType).success
          );
        },
      ),
  ]);

  return derivationTypes;
}
