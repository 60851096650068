import { Alert, Box } from '@mui/material';

export function MissingContractIdAlert() {
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      minWidth="280px"
    >
      <Box pt={2} pb={4}>
        <Alert severity="warning">
          Seu perfil de acesso não possui contratos nesta organização.
        </Alert>
      </Box>
    </Box>
  );
}
