import { Box, Typography } from '@mui/material';

import Markdown from '../../../modules/login/components/Markdown';

import { TERMS_OF_SERVICE } from './TermsOfServiceContent';

export function TermsOfServiceBox() {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          gap: 3,
        },
        [theme.breakpoints.down('md')]: {
          maxHeight: '70svh',
          gap: 1,
        },
      })}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: 'bold' }}
        data-testid="terms-of-service-title"
      >
        Termos e Condições Gerais de Uso
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            pr: 0.5,
            backgroundColor: '#FBFBFB',
            height: '500px',
            overflow: 'hidden',
            overflowY: 'scroll',
          },
        })}
        data-testid="terms"
      >
        <Markdown variant="body2" align="justify" pt={2}>
          {TERMS_OF_SERVICE}
        </Markdown>
      </Box>
    </Box>
  );
}
