import {
  AdmissionDraftEntry,
  AdmissionDraftInput,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
} from '@octopus/api';
import {
  adminSteps,
  initialSteps,
  workerSteps,
} from '@octopus/onboarding-types';

import { AdmissionFormState } from '../types';

import * as adminMapper from './adminEntryMapper';
import * as workerMapper from './workerEntryMapper';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  const adminState = adminMapper.getFormStateFromEntry({ admissionDraftEntry });
  const workerState = workerMapper.getFormStateFromEntry({
    admissionDraftEntry,
  });

  return {
    ...adminState,
    ...workerState,
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftUpdateInput => {
  let mapper = null;
  if (adminSteps.has(currentStepName)) {
    mapper = adminMapper;
  }

  if (workerSteps.has(currentStepName)) {
    mapper = workerMapper;
  }

  return mapper!.getUpdateInputFromFormState({
    formState,
    currentStepName,
  });
};

const getCreateInputFromFormState = ({
  formState,
}: {
  formState: AdmissionFormState;
}): AdmissionDraftInput => {
  const draft = Array.from(initialSteps.values()).reduce((acc, stepName) => {
    return {
      ...acc,
      ...adminMapper.getUpdateInputFromFormState({
        formState,
        currentStepName: stepName,
      }),
    };
  }, {});

  return draft as AdmissionDraftInput;
};

export {
  getCreateInputFromFormState,
  getUpdateInputFromFormState,
  getFormStateFromEntry,
};
