import { TFFlagValue, TFFlagsContext } from './createFFlagsContext';
import { TFlagNameSetup } from './TFlagNameSetup';

type TFlagsResolverArg<TFlagName extends string> = {
  set: (flagName: TFlagName, flagValue: TFFlagValue) => void;
};

export type TFFlagsResolverCallback<TFlagName extends string> = (
  fflags: TFlagsResolverArg<TFlagName>,
) => void;

function createFFlagsResolverObject<const TFlagName extends string>(
  _: TFlagNameSetup<TFlagName>,
) {
  const uncommitedFFlags: Partial<Record<TFlagName, TFFlagValue>> = {};
  const setFFlags = (flagName: TFlagName, flagValue: TFFlagValue) => {
    uncommitedFFlags[flagName] = flagValue;
  };

  const fflags = {
    set: setFFlags,
  };

  const commitFflags = () => {
    return { ...uncommitedFFlags };
  };

  return { fflags, commitFflags };
}

export function createFFlagsResolver<const TFlagName extends string>(
  flagNames: TFlagNameSetup<TFlagName>,
  flagsContext: TFFlagsContext<TFlagName>,
) {
  return function resolveFFlags(
    resolver: TFFlagsResolverCallback<TFlagName>,
  ): void {
    const { fflags, commitFflags } = createFFlagsResolverObject(flagNames);

    Promise.resolve(resolver(fflags)).then(() => {
      const commitedFlags = commitFflags();
      flagsContext.push(commitedFlags);
    });

    return void 0;
  };
}
