import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { OrganizationAvatar } from '../../../modules/components/OrganizationAvatar';
import { MembershipContext, MembershipOption } from '../../../modules/types';
import { membershipEquals } from '../../../utils';

export function MembershipSelectorChip({
  membership,
  membershipOptions,
}: {
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
}) {
  const path = '/memberships/selector';
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selected = useMemo(() => {
    return pathname.startsWith(path);
  }, [pathname, path]);

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={() => navigate(path)}
      borderRadius={1}
      bgcolor={selected ? 'rgba(247, 247, 248, 0.2)' : 'rgba(0,0,0,0)'}
      width="32px"
      height="32px"
      p={0.5}
    >
      <OrganizationBadge
        membership={membership}
        membershipOptions={membershipOptions}
      />
    </Box>
  );
}

function OrganizationBadge({
  membership,
  membershipOptions,
}: {
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
}) {
  const selected = membershipOptions.find((option) =>
    membershipEquals(option, membership),
  );
  return (
    <OrganizationAvatar name={selected?.label || '-'} logo={selected?.logo} />
  );
}
