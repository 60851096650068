import { AppError, translate } from '@octopus/i18n';

export function translateAPIErrors(error?: AppError): string[] {
  const errors =
    error?.stack?.details &&
    Object.entries(error?.stack?.details)?.map(
      // todo: show field ([field, error]) => `[${field}]: ${ErrorCodes.pt[error.errorCode]}`,
      ([_field, detail]) => {
        const translation = detail.errorCode
          ? translate(detail.errorCode, 'pt')
          : null;
        return translation ?? detail.message;
      },
    );

  return errors;
}
