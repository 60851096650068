import { z } from 'zod';

import {
  parsePixKey,
  pixKeyTypes,
} from '../../../../../modules/payrolls/parser';

const pixTypeSchema = Object.keys(pixKeyTypes).reduce(
  (acc, pixKeyType) => {
    return { ...acc, [pixKeyType]: z.string().optional() };
  },
  {} as Record<string, z.ZodType>,
);

export const dadosBancariosSchema = z
  .object({
    chave_pix_none: z.literal('true').optional(),
    ...pixTypeSchema,
  })
  .superRefine((formState, ctx) => {
    const { chave_pix_none } = formState || {};

    if (chave_pix_none) {
      return;
    }

    const [tipoPix, chavePix] = Object.entries(formState)?.[0] || [];
    if (!chavePix) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obrigatório',
        path: [tipoPix],
      });
    }

    const pix = parsePixKey(chavePix);
    if (!pix) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_string,
        validation: 'regex',
        path: [tipoPix],
      });
    }

    return;
  });
