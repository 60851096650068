import { Typography } from '@mui/material';

type Props = { errorMessage: string; dataTestId?: string; sx?: object };

export function ErrorLabel({ errorMessage, dataTestId, sx }: Props) {
  const testid = dataTestId ? { 'data-testid': dataTestId } : {};
  if (!errorMessage) return null;
  return (
    <Typography
      variant="caption"
      color="error"
      sx={{ mt: 0.75, ...sx }}
      {...testid}
    >
      {errorMessage}
    </Typography>
  );
}
