import { SearchInput } from '@octopus/api';
import { contractStatuses } from '@octopus/contract-types';

export function addTerminationExclusionToFilter(searchInput: SearchInput) {
  if (!searchInput.filtering) {
    searchInput.filtering = {};
  }

  if (!searchInput.filtering.elements) {
    searchInput.filtering.elements = {};
  }

  if (searchInput.filtering.elements['status'] === undefined) {
    searchInput.filtering.elements['status'] = [
      { not: contractStatuses.terminated },
    ];
  }
}
