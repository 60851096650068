import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { UnidadeSalarioFixo } from '@octopus/esocial/mapper';
import {
  BrasilAmountType,
  IFormDefinition,
  UI_TYPE,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../../inputUtils';
import {
  AdmissionFormState,
  ENABLED_UNIDADE_SALARIO_FIXO,
  FormStepDefinition,
} from '../../types';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const { salary, unidadeBaseCalculo } = formState;

  return [
    {
      label: 'Valor da bolsa',
      type: BrasilAmountType,
      name: 'salary',
      uiType: UI_TYPE.TEXT_MONEY,
      placeholder: '0,00',
      value: salary || '',
    },
    {
      label: 'Unidade para base de cálculo',
      type: z.number().int(),
      name: 'unidadeBaseCalculo',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de salário',
      options: getOptionsFromMapper({
        mapper: UnidadeSalarioFixo,
        currentValue: unidadeBaseCalculo,
        enabledKeys: ENABLED_UNIDADE_SALARIO_FIXO,
        selectIfOnlyOne: true,
      }),
    },
  ];
};

export const getBolsaAuxilioFields = ({
  formState,
  remuneracaoFormSteps,
}: {
  formState: AdmissionFormState;
  remuneracaoFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
  }),
  options: {
    id: admissionDraftFormSteps.remuneracao,
    title: 'Bolsa Auxílio',
    completed: remuneracaoFormSteps?.completed,
    reviewed: remuneracaoFormSteps?.reviewed,
  },
});
