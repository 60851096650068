import React from 'react';

import { LoadingButton } from '@mui/lab';

import { getAuthToken } from '@octopus/api';

import { download } from '../../spreadsheets/downloader';

interface HistoricalEventsDownloadProps {
  organizationId: string;
  setError: (error: boolean) => void;
}

export default function HistoricalEventsDownload({
  organizationId,
  setError,
}: HistoricalEventsDownloadProps) {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    setError(false);

    const authToken = getAuthToken();

    if (!authToken) {
      setLoading(false);
      return;
    }

    download(authToken, organizationId, 'historicalEvents')
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} variant="contained">
      <span>Eventos Históricos</span>
    </LoadingButton>
  );
}
