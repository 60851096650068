import { SearchInput } from '@octopus/api';
import {
  DataGridProps,
  FilteringProps,
  RangeFilter,
} from '@octopus/ui/data-grid';

export type FilteringObject = {
  not: string;
};

export function getActiveElementFilters({
  filtersState,
  activeFilters,
  invertSelection,
  getAllElements,
}: FilteringProps): Record<string, (string | FilteringObject)[]> {
  const allElements = getAllElements();

  const activeEntries = Object.entries(filtersState.elementFilters)
    .filter(([k]) => activeFilters.has(k))
    .filter(([, v]) => !!v && v.length > 0);

  const filters: Record<string, (string | FilteringObject)[]> =
    Object.fromEntries(activeEntries);

  activeEntries.forEach(([k, v]) => {
    if (invertSelection[k]) {
      const notFilters: FilteringObject[] = v.map((filter) => ({
        not: filter,
      }));

      const defaultFilters: (string | FilteringObject)[] = allElements[k];
      filters[k] = defaultFilters.concat(...notFilters);
    }
  });
  return filters;
}

export function getActiveRangeFilters({
  filtersState,
  activeFilters,
}: FilteringProps): Record<string, RangeFilter> {
  return Object.fromEntries(
    Object.entries(filtersState.rangeFilters)
      .filter(([k]) => activeFilters.has(k))
      .filter(([, v]) => !!v.min || !!v.max),
  );
}

export function prepareDataGridQueryParams({
  sortingProps,
  filteringProps,
  searchProps,
  paginationProps,
}: DataGridProps): Record<string, unknown> {
  const elementFilters = getActiveElementFilters(filteringProps);
  const rangeFilters = getActiveRangeFilters(filteringProps);
  const query = searchProps.searchTerm;
  return {
    size: `${paginationProps.rowsPerPage}`,
    page: `${paginationProps.page}`,
    ...(query ? { query } : {}),
    ...(rangeFilters && Object.keys(rangeFilters).length > 0
      ? { rangeFilters: JSON.stringify(rangeFilters) }
      : {}),
    ...(sortingProps.field
      ? {
          sortField: sortingProps.field,
          sortOrder: sortingProps.order,
        }
      : {}),
    ...(elementFilters && Object.keys(elementFilters).length > 0
      ? { elementFilters: JSON.stringify(elementFilters) }
      : undefined),
  };
}

export function prepareDataGridSearchInput({
  sortingProps,
  filteringProps,
  searchProps,
  paginationProps,
}: DataGridProps): SearchInput {
  const elementFilters = getActiveElementFilters(filteringProps);
  const rangeFilters = getActiveRangeFilters(filteringProps);
  const query = searchProps.searchTerm;

  return {
    pagination: {
      size: paginationProps.rowsPerPage,
      page: paginationProps.page,
    },
    ...(query ? { query } : {}),
    filtering: {
      ...(rangeFilters && Object.keys(rangeFilters).length > 0
        ? { ranges: rangeFilters }
        : {}),
      ...(elementFilters && Object.keys(elementFilters).length > 0
        ? { elements: elementFilters }
        : {}),
    },
    sorting: {
      field: sortingProps.field,
      order: sortingProps.order,
    },
  };
}
