import { LocalDate } from '@js-joda/core';
import { z } from 'zod';

const MINIMUM_HIRING_AGE = 14;
const MAXIMUM_HIRING_AGE = 200;

export const validHiringBirthDateSchema = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}$/)
  .refine(
    (val: string) => {
      try {
        const date = LocalDate.parse(val);
        const now = LocalDate.now();

        const minimumHiringBirthDate = now.minusYears(MINIMUM_HIRING_AGE);
        const maximumHiringBirthDate = now.minusYears(MAXIMUM_HIRING_AGE);
        return (
          date.isAfter(maximumHiringBirthDate) &&
          date.isBefore(minimumHiringBirthDate)
        );
      } catch (_) {
        return false;
      }
    },
    {
      params: {
        i18n: { key: 'form_invalid_birth_date' },
      },
    },
  );
