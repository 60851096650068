import { useState } from 'react';

import { Checkbox, Tooltip } from '@mui/material';

type SelectionProps = {
  allSelected: boolean;
  included: string[];
  excluded: string[];

  toggle: (
    payload:
      | {
          id: string;
        }
      | {
          all: true;
        },
  ) => void;
  states: {
    [key: string]: boolean;
    default: boolean;
  };

  clear: () => void;
};

function useSelection(): SelectionProps {
  const [allSelected, setAllSelected] = useState(false);
  const [included, setIncluded] = useState<string[]>([]);
  const [excluded, setExcluded] = useState<string[]>([]);

  const states: SelectionProps['states'] = {
    default: allSelected,
    ...included.reduce(
      (acc, id) => ({
        ...acc,
        [id]: true,
      }),
      {},
    ),
    ...excluded.reduce(
      (acc, id) => ({
        ...acc,
        [id]: false,
      }),
      {},
    ),
  };

  const clear = () => {
    setAllSelected(false);
    setIncluded([]);
    setExcluded([]);
  };

  const toggle = (payload: { id: string } | { all: true }) => {
    if ('all' in payload) {
      // When toggling all, if there are some already selected,
      // we clear the selection, otherwise we select all.
      if (included.length > 0) {
        clear();
        return;
      }

      setAllSelected(!allSelected);
      setIncluded([]);
      setExcluded([]);
      return;
    }

    if (included.includes(payload.id)) {
      setIncluded(included.filter((id) => id !== payload.id));
      return;
    }

    if (excluded.includes(payload.id)) {
      setExcluded(excluded.filter((id) => id !== payload.id));
      return;
    }

    if (allSelected) {
      setExcluded(excluded.concat(payload.id));
      return;
    }

    setIncluded(included.concat(payload.id));
  };

  return {
    allSelected,
    included,
    excluded,
    toggle,
    clear,
    states,
  };
}

function ToggleAllCheckbox({
  selectionProps,
}: {
  selectionProps: SelectionProps;
}) {
  return (
    <Tooltip
      placement="top"
      title="Selecionar/deselecionar todos colaboradores"
    >
      <Checkbox
        color="info"
        checked={selectionProps.allSelected}
        indeterminate={
          (selectionProps.allSelected && selectionProps.excluded.length > 0) ||
          selectionProps.included.length > 0
        }
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={() => {
          selectionProps.toggle({ all: true });
        }}
      />
    </Tooltip>
  );
}

function ToggleItemCheckbox({
  selectionProps,
  id,
}: {
  selectionProps: SelectionProps;
  id: string;
}) {
  return (
    <Checkbox
      color="info"
      checked={
        selectionProps.states[id] === undefined
          ? selectionProps.states.default
          : selectionProps.states[id]
      }
      onClick={(event) => {
        event.stopPropagation();
      }}
      onChange={() => {
        selectionProps.toggle({ id });
      }}
    />
  );
}

export type { SelectionProps };
export { useSelection, ToggleAllCheckbox, ToggleItemCheckbox };
