import React, { useState } from 'react';

import { Alert, Box, Typography } from '@mui/material';

import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';

export type PasswordChangeProps = {
  submit: ({
    password,
    tempPassword,
  }: {
    password: string;
    tempPassword: string;
  }) => Promise<void>;
  username: string;
};

export function PasswordChange({ submit, username }: PasswordChangeProps) {
  const [state, setState] = useState({
    currentStep: 1,
    tempPassword: '',
    password: '',
    confirmPassword: '',
    showTooltip: false,
    showPassword: false,
    isValidPassword: false,
    acceptedTermsOfService: false,
    openTermsOfService: false,
    error: null,
    validation: {
      hasMinLength: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialCharacter: false,
    },
  });

  const submitStepOneChanges = async ({
    tempPassword,
    error,
  }: {
    tempPassword: string;
    error: string;
  }) => {
    console.log({ error });
    setState((oldState) => ({
      ...oldState,
      tempPassword,
      error,
      currentStep: error ? 1 : 2,
    }));
  };

  const submitStepTwoChanges = async ({
    password,
    error,
  }: {
    password: string;
    error: string;
  }) => {
    setState((oldState) => ({ ...oldState, password, error }));
    if (!error) {
      try {
        await submit({
          tempPassword: state.tempPassword,
          password,
        });
      } catch (err) {
        setState((oldState) => ({ ...oldState, error: err.message }));
      }
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body2">
          Preencha sua senha e guarde-a bem, ela será utilizada para acessar a
          plataforma.
        </Typography>
      </Box>
      <StepOne
        username={username}
        hidden={state.currentStep !== 1}
        submit={submitStepOneChanges}
      />
      <StepTwo hidden={state.currentStep !== 2} submit={submitStepTwoChanges} />

      {!state.showTooltip && state.error && (
        <Alert severity="error">
          <Typography
            variant="caption"
            fontWeight="500"
            data-testid="new-password-error"
          >
            {state.error}
          </Typography>
        </Alert>
      )}
    </Box>
  );
}
