import { useMemo } from 'react';

import { Box } from '@mui/material';

type ExplodedDateProps = {
  ddmmyyyy: string | null | undefined;
  placeholder?: string;
};

const placeholderDate = {
  day: undefined as undefined,
  month: undefined as undefined,
  year: undefined as undefined,
};

export function ExplodedDate(props: ExplodedDateProps) {
  const date = useMemo(() => {
    if (!props.ddmmyyyy) {
      return placeholderDate;
    }

    const split = props.ddmmyyyy.split('/');
    if (split.length !== 3) {
      return placeholderDate;
    }

    return {
      day: split[0],
      month: split[1],
      year: split[2],
    };
  }, [props.ddmmyyyy]);

  if (date === placeholderDate && props.placeholder) {
    return <Box color="#BABABF">{props.placeholder}</Box>;
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
      }}
    >
      {date.day || '--'}
      <Box
        component={'span'}
        sx={(theme) => ({
          mx: 0.5,
          color: theme.palette.strokes.heavy,
        })}
      >
        /
      </Box>
      {date.month || '--'}
      <Box
        component={'span'}
        sx={(theme) => ({
          mx: 0.5,
          color: theme.palette.strokes.heavy,
        })}
      >
        /
      </Box>
      {date.year || '--'}
    </Box>
  );
}
