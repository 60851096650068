import { z } from 'zod';

import { Parentesco } from '@octopus/esocial/mapper';

import { Dependent, extractParentescoType } from '../types';

const parantescoTypeSchema = Parentesco.codes().reduce(
  (acc, type) => {
    return { ...acc, [`type_${type}`]: z.string().optional() };
  },
  {} as Record<string, z.ZodType>,
);

export const parentescoSchema = z
  .object({
    ...parantescoTypeSchema,
    descrDep: z.string().optional(),
  })
  .superRefine((fieldState, ctx) => {
    const { descrDep } = fieldState;
    const parentesco = extractParentescoType({
      parentesco: fieldState,
    } as Dependent);

    if (!parentesco) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obrigatório',
        path: ['parentesco'],
      });
    }

    if (parentesco === '99' && !descrDep) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Obrigatório',
        path: ['descrDep'],
      });
    }
  });
