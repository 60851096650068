import { ContractBRPjEntryContrato, ContractStatus } from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';
import { formatDateBR } from '@octopus/formatters';

import { StatusBadge } from '../../../../people/person/components/StatusBadge';
import { Record, RecordEntry } from '../../../Record';

export type SituacaoRecordProps = {
  status: ContractStatus;
  contrato: ContractBRPjEntryContrato;
};

export function SituacaoRecord({ status, contrato }: SituacaoRecordProps) {
  return (
    <Record title="Situação">
      <RecordEntry label="Situação">
        <StatusBadge status={status} contractType={contractTypes.brPj} />
      </RecordEntry>
      <RecordEntry label="Data do desligamento" hide={status !== 'terminated'}>
        {formatDateBR(contrato?.termino)}
      </RecordEntry>
    </Record>
  );
}
