import { History as HistoryIcon } from '@mui/icons-material';
import { Box, Divider, Skeleton, Typography } from '@mui/material';

import {
  PayrollElement,
  PayrollEntry,
  PayrollPayslipEntry,
} from '@octopus/api';
import { formatMoney, formatPeriodDate } from '@octopus/formatters';

import { DisplayDiff } from './DisplayDiff';
import { ElementsTableItems } from './ElementsTableItems';

export function ElementsTable({
  payroll,
  comparisonPeriod,
  isLoadingComparisonPayroll,
  comparisonPayroll,
  summary,
  onClickElement,
  selectedElementId,
  selectorIconPosition = 'left',
}: {
  payroll: PayrollEntry | PayrollPayslipEntry;
  comparisonPeriod?: string;
  comparisonPayroll?: PayrollEntry;
  isLoadingComparisonPayroll?: boolean;
  summary: 'totalCost' | 'workerEarningsTotal' | 'workerDeductionsTotal';
  onClickElement: (element: PayrollElement) => void;
  selectedElementId?: string | undefined;
  selectorIconPosition?: 'left' | 'right';
}) {
  const { total } = payroll.outputs?.[summary] || { total: '0' };
  const hasComparison = Boolean(comparisonPeriod);

  return (
    <Box
      component={'table'}
      width="100%"
      sx={{
        tableLayout: 'auto',
        borderCollapse: 'collapse',
        '& tr > td:nth-last-of-type(2):not([role="presentation"]), & tr > th:nth-last-of-type(2):not([role="presentation"])':
          {
            textAlign: 'left',
            '& > *': {
              textAlign: 'left',
            },
          },
      }}
    >
      <thead>
        <tr
          style={{
            paddingBottom: '16px',
          }}
        >
          <th align="left" style={{ width: '16px' }} role="presentation" />
          <th align="left" style={{ width: hasComparison ? '40%' : '70%' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Descrição
            </Typography>
          </th>
          <th align="left" style={{ width: '30%' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Valor
            </Typography>
          </th>
          {hasComparison && (
            <th align="left" style={{ width: '30%' }}>
              <Box
                component={'span'}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <HistoryIcon />
                <Typography
                  variant="body2"
                  sx={{ marginInlineStart: 0.5, fontWeight: 'bold' }}
                >
                  {formatPeriodDate(comparisonPeriod, {
                    short: true,
                  })}
                </Typography>
              </Box>
            </th>
          )}
          <th align="left" style={{ width: '16px' }} role="presentation" />
        </tr>
        <tr>
          <td />
          <td colSpan={hasComparison ? 3 : 2}>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
      </thead>
      <tbody>
        <ElementsTableItems
          summary={summary}
          onClickElement={onClickElement}
          selectedElementId={selectedElementId}
          selectorIconPosition={selectorIconPosition}
          comparisonPayroll={comparisonPayroll}
          isLoadingComparisonPayroll={isLoadingComparisonPayroll}
          comparisonPeriod={comparisonPeriod}
          payroll={payroll}
        />
        <tr>
          <td />
          <td colSpan={hasComparison ? 3 : 2}>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <Box
          component="tr"
          sx={{
            '& > *': {
              pt: 2,
            },
          }}
        >
          <td />
          <td>
            <Typography variant="body2" fontWeight="bold">
              Total:
            </Typography>
          </td>
          <td>
            <Typography variant="body2" fontWeight="bold">
              {formatMoney(total)}
            </Typography>
          </td>
          {hasComparison && (
            <td>
              {isLoadingComparisonPayroll && (
                <Skeleton variant="rounded" width="100%" />
              )}
              {!isLoadingComparisonPayroll && (
                <DisplayDiff
                  variant="caption"
                  current={total}
                  compare={comparisonPayroll?.outputs?.[summary]?.total}
                  withSign={false}
                  withCurrency={false}
                />
              )}
            </td>
          )}
          <td />
        </Box>
      </tbody>
    </Box>
  );
}
