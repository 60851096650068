import { ComponentType, useEffect } from 'react';

import { ErrorOutline } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Button,
  Container,
  Fade,
  Typography,
} from '@mui/material';

export type FetchResult<T> = {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
  data?: T;
};

export type FetchResultMap = {
  results: { [key: string]: FetchResult<any> };
};

export function DataFetching<T>({
  fetchResult,
  useHook: getFetchResult,
  Data,
  Loading,
  Error,
  containerSx,
  onData,
}: (
  | {
      fetchResult: FetchResult<T> | FetchResultMap;
      useHook?: never;
    }
  | {
      useHook: () => FetchResult<T> | FetchResultMap;
      fetchResult?: never;
    }
) & {
  Data: ComponentType<{
    data: T | undefined;
  }>;
  Loading: ComponentType;
  Error?: ComponentType<{ error: unknown }>;
  containerSx?: BoxProps['sx'];
  onData?: (data: T | undefined) => void;
}) {
  const result = getFetchResult ? getFetchResult() : fetchResult;

  const { data, isError, isLoading, error, refetch } =
    'results' in result ? mergeFetchResults(result.results) : result;
  const ErrorComponent = () => {
    if (Error) {
      return <Error error={error} />;
    }
    return (
      <Container maxWidth="sm">
        <Box textAlign="center" pt={5}>
          <>
            <ErrorOutline sx={{ fontSize: '80px', color: '#BABABF', pb: 1 }} />
            {/* FIXME: color */}
            <Typography variant="body2">
              Desculpe, algo deu errado e não conseguimos carregar essa
              informação. Por favor, verifique sua conexão com a internet e
              tente novamente.
            </Typography>
            {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
            <Box pt={3}>
              <Button color="secondary" onClick={() => refetch()}>
                Recarregar
              </Button>
            </Box>
          </>
        </Box>
      </Container>
    );
  };

  useEffect(() => {
    onData?.(data as T);
  }, [data]);

  const scenes = [
    {
      id: 'error',
      condition: isError && !isLoading, // FIXME: this is a workaround for when there are both errors and data, we should think more about this
      component: <ErrorComponent />,
    },
    {
      id: 'loading',
      condition: isLoading && !isError,
      component: <Loading />,
    },
    {
      id: 'data',
      condition: !isLoading && !isError,
      component: <Data data={data as T} />,
    },
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        ...containerSx,
      }}
    >
      {scenes.map((scene) => (
        <Fade in={scene.condition} key={scene.id} unmountOnExit>
          <Box
            sx={{
              transition: 'all 0.5s',
              width: '100%',
              height: '100%',
              position: scene.condition ? 'relative' : 'absolute',
              top: 0,
            }}
          >
            {scene.component}
          </Box>
        </Fade>
      ))}
    </Box>
  );
}

export function mergeFetchResults<T>(fetchResultMap: {
  [key: string]: FetchResult<T>;
}): FetchResult<T> {
  const fetchResults = Object.values(fetchResultMap);
  const isError = fetchResults.some((fr) => fr.isError);
  const isLoading = isError ? false : fetchResults.some((fr) => fr.isLoading);
  const error = isError
    ? fetchResults.filter((fr) => fr.isError).map((fr) => fr.error)
    : undefined;
  const refetch = () => fetchResults.forEach((fr) => fr.refetch());
  const data: T | undefined =
    isLoading || isError
      ? undefined
      : (Object.entries(fetchResultMap)
          .filter(([_, fr]) => !!fr.data)
          .reduce(
            (data, [key, fr]) => ({
              ...data,
              [key]: fr.data,
            }),
            {},
          ) as T);
  return {
    isLoading,
    isError,
    error,
    refetch,
    data,
  };
}
