export * from './elements';
export * from './records';
export * from './dadosRelevantes';
export * from './lancamentosContabeis';

export const UNSAFE_INTERNALS_PLEASE_FIXME_ASAP = {
  elementsIds: {
    intermediarios: {
      salarioBase: 'salarioBase',
    },
  },
  eventTypes: {
    periodosAquisitivosFerias: 'periodosAquisitivosFerias',
  },
};
