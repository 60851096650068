import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import {
  IFieldCollectionValueWithoutOptionsDefinition,
  IFormDefinition,
  UI_TYPE,
  cnpjSchema,
  cpfSchema,
  phoneSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  PixKeyType,
  pixKeyLabels,
  pixKeyTypes,
} from '../../../../../modules/payrolls/parser';
import { getOptionsFromList } from '../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../types';

import { dadosBancariosSchema } from './dadosBancariosSchema';

const pixField: Record<
  PixKeyType,
  { type: z.ZodType; placeholder: string; mask?: string }
> = {
  [pixKeyTypes.email]: {
    type: z.string().optional(),
    placeholder: 'Ex: maria.eduarda.carmo@gmail.com',
  },
  [pixKeyTypes.celular]: {
    type: phoneSchema.optional(),
    placeholder: '(00) 00000-0000',
    mask: 'phone',
  },
  [pixKeyTypes.cnpj]: {
    type: cnpjSchema.optional(),
    placeholder: '00.000.000/0000-00',
    mask: 'cnpj',
  },
  [pixKeyTypes.cpf]: {
    type: cpfSchema.optional(),
    placeholder: '000.000.000-00',
    mask: 'cpf',
  },
  [pixKeyTypes.aleatoria]: {
    type: z.string().optional(),
    placeholder: 'Ex: dcta478j-196l-03fm-t6gh-4298er7845m2',
  },
};

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const { chavePix, tipoPix, codigoBanco, agencia, conta } = formState;

  return [
    {
      label: 'Tipo Pix',
      type: dadosBancariosSchema,
      name: 'tipoPix',
      uiType: UI_TYPE.SELECT,
      fieldsOptions: [
        {
          label: 'Prefiro não informar',
          selected: !tipoPix,
          fields: [
            {
              label: '-',
              hidden: true,
              type: z.string().optional(),
              name: 'chave_pix_none',
              value: 'true',
              uiType: UI_TYPE.TEXT,
            },
          ],
        },
        ...Object.keys(pixKeyTypes).map(
          (pixKeyType) =>
            ({
              value: pixKeyType ?? '',
              selected: tipoPix === pixKeyType,
              label: pixKeyLabels[pixKeyType as PixKeyType],
              fields: [
                {
                  ...pixField[pixKeyType as PixKeyType],
                  label: 'Chave Pix',
                  name: pixKeyType,
                  uiType: UI_TYPE.TEXT,
                  value: chavePix ?? '',
                },
              ],
            }) as IFieldCollectionValueWithoutOptionsDefinition,
        ),
      ],
    },
    {
      label: 'Banco',
      type: z.string().optional(),
      name: 'codigoBanco',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione',
      options: getOptionsFromList({
        list: banksList,
        currentValue: codigoBanco,
      }),
    },
    {
      label: 'Agência',
      type: z.number().optional(),
      name: 'agencia',
      uiType: UI_TYPE.TEXT,
      placeholder: '0000',
      value: agencia || '',
    },
    {
      label: 'Conta (com dígito)',
      type: z.string().optional(),
      name: 'conta',
      uiType: UI_TYPE.TEXT,
      placeholder: '0000000-0',
      value: conta || '',
    },
  ].filter((item) => item);
};

export const getDadosBancariosFields = ({
  formState,
  dadosBancariosFormSteps,
}: {
  formState: AdmissionFormState;
  dadosBancariosFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
  }),
  options: {
    id: admissionDraftFormSteps.dados_bancarios,
    title: 'Dados bancários',
    completed: dadosBancariosFormSteps?.completed,
    reviewed: dadosBancariosFormSteps?.reviewed,
  },
});
