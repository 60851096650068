export const banksList: Record<string, string> = {
  '332': 'Acesso Soluções De Pagamento S.A.',
  '117': 'Advanced Corretora De Câmbio Ltda',
  '280': 'Avista S.A. Crédito',
  '272': 'Agk Corretora De Cambio S.A.',
  '349': 'Al5 S.A. Crédito',
  '313': 'Amazónia Corretora De Câmbio Ltda.',
  '188': 'Ativa Investimentos S.A. Corretora De Títulos, Câmbio E Valores',
  '080': 'B&T Corretora De Cambio Ltda.',
  '246': 'Banco Abc Brasil S.A.',
  '075': 'Banco Abn Amro S.A.',
  '121': 'Banco Agibank S.A.',
  '025': 'Banco Alfa S.A.',
  '065': 'Banco Andbank (Brasil) S.A.',
  '213': 'Banco Arbi S.A.',
  '096': 'Banco B3 S.A.',
  '024': 'Banco Bandepe S.A.',
  '330': 'Banco Bari De Investimentos E Financiamentos S.A.',
  '318': 'Banco BMG S.A.',
  '752': 'Banco Bnp Paribas Brasil S.A.',
  '107': 'Banco Bocom Bbm S.A.',
  '063': 'Banco Bradescard S.A.',
  '036': 'Banco Bradesco BBI S.A.',
  '122': 'Banco Bradesco Berj S.A.',
  '394': 'Banco Bradesco Financiamentos S.A.',
  '237': 'Banco Bradesco S.A.',
  '218': 'Banco Bs2 S.A.',
  '208': 'Banco BTG Pactual S.A.',
  '626': 'Banco C6 Consignado S.A.',
  '336': 'Banco C6 S.A.',
  '473': 'Banco Caixa Geral - Brasil S.A.',
  '412': 'Banco Capital S.A.',
  '040': 'Banco Cargill S.A.',
  '266': 'Banco Cedula S.A.',
  '739': 'Banco Cetelem S.A.',
  '233': 'Banco Cetelem S.A.',
  '745': 'Banco Citibank S.A.',
  '241': 'Banco Classico S.A.',
  '756': 'Banco Cooperativo Do Brasil S.A. - Bancoob - Sicoob',
  '748': 'Banco Cooperativo Sicredi S.A.',
  '222': 'Banco Crédit Agricole Brasil S.A.',
  '505': 'Banco Credit Suisse (Brasil) S.A.',
  '069': 'Banco Crefisa S.A.',
  '368': 'Banco CSF S.A.',
  '003': 'Banco da Amazonia S.A.',
  '083': 'Banco da China Brasil S.A.',
  '707': 'Banco Daycoval S.A.',
  '300': 'Banco De La Nacion Argentina',
  '495': 'Banco De La Provincia De Buenos Aires',
  '654': 'Banco Digimais S.A.',
  '335': 'Banco Digio S.A.',
  '001': 'Banco do Brasil S.A.',
  '047': 'Banco Do Estado De Sergipe S.A.',
  '037': 'Banco Do Estado Do Pará S.A.',
  '041': 'Banco Do Estado Do Rio Grande Do Sul S.A.',
  '004': 'Banco Do Nordeste Do Brasil S.A.',
  '265': 'Banco Fator S.A.',
  '224': 'Banco Fibra S.A.',
  '094': 'Banco Finaxis S.A.',
  '390': 'Banco Gm S.A.',
  '612': 'Banco Guanabara S.A.',
  '269': 'Banco HSBC S.A.',
  '012': 'Banco Inbursa S.A.',
  '604': 'Banco Industrial Do Brasil S.A.',
  '653': 'Banco Indusval S.A.',
  '077': 'Banco Inter S.A.',
  '249': 'Banco Investcred Unibanco S.A.',
  '184': 'Banco Itaú BBA S.A.',
  '029': 'Banco Itaú Consignado S.A.',
  '479': 'Banco Itaubank S.A.',
  '074': 'Banco J. Safra S.A.',
  '376': 'Banco J.P. Morgan S.A.',
  '217': 'Banco John Deere S.A.',
  '076': 'Banco Kdb Do Brasil S.A.',
  '757': 'Banco Keb Hana Do Brasil S.A.',
  '600': 'Banco Luso Brasileiro S.A.',
  '243': 'Banco Máxima S.A.',
  '389': 'Banco Mercantil do Brasil S.A.',
  '381': 'Banco Mercedes-Benz Do Brasil S.A.',
  '746': 'Banco Modal S.A.',
  '066': 'Banco Morgan Stanley S.A.',
  '456': 'Banco Mufg Brasil S.A.',
  '007': 'Banco Nacional De Desenvolvimento Economico E Social',
  '169': 'Banco Olé Consignado S.A.',
  '079': 'Banco Original Do Agronegócio S.A.',
  '212': 'Banco Original S.A.',
  '712': 'Banco Ourinvest S.A.',
  '623': 'Banco Pan S.A.',
  '611': 'Banco Paulista S.A.',
  '643': 'Banco Pine S.A.',
  '747': 'Banco Rabobank International Brasil S.A.',
  '088': 'Banco Randon S.A.',
  '633': 'Banco Rendimento S.A.',
  '741': 'Banco Ribeirao Preto S.A.',
  '120': 'Banco Rodobens S.A.',
  '422': 'Banco Safra S.A.',
  '033': 'Banco Santander (Brasil) S.A.',
  '743': 'Banco Semear S.A.',
  '754': 'Banco Sistema S.A.',
  '630': 'Banco Smartbank S.A.',
  '366': 'Banco Societe Generale Brasil S.A.',
  '637': 'Banco Sofisa S.A.',
  '464': 'Banco Sumitomo Mitsui Brasileiro S.A.',
  '082': 'Banco Topázio S.A.',
  '387': 'Banco Toyota Do Brasil S.A.',
  '634': 'Banco Triangulo S.A.',
  '018': 'Banco Tricury S.A.',
  '393': 'Banco Volkswagen S.A.',
  '655': 'Banco Votorantim S.A.',
  '610': 'Banco Vr S.A.',
  '119': 'Banco Western Union Do Brasil S.A.',
  '124': 'Banco Woori Bank Do Brasil S.A.',
  '348': 'Banco Xp S.A.',
  '081': 'Bancoseguro S.A.',
  '021': 'Banestes S.A. Banco Do Estado do Espirito Santo',
  '755': 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
  '268': 'Bari Companhia Hipotecária',
  '378': 'Bbc Leasing S.A. - Arrendamento Mercantil',
  '250': 'Bcv - Banco De Crédito E Varejo S.A.',
  '144': 'Bexs Banco De Câmbio S/A',
  '253': 'Bexs Corretora De Câmbio S/A',
  '134': 'Bgc Liquidez Distribuidora De Títulos E Valores Mobiliários Ltda',
  '017': 'Bny Mellon Banco S.A.',
  '408': 'Bónuscred Sociedade De Crédito Direto S.A.',
  '301': 'Bpp Instituição De Pagamento S.A.',
  '126': 'Br Partners Banco De Investimento S.A.',
  '070': 'BrB - Banco De Brasilia S.A.',
  '092': 'Brk S.A. Crédito',
  '173': 'Brl Trust Distribuidora De Títulos E Valores Mobiliários S.A.',
  '142': 'Broker Brasil Corretora De Câmbio Ltda.',
  '292': 'Bs2 Distribuidora De Títulos E Valores Mobiliários S.A.',
  '104': 'Caixa Economica Federal',
  '309': 'Cambionet Corretora De Câmbio Ltda.',
  '288': 'Carol Distribuidora De Titulos E Valores Mobiliarios Ltda.',
  '324': 'Cartos Sociedade De Crédito Direto S.A.',
  '130': 'Caruana S.A. - Sociedade De Crédito',
  '159': 'Casa Do Crédito S.A. Sociedade De Crédito Ao Microempreendedor',
  '114': 'Central Cooperativa De Crédito No Estado Do Espírito Santo - Cecoop',
  '091':
    'Central De Cooperativas De Economia E Crédito Mútuo Do Estado Do Rio Grande Do S',
  '320': 'China Construction Bank (Brasil) Banco Múltiplo S.A.',
  '362': 'Cielo S.A.',
  '477': 'Citibank N.A.',
  '180':
    'Cm Capital Markets Corretora De Câmbio, Títulos E Valores Mobiliários Ltda',
  '127': 'Codepe Corretora De Valores E Câmbio S.A.',
  '163': 'Commerzbank Brasil S.A. - Banco Múltiplo',
  '133':
    'Banco Cresol - Confederação Nacional Das Cooperativas Centrais De Crédito e Economia Familiar e Solidária',
  '136':
    'Unicred Do Brasil - Confederação Nacional Das Cooperativas Centrais Unicred Ltda.',
  '060': 'Confidence Corretora De Câmbio S.A.',
  '085': 'Cooperativa Central De Crédito - Ailos',
  '016':
    'Cooperativa de Crédito Mutuo dos Despachantes de Trânsito de Santa Catarina e Rio Grande do Sul',
  '281': 'Cooperativa De Crédito Rural Coopavel',
  '322': 'Cooperativa De Crédito Rural De Abelardo Luz - Sulcredi/Crediluz',
  '391': 'Cooperativa De Credito Rural De Ibiam - Sulcredi/Ibiam',
  '286': 'Cooperativa De Crédito Rural De Ouro Sulcredi/Ouro',
  '279': 'Cooperativa De Credito Rural De Primavera Do Leste',
  '273':
    'Cooperativa De Crédito Rural De São Miguel Do Oeste - Sulcredi/São Miguel',
  '403': 'Cora Sociedade De Crédito Direto S.A.',
  '098': 'Credialiança Cooperativa De Crédito Rural',
  '010': 'Credicoamo Credito Rural Cooperativa',
  '089': 'Credisan Cooperativa De Crédito',
  '097': 'Credisis - Central De Cooperativas De Crédito Ltda.',
  '011': 'Credit Suisse Hedging-Griffo Corretora De Valores S.A',
  '342': 'Creditas Sociedade De Crédito Direto S.A.',
  '321':
    'Crefaz Sociedade De Crédito Ao Microempreendedor E A Empresa De Pequeno Porte Lt',
  '289': 'Decyseo Corretora De Cambio Ltda.',
  '487': 'Deutsche Bank S.A. - Banco Alemao',
  '140': 'Easynvest - Título Corretora De Valores Sa',
  '149': 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
  '196': 'Fair Corretora De Cambio S.A.',
  '343':
    'Ffa Sociedade De Crédito Ao Microempreendedor E À Empresa De Pequeno Porte Ltda.',
  '331': 'Fram Capital Distribuidora De Títulos E Valores Mobiliários S.A.',
  '285': 'Frente Corretora De Câmbio Ltda.',
  '278': 'Genial Investimentos Corretora De Valores Mobiliários S.A.',
  '364': 'Gerencianet S.A.',
  '138': 'Get Money Corretora De Câmbio S.A.',
  '384':
    'Global Financas - Sociedade De Credito Ao Microempreendedor E A Empresa De Pequeno Porte Ltda',
  '064': 'Goldman Sachs Do Brasil Banco Multiplo S.A.',
  '177': 'Guide Investimentos S.A. Corretora De Valores',
  '146': 'Guitta Corretora De Cambio Ltda.',
  '078': 'Haitong Banco De Investimento Do Brasil S.A.',
  '062': 'Hipercard Banco Múltiplo S.A.',
  '189': 'HS Financeira S/A Credito',
  '396': 'Hub Pagamentos S.A',
  '271': 'Ib Corretora De Câmbio, Títulos E Valores Mobiliários S.A.',
  '157': 'Icap Do Brasil Corretora De Títulos E Valores Mobiliários Ltda.',
  '132': 'Icbc Do Brasil Banco Múltiplo S.A.',
  '492': 'Ing Bank N.V.',
  '139': 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
  '652': 'Itaú Unibanco Holding S.A.',
  '341': 'Itaú Unibanco S.A.',
  '488': 'Jpmorgan Chase Bank',
  '399': 'Kirton Bank S.A. - Banco Múltiplo',
  '293': 'Lastro Rdv Distribuidora De Títulos E Valores Mobiliários Ltda.',
  '105': 'Lecca Crédito',
  '145': 'Levycam - Corretora De Cambio E Valores Ltda.',
  '397': 'Listo Sociedade De Credito Direto S.A.',
  '113': 'Magliano S.A. Corretora De Cambio E Valores Mobiliarios',
  '323': 'Mercadopago.Com Representacoes Ltda.',
  '274':
    'Money Plus Sociedade De Crédito ao Microempreendedor e a Empresa De Pequeno Port',
  '259': 'Moneycorp Banco De Câmbio S.A.',
  '128': 'Ms Bank S.A. Banco De Câmbio',
  '354':
    'Necton Investimentos S.A. Corretora de Valores Mobiliários e Commodities',
  '191': 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
  '753': 'Novo Banco Continental S.A. - Banco Múltiplo',
  '260': 'Nu Pagamentos S.A.',
  '111': 'Oliveira Trust Distribuidora de Títulos e Valores Mobiliarios S.A.',
  '319': 'Om Distribuidora de Títulos e Valores Mobiliários Ltda',
  '613': 'Omni Banco S.A.',
  '325': 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
  '355': 'Ótimo Sociedade de Crédito Direto S.A.',
  '290': 'Pagseguro Internet S.A.',
  '254': 'Paraná Banco S.A.',
  '326': 'Parati - Credito',
  '194': 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda',
  '174': 'Pefisa S.A. - Crédito',
  '315': 'Pi Distribuidora de Títulos e Valores Mobiliários S.A.',
  '380': 'Picpay Servicos S.A.',
  '100': 'Planner Corretora de Valores S.A.',
  '125': 'Plural S.A. Banco Múltiplo',
  '108': 'Portocred S.A. - Credito',
  '306': 'Portopar Distribuidora de Titulos e Valores Mobiliarios Ltda.',
  '329': 'Qi Sociedade de Crédito Direto S.A.',
  '283':
    'Rb Capital Investimentos Distribuidora de Títulos e Valores Mobiliários Limitada',
  '374': 'Realize Crédito',
  '101': 'Renascenca Distribuidora de Títulos e Valores Mobiliários Ltda',
  '270': 'Sagitur Corretora De Câmbio Ltda.',
  '751': 'Scotiabank Brasil S.A. Banco Múltiplo',
  '276': 'Senff S.A. - Crédito',
  '545': 'Senso Corretora De Cambio E Valores Mobiliarios S.A',
  '190':
    'Servicoop - Cooperativa De Crédito Dos Servidores Públicos Estaduais Do Rio Gran',
  '363': 'Socopa Sociedade Corretora Paulista S.A.',
  '183':
    'Socred S.A. - Sociedade De Crédito Ao Microempreendedor e a Empresa De Pequeno P',
  '365': 'Solidus S.A. Corretora de Cambio e Valores Mobiliarios',
  '299': 'Sorocred Crédito',
  '014': 'State Street Brasil S.A. - Banco Comercial',
  '197': 'Stone Pagamentos S.A.',
  '404': 'Sumup Sociedade De Crédito Direto S.A.',
  '340': 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
  '370':
    'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
  '352': 'Toro Corretora De Títulos E Valores Mobiliários Ltda',
  '095': 'Travelex Banco De Câmbio S.A.',
  '143': 'Treviso Corretora De Câmbio S.A.',
  '131': 'Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda',
  '129': 'Ubs Brasil Banco de Investimento S.A.',
  '015': 'Ubs Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  '099':
    'Uniprime Central - Central Interestadual De Cooperativas de Credito Ltda.',
  '084':
    'Uniprime Norte Do Paraná - Coop de Economia e Crédito Mútuo Dos Médicos',
  '373': 'UP.P Sociedade de Empréstimo Entre Pessoas S.A.',
  '298': "Vip's Corretora de Câmbio Ltda.",
  '296': 'Vision S.A. Corretora De Cambio',
  '367': 'Vitreo Distribuidora de Títulos e Valores Mobiliários S.A.',
  '310': 'Vortx Distribuidora de Titulos e Valores Mobiliarios Ltda.',
  '102':
    'Xp Investimentos Corretora de Câmbio,Títulos d Valores Mobiliários S/A',
  '359': 'Zema Crédito',
};
