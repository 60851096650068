import React from 'react';
import { IMask, IMaskInput } from 'react-imask';

import dayjs from 'dayjs';

import { TextField, TextFieldProps } from '@mui/material';

export type DateFieldProps = {
  dateFormat: string;
  value: dayjs.Dayjs | null;
  onChange: (value: dayjs.Dayjs | null) => void;
} & Omit<TextFieldProps, 'value' | 'onChange'>;

export function DateField({
  dateFormat,
  value,
  onChange,
  InputProps,
  ...props
}: DateFieldProps) {
  const TextMaskCustom = React.useMemo(() => {
    return React.forwardRef<HTMLElement, CustomProps>(
      function TextMaskCustom(props) {
        const { onChange, ...other } = props;
        return (
          <IMaskInput
            {...other}
            mask={Date}
            pattern={dateFormat}
            lazy={false}
            overwrite={true}
            format={(date: Date) => {
              return dayjs(date).format(dateFormat);
            }}
            parse={(str: string) => {
              return dayjs(str, dateFormat).toDate();
            }}
            onComplete={(value: unknown) =>
              onChange({
                target: {
                  name: props.name,
                  value: value as string,
                },
              })
            }
            blocks={{
              YYYY: {
                mask: IMask.MaskedRange,
                from: 1970,
                to: 2030,
                placeholderChar: 'a',
              },
              MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                placeholderChar: 'm',
              },
              DD: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                placeholderChar: 'd',
              },
            }}
          />
        );
      },
    );
  }, [dateFormat]);
  const parsedValue = React.useMemo(() => {
    return value ? value.format(dateFormat) : '';
  }, [value, dateFormat]);
  return (
    <TextField
      value={parsedValue}
      onChange={(e) => {
        const value = e.target.value;
        onChange(value ? dayjs(value, dateFormat) : null);
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      InputProps={{ ...InputProps, inputComponent: TextMaskCustom as any }}
    />
  );
}

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};
