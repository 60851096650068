import { useEffect, useState } from 'react';

import { useMaskito } from '@maskito/react';

import { Typography } from '@mui/material';

import {
  ContractBRCltEntryPagamento,
  ContractBRPjEntryPagamento,
} from '@octopus/api';
import { banksList } from '@octopus/contract-types';

import {
  MaskitoOptionsBR,
  getPixKeyMask,
} from '../../../form/Field/MaskitoOptions';
import { formatPixKey } from '../../../formatters';
import {
  PixKeyType,
  parsePixKey,
  pixKeyLabels,
  pixKeyTypes,
} from '../../../payrolls/parser';
import { PixChip } from '../../../people/person/components/PixChip';
import { Record, RecordEntry } from '../../Record';
import { BaseRecordProps } from '../common';
import { useRecordEdit } from '../useRecordEdit';

export type PagamentoRecordData = {
  pagamento:
    | ContractBRCltEntryPagamento
    | ContractBRPjEntryPagamento
    | undefined;
};

export type PagamentoRecordProps = BaseRecordProps<PagamentoRecordData>;

export function PagamentoRecord(props: PagamentoRecordProps) {
  const {
    data: { pagamento },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<PagamentoRecordData>(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        pagamento: { ...data.pagamento, [key]: formatter(value) },
      }));
  };

  const [pixKeyType, setPixKeyType] = useState<PixKeyType | null>(
    pagamento?.tipoPix ?? parsePixKey(pagamento?.chavePix) ?? null,
  );
  useEffect(() => {
    updateData((data) => ({
      ...data,
      pagamento: { ...data.pagamento, tipoPix: pixKeyType },
    }));
  }, [pixKeyType]);

  const [pixKey, setPixKey] = useState<string>(
    pagamento?.chavePix ? formatPixKey(pagamento.chavePix, pixKeyType) : '',
  );

  useEffect(() => {
    updateData((data) => ({
      ...data,
      pagamento: { ...data.pagamento, chavePix: pixKey },
    }));
  }, [pixKey]);

  const pixMask = useMaskito({
    options: getPixKeyMask(pixKeyType),
  });

  const agencyMask = useMaskito({
    options: MaskitoOptionsBR.bankAgency,
  });

  const accountMask = useMaskito({
    options: MaskitoOptionsBR.bankAccount,
  });

  return (
    <Record title="Dados para pagamentos" edit={editRecordProps}>
      <RecordEntry
        label="Chave Pix"
        edit={{
          editing,
          type: 'options-and-text',
          options: {
            value: pixKeyType,
            options: [
              { value: '', label: 'N/A' },
              ...Object.keys(pixKeyTypes).map((value) => ({
                value,
                label: pixKeyLabels[value as PixKeyType],
              })),
            ],
            onChange: (val) => {
              setPixKey('');
              setPixKeyType(val as PixKeyType);
            },
          },
          text: {
            value: pixKey,
            onChange: setPixKey,
            disabled: !pixKeyType,
            mask: pixMask,
          },
          hasError: hasError('br/pagamento/chavePix'),
        }}
      >
        {pagamento?.chavePix ? (
          <Typography variant="body2" color="text.primary" textAlign="right">
            {pixKey}
            <PixChip type={pixKeyType} />
          </Typography>
        ) : undefined}
      </RecordEntry>
      <RecordEntry
        label="Banco"
        edit={{
          editing,
          type: 'options',
          onChange: (code) => {
            updateData((data) => ({
              ...data,
              pagamento: {
                ...data.pagamento,
                codigoBanco: code,
                nomeBanco: code !== '' ? banksList[code] : '',
                ...(code === '' ? { agencia: '', conta: '' } : {}),
              },
            }));
          },
          value: formData.pagamento?.codigoBanco,
          options: [
            { value: '', label: 'N/A' },
            ...Object.entries(banksList)
              .sort(([code1], [code2]) => parseInt(code1) - parseInt(code2))
              .map(([code, name]) => ({
                value: code,
                label: `${code} ${name}`,
              })),
          ],
          hasError: hasError('br/pagamento/codigoBanco'),
        }}
      >
        {pagamento?.codigoBanco
          ? `${pagamento?.codigoBanco}   ${banksList[pagamento?.codigoBanco]}`
          : undefined}
      </RecordEntry>
      <RecordEntry
        label="Agência"
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('agencia'),
          value: formData.pagamento?.agencia,
          disabled: !formData.pagamento?.codigoBanco,
          mask: agencyMask,
          hasError: hasError('br/pagamento/agencia'),
        }}
      >
        {pagamento?.agencia}
      </RecordEntry>
      <RecordEntry
        label="Conta"
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('conta'),
          value: formData.pagamento?.conta,
          disabled: !formData.pagamento?.codigoBanco,
          mask: accountMask,
          hasError: hasError('br/pagamento/conta'),
        }}
      >
        {pagamento?.conta}
      </RecordEntry>
    </Record>
  );
}
