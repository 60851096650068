import { useContext, useEffect, useState } from 'react';

import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import {
  ApiContext,
  EventSourcingEventWithAuthor,
  GetContractEventsPageError,
  GetContractEventsPageQueryParams,
  fetchGetContractEventsPage,
  useApiContext,
  useGetContract,
} from '@octopus/api';
import {
  ContractFieldChange,
  formatChangedFieldData,
  formatChangedFieldLabel,
} from '@octopus/formatters';

import { AppContext } from '../../../../context';

export type ContractHistoryEvent = Readonly<
  EventSourcingEventWithAuthor & {
    canceled: boolean;
  }
>;

export function useGetAllAuthoredContractEvents(
  {
    organizationId,
    contractId,
    pageSize = 50,
    sort = 'desc',
  }: {
    organizationId: string;
    contractId: string;
    pageSize?: number;
    sort?: 'asc' | 'desc';
  },
  options?: Omit<
    UseQueryOptions<
      ContractHistoryEvent[],
      GetContractEventsPageError,
      ContractHistoryEvent[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) {
  const { fetcherOptions, queryOptions } = useApiContext(options);

  return useQuery<
    ContractHistoryEvent[],
    GetContractEventsPageError,
    ContractHistoryEvent[]
  >({
    queryKey: [
      'getAllAuthoredContractEvents',
      organizationId,
      contractId,
      pageSize.toString(),
      sort,
    ],
    queryFn: ({ signal }) => {
      return fetchAllAuthoredContractEvents({
        organizationId,
        contractId,
        pageSize,
        sort,
        fetcherOptions,
        signal,
      });
    },
    ...options,
    ...queryOptions,
  });
}

async function fetchAllAuthoredContractEvents({
  organizationId,
  contractId,
  pageSize = 50,
  sort = 'desc',
  fetcherOptions,
  signal,
}: {
  organizationId: string;
  contractId: string;
  pageSize?: number;
  sort?: 'asc' | 'desc';
  signal: AbortSignal;
  fetcherOptions: ApiContext['fetcherOptions'];
}): Promise<ContractHistoryEvent[]> {
  let cursor;
  let events: EventSourcingEventWithAuthor[] = [];
  do {
    const queryParams: GetContractEventsPageQueryParams = {
      limit: pageSize.toString(),
      sort,
    };
    if (cursor) {
      queryParams.cursor = cursor;
    }
    const { data, nextCursor } = await fetchGetContractEventsPage(
      {
        ...fetcherOptions,
        pathParams: {
          organizationId,
          contractId,
        },
        queryParams,
      },
      signal,
    );

    cursor = nextCursor;
    events = events.concat(data);
  } while (cursor);

  return checkCanceledEvents(events);
}

function checkCanceledEvents(
  events: EventSourcingEventWithAuthor[],
): ContractHistoryEvent[] {
  const canceledEvents = events.reduce((acc, event) => {
    if (event.type === 'correction') {
      acc.add(event.payload.sequenceId);
    }
    return acc;
  }, new Set<number>([]));

  return events.map((event) => ({
    ...event,
    canceled: canceledEvents.has(event.sequenceId),
  }));
}

type FormattedData = string | undefined;
type FormattedDiffValuesArgs = ContractFieldChange & {
  organizationId: string;
  contractId: string;
};
export function useFormattedDiffValues({
  organizationId,
  contractId,
  path,
  oldData,
  newData,
}: FormattedDiffValuesArgs) {
  const { appContext } = useContext(AppContext);
  const [formattedOldData, setFormattedOldData] =
    useState<FormattedData>(undefined);
  const [formattedNewData, setFormattedNewData] =
    useState<FormattedData>(undefined);

  const getOldDataContract = useGetContract(
    { pathParams: { organizationId, contractId: oldData as string } },
    { enabled: path === 'contractId' && !!oldData && oldData !== contractId },
  );
  const getNewDataContract = useGetContract(
    { pathParams: { organizationId, contractId: newData as string } },
    { enabled: path === 'contractId' && !!newData },
  );

  useEffect(() => {
    if (path === 'contractId') {
      const oldValue =
        oldData === contractId ? undefined : getOldDataContract.data?.name;
      setFormattedOldData(oldValue);
      setFormattedNewData(getNewDataContract.data?.name);
    } else {
      setFormattedOldData(
        formatChangedFieldData(path, oldData as string, appContext),
      );
      setFormattedNewData(
        formatChangedFieldData(path, newData as string, appContext),
      );
    }
  }, [
    organizationId,
    contractId,
    path,
    oldData,
    newData,
    appContext,
    getOldDataContract,
    getNewDataContract,
  ]);

  return {
    label: formatChangedFieldLabel(path),
    formattedOldData,
    formattedNewData,
  };
}
