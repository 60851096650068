import dayjs from 'dayjs';

import { VacationsAccrualPeriodEntry } from '@octopus/api';
import {
  DaysBeforeDSRRuleConfiguration,
  DaysBeforeStartRuleConfiguration,
} from '@octopus/vacations-types';

export const getCurrentAccrualPeriods = (
  accrualPeriods: VacationsAccrualPeriodEntry[],
) => {
  return accrualPeriods?.find(({ daysAvailable }) => daysAvailable > 0);
};

export const getVacationsConfiguration = (
  accrualPeriod: VacationsAccrualPeriodEntry,
  vacationsConfiguration: any,
  sellVacation: boolean,
) => {
  const { rules } = vacationsConfiguration || {};
  const { concessionPeriod, daysAvailable, daysSold } = accrualPeriod || {};
  if (!rules || !concessionPeriod || !daysAvailable) {
    return {};
  }

  const {
    enabled: daysBeforeStartEnabled,
    minimum,
    maximum,
  } = rules?.daysBeforeStart as DaysBeforeStartRuleConfiguration;

  const { enabled: daysBeforeDSREnabled, days: daysBeforeDsr } =
    rules?.daysBeforeDSR as DaysBeforeDSRRuleConfiguration;

  const { enabled: daysAvailableEnabled } =
    rules?.daysAvailable as DaysBeforeDSRRuleConfiguration;

  const { enabled: atLeast5DaysEnabled } =
    rules?.art134AtLeast5Days as DaysBeforeDSRRuleConfiguration;

  const ABONO_PECUNIARIO_MINIMUM_DAYS = 10;
  const MINIMUM_DAYS_TO_TAKE_VACATION = 5;
  const MINIMUM_DAYS_REQUIRED_TO_SELL =
    ABONO_PECUNIARIO_MINIMUM_DAYS + MINIMUM_DAYS_TO_TAKE_VACATION;

  const hasSoldVacation = daysSold > 0;
  const canSellVacation =
    rules?.maySellVacations &&
    !hasSoldVacation &&
    daysAvailable >= MINIMUM_DAYS_REQUIRED_TO_SELL;

  const remainingDaysAvailable = sellVacation
    ? daysAvailable - 10
    : daysAvailable;

  const minDate = daysBeforeStartEnabled
    ? dayjs().add(minimum, 'day')
    : dayjs();
  const maxDate = daysBeforeStartEnabled
    ? dayjs().add(maximum, 'day')
    : undefined;

  const minDuration = 5;
  const maxDuration = daysAvailable - (sellVacation ? 10 : 0);
  const disableWeekends = (date: dayjs.Dayjs) => {
    if (!daysBeforeDSREnabled) {
      return false;
    }
    if (daysBeforeDsr < 2) {
      return [0, 6].includes(date.day());
    }
    return [0, 5, 6].includes(date.day());
  };

  const currentMonth = dayjs().format('MMMM').toLowerCase();
  const isThirteenthSalaryAdvanceEligible =
    rules?.thirteenthAdvance &&
    rules?.thirteenthAdvanceAllowedMonths?.monthsAllowedToRequest.includes(
      currentMonth,
    );

  return {
    minDate,
    maxDate,
    minDuration,
    maxDuration,
    disableWeekends,
    canSellVacation,
    remainingDaysAvailable,
    isThirteenthSalaryAdvanceEligible,
    daysAvailableEnabled,
    atLeast5DaysEnabled,
  };
};
