export function downloadBlobFile(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const linkElement = document.createElement('a');
  linkElement.href = url;
  linkElement.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      linkElement.removeEventListener('click', clickHandler);
    }, 150);
  };
  linkElement.addEventListener('click', clickHandler, false);
  linkElement.click();
}

function downloadBase64File(
  base64Data: string,
  fileName: string,
  contentType: string,
): void {
  const binaryData = atob(base64Data);

  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: contentType || 'text/plain' });

  downloadBlobFile(blob, fileName);
}

export function downloadBase64XLSXFile(base64Data: string, fileName: string) {
  downloadBase64File(
    base64Data,
    fileName,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  );
}

export function downloadBase64PdfFile(base64Data: string, fileName: string) {
  downloadBase64File(base64Data, fileName, 'application/pdf');
}
