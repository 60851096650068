import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { OctopusLoading } from '@octopus/ui/design-system';

export type TakoProgressProps = {
  width?: string | number;
  height?: string | number;
  progress?: {
    current: number;
    total: number;
  };
  label?: {
    phrases: string[];
    cycleTimeInMs: number;
  };
};

export function TakoProgress({
  width,
  height,
  progress,
  label,
}: TakoProgressProps) {
  const [currentPhrase, setCurrentPhrase] = useState<number>(-1);

  const handleChangePhrases = () => {
    setCurrentPhrase((i) => (i + 1) % label.phrases.length);
    setTimeout(handleChangePhrases, label.cycleTimeInMs);
  };

  useEffect(() => {
    if (label && currentPhrase === -1) {
      handleChangePhrases();
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width={width}
      height={height}
      gap={3}
    >
      <Box width="200px" height="200px">
        <OctopusLoading />
      </Box>
      {progress && (
        <Box height="150px" width="200px">
          <LinearProgress
            variant="determinate"
            value={(100 / progress.total) * progress.current}
          />
        </Box>
      )}
      {label && (
        <Typography variant="subtitle1" color="text.primary" fontWeight="500">
          {label.phrases[currentPhrase]}
        </Typography>
      )}
    </Box>
  );
}
