import { useMaskito } from '@maskito/react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Typography } from '@mui/material';

import { ContractBRPjEntryDependente } from '@octopus/api';
import { Sexo } from '@octopus/esocial/mapper';
import { formatCPF, formatDateBR, getOnlyDigits } from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../../form/Field/MaskitoOptions';
import {
  Record,
  RecordEntry,
  RecordEntryGroup,
  mapperToOptions,
} from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type DependentesRecordData = {
  dependentes: ContractBRPjEntryDependente[] | undefined;
};

export type DependentesRecordProps = BaseRecordProps<DependentesRecordData>;

export function DependentesRecord(props: DependentesRecordProps) {
  const { dependentes } = props.data;

  const recordEditProps = useRecordEdit(props);
  const { editing, formData, updateData, editRecordProps, hasError } =
    recordEditProps;

  const prepareOnChange =
    (
      index: number,
      field: keyof ContractBRPjEntryDependente,
      formatter?: (value: string) => string,
    ) =>
    (value: string) =>
      updateData((data) => {
        return {
          ...data,
          dependentes: data.dependentes.map((dep, i) => {
            if (i === index) {
              if (!value) {
                const { [field]: _, ...rest } = dep;
                return { ...rest } as ContractBRPjEntryDependente;
              }
              return {
                ...dep,
                [field]: formatter ? formatter(value) : value,
              };
            }
            return dep;
          }),
        };
      });

  const addButton = (
    <Box display="flex" width="100%" justifyContent="flex-end">
      <Button
        variant="outlined"
        color="primaryAlt"
        onClick={() => {
          updateData((data) => ({
            ...data,
            dependentes: [...(data?.dependentes ?? []), { nmDep: '' }],
          }));
        }}
      >
        Adicionar novo dependente
      </Button>
    </Box>
  );

  const dependentesList = editing ? formData.dependentes : dependentes;

  if (!dependentesList || dependentesList.length === 0) {
    return (
      <Record title="Dependentes" edit={editRecordProps}>
        <RecordEntry label="Nenhum dependente cadastrado" hideValue={!editing}>
          {addButton}
        </RecordEntry>
      </Record>
    );
  }
  return (
    <Record title="Dependentes" edit={editRecordProps}>
      <Box>
        {dependentesList.map((dependente, index) => (
          <DependenteEntry
            key={index}
            dependente={dependente}
            editing={editing}
            prepareOnChange={(field, formatter) =>
              prepareOnChange(index, field, formatter)
            }
            withDivider={index < formData.dependentes.length - 1}
            removeEntry={() =>
              updateData((data) => ({
                ...data,
                dependentes: data.dependentes.filter((_, i) => i !== index),
              }))
            }
            hasError={(field) => hasError(`br/dependentes/${index}/${field}`)}
          />
        ))}
      </Box>
      {editing && <Box pt={2}>{addButton}</Box>}
    </Record>
  );
}

type DependenteEntryProps = {
  dependente: ContractBRPjEntryDependente;
  editing: boolean;
  prepareOnChange: (
    field: keyof ContractBRPjEntryDependente,
    formatter?: (str: string) => string,
  ) => (val: string) => void;
  withDivider: boolean;
  removeEntry: () => void;
  hasError: (field: string) => boolean;
};

function DependenteEntry({
  dependente: { nmDep, descricao, cpfDep, sexoDep, dtNascto },
  editing,
  prepareOnChange,
  withDivider,
  removeEntry,
  hasError,
}: DependenteEntryProps) {
  const cpfMask = useMaskito({
    options: MaskitoOptionsBR.cpf,
  });
  return (
    <RecordEntryGroup isEditing={editing} withDivider={withDivider}>
      <RecordEntry
        label="Nome completo"
        edit={{
          editing,
          type: 'text',
          value: nmDep,
          onChange: prepareOnChange('nmDep'),
          hasError: hasError('nmDep'),
        }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          textAlign="right"
          fontWeight="700"
        >
          {nmDep}
        </Typography>
      </RecordEntry>
      <RecordEntry
        label="Parentesco"
        edit={{
          editing,
          type: 'text',
          value: descricao,
          onChange: prepareOnChange('descricao'),
          hasError: hasError('descricao'),
        }}
      >
        {descricao}
      </RecordEntry>
      <RecordEntry
        label="Data de nascimento"
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: dtNascto,
          onChange: prepareOnChange('dtNascto'),
          hasError: hasError('dtNascto'),
        }}
      >
        {dtNascto ? formatDateBR(dtNascto) : undefined}
      </RecordEntry>
      <RecordEntry
        label="CPF"
        edit={{
          editing,
          type: 'text',
          value: formatCPF(cpfDep),
          onChange: prepareOnChange('cpfDep', getOnlyDigits),
          mask: cpfMask,
          hasError: hasError('cpfDep'),
        }}
      >
        {formatCPF(cpfDep)}
      </RecordEntry>
      <RecordEntry
        label="Sexo"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('sexoDep'),
          value: sexoDep,
          options: [
            { label: 'N/A', value: null },
            ...mapperToOptions({ mapper: Sexo }),
          ],
          hasError: hasError('sexoDep'),
        }}
      >
        {Sexo.getByCode(sexoDep)}
      </RecordEntry>
      {editing && (
        <Box width="100%" pt={1}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={removeEntry}
          >
            Excluir dependente
          </Button>
        </Box>
      )}
    </RecordEntryGroup>
  );
}
