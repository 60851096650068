import { Switch, SwitchProps } from '@mui/material';

export const CustomSwitch = (props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    sx={(theme) => ({
      width: '2.25em',
      height: '1.25em',
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: '0 .125em',
        top: '50%',
        transform: 'translateY(-50%)',
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(calc(100% - .25em)) translateY(-50%)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: '1em',
        height: '1em',
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    })}
  />
);
