import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { useFFlags } from '../../fflags';
import { SidebarApp } from '../types';

export function SidebarAppSelector({
  apps,
  open,
}: {
  apps: SidebarApp[];
  open: boolean;
}) {
  const { FFlags } = useFFlags();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      height="100%"
      pt={3}
    >
      {apps.map((app, i) => {
        if (app.fflag) {
          return (
            <FFlags key={i} {...{ [app.fflag]: true }}>
              <SidebarAppSelectorItem app={app} open={open} />
            </FFlags>
          );
        }
        return <SidebarAppSelectorItem key={i} app={app} open={open} />;
      })}
    </Box>
  );
}

function SidebarAppSelectorItem({
  app,
  open,
}: {
  app: SidebarApp;
  open: boolean;
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { title, Icon, path } = app;

  const selected = useMemo(() => {
    return pathname.startsWith(path);
  }, [pathname, path]);

  return (
    <Button
      onClick={() => navigate(path)}
      sx={(theme) => ({
        height: '40px',
        mx: 1,
        mb: 0.25,
        padding: '0',
        border: '0',
        minWidth: '0',
        justifyContent: 'flex-start',
        backgroundColor: selected
          ? theme.palette.strokes.light
          : theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.strokes.light,
        },
        '&:active': {
          backgroundColor: theme.palette.strokes.heavy,
        },
      })}
    >
      <Icon
        sx={(theme) => ({
          px: 1,
          color: selected
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
        })}
      />
      {open && (
        <Typography
          display="flex"
          alignItems="center"
          noWrap
          maxWidth="100%"
          variant="body2"
          mr={1}
          sx={{ fontWeight: selected ? 'bold' : 'normal' }}
        >
          {title}
        </Typography>
      )}
    </Button>
  );
}
