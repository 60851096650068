import { MembershipContext } from '../modules/types';

export function membershipEquals(a?: MembershipContext, b?: MembershipContext) {
  if (!a || !b) {
    return false;
  }
  return (
    a.organizationId === b.organizationId &&
    a.companyId === b.companyId &&
    a.membershipType === b.membershipType
  );
}
