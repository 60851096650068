import { MouseEvent, useEffect, useRef, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Typography } from '@mui/material';

import {
  SecurePasswordValidator,
  ShowablePasswordField,
} from '../../../modules/login';

export type PasswordChangeProps = {
  submit: (password: string) => Promise<void>;
  onSuccess: () => void;
};

export function PasswordChange({ submit, onSuccess }: PasswordChangeProps) {
  const passwordRef = useRef<HTMLDivElement | null>();
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    showTooltip: false,
    showPassword: false,
    isValidPassword: false,
    validation: {
      hasMinLength: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialCharacter: false,
    },
  });

  const showTooltip = () => {
    if (state.isValidPassword) {
      return;
    }
    setState((oldState) => ({
      ...oldState,
      showTooltip: true,
    }));
  };

  const closeTooltip = () => {
    if (state.isValidPassword) {
      setState((oldState) => ({
        ...oldState,
        showTooltip: false,
      }));
    }
  };

  const showPassword = () => {
    setState((oldState) => ({
      ...oldState,
      showPassword: !oldState.showPassword,
    }));
  };

  const setNewPasswordValue = (e: { target: { value: string } }) => {
    const password = e.target.value;
    const hasMinLength = password.length >= 12;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-={};':"\\|,.<>/?]/.test(
      password,
    );
    const isValidPassword =
      hasMinLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialCharacter;
    setState((oldState) => ({
      ...oldState,
      password,
      isValidPassword,
      validation: {
        hasMinLength,
        hasUpperCase,
        hasLowerCase,
        hasNumber,
        hasSpecialCharacter,
      },
    }));
  };

  const { mutate, isError, isLoading, error } = useMutation(
    async (e: MouseEvent) => {
      e.preventDefault();
      if (!state.isValidPassword) {
        throw new Error('Senha inválida');
      }
      if (state.password !== state.confirmPassword) {
        throw new Error('Senhas estão diferentes');
      }
      await submit(state.password);
      onSuccess();
    },
  );

  useEffect(() => {
    if (state.isValidPassword && state.showTooltip) {
      closeTooltip();
    }
    if (
      !state.isValidPassword &&
      !state.showTooltip &&
      state.password.length > 0
    ) {
      showTooltip();
    }
  }, [state.isValidPassword, state.showTooltip, state.password]);

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={4}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="h3"
          fontWeight="700"
          data-testid="new-password-title"
        >
          Criar uma nova senha
        </Typography>
        <Typography variant="body2">
          Você vai usar essa senha para entrar na sua conta no futuro.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            Insira a nova senha
          </Typography>
          <Box ref={passwordRef}>
            <ShowablePasswordField
              onChange={setNewPasswordValue}
              onMouseOver={showTooltip}
              onBlur={closeTooltip}
              show={state.showPassword}
              setShow={showPassword}
              error={isError}
              data-testid="password"
            />
          </Box>
          {state.showTooltip && (
            <Box
              boxSizing="border-box"
              px={2}
              py={1.5}
              width="100%"
              boxShadow="rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px"
              borderRadius={1}
            >
              <SecurePasswordValidator {...state.validation} />
            </Box>
          )}
        </Box>
        {!state.showTooltip && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight="500"
            >
              Confirme a nova senha
            </Typography>
            <ShowablePasswordField
              onChange={(e) => {
                setState((oldState) => ({
                  ...oldState,
                  confirmPassword: e.target.value,
                }));
              }}
              show={state.showPassword}
              setShow={showPassword}
              error={isError}
              data-testid="confirm"
            />
          </Box>
        )}
      </Box>
      {!state.showTooltip && isError && (
        <Alert severity="error">
          <Typography
            variant="caption"
            fontWeight="500"
            data-testid="new-password-error"
          >
            {(error as Error).message}
          </Typography>
        </Alert>
      )}
      {!state.showTooltip && (
        <LoadingButton
          fullWidth
          color="primaryAlt"
          loading={isLoading}
          onClick={mutate}
          variant="contained"
          sx={{ p: 1.5 }}
          data-testid="submit"
        >
          <Typography variant="body1" color="secondary" fontWeight="700">
            Continuar
          </Typography>
        </LoadingButton>
      )}
    </Box>
  );
}
