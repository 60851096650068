import { MouseEvent, useRef, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';

import { ShowablePasswordField } from '../../../../modules/login';

export type Props = {
  username: string;
  hidden: boolean;
  submit: ({
    tempPassword,
    error,
  }: {
    tempPassword: string;
    error: string;
  }) => Promise<void>;
};

export function StepOne({ username, submit, hidden }: Props) {
  const passwordRef = useRef<HTMLDivElement | null>();
  const [state, setState] = useState({
    tempPassword: '',
  });

  const setTempPasswordValue = (e: { target: { value: string } }) => {
    setState((oldState) => ({
      ...oldState,
      tempPassword: e.target.value,
    }));
  };

  const { mutate, isError, isLoading } = useMutation(async (e: MouseEvent) => {
    e.preventDefault();
    let error = null;
    if (!state.tempPassword) {
      error = 'Senha inválida';
    }

    if (error) {
      await submit({
        tempPassword: state.tempPassword,
        error,
      });
      throw new Error(error);
    }

    await submit({
      tempPassword: state.tempPassword,
      error: null,
    });
  });

  if (hidden) {
    return null;
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            E-mail
          </Typography>
          <TextField
            disabled
            fullWidth
            variant="outlined"
            type="text"
            value={username}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            Senha temporária
          </Typography>
          <Box ref={passwordRef}>
            <ShowablePasswordField
              onChange={setTempPasswordValue}
              show={true}
              hideButton={true}
              error={isError && state.tempPassword === ''}
              data-testid="tempPassword"
            />
          </Box>
        </Box>
      </Box>
      <LoadingButton
        fullWidth
        color="primaryAlt"
        loading={isLoading}
        onClick={mutate}
        variant="contained"
        sx={{ p: 1.5 }}
        data-testid="submit"
      >
        <Typography variant="body1" color="secondary" fontWeight="700">
          Avançar
        </Typography>
      </LoadingButton>
    </>
  );
}
