import { ZodTypeAny, z } from 'zod';

import {
  IFormDefinitionEntry,
  isFieldCollectionWithOptions,
  isFieldset,
  isFieldsetBranching,
  isNamedFieldDefinition,
  isTypedFieldDefinition,
} from './formFieldDefinitions/formFieldDefinitions';
import {
  booleanType,
  numberType,
  tupleType,
} from './formFieldDefinitions/formFieldDefinitionValueType';

const INTERSECTION_FIELDS = Symbol.for('forms_intersection');

type IFieldShape = Record<string, z.ZodTypeAny> &
  Partial<Record<typeof INTERSECTION_FIELDS, z.ZodTypeAny[]>>;
type IFieldShapes = IFieldShapesSingle | IFieldShapesUnion;
type IFieldShapesSingle = [IFieldShape];
type IFieldShapesUnion = [IFieldShape, IFieldShape, ...IFieldShape[]];

function parseToZodSchema(zodTypeShape: IFieldShape) {
  const shapeWithoutIntersections = Object.fromEntries(
    Object.entries(zodTypeShape),
  );
  const intersectionShapes = zodTypeShape[INTERSECTION_FIELDS];

  const mainShape = z.object(shapeWithoutIntersections);
  if (!intersectionShapes?.length) return mainShape;

  const shapeWithIntersections = intersectionShapes.reduce((shapeA, shapeB) => {
    return z.intersection(shapeA, shapeB);
  }, mainShape);

  return shapeWithIntersections;
}
function parseToObjectShapes(
  fieldDefinitions: IFormDefinitionEntry[],
  shapes: IFieldShapes = [{}],
  currentShapeIndex = 0,
  levelId = '',
) {
  const currentShape = shapes[currentShapeIndex];
  if (!currentShape) return shapes;

  let lastFieldCount = 0;
  for (const field of fieldDefinitions) {
    const currentLevelId = `${levelId}${lastFieldCount++}`;

    if (isFieldset(field)) {
      const isNamedField = isNamedFieldDefinition(field);
      const nestedShapes = [{}] as IFieldShapes;
      parseToObjectShapes(
        field.fields,
        nestedShapes,
        nestedShapes.length - 1,
        currentLevelId,
      );

      const nestedSchema = parseToZodSchema(nestedShapes[0]);
      if (isNamedField) {
        currentShape[field.name] = nestedSchema;
      } else {
        const intersectionFields = currentShape[INTERSECTION_FIELDS] || [];
        intersectionFields.push(nestedSchema);
        currentShape[INTERSECTION_FIELDS] = intersectionFields;
      }
    } else if (isFieldsetBranching(field)) {
      const isNamedField = isNamedFieldDefinition(field);
      const branchingShapes: IFieldShapesUnion =
        [] as unknown as IFieldShapesUnion;
      for (const branchingOption of field.fieldsOptions) {
        branchingShapes.push({});
        parseToObjectShapes(
          branchingOption.fields,
          branchingShapes,
          branchingShapes.length - 1,
          currentLevelId,
        );
      }

      const branchingSchemas = branchingShapes
        .filter((shape) => Object.keys(shape).length)
        .map((shape) => parseToZodSchema(shape)) as unknown as [
        ZodTypeAny,
        ZodTypeAny,
        ...ZodTypeAny[],
      ];

      const branchingSchemasUnion = branchingSchemas.length
        ? z.union(branchingSchemas)
        : null;

      if (isNamedField) {
        if (field.type || branchingSchemasUnion) {
          currentShape[field.name] = field.type || branchingSchemasUnion;
        }
      } else {
        if (branchingSchemasUnion) {
          const intersectionFields = currentShape[INTERSECTION_FIELDS] || [];
          intersectionFields.push(branchingSchemasUnion);
          currentShape[INTERSECTION_FIELDS] = intersectionFields;
        }
      }
    } else if (isTypedFieldDefinition(field) && isNamedFieldDefinition(field)) {
      const isSerializedJSON =
        numberType.safeParse(field.type).success ||
        booleanType.safeParse(field.type).success ||
        tupleType.safeParse(field.type).success;

      currentShape[field.name] = field.type;

      if (isSerializedJSON) {
        currentShape[field.name] = z.preprocess((value) => {
          if (typeof value === 'string') {
            try {
              return JSON.parse(value);
            } catch (error) {
              return value;
            }
          }
          return value;
        }, field.type);
      }

      if (isFieldCollectionWithOptions(field)) {
        const requiredOptions = field.options
          .filter((option) => option.required)
          .map((option) => option.value);
        const defaultSelectedOptions = field.options
          .filter((option) => !option.required && option.selected)
          .map((option) => option.value);

        if (field.type instanceof z.ZodArray) {
          currentShape[field.name] = field.type.default([
            ...requiredOptions,
            ...defaultSelectedOptions,
          ]);
        } else if (field.type instanceof z.ZodSet) {
          currentShape[field.name] = field.type.default(
            new Set([...requiredOptions, ...defaultSelectedOptions]),
          );
        }
      }
    }
  }

  return shapes;
}

export function createValidationSchema(
  fieldDefinitions: IFormDefinitionEntry[],
): Zod.z.ZodTypeAny {
  const zodTypeShapes: IFieldShapes = parseToObjectShapes(fieldDefinitions);
  const validationSchema = parseToZodSchema(zodTypeShapes[0]);
  return validationSchema;
}
