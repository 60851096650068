import { z } from 'zod';

import { IFormDefinition } from './formFieldDefinitions';
import { UI_TYPE } from './UI_TYPE';

const COMPOSICOES: IFormDefinition = [
  {
    label: 'Incluir composições',
    name: 'compositions',
    type: z.array(z.string()),
    uiType: UI_TYPE.CHECKBOX,
    options: [
      {
        label: 'IRRF',
        value: 'irrf',
      },
      {
        label: 'INSS',
        value: 'inss',
      },
      {
        label: 'FGTS',
        value: 'fgts',
      },
    ],
  },
  {
    label: 'Selecionar competências',
    name: 'period',
    uiType: UI_TYPE.SELECT,
    fieldsOptions: [
      {
        label: 'Em',
        selected: true,
        fields: [
          {
            label: 'Mês',
            name: 'in',
            type: z.string(),
            uiType: UI_TYPE.TEXT_DATE_PICKER_YEAR_MONTH,
          },
        ],
      },
      {
        label: 'Entre',
        fields: [
          {
            label: 'Mês inicial',
            name: 'start',
            type: z.string(),
            uiType: UI_TYPE.TEXT_DATE_PICKER_YEAR_MONTH,
          },
          {
            label: 'Mês final',
            name: 'end',
            type: z.string(),
            uiType: UI_TYPE.TEXT_DATE_PICKER_YEAR_MONTH,
          },
        ],
      },
    ],
  },
  {
    label: 'Incluir dados dos colaboradores',
    name: 'fields',
    type: z.array(z.string()),
    uiType: UI_TYPE.SELECT_SEARCH_TAG_CLOUD,
    options: [
      {
        label: 'Nome completo',
        value: 'nmTrab',
        selected: true,
        required: true,
      },
      {
        label: 'Matrícula',
        value: 'matricula',
        selected: true,
        required: true,
      },
      {
        label: 'CPF',
        value: 'cpfTrab',
        selected: true,
        required: true,
      },
      {
        label: 'Situação',
        value: 'br:situacao',
      },
      {
        label: 'Data de admissão',
        value: 'br:dataAdmissao',
      },
      {
        label: 'Data de admissão',
        value: 'br:dataAdmissao',
      },
      {
        label: 'Vínculo',
        value: 'br:vinculo',
      },
      {
        label: 'Cargo',
        value: 'br:cargo',
      },
      {
        label: 'Centro de custo',
        value: 'br:centroDeCusto',
      },
      {
        label: 'Departamento',
        value: 'br:departamento',
      },
      {
        label: 'Salário contratual',
        value: 'br:salarioContratual',
      },
    ],
  },
];

const COMPOSICOES_PAYLOAD_A = {
  compositions: ['irrf', 'inss'],
  period: {
    in: '2023-12',
  },
  fields: ['nmTrab', 'matricula', 'cpfTrab'],
};
const COMPOSICOES_PAYLOAD_B = {
  compositions: ['irrf', 'inss'],
  period: {
    start: '2019-01',
    end: '2023-12',
  },
  fields: ['nmTrab', 'matricula', 'cpfTrab'],
};

const ADMISSOES_RESCISOES: IFormDefinition = [
  {
    label: 'Evento e Período',
    fields: [
      {
        label: 'Evento',
        name: 'events',
        type: z.array(z.enum(['ADMISSION', 'TERMINATION'])),
        uiType: UI_TYPE.SELECT,
        options: [
          {
            label: 'Admissões e/ou rescisões',
            value: ['ADMISSION', 'TERMINATION'],
          },
          {
            label: 'Admissões',
            value: ['ADMISSION'],
          },
          {
            label: 'Rescisões',
            value: ['TERMINATION'],
          },
        ],
      },
      {
        label: 'Período',
        uiType: UI_TYPE.SELECT,
        name: 'period',
        fieldsOptions: [
          {
            label: 'Em',
            selected: true,
            fields: [
              {
                name: 'atDate',
                label: 'Mês',
                type: z.string(),
                uiType: UI_TYPE.TEXT_DATE_PICKER,
              },
            ],
          },
          {
            label: 'Entre',
            fields: [
              {
                name: 'startDate',
                label: 'Mês inicial',
                type: z.string(),
                uiType: UI_TYPE.TEXT_DATE_PICKER,
              },
              {
                name: 'endDate',
                label: 'Mês final',
                type: z.string(),
                uiType: UI_TYPE.TEXT_DATE_PICKER,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Incluir dados dos colaboradores',
    name: 'dadosColaboradores',
    type: z.array(z.string()),
    uiType: UI_TYPE.SELECT_SEARCH_TAG_CLOUD,
    options: [
      {
        label: 'Nome completo',
        value: 'br:completeName',
        selected: true,
        required: true,
      },
      {
        label: 'Matrícula',
        value: 'br:matricula',
        selected: true,
        required: true,
      },
      {
        label: 'CPF',
        value: 'br:cpf',
        selected: true,
        required: true,
      },
      {
        label: 'Situação',
        value: 'br:situacao',
      },
      {
        label: 'Data de admissão',
        value: 'br:dataAdmissao',
      },
      {
        label: 'Data de admissão',
        value: 'br:dataAdmissao',
      },
      {
        label: 'Vínculo',
        value: 'br:vinculo',
      },
      {
        label: 'Cargo',
        value: 'br:cargo',
      },
      {
        label: 'Centro de custo',
        value: 'br:centroDeCusto',
      },
      {
        label: 'Departamento',
        value: 'br:departamento',
      },
      {
        label: 'Salário contratual',
        value: 'br:salarioContratual',
      },
    ],
  },
];

const ADMISSOES_RESCISOES_PAYLOAD_A = {
  events: ['ADMISSION', 'TERMINATION'],
  period: {
    atDate: '2019-11-01',
  },
  dadosColaboradores: ['br:completeName', 'br:matricula', 'br:cpf'],
};

const ADMISSOES_RESCISOES_PAYLOAD_B = {
  events: ['ADMISSION', 'TERMINATION'],
  period: {
    startDate: '2019-11-01',
    endDate: '2023-11-01',
  },
  dadosColaboradores: ['br:completeName', 'br:matricula', 'br:cpf'],
};

const ADMISSOES_RESCISOES_PAYLOAD_C = {
  events: ['ADMISSION'],
  period: {
    atDate: '2019-11-01',
  },
  dadosColaboradores: ['br:completeName', 'br:matricula', 'br:cpf'],
};

const ADMISSOES_RESCISOES_PAYLOAD_D = {
  events: ['ADMISSION'],
  period: {
    startDate: '2019-11-01',
    endDate: '2023-11-01',
  },
  dadosColaboradores: ['br:completeName', 'br:matricula', 'br:cpf'],
};

const ADMISSOES_RESCISOES_PAYLOAD_E = {
  events: ['TERMINATION'],
  period: {
    atDate: '2019-11-01',
  },
  dadosColaboradores: ['br:completeName', 'br:matricula', 'br:cpf'],
};
const ADMISSOES_RESCISOES_PAYLOAD_F = {
  events: ['TERMINATION'],
  period: {
    startDate: '2019-11-01',
    endDate: '2023-11-01',
  },
  dadosColaboradores: ['br:completeName', 'br:matricula', 'br:cpf'],
};

const ANIVERSARIOS: IFormDefinition = [
  {
    name: 'month',
    label: 'Mês',
    type: z.number(),
    uiType: UI_TYPE.SELECT,
    options: [
      { label: 'Janeiro', value: 1 },
      { label: 'Fevereiro', value: 2 },
      { label: 'Março', value: 3 },
      { label: 'Abril', value: 4 },
      { label: 'Maio', value: 5 },
      { label: 'Junho', value: 6 },
      { label: 'Julho', value: 7 },
      { label: 'Agosto', value: 8 },
      { label: 'Setembro', value: 9 },
      { label: 'Outubro', value: 10 },
      { label: 'Novembro', value: 11 },
      { label: 'Dezembro', value: 12 },
    ],
  },
  {
    label: 'Incluir dados dos colaboradores',
    name: 'dadosColaboradores',
    type: z.array(z.string()),
    uiType: UI_TYPE.SELECT_SEARCH_TAG_CLOUD,
    options: [
      {
        label: 'Nome completo',
        value: 'br:completeName',
        selected: true,
        required: true,
      },
      {
        label: 'Matrícula',
        value: 'br:matricula',
        selected: true,
        required: true,
      },
      {
        label: 'CPF',
        value: 'br:cpf',
        selected: true,
        required: true,
      },
      {
        label: 'Situação',
        value: 'br:situacao',
      },
      {
        label: 'Data de admissão',
        value: 'br:dataAdmissao',
      },
      {
        label: 'Data de admissão',
        value: 'br:dataAdmissao',
      },
      {
        label: 'Vínculo',
        value: 'br:vinculo',
      },
      {
        label: 'Cargo',
        value: 'br:cargo',
      },
      {
        label: 'Centro de custo',
        value: 'br:centroDeCusto',
      },
      {
        label: 'Departamento',
        value: 'br:departamento',
      },
      {
        label: 'Salário contratual',
        value: 'br:salarioContratual',
      },
    ],
  },
];

const ANIVERSARIOS_PAYLOAD = {
  month: 1,
  dadosColaboradores: ['br:completeName', 'br:matricula', 'br:cpf'],
};

export const FORM_PAYLOAD = {
  ANIVERSARIOS: ANIVERSARIOS_PAYLOAD,
  COMPOSICOES_A: COMPOSICOES_PAYLOAD_A,
  COMPOSICOES_B: COMPOSICOES_PAYLOAD_B,
  ADMISSOES_RESCISOES_A: ADMISSOES_RESCISOES_PAYLOAD_A,
  ADMISSOES_RESCISOES_B: ADMISSOES_RESCISOES_PAYLOAD_B,
  ADMISSOES_RESCISOES_C: ADMISSOES_RESCISOES_PAYLOAD_C,
  ADMISSOES_RESCISOES_D: ADMISSOES_RESCISOES_PAYLOAD_D,
  ADMISSOES_RESCISOES_E: ADMISSOES_RESCISOES_PAYLOAD_E,
  ADMISSOES_RESCISOES_F: ADMISSOES_RESCISOES_PAYLOAD_F,
};

export const FORM_DEFINITION = {
  COMPOSICOES,
  ADMISSOES_RESCISOES,
  ANIVERSARIOS,
};
