import { useEffect, useMemo, useState } from 'react';

import { TFFlagValue, TFFlagsContext } from './createFFlagsContext';

type TFlagInstanceValue =
  | {
      isLoading: true;
      enabled: false;
    }
  | {
      isLoading: false;
      enabled: boolean;
    };

export function createFFlagsInstance<const TFlagName extends string>(
  flagContext: TFFlagsContext<TFlagName>,
) {
  const [fflags, setFFlags] =
    useState<Partial<Record<TFlagName, TFFlagValue>>>();

  useEffect(() => {
    flagContext.pull().then((fflags) => {
      setFFlags(fflags);
    });
  }, []);

  const fflagsInstance = useMemo(() => {
    return new Proxy({} as Record<TFlagName, TFlagInstanceValue>, {
      get: (_target, propName: string) => {
        const isLoading = fflags === undefined;
        return {
          isLoading: isLoading,
          enabled:
            !isLoading && propName in fflags && fflags[propName as TFlagName],
        };
      },
    });
  }, [fflags]);

  return fflagsInstance;
}
