import { PageAlert } from './PageAlert';

export function MissingPersonIdAlert() {
  return (
    <PageAlert
      message="Seu perfil de acesso não possui dados pessoais cadastrados."
      severity="warning"
      showRetryMessage={false}
    />
  );
}
