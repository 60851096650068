import { DateTimeFormatter, Year, YearMonth } from '@js-joda/core';

import { PeriodFormat } from '../modules/types';

const YYYYMM_FORMATTER = DateTimeFormatter.ofPattern('yyyy-MM');

export function getNextPeriod(period: PeriodFormat): PeriodFormat {
  if (period.length === 4) {
    return Year.parse(period).plusYears(1).toString() as PeriodFormat;
  }
  return YearMonth.parse(period, YYYYMM_FORMATTER)
    .plusMonths(1)
    .format(YYYYMM_FORMATTER) as PeriodFormat;
}

export function getPreviousPeriod(period: PeriodFormat): PeriodFormat {
  if (period.length === 4) {
    return Year.parse(period).minusYears(1).toString() as PeriodFormat;
  }
  return YearMonth.parse(period, YYYYMM_FORMATTER)
    .minusMonths(1)
    .format(YYYYMM_FORMATTER) as PeriodFormat;
}
