import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { Button } from '@octopus/ui/design-system';

export function ConfirmModal({
  open,
  isSubmitting,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  isSubmitting: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  if (!open) {
    return null;
  }

  const content = [
    {
      title: 'A pessoa colaboradora será avisada',
      text: 'Ela receberá um email avisando que suas férias foram agendadas, com todas as informações detalhadas sobre suas férias.',
    },
    {
      title: 'O cálculo do pagamento ficará disponível para aprovação',
      text: 'Na aba ”Cálculos” será possível ver os detalhes deste agendamento, assim como a prévia do cálculo do pagamento, que poderá ser aprovado a qualquer momento. O recibo e os dados para o pagamento serão gerados após a aprovação do cálculo.',
    },
  ];
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      data-testid="confirm-vacations-modal"
    >
      <DialogTitle mt={3}>
        <Typography fontWeight={700} fontSize="32px">
          Agendar férias
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight="bold" fontSize="16px">
          Ao confirmar, o que acontece em seguida:
        </Typography>
        <List
          disablePadding
          sx={{
            listStyleType: 'disc',
            '& .MuiListItem-root': {
              display: 'list-item',
            },
            pl: 3,
          }}
        >
          {content.map((item) => (
            <ListItem sx={{ mt: 2 }} disablePadding key={item.title}>
              <Box display="flex" flexDirection="column">
                <Typography fontWeight={500} fontSize="16px">
                  {item.title}
                </Typography>
                <Typography mt={0.5} variant="subtitle1" fontSize="12px">
                  {item.text}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={onCancel}
          variantSemantic="secondary"
        >
          Cancelar
        </Button>
        <Button
          sx={{ minWidth: '127px' }}
          isLoading={isSubmitting}
          onClick={onConfirm}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
