import Big from 'big.js';

import { Box, Typography, TypographyVariant } from '@mui/material';

import { formatMoney } from '@octopus/formatters';

import comparisonNegativeIcon from '../../../assets/comparisonArrowNegative.svg';
import comparisonPositiveIcon from '../../../assets/comparisonArrowPositive.svg';

export function DisplayDiff({
  current,
  compare,
  variant,
  withSign = true,
  withCurrency = true,
}: {
  variant: TypographyVariant;
  current: string | undefined;
  compare: string | undefined;
  withSign?: boolean;
  withCurrency?: boolean;
}) {
  const diff = compare ? Big(current || 0).minus(compare) : Big(current || 0);

  const isPositiveDiff = diff.gt(0);
  const isNegativeDiff = diff.lt(0);

  const color = isPositiveDiff
    ? 'success.main'
    : isNegativeDiff
      ? 'error.main'
      : 'strokes.heavy';

  return (
    <Typography
      variant={variant}
      color={diff.gt(0) ? 'success.main' : 'error.main'}
      sx={{
        color: color,
        '--icon-fill-color': color,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!withSign && (
        <Box
          component={'img'}
          sx={{
            order: 1,
            marginInlineStart: 0.75,
            height: '.5em',
            ...(isPositiveDiff && {
              position: 'relative',
              top: '.05em', //positive arrow svg is not cropped as down arrow svg
            }),
          }}
          {...(isPositiveDiff && {
            src: comparisonPositiveIcon,
            alt: '+',
          })}
          {...(isNegativeDiff && {
            src: comparisonNegativeIcon,
            alt: '-',
          })}
        />
      )}

      {formatMoney(diff.toString(), { withSign, withCurrency })}
    </Typography>
  );
}
