import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Divider, Drawer, Typography } from '@mui/material';

import { PayrollElement, PayrollOutputsOutputs } from '@octopus/api';
import { formatMoney } from '@octopus/formatters';

import { CalculationExplanation } from '../payrolls/CalculationExplanation';

export type CalculationBreakdownProps = {
  calculationOutputs: PayrollOutputsOutputs;
};

export function CalculationBreakdown({
  calculationOutputs,
}: CalculationBreakdownProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          mx: 0.5,
          px: 3,
          py: 4,
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: theme.palette.strokes.light,
          borderRadius: '8px',
          gap: 6,
        },
        [theme.breakpoints.down('md')]: {
          px: 2.5,
          py: 1,
          gap: 6,
        },
      })}
      data-testid="payslip-calculation-breakdown"
    >
      <CalculationBasis
        label="Proventos"
        basisElements={calculationOutputs.workerEarningsTotal.elements.map(
          (element) => calculationOutputs.elements[element],
        )}
        total={calculationOutputs.workerEarningsTotal.total}
        elements={calculationOutputs.elements}
      />
      <CalculationBasis
        label="Deduções"
        basisElements={calculationOutputs.workerDeductionsTotal.elements.map(
          (element) => calculationOutputs.elements[element],
        )}
        total={calculationOutputs.workerDeductionsTotal.total}
        elements={calculationOutputs.elements}
      />
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <Box py={1}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between" px={1} py={2}>
          <Typography width="65%" variant="h4" fontWeight="700">
            Valor líquido total
          </Typography>
          <Typography width="30%" variant="h4" fontWeight="700">
            {formatMoney(calculationOutputs.netPay.total)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          pl={2.5}
          pr={3.5}
          py={2}
          my={1}
          bgcolor="background.default"
          borderRadius={1}
          border={(theme) => `1px solid ${theme.palette.strokes.light}`}
        >
          <Typography variant="body1" fontWeight="700">
            Líquido total
          </Typography>
          <Typography variant="body1" fontWeight="700">
            {formatMoney(calculationOutputs.netPay.total)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

type CalculationBasisProps = {
  label: string;
  basisElements: PayrollElement[];
  total: string;
  elements: { [key: string]: PayrollElement };
};

function CalculationBasis({
  label,
  basisElements,
  total,
  elements,
}: CalculationBasisProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stackCount = parseInt(searchParams.get('c') ?? '0');
  const elementId = searchParams.get('elementId');
  const [element] = useState<PayrollElement | undefined>(
    elementId ? elements[elementId] : undefined,
  );

  const onClickElement = (element: PayrollElement) => {
    navigate(`?elementId=${element.id}&c=${stackCount + 1}`);
  };

  return (
    <Box>
      <Typography
        px={1}
        pb={2}
        variant="h3"
        fontWeight="700"
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            pb: 2,
          },
          [theme.breakpoints.down('md')]: {
            pb: 1,
          },
        })}
      >
        {label}
      </Typography>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <Box display="flex" justifyContent="space-between" px={1}>
          <Typography width="65%" variant="body2" fontWeight="700">
            Descrição
          </Typography>
          <Typography width="30%" variant="body2" fontWeight="700">
            Valor
          </Typography>
        </Box>
        <Box py={1}>
          <Divider />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        {basisElements.map((element) => (
          <Box
            key={element.id}
            display="flex"
            justifyContent="space-between"
            px={1}
            sx={(theme) => ({
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.background.default,
              },
              [theme.breakpoints.up('md')]: {
                borderRadius: 1,
                py: 0.75,
              },
              [theme.breakpoints.down('md')]: {
                py: 2,
                borderBottom: `1px solid ${theme.palette.strokes.light}`,
              },
            })}
            onClick={() => onClickElement(element)}
            data-testid="payslip-calculation-breakdown-item"
          >
            <Typography width="65%" variant="body2" fontWeight="500">
              {element.name}
            </Typography>
            <Typography width="30%" variant="body2" fontWeight="500">
              {formatMoney(element.amount)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        py={1}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        px={1}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            pt: 2,
          },
          [theme.breakpoints.up('md')]: {
            py: 0.5,
          },
        })}
      >
        <Typography width="65%" variant="body2" fontWeight="700">
          Total
        </Typography>
        <Typography width="30%" variant="body2" fontWeight="700">
          {formatMoney(total)}
        </Typography>
      </Box>
      <Drawer
        anchor={'right'}
        open={!!element}
        onClose={() => navigate('')}
        elevation={2}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
        transitionDuration={0}
      >
        <Box
          width="520px"
          data-testid="payslip-calculation-breakdown-explanation"
        >
          <CalculationExplanation
            element={element}
            onClickElement={onClickElement}
            canGoBack={stackCount > 1}
            goBack={() => {
              navigate(-1);
            }}
            onExit={() => {
              navigate('');
            }}
          />
        </Box>
      </Drawer>
      <Drawer
        anchor={'bottom'}
        open={!!element}
        onClose={() => navigate('')}
        elevation={2}
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
          zIndex: 99999,
        })}
        PaperProps={{ sx: { borderRadius: '16px 16px 0 0 ' } }}
        transitionDuration={0}
      >
        <Box
          height="85vh"
          data-testid="payslip-calculation-breakdown-explanation"
        >
          <CalculationExplanation
            element={element}
            onClickElement={onClickElement}
            canGoBack={stackCount > 1}
            goBack={() => {
              navigate(-1);
            }}
            onExit={() => {
              navigate('');
            }}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
