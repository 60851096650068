import {
  GeneratePdfReportVariables,
  fetchGeneratePdfReport,
} from '@octopus/api';

export const downloadPdfReport = async (args: GeneratePdfReportVariables) => {
  let file = { fileContent: '', fileName: '' };
  const maxAttempts = 120; // 5 minutes  (lambda timeout)
  const delay = 5 * 1000; // 5 seconds
  let attempts = 0;

  do {
    attempts++;
    file = await fetchGeneratePdfReport(args);
    if (file.fileContent) {
      break;
    }

    await new Promise((resolve) => setTimeout(resolve, delay));
  } while (!file.fileContent && attempts < maxAttempts);
  if (!file.fileContent) {
    throw new Error('Failed to download payslips');
  }
  return {
    fileContent: file.fileContent,
    fileName: file.fileName,
  };
};
