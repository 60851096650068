import React from 'react';

import {
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
  AdmissionType,
} from '@octopus/api';
import { UnidadeSalarioFixo } from '@octopus/esocial/mapper';
import { z } from '@octopus/i18n';
import {
  BrasilAmountType,
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  cnpjSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import { NewAdmissionSummary } from '../../summary/NewAdmissionSummary';
import {
  getDuracaoFields,
  getProfissionalFields,
  getRegimeFields,
} from '../fieldDefinitions';
import { getCriacaoUsuarioFields } from '../fieldDefinitions/criacaoUsuario';
import { getJornadaFields } from '../fieldDefinitions/jornada';
import { getModalidadeContratoFields } from '../fieldDefinitions/modalidadeContrato';
import {
  getOptionsFromCompanyContext,
  getOptionsFromMapper,
} from '../inputUtils';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  ENABLED_UNIDADE_SALARIO_FIXO,
  FormStepDefinition,
} from '../types';

import { IFormSteps } from './types';

const getAdminSteps = ({
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
  probationPeriodEnabled,
  estagiarioEnabled,
  pjEnabled,
}: {
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
  probationPeriodEnabled: boolean;
  estagiarioEnabled: boolean;
  pjEnabled: boolean;
}): AdmissionFormSteps => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities
  ) {
    return {};
  }

  const {
    admissionType,
    admissionDate,
    tpContr,
    cnpjSindCategProf,
    salary,
    unidadeBaseCalculo,
  } = formState;

  const modalidade_contrato = getModalidadeContratoFields({
    companyContext,
    formState,
    estagiarioEnabled,
    pjEnabled,
    modalidadeFormSteps: formSteps[admissionDraftFormSteps.modalidade_contrato],
  });

  const criacao_usuario = getCriacaoUsuarioFields({
    formState,
    criacaoUsuarioFormSteps: formSteps[admissionDraftFormSteps.criacao_usuario],
  });

  const profissional = getProfissionalFields({
    companyContext,
    formState,
    profissionalFormSteps: formSteps[admissionDraftFormSteps.profissional],
  });

  const regime_trabalho = getRegimeFields({
    formState,
    regimeFormSteps: formSteps[admissionDraftFormSteps.regime_trabalho],
  });

  const jornada = getJornadaFields({
    formState,
    jornadaFormSteps: formSteps[admissionDraftFormSteps.jornada],
    hideJornadaFields: false,
  });

  const duracao = getDuracaoFields({
    tpContr,
    admissionDate,
    duracaoFormSteps: formSteps[admissionDraftFormSteps.duracao],
    probationPeriodEnabled,
  });

  const sindicato = {
    definition: [
      {
        label: 'CNPJ do sindicato',
        type: cnpjSchema,
        name: 'cnpjSindCategProf',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Selecione um sindicato',
        options: getOptionsFromCompanyContext({
          baseContext: companyContext?.sindicatos,
          currentValue: cnpjSindCategProf,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.sindicato,
      title: 'Sindicato',
      completed: formSteps[admissionDraftFormSteps.sindicato]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.sindicato]?.reviewed,
    },
  };

  const remuneracao = {
    definition: [
      {
        label: 'Salário base',
        type: BrasilAmountType,
        name: 'salary',
        uiType: UI_TYPE.TEXT_MONEY,
        placeholder: '0,00',
        value: salary || '',
      },
      {
        label: 'Unidade para base de cálculo',
        type: z.number().int(),
        name: 'unidadeBaseCalculo',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o tipo de salário',
        options: getOptionsFromMapper({
          mapper: UnidadeSalarioFixo,
          currentValue: unidadeBaseCalculo,
          enabledKeys: ENABLED_UNIDADE_SALARIO_FIXO,
          selectIfOnlyOne: true,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.remuneracao,
      title: 'Remuneração',
      completed: formSteps[admissionDraftFormSteps.remuneracao]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.remuneracao]?.reviewed,
    },
  };

  const envio_convite = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'envio_convite',
    formSteps,
    isWorkerExperience,
  });

  return {
    modalidade_contrato,
    criacao_usuario,
    profissional,
    regime_trabalho,
    duracao,
    jornada,
    sindicato,
    remuneracao,
    ...(envio_convite && { envio_convite }),
  };
};

const getSummaryStep = ({
  draftStatus,
  admissionType,
  stepName,
  formSteps,
  isWorkerExperience,
}: {
  draftStatus: AdmissionDraftStatus;
  admissionType: AdmissionType;
  stepName: string;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
}): FormStepDefinition => {
  if (
    (admissionType === 'admin_fills' && stepName !== 'finaliza_admissao') ||
    (admissionType === 'send_invite' && stepName !== 'envio_convite') ||
    isWorkerExperience
  ) {
    return null;
  }

  const cta =
    admissionType === 'admin_fills'
      ? draftStatus !== 'admission_submitted'
        ? 'Enviar admissão para revisão'
        : 'Concluir admissão'
      : draftStatus !== 'draft_created'
        ? 'Reenviar convite'
        : 'Salvar candidato(a) e enviar convite';

  return {
    definition: [] as IFormDefinition,
    options: {
      id: stepName as keyof typeof admissionDraftFormSteps,
      title: 'Resumo e finalização',
      hideTitle: true,
      completed: formSteps[stepName]?.completed,
      reviewed: formSteps[stepName]?.reviewed,

      getCustomComponent: ({
        updateDisabled,
        formState,
        stepDefinition,
        onSubmit,
        isLoading,
      }: {
        updateDisabled: boolean;
        formState: AdmissionFormState;
        stepDefinition: FormStepDefinition;
        onSubmit: ({ extraData }: { extraData?: Record<string, any> }) => void;
        isLoading: boolean;
      }): React.ReactElement => {
        if (!formState || !stepDefinition) {
          return null;
        }

        return (
          <NewAdmissionSummary
            submitLabel={cta}
            formState={formState}
            onSubmit={onSubmit}
            isLoading={isLoading}
            disabled={updateDisabled}
          />
        );
      },
    },
  };
};

export const cltGeral = {
  getAdminSteps,
  getSummaryStep,
} as IFormSteps;
