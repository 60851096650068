import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { capitalize } from './string';

dayjs.extend(utc);

const dayInMilliseconds = 1000 * 60 * 60 * 24;

const dateIsoRegex =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?([+-]\d{2}:\d{2}|Z)?$/;

export type DateParts = {
  year: string;
  month: string;
  day?: string;
};

export type DateTimeParts = DateParts & {
  hour: string;
  minute: string;
  second: string;
  locale?: string;
  timezone?: string;
};

export function formatPeriodDate(
  date: string | number | undefined,
  { short = false } = {},
) {
  if (!date) {
    return '';
  }

  if (typeof date === 'number') {
    date = new Date(date).toISOString().split('T')[0]!;
  }

  if (date.length === 4) {
    date = `${date}-12-20`;
  }

  const months = short
    ? [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ]
    : [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
  // from YYYY-MM-DD to Month/Year
  return [
    `${months[Number(date.slice(5, 7)) - 1]}`,
    `${short ? date.slice(2, 4) : date.slice(0, 4)}`,
  ].join(' ');
}

export function formatDateBR(
  date: string | number | undefined | Date,
  { includeDayName = false } = {},
) {
  if (!date) {
    return '';
  }

  if (date instanceof Date) {
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  if (typeof date === 'number') {
    date = new Date(date).toISOString();
  }

  let formatted;
  if (date.includes('T')) {
    const dateParts = breakISODate(date);
    formatted = `${dateParts.day}/${dateParts.month}/${dateParts.year}`;
  } else {
    formatted = date.split('-').reverse().join('/');
  }

  if (!includeDayName) {
    return formatted;
  }

  const dayName = new Date(`${date.split('T')[0]}T12:00:00`).toLocaleDateString(
    'pt-BR',
    {
      weekday: 'short',
    },
  );
  return `${capitalize(dayName)?.replace(/\.$/, '')}, ${formatted}`;
}

export const MONTHS_BR = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export function formatMonthBR(month: string | number | undefined) {
  if (!month) {
    return undefined;
  }
  if (typeof month === 'number') {
    month = month.toString();
  }

  return MONTHS_BR[Number(month) - 1];
}

/**
 * Breaks a date in ISO string into it's parts
 * @param date the date in format ISO string
 * @param props optional props to override the default locale and timezone
 * @returns a type with the date parts, accessible by date.year, date.month and so on
 */
export function breakISODate(
  date: string,
  props?: { locale?: string; timezone?: string },
): DateTimeParts {
  const locale = props?.locale || 'pt-br';
  const timezone = props?.timezone || 'America/Sao_Paulo';

  if (!dateIsoRegex.test(date)) {
    throw new Error(`${date} is not a valid ISO date`);
  }

  const intDate = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: timezone,
  });

  try {
    const parsedDate = new Date(Date.parse(date));
    const formattedDate = intDate.formatToParts(parsedDate).reduce(
      (acc, { type, value }) => {
        acc[type] = value;
        return acc;
      },
      {} as Record<Intl.DateTimeFormatPartTypes, string>,
    );

    return {
      year: formattedDate.year,
      month: formattedDate.month,
      day: formattedDate.day,
      hour: formattedDate.hour,
      minute: formattedDate.minute,
      second: formattedDate.second,
      locale,
      timezone,
    };
  } catch (e) {
    throw new Error(`${date} is not a valid ISO date`);
  }
}

export function formatDateTimeBR(
  date: string | number | undefined,
  withSeconds = false,
): string {
  if (!date) {
    return '';
  }

  if (typeof date === 'number') {
    date = new Date(date).toISOString();
  }

  if (!date.includes('T')) {
    return formatDateBR(date);
  }

  const datePart = new Date(date).toLocaleDateString('pt-br', {
    timeZone: 'America/Sao_Paulo',
  });
  const time = new Date(date)
    .toLocaleTimeString('pt-br', {
      timeZone: 'America/Sao_Paulo',
    })
    .slice(0, withSeconds ? 8 : 5);

  return `${datePart} às ${time}`;
}

export function calculateDaysDifferenceBetweenDates(
  startDate: string | undefined,
  endDate: string | undefined,
): number {
  if (!startDate || !endDate) return 0;
  const differenceInMillis = Math.abs(
    new Date(endDate).getTime() - new Date(startDate).getTime(),
  );
  return Math.floor(differenceInMillis / dayInMilliseconds);
}

export function formatDateWithDaysAdded(
  baseDate: string,
  daysToAdd: number,
): string {
  return dayjs
    .utc(new Date(baseDate))
    .add(daysToAdd, 'days')
    .format('YYYY-MM-DD');
}

export function formatNumberOfDays(days: number | undefined): string {
  if (days == null) return '';
  return days === 1 ? `${days} dia` : `${days} dias`;
}
