import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Divider, Typography } from '@mui/material';

import { answerNewPasswordChallenge, loginUser } from '../../../modules/login';

import { PasswordChange } from './password-change/PasswordChange';

export type Props = {
  username: string;
  onSuccess: (token: string) => void;
};

export function NewPasswordChallenge({ username, onSuccess }: Props) {
  const navigate = useNavigate();

  const submit = async ({
    password,
    tempPassword,
  }: {
    password: string;
    tempPassword: string;
  }) => {
    const result = await loginUser(username, tempPassword);
    if ('challenge' in result) {
      const { token } = await answerNewPasswordChallenge(
        username,
        { challenge: result.challenge },
        password,
      );
      onSuccess(token);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <PasswordChange submit={submit} username={username} />
      <Divider>
        <Typography
          textAlign="center"
          variant="caption"
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          ou
        </Typography>
      </Divider>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 0.5,
          padding: 0.5,
        }}
      >
        <Typography variant="button" color="text.primary">
          Se você já trocou a senha temporária,{' '}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate('/login')}
          sx={{ padding: 0 }}
        >
          clique aqui
        </Button>{' '}
        <Typography variant="button" color="text.primary">
          para entrar na plataforma
        </Typography>
      </Box>
    </Box>
  );
}
