import { z } from 'zod';

const regex = /^[0-9,.]*$/;

export function parseFormAmount(val: unknown) {
  if (!regex.test(String(val))) {
    return undefined;
  }

  const converted = parseFloat(String(val).replace(',', '.'));
  if (isNaN(converted)) {
    return undefined;
  }

  return String(converted);
}

export const BrasilAmountType = z.preprocess((val: unknown) => {
  return parseFormAmount(val);
}, z.string());

export const BrasilAmountTypeOptional = z.preprocess((val: unknown) => {
  if (val === '') {
    return '0';
  }

  return parseFormAmount(val);
}, z.string());
