import React from 'react';

import { LoadingButton } from '@mui/lab';

import DownloadModal, { SupportedPayrollTypes } from './DownloadModal';

interface PayrollDownloadProps {
  organizationId: string;
  companyId: string;
  setError: (error: boolean) => void;
  type: SupportedPayrollTypes;
}

const labels = {
  payroll: 'Folha de Pagamento',
  advance: 'Folha de Adiantamento',
  complementary: 'Folha Complementar',
  complementaryTermination: 'Folha Resc. Complem.',
  termination: 'Desligamento',
  thirteenth: 'Decimo Terceiro',
  rpa: 'RPA',
} as const;

export default function PayrollDownload({
  organizationId,
  companyId,
  setError,
  type,
}: PayrollDownloadProps) {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const label = labels[type];

  const usePeriod = [
    'payroll',
    'advance',
    'complementary',
    'complementaryTermination',
    'rpa',
  ].includes(type);

  return (
    <div>
      <LoadingButton
        onClick={handleClickOpen}
        loading={loading}
        variant="contained"
      >
        {label}
      </LoadingButton>

      <DownloadModal
        organizationId={organizationId}
        companyId={companyId}
        type={type}
        label={label}
        requirements={{ period: usePeriod }}
        states={{
          loading: { state: loading, setState: setLoading },
          error: { state: false, setState: setError },
          open: { state: open, setState: setOpen },
        }}
      />
    </div>
  );
}
