import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export function FileComponent({
  file,
  setFile,
  showDeleteButton = true,
  isLoading = false,
  showFilePreview = true,
  disabled = false,
  disabledMessage = '',
}: {
  file: File | undefined;
  setFile: (file: File | undefined) => void;
  showDeleteButton?: boolean;
  isLoading?: boolean;
  showFilePreview?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
}) {
  return (
    <Box
      bgcolor={'#F7F7F8'}
      borderRadius={1}
      width={'100%'}
      height={'100%'}
      boxSizing={'border-box'}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          padding: 4,
          border: '1px solid',
          borderColor: 'strokes.default',
        },
        [theme.breakpoints.down('md')]: {
          padding: 0,
          border: 'none',
        },
      })}
    >
      {isLoading && <FileOperationScene type={'Carregando'} />}
      {!isLoading && !file && (
        <UploadComponent
          setFile={setFile}
          disabled={disabled}
          disabledMessage={disabledMessage}
        />
      )}
      {!isLoading && file && showFilePreview && (
        <FilePreviewComponent
          file={file}
          setFile={setFile}
          showDeleteButton={showDeleteButton}
        />
      )}
    </Box>
  );
}

export function UploadComponent({
  setFile,
  disabled,
  disabledMessage,
}: {
  setFile: (file: File) => void;
  disabled?: boolean;
  disabledMessage?: string;
}) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (disabled && disabledMessage) {
        setError(disabledMessage);
        return;
      }
      if (acceptedFiles[0].size > 5000000) {
        setError('O arquivo deve ter no máximo 5000kb');
        return;
      }
      setFile(acceptedFiles[0]);
    },
    [disabled, disabledMessage, setFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
  });

  const [error, setError] = useState(undefined as string);

  const bgColorDragging = '#EDEDED';
  const bgColorNotDragging = '#FFF';

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const bgColor = isSmall
    ? 'background.primary'
    : isDragActive
      ? bgColorDragging
      : bgColorNotDragging;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      borderRadius={1}
      bgcolor={bgColor}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      {...getRootProps()}
      boxSizing={'border-box'}
      sx={(theme) => ({
        padding: 3,
        border: `1px dashed ${theme.palette.strokes.light}`,
      })}
    >
      <Box display={'flex'} flexDirection={'row'} bgcolor={bgColor}>
        <FileUploadOutlinedIcon
          sx={(theme) => ({
            width: '24px',
            height: '24px',
            [theme.breakpoints.up('md')]: {
              color: theme.palette.text.secondary,
            },
            [theme.breakpoints.down('md')]: {
              color: theme.palette.primary.main,
            },
          })}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        bgcolor={bgColor}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        {disabled && (
          <Typography
            bgcolor={bgColor}
            textAlign={'center'}
            variant="button"
            color="text.secondary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {disabledMessage}
          </Typography>
        )}
        {!disabled && (
          <Typography
            bgcolor={bgColor}
            textAlign={'center'}
            variant="button"
            color="text.secondary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            Para enviar o arquivo{' '}
            <Typography
              variant="button"
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              clique aqui{' '}
              <input type={'file'} {...getInputProps()} disabled={disabled} />
            </Typography>
            ou arraste-o para cá.{'\n'}
            São aceitos arquivos no formato PDF, JPG e PNG, com tamanho máximo
            de 5MB.
          </Typography>
        )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        bgcolor={bgColor}
        width={'100%'}
        maxWidth={'100%'}
        sx={(theme) => ({
          textAlign: 'center',
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        {disabled && (
          <Typography
            bgcolor={bgColor}
            textAlign={'center'}
            variant="button"
            color="text.secondary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {disabledMessage}
          </Typography>
        )}{' '}
        <Box
          display={'flex'}
          flexDirection={'column'}
          bgcolor={bgColor}
          justifyContent={'center'}
        >
          {!disabled && (
            <Button variant={'text'} component={'label'}>
              <label htmlFor={'fileInput'}>
                Toque aqui para tirar uma foto ou enviar o arquivo do seu
                documento
              </label>
              <input
                disabled={disabled}
                type={'file'}
                hidden={true}
                accept={'.pdf,.png,.jpg,.jpeg'}
                id={'fileInput'}
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
              />
            </Button>
          )}
          {!disabled && (
            <Typography
              bgcolor={bgColor}
              textAlign={'center'}
              variant="caption"
              color="text.secondary"
              sx={{ whiteSpace: 'pre-line' }}
            >
              São aceitos arquivos no formato PDF, JPG e PNG, com tamanho máximo
              de 5MB.
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        bgcolor={bgColor}
        gap={2}
        maxWidth={'280px'}
      >
        {error && (
          <Typography fontSize={'12px'} lineHeight={'16px'} color={'error'}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export function FilePreviewComponent({
  file,
  setFile,
  showDeleteButton,
}: {
  file?: File;
  setFile: (file?: File) => void;
  showDeleteButton: boolean;
}) {
  if (!file) {
    return undefined;
  }

  return (
    <Box
      display={'flex'}
      flex={'auto'}
      boxSizing={'border-box'}
      position={'relative'}
      height={file.type === 'application/pdf' ? '100%' : undefined}
    >
      {file.type === 'application/pdf' && (
        <iframe
          src={URL.createObjectURL(file) + '#navpanes=0&scrollbar=0'}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {file.type.startsWith('image/') && (
        <Box>
          <img
            src={URL.createObjectURL(file)}
            style={{
              width: '100%',
            }}
          />
        </Box>
      )}
      {showDeleteButton && (
        <Box
          position={'absolute'}
          bottom={0}
          sx={{
            transform: 'translateX(-50%) translateY(50%)',
            left: '50%',
            bgcolor: '#FFF',
          }}
        >
          <IconButton
            onClick={() => {
              setFile(undefined);
            }}
            sx={{
              border: '1px solid #EDEDED',
              borderRadius: 1,
              boxShadow:
                '0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 16px 0px rgba(0, 0, 0, 0.03)',
            }}
          >
            <DeleteOutlineOutlinedIcon
              color={'action'}
              width={'16px'}
              height={'16px'}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export async function uploadFile(uploadUrl: string, file: File) {
  const data = await file.arrayBuffer();

  const resp = await fetch(uploadUrl, {
    method: 'PUT',
    body: data,
  });

  if (resp.status !== 200) {
    throw new Error('Erro ao enviar arquivo');
  }

  return resp;
}

function FileOperationScene({ type }: { type: string }) {
  return (
    <Box
      display={'flex'}
      height={'100%'}
      justifyContent={'center'}
      width={'100%'}
      alignItems={'center'}
    >
      <Box
        display={'flex'}
        height={'100%'}
        px={5}
        py={2}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
      >
        <Typography variant={'caption'} color={'text.secondary'} noWrap={true}>
          {type}...
        </Typography>

        <CircularProgress />
      </Box>
    </Box>
  );
}
