import EnErrorCodes from '../en/contracts';

const ContractErrorCodes: Record<keyof typeof EnErrorCodes, string> = {
  CTR00001: 'Faltam informações pessoais',
  CTR00002: 'Data de nascimento é obrigatória',
  CTR00003:
    'Tempo de residência e Condição de ingresso não deve ser preenchido para trabalhador imigrante',
  CTR00004:
    'Tempo de residência e Condição de ingresso é obrigatório para trabalhador imigrante',
  CTR00005: 'Endereço é obrigatório',
  CTR00006: 'Deficiencia é obrigatório',
  CTR00007: 'CPF do dependente inválido',
  CTR00009: 'CBO da Função é obrigatório quando Nome da Função está preenchido',
  CTR00010: 'CBO da Função deve ser vazio se Nome da Função está vazio',
  CTR00008: 'Nome da função é obrigatório para cargo de confiança',
  CTR00011: 'Estágio é obrigatório para o código de categoria selecionado',
  CTR00012: 'Estágio deve ser vazio para o código de categoria selecionado',
  CTR00013:
    'Natureza da prestação de serviço civil voluntário deve ser não obrigatória',
  CTR00014:
    'Nivel do estágio é obrgiatório para o código de categoria selecionado',
  CTR00015: 'Nivel do estágio inválido para o código de categoria selecionado',
  CTR00016: 'Data Prevista de Término deve ser depois da Data de Admissão',
  CTR00017: 'CNPJ do Agente Integrador inválido',
  CTR00018: 'CPF da instituição de ensino inválido',
  CTR00019: 'CNPJ da instituição de ensino inválido',
  CTR00020:
    'Regime de Trabalho inválido para o Código de Categoria selecionado',
  CTR00021:
    'Regime Previdenciário inválido para o Código de Categoria selecionado',
  CTR00022: 'Regime Previdenciário inválido para este código de categoria',
  CTR00023: 'Regime é obrigatório para o Regime de Trabalho selecionado',
  CTR00024: 'Regime deve ser vazio para o Regime de Trabalho selecionado',
  CTR00025:
    'Número do processo trabalhista é obrigatório para admissão decorrente de ação judicial',
  CTR00026:
    'Número do processo trabalhista deve ser vazio para esse tipo de admissão',
  CTR00027:
    'Jornada em horas semanais é obrigatória para o código de categoria selecionado',
  CTR00028: 'Hora noturna é obrigatória para o código de categoria selecionado',
  CTR00029:
    'Remuneração é obrigatória para o Tipo de regime Trabalhista selecionado',
  CTR00030:
    'Remuneração deve ser vazia para o Tipo de regime Trabalhista selecionado',
  CTR00031: 'Salário deve ser 0 para a unidade selecionada',
  CTR00032: 'Descrição do salário é obrigatória para a unidade selecionada',
  CTR00033:
    'Duração é obrigatória para o Tipo de regime Trabalhista selecionado',
  CTR00034: 'Duração deve ser vazia o Tipo de regime Trabalhista selecionado',
  CTR00035:
    'Data de término deve ser vazia para o Tipo de contrato selecionado',
  CTR00036:
    'Cláusula assecuratória deve ser vazia para o Tipo de contrato selecionado',
  CTR00037: 'Data de término é obrigatória para o Tipo de contrato selecionado',
  CTR00038:
    'Objeto determinante é obrigatório para o Tipo de contrato selecionado',
  CTR00039:
    'Cláusula assecuratória é obrigatória o Tipo de contrato selecionado',
  CTR00040: 'Data de Término deve ser igual ou maior que a Data de Admissão',
  CTR00041:
    'Data da extensão do Período de experiencia só deve ser preenchido se Data de Término está preenchida',
  CTR00042:
    'Data da extensão do Período de experiencia deve ser depois da Data de Término',
  CTR00043: 'Sucessão é obrigatória para o tipo de admissão selecionado',
  CTR00044:
    'Número de inscrição deve ser CNP cálido para o Tipo de inscrição selecionado',
  CTR00045:
    'Número de inscrição deve ser CPF cálido para o Tipo de inscrição selecionado',
  CTR00046:
    'Número de inscrição deve ser CEI cálido para o Tipo de inscrição selecionado',
  CTR00047: 'Data de Transferência deve ser maior que a Data de Admissão',
  CTR00048:
    'Tipo de inscrição inválido, a menos que data de transferencia seja antes de 2011-12-31',
  CTR00049:
    'Tipo de inscrição inválido, a menos que data de transferencia seja antes de 1999-06-30',
  CTR00050: 'Contrato(br) é obrigatório',
  CTR00051: 'Tipo de contrato inválido. Esperado: CLT.',
  CTR00052: 'Campo obrigatório',
  CTR00053: 'Remuneração obrigatoria para contribuinte CLT padrão',
  CTR00054:
    'Remuneração não deve ser passada para contribuinte CLT autônomo [701, 711, 712]',
  CTR00055: 'tpRegPrev é obritatório para CLT:geral',
  CTR00056:
    'Jornada não deve ser passada para contribuinte CLT autônomo [701, 711, 712]',
  CTR00057:
    'Estagio não deve ser passada para contribuinte CLT autônomo [701, 711, 712]',
  CTR00058:
    'Duração não deve ser passada para contribuinte CLT autônomo [701, 711, 712]',
  CTR00059: 'Jornada obrigatorio para contribuinte CLT padrão',
  CTR00060: 'Duração obrigatorio para contribuinte CLT padrão',
  CTR00061: 'Vinculo obrigatorio para contribuinte CLT padrão',
  CTR00062: 'Data de admissão é obrigatória',
  CTR00063:
    'Campos [tpAdmissao, indAdmissao, tpRegJor, natAtividade] são obrigatórios',
  CTR00064: 'Campo tpRegTrab é obritatório para CLT:geral',

  CTR00065: 'Campo descrDep é obrigatório quando tpDep é 99',
  CTR00066: 'Campo descrDep é permitido apenas se tpDep for 99',
  CTR00067: 'Tipo de contrato inválido. Deve ser br:clt',
  CTR00068: 'Matrícula é obrigatória',
  CTR00069:
    'Data de opção do FGTS é obrigatória para funcionário com Código de Categoria=721',
  CTR00070: 'Tipo de contrato inválido. Deve ser PJ',
  CTR00071: 'Contrato não encontrado',
  CTR00072: 'Contrato está terminado',
  CTR00073: 'Contrato não pode ser atribuído como seu próprio gerente',
  CTR00074: 'Contrato já está atribuído ao gerente na data efetiva informada',
  CTR00075: 'Contrato do gerente não encontrado',
  CTR00076: 'Contrato do gerente está terminado',
  CTR00077:
    'Não é possível atribuir um gerente que causaria uma estrutura circular no organograma',
  CTR00078: 'Contrato não está ativo',
  CTR00079: 'Não é possível transferir para a mesma empresa',
  CTR00080: 'Evento de contrato não encontrado',
  CTR00081: 'Tipo de evento não pode ser excluído',
  CTR00082: 'Evento de admissão de contrato não pode ser encontrado',
  CTR00083:
    'Não é possível excluir evento de contrato para empresa com eSocial habilitado',
  CTR00084: 'Licença não encontrada',
  CTR00085:
    'Não é possível excluir a licença se a data de início é antes da última licença',
  CTR00086: 'Não é possível transferir para a mesma entidade legal',
  CTR00087:
    'Não é possível atualizar a licença se a data de término é antes da data de início',
  CTR00088:
    'Não é possível alterar a licença se a data de início é antes da última licença',
  CTR00089:
    'Não é possível registrar a licença se a data de término é antes da data de início',
  CTR00090: 'Outras razões de licença ainda não implementadas',
  CTR00091:
    'Não é possível registrar licença se a data de início é antes da última licença',
  CTR00092: 'Payload de licença br inválido',
  CTR00093: 'Não é possível terminar contrato antes da última alteração',
  CTR00094: 'Dependentes duplicados',
  CTR00095: 'Cpf inválido para dependente',
  CTR00096: 'vrSalFx tem mais de 2 casas decimais',
  CTR00097: 'novaMatricula é obrigatório para um contrato CLT',
  CTR00098: 'Sindicato deve ser vazio para estagiário',
};

export default ContractErrorCodes;
