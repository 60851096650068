const PayrollErrorCodes = {
  'RE002/PC001': 'Estagiario may create only monthly or termination payrolls',
  'RE002/PC002':
    'DiretorSemFgts and DiretorComFgts are allowed to create only monthly and termination payrolls',
  'RE002/PC003': 'Thirteenth payroll validations',
  'RE002/PC004':
    'Termination may not be created for employee with planned vacations',
  'RE002/PC005': 'Termination may not be created during employee vacations',
  'RE002/PC006': 'Autonomo only allow `rpa` payroll type',
  'RE002/PC007': 'Contract is not active,  cannot create payroll',
  'RE002/PC008':
    'Contract is active, cannot create complementaryTermination payroll',

  PAY00001: 'Payment cannot be set after 5th day of next month',
  PAY00002: 'Type must be element for dependent targets',
  PAY00003: 'Payload type must be currency for elements',
  PAY00004: 'Tag must be earnings, deductions or informative for type elements',
  PAY00005: 'Tag must be events for type event',
  PAY00006: 'Target must be employee for type event',
  PAY00007: 'Payload type must be hours or number for horasTrabalhadasEvent',
  PAY00008: 'SubType is required for horasTrabalhadasEvent',
  PAY00009: 'Invalid subType',
  PAY00010: 'Extra hours percentage is required',
  PAY00011: 'Extra hours percentage must be a single value',
  PAY00012: 'Extra hours percentage must be a number',
  PAY00013: 'No extra parameters are expected for this event',
  PAY00014: 'Payload type must be dateRange for faltasJustificadas',
  PAY00015: 'Motivo is required for faltasJustificadas',
  PAY00016: 'Invalid motivo for faltasJustificadas',
  PAY00017: 'Only one extra parameter is allowed for faltasJustificadas',
  PAY00018: 'Invalid extra parameter for faltasJustificadas',
  PAY00019: 'Payload type must be days for faltasInjustificadas',
  PAY00020: 'Only one extra parameter is allowed for faltasInjustificadas',
  PAY00021: 'Invalid extra parameter for faltasInjustificadas',
  PAY00022: 'Payload type must be currency for adiantamentoSalarial',
  PAY00023: 'Only one extra parameter is allowed for adiantamentoSalarial',
  PAY00024: 'Invalid extra parameter for adiantamentoSalarial',
  PAY00025: 'Payload type must be dateRange for afastamento',
  PAY00026: 'Two extra parameter are required for afastamento',
  PAY00027: 'Invalid motivo for afastamento',
  PAY00028: 'faltasJustificadasDisponiveis must be a number',
  PAY00029: 'Payload type must be dateRange for afastamentoPrevidenciario',
  PAY00030: 'One extra parameter are required for afastamentoPrevidenciario',
  PAY00031: 'Invalid motivo for afastamentoPrevidenciario',
  PAY00032: 'Payload type must be dateRange for licencaMaternidade',
  PAY00033: 'One extra parameter is allowed for licencaMaternidade',
  PAY00034: 'Invalid extra parameter for licencaMaternidade',
  PAY00035: 'Not supported event type',
} as const;

export default PayrollErrorCodes;
