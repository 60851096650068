import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  VacationsScheduleSummary,
  fetchGetContractVacationsSchedule,
} from '@octopus/api';

import VacationsRequestedCard from '../../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { DataFetching } from '../../../../modules/dataFetching';
import { DeclinedVacationStatus, declinedStatus } from '../../utils/types';

function VacationsHistoryTab({
  organizationId,
}: {
  organizationId: string | undefined;
}): ReactNode {
  const { contractId } = useParams();

  const vacationsScheduledQuery = useQuery({
    queryKey: [organizationId, contractId, 'proximas', 'solicitadas'],
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    queryFn: () => {
      return fetchGetContractVacationsSchedule({
        pathParams: {
          organizationId: organizationId,
          contractId: contractId,
        },
      });
    },
    enabled: !!organizationId,
  });

  const [expandButtonState, setExpandButtonState] = React.useState(false);

  const handleExpandButtonClick = () => {
    setExpandButtonState(!expandButtonState);
  };

  return (
    <Box
      display={'flex'}
      sx={{
        width: '100%',
        flexDirection: 'column',
        py: 0,
        px: 0,
      }}
    >
      <DataFetching
        fetchResult={vacationsScheduledQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;
          return (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'stretch',
              }}
            >
              {response.data.filter(hasVacationSucceeded).length > 0 ? (
                response.data.map(
                  (scheduleSummary: VacationsScheduleSummary) => {
                    return hasVacationSucceeded(scheduleSummary) ? (
                      <VacationsRequestedCard
                        scheduleSummary={scheduleSummary}
                        detailsMode={false}
                        historyMode={true}
                        detailsLink={`/vacations/${contractId}/requestDetails/${scheduleSummary.sequence}`}
                      />
                    ) : null;
                  },
                )
              ) : (
                <Box sx={{ py: 8, alignSelf: 'center' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      fontStyle: 'italic',
                    }}
                  >
                    Você não possui férias passadas ainda.
                  </Typography>
                </Box>
              )}
              {response.data.filter(shouldShowRequestAsCanceled).length > 0 ? (
                <List
                  sx={(theme) => ({
                    width: '100%',
                    bgcolor: theme.palette.background.secondary,
                  })}
                >
                  <ListItemButton
                    onClick={handleExpandButtonClick}
                    sx={(theme) => ({
                      padding: '0px',
                      backgroundColor: theme.palette.background.secondary,
                      ':hover': {
                        opacity: 1,
                      },
                    })}
                  >
                    <ListItemText
                      sx={(theme) => ({
                        p: 0,
                        pt: 1,
                        backgroundColor: theme.palette.background.secondary,
                      })}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#616161',
                          fontSize: '14px',
                          fontWeight: 500,
                          pb: 1.5,
                        }}
                      >
                        Recusadas e canceladas
                      </Typography>
                    </ListItemText>
                    {expandButtonState ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={expandButtonState} timeout="auto" unmountOnExit>
                    {response.data.map(
                      (scheduleSummary: VacationsScheduleSummary) => {
                        return shouldShowRequestAsCanceled(scheduleSummary) ? (
                          <VacationsRequestedCard
                            scheduleSummary={scheduleSummary}
                            detailsMode={false}
                            historyMode={true}
                            detailsLink={`/vacations/${contractId}/requestDetails/${scheduleSummary.sequence}`}
                          />
                        ) : null;
                      },
                    )}
                  </Collapse>
                </List>
              ) : null}
            </Box>
          );
        }}
      />
    </Box>
  );
}

function hasVacationSucceeded(scheduleSummary: VacationsScheduleSummary) {
  return (
    scheduleSummary.status === 'payrollApproved' &&
    dayjs(scheduleSummary.endDate).isBefore(dayjs())
  );
}
function shouldShowRequestAsCanceled(
  scheduleSummary: VacationsScheduleSummary,
): boolean {
  return declinedStatus.includes(
    scheduleSummary.status as DeclinedVacationStatus,
  );
}
export default VacationsHistoryTab;
