import { ReactElement } from 'react';

import { Box } from '@mui/material';

export type CalculationBoxProps = {
  children?: ReactElement;
  transparent?: boolean;
};

export function CalculationBox({ children, transparent }: CalculationBoxProps) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: transparent ? '' : theme.palette.background.paper,
        borderColor: theme.palette.strokes.light,
        padding: transparent ? '16px 20px' : '20px',
        borderStyle: 'solid',
        borderRadius: '12px',
        borderWidth: '1px',
      })}
    >
      {children}
    </Box>
  );
}
