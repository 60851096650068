import { Box, Divider, Skeleton } from '@mui/material';

import { ContractEntry } from '@octopus/api';

import { PageAlert } from '../../../modules/components/PageAlert';
import { QueryResult } from '../../../modules/types';
import { useFFlags } from '../../fflags';

import { CltContractDetails } from './CltContractDetails';
import { PjContractDetails } from './PjContractDetails';
import { PageRefs } from './types';

export function ContractDetailsContent({
  refs,
  contractQuery,
}: {
  refs: PageRefs;
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { FFlags } = useFFlags();
  if (contractQuery.isLoading) {
    return <ContractDetailsContentSkeleton />;
  }
  switch (contractQuery.data?.contractType) {
    case 'br:clt':
      return (
        <>
          <FFlags canAccessEmployeeEdit>
            <CltContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={true}
              historyEnabled={true}
            />
          </FFlags>
          <FFlags canAccessEmployeeEdit={false}>
            <CltContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={false}
              historyEnabled={false}
            />
          </FFlags>
        </>
      );
    case 'br:pj':
      return (
        <>
          <FFlags canAccessContractorEdit>
            <PjContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={true}
              historyEnabled={true}
            />
          </FFlags>
          <FFlags canAccessContractorEdit={false}>
            <PjContractDetails
              refs={refs}
              contractQuery={contractQuery}
              isEditable={false}
              historyEnabled={false}
            />
          </FFlags>
        </>
      );
    default:
      return (
        <PageAlert
          message="Tipo de contrato não suportado"
          severity="error"
          showRetryMessage={false}
          retryMessage="Se o problema persistir, entre em contato com o suporte da Tako."
        />
      );
  }
}

function ContractDetailsContentSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="rounded" height="48px" />
      <Divider />
      <Skeleton variant="rounded" height="40vh" />
      <Skeleton variant="rounded" height="40vh" />
      <Skeleton variant="rounded" height="40vh" />
    </Box>
  );
}
