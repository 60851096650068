import { useRef, useState } from 'react';

import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import {
  Box,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';

import { Button } from '@octopus/ui/design-system';

export const SubmitButton = ({
  submitLabel,
  isLoading,
  formId,
}: {
  submitLabel: string;
  isLoading: boolean;
  formId: string;
}) => {
  return (
    <Button
      isLoading={isLoading}
      sx={{
        paddingBlock: 1,
        paddingInline: 3,
        marginLeft: 'auto',
      }}
      variantSemantic="highlight"
      type="submit"
      form={formId}
    >
      <Typography
        variant="body2"
        sx={{
          lineHeight: '20px',
          color: (theme) => theme.palette.primaryAlt.contrastText,
        }}
        data-testid="form-submit-button"
      >
        {submitLabel}
      </Typography>
    </Button>
  );
};

export const MultipleFormatsSubmitButton = ({
  formId,
  setFormat,
}: {
  formId: string;
  setFormat: (format: string) => void;
}) => {
  const options = [
    {
      icon: <XlsIcon />,
      label: 'Gerar relatório em Planilha',
      format: 'xls',
    },
    {
      icon: <PdfIcon />,
      label: 'Gerar relatório em PDF',
      format: 'pdf',
    },
  ];
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setFormat(options[index].format);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box marginLeft="auto">
      <ButtonGroup sx={{ backgroundColor: '#25252D' }} ref={anchorRef}>
        <Button
          form={formId}
          sx={{
            paddingRight: '0px',
            paddingLeft: '8px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
        >
          {options[selectedIndex]?.label}
        </Button>
        <Divider
          sx={{ backgroundColor: '#fff' }}
          variant="middle"
          orientation="vertical"
          flexItem
        />
        <Button
          onClick={handleToggle}
          sx={{
            paddingRight: '4px',
            paddingLeft: '0px',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
          }}
        >
          <KeyboardArrowDownOutlined fontSize="large" />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="top-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'top' ? 'right top' : 'right bottom',
            }}
          >
            <Paper sx={(theme) => ({ marginY: theme.spacing(0.5) })}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  sx={(theme) => ({
                    paddingTop: 0,
                    paddingBottom: theme.spacing(1),
                    paddingX: theme.spacing(1),
                  })}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      sx={(theme) => ({
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: theme.spacing(1),
                        paddingX: theme.spacing(6),
                        marginTop: theme.spacing(1),
                        width: '100%',
                      })}
                      component="button"
                      key={option.label}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.icon}
                      <Box ml={1}>{option.label}</Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const XlsIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="XLS-file">
      <path
        id="Vector"
        d="M10.5 2.25006V5.25006C10.5 5.44897 10.579 5.63974 10.7197 5.78039C10.8603 5.92104 11.0511 6.00006 11.25 6.00006H14.25M10.5 2.25006H5.25C4.85218 2.25006 4.47064 2.4081 4.18934 2.6894C3.90804 2.97071 3.75 3.35224 3.75 3.75006V9.00006M10.5 2.25006L14.25 6.00006M14.25 6.00006V9.00006M3 11.2501L6 15.7501M3 15.7501L6 11.2501M12.75 15.1876C12.75 15.4981 13.002 15.7501 13.3125 15.7501H14.25C14.4489 15.7501 14.6397 15.671 14.7803 15.5304C14.921 15.3897 15 15.199 15 15.0001V14.2501C15 14.0511 14.921 13.8604 14.7803 13.7197C14.6397 13.5791 14.4489 13.5001 14.25 13.5001H13.5C13.3011 13.5001 13.1103 13.421 12.9697 13.2804C12.829 13.1397 12.75 12.949 12.75 12.7501V12.0001C12.75 11.8011 12.829 11.6104 12.9697 11.4697C13.1103 11.3291 13.3011 11.2501 13.5 11.2501H14.4375C14.5867 11.2501 14.7298 11.3093 14.8352 11.4148C14.9407 11.5203 15 11.6634 15 11.8126M8.25 11.2501V15.7501H10.5"
        stroke="#616161"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

const PdfIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="PDF-file">
      <path
        id="Vector"
        d="M10.5 2.25006V5.25006C10.5 5.44897 10.579 5.63974 10.7197 5.78039C10.8603 5.92104 11.0511 6.00006 11.25 6.00006H14.25M10.5 2.25006H5.25C4.85218 2.25006 4.47064 2.4081 4.18934 2.6894C3.90804 2.97071 3.75 3.35224 3.75 3.75006V9.00006M10.5 2.25006L14.25 6.00006M14.25 6.00006V9.00006M3.75 13.5001H4.875C5.17337 13.5001 5.45952 13.3815 5.6705 13.1706C5.88147 12.9596 6 12.6734 6 12.3751C6 12.0767 5.88147 11.7905 5.6705 11.5796C5.45952 11.3686 5.17337 11.2501 4.875 11.2501H3.75V15.7501M12.75 13.5001H14.25M15 11.2501H12.75V15.7501M8.25 11.2501V15.7501H9C9.39782 15.7501 9.77936 15.592 10.0607 15.3107C10.342 15.0294 10.5 14.6479 10.5 14.2501V12.7501C10.5 12.3522 10.342 11.9707 10.0607 11.6894C9.77936 11.4081 9.39782 11.2501 9 11.2501H8.25Z"
        stroke="#616161"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
