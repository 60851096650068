import { useEffect, useState } from 'react';

import { TFFlagValue, TFFlagsContext } from './createFFlagsContext';
import { TFlagNameSetup } from './TFlagNameSetup';

export type TFFlagsComponentProps<TFlagName extends string> =
  React.PropsWithChildren<Partial<Record<TFlagName, TFFlagValue>>>;

export function createFFlagsComponent<const TFlagName extends string>(
  flagNames: TFlagNameSetup<TFlagName>,
  flagContext: TFFlagsContext<TFlagName>,
) {
  return function FFlags(props: TFFlagsComponentProps<TFlagName>) {
    const { children, ...flagProps } = props;
    const [fflags, setFFlags] =
      useState<Partial<Record<TFlagName, TFFlagValue>>>();

    useEffect(() => {
      flagContext.onPush(() => {
        flagContext.pull().then((fflags) => {
          // console.log('onPush', fflags);
          setFFlags(fflags);
        });
      });
    }, []);

    const flagPropEntries = Object.entries(flagProps) as [
      TFlagName,
      TFFlagValue,
    ][];

    const isLoading = fflags === undefined;
    const isEnabled =
      !isLoading &&
      flagPropEntries.reduce((isEnabled, [fflagName, fflagValue]) => {
        return isEnabled && fflags[fflagName] === fflagValue;
      }, true);

    return isEnabled ? children : '';
  };
}
