//global setup so that js value changes on form fields trigger input events
//this enables fomrm field elements decoupling from whatever form state management library we decide to use

if (typeof document === 'object') {
  const isHTMLFormFieldElement = (
    arg: unknown,
  ): arg is HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement => {
    return (
      arg instanceof Element &&
      (arg.tagName === 'INPUT' ||
        arg.tagName === 'SELECT' ||
        arg.tagName === 'RADIO' ||
        arg.tagName === 'TEXTAREA')
    );
  };

  for (const HTMLFieldElement of [
    HTMLInputElement,
    HTMLSelectElement,
    HTMLTextAreaElement,
  ]) {
    const fieldValuePropDescriptor = Object.getOwnPropertyDescriptor(
      HTMLFieldElement.prototype,
      'value',
    );
    Object.defineProperty(HTMLFieldElement.prototype, 'value', {
      ...fieldValuePropDescriptor,
      set: function (newValue: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const fieldElement = this;
        const isSameOldValue = fieldElement.value === newValue;
        const isFormField = isHTMLFormFieldElement(fieldElement);

        fieldValuePropDescriptor.set.call(fieldElement, newValue);

        if (!isFormField || isSameOldValue) return;

        const event = new Event('input', { bubbles: true });
        fieldElement.dispatchEvent(event);
      },
    });
  }
}
