import { Label } from '../Field/commons/Label';
// eslint-disable-next-line import/no-cycle
import { FormFields } from '../FormFields';
import { TFieldRenderProps, TFieldsetRenderProps } from '../parseField/types';

type FieldsetProps = {
  field: TFieldRenderProps & TFieldsetRenderProps;
};

export function Fieldset(props: FieldsetProps) {
  const { field: fieldset } = props;

  return (
    <fieldset {...fieldset.props}>
      <Label field={fieldset} />
      <FormFields
        fields={fieldset.fields.map((field) => ['asdada', field])}
        isInFieldset={true}
      />
    </fieldset>
  );
}

Fieldset.canRender = (
  field: TFieldRenderProps,
): field is FieldsetProps['field'] => {
  return 'fields' in field;
};
